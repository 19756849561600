<app-image-canvas *ngIf="markerData && pdfPlanPageMarkings && imageUrl"
                  #imgCanvas
                  [oldMarkers]="markerData"
                  (olderMarkersChange)="markerDataChanged($event)"
                  [readonly]="readonly"
                  [allEditable]="!readonly"
                  [src]="imageUrl"
                  [srcWidth]="pdfPlanPage?.width"
                  [srcHeight]="pdfPlanPage?.height"
                  [pdfPlanPageMarkings]="pdfPlanPageMarkings"
                  (pdfPlanPageMarkingsChange)="pdfPlanPageMarkingsChanged($event)"
                  [groupPdfPlanPageMarkings]="true"
                  [moveChoice]="moveChoice" (moveChoiceChange)="moveChoiceChanged($event)"
                  (loadedImage)="loadedImage.emit($event)"
                  [width]="width"
                  [height]="height"
                  [moveEntriesWhenOutsideVisibleArea]="true"
                  (entriesMovedFromOutsideVisibleArea)="entriesMovedFromOutsideVisibleArea.emit($event)"
>
</app-image-canvas>
