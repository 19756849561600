import {NgIf} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from 'src/app/pipes/pipes.module';
import {AnimationItem} from 'lottie-web';
import {AnimationOptions, LottieComponent} from 'ngx-lottie';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    FontAwesomeModule,
    PipesModule,
    NgIf,
    LottieComponent
  ]
})
export class InfoPopoverComponent implements OnInit {

  popover: HTMLIonPopoverElement;

  @Input()
  title: string;

  @Input()
  text: string;

  @Input()
  hintText?: string;

  @Input()
  lottieFilename?: string;

  private animationItem: AnimationItem | undefined;

  options: AnimationOptions|undefined;

  constructor() { }

  ngOnInit() {
    if (this.lottieFilename) {
      this.options = {
        path: '../../../../assets/lottie/' + this.lottieFilename,
        autoplay: true,
        loop: true
      };
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  dismiss() {
    this.popover.dismiss();
  }
}
