<ion-content class="with-padding">
  <div class="d-flex flex-column gap-7">
    <div>
      <ion-button class="omg-btn-text" (click)="changeMethod.emit('totp')">
        <fa-icon slot="start" [icon]="['fal', 'arrow-left']"></fa-icon>
        <span>{{'twoFactor.login.backToTotp' | translate}}</span>
      </ion-button>
    </div>

    <div class="d-flex flex-column gap-6 main-content">
      <div class="d-flex flex-column gap-2">
        <div class="ion-text-center omg-t-header-3-bold">
          {{'twoFactor.login.recovery_code.title' | translate}}
        </div>
        <div class="ion-text-center omg-t-body">
          {{'twoFactor.login.recovery_code.description' | translate}}
        </div>
        <div class="ion-text-center omg-t-body">
          {{'twoFactor.login.recovery_code.invalidWarning' | translate}}
        </div>
      </div>

      <div class="d-flex flex-column gap-2">
        <div class="code-input-wrapper">
          <code-input [inputType]="'text'" [initialFocusField]="0" [codeLength]="8" [isCharsCode]="true" (codeChanged)="codeUpdated($event)"></code-input>
        </div>
        <ion-note><ng-content></ng-content>&nbsp;</ion-note>
      </div>

      <ion-button class="omg-btn-primary" block type="button" (click)="verify.emit()" [disabled]="loading || details.code?.length !== 8">
        <ion-spinner slot="start" *ngIf="loading"></ion-spinner>
        <span>{{'twoFactor.login.verify' | translate}}</span>
      </ion-button>

      <div class="ion-text-center omg-t-body omg-f-italic text-secondary">
        {{'twoFactor.login.recovery_code.lostCodesPrefix' | translate}} <br />
        <a [href]="supportPageUrl" target="_blank" class="omg-link">{{'twoFactor.login.recovery_code.lostCodes' | translate}}</a>
      </div>
    </div>
  </div>
</ion-content>
