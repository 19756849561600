import { ColumnConfig } from '../ColumnConfig';
import { StaffColumnSet } from './StaffColumnSet';

const UniqueCodeInClientUniq = ['client_id', 'code'];
const UniqueNameInClientUniq = ['client_id', 'name'];

export const StaffingTypeColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'name', uniqueWith: [UniqueNameInClientUniq]},
  {name: 'code', uniqueWith: [UniqueCodeInClientUniq]},
  {name: 'client_id', prop: 'clientId', uniqueWith: [UniqueNameInClientUniq, UniqueCodeInClientUniq]},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'staffs', fk: 'staffingTypeId', mappedBy: StaffColumnSet, transient: true},
];
