import {IdType, ProtocolEntryIconStatus, ProtocolEntryPriorityType} from 'submodules/baumaster-v2-common';
import {Nullish} from './nullish';

export type EntryLayout = 'STANDARD' | 'SHORT' | 'CONTINUOUS';

export interface EntryCardBaseModel {
  id: string;
  mainEntryOrder: number;
  subEntryOrder: number;
  createdAt: string | Date;
  layout: EntryLayout;
  isNew?: boolean;
  titleText: string;
  text: string;
  internalAssignmentId?: Nullish<IdType>;
  company?: Nullish<string>;
  craft?: Nullish<string>;
  type?: Nullish<string>;
  todoUntil?: Nullish<string | Date>;
  priority?: Nullish<ProtocolEntryPriorityType>;
  hasMarker: boolean;
  hasAttachments: boolean;
  hasChat: boolean;
  subEntriesCount?: Nullish<number>;
  status: ProtocolEntryIconStatus;
  isSubtask: boolean;
  isProtocolClosed: boolean;
  parentId?: IdType;
  protocolId: IdType;
  companyId?: IdType | null;
  craftId?: IdType | null;
  locationId?: IdType | null;
  typeId?: IdType | null;
  allCompanies: boolean;
  nameableDropdownId?: IdType | null;
  createdById?: IdType | null;
  observerCompanies?: IdType[] | null;
  mainEntryNumber: number;
  createdInProtocolId?: IdType | null;
  entryNumber: number;
  isContinuousInfo: boolean;
}

export interface EntryCardEntryModel extends EntryCardBaseModel {
  protocolEntryNumber: string;
  isCarriedOver?: boolean;
  createdInProtocol?: string;
  showCreatedInProtocol?: boolean;
  originalProtocolId?: IdType;
  taskNumber?: never;
}
export interface EntryCardTaskModel extends EntryCardBaseModel {
  protocolEntryNumber?: never;
  isCarriedOver?: never;
  createdInProtocol?: never;
  showCreatedInProtocol?: never;
  originalProtocolId?: never;
  taskNumber: number;
}
export type EntryCardModel = EntryCardEntryModel | EntryCardTaskModel;

export type EntryCardListModel = EntryCardModel & {
  groupId?: string;
  firstInGroup?: boolean;
};

export interface EntryCardSettings {
  showId: boolean;
  showText: boolean;
  showInitials: boolean;
  showCraft: boolean;
  showType: boolean;
  showPriorityDate: boolean;
  showAttachmentsSub: boolean;
}

export const ENTRY_CARD_SETTINGS_DEFAULT: EntryCardSettings = {
  showId: true,
  showText: true,
  showInitials: true,
  showCraft: true,
  showType: true,
  showPriorityDate: true,
  showAttachmentsSub: true
};

export const PROTOCOL_ENTRY_CARD_SETTINGS_DEFAULT: EntryCardSettings = {
  showId: true,
  showText: false,
  showInitials: true,
  showCraft: true,
  showType: true,
  showPriorityDate: true,
  showAttachmentsSub: true
};

export const BIM_ENTRY_CARD_SETTINGS_DEFAULT: EntryCardSettings = {
  showId: true,
  showText: false,
  showInitials: false,
  showCraft: false,
  showType: false,
  showPriorityDate: true,
  showAttachmentsSub: true
};

export const ENTRY_CARD_DEFAULT_HEIGHT = 183;

export type TaskCardView = 'LIST' | 'DETAIL' | 'FILTER';
