export enum EntryMailSendWorkflowStep {
  MAILING_LIST = 'MAILING_LIST',
  EMAIL_SETTINGS = 'EMAIL_SETTINGS',
}

export interface EntryMailSendWorkflowStepConfig {
  currentStep: EntryMailSendWorkflowStep;
  previousStep: EntryMailSendWorkflowStep | null;
  nextStep: EntryMailSendWorkflowStep | null;
}

