import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'isEmpty'
})
export class IsEmptyPipe implements PipeTransform {

  transform(value: any, ...args: string[]): boolean {
    const isEmptyValue =  typeof value === 'undefined' || value === '' || value === null;
    if (_.head(args) === 'reverse'){
      return !isEmptyValue;
    }
    return isEmptyValue;
  }

}
