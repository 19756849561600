import {Injectable} from '@angular/core';
import {IdType, PdfPlan, User} from 'submodules/baumaster-v2-common';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/pdfPlans';

@Injectable({
  providedIn: 'root'
})
export class PdfPlanDataService extends AbstractProjectAwareDataService<PdfPlan> {

  constructor(http: HttpClient, storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.PDF_PLAN, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

  getByPdfPlanFolder(pdfPlanFolderId: IdType): Observable<Array<PdfPlan>> {
    return this.data.pipe(map((pdfPlans) => pdfPlans.filter((pdfPlan) => pdfPlan.folderId === pdfPlanFolderId)));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
