import {Injectable} from '@angular/core';
import {IdType, Project, Protocol} from 'submodules/baumaster-v2-common';
import {ToastDurationInMs} from '../../shared/constants';
import {observableToPromise} from '../../utils/async-utils';
import {SyncStrategy} from '../sync/sync-utils';
import {environment} from '../../../environments/environment';
import {NetworkStatusService} from '../common/network-status.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertController} from '@ionic/angular';
import {SyncStatusService} from '../sync/sync-status.service';
import {SyncService} from '../sync/sync.service';
import {HttpClient} from '@angular/common/http';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {SystemEventService} from '../event/system-event.service';
import {combineLatest, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {ProtocolDataService} from '../data/protocol-data.service';
import { ToastService } from '../common/toast.service';

const LOG_SOURCE = 'ProtocolDeletionService';

@Injectable({
  providedIn: 'root'
})
export class ProtocolDeletionService {
  constructor(private networkStatusService: NetworkStatusService, private translateService: TranslateService, private toastService: ToastService, private syncStatusService: SyncStatusService,
              private syncService: SyncService, private http: HttpClient, private alertCtrl: AlertController, private protocolEntryDataService: ProtocolEntryDataService,
              private systemEventService: SystemEventService, private protocolDataService: ProtocolDataService) {
  }

  public async deleteProtocolOnServer(protocol: Protocol, project: Project) {
    if (this.networkStatusService.offline) {
      await this.toastService.info('protocol.toast.deleteOfflineNotPossible');
      return;
    }
    await this.deleteProtocol(project, protocol);
  }

  private async deleteProtocol(project: Project, protocol: Protocol) {
    const deletionProgress = await this.showProtocolDeletionProgress();
    try {
      const isDeletable = this.isDeletable(protocol);
      if (!isDeletable) {
        throw new Error(`'Unable to delete protocol ${protocol.id}.`);
      }
      const projectsWithLocalChanges = await observableToPromise(this.syncStatusService.clientOrProjectsWithLocalChanges$);
      if (projectsWithLocalChanges.has(project.id) || projectsWithLocalChanges.has(undefined)) {
        await this.syncService.dataSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
      }
      await observableToPromise(this.http.post(this.getDeleteProtocolUrl(project.id, protocol.id), undefined));
      await this.syncService.dataSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
      await this.toastService.info('protocol.toast.deletedProtocol');
    } catch (error) {
      let errorMessage: string;
      if (error.status === 400) {
        const errorCode = error.error.errorCode;
        errorMessage = this.translateService.instant('protocol.toast.' + errorCode);
      } else {
        errorMessage = error?.message;
      }
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - deleteProtocol', errorMessage);
      await this.toastService.toastWithTranslateParams('protocol.toast.deletingProtocolFailed', {message: errorMessage}, ToastDurationInMs.ERROR);
    } finally {
      await deletionProgress.dismiss();
    }
  }

  private getDeleteProtocolUrl(projectId: IdType, protocolId: IdType) {
    return environment.serverUrl + `api/data/protocols/${projectId}/${protocolId}/delete`;
  }

  private async showProtocolDeletionProgress(): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create({
      backdropDismiss: false,
      keyboardClose: false,
      message: this.translateService.instant('protocol.status.deletingProtocol')
    });
    await alert.present();
    return alert;
  }

  /**
   * Checks if the procotol is able to be deleted.
   * Follows the rules described in BM2-382.
   *
   * @param protocol Protocol to check
   * @returns Observable, that emits to true, if deletion can be performed
   */
  public isDeletableStream(protocol: Protocol): Observable<boolean> {
    return combineLatest([
      this.protocolDataService.getById(protocol.id),
      this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(protocol.id)
    ]).pipe(map(([currentProtocol, currentEntries]) => {
      if (!currentProtocol) {
        return false;
      }
      // Don't allow to remove protocol, that has been already closed
      if (currentProtocol.closedAt !== null) {
        return false;
      }

      // Allow to remove continuous protocol, if it's just consisting of old entries
      if (currentEntries.every((entry) => entry.isOpenEntry)) {
        return true;
      }

      // Allow to remove protocol if it has no entries
      return currentEntries.length === 0;
    }));
  }

  /**
   * Checks if the procotol is able to be deleted.
   * Follows the rules described in BM2-382.
   *
   * @param protocol Protocol to check
   * @returns Promise, that resolves to true, if deletion can be performed
   */
  public isDeletable(protocol: Protocol): Promise<boolean> {
    return observableToPromise(
      this.isDeletableStream(protocol).pipe(take(1))
    );
  }
}
