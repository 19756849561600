import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {PdfPlansFilter} from 'src/app/model/pdf-plans-filter';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {haveObjectsEqualProperties} from 'src/app/utils/object-utils';
import {PlanAnalyticsService} from './plan-analytics.service';

export type ActiveSortType = 'name' | 'index' | 'date' | undefined;
export type ActiveSortDirectionType = 'asc' | 'desc';

const DEFAULT_PDF_PLANS_FILTER: PdfPlansFilter = {
  tags: [],
  locationIds: [],
  includeActive: true,
  includeInactive: true,
};

const compareFilters = (a: PdfPlansFilter, b: PdfPlansFilter): boolean => {
  const aTags = a.tags ?? [];
  const bTags = b.tags ?? [];
  if (aTags.length !== bTags.length) {
    return false;
  }
  if (aTags.length !== 0) {
    const aTagsSet = new Set(aTags.map(({id}) => id));
    if (!bTags.every(({id}) => aTagsSet.has(id))) {
      return false;
    }
  }
  const aLocationIds = (a.locationIds ?? []);
  const bLocationIds = (b.locationIds ?? []);
  if (aLocationIds.length !== bLocationIds.length) {
    return false;
  }
  if (!aLocationIds.every((id) => bLocationIds.includes(id))) {
    return false;
  }

  return haveObjectsEqualProperties(a, b, ['includeActive', 'includeInactive']);
};

@Injectable({
  providedIn: 'root'
})
export class PdfPlansFilterService {

  private sortSubject = new BehaviorSubject<ActiveSortType>(undefined);
  private sortDirectionSubject = new BehaviorSubject<ActiveSortDirectionType>('asc');
  private filterSubject = new BehaviorSubject<PdfPlansFilter>(DEFAULT_PDF_PLANS_FILTER);

  get sortDirection() {
    return this.sortDirectionSubject.value;
  }
  set sortDirection(sortDirection: ActiveSortDirectionType) {
    this.sortDirectionSubject.next(sortDirection);
  }
  sortDirection$ = this.sortDirectionSubject.asObservable();

  get sort() {
    return this.sortSubject.value;
  }
  set sort(sort: ActiveSortType) {
    if (this.sort === sort) {
      this.sortDirectionSubject.next(this.getNextDirection());
    } else {
      this.sortDirectionSubject.next('asc');
      this.sortSubject.next(sort);
    }
  }
  sort$ = this.sortSubject.asObservable();

  get filter() {
    return this.filterSubject.value;
  }
  set filter(filter: PdfPlansFilter) {
    this.planAnalyticsService.planFilterApplied({
      location: Boolean(filter.locationIds?.length),
      tags: Boolean(filter.tags?.length),
      active: filter.includeActive,
      inactive: filter.includeInactive,
    });
    this.filterSubject.next(filter);
  }
  filter$ = this.filterSubject.asObservable();

  hasFilter$ = this.filter$.pipe(
    map((filter) => !compareFilters(filter, DEFAULT_PDF_PLANS_FILTER))
  );

  sortAndDirectionAndFilter$ = combineLatestAsync([
    this.sort$,
    this.sortDirection$,
    this.filter$,
  ]);

  constructor(private planAnalyticsService: PlanAnalyticsService) { }

  private getNextDirection(): ActiveSortDirectionType {
    if (this.sortDirection === 'asc') {
      return 'desc';
    }

    return 'asc';
  }

  clear() {
    this.sort = undefined;
    this.filter = DEFAULT_PDF_PLANS_FILTER;
  }
}
