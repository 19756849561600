import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {ProtocolEntrySearchFilter} from 'src/app/model/protocol-entry-search-filter';
import {defaultDateComparator} from 'src/app/utils/filter-utils';
import {ProjectDataService} from '../data/project-data.service';
import _ from 'lodash';
import {AuthenticationService} from '../auth/authentication.service';

export const EMPTY_PROTOCOL_ENTRY_SEARCH_FILTER: ProtocolEntrySearchFilter = {
  project: {
    in: [],
  },
  protocol: {
    typeId: {
      in: [],
    },
  },
  entry: {
    companyId: {
      in: [],
    },
    craftId: {
      in: [],
    },
    createdAt: {
      gte: null,
      lte: null,
      comparator: defaultDateComparator,
    },
    internalAssignmentId: {
      in: [],
    },
    locationId: {
      in: [],
    },
    priority: {
      in: [],
    },
    status: {
      in: [],
    },
    typeId: {
      in: [],
    },
    nameableDropdownId: {
      in: [],
    },
    observerCompanies: {
      in: [],
    },
    todoUntil: {
      gte: null,
      lte: null,
      comparator: defaultDateComparator,
    },
    allCompanies: {
      in: [],
    },
  },
};

@Injectable({
  providedIn: 'root'
})
export class ProtocolEntrySearchFilterService {

  filtersSubject = new BehaviorSubject<ProtocolEntrySearchFilter>(_.cloneDeep(EMPTY_PROTOCOL_ENTRY_SEARCH_FILTER));

  filters$ = this.filtersSubject.asObservable();

  hasFilter$ = this.filters$.pipe(
    map((filter) => {
      const walkFn = ([key, obj]: [string, any]) => {
        if (Array.isArray(obj)) {
          return obj.length === 0;
        }
        if (obj === null) {
          return true;
        }
        if (obj instanceof Date) {
          return false;
        }
        if (typeof obj === 'object' && key !== 'eq') {
          return Object.entries(obj).every(walkFn);
        }
        if (key === 'comparator') {
          return true;
        }
        return false;
      };

      return !Object.entries(filter).every(walkFn);
    })
  );

  get filters(): ProtocolEntrySearchFilter {
    return this.filtersSubject.value;
  }

  constructor(private projectDataService: ProjectDataService, private authenticationService: AuthenticationService) {
    this.authenticationService.isAuthenticated$.pipe(distinctUntilChanged(_.isEqual)).subscribe((auth) => {
      if (!auth) {
        this.clearAllFilters();
      }
    });
  }

  async clearAllFilters() {
    this.filtersSubject.next(_.cloneDeep(EMPTY_PROTOCOL_ENTRY_SEARCH_FILTER));
  }

  async clearAllFiltersButProject() {
    const currentProject = await this.projectDataService.getCurrentProject();
    const project = _.cloneDeep(EMPTY_PROTOCOL_ENTRY_SEARCH_FILTER.project);
    if (currentProject) {
      project.in = [currentProject.id];
    }
    this.filtersSubject.next({..._.cloneDeep(EMPTY_PROTOCOL_ENTRY_SEARCH_FILTER), project});
  }
}
