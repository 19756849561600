<ion-grid>
  <ion-row>
    <ion-col class="wifi-icon"><fa-icon [icon]="['fal', 'wifi-slash']"></fa-icon></ion-col>
  </ion-row>
  <ion-row *ngIf="showTitle" class="title">
    <ion-col>{{'offlineInfo.title' | translate}}</ion-col>
  </ion-row>
  <ion-row *ngIf="!onlineOnlyText" class="description">
    <ion-col>{{'offlineInfo.description1' | translate}}</ion-col>
  </ion-row>
  <ion-row *ngIf="!onlineOnlyText" class="description">
    <ion-col>{{'offlineInfo.description2' | translate}}</ion-col>
  </ion-row>
  <ion-row *ngIf="onlineOnlyText" class="description">
    <ion-col>{{'offlineInfo.description3' | translate}}</ion-col>
  </ion-row>
  <ion-row *ngIf="showHint" class="hint-box ion-no-padding ion-margin-vertical">
    <ion-col size="1" class="d-flex ion-align-items-center ion-justify-content-center ion-no-padding ion-no-margin">
      <fa-icon class="hint-icon" [icon]="['fal', 'lightbulb-on']"></fa-icon>
    </ion-col>
    <ion-col size="11" class="text ion-no-padding ion-no-margin">
      <span class="hint-text">
        <span class="hint-hint">{{'offlineInfo.hint' | translate}}</span>
        {{'offlineInfo.hintText' | translate}}
      </span>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="showRetry">
    <ion-col>
      <ion-button class="margin-left-10 omg-btn-secondary" (click)="retry.emit()" size="medium" title="{{ 'project.label.archive' | translate }}">
        {{'offlineInfo.retryLabel' | translate}}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>