import packageJson from '../../package.json';
const {version} = packageJson;

export const environment = {
  name: 'staging',
  localhost: false,
  production: true,
  pwa: true,
  serverUrl: 'https://api.stage.bau-master.at/',
  logLevel: 'DEBUG',
  analyticsEnabled: true,
  userFlowToken: 'ct_zfve3hejvbak7bltmdywxbkiue',
  canduToken: 'tk9EvPHxvm',
  version,
  senderEmailAddress: 'app@bau-master.com',
  deepLinksDomains: [/https:\/\/baumaster-2edeb\.web\.app/, /https:\/\/web2\.stage\.bau-master\.com/],
  hasUiPage: true,
  registerLabel: 'LOGIN.request_account',
  registerLink: 'https://www.bau-master.com/registrierung/',
  posthogToken: 'phc_NRTE6fNSiYwTkEZSCrHp6Ccc1YJlP3W8phPD9fVRISH',
  posthogUrl: 'https://eu.posthog.com',
  canduAttachableNewsId: 'BM-Dashboard-content',
  supportPageUrl: 'https://bau-master.com/support/',
  helpPageUrl: 'https://helpdesk.bau-master.com/portal/de/home',
  termsOfUseUrl: 'https://bau-master.com/nutzungsbedingungen/',
  privacyPolicyUrl: 'https://bau-master.com/datenschutz/',
  licenseAssignHelpUrl: 'https://helpdesk.bau-master.com/portal/de/kb/articles/rechte-und-lizenzen-zuweisen',
  academyUrl: 'https://learn.bau-master.com/',
  hideBimSection: false,
  hideDailyConstructionRecord: false,
  totpIssuer: 'web2.stage.bau-master.com',
};

