import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IdType, ProtocolEntryCompany, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareMappingDataService} from './abstract-project-aware-mapping-data.service';
import {ProjectDataService} from './project-data.service';
import * as _ from 'lodash';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/protocolEntryCompanies';

@Injectable({
  providedIn: 'root'
})
export class ProtocolEntryCompanyDataService extends AbstractProjectAwareMappingDataService<ProtocolEntryCompany> {

  dataGroupedByEntryId: Observable<Record<IdType, ProtocolEntryCompany[]>> = this.data.pipe(map((companies) => _.groupBy(companies, 'protocolEntryId')));
  dataAcrossProjectsGroupedByEntryId: Observable<Record<IdType, ProtocolEntryCompany[]>> = this.dataAcrossProjects$.pipe(map((companies) => _.groupBy(companies, 'protocolEntryId')));

  constructor(http: HttpClient,
              storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.PROTOCOL_ENTRY_COMPANY, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  findAllByProtocolEntryIdAcrossProjects(entryId: IdType) {
    return this.findAllByProtocolEntryIds$([entryId], this.dataAcrossProjects$);
  }

  findAllByProtocolEntryId(entryId: IdType) {
    return this.findAllByProtocolEntryIds$([entryId], this.data);
  }

  findAllByProtocolEntryIdsAcrossProjects(entryIds: IdType[]) {
    return this.findAllByProtocolEntryIds$(entryIds, this.dataAcrossProjects$);
  }

  findAllByProtocolEntryIds(entryIds: IdType[]) {
    return this.findAllByProtocolEntryIds$(entryIds, this.data);
  }

  private findAllByProtocolEntryIds$(entryIds: IdType[], protocolEntryCompanies$: Observable<ProtocolEntryCompany[]>) {
    return protocolEntryCompanies$.pipe(
      map((protocolEntriesCompanies) => protocolEntriesCompanies.filter(({ protocolEntryId }) => entryIds.includes(protocolEntryId)))
    );
  }

}
