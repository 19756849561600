import { ColumnConfig } from '../ColumnConfig';

const UniqueCustomReportTypesInProjectUniq = ['project_id', 'custom_report_type_id'];

export const ProjectCustomReportTypeColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'project_id', prop: 'projectId', compositeKey: true, uniqueWith: [UniqueCustomReportTypesInProjectUniq]},
  {name: 'custom_report_type_id', prop: 'customReportTypeId', compositeKey: true, uniqueWith: [UniqueCustomReportTypesInProjectUniq]}
];
