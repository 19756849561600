import { ColumnConfig } from '../ColumnConfig';

const UniqueReportCompanyPerActivityUniq = ['report_company_id', 'activity_id'];

export const ReportCompanyActivityColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'report_company_id', prop: 'reportCompanyId', cnd: true, uniqueWith: [UniqueReportCompanyPerActivityUniq]},
  {name: 'activity_id', prop: 'activityId', cnd: true, uniqueWith: [UniqueReportCompanyPerActivityUniq]},
];
