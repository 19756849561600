<div
  class="tag-wrapper"
  [class.tag-selected]="selectedIds?.includes(tag.id) || false"
  *ngFor="let tag of displayTags; trackBy: trackById"
  (click)="interactive && tagClick.emit(tag)"
  (keydown)="handleKeyDown($event, tag)"
  [attr.role]="interactive ? 'listitem' : undefined"
>
  <ng-container *ngTemplateOutlet="startWrapperTemplate; context: {tag:tag}"></ng-container>
  <app-tag
    [tag]="tag"
    [startTemplate]="startTemplate"
    [endTemplate]="endTemplate"
    [attr.tabindex]="interactive ? -1 : undefined"
  ></app-tag>
  <ng-container *ngTemplateOutlet="endWrapperTemplate; context: {tag:tag}"></ng-container>
</div>
<app-tag *ngIf="showMoreTags" [tag]="showMoreTag"></app-tag>
