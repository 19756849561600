import {ColumnConfig} from '../ColumnConfig';

export const SyncPerformanceColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'sync_strategy', prop: 'syncStrategy'},
  {name: 'duration', prop: 'duration'},
  {name: 'errored', prop: 'errored'},
  {name: 'unresolved_conflicts', prop: 'unresolvedConflicts'},
  {name: 'conflicts', prop: 'conflicts'},
  {name: 'device_uuid', prop: 'deviceUuid'},
  {name: 'perf', prop: 'perf', cast: 'Jsonb', def: null},
  {name: 'created_by_id', prop: 'createdById'}
];
