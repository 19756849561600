import {Column, Content, ContentColumns, ContentImage, ContentSvg, ContentTable, Table, TableCell, TableLayout} from 'pdfmake/interfaces';
import _ from 'lodash';
import {ActivityWithLocation, Color, PdfReportGenerateData} from '../pdfReport.model';
import {PdfReportSendReq} from '../../../requestResponse';
import {Activity, ActivityTypeEnum, Attachment, AttachmentReportActivity, AttachmentReportEquipment, AttachmentReportMaterial, Equipment, IdType, Participant, PdfPreview, Profile, ProtocolEntryLocation, ReportTypeCode, Staff} from '../../../models';
import Translation from '../../../i18n/translation';
import {getFormattedNumber, NumberFormatTypes} from '../../../i18n/number-format';
import {PdfReportSvgIcons} from '../pdfReportSvgIcons';
import {canvasLine, formatDate, getWeekDay} from '../../protocol/content/abstractPdf.content';
import {CompanyWithParticipants, ParticipantsProfile} from '../../protocol/content/abstractProject.content';
import {SvgIcons} from '../../protocol/pdfSvgIcons';
import {AttachmentWithContent} from '../../protocol/pdfProtocol.model';
import {formatOneLineAddress, sanitizeBase64Image, wrappedOrEmpty} from '../../common-report-utils';
import {formatProjectNumberOptional} from '../../../commonUtils';

interface DetailsTableCellText {
    label: string;
    text: string;
}

interface DetailsTableCellCheckbox {
    label: string;
    checked: boolean;
}

type DetailsTableCell = DetailsTableCellText | DetailsTableCellCheckbox;

export abstract class AbstractReportContent {

    protected i18n: Map<string, string>|undefined;
    protected config: PdfReportSendReq;
    protected data: PdfReportGenerateData;
    protected pdfPreview: PdfPreview|undefined;
    protected lng: string;

    constructor(lng: string, config: PdfReportSendReq, data: PdfReportGenerateData, pdfPreview?: PdfPreview) {
        const translation = new Translation();
        this.lng = lng;
        this.i18n = translation.getTranslation(lng);
        this.data = data;
        this.config = config;
        this.pdfPreview = pdfPreview;
    }

    protected tblLayout: TableLayout = {
        hLineWidth(i, node) {
            return 0.5;
        },
        vLineWidth(i, node) {
            return 0.5;
        },
        hLineColor(i, node) {
            return '#B3B3B3';
        },
        vLineColor(i, node) {
            return '#B3B3B3';
        },
    };

    protected writePdfStartBanner(content: Content[]): void {
        const bannerContentBase64 = this.data.pdfProjectBanners?.get('pdf_start_banner')?.contentBase64 ?? this.data.attachmentClients?.get('pdfStartBanner')?.contentBase64;
        if (!_.isEmpty(bannerContentBase64)) {
            content.push({
                image: 'pdfStartBanner', fit: [515, 105]
            });
        }
    }

    // Adopted from writeCompanyInfo
    protected writeInvolved(content: Content[], {
        projectLeaderLabel = this.i18n?.get('project_leader')
    } = {}): void {

        const client = this.getValueNotNull(this.data.client, 'client');

        let projectLeaderAddress = client.name + '';

        // Do not include client's address, if it not exists
        if (this.data.client.addressId) {
            const clientAddressId = this.getValueNotNull(this.data.client.addressId, 'client.addressId');
            const clientAddress = this.getValueNotNull(this.data.lookup.addresses.get(clientAddressId), 'client address');

            if (clientAddress.street1 && clientAddress.street1 !== '') {
                projectLeaderAddress += '\n' + clientAddress.street1;
            }
            if (clientAddress.street2 && clientAddress.street2 !== '') {
                projectLeaderAddress += '\n' + clientAddress.street2;
            }
            if (clientAddress.zipCode && clientAddress.zipCode !== '' && clientAddress.city && clientAddress.city !== '') {
                projectLeaderAddress += '\n' + clientAddress.zipCode + ' ' + clientAddress.city;
            }
        }

        const contracteeName = this.sanitizeValue(this.data.project.contractee);
        const contracteeAddress = this.sanitizeValue(this.data.project.addressContractee);
        let customContracteeName = '';
        if ((!_.isEmpty(this.data.report.dependencyLink)) && this.data.report.dependencyLink) {
            customContracteeName = this.data.report.dependencyLink;
        }

        const tblContentInvolved: Table = {
            headerRows: 0,
            widths: [240, 10, 240],
            body: [
                [
                    {
                        margin: [5, 5, 0, 5],
                        columns: [
                            {
                                width: 62,
                                text: `${projectLeaderLabel}`,
                                style: ['font8', 'textBold', 'alignRight'],
                            },
                            {
                                text: projectLeaderAddress,
                                style: ['font8'],
                                margin: [7, 0, 0, 0],
                            }
                        ]
                    },
                    {
                        border: [false, false, false, false],
                        text: ''
                    },
                    {
                        margin: [0, 5, 0, 5],
                        columns: [
                            {
                                width: 60,
                                text: `${this.i18n?.get('client')}`,
                                style: ['font8', 'textBold', 'alignRight'],
                            },
                            {
                                text: _.isEmpty(customContracteeName) ? contracteeName + '\n' + contracteeAddress : customContracteeName,
                                style: ['font8'],
                                margin: [7, 0, 0, 0],
                            }
                        ]
                    },
                ],
            ]
        };

        content.push({
            layout: this.tblLayout,
            table: tblContentInvolved,
            margin: [0, 10, 0, 0],
        });
    }
    protected writeWeatherDirectedReport(content: Content[]): void{
        let weather = '';
        let temp = '';
        let humidity = '';
        let windspeed = '';
        if (this.getWeather(this.data.report.weather)) {
            weather += this.getWeather(this.data.report.weather);
        }

        if ((this.data.report.minTemp !== null && this.data.report.minTemp !== undefined) || (this.data.report.maxTemp !== null && this.data.report.maxTemp !== undefined)) {
            if ((this.data.report.minTemp !== null && this.data.report.minTemp !== undefined) && (this.data.report.maxTemp !== null && this.data.report.maxTemp !== undefined)) {
                temp += this.data.report.minTemp;
                temp += '° ' + this.i18n?.get('to') + ' ';
                temp += this.data.report.maxTemp;
            } else {
                temp += this.data.report.minTemp ? this.data.report.minTemp : this.data.report.maxTemp;
            }
            temp += '°';
        }
        if (this.data.report.humidity !== null && this.data.report.humidity !== undefined) {
            humidity += this.data.report.humidity;
            humidity += ' %';
        }
        if (this.data.report.windspeed !== null && this.data.report.windspeed !== undefined){
            windspeed += this.data.report.windspeed;
            windspeed += ' km/h';
        }
        const tblWeather: Table = {
            headerRows: 0,
            widths: [121, 121, 121, 121],
            body: [
                [
                    {
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],
                        columns: [
                            {
                                width: 121, 
                                text: [
                                    {
                                        text: `${this.i18n?.get('weather')}`,
                                        style: ['font7'],
                                    },
                                    {text: '\n', style: ['font7'], },
                                    {
                                        text: weather,
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            },
                            {
                                width: 121,
                                text: [
                                    {
                                        text: `${this.i18n?.get('temperature')}`,
                                        style: ['font7'],
                                    },
                                    {text: '\n', style: ['font7'], },
                                    {
                                        text: temp,
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            },
                            {
                                width: 121, 
                                text: [
                                    {
                                        text: `${this.i18n?.get('humidity')}`,
                                        style: ['font7'],
                                    },
                                    {text: '\n', style: ['font7'], },
                                    {
                                        text: humidity,
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            },
                            {
                                width: 121,
                                text: [
                                    {
                                        text: `${this.i18n?.get('windspeed')}`,
                                        style: ['font7'],
                                    },
                                    {text: '\n', style: ['font7'], },
                                    {
                                        text: windspeed,
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            }
                        ]
                    }
                ]
            ]
        };

        const tblBorder: Table = {
            headerRows: 0,
            widths: [507],
            body: [
                [
                    {
                        columns: [
                            {
                                margin: [0, 5, 0, 5],
                                table: tblWeather,
                                layout: {
                                    vLineWidth(i, node) {
                                        return 0.5;
                                    },
                                    vLineColor(i, node) {
                                        return Color.BLACK;
                                    },
                                },
                            }
                        ]
                    }
                ]
            ]
        };
        
        if(this.data.report.weather && this.data.report.minTemp && this.data.report.maxTemp){
            content.push({
                layout: this.tblLayout,
                table: tblBorder,
                margin: [0, 10, 0, 0],
            });
        }
    }

    protected writeWeather(content: Content[]): void {
        let weather = '';
        let temp = '';
        let humidity = '';
        let windspeed = '';
        if (this.getWeather(this.data.report.weather)) {
            weather += this.getWeather(this.data.report.weather);
        }

        if ((this.data.report.minTemp !== null && this.data.report.minTemp !== undefined) || (this.data.report.maxTemp !== null && this.data.report.maxTemp !== undefined)) {
            if ((this.data.report.minTemp !== null && this.data.report.minTemp !== undefined) && (this.data.report.maxTemp !== null && this.data.report.maxTemp !== undefined)) {
                temp += this.data.report.minTemp;
                temp += '° ' + this.i18n?.get('to') + ' ';
                temp += this.data.report.maxTemp;
            }
            else {
                temp += this.data.report.minTemp ? this.data.report.minTemp : this.data.report.maxTemp;
            }
            temp += '°';
        }
        if (this.data.report.humidity !== null && this.data.report.humidity !== undefined) {
            humidity += this.data.report.humidity;
            humidity += ' %';
        }
        if (this.data.report.windspeed !== null && this.data.report.windspeed !== undefined){
            windspeed += this.data.report.windspeed;
            windspeed += ' km/h';
        }

        let badWeather = '';
        if (this.data.report.badWeatherTime1From) {
            badWeather += this.getTime(this.data.report.badWeatherTime1From);
            badWeather += ' ' + this.i18n?.get('to') + ' ';
            badWeather += this.getTime(this.data.report.badWeatherTime1To);
            badWeather += '\n';
        }
        if (this.data.report.badWeatherTime2From) {
            badWeather += this.getTime(this.data.report.badWeatherTime2From);
            badWeather += ' ' + this.i18n?.get('to') + ' ';
            badWeather += this.getTime(this.data.report.badWeatherTime2To);
        }

        // WorkingHours
        let workingHours = '';
        if (this.data.report.workingTime1From) {
            workingHours += this.getTime(this.data.report.workingTime1From);
            workingHours += ' ' + this.i18n?.get('to') + ' ';
            workingHours += this.getTime(this.data.report.workingTime1To);
            workingHours += '\n';
        }
        if (this.data.report.workingTime2From) {
            workingHours += this.getTime(this.data.report.workingTime2From);
            workingHours += ' ' + this.i18n?.get('to') + ' ';
            workingHours += this.getTime(this.data.report.workingTime2To);
        }
        const reportDate = formatDate(this.data.report.date);

        const tblWeather: Table = {
            headerRows: 0,
            widths: [242, 121, 121],
            body: [
                [
                    {
                        border: [false, false, true, false],
                        margin: [0, 0, 0, 0],
                        columns: [
                            {
                                width: 60, 
                                text: [
                                    {
                                        text: `${this.i18n?.get('weather')}`,
                                        style: ['font7'],
                                    },
                                    {text: '\n', style: ['font7'], },
                                    {
                                        text: weather,
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            },
                            {
                                width: 60,
                                text: [
                                    {
                                        text: `${this.i18n?.get('temperature')}`,
                                        style: ['font7'],
                                    },
                                    {text: '\n', style: ['font7'], },
                                    {
                                        text: temp,
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            },
                            {
                                width: 60, 
                                text: [
                                    {
                                        text: `${this.i18n?.get('humidity')}`,
                                        style: ['font7'],
                                    },
                                    {text: '\n', style: ['font7'], },
                                    {
                                        text: humidity,
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            },
                            {
                                width: 60,
                                text: [
                                    {
                                        text: `${this.i18n?.get('windspeed')}`,
                                        style: ['font7'],
                                    },
                                    {text: '\n', style: ['font7'], },
                                    {
                                        text: windspeed,
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        border: [false, false, true, false],
                        margin: [5, 0, 0, 0],
                        columns: [
                            {
                                width: 20,
                                svg: this.changeSvgIconFill(PdfReportSvgIcons.clock, Color.BLACK),
                                fit: [12, 12],
                            },
                            {
                                text: [
                                    {
                                        text: `${this.i18n?.get('working_hours')}`,
                                        style: ['font7'],
                                    },
                                    {text: '\n', style: ['font7'], },
                                    {
                                        text: workingHours,
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        border: [false, false, false, false],
                        margin: [5, 0, 0, 0],
                        columns: [
                            {
                                width: 20,
                                svg: this.changeSvgIconFill(PdfReportSvgIcons.badWeather, Color.BLACK),
                                fit: [12, 12],
                            },
                            {
                                text: [
                                    {
                                        text: `${this.i18n?.get('bad_weather')}`,
                                        style: ['font7'],
                                    },
                                    {text: '\n', style: ['font7'], },
                                    {
                                        text: badWeather,
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            }
                        ]
                    },
                ]
            ]
        };

        const tblBorder: Table = {
            headerRows: 0,
            widths: [507],
            body: [
                [
                    {
                        columns: [
                            {
                                margin: [0, 5, 0, 5],
                                table: tblWeather,
                                layout: {
                                    vLineWidth(i, node) {
                                        return 0.5;
                                    },
                                    vLineColor(i, node) {
                                        return Color.BLACK;
                                    },
                                },
                            }
                        ]
                    }
                ]
            ]
        };

        content.push({
            layout: this.tblLayout,
            table: tblBorder,
            margin: [0, 10, 0, 0],
        });
    }

    protected getDateColumns(reportDate: string): Column[] {
        const reportDay = this.convertNumberToWeekDay(getWeekDay(this.data.report.date));
        return [
            {
                width: 20,
                svg: this.changeSvgIconFill(PdfReportSvgIcons.calendar, Color.BLACK),
                fit: [12, 12],
            },
            {
                text: [
                    {
                        text: `${this.i18n?.get('date')}`,
                        style: ['font7'],
                    },
                    {text: '\n', style: ['font7'], },
                    {
                        text: reportDay ? reportDay + ' ' + reportDate : reportDate,
                        style: ['font8', 'textBold'],
                    },
                ]
            }
        ];
    }

    private getEditorName() {
        const editorProfile = this.data.lookup.profiles.get(this.data.report.editorId || '');
        if (editorProfile) {
            const editorAddress = this.data.lookup.addresses.get(editorProfile.addressId || '');
            if (editorAddress) {
                return editorAddress.firstName + ' ' + editorAddress.lastName;
            }
        }

        return '';
    }

    private getEmployerName() {
        const profile = this.data.lookup.profiles.get(this.data.report.employerId || '');
        if (profile) {
            const address = this.data.lookup.addresses.get(profile.addressId || '');
            if (address) {
                return address.firstName + ' ' + address.lastName;
            }
        }
        return '';
    }

    protected writeProject(content: Content[], {
        includeCreatedBy = true,
        includeTitle = true,
    } = {}): void {
        const reportType = this.data.lookup.reportTypes.get(this.data.reportWeek.typeId);
        if (!reportType) {
            throw new Error(`Unable to find reportType with id ${this.data.reportWeek.typeId}.`);
        }
        const reportTitleLabel = `${this.i18n?.get(`reportTypeCode.${reportType.name}`)} | Nr. ${this.i18n?.get(`reportTypeCodeAbbreviation.${reportType.name}`)}`;

        const editorName = this.getEditorName();
        const reportDate = formatDate(this.data.report.date);

        let projectAddress = '';
        if (this.data.project.addressSite && !this.data.project.addressId) {
            projectAddress = this.data.project.addressSite;
        } else {
            projectAddress = formatOneLineAddress(this.data.projectAddress);
        }
        content.push(
            {
                text: `${formatProjectNumberOptional(this.data.project.number)} - ${this.sanitizeValue(this.data.project.name)}`,
                style: ['font14', 'fontColorGray', 'marginTop5'],
            },
            {
                text: `${this.sanitizeValue(projectAddress)}`,
                style: 'font8',
            },
            {
                text: `${reportTitleLabel} ${this.data.report.reportNumber}`,
                style: ['textBold', 'font14', 'marginTop5', 'fontColorGray'],
            },
        );

        if (includeCreatedBy) {
            content.push({
                text: [
                    {
                        text: `${this.i18n?.get('created_by')}: `,
                        style: ['textBold', 'font8'],
                    },
                    {
                        text: editorName,
                        style: ['font8'],
                    }
                ],
            });
        }

        if (includeTitle) {
            content.push(
                {
                    margin: [0, 5, 0, 0],
                    columns: this.data.report.title ? [
                        {
                            width: 10,
                            svg: this.changeSvgIconFill(PdfReportSvgIcons.paper, Color.BLUE),
                            fit: [8, 8],
                        },
                        {
                            text: [
                                {
                                    text: this.sanitizeValue(this.data.report.title),
                                    style: ['font8'],
                                },
                            ]
                        },
                        {
                            width: 'auto',
                            margin: [0, 0, 13, 0],
                            columns: this.getDateColumns(reportDate)
                        }
                    ] : 
                    [
                        {
                            text: '',
                        },
                        {
                            width: 'auto',
                            margin: [0, 0, 13, 0],
                            columns: this.getDateColumns(reportDate)
                        }
                    ]
                }
            );
        }

    }

    protected writeProjectWithDate(content: Content[]): void {
        const projectColumn: Column = {
            width: '*',
            stack: []
        };
        const projectAndDateRow: Content = {
            margin: [0, 10, 0, 0],
            columns: [
                projectColumn,
                {
                    width: 'auto',
                    margin: [0, 14, 13, 0],
                    columns: this.getDateColumns(formatDate(this.data.report.date))
                }
            ],
        };
        this.writeProject(projectColumn.stack, {
            includeCreatedBy: false,
            includeTitle: false
        });
        content.push(projectAndDateRow);
    }

    private isDetailsTableCellCheckbox(detail: DetailsTableCell): detail is DetailsTableCellCheckbox {
        return 'checked' in detail;
    }

    private isDetailsTableCellText(detail: DetailsTableCell): detail is DetailsTableCellText {
        return 'text' in detail;
    }

    private createDetailsTableCell(detail: DetailsTableCell): Content[] {
        const breakLine = {text: '\n', style: ['font7'], };
        if (this.isDetailsTableCellCheckbox(detail)) {
            return [{style: ['marginTop5'], columns: [
                {
                    margin: [0, 0, 10, 0],
                    columns: [
                        {
                            margin: [0, 2, 0, 0],
                            text: detail.label,
                            style: ['font7', 'textBold'],
                            width: '*'
                        },
                        {
                            style: ['marginLeft10'],
                            svg: detail.checked ? PdfReportSvgIcons.blackCheckboxCheck : PdfReportSvgIcons.blackCheckboxUncheck,
                            width: 12,
                            fit: [12, 12]
                        },
                    ]
                },
            ]}, breakLine];
        }

        if (this.isDetailsTableCellText(detail)) {
            return [{text: [
                {
                    text: detail.label,
                    style: ['font7', 'textBold'],
                },
                breakLine,
                {
                    text: detail.text,
                    style: ['font8'],
                },
            ]}, breakLine];
        }

        throw new Error(`Unknown DetailTableCell ${Object.keys(detail)}`);
    }

    private createDetailsTableColumn(details: DetailsTableCell[], {
        isFirst = false,
        isLast = false,
    } = {}): TableCell {
        const rows = details.reduce<Content[]>((acc, detail) => acc.concat(this.createDetailsTableCell(detail)), []).slice(0, -1);

        return {
            border: [false, false, !isLast, false],
            margin: [isFirst ? 0 : 5, 0, 0, 0],
            columns: [rows]
        };
    }

    private createDetailsTable(detailsInColumns: DetailsTableCell[][]): ContentTable {
        const numberOfColumns = detailsInColumns.length;
        const widths = Array(numberOfColumns).fill(Math.floor(464 / numberOfColumns));

        const innerTable: Table = {
            headerRows: 0,
            widths,
            body: [
                detailsInColumns.map((details, index, arr) => this.createDetailsTableColumn(details, {
                    isFirst: index === 0,
                    isLast: index === arr.length - 1,
                }))
            ]
        };

        const outerTable: Table = {
            headerRows: 0,
            widths: [507],
            body: [[{
                columns: [
                    {
                        margin: [0, 5, 0, 5],
                        table: innerTable,
                        layout: {
                            vLineWidth() {
                                return 0.5;
                            },
                            vLineColor() {
                                return Color.BLACK;
                            },
                        },
                    }
                ]
            }]]
        };

        return {
            layout: this.tblLayout,
            table: outerTable,
            margin: [0, 10],
        };
    }

    protected writeDirectedReportSummary(content: Content[]): void {
        const createdByLabel = `${this.i18n?.get('created_by')}:`;
        const titleLabel = `${this.i18n?.get('report_phase')}:`;
        const clientLabel = `${this.i18n?.get('client_directed')}:`;
        const craftLabel = `${this.i18n?.get('craft')}:`;
        const internalNumberLabel = `${this.i18n?.get('internal_number')}:`;
        const hasDamageLabel = `${this.i18n?.get('has_damage')}:`;

        const createdBy = this.getEditorName();
        const title = this.sanitizeValue(this.data.report.title);
        const client = this.getEmployerName();
        const craft = this.sanitizeValue(this.data.lookup.crafts.get(this.data.report.craftId ?? '')?.name);
        const internalNumber = this.sanitizeValue(this.data.report.internalNumber);
        const hasDamage = this.data.report.hasDamage ?? false;

        content.push(this.createDetailsTable([
            [
                { label: createdByLabel, text: createdBy, },
                { label: titleLabel, text: title, },
            ],
            [
                { label: clientLabel, text: client, },
                { label: craftLabel, text: craft, },
            ],
            [
                { label: internalNumberLabel, text: internalNumber, },
                { label: hasDamageLabel, checked: hasDamage, },
            ],
        ]));
    }

    protected writeParticipantsHeader(content: Content[]): void {
        if (this.data.participants.some(participant => participant.mailingList || participant.present)) {
            content.push({
                style: ['marginTop10'],
                columns: [
                    {
                        columns: [
                            {
                                svg: this.changeSvgIconFill(PdfReportSvgIcons.participants, Color.BLUE),
                                fit: [8, 8],
                            }
                        ],
                        width: 'auto'
                    },
                    {
                        text: `${this.i18n?.get('recipients')}`,
                        width: '*',
                        style: ['font8', 'marginLeft2', 'fontColorBlue', 'textBold'],
                    }
                ]
            });
            this.writeLine(content);
        }
    }

    protected writeParticipants(content: Content[]): void {
        const companyWithParticipants: CompanyWithParticipants[] = [];
        const participantsInMailingList = this.data.participants.filter((participant) => participant.mailingList);
        for (const participant of participantsInMailingList) {

            const profile = this.data.lookup.profiles.get(participant.profileId);
            if (profile) {
                const participantsProfile = this.getParticipantsProfile(profile, participant);
                if (participantsProfile) {
                    const participantCompany = this.data.lookup.companies.get(profile.companyId);
                    if (participantCompany) {
                        const companyWithParticipantsIndex = _.findIndex(companyWithParticipants, (companyWithParticipant) => companyWithParticipant.company.id === participantCompany?.id);
                        if (companyWithParticipantsIndex !== -1) {
                            companyWithParticipants[companyWithParticipantsIndex].participants.push(participantsProfile);
                        } else if (companyWithParticipantsIndex === -1) {
                            companyWithParticipants.push({
                                companyName: participantCompany.name,
                                company: participantCompany,
                                participants: [participantsProfile]
                            });
                        }
                    }
                }
            }
        }

        if (!_.isEmpty(participantsInMailingList)) {
            content.push(
                {
                    columns: [
                        {
                            text: `${this.i18n?.get('name_without_degree')}`,
                            width: 144,
                            style: ['font8', 'textBold', 'margin5']
                        },
                        {text: `${this.i18n?.get('craft')}`, width: 100, style: ['font8', 'textBold', 'margin5']},
                        {text: `${this.i18n?.get('phone')}`, width: 86, style: ['font8', 'textBold', 'margin5']},
                        {text: `${this.i18n?.get('email')}`, width: 128, style: ['font8', 'textBold', 'margin5']},
                        {text: `${this.i18n?.get('rec')}`, width: 32, style: ['font8', 'textBold', 'margin5']},
                    ]
                }
            );
        }

        for (const companyParticipants of companyWithParticipants) {
            content.push({
                margin: [0, 5, 0, 0],
                text: companyParticipants.companyName,
                style: ['participantCompany', 'textBold', 'font8']
            });
            for (const participantProfile of this.sortParticipantsProfiles(companyParticipants.participants)) {
                content.push({
                    columns: [
                        // tslint:disable-next-line:max-line-length
                        { text: `${this.sanitizeValue(participantProfile.address?.firstName)} ${this.sanitizeValue(participantProfile.address?.lastName)} `, width: 144, style: ['font8', 'participant'] },
                        { text: `${this.sanitizeValue(participantProfile.craft?.name)}`, width: 100, style: ['font8', 'participant'] },
                        { text: `${this.getParticipantPhone(participantProfile)}`, width: 86, style: ['font8', 'fontColorBlue', 'participant'] },
                        { text: `${this.getParticipantEmail(participantProfile)}`, link: `mailto: ${this.sanitizeValue(participantProfile.address?.email)}`, width: 128, style: ['font8', 'fontColorBlue', 'participant'] },
                        {
                            width: 32,
                            style: ['participant'],
                            columns: [
                                {
                                    svg: participantProfile.mailingList ? PdfReportSvgIcons.blackCheckboxCheck : PdfReportSvgIcons.blackCheckboxUncheck, width: 12
                                }
                            ]
                        },
                    ]
                });
                this.writeLine(content, undefined, 2);
            }
        }
    }

    private writeTableHeader(content: Content[], icon: PdfReportSvgIcons, title: string | undefined, width: number | undefined | '*', columns: {
        text: string | undefined;
        width: number | undefined | '*'
    }[]) {
        content.push({
            style: ['marginTop20'],
            columns: [
                {
                    columns: [
                        {
                            svg: this.changeSvgIconFill(icon, Color.BLUE),
                            fit: [8, 8],
                            width: 8,
                            style: ['marginTop1']

                        },
                        {
                            text: this.sanitizeValue(title),
                            width: '*',
                            style: ['font8', 'fontColorBlue', 'textBold', 'marginLeft2'],

                        }
                    ],
                    width,
                },
                ...columns.map((column) => ({
                    ...column,
                    text: this.sanitizeValue(column.text),
                    style: ['font8', 'textBold'],
                }))
            ]
        });
        this.writeLine(content);
    }

    private writeTableRow(content: Content[], columns: {
        text: string;
        width: number | undefined | '*'
    }[]) {
        content.push({
            margin: [0, 5],
            columns: columns.map((column, index) => ({
                ...column,
                style: ['font8', ...(index === 0 ? ['marginLeft10'] : [])],
            }))
        });
    }

    private writeTableAttachments(
        content: Content[],
        attachments: AttachmentWithContent<Attachment>[]
    ) {
        const attachmentsOrderedByNewest = _.orderBy(attachments, [(v) => v.attachment.createdAt, (v) => v.attachment.id], ['asc', 'asc']);

        const chunkedAttachments = _.chunk(attachmentsOrderedByNewest, 3);

        const chunkAttachmentsColumn: Column[] = [];

        for (const attachmentsChunk of chunkedAttachments) {
            const attachmentColumns = new Array<ContentSvg|ContentImage>();
            for (const attachment of attachmentsChunk) {
                if (this.isImage(attachment.attachment.mimeType)) {
                    if (attachment.contentBase64) {
                        attachmentColumns.push({
                            image: this.sanitizeBase64Image(attachment.contentBase64),
                            fit: [152, 100],
                            style: ['alignCenter'],
                        });
                    } else {
                        attachmentColumns.push({
                            svg: SvgIcons.noImagePlaceholder,
                            fit: [152, 100],
                            style: ['alignCenter'],
                        });
                    }

                }
            }

            chunkAttachmentsColumn.push({
                columns: attachmentColumns,
                style: ['marginTop5']
            });
        }

        content.push(...chunkAttachmentsColumn);
    }

    private writeTableContent<T extends string>(
        content: Content[],
        columns: {
            field: T,
            width: number | undefined | '*'
        }[],
        data: (Record<T, string> & { attachments?: AttachmentWithContent<Attachment>[]; showTimeBlocks?: boolean; timeBlocksString?: string })[]
    ) {
        data.forEach((item) => {
            this.writeTableRow(content, columns.map((column) => ({
                text: item[column.field],
                width: column.width,
            })));
            if (item.attachments) {
                this.writeTableAttachments(content, item.attachments);
            }
            if (item.showTimeBlocks && item.timeBlocksString) {
                content.push({ text: `(${this.i18n?.get('workingHours') ?? ''} ${item.timeBlocksString})`, style: ['font8', 'fontColorGray'], margin: [170, 0, 0, 3] });
            }
            this.writeLine(content, undefined, 2);
        });
    }

    protected writeStaffsHeader(content: Content[]): void {
        if (!_.isEmpty(this.data.staffs)) {
            this.writeTableHeader(content, PdfReportSvgIcons.hardhatPerson, this.i18n?.get('staffs_header'), 170, [
                {text: this.i18n?.get('amount'), width: 100},
                {text: this.i18n?.get('hours'), width: 120},
                {text: this.i18n?.get('total_hours'), width: 100},
            ]);
        }
    }

    protected writeStaffs(content: Content[]): void {
        if (!this.data.staffs) {
            return;
        }

        const staffs = this.data.staffs.map((staff) => {
            const count = isNaN(parseFloat(`${staff.count}`)) ? undefined : parseFloat(`${staff.count}`);
            const hours = isNaN(parseFloat(`${staff.hours}`)) ? undefined : parseFloat(`${staff.hours}`);
            return ({
                type: this.sanitizeValue(this.data.lookup.staffingTypes.get(staff.staffingTypeId ?? '')?.name),
                count: `${this.formattedNumber(count, 'integer')}x`,
                hours: this.formattedNumber(hours),
                sum: count && hours ? this.formattedNumber(count * hours) : '',
                showTimeBlocks: staff.useTimeBlocks && this.config.showTimeBlocks,
                timeBlocksString: this.getTimeBlocksString(staff)
            });
        });
        const summaryRow = this.data.staffs.reduce((acc, staff) => {
            const count = isNaN(parseFloat(`${staff.count}`)) ? undefined : parseFloat(`${staff.count}`);
            const hours = isNaN(parseFloat(`${staff.hours}`)) ? undefined : parseFloat(`${staff.hours}`);
            return ({
                count: (count ?? 0) + acc.count,
                hours: (hours ?? 0) + acc.hours,
                sum: (count ?? 0) * (hours ?? 0) + acc.sum,
            });
        }, {
            count: 0,
            hours: 0,
            sum: 0,
        });

        this.writeTableContent(content, [
            {
                field: 'type',
                width: 170
            },
            {
                field: 'count',
                width: 100
            },
            {
                field: 'hours',
                width: 120
            },
            {
                field: 'sum',
                width: 100
            }
        ], staffs);

        this.writeSummaryRow(content, summaryRow.count, summaryRow.hours, summaryRow.sum, {skipHoursPerEmployeeColumn: true});
    }

    protected writeEmployeesHeader(content: Content[]): void {
        this.writeTableHeader(content, PdfReportSvgIcons.hardhatPerson, this.i18n?.get('employees_header'), 160, [
            {text: this.i18n?.get('staffing_type'), width: 110},
            {text: this.i18n?.get('bonus_or_overtime'), width: 140},
            {text: this.i18n?.get('working_hours_header'), width: 80},
        ]);
    }

    protected writeEmployees(content: Content[]): void {
        if (!this.data.employees) {
            return;
        }

        const employees = this.data.employees.map((employee) => {
            const hours = isNaN(parseFloat(`${employee.hours}`)) ? undefined : parseFloat(`${employee.hours}`);
            const staffingType = Array.from(this.data.lookup.staffingTypes.values())
                .find((theStaffingType) => theStaffingType.code === employee.displayGroup);
            return ({
                name: this.sanitizeValue(employee.displayEmployerName),
                type: this.sanitizeValue(staffingType?.name),
                payType: this.sanitizeValue(this.data.lookup.additionalPayTypes.get(employee.additionalPayTypeId ?? '')?.name),
                hours: this.formattedNumber(hours),
            });
        });
        const summaryRow = this.data.employees.reduce((acc, employee) => {
            const hours = isNaN(parseFloat(`${employee.hours}`)) ? undefined : parseFloat(`${employee.hours}`);
            return ({
                count: 1 + acc.count,
                sum: (hours ?? 0) + acc.sum,
            });
        }, {
            count: 0,
            sum: 0,
        });

        this.writeTableContent(content, [
            {
                field: 'name',
                width: 160
            },
            {
                field: 'type',
                width: 110
            },
            {
                field: 'payType',
                width: 140
            },
            {
                field: 'hours',
                width: 80
            }
        ], employees);

        this.writeSummaryRow(content, summaryRow.count, 0, summaryRow.sum, { skipHoursPerEmployeeColumn: true });
    }

    protected writeMaterialsHeader(content: Content[]): void {
        if (!_.isEmpty(this.data.materials)) {
            this.writeTableHeader(content, PdfReportSvgIcons.wall, this.i18n?.get('material'), 170, [
                {text: this.i18n?.get('amount'), width: 100},
                {text: this.i18n?.get('supplier'), width: 120},
                {text: this.i18n?.get('delivery_number'), width: 80},
            ]);
        }
    }

    protected writeMaterials(content: Content[]): void {
        if (!this.data.materials) {
            return;
        }

        const materials = this.data.materials.map((material) => {
            return ({
                material: this.sanitizeValue(material.description),
                amount: `${this.formattedNumber(material.count, 'hours')} ${this.sanitizeValue(material.unit)}`,
                supplier: this.sanitizeValue(material.supplier),
                invoice: this.sanitizeValue(material.deliveryNumber),
                attachments: this.data.attachmentReportMaterials?.filter((attachment) => attachment.attachment.materialId === material.id),
            });
        });

        this.writeTableContent(content, [
            {
                field: 'material',
                width: 170
            },
            {
                field: 'amount',
                width: 100
            },
            {
                field: 'supplier',
                width: 120
            },
            {
                field: 'invoice',
                width: 80
            },
        ], materials);
    }

    protected writeEquipmentsHeader(content: Content[]): void {
        if (!_.isEmpty(this.data.equipments)) {
            this.writeTableHeader(content, PdfReportSvgIcons.shovel, this.i18n?.get('equipment'), 170, [
                {text: this.i18n?.get('amount'), width: 240},
            ]);
        }
    }

    private getAmount(equipment: Equipment): string {
        if (!equipment.count && !equipment.hours) {
            return '';
        }
        if (equipment.count && !equipment.hours) {
            return `${this.formattedNumber(equipment.count, 'hours')}x`;
        }
        if (!equipment.count && equipment.hours) {
            return `${this.formattedNumber(equipment.hours)} Std`;
        }
        return `${this.formattedNumber(equipment.count, 'hours')} x ${this.formattedNumber(equipment.hours)} Std`;
    }

    protected writeEquipments(content: Content[]): void {
        if (!this.data.equipments) {
            return;
        }

        const equipments = this.data.equipments.map((equipment) => {

            return ({
                equipment: this.sanitizeValue(equipment.equipmentItemDisplay),
                amount: this.getAmount(equipment),
                attachments: this.data.attachmentReportEquipments?.filter((attachment) => attachment.attachment.equipmentId === equipment.id),
            });
        });

        this.writeTableContent(content, [
            {
                field: 'equipment',
                width: 170
            },
            {
                field: 'amount',
                width: 240
            },
        ], equipments);
    }

    protected writeActivitiesHeader(content: Content[]): void {
        if (!_.isEmpty(this.data.activities)) {
            this.writeTableHeader(content, PdfReportSvgIcons.paper, this.i18n?.get('activities'), 270, [
                {text: this.i18n?.get('hours'), width: 120},
                {text: this.i18n?.get('location'), width: 90},
            ]);
        }
    }

    protected writeActivities(content: Content[]): void {
        if (!this.data.activities) {
            return;
        }

        const reportActivityIds = new Set(this.data.reportCompanyActivities.map((a) => a.activityId));

        const activities = this.data.activities.filter((activity) => !reportActivityIds.has(activity.id) && activity.type !== ActivityTypeEnum.SPECIAL_OCCURRENCE).map((activity) => {
            const hours = isNaN(parseFloat(`${activity.hours}`)) ? undefined : parseFloat(`${activity.hours}`);
            return ({
                activity: this.sanitizeValue(activity.description),
                hours: this.formattedNumber(hours),
                location: this.sanitizeValue(this.data.lookup.protocolEntryLocations.get(activity.locationId ?? '')?.location),
                attachments: this.data.attachmentReportActivities?.filter((attachment) => attachment.attachment.activityId === activity.id),
            });
        });

        this.writeTableContent(content, [
            {
                field: 'activity',
                width: 270
            },
            {
                field: 'hours',
                width: 120
            },
            {
                field: 'location',
                width: 90
            },
        ], activities);
    }

    protected writeCompaniesHeader(content: Content[]): void {
        content.push({
            style: ['marginTop20'],
            columns: [
                {
                    columns: [
                        {
                            svg: this.changeSvgIconFill(PdfReportSvgIcons.paper, Color.BLUE),
                            fit: [12, 12],
                        }
                    ],
                    width: 'auto'
                },
                {
                    text: `${this.i18n?.get('active_companies')}`,
                    width: '*',
                    style: ['font8', 'marginLeft10', 'fontColorBlue', 'textBold'],
                }
            ]
        });
        this.writeLine(content);
    }

    protected writeCompanies(content: Content[]): void {
        const companyTblLayout: TableLayout = this.getCompanyTableLayout();

        for (const [reportCompanyId, reportCompany] of this.data.reportCompanies.entries()) {

            const companyActivities = this.data.reportCompanyActivities.filter(activity => activity.reportCompanyId === reportCompany.id);
            const companyAttachments = this.data.attachmentReportCompanies.filter(attachmentCompany => attachmentCompany.attachment.reportCompanyId === reportCompany.id);

            const allAttachmentsColumn = new Array<ContentSvg|ContentImage|ContentColumns>();
            const attachmentsOrderedByNewest = _.orderBy(companyAttachments, [(v) => v.attachment.createdAt, (v) => v.attachment.id], ['asc', 'asc']);

            const chunkCompanyAttachments = _.chunk(attachmentsOrderedByNewest, 3);

            const chunkAttachmentsColumn = new Array<ContentSvg|ContentImage|ContentColumns>();

            for (const attachments of chunkCompanyAttachments) {

                const attachmentColumns = new Array<ContentSvg|ContentImage>();
                for (const attachment of attachments) {
                    if (this.isImage(attachment.attachment.mimeType)) {
                        if (attachment.contentBase64) {
                            attachmentColumns.push({
                                image: this.sanitizeBase64Image(attachment.contentBase64),
                                fit: [152, 100],
                                style: ['alignCenter'],
                            });
                        } else {
                            attachmentColumns.push({
                                svg: attachment.contentBase64 ? '' : SvgIcons.noImagePlaceholder,
                                fit: [152, 100],
                                style: ['alignCenter'],
                            });
                        }

                    }
                }

                chunkAttachmentsColumn.push({
                    columns: attachmentColumns,
                    style: ['marginTop5']
                });
            }

            allAttachmentsColumn.push({
                columns: [chunkAttachmentsColumn],
                style: ['marginTop5']
            });

            const companyName = this.data.lookup.companies.get(reportCompany.companyId)?.name ?? ' ';
            const sumEmployees = reportCompany.numberOfPersons;
            const hoursPerEmployee = reportCompany.workingHours;
            const sumHours = sumEmployees * hoursPerEmployee;

            const companyTbl: Table = {
                headerRows: 0,
                widths: ['*'],
                body: [
                    [
                        { // col
                            fillColor: Color.VERY_LIGHT_GRAY,
                            margin: [0, 3, 0, 3],
                            text: [
                                {
                                    text: companyName,
                                    style: ['font8', 'textBold', 'alignCenter'],
                                },
                            ]
                        }
                    ],
                ]
            };
            content.push({
                table: companyTbl,
                layout: companyTblLayout,
                margin: [0, 10, 0, 0],
            });

            this.writeSummaryRow(content, sumEmployees, hoursPerEmployee, sumHours, { companyTblLayout });

            content.push({
                text: `${this.i18n?.get('implementation')}:`,
                style: ['font8', 'textBold'],
                margin: [0, 0, 0, 3],
            });

            const filteredActivities = this.data.activities.filter((activity) => companyActivities.some((reportCompanyActivity) => reportCompanyActivity.activityId === activity.id));
            for (const activity of filteredActivities) {
                if (activity?.type === ActivityTypeEnum.ACTIVITY) {
                    const activityDescription = this.sanitizeValue(activity.description);

                    const locationName: string = this.getLocationNameForActivity(activity, this.data.lookup.protocolEntryLocations);
                    const activityWithLocation: ActivityWithLocation = {...activity, ...{locationName}};

                    let activityLocationName = '';
                    if (activityWithLocation.locationName) {
                         activityLocationName = this.i18n?.get('location') + ' ' + activityWithLocation.locationName;
                    }

                    content.push({
                        columns: [
                            {
                                text: activityDescription,
                                width: '*',
                                style: ['font7', 'alignLeft'],

                            },
                            {
                                text: activityLocationName,
                                width: '200',
                                style: ['font7', 'alignRight'],
                            }
                        ]
                    });
                }
            }

            content.push({
                columns: allAttachmentsColumn,
            });
        }
    }

    private getCompanyTableLayout(): TableLayout {
        return {
            hLineWidth(i, node) {
                return 0;
            },
            vLineWidth(i, node) {
                return 0;
            },
            defaultBorder: false,
        };
    }

    protected writeSummaryRow(content: Content[], sumEmployees: number|null|undefined, hoursPerEmployee: number|null|undefined, sumHours: number, {
        companyTblLayout = this.getCompanyTableLayout(),
        skipHoursPerEmployeeColumn = false
    } = {}) {
        const sumEmployeesProvided = sumEmployees !== null && sumEmployees !== undefined;
        const hoursPerEmployeeProvided = hoursPerEmployee !== null && hoursPerEmployee !== undefined;
        const widths = ['*', 100, 100, 100, '*'];
        let employeeSumMargin = 10;
        let hourSumMargin = 0;
        if (!sumEmployeesProvided && !hoursPerEmployeeProvided) {
            widths.splice(1, 3);
        } else if (skipHoursPerEmployeeColumn) {
            widths.splice(2, 1);
            const reportTypeCode = this.data.lookup.reportTypes.get(this.data.reportWeek.typeId)?.name;
            if (reportTypeCode === ReportTypeCode.REPORT_TYPE_DIRECTED_REPORT) {
                employeeSumMargin = -12;
                hourSumMargin = 116;
            } else {
                employeeSumMargin = -67;
                hourSumMargin = 123;
            }
        }
        const tblSums: Table = {
            headerRows: 0,
            widths,
            body: [
                [
                    {
                        text: ' ',
                        style: ['font7'],
                    },
                    {
                        columns: [
                            {
                                width: 12,
                                svg: this.changeSvgIconFill(PdfReportSvgIcons.people, Color.BLUE),
                                fit: [11, 8],
                            },
                            {
                                text: [
                                    {
                                        text: `${this.i18n?.get('employees')} `,
                                        style: ['font7'],
                                    },
                                    {
                                        text: sumEmployees ?? 0,
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        columns: (sumEmployeesProvided && !hoursPerEmployeeProvided) ? [] : [
                            {
                                width: 20,
                                svg: this.changeSvgIconFill(PdfReportSvgIcons.clock, Color.BLUE),
                                fit: [12, 12],
                            },
                            {
                                text: [
                                    {
                                        text: `x ${this.i18n?.get('per')} `,
                                        style: ['font7'],
                                    },
                                    {
                                        text: (hoursPerEmployee ?? 0) + 'h',
                                        style: ['font8', 'textBold'],
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        columns: (sumEmployeesProvided && !hoursPerEmployeeProvided) ? [] :  [
                            {
                                width: 11,
                                svg: this.changeSvgIconFill(PdfReportSvgIcons.sum, Color.BLUE),
                                fit: [9, 8],
                            },
                            {
                                text: [
                                    {
                                        text: `${this.i18n?.get('sum')} `,
                                        style: ['font7'],
                                    },
                                    {
                                        text: sumHours + 'h',
                                        style: ['font8', 'textBold'],
                                    },
                                ], width: 100,
                            }
                        ],
                        margin: [hourSumMargin, 0, 0, 0]
                    },
                    {
                        text: ' ',
                        style: ['font7'],
                    },
                ]
            ]
        };
        if (!sumEmployeesProvided && !hoursPerEmployeeProvided) {
            tblSums.body[0].splice(1, 3);
        } else if (skipHoursPerEmployeeColumn) {
            tblSums.body[0].splice(2, 1);
        }
        content.push({
            table: tblSums,
            layout: companyTblLayout,
            margin: [employeeSumMargin, 5, 0, 0],
        });
    }

    protected writeOthersHeader(content: Content[]): void {
        if (this.data.activities.some(activity => activity.type === ActivityTypeEnum.SPECIAL_OCCURRENCE)) {
            content.push({
                style: ['marginTop20'],
                columns: [
                    {
                        columns: [
                            {
                                svg: this.changeSvgIconFill(PdfReportSvgIcons.paper, Color.BLUE),
                                fit: [8, 8],
                            }
                        ],
                        width: 'auto'
                    },
                    {
                        text: `${this.i18n?.get('others')}`,
                        width: '*',
                        style: ['font8', 'marginLeft2', 'fontColorBlue', 'textBold'],
                    }
                ]
            });
            this.writeLine(content);
        }
    }

    protected writeOthers(content: Content[]): void {

        for (const [activityId, activity] of this.data.activities.entries()) {
            if (activity.type === ActivityTypeEnum.SPECIAL_OCCURRENCE) {
                const description = activity.description ?? ' ';
                content.push({
                    text: description,
                    style: ['font8', 'textBold'],
                    margin: [0, 3, 0, 0],
                });
                if (this.data.attachmentReportActivities) {
                    this.writeTableAttachments(content, this.data.attachmentReportActivities?.filter((attachment) => attachment.attachment.activityId === activity.id));
                }
                this.writeLine(content);
            }
        }
    }

    // Probably put to some common place, but also use with protocols and global search
    protected isImage(mimeType: string): boolean {
        return mimeType.startsWith('image/');
    }

    protected sanitizeBase64Image(base64Image: string): string {
        return sanitizeBase64Image(base64Image);
    }

    protected writeLine(content: Content[], width?: number, padding?: number): void {
        content.push(canvasLine(width, padding));
    }

    protected getValueNotNull<T>(value: T | undefined | null, description: string): T {
        if (value === undefined || value === null) {
            throw new Error(`Value "${value}" and Description "${description}" was null or undefined.`);
        }
        return value;
    }

    protected changeSvgIconFill(svg: PdfReportSvgIcons, color: string): string {
        return svg.toString().replace('__COLOR__', color);
    }

    protected getTime(time: string | Date | undefined | null): string|null {
        const dateRegex = /^\d+-\d+-\w+/;
        const timeRegex = /^\d+:\d+/;
        if (_.isDate(time) || _.isString(time) && dateRegex.test(time)) {
            return formatDate(time, 'HH:mm', this.lng);
        } else if (_.isString(time) && timeRegex.test(time)) {
            const filteredTime = time.match(timeRegex);
            return _.get(filteredTime, '[0]', '');
        }
        return null;
    }

    protected sanitizeValue(value: string|undefined|null): string {
        return _.isEmpty(value) ? '' : value + '';
    }

    protected formattedNumber(value: number | null | undefined, style: NumberFormatTypes = 'hours'): string {
        return getFormattedNumber(this.lng, value, style);
    }

    protected getProjectProfilesByCompanyId(companyId: IdType): Profile[] {
        const profiles = new Array<Profile>();
        for (const [profileId, profile] of this.data.lookup.profiles.entries()) {
            if (profile.companyId === companyId && this.data.lookup.projectProfiles.find(projectProfile => projectProfile.profileId === profileId)) {
                profiles.push(profile);
            }
        }
        return profiles;
    }

    protected getParticipantsProfile(profile: Profile, participant?: Participant): ParticipantsProfile {
        const address = this.data.lookup.addresses.get(profile?.addressId || '');
        const clientProfileCraft = this.data.lookup.profileCrafts.find(profileCraft => profileCraft.profileId === profile.id);
        const clientCraft = this.data.lookup.crafts.get(clientProfileCraft?.craftId || '');

        let participantsProfile: ParticipantsProfile;
        if (participant !== undefined) {
            participantsProfile = _.clone(participant) as ParticipantsProfile;
        } else {
            participantsProfile = {
                invited: false,
                mailingList: true,
                profileId: profile.id
            } as ParticipantsProfile;
        }

        participantsProfile.profile = profile;
        participantsProfile.address = address;
        participantsProfile.craft = clientCraft;

        return participantsProfile;
    }

    protected sortParticipantsProfiles(participantsProfiles: ParticipantsProfile[]): ParticipantsProfile[] {
        const sortAddressName = (participantProfile: ParticipantsProfile) => `${participantProfile.address?.firstName} ${participantProfile.address?.lastName}`;
        return _.orderBy(participantsProfiles, sortAddressName, ['asc']);
    }

    protected getParticipantEmail(participantProfile: ParticipantsProfile): string {
        if (participantProfile?.profile?.dsgvoShowEmail && !_.isEmpty(participantProfile?.address?.email)) {
            return participantProfile?.address?.email + '';
        }
        return '';
    }

    protected getParticipantPhone(participantProfile: ParticipantsProfile): string {
        if (participantProfile?.profile?.dsgvoShowTel && !_.isEmpty(participantProfile?.address?.mobile)) {
            return participantProfile?.address?.mobile + '';
        } else if (participantProfile?.profile?.dsgvoShowTel && !_.isEmpty(participantProfile?.address?.phone)) {
            return participantProfile?.address?.phone + '';
        }
        return '';
    }

    protected getWeather(weatherKey: number|undefined|null): string {
        const weatherOption = this.i18n?.get('weatherOption_' + weatherKey);
        return _.isEmpty(weatherOption) ? '' : weatherOption + '';
    }

    protected getLocationNameForActivity(activity: Activity, protocolEntryLocations: Map<IdType, ProtocolEntryLocation>): string {
        if (!activity.locationId) {
            return '';
        }
        return protocolEntryLocations.get(activity.locationId)?.location || '';
    }

    private convertNumberToWeekDay(dayNumber: number): string | undefined {
        switch (dayNumber) {
            case 0:
                return this.i18n?.get('sunday');
            case 1:
                return this.i18n?.get('monday');
            case 2:
                return this.i18n?.get('tuesday');
            case 3:
                return this.i18n?.get('wednesday');
            case 4:
                return this.i18n?.get('thursday');
            case 5:
                return this.i18n?.get('friday');
            case 6:
                return this.i18n?.get('saturday');
            default:
                return '';
        }
    }

    private getTimeBlocksString(staff: Staff): string {
        let timeBlockString = '';
        if (staff?.workingTimeFrom1 && staff?.workingTimeTo1) {
            timeBlockString += `${this.getTime(staff.workingTimeFrom1)} - ${this.getTime(staff.workingTimeTo1)}`;
        }
        if (staff?.workingTimeFrom2 && staff?.workingTimeTo2) {
            timeBlockString += `${timeBlockString.length > 1 ? ' |' : ''} ${this.getTime(staff.workingTimeFrom2)} - ${this.getTime(staff.workingTimeTo2)}`;
        }
        if (staff?.workingTimeFrom3 && staff?.workingTimeTo3) {
            timeBlockString += `${timeBlockString.length > 1 ? ' |' : ''} ${this.getTime(staff.workingTimeFrom3)} - ${this.getTime(staff.workingTimeTo3)}`;
        }
        if (staff?.workingTimeFrom4 && staff?.workingTimeTo4) {
            timeBlockString += `${timeBlockString.length > 1 ? ' |' : ''} ${this.getTime(staff.workingTimeFrom4)} - ${this.getTime(staff.workingTimeTo4)}`;
        }
        if (staff?.workingTimeFrom5 && staff?.workingTimeTo5) {
            timeBlockString += ` ${timeBlockString.length > 1 ? ' |' : ''} ${this.getTime(staff.workingTimeFrom5)} - ${this.getTime(staff.workingTimeTo5)}`;
        }

        return timeBlockString;
    }

    protected writeBigAttachments(content: Content[]): void {
        if (this.config.appendActivityAttachments) {
            for (const activity of this.data.activities) {
                if (activity.type === ActivityTypeEnum.SPECIAL_OCCURRENCE) {
                    continue;
                }
                const attachments = this.data.attachmentReportActivities?.filter((attachment) => attachment.attachment.activityId === activity.id);
                this.writeSinglePageAttachments(attachments, content);
            }
        }
        if (this.config.appendOtherAttachments) {
            for (const activity of this.data.activities) {
                if (activity.type !== ActivityTypeEnum.SPECIAL_OCCURRENCE) {
                    continue;
                }
                const attachments = this.data.attachmentReportActivities?.filter((attachment) => attachment.attachment.activityId === activity.id);
                this.writeSinglePageAttachments(attachments, content);
            }
        }
        if (this.config.appendMaterialAttachments && this.data.attachmentReportMaterials) {
            this.writeSinglePageAttachments(this.data.attachmentReportMaterials, content);
        }
        if (this.config.appendEquipmentAttachments && this.data.attachmentReportEquipments) {
            this.writeSinglePageAttachments(this.data.attachmentReportEquipments, content);
        }
    }

    protected writeSignatures(content: Content[]) {
        const {clientSignerName, contractorSignerName} = this.getSignatureData(this.data);
        content.push({
            style: ['marginTop10'],
            columns: [
                {
                alignment: 'left',
                image: 'clientSign',
                fit: [257, 100],
                width: '50%',
                },
                {
                alignment: 'right',
                image: 'contractorSign',
                fit: [257, 100],
                width: '50%',
                }
            ],
        } as unknown as Column);
        const areReportSignaturesSupported = this.data.lookup.reportTypes.get(this.data.reportWeek.typeId)?.name !== ReportTypeCode.REPORT_TYPE_CONSTRUCTION_DIARY;
        if (areReportSignaturesSupported) {
            content.push({
                columns: [
                    {alignment: 'left', fontSize: 8, text: clientSignerName, width: '50%'},
                    {alignment: 'right', fontSize: 8, text: contractorSignerName, width: '50%'}
                ],
            });
            content.push({
                columns: [
                    {alignment: 'left', fontSize: 8, text: this.i18n?.get('signature_client') ?? '', width: '50%'},
                    {alignment: 'right', fontSize: 8, text: this.i18n?.get('signature_contractor') ?? '', width: '50%'},
                ]
            });
        }
    }

    protected getSignatureData(data: PdfReportGenerateData): {
        clientSignerName: string;
        contractorSignerName: string;
        hasSigns: boolean;
    } {
        const clientSignerName = wrappedOrEmpty(data.attachmentReportSignatures?.find((sign) => sign.attachment.code === 'client')?.attachment.signerName);
        const contractorSignerName = wrappedOrEmpty(data.attachmentReportSignatures?.find((sign) => sign.attachment.code === 'contractor')?.attachment.signerName);
        const hasSigns = Boolean(clientSignerName || contractorSignerName);
    
        return {hasSigns, clientSignerName, contractorSignerName};
    }

    private writeSinglePageAttachments(attachments: Array<AttachmentWithContent<AttachmentReportActivity | AttachmentReportEquipment | AttachmentReportMaterial>>, content: Content[]) {
        for (const attachment of attachments) {
            if (this.isImage(attachment.attachment.mimeType)) {
                if (attachment.contentBase64) {
                    content.push({
                        image: this.sanitizeBase64Image(attachment.contentBase64),
                        fit: [500, 500],
                        style: ['alignCenter'],
                    });
                } else {
                    content.push({
                        svg: SvgIcons.noImagePlaceholder,
                        fit: [500, 500],
                        style: ['alignCenter'],
                    });
                }

            }
        }
    }
    
}
