import {Injectable} from '@angular/core';
import {StorageService} from '../storage.service';
import {BehaviorSubject} from 'rxjs';
import {StorageKeyEnum} from '../../shared/constants';
import {DEFAULT_ENTRY_CREATION_MODE, EntryCreationMode} from 'src/app/model/entry-creation-mode';
import { map, shareReplay } from 'rxjs/operators';
import { PopoverService, isPopoverDismissed } from '../ui/popover.service';

@Injectable({
  providedIn: 'root'
})
export class EntryCreationModeService {
  public entryCreationModeSubject = new BehaviorSubject<EntryCreationMode>('text');
  entryCreationMode$ = this.entryCreationModeSubject.asObservable();
  entryCreationModeIcon$ = this.entryCreationMode$.pipe(map((mode) => {
    switch (mode) {
      case 'image': return ['fal', 'camera'];
      case 'plan': return ['fal', 'map-marker-alt'];
      case 'text':
      default: return ['fal', 'text'];
    }
  }), shareReplay(1));

  private set entryCreationMode(entryCreationMode: EntryCreationMode) {
    this.entryCreationModeSubject.next(entryCreationMode);
  }

  public get entryCreationMode() {
    return this.entryCreationModeSubject.value;
  }

  constructor(private storage: StorageService, private popoverService: PopoverService) {
    this.initModeFromStorage();
  }

  private async initModeFromStorage() {
    const entryCreationMode: EntryCreationMode|undefined = await this.storage.get(StorageKeyEnum.ENTRY_CREATION_MODE);
    this.entryCreationMode = entryCreationMode ?? DEFAULT_ENTRY_CREATION_MODE;
  }

  async updateEntryCreationMode(entryCreationMode: EntryCreationMode) {
    this.entryCreationMode = entryCreationMode;
    await this.storage.set(StorageKeyEnum.ENTRY_CREATION_MODE, entryCreationMode);
  }

  async openEntryCreationModeOptions(event: Event): Promise<boolean> {
    const result = await this.popoverService.openActions(event, [
      {
        label: 'button.textBased',
        role: 'text',
        icon: ['fal', 'text'],
      },
      {
        label: 'button.planBased',
        role: 'plan',
        icon: ['fal', 'map-marker-alt'],
      },
    ]);

    if (isPopoverDismissed(result)) {
      return false;
    }

    if (result === 'text') {
      await this.updateEntryCreationMode('text');
      return true;
    }

    if (result === 'plan') {
      await this.updateEntryCreationMode('plan');
      return true;
    }

    return false;
  }
}
