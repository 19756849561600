import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractDataService, DataServiceDeleteOptions, DataServiceInsertOptions, VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import {combineLatest, Observable, ReplaySubject, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Client, ClientType, IdAware, IdType, User} from 'submodules/baumaster-v2-common';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import _ from 'lodash';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {LocalChanges} from '../../model/local-changes';
import {flattenMap} from '../../utils/data-utils';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';

@Injectable()
export abstract class AbstractClientAwareDataService<T extends IdAware> extends AbstractDataService<T> implements OnDestroy {
  protected readonly idColumn = 'id';
  protected readonly restEndpointUrl = environment.serverUrl + this.restEndpointUri;
  private readonly dataByClientId = new Map<IdType, Array<T>>();
  private readonly dataByClientIdSubject = new ReplaySubject<Map<IdType, Array<T>>>(1);
  public readonly dataByClientId$: Observable<Map<IdType, Array<T>>> = this.dataByClientIdSubject.asObservable();
  public readonly dataAcrossClients$ = this.dataByClientId$.pipe(map(flattenMap));
  public readonly dataGroupedById: Observable<Record<IdType, T>> = this.data.pipe(
    map(entities => entities === null ? {} : _.keyBy(entities, 'id'))
  );
  public readonly dataAcrossClientsGroupedById: Observable<Record<IdType, T>> = this.dataAcrossClients$.pipe(
    map(entities => entities === null ? {} : _.keyBy(entities, 'id'))
  );
  public readonly dataForOwnClient$: Observable<Array<T>> = this.clientService.ownClient$.pipe(
    switchMap((ownClient) => this.dataByClientId$.pipe(map((dataByClientId) => ownClient ? dataByClientId.get(ownClient.id) : []))));
  public readonly dataForOwnClientGroupedById: Observable<Record<IdType, T>> = this.dataForOwnClient$.pipe(
    map(entities => entities === null ? {} : _.keyBy(entities, 'id'))
  );
  private readonly authSubscription: Subscription;
  private currentProjectAuthSubscription: Subscription;
  protected ownClient: Client|undefined;
  private clients: Array<Client> = [];
  protected currentClientId: IdType|undefined;

  constructor(@Inject('StorageKeyEnum') storageKey: StorageKeyEnum,
              @Inject('String') protected readonly restEndpointUri: string,
              @Inject('Array') protected readonly defaultValue: Array<T>,
              protected http: HttpClient, protected storage: StorageService, protected authenticationService: AuthenticationService, protected userService: UserService,
              protected clientService: ClientService, protected loggingService: LoggingService,
              protected integrityResolverService: IntegrityResolverService,
              public versionIntroduced = VERSION_INTRODUCED_DEFAULT,
              @Inject('Array') protected sortColumns?: Array<keyof T | ((item: T) => any)>,
              @Inject('Array') protected sortColumnOrders?: Array<'asc'|'desc'>) {
    super(true, false, storageKey, restEndpointUri, defaultValue, http, storage, authenticationService, loggingService,
          integrityResolverService, versionIntroduced, sortColumns, sortColumnOrders);
    this.currentProjectAuthSubscription = combineLatest([this.clientService.clients$, this.clientService.currentClient$, authenticationService.isAuthenticated$])
      .pipe(debounceTime(0)) // prevents calling subscribe 3 times, one time for each observable in combineLatest
      .subscribe(async ([clients, currentClient, isAuthenticated]) => {
        this.isAuthenticated = isAuthenticated;
        if (isAuthenticated) {
          const currentClientId = currentClient?.id;
          this.currentClientId = currentClientId;
          this.clients = clients;
          this.ownClient = clients.find((client) => client.type === ClientType.OWN);

          const dataByClientIdToAdd = new Map<IdType, Array<T>>();
          for (const client of clients) {
            const clientId = client.id;
            if (!this.dataByClientId.has(clientId)) {
              const data = await this.getDataFromStorageOrServer(clientId);
              const value = data ? this.ensureDataSorted(data) : this.defaultValue;
              dataByClientIdToAdd.set(clientId, value);
            }
          }
          let added = false;
          let deleted = false;
          for (const clientId of dataByClientIdToAdd.keys()) {
            if (!this.dataByClientId.has(clientId)) {
              this.dataByClientId.set(clientId, dataByClientIdToAdd.get(clientId));
              added = true;
            }
          }

          for (const clientId of this.dataByClientId.keys()) {
            const client = clients.find((value) => value.id === clientId);
            if (!client) {
              const deletedOne = this.dataByClientId.delete(clientId);
              deleted = deleted || deletedOne;
            }
          }
          if (currentClientId && this.dataByClientId.has(currentClientId)) {
            const data = this.dataByClientId.get(currentClientId);
            this.setNextData(data, currentClientId);
            await this.initLocalChangesSubject(currentClientId);
          } else if (added || deleted) {
            this.dataByClientIdSubject.next(this.dataByClientId);
          }
          await this.initLocalChangesByClientOrProjectSubject();
        } else {
          await this.removeAllStorageData();
          this.loggingService.debug(this.logSource, `dataSubject.next(null) in combineLatest`);
          this.dataSubject.next(null);
          this.clearDataSubjectByClientId();
          this.localChangesSubject.next(new LocalChanges<T>());
          this.localChangesByClientOrProjectSubject.next(new Map());
        }
      });
    this.userService.currentUser$.subscribe((currentUser) => {
      if (!currentUser) {
        this.hasCurrentUserPermission = undefined;
      } else {
        this.hasCurrentUserPermission = this.checkHasCurrentUserPermission(currentUser);
      }
    });
  }

  private setNextData(values: Array<T>, clientId: IdType) {
    this.dataSubject.next(values);
    this.dataByClientId.set(clientId, values);
    this.dataByClientIdSubject.next(this.dataByClientId);
  }

  private setNextDataOtherClient(values: Array<T>, clientId: IdType) {
    this.dataByClientId.set(clientId, values);
    this.dataByClientIdSubject.next(this.dataByClientId);
  }

  private clearDataSubjectByClientId() {
    this.dataByClientId.clear();
    this.dataByClientIdSubject.next(this.dataByClientId);
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.currentProjectAuthSubscription) {
      this.currentProjectAuthSubscription.unsubscribe();
    }
  }

  private assertClientsInitialized() {
    if (!this.clients?.length) {
      throw new Error('clients not yet initialized.');
    }
    if (!this.ownClient) {
      throw new Error('ownClient not yet initialized.');
    }
  }

  private getClientIdOrCurrent(clientId?: IdType): IdType {
    if (clientId) {
      return clientId;
    }
    this.assertClientsInitialized();
    return this.clientService.getCurrentClientMandatory().id;
  }

  public async insertUpdateDelete(changes: {
                                    inserts?: T | Array<T> | ((storageData: Array<T>) => T|Array<T>|undefined),
                                    insertOptions?: DataServiceInsertOptions
                                    updates?: T | Array<T> | ((storageData: Array<T>) => T|Array<T>|undefined),
                                    deletes?: T | Array<T> | ((storageData: Array<T>) => T|Array<T>|undefined),
                                    deleteOptions?: DataServiceDeleteOptions
                                  },
                                  clientId?: IdType): Promise<Array<T>> {
    return await super.insertUpdateDeleteInternal(changes, this.getClientIdOrCurrent(clientId));
  }

  public async insertOrUpdate(valueOrArray: T | Array<T>, clientId?: IdType): Promise<Array<T>> {
    return await super.insertOrUpdate(valueOrArray, this.getClientIdOrCurrent(clientId));
  }

  public async insert(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T|Array<T>|undefined), clientId?: IdType, options?: DataServiceInsertOptions): Promise<Array<T>> {
    return await super.insertInternal(valueArrayOrFunction, this.getClientIdOrCurrent(clientId), options);
  }

  public async update(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T|Array<T>|undefined), clientId?: IdType): Promise<Array<T>> {
    return await super.updateInternal(valueArrayOrFunction, this.getClientIdOrCurrent(clientId));
  }

  public async delete(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T|Array<T>|undefined), clientId?: IdType, options?: DataServiceDeleteOptions): Promise<void> {
    await super.deleteInternal(valueArrayOrFunction, this.getClientIdOrCurrent(clientId), options);
  }

  protected async storageDataChanged(data: Array<T>, clientId: IdType): Promise<void> {
    const values = this.ensureDataSorted(data);
    if (this.currentClientId === clientId) {
      this.loggingService.debug(this.logSource, `storageDataChanged(${data?.length} entries).`);
      this.setNextData(values, clientId);
    } else {
      this.loggingService.debug(this.logSource, `storageDataChanged(${data?.length} for client ${clientId} entries).`);
      this.setNextDataOtherClient(values, clientId);
    }
  }

  protected async removeAllStorageData(): Promise<void> {
    const storageKeysToDelete = (await this.storage.keys()).filter(key => this.isStorageKey(key) || this.isStorageKeyLocalChanges(key));
    for (const storageKey of storageKeysToDelete) {
      this.loggingService.debug(this.logSource, `removeAllStorageData - before storage.remove(${storageKey})`);
      await this.storage.remove(storageKey);
      await this.storage.remove(this.getStorageKeyLocalChanges());
      this.loggingService.debug(this.logSource, `removeAllStorageData - before storage.remove(${storageKey})`);
    }
    this.clearStorageInitialized();
  }

  public async removeAllStorageDataButForClients(keepClientIds: Array<IdType|undefined>): Promise<Array<T>> {
    const storageKeysToKeep = keepClientIds.map((clientId) => this.getStorageKey(clientId))
      .concat(keepClientIds.map((clientId) => this.getStorageKeyLocalChanges(clientId)));
    const deleteStorageKeys = (await this.storage.keys()).filter(key => (this.isStorageKey(key) || this.isStorageKeyLocalChanges(key)) &&
      (storageKeysToKeep === undefined || !storageKeysToKeep.find((storageKeyToKeep) => storageKeyToKeep === key)));
    let deletedElements = new Array<T>();
    for (const storageKey of deleteStorageKeys) {
      const elementToDelete: Array<T> | null = await this.storage.get(storageKey);
      if (elementToDelete && elementToDelete.length) {
        deletedElements = deletedElements.concat(elementToDelete);
      }
      await this.storage.remove(storageKey);
      this.loggingService.info(this.logSource, `Removed data with key ${storageKey}.`);
    }
    return deletedElements;
  }

  public async removeStorageDataForClient(clientId: IdType): Promise<Array<T>> {
    const storageKey = this.getStorageKey(clientId);
    const deletedElements: Array<T> | null = await this.storage.get(storageKey);
    await this.storage.remove(storageKey);
    await this.storage.remove(this.getStorageKeyLocalChanges(clientId));
    return deletedElements || [];
  }

  public getByIdAcrossClients(id: IdType): Observable<T | undefined> {
    return this.dataByClientId$.pipe(map(dataByProjectId => {
      if (!dataByProjectId) {
        return undefined;
      }
      return _.flatten(Array.from(dataByProjectId.values())).find(data => data.id === id);
    }));
  }

  public getByIdsAcrossClients(ids: IdType[]): Observable<T[] | undefined> {
    return this.dataByClientId$.pipe(map(dataByProjectId => {
      if (!dataByProjectId) {
        return undefined;
      }
      return _.flatten(Array.from(dataByProjectId.values())).filter(data => ids.includes(data.id));
    }));
  }

  public getByIdForOwnClient(id: IdType): Observable<T | undefined> {
    return this.dataForOwnClient$.pipe(map(data => {
      if (!data) {
        return undefined;
      }
      return data.find(item => item.id === id);
    }));
  }

  public getDataForClient$(clientId: IdType): Observable<Array<T> | undefined> {
    return this.dataByClientId$.pipe(map((dataByProjectId) => dataByProjectId.get(clientId)));
  }

  public getDataForClient(clientId: IdType): Array<T> | undefined {
    return this.dataByClientId.get(clientId);
  }

  public getDataFromMemory(clientOrProjectId?: IdType): Array<T>|undefined {
    return this.getDataForClient(clientOrProjectId);
  }

  protected abstract checkHasCurrentUserPermission(currentUser: User): boolean;
}
