<div class="storage-quota-notification-warning" *ngIf="isStorageQuotaLevelWarningRelevantForDevice && storageQuotaLevel$ && (storageQuotaLevel$ | async) === StorageQuotaLevelEnum.WARNING"
     (click)="showStorageWarningDialog()">
  <fa-icon id="storage-quota-notification-warning-icon" class="storage-quota-notification-icon" size="lg" [icon]="['fal', 'exclamation-triangle']"></fa-icon>
  <app-tooltip target="storage-quota-notification-warning-icon" placement="bottom">
    {{ 'alert.storageSpaceLeftWarning.header' | translate }}
  </app-tooltip>
</div>

<div class="storage-quota-notification-critical" *ngIf="isStorageQuotaLevelWarningRelevantForDevice && showCritical && storageQuotaLevel$ && ((storageQuotaLevel$ | async) === StorageQuotaLevelEnum.CRITICAL || (storageQuotaLevel$ | async) === StorageQuotaLevelEnum.FULL)"
     (click)="showStorageCriticalDialog()">
  <fa-icon id="storage-quota-notification-critical-icon" class="storage-quota-notification-icon" size="lg" [icon]="['fal', 'exclamation-square']"></fa-icon>
  <ion-text class="ion-padding-start ion-hide-sm-down" >{{ 'alert.storageSpaceLeftCritical.header' | translate }}</ion-text>
  <app-tooltip target="storage-quota-notification-critical-icon" placement="bottom">
    {{ 'alert.storageSpaceLeftCritical.header' | translate }}
  </app-tooltip>
</div>