import {Directive, ElementRef, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {AllTagsPopoverComponent} from 'src/app/components/tags/all-tags-popover/all-tags-popover.component';
import {TagListComponent} from 'src/app/components/tags/tag-list/tag-list.component';
import {PopoverService} from 'src/app/services/ui/popover.service';

@Directive({
  selector: 'app-tag-list[appAllTagsPopover]'
})
export class AllTagsPopoverDirective implements OnDestroy {
  private openCloseSub: Subscription;

  constructor(private element: ElementRef, private popoverService: PopoverService, private tagListComponent: TagListComponent) {
    this.openCloseSub = this.popoverService.getHoverableOrTappable(this.element, {
      component: AllTagsPopoverComponent,
      componentProps: { get tags() { return tagListComponent.tags; } },
    }, {
      filterCondition: () => this.tagListComponent.showMoreTags,
    }).subscribe();
  }

  ngOnDestroy() {
    this.openCloseSub.unsubscribe();
  }

}
