<ng-container *rxLet="data$; let data">
  <app-main-header *ngIf="data.allowInteraction && !(data.hasProjects || data.allowedWhenNoProjects)"></app-main-header>

  <ion-content>
    <ng-container *ngIf="data.isAuthenticated">
      <app-initial-sync-placeholder *ngIf="!data.allowInteraction && data.initialSync" (logout)="logout()"></app-initial-sync-placeholder>
      <app-no-projects-placeholder *ngIf="data.allowInteraction && !data.hasProjects"
        [isClientAdmin]="data.isCurrentUserAdmin"></app-no-projects-placeholder>
      <app-notifications-center *ngIf="data.allowInteraction"></app-notifications-center>
    </ng-container>
    <ion-router-outlet
      *ngIf="data.allowInteraction && (data.hasProjects || data.allowedWhenNoProjects)"
    ></ion-router-outlet>
  </ion-content>
</ng-container>