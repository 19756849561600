import {Injectable} from '@angular/core';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {StorageService} from '../storage.service';
import {ClientService} from '../client/client.service';
import {isClientTestPhase} from 'src/app/utils/client-status-utils';
import {BehaviorSubject, debounceTime, shareReplay, Subscription} from 'rxjs';
import {Nullish} from 'src/app/model/nullish';
import {PosthogService} from '../posthog/posthog.service';

export type SideBarSetting = {
  pinned: boolean;
}

const LOG_SOURCE = 'SideBarSettingsService';
const STORAGE_KEY = StorageKeyEnum.SIDEBAR_SETTING;

const DEFAULT_SIDEBAR_SETTING: SideBarSetting = {pinned: false};
const DEFAULT_SIDEBAR_SETTING_TEST_PHASE: SideBarSetting = {pinned: true};

@Injectable({
  providedIn: 'root'
})
export class SideBarSettingsService {

  private sideBarSettingSubject = new BehaviorSubject<SideBarSetting>(DEFAULT_SIDEBAR_SETTING);
  public readonly sideBarSetting$ = this.sideBarSettingSubject.asObservable().pipe(debounceTime(0), shareReplay(1));
  private clientSubscription: Subscription|undefined;

  constructor(private storage: StorageService, private loggingService: LoggingService, private clientService: ClientService, private posthogService: PosthogService) {
    this.initializeSideBarSetting();
  }

  private async persistSideBarSetting() {
    this.loggingService.debug(LOG_SOURCE, `Persisting sidebar setting ${this.sideBarSettingSubject.value.pinned} to the storage`);
    this.posthogService.captureEvent('[Menu] Persisted burger menu status', {pinned: this.sideBarSettingSubject?.value?.pinned});
    await this.storage.set(STORAGE_KEY, this.sideBarSettingSubject.value, {
      ensureStored: false,
      immediate: false,
    });
  }

  private async initializeSideBarSetting() {
    if (await this.initializeSideBarSettingFromStorage()) {
      return;
    }
    this.clientSubscription?.unsubscribe();
    this.clientSubscription = this.clientService.ownClient$.subscribe(async (client) => {
      if (client && isClientTestPhase(client) && !(await this.storage.get(STORAGE_KEY))) {
        if (this.sideBarSettingSubject.value.pinned !== DEFAULT_SIDEBAR_SETTING_TEST_PHASE.pinned) {
          this.sideBarSettingSubject.next(DEFAULT_SIDEBAR_SETTING_TEST_PHASE);
          await this.persistSideBarSetting();
        }
      }
    });
  }

  private async initializeSideBarSettingFromStorage(): Promise<SideBarSetting|undefined> {
    this.loggingService.debug(LOG_SOURCE, 'initializeSideBarSettingFromStorage called.');
    const storedSideBarSetting: Nullish<SideBarSetting> = await this.storage.get(STORAGE_KEY);
    if (storedSideBarSetting) {
      this.loggingService.debug(LOG_SOURCE, 'initializeSideBarSettingFromStorage - found storedSideBarSetting.');
      this.sideBarSettingSubject.next(storedSideBarSetting);
      return storedSideBarSetting;
    }
    this.loggingService.debug(LOG_SOURCE, 'initializeSideBarSettingFromStorage - did not find.');
    return undefined;
  }

  public async toggleCurrentSideBarSetting() {
    const newSetting: SideBarSetting = {pinned: !this.sideBarSettingSubject.value.pinned};
    this.sideBarSettingSubject.next(newSetting);
    await this.persistSideBarSetting();
  }
}
