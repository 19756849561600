import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent {
  @Input()
  dismissModalOnClose = true;

  @Input()
  showCloseButton = true;

  @Output()
  // TODO: Change the name of it when working on modals change
  // eslint-disable-next-line @angular-eslint/no-output-native
  close = new EventEmitter<void>();

  constructor(private modalController: ModalController) {}

  handleClose() {
    if (this.dismissModalOnClose) {
      this.modalController.dismiss(undefined, 'cancel');
    }

    this.close.emit();
  }

}
