<ion-item lines="none">
  <ion-toggle
      slot="start"
      [ngModel]="showSignatures"
      (ngModelChange)="onShowChange($event)"
      [disabled]="!(isFeatureEnabled$ | async)"
      appSlimToggle
  ></ion-toggle>
  <ion-label>
    <div>{{ "sendProtocol.protocolConfig.signature.sign" | translate }}</div>
    <ion-note *ngIf="!(isFeatureEnabled$ | async)">
      {{ "sendProtocol.protocolConfig.signature.noPermission" | translate }}
    </ion-note>
  </ion-label>
</ion-item>

<ion-grid *ngIf="showSignatures">
  <app-pdf-protocol-signature *ngFor="let pdfProtocolSignature of pdfProtocolSignatures; let i = index; trackBy: trackByProfileOrName"
                              [pdfProtocolSignature]="pdfProtocolSignature"
                              [allowDelete]="true"
                              [readonly]="!(isFeatureEnabled$ | async) || operationInProgress"
                              (pdfProtocolSignatureChange)="handlePdfProtocolSignatureChange($event, i)"
                              (pdfProtocolSignatureDelete)="handlePdfProtocolSignatureDelete($event, i)"
  ></app-pdf-protocol-signature>
  <ion-row *ngIf="!pdfProtocolSignatures.length">
    <ion-col size="12" class="ion-justify-content-center">
      <h3 class="text-secondary ion-text-center">
        {{ 'sendProtocol.protocolConfig.signature.noContactsSelected' | translate }}
      </h3>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="ion-justify-content-center">
      <ionic-selectable
        #profilesSelectable
        [canSearch]="true"
        [isEnabled]="true"
        searchPlaceholder="{{ 'search' | translate }}"
        searchFailText="{{ 'noItemsFound' | translate }}"
        [items]="profileCompanyAddresses$ | async"
        [ngModel]="selectedProfileCompanyAddresses$ | async"
        (ngModelChange)="handleProfileCompanyAddressesChange($event)"
        itemValueField="id"
        itemTextField="searchText"
        groupValueField="company.id"
        groupTextField="company.name"
        class="text-primary"
        [isMultiple]="true"
        appSelectableCommon
        #fs="appSelectableCommon"
        (onOpen)="onOpen($event)"
        (onClose)="onClose($event)"
        [shouldFocusSearchbar]="fs.isDesktop"
      >
        <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
          <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
        </ng-template>
        <ng-template ionicSelectableValueTemplate let-value="value">
          <app-selectable-value [value]="value"></app-selectable-value>
        </ng-template>
        <ng-template ionicSelectableHeaderTemplate>
          <app-selectable-header></app-selectable-header>
        </ng-template>
        <ng-template ionicSelectableFooterTemplate>
          <app-selectable-footer></app-selectable-footer>
        </ng-template>
        <ng-template ionicSelectableItemTemplate let-profile="item">
          <ion-label class="participant">
            <ion-text class="participant-name">
              {{ profile.address.firstName }} {{ profile.address.lastName }}
            </ion-text>
            <ion-note>
              {{ profile.address.email }}
            </ion-note>
          </ion-label>
        </ng-template>
      </ionic-selectable>
      <ion-grid>
        <ion-row>
          <ion-col size="12" size-sm="6">
            <ion-buttons class="ion-no-padding ion-justify-content-center">
              <ion-button
                  class="omg-btn-secondary"
                  (click)="profilesSelectable.open()"
                  [disabled]="operationInProgress"
              >
                {{ 'sendProtocol.protocolConfig.signature.editSigner' | translate }}
              </ion-button>
            </ion-buttons>
          </ion-col>
          <ion-col size="12" size-sm="6">
            <ion-buttons class="ion-no-padding ion-justify-content-center">
              <ion-button
                  class="omg-btn-secondary"
                  (click)="addCustomSigner()"
                  [disabled]="operationInProgress"
              >
                {{ 'sendProtocol.protocolConfig.signature.addCustomSigner' | translate }}
              </ion-button>
            </ion-buttons>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
