import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {Craft, IdType, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {mapObjectNameWithDeletedSuffix, mapObjectsNameWithDeletedSuffix} from 'src/app/utils/async-utils';
import {TranslateService} from '@ngx-translate/core';
import {Nullish} from 'src/app/model/nullish';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/craft/';

@Injectable({
  providedIn: 'root'
})
export class CraftDataService extends AbstractClientAwareDataService<Craft> {
  public readonly dataActive$ = this.dataForOwnClient$.pipe(map((crafts) => crafts?.filter((craft) => craft.isActive || craft.isActive === undefined)));
  public readonly dataWithDeletedSuffix = this.data.pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  public readonly dataForOwnClientWithDeletedSuffix$ = this.dataForOwnClient$.pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  public readonly dataAcrossClientsWithDeletedSuffix$ = this.dataAcrossClients$.pipe(mapObjectsNameWithDeletedSuffix(this.translateService));

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService, private translateService: TranslateService) {
    super(StorageKeyEnum.CRAFT, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, [(value) => value?.name?.toLowerCase()]);
  }

  getByIdWithDeletedSuffix(id: IdType): Observable<Nullish<Craft>> {
    return this.getById(id).pipe(mapObjectNameWithDeletedSuffix(this.translateService));
  }

  getByIdAcrossProjectsWithDeletedSuffix(id: IdType): Observable<Nullish<Craft>> {
    return this.getByIdAcrossClients(id).pipe(mapObjectNameWithDeletedSuffix(this.translateService));
  }

  public getByCraftId(craftId: IdType): Observable<Craft|null> {
    return this.data.pipe(
      map(crafts => crafts.find(craft => craft.id === craftId))
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  dataWithDeletedSuffixWithIds(idsToInclude: Array<IdType>): Observable<Craft[]> {
    return this.dataWithDeletedSuffix.pipe(map((crafts) => crafts.filter((craft) => craft.isActive === undefined || craft.isActive || idsToInclude?.includes(craft.id) )));
  }
}
