import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {Client, ClientType, Project} from 'submodules/baumaster-v2-common';
import {Subscription} from 'rxjs';
import {IonicSelectableComponent} from 'ionic-selectable';
import _ from 'lodash';
import ProjectForDisplay from '../../../model/ProjectForDisplay';
import {SystemEventService} from '../../../services/event/system-event.service';
import {LoggingService} from '../../../services/common/logging.service';
import {ClientDataService} from 'src/app/services/data/client-data.service';
import {getProjectDisplayName} from 'src/app/utils/project-utils';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';
import {ProjectService} from '../../../services/project/project.service';
import {ProjectAvailabilityExpirationService} from '../../../services/project/project-availability-expiration.service';
import {LastUsedProjectService} from '../../../services/common/last-used-project.service';
import {combineLatestAsync} from '../../../utils/async-utils';
import {TranslateService} from '@ngx-translate/core';
import {convertErrorToMessage} from '../../../shared/errors';

const LOG_SOURCE = 'ProjectSelectorComponent';

@Component({
  selector: 'app-project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss']
})

export class ProjectSelectorComponent implements OnInit, OnDestroy {
  @Input()
  labelTextClass?: string;

  selectedProject: ProjectForDisplay|undefined;
  selectedProjectClient: Client|undefined;
  projects: Array<ProjectForDisplay>;
  projectSub: Subscription|undefined;
  private selectedProjectSubscription: Subscription|undefined;
  private clientSubscription: Subscription|undefined;
  clientType = ClientType;
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  constructor(private projectDataService: ProjectDataService, private clientDataService: ClientDataService,
              private systemEventService: SystemEventService, private loggingService: LoggingService,
              private lastUsedProjectService: LastUsedProjectService, private translateService: TranslateService,
              private projectAvailabilityExpirationService: ProjectAvailabilityExpirationService, private projectService: ProjectService,
              private selectableUtilService: SelectableUtilService) {}

  async ngOnInit() {
    this.selectedProjectSubscription?.unsubscribe();
    this.selectedProjectSubscription = combineLatestAsync([this.projectDataService.currentProjectObservable, this.projectDataService.dataAcrossClientsActive$])
      .subscribe(  ([project, activeProjects]) => {
        try {
          if (project) {
            this.updateSelectedProject(this.createProjectForDisplay(project));
            // Calling storeProjectExpirationDate here ensures that the expiry date of the current project is updated (marked as being used) every time the project selector is being used.
            this.projectAvailabilityExpirationService.storeProjectExpirationDateAndInit(project.id, activeProjects.map((p) => p.id));
            this.lastUsedProjectService.markProjectAsUsed(project);
          }
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `ngOnInit - selectedProjectSubscription - failed: ${convertErrorToMessage(error)}`);
          this.systemEventService.logErrorEvent(LOG_SOURCE + 'ngOnInit - selectedProjectSubscription', `failed: ${convertErrorToMessage(error)}`);
        }
    });
    this.projectSub = this.projectService.activeProjectsForDisplay$.subscribe(async projects => {
      if (!_.isEqual(this.projects, projects)) {
        this.projects = projects;
      }
      const currentProject = await this.projectDataService.getCurrentProject();
      if (projects?.length > 0 && !currentProject) {
        await this.setSelectedProject(_.head(projects));
      }
    });
  }

  ngOnDestroy() {
    this.selectedProjectSubscription?.unsubscribe();
    this.selectedProjectSubscription = undefined;
    this.clientSubscription?.unsubscribe();
    this.clientSubscription = undefined;
    if (this.projectSub) {
      this.projectSub.unsubscribe();
      this.projectSub = undefined;
    }
  }

  async onSelectProject(event: { component: IonicSelectableComponent, item: any, isSelected: boolean}): Promise<void> {
    this.loggingService.debug(LOG_SOURCE, `onSelectProject called - item=${event.item}, isSelected=${event.isSelected}`);
    const previousCurrentProject = await this.projectDataService.getCurrentProject();
    const project: Project = event.item;

    try {
      if (event.component.isOpened) {
        await event.component.close();
      }
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Error ionicSelectable.close. ` + error?.message);
      this.systemEventService.logEvent('ProjectSelectorComponent.onSelectProject', error);
    }
    try {
      const projectAvailable = await this.projectService.ensureProjectDataOfflineAvailable(project.id, {modalAllowInBackground: false});
      if (!projectAvailable.success) {
        this.updateSelectedProject(this.createProjectForDisplay(previousCurrentProject));
        return;
      }

      await this.setSelectedProject(project);
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error onSelectProject. ` + convertErrorToMessage(error));
      this.systemEventService.logErrorEvent('ProjectSelectorComponent.onSelectProject', error);
    }
  }

  async onSelectedProjectChanged(event: {component: IonicSelectableComponent, value: any}): Promise<void> {
    this.loggingService.debug(LOG_SOURCE, `onSelectedProjectChanged called - ${event.value}`);
  }

  async setSelectedProject(project?: Project) {
    if (project) {
      await this.updateSelectedProject(this.createProjectForDisplay(project));
    }
    const currentProject = await this.projectDataService.getCurrentProject();
    if (project && (!currentProject || currentProject.id !== project.id)) {
      await this.projectDataService.setCurrentProject(project);
    }
  }

  private updateSelectedProject(project: ProjectForDisplay) {
    this.clientSubscription?.unsubscribe();
    this.clientSubscription = this.clientDataService.getById(project.clientId).subscribe((client) => this.selectedProjectClient = client);
    this.selectedProject = project;
  }

  getGroupText = (projectForDisplay: ProjectForDisplay, index: number, projectsForDisplay: ProjectForDisplay[]) => {
    return this.projectService.getProjectGroupText(projectForDisplay, index, projectsForDisplay);
  };

  private createProjectForDisplay(project: Project): ProjectForDisplay {
    return {...project,
      displayName: getProjectDisplayName(project),
      isAvailable: true
    } as ProjectForDisplay;
  }

  getGroupTextSimple(group: {value: boolean; text: string|null, items: ProjectForDisplay[]}): string|null {
    return group.value ? this.translateService.instant('recently_used') : this.translateService.instant('others');
  }


  async onOpen($event: { component: IonicSelectableComponent }) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: { component: IonicSelectableComponent }) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
