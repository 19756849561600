<app-modal-header>
  <ion-title> {{ 'companyForm.createCompanyContact' | translate }} </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <app-company-form (companyChange)="companyChanged($event)" (isValidChange)="validityChanged($event)" [prePopulateMainFieldWithText]="prePopulateMainFieldWithText"></app-company-form>
</ion-content>

<app-modal-footer>
    <ion-buttons slot="start" class="omg-form" *ngIf="(notConnected$ | async) && !createForSelectable">
      <ion-item class="omg omg-form-checkbox" (click)="toggleAddToProject()">
       <ion-checkbox label-placement="end" justify="start" [ngModel]="addToProject">
         {{ 'companyForm.addCompanyToProject' | translate }}
       </ion-checkbox>
      </ion-item>
    </ion-buttons>
    <ion-buttons slot="end" >
      <ion-button class="omg-btn-text" (click)="dismissModal()">
        {{ 'cancel' | translate }}
      </ion-button>
      <ion-button class="omg-btn-primary" [disabled]="!isValid || isCreating" (click)="createNewCompany()">
        {{ 'button.create' | translate }}
      </ion-button>
    </ion-buttons>
</app-modal-footer>
