import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {CalendarDay} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractNonClientAwareDataService} from './abstract-non-client-aware-data.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {DataServiceDeleteOptions, DataServiceInsertOptions, VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import {map} from 'rxjs/operators';

const REST_ENDPOINT_URI = 'api/data/calendarDays';

@Injectable({
  providedIn: 'root'
})
export class CalendarDayDataService extends AbstractNonClientAwareDataService<CalendarDay> {
  public readonly dataActive$ = this.data.pipe(map((calendarDays) => calendarDays?.filter((calendarDay) => calendarDay.isActive)));

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.CALENDAR_DAY, REST_ENDPOINT_URI, [], http, storage, authenticationService, loggingService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, ['calendarId', 'date']);
  }

  async insertUpdateDelete(changes: {
    inserts?: Array<CalendarDay> | ((storageData: Array<CalendarDay>) => (Array<CalendarDay> | CalendarDay | undefined)) | CalendarDay;
    insertOptions?: DataServiceInsertOptions;
    updates?: Array<CalendarDay> | ((storageData: Array<CalendarDay>) => (Array<CalendarDay> | CalendarDay | undefined)) | CalendarDay;
    deletes?: Array<CalendarDay> | ((storageData: Array<CalendarDay>) => (Array<CalendarDay> | CalendarDay | undefined)) | CalendarDay;
    deleteOptions?: DataServiceDeleteOptions
  }): Promise<Array<CalendarDay>> {
    throw new Error('Modification is not supported for CalendarDayDataService since it is readonly.');
  }

  async insertOrUpdate(valueOrArray: Array<CalendarDay> | CalendarDay): Promise<Array<CalendarDay>> {
    throw new Error('Modification is not supported for CalendarDayDataService since it is readonly.');
  }

  async insert(valueArrayOrFunction: Array<CalendarDay> | ((storageData: Array<CalendarDay>) => (Array<CalendarDay> | CalendarDay | undefined)) | CalendarDay,
               options?: DataServiceInsertOptions): Promise<Array<CalendarDay>> {
    throw new Error('Modification is not supported for CalendarDayDataService since it is readonly.');
  }

  async update(valueArrayOrFunction: Array<CalendarDay> | ((storageData: Array<CalendarDay>) => (Array<CalendarDay> | CalendarDay | undefined)) | CalendarDay): Promise<Array<CalendarDay>> {
    throw new Error('Modification is not supported for CalendarDayDataService since it is readonly.');
  }

  async delete(valueArrayOrFunction: Array<CalendarDay> | ((storageData: Array<CalendarDay>) => (Array<CalendarDay> | CalendarDay | undefined)) | CalendarDay,
               options?: DataServiceDeleteOptions): Promise<void> {
    throw new Error('Modification is not supported for CalendarDayDataService since it is readonly.');
  }
}
