import {Directive, EventEmitter, Output} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {UserEmailSignature} from 'submodules/baumaster-v2-common';
import {EmptyUserEmailSignature, UserEmailSignatureFormType} from '../../../model/email-signature-form-model';

@Directive()
export abstract class EmailSignatureFormUtilDirective implements ControlValueAccessor {

  readonly acceptedMimeTypesForUpload = 'image/jpeg, image/png';
  value: UserEmailSignatureFormType = EmptyUserEmailSignature;

  @Output()
  attachmentCreate = new EventEmitter<{blob: Blob; filename: string}>();

  private propagateOnChange = (_: any) => { };
  private propagateOnTouched = () => { };

  writeValue(value: any): void {
    if (!value) {
      this.value = {...EmptyUserEmailSignature};
    } else {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  updateModel(partial: Partial<UserEmailSignature>) {
    this.value = {
      ...EmptyUserEmailSignature,
      ...this.value,
      ...partial,
    };
    this.propagateOnChange(this.value);
    this.propagateOnTouched();
  }

  handleFileUpload(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const files: FileList = target.files;
    if (files.length === 0) {
      return;
    }

    const blob = files.item(0);
    const filename = blob.name;

    this.updateModel({ size: 'ORIGINAL' });

    this.attachmentCreate.emit({blob, filename});
  }
}
