<app-modal-header>
  <ion-title>{{ (copyMultiple ? 'copyWorkflow.protocolEntries.title' : 'copyWorkflow.protocolEntry.title') | translate: {count: this.protocolEntries?.length} }}</ion-title>
  <div class="ion-text-center no-internet-text-container pt-3 pb-3">
    <ion-label class="omg-attention omg-attention-danger" *ngIf="!currentProjectWithOffline?.isOfflineAvailable && allAttachmentsWithOffline?.length && !isConnected">
      {{'copyWorkflow.textNoInternet' | translate}}
    </ion-label>
    <ion-label class="omg-attention omg-attention-danger" *ngIf="currentProjectWithOffline?.isOfflineAvailable && allAttachmentsWithOffline?.length && !allAttachmentsOfflineAvailable && !isConnected">
      {{'copyWorkflow.textNotAllAttachmentsOfflineAvailable' | translate}}
    </ion-label>
    <ion-label class="omg-attention omg-attention-warning" *ngIf="!currentProjectWithOffline?.isOfflineAvailable && allAttachmentsWithOffline?.length && copyEntryForm.get('copyAttachments').value === true">
      {{'copyWorkflow.textOnlineProject' | translate}}
    </ion-label>
    <ion-label class="omg-attention omg-attention-warning" *ngIf="currentProjectWithOffline?.isOfflineAvailable && allAttachmentsWithOffline?.length && !allAttachmentsOfflineAvailable && isConnected && copyEntryForm.get('copyAttachments').value === true">
      {{'copyWorkflow.textDownloadAttachmentForOfflineProject' | translate}}
    </ion-label>
    <ion-label class="omg-attention omg-attention-warning" *ngIf="sourceProtocol && !isSourceProtocolTypeShort() && destinationProtocolsIncludesShort()">
      {{'copyWorkflow.textDestinationShort' | translate}}
    </ion-label>
    <ion-label class="omg-attention omg-attention-warning" *ngIf="copyMultiple && copyMultipleAnalyze?.parentWithoutAnyChild">
      {{'copyWorkflow.textCopyMultipleParentWithoutAnyChild' | translate}}
    </ion-label>
    <ion-label class="omg-attention omg-attention-warning" *ngIf="copyMultiple && copyMultipleAnalyze?.childWithoutParent">
      {{'copyWorkflow.textCopyMultipleChildWithoutParent' | translate}}
    </ion-label>
  </div>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="copyEntryForm" action="#">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label position="floating">{{ 'copyWorkflow.destinationProtocol' | translate }}</ion-label>
            <ionic-selectable #protocolIonicSelectable
                              [isMultiple]="true"
                              required="true"
                              (onChange)="protocolsOnChange($event)"
                              item-content
                              itemTextField="displayName"
                              formControlName="protocol"
                              [items]=" protocols$ | async"
                              itemValueField="id"
                              [canSearch]="true"
                              [canClear]="true"
                              searchPlaceholder="{{'search' | translate}}"
                              searchFailText="{{'noItemsFound' | translate}}"
                              appSelectableCommon
                              #fs="appSelectableCommon"
                              (onOpen)="onOpen($event)"
                              (onClose)="onClose($event)"
                              [shouldFocusSearchbar]="fs.isDesktop">
              <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
                <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
              </ng-template>
              <ng-template ionicSelectableValueTemplate let-value="value">
                <app-selectable-value [value]="value"></app-selectable-value>
              </ng-template>
              <ng-template ionicSelectableHeaderTemplate>
                <app-selectable-header></app-selectable-header>
              </ng-template>
              <ng-template ionicSelectableFooterTemplate>
                <app-selectable-footer [canClear]="false"></app-selectable-footer>
              </ng-template>
            </ionic-selectable>
          </ion-item>
          <span *ngIf="destinationProtocolsHasClosed()" class="error-message padding-left-10">
          {{ 'copyWorkflow.errorProtocolClosed' | translate }}
        </span>
        </ion-col>
      </ion-row>
    </ion-grid>

    <div class="ion-padding">
      <ion-label>{{ "copyWorkflow.entrySettings" | translate }}</ion-label>
    </div>

    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="copyAttachments">
             {{ 'copyWorkflow.attachments' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="ignoreMissingAttachments">
             {{ 'copyWorkflow.ignoreMissingAttachments' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6" *ngIf="!copyMultiple && protocolEntry.parentId && !destinationProtocolsIncludesShort()">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="copyParentEntry">
             {{ 'copyWorkflow.mainEntry' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6" *ngIf="!copyMultiple && !protocolEntry.parentId && subEntries?.length && !destinationProtocolsIncludesShort()">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="copySubEntries">
             {{ 'copyWorkflow.subEntries' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="copyDetails">
             {{ 'copyWorkflow.details' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="copyCreationDate">
             {{ 'copyWorkflow.entryCreationDate' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item class="omg omg-form-checkbox">
            <ion-checkbox labelPlacement="end" justify="start" formControlName="copyMarkers">
              {{ 'copyWorkflow.markers' | translate }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>


<app-modal-footer>
    <ion-buttons slot="end">
      <ion-button class="omg-btn-text" (click)="cancel()">
        {{'cancel' | translate}}
      </ion-button>
      <ion-button class="omg-btn-primary" (click)="copyEntry()" [disabled]="!destinationProtocols?.length || destinationProtocolsHasClosed()">
        {{'copy' | translate}}
      </ion-button>
    </ion-buttons>
</app-modal-footer>
