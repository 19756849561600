import { ColumnConfig } from '../ColumnConfig';
import { CustomReportTypeColumnSet } from './CustomReportTypeColumnSet';
import { ReportWeekColumnSet } from './ReportWeekColumnSet';

export const ReportTypeColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'name', prop: 'name'},
  {name: 'customReportTypes', fk: 'reportTypeId', mappedBy: CustomReportTypeColumnSet, transient: true},
  {name: 'reportWeeks', fk: 'typeId', mappedBy: ReportWeekColumnSet, transient: true},
];
