import {AsyncPipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {LoginOptions} from 'src/app/services/auth/authentication.service';
import {PageDidEnterLifecycleService} from 'src/app/services/common/page-did-enter-lifecycle.service';
import {LogoutService} from 'src/app/services/logout.service';
import {SyncStatusService} from 'src/app/services/sync/sync-status.service';
import {UserService} from 'src/app/services/user/user.service';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {LoginComponent} from '../login/login.component';
import {RxLet} from '@rx-angular/template/let';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  standalone: true,
  imports: [LoginComponent, UiModule, IonicModule, RxLet, TranslateModule, AsyncPipe],
  providers: [PageDidEnterLifecycleService],
})
export class LoginModalComponent implements OnInit {

  protected readonly loginOptions: Partial<LoginOptions> = {
    automaticallyChangeFileAccessUtil: false,
    skipImpersonate: true,
  };
  private modal: HTMLIonModalElement;
  protected username$ = this.userService.currentUser$.pipe(map((user) => user.username));
  protected hasLocalChanged$ = this.syncStatusService.hasUnsynchronizedChanges$;

  constructor(
    private userService: UserService,
    private pageDidEnterLifecycleService: PageDidEnterLifecycleService,
    private syncStatusService: SyncStatusService,
    private logoutService: LogoutService
  ) { }

  ngOnInit() {
    this.modal.canDismiss = false;
  }

  ionViewDidEnter() {
    this.pageDidEnterLifecycleService.pageDidEnter();
  }

  async logout() {
    if (await this.logoutService.logout()) {
      this.dismiss();
    }
  }

  dismiss() {
    this.modal.canDismiss = true;
    this.modal.dismiss();
  }

}
