import {CommonModule} from '@angular/common';
import {Component, ContentChild, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {AutoSizeVirtualScrollStrategy, RxVirtualFor, RxVirtualScrollViewportComponent} from '@rx-angular/template/experimental/virtual-scrolling';
import _ from 'lodash';
import {BehaviorSubject, Observable, ReplaySubject, Subject, animationFrameScheduler} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, skip, subscribeOn, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ENTRY_CARD_DEFAULT_HEIGHT, EntryCardListModel, EntryCardModel, EntryCardSettings} from 'src/app/model/entry-card-model';
import {LoggingService} from 'src/app/services/common/logging.service';
import {AbstractEntryListService} from 'src/app/services/entry/abstract-entry-list.service';
import {EntryStatusPopoverService} from 'src/app/services/protocol/entry-status-popover.service';
import {ProtocolEntrySelectionService} from 'src/app/services/protocol/protocol-entry-selection.service';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {IdType, LicenseType} from 'submodules/baumaster-v2-common';
import {EntryCardSettingsService} from '../../../services/entry/entry-card-settings.service';
import {FeatureEnabledService} from '../../../services/feature/feature-enabled.service';
import {convertErrorToMessage} from '../../../shared/errors';
import {EntryCardComponent, EntryCardFooterTemplateDirective} from '../entry-card/entry-card.component';

const LOG_SOURCE = 'EntryCardListComponent';

@Directive({standalone: true, selector: '[appEntryListCardFooterTemplate]'})
export class EntryListCardFooterTemplateDirective {
  static ngTemplateContextGuard(_dir: EntryListCardFooterTemplateDirective, ctx: unknown): ctx is {entry: EntryCardListModel} { return true; }
}

@Directive({standalone: true, selector: '[appEntryListGroupHeaderTemplate]'})
export class EntryListGroupHeaderTemplateDirective {
  static ngTemplateContextGuard(_dir: EntryListGroupHeaderTemplateDirective, ctx: unknown): ctx is {entry: EntryCardListModel} { return true; }
}

@Component({
  selector: 'app-entry-card-list',
  templateUrl: './entry-card-list.component.html',
  styleUrls: ['./entry-card-list.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    EntryCardComponent,
    CommonModule,
    RxVirtualFor,
    RxVirtualScrollViewportComponent,
    AutoSizeVirtualScrollStrategy,
    IonicModule,
    TranslateModule,
    FormsModule,
    FontAwesomeModule,
    EntryCardFooterTemplateDirective,
  ]
})
export class EntryCardListComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  readonly trackByIdAndHeight =
    (index: number, item: EntryCardListModel) => `${item?.id}_${this.entryCardHeight ?? ENTRY_CARD_DEFAULT_HEIGHT}_${this.selectedEntryId === item?.id}_${!!item?.firstInGroup}`;
  isCheckboxSelected$: (entry: EntryCardModel) => Observable<boolean>;
  readonly isEditEnabled$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]);

  @ViewChild(RxVirtualScrollViewportComponent, {
    static: false
  })
  viewport: RxVirtualScrollViewportComponent;

  private readonly selectedEntryIdSubject = new BehaviorSubject<IdType|undefined>(undefined);
  private selectedEntryId$ = this.selectedEntryIdSubject.asObservable();

  @Input()
  set selectedEntryId(selectedEntryId: IdType|undefined) { this.selectedEntryIdSubject.next(selectedEntryId); }
  get selectedEntryId(): IdType|undefined { return this.selectedEntryIdSubject.value; }

  @Input()
  showSelectCheckbox?: boolean = true;

  @Input()
  selectedEntryAdditionalHeightFn?: (item: EntryCardModel) => number;

  @Input()
  displaySubentriesFlat = false;

  @Input()
  groupAdditionalHeightFn?: (item: EntryCardListModel) => number;

  @Input()
  scrollToOnChangedSelectedEntryId = false;

  @Output()
  entryClick = new EventEmitter<{event: MouseEvent; entry: EntryCardModel}>();

  @Output()
  threeDotsClick = new EventEmitter<{event: MouseEvent; entry: EntryCardModel}>();

  /**
   * By default, entry card list will also open status popover. If that's not desired, prevent default on `event`
   */
  @Output()
  statusClick = new EventEmitter<{event: MouseEvent; entry: EntryCardModel}>();

  entries$: Observable<EntryCardListModel[]> = this.entryListService.entriesFiltered$.pipe(
    distinctUntilChanged((a, b) => {
      if (!this.viewport) {
        return false;
      }

      this.determineNewPositionOfCurrentScrollPositionAndScrollToIt(a, b);

      return false;
    }),
    tap((entries) => this.protocolEntrySelectionService.setAllEntries(entries)),
    switchMap((entries) => combineLatestAsync([this.entryCardSettingsService.entryCardHeight$, this.selectedEntryId$]).pipe(
      map(() => [...entries])
    ))
  );

  isSelectMode$ = this.protocolEntrySelectionService.isSelectMode$;
  allEntriesCount$ = this.protocolEntrySelectionService.allEntries$.pipe(
    map((entries) => entries.length),
    distinctUntilChanged()
  );
  selectedEntriesCount$ = this.protocolEntrySelectionService.selectedEntries$.pipe(
    map((entries) => entries.length),
    distinctUntilChanged()
  );
  allSelected$ = this.protocolEntrySelectionService.hasAllSelected$;
  someSelected$ = combineLatestAsync([
    this.allSelected$,
    this.protocolEntrySelectionService.hasAnySelected$,
  ]).pipe(map(([allSelected, hasAnySelected]) => !allSelected && hasAnySelected));
  hasFilters$ = this.entryListService.hasFilters$;
  totalVsFilteredEntryCount$ = this.entryListService.totalVsFilteredEntriesCount$;
  entryCardSettings: EntryCardSettings|undefined;
  entryCardHeight: number|undefined;

  entriesRendered = new ReplaySubject<unknown>(1);
  scrollToSubject = new Subject<number>();

  @ContentChild(EntryListCardFooterTemplateDirective, {
    read: TemplateRef,
    static: true
  }) cardFooterTemplate: TemplateRef<{entry: EntryCardListModel}>;
  @ContentChild(EntryListGroupHeaderTemplateDirective, {
    read: TemplateRef,
    static: true
  }) groupHeaderTemplate: TemplateRef<{entry: EntryCardListModel}>;

  constructor(
    private entryStatusPopoverService: EntryStatusPopoverService,
    private entryListService: AbstractEntryListService,
    public protocolEntrySelectionService: ProtocolEntrySelectionService<EntryCardModel>,
    private entryCardSettingsService: EntryCardSettingsService,
    private loggingService: LoggingService,
    private featureEnabledService: FeatureEnabledService,
  ) {
    this.isCheckboxSelected$ = _.memoize(
      (entry) => this.protocolEntrySelectionService.isSelected$(entry),
      (entry) => entry.id
    );
  }

  resetFilters() {
    this.entryListService.resetFilters();
  }

  toggleSelection(entry: EntryCardModel) {
    if (!this.protocolEntrySelectionService.isSelectMode) {
      this.protocolEntrySelectionService.enterSelectMode();
    }

    this.protocolEntrySelectionService.toggle(entry);
  }

  private determineNewPositionOfCurrentScrollPositionAndScrollToIt(a: EntryCardModel[], b: EntryCardModel[]) {
    this.viewport.scrolledIndexChange.pipe(take(1)).subscribe((currentIndex) => {
      const firstParentEntry = a.reduce((acc, entry, index) => {
        if (index > currentIndex) {
          return acc;
        }
        if (!entry.isSubtask) {
          return entry;
        }

        return acc;
      }, a[0]);

      if (!firstParentEntry) {
        return;
      }

      const newIndex = b.findIndex((entry) => entry.id === firstParentEntry.id);

      if (newIndex >= 0) {
        this.scrollToSubject.next(newIndex);
      }
    });
  }

  async scrollToEntry(
    entryId: IdType,
    {
      scheduleAtNextRender: scheduleAtNextRender = false
    }: {
      scheduleAtNextRender?: boolean;
    } = {}
  ) {
    this.loggingService.debug(LOG_SOURCE, `Scheduling scrolling to ${entryId} {scheduleAtNextRender:${''+scheduleAtNextRender}}...`);
    (scheduleAtNextRender ? this.entriesRendered.pipe(skip(1)) : this.entriesRendered).pipe(
      take(1),
      switchMap(() => this.entryListService.entriesFiltered$.pipe(take(1))),
      takeUntil(this.destroy$)
    ).subscribe(
      (allEntries) => {
        try {
          if (!this.viewport) {
            this.loggingService.warn(LOG_SOURCE, `Ignored navigation to ${entryId} due to not present rx viewport in view child`);
            return;
          }
          const index = allEntries.findIndex((entry) => entry.id === entryId);
          if (index < 0) {
            this.loggingService.warn(LOG_SOURCE, `Ignored navigation to ${entryId}, because it has not been found in all entries`);
            return;
          }
          this.loggingService.debug(LOG_SOURCE, `Scrolling to ${entryId}...`);
          this.scrollToSubject.next(index);
        } catch (error) {
          this.loggingService.warn(LOG_SOURCE, `scrollToEntry failed with error ${convertErrorToMessage(error)}`);
        }
      }
    );
  }

  ngOnInit() {
    this.entryCardSettingsService.entryCardSettings$
      .pipe(takeUntil(this.destroy$))
      .subscribe((entryCardSettings) => this.entryCardSettings = entryCardSettings);
    this.entryCardSettingsService.entryCardHeight$
      .pipe(takeUntil(this.destroy$))
      .subscribe((entryCardHeight) => this.entryCardHeight = entryCardHeight);
    this.watchScrollTo();
  }

  private watchScrollTo() {
    this.scrollToSubject
      .pipe(
        debounceTime(0, animationFrameScheduler),
        subscribeOn(animationFrameScheduler),
        takeUntil(this.destroy$)
      )
      .subscribe((index) => {
        try {
          if (index === -1) {
            this.loggingService.debug(LOG_SOURCE, `watchScrollTo index ${index}} called. Ignoring.`);
            this.viewport?.scrollTo(0);
            return;
          }
          this.viewport?.scrollToIndex(index);
        } catch (e) {
          this.loggingService.warn(LOG_SOURCE, `watchScrollTo(${index}) failed. ${convertErrorToMessage(e)}`);
        }
      });
  }

  ngOnDestroy() {
    this.isCheckboxSelected$ = undefined;
    this.destroy$.next();
    this.destroy$.complete();
  }

  async showStatusPopover(event: MouseEvent, entry: EntryCardModel) {
    this.statusClick.emit({event, entry});
    if (event.defaultPrevented) {
      return;
    }

    await this.entryStatusPopoverService.changeStatus(event, entry.id, entry.layout, {
      currentIconStatus: entry.status,
      assignmentId: entry.internalAssignmentId,
      isOwnClient: true
    });
  }
}
