export enum PdfProtocolLayout {
    CLASSIC = 'CLASSIC',
    COMPACT = 'COMPACT'
}

export enum PdfPrintEntryDetails {
    DATES = 'dates',
    PRIORITY = 'priority',
    COMPANY = 'company',
    RESPONSIBLE = 'responsible',
    OBSERVER_COMPANIES = 'observer_companies',
    TYPE = 'type',
    CRAFT = 'craft',
    LOCATION = 'location',
    ADDITIONAL_FIELD = 'additional_field',
    COSTS = 'costs',
}
