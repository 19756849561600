import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PdfProtocolSignatureForm} from '../pdf-protocol-signatures/pdf-protocol-signatures.interface';
import {ModalController} from '@ionic/angular';
import {PdfProtocolSignaturesDialogComponent} from '../pdf-protocol-signatures-dialog/pdf-protocol-signatures-dialog.component';
import {ProtocolSignatureService} from '../../../services/protocol/protocol-signature.service';
import {IdType} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../../../services/common/logging.service';
import {convertErrorToMessage} from '../../../shared/errors';
import {ToastService} from '../../../services/common/toast.service';
import {SystemEventService} from '../../../services/event/system-event.service';

const LOG_SOURCE = 'PdfProtocolSignaturesFooterComponent';

@Component({
  selector: 'app-pdf-protocol-signatures-footer',
  templateUrl: './pdf-protocol-signatures-footer.component.html',
  styleUrls: ['./pdf-protocol-signatures-footer.component.scss'],
})
export class PdfProtocolSignaturesFooterComponent implements OnInit, OnChanges {
  @Input()
  public readonly = false;
  @Input()
  pdfProtocolSignatures: PdfProtocolSignatureForm[] = [];
  @Output()
  pdfProtocolSignaturesChange = new EventEmitter<PdfProtocolSignatureForm[]>();
  @Input()
  public showSendButtons = false;
  @Input()
  public showCloseCheckbox = false;
  @Output()
  public requestCloseEmitter = new EventEmitter<void>();
  @Output()
  public requestRenderEmitter = new EventEmitter<void>();
  @Input()
  public projectId: IdType;
  @Input()
  public protocolId: IdType;
  public operationInProgress = false;

  public closeProtocolAfterSend = false;
  public signedCount = 0;

  constructor(private modalController: ModalController, private protocolSignatureService: ProtocolSignatureService, private loggingService: LoggingService, private toastService: ToastService,
              private systemEventService: SystemEventService) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pdfProtocolSignatures) {
      this.updateSignedCount();
    }
  }

  private updateSignedCount() {
    if (!this.pdfProtocolSignatures?.length) {
      this.signedCount = 0;
      return;
    }
    this.signedCount = this.pdfProtocolSignatures.filter((pdfProtocolSignature) => pdfProtocolSignature.signature).length;
  }

  public checkCloseProtocol() {
    this.closeProtocolAfterSend = !this.closeProtocolAfterSend;
  }

  public triggerClose() {
    this.requestCloseEmitter.emit();
  }

  public async sign(): Promise<boolean> {
    if (this.readonly) {
      return;
    }
    try {
      this.operationInProgress = true;

      const modal = await this.modalController.create({
        component: PdfProtocolSignaturesDialogComponent,
        canDismiss: false,
        componentProps: {
          pdfProtocolSignatures: this.pdfProtocolSignatures,
        }
      });
      await modal.present();

      const result = await modal.onDidDismiss();
      if (result.role === 'cancel' || !result.data) {
        return false;
      }
      this.pdfProtocolSignatures = result.data;
      if (!this.projectId || !this.protocolId) {
        throw new Error(`Unable to persistAttachmentProtocolSignaturesFromFormValues because protocolId and/or projectId is not provided to the component`);
      }
      await this.protocolSignatureService.persistAttachmentProtocolSignaturesFromFormValues(this.projectId, this.protocolId, this.pdfProtocolSignatures, true);
      this.updateSignedCount();
      this.pdfProtocolSignaturesChange.emit(this.pdfProtocolSignatures);
      return true;
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' sign', `Error signing ${convertErrorToMessage(error)}`);
      this.loggingService.error(LOG_SOURCE, `Error signing ${convertErrorToMessage(error)}`);
      this.toastService.error('error_saving_message');
    } finally {
      this.operationInProgress = false;
    }
  }
}
