import {Injectable} from '@angular/core';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {BehaviorSubject, fromEvent, Subscription} from 'rxjs';
import {ThemeSetting} from 'src/app/model/theme-setting';
import {StorageService} from '../storage.service';

const LOG_SOURCE = 'ThemeService';
const STORAGE_KEY = StorageKeyEnum.DARK_THEME;

const DEFAULT_THEME_SETTING: ThemeSetting = 'LIGHT';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private darkMediaQuery: MediaQueryList;
  private darkSubscription?: Subscription;
  themeSetting: ThemeSetting = DEFAULT_THEME_SETTING;
  private isDarkSubject = new BehaviorSubject<boolean>(false);
  public isDark$ = this.isDarkSubject.asObservable();

  get isDark(): boolean {
    return this.isDarkSubject.value;
  }

  set isDark(isDark: boolean) {
    this.isDarkSubject.next(isDark);
  }

  constructor(private storage: StorageService, private loggingService: LoggingService) {
    this.darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  }

  private listenOnPrefersDark() {
    this.unsubscribeFromPrefersDark();

    this.darkSubscription = fromEvent<MediaQueryListEvent>(this.darkMediaQuery, 'change').subscribe((query) => {
      this.changeTheme(query.matches);
    });

    this.changeTheme(this.darkMediaQuery.matches);
  }

  private unsubscribeFromPrefersDark() {
    this.darkSubscription?.unsubscribe();
  }

  private async persistThemeSetting() {
    this.loggingService.debug(LOG_SOURCE, `Persisting theme setting ${this.themeSetting} to the storage`);
    await this.storage.set(STORAGE_KEY, this.themeSetting, {
      ensureStored: false,
      immediate: false,
    });
  }

  private async applyThemeSetting(writeToStorage = true) {
    if (this.themeSetting === 'SYSTEM') {
      this.listenOnPrefersDark();
    } else {
      this.unsubscribeFromPrefersDark();

      this.changeTheme(this.themeSetting === 'DARK');
    }

    if (writeToStorage) {
      await this.persistThemeSetting();
    }
  }

  public async initializeAppTheme() {
    this.loggingService.debug(LOG_SOURCE, 'initializeAppTheme called.');

    const storedThemeSetting = await this.storage.get(STORAGE_KEY);
    let writeToStorage: boolean;
    if (typeof storedThemeSetting === 'boolean' || !storedThemeSetting) {
      this.themeSetting = DEFAULT_THEME_SETTING;
      writeToStorage = true;
    } else {
      this.themeSetting = storedThemeSetting as ThemeSetting;
      writeToStorage = false;
    }

    this.applyThemeSetting(writeToStorage);
  }

  public setCurrentTheme(theme: ThemeSetting) {
    this.themeSetting = theme;
    this.applyThemeSetting();
  }

  private changeTheme(darkTheme: boolean) {
    this.loggingService.debug(LOG_SOURCE, `Setting darkTheme to ${darkTheme}`);
    this.isDark = darkTheme;
    document.body.classList.toggle('dark', darkTheme);
  }

}
