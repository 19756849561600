import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {ViewerGuard} from './guards/viewer.guard';
import {ProjectOmitterGuard} from './middleware/project-omitter.guard';
import {ReportGuard} from './guards/report.guard';
import {ProfessionalAllowedGuard} from './guards/professional-allowed.guard';
import {environment} from 'src/environments/environment';
import {AppDataPage} from './pages/app-data/app-data.page';
import {AllowInteractionDeadlineGuard} from './guards/loading/allow-interaction-deadline.guard';
import {LoadingAppDataComponent} from './pages/loading/loading-app-data/loading-app-data.component';
import {CONTACT_PAGE_IS_UNIT_CONTACT_TOKEN} from './shared/injection-tokens';

const theRoutes: Routes = [
  { path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full' },
  {
    path: 'tasks',
    loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksPageModule),
    canActivate: [AuthGuard, ProjectOmitterGuard]
  },
  {
    path: 'protocols',
    loadChildren: () => import('./pages/protocols/protocols.module').then(m => m.ProtocolsPageModule),
    canActivate: [AuthGuard, ProjectOmitterGuard]
  },
  {
    path: 'protocols-search',
    loadChildren: () => import('./pages/protocols/protocols.module').then(m => m.ProtocolsPageModule),
    canActivate: [AuthGuard, ProjectOmitterGuard]
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/projects/projects.module').then( m => m.ProjectsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then( m => m.ContactsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contact-detail/employee/:addressId',
    loadChildren: () => import('./pages/contact-employee-edit/contact-employee-edit.module').then( m => m.ContactEmployeeEditModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contact-detail/unit-contact/:addressId',
    loadChildren: () => import('./pages/contact-employee-edit/contact-employee-edit.module').then( m => m.ContactEmployeeEditModule),
    canActivate: [AuthGuard],
    providers: [{
      provide: CONTACT_PAGE_IS_UNIT_CONTACT_TOKEN,
      useValue: true
    }]
  },
  {
    path: 'company',
    loadChildren: () => import('./pages/company/company.module').then( m => m.CompanyPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule),
    canActivate: [AuthGuard, ReportGuard]
  },
  {
    path: 'constructionReports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule),
    canActivate: [AuthGuard, ReportGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard, ProjectOmitterGuard]
  },
  {
    path: 'project-settings',
    loadChildren: () => import('./pages/project-settings/project-settings.module').then( m => m.ProjectSettingsPageModule),
    canActivate: [AuthGuard, ProjectOmitterGuard, ViewerGuard]
  },
  {
    path: 'project-room',
    loadChildren: () => import('./pages/project-room/project-room.module').then( m => m.ProjectRoomPageModule),
    canActivate: [AuthGuard, ProjectOmitterGuard]
  },
  {
    path: 'the-settings',
    loadChildren: () => import('./pages/the-settings/the-settings.module').then( m => m.TheSettingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'construction-schedule',
    loadChildren: () => import('./pages/construction-schedule/construction-schedule.module').then( m => m.ConstructionSchedulePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'project-costs',
    loadChildren: () => import('./pages/project-costs/project-costs.module').then( m => m.ProjectCostsPageModule),
    canActivate: [AuthGuard, ProfessionalAllowedGuard]
  },
  ...(environment.hasUiPage ? [{
    path: 'ui',
    loadChildren: () => import('./ui/ui.module').then( m => m.UiPageModule)
  }] : []),
  {
    path: '**',
    redirectTo: '/dashboard',
  }
];

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'resetPasswordConfirm/:resetPasswordLink',
    loadChildren: () => import('./pages/reset-password-confirm/reset-password-confirm.module').then( m => m.ResetPasswordConfirmPageModule)
  },
  {
    path: 'userInviteConfirm/:userInviteLink',
    loadChildren: () => import('./pages/user-invite-confirm/user-invite-confirm.module').then( m => m.UserInviteConfirmPageModule)
  },
  {
    path: 'userConnectionInviteConfirm/:language/:newExisting/:userConnectionInviteLink',
    loadChildren: () => import('./pages/user-connection-invite-confirm/user-connection-invite-confirm.module').then( m => m.UserConnectionInviteConfirmPageModule)
  },
  {
    path: 'technical-help',
    loadChildren: () => import('./pages/technical-help/technical-help.module').then( m => m.TechnicalHelpPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: AppDataPage,
    children: [
      {
        path: 'loading',
        component: LoadingAppDataComponent
      },
      {
        path: '',
        children: theRoutes,
        canActivate: [AllowInteractionDeadlineGuard],
      },
    ],
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
