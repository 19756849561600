<div [appLayout]="0.5" *ngFor="let color of colors" (click)="changeColor(color.color)" class="color-list" [class.active]="color.color === tag.color">
  <div
    class="color"
    [style.--accent-color]="'var(--bau-color-' + color.color + '-rgb)'"
    [style.--accent-opacity]="'var(--bau-color-' + color.color + '-opacity)'"
    [style.--accent-opacity-hover]="'var(--bau-color-' + color.color + '-opacity-hover)'"
  ></div>
  {{ color.label | translate }}
</div>

<div class="section-header">
  {{ 'tags.popover.edit.deleteTagHeader' | translate }}
</div>

<ion-button class="omg-btn-secondary omg-btn-color-danger" (click)="tagDelete.emit(tag)">
  <fa-icon slot="start" [icon]="['fal', 'trash-alt']"></fa-icon>
  <span>{{ 'tags.popover.edit.deleteTagButton' | translate }}</span>
</ion-button>
