<app-modal-header>
  <ion-title>{{'quotaExceeded.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <div class="ion-padding-horizontal ion-padding-vertical">{{'quotaExceeded.text' | translate}}</div>
  <ion-list class="ion-no-padding">
    <ion-item *ngIf="isSwitchingFileAccessUtilSupported" (click)="close()" [routerLink]="['/the-settings/general-settings-appearance']">
      <ion-label>{{ "quotaExceeded.optionSettingsFileSystem" | translate }}</ion-label>
    </ion-item>
    <ion-item (click)="close()" [routerLink]="['/projects']">
      <ion-label>{{ "quotaExceeded.optionOfflineAvailableProjects" | translate }}</ion-label>
    </ion-item>
    <ion-item (click)="close()" [routerLink]="['/projects']">
      <ion-label>{{ "quotaExceeded.optionArchiveProjects" | translate }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-primary" (click)="close()">{{'close' | translate}}</ion-button>
  </ion-buttons>
</app-modal-footer>
