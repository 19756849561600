import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReportDataService} from '../data/report-data.service';
import {
  ActivityTypeEnum,
  Attachment,
  AttachmentReportActivity,
  AttachmentReportEquipment,
  AttachmentReportMaterial,
  AttachmentWithContent,
  CustomPdfConfiguration,
  Employee,
  Equipment,
  IdType,
  Material,
  MIME_TYPE_PDF,
  Participant,
  PdfProtocolSendRes,
  PdfReportGenerateData,
  PdfReportSendReq,
  Report,
  ReportTypeCode,
  Staff
} from 'submodules/baumaster-v2-common';
import {observableToPromise} from '../../utils/async-utils';
import {SystemEventService} from '../event/system-event.service';
import {SyncService} from '../sync/sync.service';
import {PdfReportCommonService} from './pdf-report-common.service';
import {PdfProtocolService} from './pdf-protocol.service';
import {AuthenticationService} from '../auth/authentication.service';
import {Observable} from 'rxjs';
import {ReportWeekDataService} from '../data/report-week-data.service';
import {ReportCompanyDataService} from '../data/report-company-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {ClientDataService} from '../data/client-data.service';
import {ActivityDataService} from '../data/activity-data.service';
import {AttachmentReportActivityDataService} from '../data/attachment-report-activity-data.service';
import {AttachmentReportCompanyDataService} from '../data/attachment-report-company-data.service';
import {AddressDataService} from '../data/address-data.service';
import {CompanyDataService} from '../data/company-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {ProfileCraftDataService} from '../data/profile-craft-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {ProjectCompanyDataService} from '../data/project-company-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {UserPublicDataService} from '../data/user-public-data.service';
import {SyncStrategy} from '../sync/sync-utils';
import {environment} from '../../../environments/environment';
import {convertISOStringToDate} from '../../utils/date-utils';
import {SyncHistoryService} from '../sync/sync-history.service';
import {SyncHistory} from '../../model/sync-models';
import {ReportTypeDataService} from '../data/report-type-data.service';
import {CustomReportTypeDataService} from '../data/custom-report-type-data.service';
import {ParticipantDataService} from '../data/participant-data.service';
import {ReportCompanyActivityDataService} from '../data/report-company-activity-data.service';
import {ProtocolEntryLocationDataService} from '../data/protocol-entry-location-data.service';
import {CustomPdfConfigurationDataService} from '../data/custom-pdf-configuration-data.service';
import _ from 'lodash';
import {EquipmentDataService} from '../data/equipment-data.service';
import {MaterialDataService} from '../data/material-data.service';
import {StaffDataService} from '../data/staff-data.service';
import {AttachmentReportEquipmentDataService} from '../data/attachment-report-equipment-data.service';
import {AttachmentReportMaterialDataService} from '../data/attachment-report-material-data.service';
import {StaffingTypeDataService} from '../data/staffing-type-data.service';
import {AttachmentReportSignatureDataService} from '../data/attachment-report-signature-data.service';
import {EmployeeDataService} from '../data/employee-data.service';
import {AdditionalPayTypeDataService} from '../data/additional-pay-type-data.service';
import {PhotoService} from '../photo/photo.service';
import {AttachmentProjectBannerDataService} from '../data/attachment-project-banner-data.service';
import {isAttachmentBlob} from 'src/app/utils/attachment-utils';
import PDFMerger from 'pdf-merger-js/browser';
import { Nullish } from 'src/app/model/nullish';

@Injectable({
  providedIn: 'root'
})
export class PdfReportService {

  constructor(private http: HttpClient,
              private systemEventService: SystemEventService,
              private authenticationService: AuthenticationService,
              private syncService: SyncService,
              private syncHistoryService: SyncHistoryService,
              private pdfReportCommonService: PdfReportCommonService,
              private reportDataService: ReportDataService,
              private pdfProtocolService: PdfProtocolService,
              private reportWeekDataService: ReportWeekDataService,
              private reportCompanyDataService: ReportCompanyDataService,
              private projectDataService: ProjectDataService,
              private clientDataService: ClientDataService,
              private activityDataService: ActivityDataService,
              private attachmentReportActivityDataService: AttachmentReportActivityDataService,
              private attachmentReportCompanyDataService: AttachmentReportCompanyDataService,
              private profileDataService: ProfileDataService,
              private addressDataService: AddressDataService,
              private companyDataService: CompanyDataService,
              private craftDataService: CraftDataService,
              private profileCraftDataService: ProfileCraftDataService,
              private projectCompanyDataService: ProjectCompanyDataService,
              private projectProfileDataService: ProjectProfileDataService,
              private userPublicDataService: UserPublicDataService,
              private reportTypeDataService: ReportTypeDataService,
              private customReportTypeDataService: CustomReportTypeDataService,
              private participantDataService: ParticipantDataService,
              private reportCompanyActivityDataService: ReportCompanyActivityDataService,
              private protocolEntryLocationDataService: ProtocolEntryLocationDataService,
              private customPdfConfigurationDataService: CustomPdfConfigurationDataService,
              private staffingTypeDataService: StaffingTypeDataService,
              private equipmentDataService: EquipmentDataService,
              private materialDataService: MaterialDataService,
              private staffDataService: StaffDataService,
              private employeeDataService: EmployeeDataService,
              private attachmentReportEquipmentDataService: AttachmentReportEquipmentDataService,
              private attachmentReportMaterialDataService: AttachmentReportMaterialDataService,
              private attachmentReportSignatureDataService: AttachmentReportSignatureDataService,
              private additionalPayTypeDataService: AdditionalPayTypeDataService,
              private photoService: PhotoService,
              private attachmentProjectBannerDataService: AttachmentProjectBannerDataService
  ) { }

  public async sendPdf(reportIds: Array<IdType>, pdfReportSendReq: PdfReportSendReq): Promise<void> {
    this.systemEventService.logEvent('PdfReportService.sendPdf', `Calling sendPdf for reports ${reportIds.join(',')}`);
    if (!reportIds.length) {
      throw new Error('PdfReportService.sendPdf - no reportIds provided.');
    }
    const reports = new Array<Report>();
    const projectIds = new Set<IdType>();
    for (const reportId of reportIds) {
      const report = await observableToPromise(this.reportDataService.getById(reportId));
      if (!report) {
        throw new Error(`Unable to find report with id ${reportId}.`);
      }
      const reportWeek = await observableToPromise(this.reportWeekDataService.getById(report.reportWeekId));
      if (!reportWeek) {
        throw new Error(`Unable to find reportWeek with id ${report.reportWeekId}.`);
      }
      projectIds.add(reportWeek.projectId);
      reports.push(report);
    }
    if (projectIds.size !== 1) {
      throw new Error(`Given reports do not all belong to the same project. projectIds=${projectIds.entries()}`);
    }
    const [projectId] = projectIds;

    if (!pdfReportSendReq.customPdfConfig) {
      pdfReportSendReq.customPdfConfig = await this.getCustomPdfConfiguration();
    }
    const url = await this.getSendPdfUrl(reports, projectId);
    try {
      await observableToPromise(this.http.post<PdfProtocolSendRes>(url, pdfReportSendReq));
    } finally {
      this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    }
  }

  private async getSendPdfUrl(reports: Array<Report>, projectId: IdType): Promise<string> {
    const currentProject = await this.projectDataService.getCurrentProject();
    if (!currentProject) {
      throw new Error('Currently no project selected.');
    }
    if (projectId !== currentProject.id) {
      throw new Error(`Current project ${currentProject.id} does not match the projectId ${projectId}`);
    }
    let url: string;
    if (reports.length === 1) {
      url = environment.serverUrl + `api/pdf/report/${reports[0].id}/send?projectId=${projectId}`;
    } else {
      url = environment.serverUrl + `api/pdf/reports/send?projectId=${projectId}`;
    }
    let syncHistory = await this.syncHistoryService.getSyncHistory(projectId);
    const syncServerHistory = await this.syncHistoryService.getSyncToServerHistory(projectId);
    if (syncHistory && syncServerHistory && convertISOStringToDate(syncServerHistory.startServerTime).getTime() > convertISOStringToDate(syncHistory.startServerTime).getTime()) {
      await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
      syncHistory = await this.syncHistoryService.getSyncHistory(projectId);
    }
    const since = this.getSinceParameter(syncHistory);
    if (since) {
      url += '&since=' + since;
    }
    return url;
  }

  private getSinceParameter(latestSyncHistory?: SyncHistory|null): string|undefined {
    if (!latestSyncHistory?.startServerTime) {
      return undefined;
    }
    return typeof latestSyncHistory.startServerTime === 'string' ? latestSyncHistory.startServerTime : latestSyncHistory.startServerTime.toString();
  }

  private async getPdfBlobForAppend(attachment: AttachmentReportActivity | AttachmentReportMaterial | AttachmentReportEquipment) : Promise<Blob | null | undefined> {
    let pdfBlob: Nullish<Blob>;
    if (attachment.mimeType === MIME_TYPE_PDF) {
      if (isAttachmentBlob(attachment)) {
        pdfBlob = await this.photoService.getAttachmentFromCache(attachment, 'image');
      } else {
        pdfBlob = await this.photoService.downloadAttachment(attachment, 'image');
      }
    }
    return pdfBlob;
  }

  public async generatePdfPreview(reportId: IdType, pdfReportSendReq: PdfReportSendReq, abortSignal?: AbortSignal): Promise<{blob: Blob,
                                  attachmentsMissingContent: Array<Attachment>}|undefined> {
    this.systemEventService.logEvent('PdfReportService.generatePdfPreview', `Generating PDF-Preview of report ${reportId}`);
    if (!pdfReportSendReq.customPdfConfig) {
      pdfReportSendReq.customPdfConfig = await this.getCustomPdfConfiguration();
    }
    if (abortSignal?.aborted) {
      return undefined;
    }
    const data = await this.getData(reportId, pdfReportSendReq);
    if (abortSignal?.aborted) {
      return undefined;
    }
    let blob = await this.pdfReportCommonService.generatePdf(pdfReportSendReq, data, abortSignal);
    const report = await this.getMandatoryValue(this.reportDataService.getById(reportId), 'report');
    const reportWeek = await this.getMandatoryValue(this.reportWeekDataService.getById(report.reportWeekId), 'reportWeek');
    const reportType = await this.getMandatoryValue(this.reportTypeDataService.getById(reportWeek.typeId), 'reportType');
    const canAppend = reportType.name === ReportTypeCode.REPORT_TYPE_CONSTRUCTION_REPORT || reportType.name === ReportTypeCode.REPORT_TYPE_DIRECTED_REPORT;
    if (canAppend && (pdfReportSendReq?.appendActivityAttachments || pdfReportSendReq?.appendOtherAttachments ||
        pdfReportSendReq?.appendMaterialAttachments || pdfReportSendReq?.appendEquipmentAttachments)) {
      const merger = new PDFMerger();
      await merger.add(blob);
      if (pdfReportSendReq?.appendActivityAttachments) {
        for (const activity of data?.activities) {
          if (activity.type === ActivityTypeEnum.SPECIAL_OCCURRENCE) {
            continue;
          }
          const activityAttachments = data.attachmentReportActivities?.filter((attachment) => attachment.attachment.activityId === activity.id);
          for (const attachment of activityAttachments) {
            const pdfBlob = await this.getPdfBlobForAppend(attachment.attachment);
            if (pdfBlob) {
              await merger.add(pdfBlob);
            }
          }
        }
      }
      if (pdfReportSendReq?.appendOtherAttachments) {
        for (const activity of data?.activities) {
          if (activity.type !== ActivityTypeEnum.SPECIAL_OCCURRENCE) {
            continue;
          }
          const activityAttachments = data.attachmentReportActivities?.filter((attachment) => attachment.attachment.activityId === activity.id);
          for (const attachment of activityAttachments) {
            const pdfBlob = await this.getPdfBlobForAppend(attachment.attachment);
            if (pdfBlob) {
              await merger.add(pdfBlob);
            }
          } 
        }
      }
      if (pdfReportSendReq?.appendMaterialAttachments) {
        for (const materialAttachment of data?.attachmentReportMaterials) {
          const pdfBlob = await this.getPdfBlobForAppend(materialAttachment.attachment);
          if (pdfBlob) {
            await merger.add(pdfBlob);
          }
        }
      }
      if (pdfReportSendReq?.appendEquipmentAttachments) {
        for (const equipmentAttachment of data?.attachmentReportEquipments) {
          const pdfBlob = await this.getPdfBlobForAppend(equipmentAttachment.attachment);
          if (pdfBlob) {
            await merger.add(pdfBlob);
          }
        }
      }
      blob = await merger.saveAsBlob();
    }
   
    return {blob, attachmentsMissingContent: data.attachmentsMissingContent};
  }

  public async generatePdfPreviews(
    reportIds: Array<IdType>,
    pdfReportSendReq: PdfReportSendReq,
    abortSignal?: AbortSignal
  ): Promise<{blobs: Array<Blob>, attachmentsMissingContent: Array<Attachment>}|undefined> {
    const blobs = new Array<Blob>();
    let attachmentsMissingContents = new Array<Attachment>();
    for (const reportId of reportIds) {
      if (abortSignal?.aborted) {
        return undefined;
      }
      const {blob, attachmentsMissingContent} = await this.generatePdfPreview(reportId, pdfReportSendReq, abortSignal);
      blobs.push(blob);
      attachmentsMissingContents = attachmentsMissingContents.concat(attachmentsMissingContent);
    }
    return {blobs, attachmentsMissingContent: attachmentsMissingContents};
  }

  private async getCustomPdfConfiguration(): Promise<CustomPdfConfiguration|undefined> {
    const customPdfConfigs =  await observableToPromise(this.customPdfConfigurationDataService.data);
    return _.first(customPdfConfigs);
  }

  private async getMandatoryValue<T>(value$: Observable<T|undefined>, valueDescription: string): Promise<T> {
    const value = await observableToPromise(value$);
    if (value === undefined || value === null) {
      throw new Error(`Mandatory value "${valueDescription}" not found.`);
    }
    return value;
  }

  public async getData(reportId: IdType, pdfReportSendReq: PdfReportSendReq): Promise<PdfReportGenerateData> {
    const attachmentsMissingContent = new Array<Attachment>();

    const authenticatedUserId = await this.getMandatoryValue(this.authenticationService.authenticatedUserId$, 'authenticatedUserId');
    const report = await this.getMandatoryValue(this.reportDataService.getById(reportId), 'report');
    const reportWeek = await this.getMandatoryValue(this.reportWeekDataService.getById(report.reportWeekId), 'reportWeek');
    const reportType = await this.getMandatoryValue(this.reportTypeDataService.getById(reportWeek.typeId), 'reportType');
    const project = await this.getMandatoryValue(this.projectDataService.getById(reportWeek.projectId), 'project');
    const projectAddress = await observableToPromise(this.addressDataService.getById(project.addressId));
    const client = await this.getMandatoryValue(this.clientDataService.getById(project.clientId), 'client');
    const reportCompanies = _.orderBy(await observableToPromise(this.reportCompanyDataService.getByReport(report.id)), ['changedAt']);
    const reportCompanyIds = reportCompanies.map((reportCompany) => reportCompany.id);
    const attachmentReportCompanies = await this.photoService.toAttachmentWithContent(await observableToPromise(this.attachmentReportCompanyDataService.getByReportCompanies(reportCompanyIds)),
      'thumbnail', attachmentsMissingContent);
    const activities = _.orderBy(await observableToPromise(this.activityDataService.getActiveByReportId(report.id)), ['type', 'position', 'changedAt']);
    const activityIds = activities.map((activity) => activity.id);
    const attachmentReportActivities = await this.photoService.toAttachmentWithContent(await observableToPromise(this.attachmentReportActivityDataService.getByActivities(activityIds)),
      'thumbnail', attachmentsMissingContent);
    const attachmentReportSignatures = await this.photoService.toAttachmentWithContent(await observableToPromise(this.attachmentReportSignatureDataService.getByReportId(reportId)),
      'thumbnail', attachmentsMissingContent);
    const participants: Array<Participant> = pdfReportSendReq.participants?.length ? pdfReportSendReq.participants : (await observableToPromise(this.participantDataService.getByReportId(reportId)));
    const allReportCompanyActivities = await observableToPromise(this.reportCompanyActivityDataService.data);
    const reportCompanyActivities = allReportCompanyActivities.filter((reportCompanyActivity) =>
      activityIds.some((activityId) => reportCompanyActivity.activityId === activityId) && reportCompanyIds.some((reportCompanyId) => reportCompanyId === reportCompanyActivity.reportCompanyId));

    const attachmentClients = await this.pdfProtocolService.getAttachmentClientMap(client, attachmentsMissingContent);
    const pdfProjectBanners = await this.pdfProtocolService.getPdfProjectBannersMap(attachmentsMissingContent);

    let equipments: Array<Equipment>|undefined;
    let materials: Array<Material>|undefined;
    let staffs: Array<Staff>|undefined;
    let employees: Array<Employee>|undefined;
    let attachmentReportEquipments: Array<AttachmentWithContent<AttachmentReportEquipment>>|undefined;
    let attachmentReportMaterials: Array<AttachmentWithContent<AttachmentReportMaterial>>|undefined;
    if (reportType.name === ReportTypeCode.REPORT_TYPE_CONSTRUCTION_REPORT || reportType.name === ReportTypeCode.REPORT_TYPE_DIRECTED_REPORT) {
      equipments = _.orderBy(await observableToPromise(this.equipmentDataService.getActiveByReportId(report.id)), ['position', 'changedAt']);
      materials = _.orderBy(await observableToPromise(this.materialDataService.getActiveByReportId(report.id)), ['position', 'changedAt']);
      staffs = _.orderBy(await observableToPromise(this.staffDataService.getActiveByReportId(report.id)), ['changedAt']);
      employees = _.orderBy(await observableToPromise(this.employeeDataService.getActiveByReportId(report.id)), ['changedAt']);
      const equipmentIds = equipments.map((equipment) => equipment.id);
      const materialIds = materials.map((material) => material.id);

      attachmentReportEquipments = await this.photoService.toAttachmentWithContent(await observableToPromise(this.attachmentReportEquipmentDataService.getByReportEquipments(equipmentIds)),
        'thumbnail', attachmentsMissingContent);
      attachmentReportMaterials = await this.photoService.toAttachmentWithContent(await observableToPromise(this.attachmentReportMaterialDataService.getByReportMaterials(materialIds)),
        'thumbnail', attachmentsMissingContent);
    }

    const ret: PdfReportGenerateData = {
      userId: authenticatedUserId,
      report,
      reportWeek,
      project,
      projectAddress,
      client,
      reportCompanies,
      activities,
      participants,
      reportCompanyActivities,
      equipments,
      materials,
      staffs,
      employees,
      attachmentClients,
      pdfProjectBanners,
      attachmentReportActivities,
      attachmentReportCompanies,
      attachmentReportEquipments,
      attachmentReportMaterials,
      attachmentReportSignatures,
      attachmentsMissingContent: _.uniq(attachmentsMissingContent),
      lookup: {
        companies: await this.pdfProtocolService.toMap(this.companyDataService.data),
        crafts: await this.pdfProtocolService.toMap(this.craftDataService.data),
        profiles: await this.pdfProtocolService.toMap(this.profileDataService.dataWithDefaultType$),
        addresses: await this.pdfProtocolService.toMap(this.addressDataService.data),
        projectCompanies: await this.pdfProtocolService.toMap(this.projectCompanyDataService.data),
        userPublicData: await this.pdfProtocolService.toMap(this.userPublicDataService.data),
        reportTypes: await this.pdfProtocolService.toMap(this.reportTypeDataService.data),
        customReportTypes: await this.pdfProtocolService.toMap(this.customReportTypeDataService.data),
        profileCrafts: await observableToPromise(this.profileCraftDataService.data),
        projectProfiles: await observableToPromise(this.projectProfileDataService.data),
        protocolEntryLocations: await this.pdfProtocolService.toMap(this.protocolEntryLocationDataService.data),
        staffingTypes: await this.pdfProtocolService.toMap(this.staffingTypeDataService.data),
        additionalPayTypes: await this.pdfProtocolService.toMap(this.additionalPayTypeDataService.data),
      }
    };
    if (attachmentsMissingContent.length && !pdfReportSendReq.pdfProtocolSetting?.ignoreMissingAttachments) {
      throw new Error(`Missing content of ${attachmentsMissingContent.length} attachments.`);
    }
    return ret;
  }

}
