<ion-content class="with-padding">
  <div class="d-flex">
    <ion-button class="omg-btn-text close-button" (click)="close()">
      <fa-icon [fixedWidth]="true" [icon]="['fal', 'times']"></fa-icon>
    </ion-button>
  </div>
  <div class="omg-modal-header">
    {{'updateModal.header' | translate}}
  </div>
  <div class="mt-3 text">
    {{(isPwa ? 'alert.refreshApp.message' : 'updateModal.message') | translate}}
  </div>
  <div class="mt-3 logo-container ion-align-items-center" *ngIf="!isPwa">
    <img class="logo"
    [src]="'/assets/images/logo.svg'" />
    <span class="text-logo">{{('updateModal.version' | translate)}} <br> {{(updateInfo?.availableVersionCode ?? '')}}</span>
  </div>
</ion-content>

<app-modal-footer>
    <ion-buttons slot="end">
      <ion-button class="omg-btn-secondary" (click)="openUpdateInfo()">{{'updateModal.buttonInfo' | translate}}</ion-button>
      <ion-button class="omg-btn-primary" (click)="startUpdateOrOpenStore()">{{'updateModal.buttonUpdate' | translate}}</ion-button>
    </ion-buttons>
</app-modal-footer>
