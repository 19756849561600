<app-modal-header [showCloseButton]="showCloseButton" [dismissModalOnClose]="false" (close)="dismissModalIfNotDirty()">
  <div class="header">
    <ion-button [disabled]="markersProtocolEntry?.length > 0 || sketchingProtocolEntry || pdfPlanMarkerProtocolEntriesWithoutShowAll?.length > 0" (click)="selectDifferentPlan()" class="omg-btn-secondary btn-position">
      <span>{{'pdfPlanMarker.changePlan' | translate}}</span>
    </ion-button>
    <ion-button (click)="goToPreviousPage()" [disabled]="previousPagerDisabled || isDirty()" class="nav-icon-btn omg-btn-secondary" *ngIf="planPages?.length > 1">
      <fa-icon [icon]="['fal', 'arrow-left']"></fa-icon>
    </ion-button>
    <ion-text color="text-dark" class="ion-text-center title" *ngIf="planPages?.length > 1">
      <div class="version-details size-xs ion-hide-sm-down">
        <div class="version-name">{{ pdfPlanVersion?.name }}</div>
        <div class="other-meta">
          &nbsp;{{ pdfPlanVersion?.index ? '| ' + pdfPlanVersion?.index : '' }} | {{ pdfPlanVersion?.date | date: 'dd.MM.yyyy' }}
        </div>
      </div>
      {{  currentPlanPage?.pageNumber + 1 }}/{{ planPages?.length }}
    </ion-text>
    <ion-button (click)="goToNextPage()" [disabled]="nextPagerDisabled || isDirty()" class="nav-icon-btn omg-btn-secondary" *ngIf="planPages?.length > 1">
      <fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
    </ion-button>
    <div *ngIf="!planPages?.length || planPages?.length === 1" class="ion-hide-sm-down size-xs title-without-nav">
      <div class="version-details size-xs ion-hide-sm-down">
        <div class="version-name">{{ pdfPlanVersion?.name }}</div>
        <div class="other-meta">
          &nbsp;{{ pdfPlanVersion?.index ? '| ' + pdfPlanVersion?.index : '' }} | {{ pdfPlanVersion?.date | date: 'dd.MM.yyyy' }}
        </div>
      </div>
    </div>
    <div class="toggle ion-hide-lg-down">
      <ion-item [class.toggle-background]="isinProjectRoom" lines="none">
        <ion-toggle slot="start" appSlimToggle [checked]="showAllMarkings" (ionChange)="showAllMarkingsChanged($event)"></ion-toggle>
        <ion-label>{{ 'pdfPlanMarker.label.showAllMarkings' | translate }}</ion-label>
      </ion-item>
    </div>
  </div>
</app-modal-header>

<ion-content
  class="ion-padding"
  forceOverscroll="false"
  appLicenseType
  #lt="appLicenseType"

>
    <div (click)="closeOverlay()" #planNameOverlay class="overlay-container ion-hide-sm-up">
      <div class="overlay-content">
        {{pdfPlanVersion.name}}
        <fa-icon class="close-icon" [icon]="['fal', 'times']"></fa-icon>
      </div>

    </div>
    <div class="info-top ion-text-center text-wrap-one-line" *ngIf="markerMode === MarkerMode.ADD && !markers?.length && !disableAddMarker && showActionBtns && (isFeatureEnabled$ | async)">{{ 'pdfPlanMarker.add_plan_marker' | translate }}</div>
    <div class="info-top ion-text-center text-wrap-one-line" *ngIf="markerMode === MarkerMode.ADD && markers?.length && !disableAddMarker && showActionBtns && (isFeatureEnabled$ | async)">{{ 'pdfPlanMarker.add_plan_marker' | translate }} {{ 'pdfPlanMarker.edit_plan_markers' | translate }}</div>
    <div class="info-top ion-text-center text-wrap-one-line" *ngIf="markerMode === MarkerMode.EDIT && markers?.length && !disableAddMarker && showActionBtns && (isFeatureEnabled$ | async)">{{ 'pdfPlanMarker.edit_plan_markers' | translate }}</div>

  <ng-container *rxLet="currentPlanPage ? (currentPlanPage | imageUri:'image': onObjectUrlCreated | async) : null; let currentPlanPageSrc">
    <app-image-canvas #imgCanvas *ngIf="markersProtocolEntry && markers && currentPlanPageSrc"
    [srcWidth]="currentPlanPage?.width"
    [srcHeight]="currentPlanPage?.height"
    (oldMarkerTap)="onOldMarkerTap($event)"
    [selectedMarkers]="markerMode !== MarkerMode.VIEW && selectedMarkers"
    (selectedMarkersChange)="markerMode !== MarkerMode.VIEW && selectedMarkers = $event"
    [createNewMarkerFn]="markerMode === MarkerMode.ADD ? createNewMarkerFn : undefined"
    [oldMarkers]="markers"
    (olderMarkersChange)="onMarkersChanged($event)"
    (newMarkersAdded)="onNewMarkersAdded($event)"
    (loadedImage)="onLoadedImage()"
    [readonly]="disableAddMarker || !(isFeatureEnabled$ | async)"
    [src]="currentPlanPageSrc"
    [pdfPlanPageMarkings]="sketches"
    [editableMarkerId]="markerMode === MarkerMode.EDIT ? (markersProtocolEntry | mapToId) : undefined"
    [cursor]="!isMobileDevice && markerMode === MarkerMode.ADD && showActionBtns && !isClosedEntry && (isFeatureEnabled$ | async) ? cursorMarker : cursorMarkerDefault"
    >
    </app-image-canvas>
    <app-offline-info *ngIf="currentPlanPageSrc === undefined" showRetry="true" (retry)="retryLoadCurrentPlanPage()"></app-offline-info>
  </ng-container>
</ion-content>

<app-modal-footer *ngIf="showFooter">
    <ion-buttons slot="start" class="margin-modal-buttons-left" *ngIf="(isFeatureEnabled$ | async)">
      <ion-button class="ion-hide-lg-up omg-btn-text" (click)="openActionsPopover($event)">
        <fa-icon size="2x" [icon]="['fal', 'ellipsis-h']"></fa-icon>
      </ion-button>

      <ion-button class="ion-hide-lg-down omg-btn-secondary" (click)="deleteAll()"
                  *ngIf="openMode !== PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS && markerMode === MarkerMode.VIEW"
                  [disabled]="loading || isClosedEntry || (!markersProtocolEntry?.length && !sketchingProtocolEntry)">
        <fa-icon [icon]="['fal', 'trash-alt']" slot="start"></fa-icon>
        <span>{{ 'pdfPlanMarker.button.deleteAll' | translate }}</span>
      </ion-button>
      <ion-button *ngIf="openMode !== PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS && markerMode === MarkerMode.VIEW" [disabled]="loading || sketchToolOpeningOrProcessing || isClosedEntry" class="ion-hide-lg-down omg-btn-primary" (click)="startEdit()">
        <fa-icon [icon]="['fal', 'edit']" slot="start"></fa-icon>
        <span>{{ 'pdfPlanMarker.button.edit' | translate }}</span>
      </ion-button>

      <ion-button *ngIf="openMode === PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS || markerMode !== MarkerMode.VIEW" [disabled]="loading || sketchToolOpeningOrProcessing || isClosedEntry" class="ion-hide-lg-down omg-btn-primary" (click)="openImageInSketchTool()">
        <fa-icon [icon]="['fal', 'signature']" slot="start"></fa-icon>
        <span>{{ 'pdfPlanMarker.button.editSketching' | translate }}</span>
      </ion-button>

      <ion-button class="ion-hide-lg-down omg-btn-secondary" (click)="deleteMarker()"
                  *ngIf="openMode !== PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS && markerMode === MarkerMode.ADD || markerMode === MarkerMode.EDIT"
                  [disabled]="loading || sketchToolOpeningOrProcessing || isClosedEntry || !selectedMarkers?.length">
        <fa-icon [icon]="['fal', 'trash-alt']" slot="start"></fa-icon>
        <span>{{ 'pdfPlanMarker.button.deleteMarker' | translate }}</span>
      </ion-button>

      <ion-button *ngIf="openMode !== PdfPlanMarkerComponentMode.PERSIST_CHANGES_AND_DISMISS && markerMode === MarkerMode.EDIT && !disableAddMarker" [disabled]="loading || sketchToolOpeningOrProcessing || isClosedEntry" class="ion-hide-lg-down omg-btn-primary" (click)="startAdd()"
                  [ngClass]="{'look-disabled': !(isMultiMarkerFeatureEnabled$ | async) && (markersProtocolEntry?.length || modifiedMarkings.new?.length)}">
        <fa-icon [icon]="['fal', 'map-marker-alt']" slot="start"></fa-icon>
        <span>{{ 'pdfPlanMarker.button.setNewMarker' | translate }}</span>
      </ion-button>
    </ion-buttons>

    <div class="toggle ion-hide-lg-up">
      <ion-item [class.toggle-background]="isinProjectRoom" lines="none">
        <ion-toggle slot="start" appSlimToggle [checked]="showAllMarkings" (ionChange)="showAllMarkingsChanged($event)"></ion-toggle>
        <ion-label>{{ 'pdfPlanMarker.label.showAllMarkings' | translate }}</ion-label>
      </ion-item>
    </div>

    <ion-buttons slot="end" class="margin-modal-buttons-right">
      <ion-button class="omg-btn-text" (click)="dismissModalIfNotDirty()" *ngIf="openedAsDialog && this.markerMode === MarkerMode.VIEW">{{(isDirty() ? 'button.cancel' : 'close') | translate }}</ion-button>
      <ion-button class="omg-btn-text ion-hide-sm-down" (click)="cancel()" *ngIf=" this.markerMode !== MarkerMode.VIEW">{{'button.cancel' | translate }}</ion-button>
      <ion-button (click)="saveMarker()"
        *ngIf="showActionBtns && markerMode !== MarkerMode.VIEW && (isFeatureEnabled$ | async)"
        [disabled]="isClosedEntry || !isDirty() || loading " class="omg-btn-primary">
          <ion-spinner slot="start" class="spinner-center-button" *ngIf="loading"></ion-spinner>
          <span>{{ 'button.save' | translate }} </span>
      </ion-button>
    </ion-buttons>
</app-modal-footer>
