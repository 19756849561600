<form class="omg-form" [formGroup]="form" action="#">
  <ion-grid class="grid-gap-4">
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-input label="{{'project_room.pdf_plan_version_form.name' | translate}}" label-placement="floating" name="name" formControlName="name" required maxlength="60" class="omg-required"></ion-input>
        </ion-item>
        <span *ngIf="form.controls.name.errors?.required && form.controls.name.dirty" class="error-message">
          {{ 'formValidation.emptyField' | translate:{field: 'project_room.pdf_plan_version_form.name' | translate} }}
        </span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-input label="{{'project_room.pdf_plan_version_form.index' | translate}}" label-placement="floating" name="index" formControlName="index" maxlength="4"></ion-input>
        </ion-item>
        <span *ngIf="form.controls.index.errors?.indexExists" class="error-message">
          {{ 'formValidation.existingValue' | translate:{field: 'project_room.pdf_plan_version_form.index' | translate} }}
        </span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-input label="{{'project_room.pdf_plan_version_form.date' | translate}}"
              label-placement="floating"
              #datepicker="mobiscroll"
              formControlName="date"
              [dateFormat]="MBSC_DATE_FORMAT"
              [showWeekNumbers]="true"
              [firstSelectDay]="1"
              [firstDay]="1"
              [setText]="'done' | translate"
              [cancelText]="'cancel' | translate"
              [buttons]="[ 'cancel', 'set' ]"
              [locale]="mbscLocale$ | async"
              [themeVariant]="mbscThemeVariant$ | async"
              theme="windows"
              mbsc-datepicker [controls]="['calendar']"
              class="omg-required"></ion-input>
        </ion-item>
        <span *ngIf="form.controls.date.errors?.required && form.controls.date.dirty" class="error-message">
          {{ 'formValidation.emptyField' | translate:{field: 'project_room.pdf_plan_version_form.date' | translate} }}
        </span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-input label="{{'project_room.pdf_plan_version_form.scale' | translate}}" label-placement="floating" name="scale" formControlName="scale" maxlength="10"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-textarea label="{{'project_room.pdf_plan_version_form.description' | translate}}" label-placement="floating" name="description" formControlName="description" auto-grow="true" rows="1"></ion-textarea>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item class="larger" button [class.item-has-value]="tags?.length > 0" (click)="editTagsModal.openModal()">
          <ion-label position="floating">
            {{'project_room.pdf_plan_version_form.tags' | translate}}
          </ion-label>
          <div class="tags-item-content ionic-selectable-label-floating" appLayout appLayoutJustifyContent="space-between" appLayoutAlignItems="stretch">
            <app-tag-list
              [maxTags]="1"
              [showMoreTagsCount]="true"
              appEditTagsModal
              #editTagsModal="appEditTagsModal"
              [ngModel]="tags"
              (ngModelChange)="handleTagsChange($event)"
              [ngModelOptions]="{standalone: true}"
            ></app-tag-list>
            <div class="ionic-selectable-icon">
              <div class="ionic-selectable-icon-inner"></div>
            </div>
          </div>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label position="floating">
            <fa-icon [icon]="['fal', 'map']" class="ion-padding-end"></fa-icon>{{'project_room.pdf_plan_version_form.location' | translate}}
          </ion-label>
          <ionic-selectable #locationIonicSelectable
                            id="companyIonicSelectable"
                            formControlName="locationId"
                            [items]="locations$ | async"
                            itemValueField="id"
                            itemTextField="location"
                            [shouldStoreItemValue]="true"
                            [canSearch]="true"
                            searchPlaceholder="{{'search' | translate}}"
                            searchFailText="{{'noItemsFound' | translate}}"
                            appSelectableCommon
                            #fs="appSelectableCommon" [shouldFocusSearchbar]="fs.isDesktop"
                            (onOpen)="onOpen($event)"
                            (onClose)="onClose($event)">
            <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
              <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
            </ng-template>
            <ng-template ionicSelectableValueTemplate let-value="value">
              <app-selectable-value [value]="value"></app-selectable-value>
            </ng-template>
            <ng-template ionicSelectableHeaderTemplate>
              <app-selectable-header></app-selectable-header>
            </ng-template>
            <ng-template ionicSelectableFooterTemplate>
              <app-selectable-footer></app-selectable-footer>
            </ng-template>
          </ionic-selectable>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
