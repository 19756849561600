import { ColumnConfig } from '../ColumnConfig';
import { CompanyColumnSet } from './CompanyColumnSet';
import { CraftColumnSet } from './CraftColumnSet';
import { CustomPdfConfigurationColumnSet } from './CustomPdfConfigurationColumnSet';
import { CustomReportTypeColumnSet } from './CustomReportTypeColumnSet';
import { NameableDropdownColumnSet } from './NameableDropdownColumnSet';
import { ProfileColumnSet } from './ProfileColumnSet';
import { ProjectColumnSet } from './ProjectColumnSet';
import { ProtocolColumnSet } from './ProtocolColumnSet';
import { ProtocolEntryLocationColumnSet } from './ProtocolEntryLocationColumnSet';
import { ProtocolEntryTypeColumnSet } from './ProtocolEntryTypeColumnSet';
import { ProtocolTypeColumnSet } from './ProtocolTypeColumnSet';
import { ReportActivityColumnSet } from './ReportActivityColumnSet';
import { ReportEmployeeColumnSet } from './ReportEmployeeColumnSet';
import { ReportMachineColumnSet } from './ReportMachineColumnSet';
import { ReportMaterialsColumnSet } from './ReportMaterialsColumnSet';
import { ReportSupplierColumnSet } from './ReportSupplierColumnSet';
import { UserConnectionInviteColumnSet } from './UserConnectionInviteColumnSet';
import { StaffingTypeColumnSet } from './StaffingTypeColumnSet';
import {AdditionalPayTypeColumnSet} from './AdditionalPayTypeColumnSet';
import {ClientCalendarColumnSet} from './CalendarColumnSet';
import {ClientTextTemplateColumnSet} from './TextTemplateColumnSet';
import {UnitLevelColumnSet} from './UnitLevelColumnSet';

const ClientColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'name'},
  {name: 'logo_id', prop: 'logoId', def: null},
  {name: 'nameable_dropdown_name', prop: 'nameableDropdownName'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'address_id', prop: 'addressId', def: null},
  {name: 'created_at', prop: 'createdAt', cnd: true, cast: 'Timestamp'},
  {name: 'invoice_address_id', prop: 'invoiceAddressId', def: null},
  {name: 'invoice_email', prop: 'invoiceEmail'},
  {name: 'payment_method_annual', prop: 'paymentMethodAnnual'},
  {name: 'tax_rate', prop: 'taxRate'},
  {name: 'typ'},
  {name: 'uid'},
  {name: 'customer_number', prop: 'customerNumber'},
  {name: 'country_id', prop: 'countryId', def: null},
  {name: 'found_through', prop: 'foundThrough', def: null},
  {name: 'pdfendbanner_id', prop: 'pdfendbannerId', def: null},
  {name: 'pdfstartbanner_id', prop: 'pdfstartbannerId', def: null},
  {name: 'company_field_required', prop: 'companyFieldRequired'},
  {name: 'licenses_monthly_standard', prop: 'licensesMonthlyStandard'},
  {name: 'license_type', prop: 'licenseType'},
  {name: 'licenses_annual_business', prop: 'licensesAnnualBusiness'},
  {name: 'licenses_annual_pro', prop: 'licensesAnnualPro'},
  {name: 'licenses_annual_standard', prop: 'licensesAnnualStandard'},
  {name: 'licenses_first_due_date', prop: 'licensesFirstDueDate', cast: 'Date', def: null},
  {name: 'payment_method_monthly', prop: 'paymentMethodMonthly'},
  {name: 'comment'},
  {name: 'reseller_id', prop: 'resellerId', def: null},
  {name: 'disc_space', prop: 'discSpace'},
  {name: 'closed_protocols', prop: 'closedProtocols'},
  {name: 'licenses_annual_light', prop: 'licensesAnnualLight'},
  {name: 'licenses_monthly_light', prop: 'licensesMonthlyLight'},
  {name: 'licenses_reports', prop: 'licensesReports'},
  {name: 'status', prop: 'status'},
  {name: 'trial_end_date', prop: 'trialEndDate', def: null},
  {name: 'went_to_hosted_page_at', prop: 'wentToHostedPageAt', def: null}
];
const AddressColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'first_name', prop: 'firstName', def: null},
  {name: 'last_name', prop: 'lastName', def: null},
  {name: 'city', def: null},
  {name: 'country', def: null},
  {name: 'email', def: null},
  {name: 'phone', def: null},
  {name: 'mobile', def: null},
  {name: 'messenger', def: null},
  {name: 'fax', def: null},
  {name: 'salutation', def: null},
  {name: 'street_1', prop: 'street1', def: null},
  {name: 'street_2', prop: 'street2', def: null},
  {name: 'zip_code', prop: 'zipCode', def: null},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];

AddressColumnSet.push(
  {name: 'clients', fk: 'addressId', mappedBy: ClientColumnSet, transient: true},
  {name: 'clients', fk: 'invoiceAddressId', mappedBy: ClientColumnSet, transient: true},
  {name: 'companies', fk: 'addressId', mappedBy: CompanyColumnSet, transient: true},
  {name: 'profiles', fk: 'addressId', mappedBy: ProfileColumnSet, transient: true},
  {name: 'projects', fk: 'addressId', mappedBy: ProjectColumnSet, transient: true},
  {name: 'unitLevels', fk: 'addressId', mappedBy: UnitLevelColumnSet, transient: true}
);

ClientColumnSet.push(
  {name: 'addresses', fk: 'clientId', mappedBy: AddressColumnSet, transient: true},
  {name: 'companies', fk: 'clientId', mappedBy: CompanyColumnSet, transient: true},
  {name: 'crafts', fk: 'clientId', mappedBy: CraftColumnSet, transient: true},
  {name: 'customPdfConfigurations', fk: 'clientId', mappedBy: CustomPdfConfigurationColumnSet, transient: true},
  {name: 'customReportTypes', fk: 'clientId', mappedBy: CustomReportTypeColumnSet, transient: true},
  {name: 'protocolNameableDropdowns', fk: 'clientId', mappedBy: NameableDropdownColumnSet, transient: true},
  {name: 'profiles', fk: 'clientId', mappedBy: ProfileColumnSet, transient: true},
  {name: 'projects', fk: 'clientId', mappedBy: ProjectColumnSet, transient: true},
  {name: 'protocolEntryLocations', fk: 'clientId', mappedBy: ProtocolEntryLocationColumnSet, transient: true},
  {name: 'protocolEntryTypes', fk: 'clientId', mappedBy: ProtocolEntryTypeColumnSet, transient: true},
  {name: 'protocolTypes', fk: 'clientId', mappedBy: ProtocolTypeColumnSet, transient: true},
  {name: 'protocols', fk: 'ownerClientId', mappedBy: ProtocolColumnSet, transient: true},
  {name: 'reportActivities', fk: 'clientId', mappedBy: ReportActivityColumnSet, transient: true},
  {name: 'reportEmployees', fk: 'clientId', mappedBy: ReportEmployeeColumnSet, transient: true},
  {name: 'reportMachines', fk: 'clientId', mappedBy: ReportMachineColumnSet, transient: true},
  {name: 'reportMaterials', fk: 'clientId', mappedBy: ReportMaterialsColumnSet, transient: true},
  {name: 'reportSuppliers', fk: 'clientId', mappedBy: ReportSupplierColumnSet, transient: true},
  {name: 'userConnectionInvites', fk: 'clientId', mappedBy: UserConnectionInviteColumnSet, transient: true},
  {name: 'staffingTypes', fk: 'clientId', mappedBy: StaffingTypeColumnSet, transient: true},
  {name: 'additionalPayTypes', fk: 'clientId', mappedBy: AdditionalPayTypeColumnSet, transient: true},
  {name: 'calendars', fk: 'clientId', mappedBy: ClientCalendarColumnSet, transient: true},
  {name: 'clientTextTemplates', fk: 'clientId', mappedBy: ClientTextTemplateColumnSet, transient: true}
);

export { ClientColumnSet, AddressColumnSet };
