import {ColumnConfig} from '../ColumnConfig';
import {UnitProfileColumnSet} from './UnitProfileColumnSet';
import {ProtocolColumnSet} from './ProtocolColumnSet';
import {ProtocolEntryColumnSet} from './ProtocolEntryColumnSet';

export const UnitColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'unit_level_id', prop: 'unitLevelId'},
  {name: 'parent_id', prop: 'parentId', def: null},
  {name: 'name', prop: 'name'},
  {name: 'notes', prop: 'notes', def: null},
  {name: 'index', prop: 'index'},
  {name: 'address_id', prop: 'addressId', def: null},
  {name: 'external_id', prop: 'externalId', def: null},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'unitProfiles', fk: 'unitId', mappedBy: UnitProfileColumnSet, transient: true},
  {name: 'protocols', fk: 'unitId', mappedBy: ProtocolColumnSet, transient: true},
  {name: 'protocolEntries', fk: 'unitId', mappedBy: ProtocolEntryColumnSet, transient: true},
];

UnitColumnSet.push({name: 'units', fk: 'parentId', mappedBy: UnitColumnSet, transient: true});
