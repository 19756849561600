import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {observableToPromise} from 'src/app/utils/async-utils';
import {IdType} from 'submodules/baumaster-v2-common';
import {LoadingService} from '../common/loading.service';
import {LoggingService} from '../common/logging.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {SystemEventService} from '../event/system-event.service';
import {ProtocolNavigationService} from '../protocol-navigation.service';
import {ProtocolEntryService} from '../protocol/protocol-entry.service';
import {AlertService} from '../ui/alert.service';
import {OmgToastService} from '../ui/omg-toast.service';

const LOG_SOURCE = 'RemoveEntryService';

@Injectable({
  providedIn: 'root'
})
export class RemoveEntryService {

  constructor(
    private protocolEntryService: ProtocolEntryService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private projectDataService: ProjectDataService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private systemEventService: SystemEventService,
    private toastService: OmgToastService,
    private protocolNavigationService: ProtocolNavigationService,
    private loggingService: LoggingService
  ) { }

  async deleteEntries(entryIds: IdType[]) {
    if (!(await this.alertService.confirm({
      message: 'entry.delete.confirm.message',
      header: 'entry.delete.confirm.header',
      confirmLabel: 'delete',
      confirmButton: {
        color: 'danger',
        fill: 'solid',
      },
    }))) {
      return;
    }

    try {
      await this.loadingService.withLoading(async () => {
        entryIds.forEach((entryId) => this.protocolNavigationService.cancelWatchProtocolEntryDeleted(entryId));
        await this.protocolEntryService.deleteProtocolEntries(
          await observableToPromise(this.protocolEntryDataService.getByIds(entryIds)),
          (await this.projectDataService.getMandatoryCurrentProject()).id
        );
        this.toastService.infoWithMessageAndHeader('entry.delete.success.header', 'entry.delete.success.message');
      }, {
        message: this.translateService.instant('entry.delete.loading'),
      });
    } catch (e) {
      this.loggingService.error(LOG_SOURCE, `deleteEntries failed - ${convertErrorToMessage(e)}`);
      this.systemEventService.logErrorEvent(LOG_SOURCE, `deleteEntries failed - ${convertErrorToMessage(e)}`);
      this.toastService.error('error_deleting_message');
    }
  }
}
