import {trigger, transition, style, animate, state} from '@angular/animations';
import {CommonModule} from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {RecentlyUsedKeyType} from 'src/app/model/selectable';
import {SelectableInputModule} from 'src/app/shared/module/selectable-input/selectable-input.module';
import {CommonSelectableModule} from 'src/app/shared/module/selectable/selectable.module';
import {IdAware, IdType} from 'submodules/baumaster-v2-common';

const DEFAULT_SHOW_SELECTED_ITEMS = false;

type ItemType = IdAware & {isDeletedOrNotInProject?: boolean};

@Component({
  selector: 'app-entry-filter-selectable',
  templateUrl: './entry-filter-selectable.component.html',
  styleUrls: ['./entry-filter-selectable.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    SelectableInputModule,
    FormsModule,
    TranslateModule,
    FontAwesomeModule,
    CommonSelectableModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EntryFilterSelectableComponent),
    multi: true
  }],
  animations: [
    trigger('smooth', [
      state('hidden', style({ opacity: 0 })),
      state('visible', style({ opacity: 1 })),
      transition('hidden <=> visible', [
        animate('160ms ease'),
      ]),
    ]),
    trigger('boxContent', [
      state('open', style({ height: '*', opacity: 1 })),
      state('close', style({ height: 0, padding: 0, marginTop: 0, borderWidth: 0, opacity: 0 })),
      transition('open <=> close', [
        animate('160ms ease'),
      ]),
    ]),
    trigger('pinned', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('160ms ease', style({ opacity: 1, height: '*' })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('160ms ease', style({ opacity: 0, height: 0 })),
      ])
    ]),
  ]
})
export class EntryFilterSelectableComponent implements OnInit, OnChanges, ControlValueAccessor {

  @HostBinding('class.has-data')
  get hasData() {
    return Boolean(this.value?.length);
  }

  @Input()
  label: string;
  @Input()
  modalTitle: string;

  @Input()
  items: ItemType[];

  @Input()
  itemTextField: string;

  @Input()
  itemGreyedOutField?: string;

  @Input()
  itemBoldField?: string;

  @Input()
  itemLabelIconTemplate?: TemplateRef<{item: ItemType}>|undefined;

  @Input()
  recentlyUsedKey?: RecentlyUsedKeyType;

  value: IdType[]|null;

  private propagateOnChange = (_: any) => { };
  private propagateOnTouched = () => { };

  get selectedItems() {
    return this.items?.filter((item) => this.value?.includes(item.id)) ?? [];
  }

  activeItems: ItemType[];

  showSelectedItems = DEFAULT_SHOW_SELECTED_ITEMS;

  constructor(private cdRef: ChangeDetectorRef) {}

  toggleSelectedItems() {
    this.showSelectedItems = !this.showSelectedItems;
  }

  writeValue(value: any): void {
    this.value = value;
    this.cdRef.markForCheck();
  }
  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.activeItems = this.items.filter((item) => item.isDeletedOrNotInProject !== true);
    }
  }

  valueChangeHandle(value: IdType[]|null) {
    this.value = value?.length === 0 ? null : value;
    if (this.value === null) {
      this.showSelectedItems = DEFAULT_SHOW_SELECTED_ITEMS;
    }
    this.propagateOnChange(this.value);
  }

  closeHandle() {
    this.propagateOnTouched();
  }

  selectAll() {
    this.valueChangeHandle(null);
  }

  removeItem(itemToRemove: ItemType) {
    const newValues = this.value?.filter((id) => id !== itemToRemove.id) ?? [];
    this.valueChangeHandle(newValues);
  }
}
