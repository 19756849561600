import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {switchMapOrDefault} from 'src/app/utils/async-utils';
import {getUserNameFromAddress} from 'src/app/utils/user-name-utils';
import {Address, Company, Profile, User} from 'submodules/baumaster-v2-common';
import {AddressDataService} from '../data/address-data.service';
import {CompanyDataService} from '../data/company-data.service';
import {ProfileDataService} from '../data/profile-data.service';

@Injectable({
  providedIn: 'root'
})
export class UsersProfileService {

  constructor(
    private translateService: TranslateService,
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private companyDataService: CompanyDataService
  ) {}

  getUserOwnProfile$(user: User): Observable<Profile | null> {
    if (!user) {
      return of(null);
    }

    return this.profileDataService.getByIdForOwnClient(user.profileId);
  }

  getUserOwnAddress$(user: User): Observable<Address | null> {
    return this.getUserOwnProfile$(user).pipe(
      switchMapOrDefault((profile) => {
        return this.addressDataService.dataForOwnClient$.pipe(
          map((addresses) => profile ? addresses?.find((address) => address.id === profile.addressId) : null)
        );
      }, null)
    );
  }

  getUserOwnCompany$(user: User): Observable<Company | null> {
    return this.getUserOwnProfile$(user).pipe(
      switchMapOrDefault((profile) => {
        return this.companyDataService.dataForOwnClient$.pipe(
          map((companies) => profile ? companies?.find((company) => company.id === profile.companyId) : null)
        );
      }, null)
    );
  }

  getUserOwnName$(user: User): Observable<string | null> {
    return this.getUserOwnProfile$(user).pipe(
      switchMapOrDefault((profile) => {
        return this.addressDataService.dataForOwnClient$.pipe(
          map((addresses) => profile ? addresses?.find((address) => address.id === profile.addressId) : null),
          map((address) => address ? getUserNameFromAddress(this.translateService, address, profile.isActive) : '')
        );
      }, null)
    );
  }
}
