<app-modal-header>
  <ion-title>{{'sendStatusReport.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <ion-grid class="omg-form">
    <ion-row>
      <ion-col size="12">
        <p>{{'sendStatusReport.text' | translate}}</p>
      </ion-col>
      <ion-col size="12">
        <ion-item>
          <ion-text>{{'sendStatusReport.systemInformation' | translate}}</ion-text>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item>
          <ion-text>{{'sendStatusReport.localChanges' | translate}}</ion-text>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item>
          <ion-text>{{'sendStatusReport.events' | translate}}</ion-text>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">{{'cancel' | translate}}</ion-button>
    <ion-button class="omg-btn-primary" (click)="send()">{{'send' | translate}}</ion-button>
  </ion-buttons>
</app-modal-footer>
