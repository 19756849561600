import {Injectable} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {ToastService} from '../common/toast.service';
import {ProtocolEntryService} from '../protocol/protocol-entry.service';
import {ProtocolEntryMassEditComponent} from '../../components/protocol/protocol-entry-mass-edit/protocol-entry-mass-edit.component';
import {ToastDurationInMs} from '../../shared/constants';
import {convertErrorToMessage} from '../../shared/errors';
import {EntryService} from './entry.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {TranslateService} from '@ngx-translate/core';
import {IdType, isTaskProtocol, PROTOCOL_LAYOUT_NAME_CONTINUOUS} from 'submodules/baumaster-v2-common';
import {observableToPromise} from '../../utils/async-utils';
import _ from 'lodash';
import {ProtocolService} from '../protocol/protocol.service';
import {SystemEventService} from '../event/system-event.service';

@Injectable({
  providedIn: 'root'
})
export class EditEntryService {

  constructor(
    private protocolEntryDataService: ProtocolEntryDataService,
    private entryService: EntryService,
    private modalController: ModalController,
    private toastService: ToastService,
    private protocolEntryService: ProtocolEntryService,
    private loadingCtrl: LoadingController,
    private translateService: TranslateService,
    private protocolService: ProtocolService,
    private systemEventService: SystemEventService) {
  }

  async massEdit(entryIds: Array<IdType>): Promise<boolean> {
    const protocolEntries = await observableToPromise(this.protocolEntryDataService.getByIds(entryIds));
    if (protocolEntries.length !== protocolEntries.length) {
      throw new Error(`selectedProtocolEntries.length (${protocolEntries.length} does not match selectedEntries.length (${protocolEntries.length}).`);
    }

    const protocolIds = _.uniq(_.compact(protocolEntries.map((protocolEntry) => protocolEntry.protocolId)));
    if (protocolIds.length === 0) {
      throw new Error('Unable to extract a protocolId from the entries for massEdit.');
    }
    if (protocolIds.length > 1) {
      throw new Error(`The entryIds for massEdit belong to ${protocolIds.length} different protocols but they must all belong to the same protocol.`);
    }
    const protocol = await this.protocolService.getProtocolById(protocolIds[0]);
    const protocolLayout = await observableToPromise(this.protocolService.getProtocolLayoutByProtocolId(protocol.id));
    const isContinuousProtocolLayout = protocolLayout?.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS;

    if (!protocol) {
      throw new Error(`No protocol selected`);
    }

    const modal = await this.modalController.create({
      component: ProtocolEntryMassEditComponent,
      componentProps: {
        protocolId: protocol.id,
        isShortProtocolLayout: false,
        isContinuousProtocolLayout,
        isTaskProtocol: isTaskProtocol(protocol),
        hasNonActionableItems: false,
        hasActionableItems: true,
        onlyActionableEntryTypes: true,
        typeRequired: true,
        hasChildEntries: protocolEntries.some((entry) => !!entry.parentId),
        isClosedOrHasCarriedOverEntries: false,
        entriesCount: protocolEntries.length,
      },
    });

    await modal.present();

    const {data} = await modal.onDidDismiss();

    if (!data) {
      return false;
    }

    const protocolEntryInput = this.protocolEntryService.getPartialProtocolEntryFromRawData(data);

    const loading = await this.loadingCtrl.create({
      message: this.translateService.instant('protocolEntry.massEdit.loading'),
    });

    await loading.present();
    try {
      await this.protocolEntryService.editProtocolEntries(protocolEntries, protocolEntryInput, protocol.projectId);
      await this.toastService.toastWithTranslateParams('protocolEntry.massEdit.success', {count: protocolEntries.length}, ToastDurationInMs.INFO);
      return true;
    } catch (e) {
      await this.toastService.toastWithTranslateParams('protocolEntry.massEdit.failure', {message: convertErrorToMessage(e)}, ToastDurationInMs.ERROR);
      this.systemEventService.logErrorEvent('protocolEntry.massEdit.failure', e);
      return false;
    } finally {
      await loading.dismiss();
    }
  }
}
