import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import async, {AsyncResultCallback} from 'async';
import _ from 'lodash';
import {Subscription} from 'rxjs';
import {SystemEvent, SystemEventCategoryEnum} from 'submodules/baumaster-v2-common';
import {v1} from 'uuid';
import {StorageKeyEnum} from '../../shared/constants';
import {convertErrorToMessage} from '../../shared/errors';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {AnalyticsService} from '../firebase/analytics.service';
import {StorageService} from '../storage.service';

const LOG_SOURCE = 'SystemEventService';
const MAX_AGE_IN_DAYS = 2;
const MAX_AGE_IN_MS = MAX_AGE_IN_DAYS * 24 * 60 * 60 * 1000;

const FLUSH_TO_STORAGE_TIMEOUT = 2000;

type StringOrFunction = string | (() => string);

export interface LogActionInstance {
  success: (str?: StringOrFunction) => void;
  failure: (error: any) => void;
  logCheckpoint: (str: StringOrFunction) => void;
}

const noop = () => {};

@Injectable({
  providedIn: 'root'
})
export class SystemEventService implements OnDestroy {
  private readonly systemEventCargo = async.cargo<SystemEvent>(async (systemEvents, callback: AsyncResultCallback<Array<SystemEvent>>) => {
    try {
      const allSystemEvents = await this.pushSystemEvents(systemEvents);
      callback(null, allSystemEvents);
    } catch (error) {
      callback(error);
    }
  });
  private authenticationSubscription: Subscription;
  private isAuthenticated: boolean|undefined;

  constructor(private storage: StorageService, private authenticationService: AuthenticationService, private router: Router, private analyticsService: AnalyticsService,
              private loggingService: LoggingService) {
    this.authenticationSubscription = this.authenticationService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
      if (!isAuthenticated) {
        await this.clearStorageData();
      }
    });
    this.housekeeping();
  }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
  }

  public async getSystemEvents(): Promise<Array<SystemEvent>> {
    return await this.getStorageData();
  }

  public async getClonedSystemEventsWithIsoDate(): Promise<Array<SystemEvent>> {
    const systemEvents = await this.getStorageData();
    return systemEvents.map((systemEvent) => {
      return {timestampIsoDate: new Date(systemEvent.timestamp).toISOString(), ...systemEvent};
    });
  }

  public logAction(
    sourceOrFunction: StringOrFunction,
    messageOrFunction: StringOrFunction
  ): LogActionInstance {
    const actionId = v1().substring(0, 8);

    let source: string;
    let message: string;
    try {
      source = this.evaluateStringOrFunction(sourceOrFunction);
      message = this.evaluateStringOrFunction(messageOrFunction);
      if (!source || !message) {
        return {
          success: noop,
          failure: noop,
          logCheckpoint: noop,
        };
      }
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Error converting source or message to a string. ${convertErrorToMessage(error)}`);
      return {
        success: noop,
        failure: noop,
        logCheckpoint: noop,
      };
    }

    const fullMessage = `[${actionId}] ${message}`;
    this.logEvent(source, `${fullMessage} started`);

    return {
      success: (str?: StringOrFunction) => this.logEvent(source, `${fullMessage} finished${str ? ` (${this.safeEvaluateStringOrFunction(str)})` : ''}`),
      failure: (error: any) => this.logErrorEvent(source, `${fullMessage} failed (${convertErrorToMessage(error)})`),
      logCheckpoint: (str: StringOrFunction) => this.logEvent(source, `${fullMessage} ${this.safeEvaluateStringOrFunction(str)}`),
    };
  }

  public async logEvent(sourceOrFunction: StringOrFunction, messageOrFunction: StringOrFunction): Promise<SystemEvent> {
    let source: string;
    let message: string;
    try {
      source = this.evaluateStringOrFunction(sourceOrFunction);
      message = this.evaluateStringOrFunction(messageOrFunction);
      if (!source || !message) {
        return;
      }
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Error converting source or message to a string. ${convertErrorToMessage(error)}`);
      return;
    }
    return await this.logEventInternal(source, message);
  }

  private safeEvaluateStringOrFunction(messageOrFunction: StringOrFunction): string {
    try {
      return this.evaluateStringOrFunction(messageOrFunction);
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Error converting source or message to a string. ${convertErrorToMessage(error)}`);
      return '__safeEvaluateStringOrFunction_failed__';
    }
  }

  private evaluateStringOrFunction(messageOrFunction: StringOrFunction): string {
    return typeof messageOrFunction === 'function' ? messageOrFunction() : messageOrFunction;
  }

  private async logEventInternal(source: string, message: string): Promise<SystemEvent> {
    await this.analyticsService.logEvent(source, message);
    const systemEvent: SystemEvent = {
      timestamp: new Date().getTime(),
      category: SystemEventCategoryEnum.EVENT,
      source,
      message,
      page: this.router.url
    };
    await this.systemEventCargo.push(systemEvent);
    return systemEvent;
  }

  private getFirstNonEmptyString(...values: Array<string|undefined|null>): string|undefined {
    for (const value of values) {
      if (!_.isEmpty(value)) {
        return value;
      }
    }
    return undefined;
  }

  public async logErrorEvent(sourceOrFunction: StringOrFunction, error: string|Error): Promise<SystemEvent> {
    let source: string;
    try {
      source = typeof sourceOrFunction === 'function' ? sourceOrFunction() : sourceOrFunction;
      if (!source) {
        return;
      }
    } catch (innerError) {
      this.loggingService.warn(LOG_SOURCE, `Error converting source to a string. ${convertErrorToMessage(innerError)}`);
      return;
    }
    return await this.logErrorEventInternal(source, error);
  }

  private async logErrorEventInternal(source: string, error: string|Error): Promise<SystemEvent> {
    const message = typeof error === 'string' ? error : this.getFirstNonEmptyString(error?.message, error?.name, error?.stack) || '';
    await this.analyticsService.logErrorEvent(source, message);
    const systemEvent: SystemEvent = {
      timestamp: new Date().getTime(),
      category: SystemEventCategoryEnum.ERROR,
      source,
      message,
      page: this.router.url
    };
    await this.systemEventCargo.push(systemEvent);
    return systemEvent;
  }

  private async housekeeping(): Promise<number> {
    const systemEvents = await this.getStorageData();
    const deletedCount = this.removeOldEvents(systemEvents);
    if (deletedCount) {
      await this.setStorageData(systemEvents);
    }
    return deletedCount;
  }

  private removeOldEvents(systemEvents: Array<SystemEvent>): number {
    if (!systemEvents.length) {
      return 0;
    }
    const maxTime = new Date().getTime() - MAX_AGE_IN_MS;
    const index = _.findLastIndex(systemEvents, (systemEvent) => systemEvent.timestamp < maxTime);
    if (index === -1) {
      return 0;
    }
    const deleted = systemEvents.splice(0, index - 1);
    return deleted.length;
  }

  private async pushSystemEvents(newSystemEvents: Array<SystemEvent>): Promise<Array<SystemEvent>> {
    if (!this.isAuthenticated) {
      this.loggingService.warn(LOG_SOURCE, `Unable to pushSystemEvent because user is not authenticated."`);
      return [];
    }

    const systemEvents = await this.getStorageData();
    this.removeOldEvents(systemEvents);
    newSystemEvents.forEach((newSystemEvent) => systemEvents.push(newSystemEvent));
    await this.setStorageData(systemEvents);
    return systemEvents;
  }

  private async getStorageData(): Promise<Array<SystemEvent>> {
    const systemEvents = await this.storage.get(StorageKeyEnum.SYSTEM_EVENT);
    return systemEvents === null || systemEvents === undefined ? [] : systemEvents;
  }

  private async setStorageData(events: Array<SystemEvent>): Promise<void> {
    await this.storage.set(StorageKeyEnum.SYSTEM_EVENT, events, {
      ensureStored: false,
      immediate: false,
      throttleTimeInMs: FLUSH_TO_STORAGE_TIMEOUT,
    });
  }

  private async clearStorageData() {
    await this.storage.remove(StorageKeyEnum.SYSTEM_EVENT);
  }
}
