import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {LoggingService} from '../common/logging.service';
import {IdType, User, UserOfflineProject} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';

const REST_ENDPOINT_URI = 'api/data/userOfflineProjects/';

@Injectable({
  providedIn: 'root'
})
export class UserOfflineProjectDataService extends AbstractClientAwareDataService<UserOfflineProject> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.USER_OFFLINE_PROJECT, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService);
  }

  public getByProjectId(projectId: IdType): Observable<UserOfflineProject | null> {
    return this.data.pipe(
      map(projects => projects.find(project => project.projectId === projectId))
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
