<ion-content class="with-padding ion-text-center">
  <ion-spinner *ngIf="loading"></ion-spinner>
  <img *ngIf="!loading && (noInternet || errorMessage)" alt="Warning no internet" src="/assets/images/warning.png" class="warning-image" />
  <div class="ion-padding-bottom" *ngIf="loading">{{ 'projectLoader.text' | translate }}</div>
  <div class="ion-padding-bottom" *ngIf="loading && takingLongerThanExpected">{{ 'projectLoader.takingLongerThanExpected' | translate }}</div>
  <div *ngIf="!loading && (noInternet || errorMessage)" class="omg-attention header1">{{ 'projectLoader.infoNoData' | translate }}</div>
  <div *ngIf="!loading && (noInternet || errorMessage)" class="header2">{{ 'projectLoader.errorLoading' | translate }}</div>
  <div *ngIf="!loading && noInternet" class="header3">{{ 'projectLoader.noInternet' | translate }}</div>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text omg-btn-secondary omg-btn-color-danger" (click)="cancel()" *ngIf="loading && allowCancel">{{ 'cancel' | translate }}</ion-button>
    <ion-button class="omg-btn-text" (click)="cancel()" *ngIf="loading === false && success !== undefined">{{ 'okay' | translate }}</ion-button>
    <ion-button class="omg-btn-text" (click)="cancel()" *ngIf="noInternet">{{ 'close' | translate }}</ion-button>
    <ion-button class="omg-btn-text" (click)="tryAgain()" *ngIf="!loading && (errorMessage || noInternet)">{{ 'projectLoader.tryAgain' | translate }}</ion-button>
    <ion-button class="omg-btn-text" (click)="continueInBackground()" *ngIf="loading && (takingLongerThanExpected || !allowCancel) && allowInBackground">{{ 'projectLoader.continueInBackground' | translate }}</ion-button>
  </ion-buttons>
</app-modal-footer>
