import {Component, OnInit} from '@angular/core';
import {AlertController, LoadingController} from '@ionic/angular';
import {StatusReportService} from '../../../services/advanced/status-report.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-send-status-report',
  templateUrl: './send-status-report.component.html',
  styleUrls: ['./send-status-report.component.scss'],
})
export class SendStatusReportComponent implements OnInit {

  private modal: HTMLIonModalElement;

  constructor(private alertController: AlertController, private translateService: TranslateService, private statusReportService: StatusReportService, private loadingController: LoadingController) { }

  ngOnInit() {}

  private async showAlertSendSuccessful(statusReportId: number) {
    const alert = await this.alertController.create(
      {
        header: this.translateService.instant('sendStatusReport.successful.title'),
        message: this.translateService.instant('sendStatusReport.successful.message', {statusReportId}),
        buttons: [{
          text: this.translateService.instant('okay')
        }]
      });
    await alert.present();
  }

  private async showAlertSendFailed(reason: string) {
    const alert = await this.alertController.create(
      {
        header: this.translateService.instant('sendStatusReport.failed.title'),
        message: this.translateService.instant('sendStatusReport.failed.message', {message: reason}),
        buttons: [{
          text: this.translateService.instant('okay')
        }]
      });
    await alert.present();
  }

  public async send() {
    const loading = await this.loadingController.create({message: this.translateService.instant('sendStatusReport.sendingMessage')});
    try {
      await loading.present();
      const statusReportId = await this.statusReportService.sendStatusReport();
      await this.modal.dismiss();
      await this.showAlertSendSuccessful(statusReportId);
    } catch (error) {
      await this.showAlertSendFailed(error.message);
    } finally {
      await loading.dismiss();
    }
  }

  public async cancel() {
    await this.modal.dismiss();
  }
}
