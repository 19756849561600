import {inject, Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntryCardSettings, ENTRY_CARD_DEFAULT_HEIGHT, ENTRY_CARD_SETTINGS_DEFAULT} from '../../model/entry-card-model';
import {StorageKeyEnum} from '../../shared/constants';
import {StorageService} from '../storage.service';

const ANDROID_ENTRY_CARD_DEFAULT_HEIGHT = ENTRY_CARD_DEFAULT_HEIGHT + 13;

@Injectable()
export abstract class BaseEntryCardSettingsService {
  protected readonly entryCardSettingsDefault: EntryCardSettings;
  protected readonly storageKey: StorageKeyEnum;
  private entryCardSettingsSubject = new ReplaySubject<EntryCardSettings>(1);
  entryCardSettings$ = this.entryCardSettingsSubject.asObservable();
  entryCardHeight$ = this.entryCardSettings$.pipe(map((entryCardSettings) => {
    const isAndroidNative = this.isAndroidNative();
    // for some reason the elements on native android are bigger and require a different calculation
    let height = isAndroidNative ? ANDROID_ENTRY_CARD_DEFAULT_HEIGHT : ENTRY_CARD_DEFAULT_HEIGHT;

    if (!entryCardSettings.showId) {
      height -= isAndroidNative ? 22 : 20;
    }
    if (!entryCardSettings.showText) {
      height -= isAndroidNative ? 24 : 22;
    }
    if (!entryCardSettings.showCraft && !entryCardSettings.showType && !entryCardSettings.showPriorityDate && !entryCardSettings.showAttachmentsSub) {
      height -= isAndroidNative ? 49 : 45;
    } else if (entryCardSettings.showCraft && !entryCardSettings.showType && entryCardSettings.showPriorityDate && !entryCardSettings.showAttachmentsSub) {
      // nothing to reduce
    } else {
      const numberOfFieldsShown =
        (entryCardSettings.showCraft ? 1 : 0) + (entryCardSettings.showType ? 1 : 0) + (entryCardSettings.showPriorityDate ? 1 : 0) + (entryCardSettings.showAttachmentsSub ? 1 : 0);
      if (numberOfFieldsShown <= 2) {
        height -= isAndroidNative ? 24 : 22;
      }
    }

    return height;
  }));

  private set entryCardSettings(entryCardSettings: EntryCardSettings) {
    this.entryCardSettingsSubject.next(entryCardSettings);
  }

  constructor(
    storageKey: StorageKeyEnum = StorageKeyEnum.ENTRY_CARD_SETTINGS,
    entryCardSettingsDefault: EntryCardSettings = ENTRY_CARD_SETTINGS_DEFAULT,
    private storage: StorageService = inject(StorageService),
    private platform: Platform = inject(Platform)
  ) {
    this.storageKey = storageKey;
    this.entryCardSettingsDefault = entryCardSettingsDefault ?? ENTRY_CARD_SETTINGS_DEFAULT;
    this.initSettingsFromStorage();
  }

  private async initSettingsFromStorage() {
    const entryCardSettings: EntryCardSettings|undefined = await this.storage.get(this.storageKey);
    this.entryCardSettings = entryCardSettings ?? this.entryCardSettingsDefault;
  }

  async updateEntryCardSettings(entryCardSettings: EntryCardSettings) {
    this.entryCardSettings = entryCardSettings;
    await this.storage.set(this.storageKey, entryCardSettings);
  }

  isAndroidNative(): boolean {
    return this.platform.is('android') && this.platform.is('capacitor');
  }

}
