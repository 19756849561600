import { ColumnConfig } from '../ColumnConfig';

export const ReportMachineColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'filename', prop: 'filename', def: null},
  {name: 'edited', prop: 'edited'},
  {name: 'tags', prop: 'tags',  cast: 'Jsonb', def: null},
  {name: 'client_id', prop: 'clientId', def: null},
  {name: 'import_by_id', prop: 'importById', def: null},
  {name: 'project_id', prop: 'projectId', def: null},
];
