import {AttachmentKind, UnprocessableFileForPdfGenerationError} from '../shared/errors';

const simpleBase64ToUint8Array = (base64: string): Uint8Array => {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const isUint8ArrayJpegOrPng = (headerBytes: Uint8Array): boolean => {
  // Logic taken from pdfmake
  if (headerBytes[0] === 0xff && headerBytes[1] === 0xd8) {
    // JP(E)G
    return true;
  } else if (headerBytes[0] === 0x89 && headerBytes[1] === 'P'.charCodeAt(0) && headerBytes[2] === 'N'.charCodeAt(0) && headerBytes[3] === 'G'.charCodeAt(0)) {
    // PNG
    return true;
  } else {
    return false;
  }
};

export const isJpegOrPng = (base64: string): boolean => {
  // We need 4 bytes to determine JPEG and PNG header.
  // 6 bytes as base64 is 8 characters
  const match = /^data:.+;base64,(.{0,8})/.exec(base64);
  const headerBase64 = (match ? match[1] : base64).substring(0, 8);
  const headerBytes = simpleBase64ToUint8Array(headerBase64);

  return isUint8ArrayJpegOrPng(headerBytes);
};

export const ensureContentIsJpegOrPng = async (file: File, kind: AttachmentKind): Promise<void> => {
  if (['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {
    const header = file.slice(0, 4);
    const arrayHeader = await header.arrayBuffer();
    const array = new Uint8Array(arrayHeader);
    if (!isUint8ArrayJpegOrPng(array)) {
      throw new UnprocessableFileForPdfGenerationError(file, kind);
    }
  }
};
