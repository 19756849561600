import { ColumnConfig } from '../ColumnConfig';

export const ConstructionScheduleLinkColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'source', prop: 'source', cnd: true},
  {name: 'target', prop: 'target', cnd: true},
  {name: 'type', prop: 'type'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'lag', prop: 'lag', def: null}
];
