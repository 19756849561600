import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const bauFlag: IconDefinition = {
  prefix: 'bau' as IconPrefix,
  iconName: 'flag' as IconName,
  icon: [
    13,
    16,
    [],
    null,
    'M13 12H1V2H13L8 7 13 12ZM0 0 1 0 1 18 0 18Z'
  ]
};
