import {Injectable} from '@angular/core';
import {IdType, PdfPlanVersion, User} from 'submodules/baumaster-v2-common';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import _ from 'lodash';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/pdfPlanVersions';
const ORDER_BY: Array<keyof PdfPlanVersion | ((item: PdfPlanVersion) => any)> = ['pdfPlanId', 'number', 'changedAt'];

@Injectable({
  providedIn: 'root'
})
export class PdfPlanVersionDataService extends AbstractProjectAwareDataService<PdfPlanVersion> {

  readonly dataLatestVersion$ = this.data.pipe(map((data) => this.filterLatest(data)));
  readonly dataLatestVersionAcrossProjects$ = this.dataAcrossProjects$.pipe(map((data) => this.filterLatest(data)));
  readonly dataByPlanId$: Observable<Record<IdType, PdfPlanVersion[]>> = this.data.pipe(map((data) => _.groupBy(data, 'pdfPlanId')), shareReplay(1));
  readonly dataByPlanIdAcrossProjects$: Observable<Record<IdType, PdfPlanVersion[]>> = this.dataAcrossProjects$.pipe(map((data) => _.groupBy(data, 'pdfPlanId')), shareReplay(1));

  constructor(http: HttpClient, storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.PDF_PLAN_VERSION, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, ORDER_BY);
  }

  public findByPdfPlan$(pdfPlanId: IdType): Observable<Array<PdfPlanVersion>> {
    return this.data.pipe(map((pdfPlanVersions) => this.filterByPdfPlanOrderByNumber(pdfPlanVersions, pdfPlanId)));
  }

  public findByVersionIds$(versionIds: Array<IdType>) {
    return this.data.pipe(map((pdfPlanVersions) => pdfPlanVersions.filter(pdfPlanVersion => versionIds.includes(pdfPlanVersion.id))));
  }

  public getLatestByPdfPlan$(pdfPlanId: IdType): Observable<PdfPlanVersion|undefined> {
    return this.findByPdfPlan$(pdfPlanId).pipe(map((pdfPlanVersions) => _.last(pdfPlanVersions)));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  public filterLatest(pdfPlanVersions: Array<PdfPlanVersion>): Array<PdfPlanVersion> {
    const grouped: Record<string, Array<PdfPlanVersion>> = _.groupBy(pdfPlanVersions, 'pdfPlanId');
    const latestValues = new Array<PdfPlanVersion>();
    for (const values of Object.values(grouped)) {
      if (values.length) {
        const latestValue = _.last(_.orderBy(values, ORDER_BY));
        latestValues.push(latestValue);
      }
    }
    return latestValues;
  }

  public filterByPdfPlanOrderByNumber(pdfPlanVersions: Array<PdfPlanVersion>, pdfPlanId: IdType): Array<PdfPlanVersion> {
    return _.orderBy(pdfPlanVersions.filter((pdfPlanVersion) => pdfPlanVersion.pdfPlanId === pdfPlanId), ORDER_BY);
  }

  protected async updatedChangedAt(ids: Array<IdType>, newChangedAt: Date|string, clientOrProjectId?: IdType,
                                   changedCallback?: (changedItem: PdfPlanVersion, oldChangedAt: Date|string) => Promise<void>): Promise<Array<PdfPlanVersion>> {
    const localChangedCallback = async (changedItem: PdfPlanVersion, oldChangedAt: Date|string): Promise<void> => {
      if (!changedItem.createdAtDb) {
        changedItem.createdAtDb = changedItem.changedAt;
      }
      if (changedCallback) {
        await changedCallback(changedItem, oldChangedAt);
      }
    };
    return await super.updatedChangedAt(ids, newChangedAt, clientOrProjectId, localChangedCallback);
  }
}
