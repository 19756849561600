<ion-modal class="omg omg-boundary omg-modal notifications-center-modal" [showBackdrop]="false" [isOpen]="isOpen$ | async" (willDismiss)="handleClosed()">
  <ng-template>
    <app-modal-header>
      <ion-title>{{ 'notificationsCenter.title' | translate }}</ion-title>
    </app-modal-header>
    <ion-content>
      <div class="d-flex flex-column h-100" *rxIf="data$; let data">
        <app-notifications-toolbar
          [showSeen]="data.showSeen"
          [unseenCountByMode]="data.unseenCountByMode"
          [projectMode]="data.projectMode"
          (projectModeChange)="handleProjectModeChange($event)"
          (markAllAsSeenClick)="handleMarkAllAsSeen(data.notifications)"
          (settingsClick)="handleSettingsClick()"
          (showSeenClick)="handleShowSeenClick(!data.showSeen)"
        ></app-notifications-toolbar>
        <app-notification-list
          [showProjectName]="data.projectMode === 'allProjects'"
          [notifications]="data.notifications"
          (statusClick)="handleStatusClick($event)"
          (notificationClick)="handleNotificationClick($event)"
        ></app-notification-list>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
