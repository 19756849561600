import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {IdType} from 'submodules/baumaster-v2-common';
import {AuthenticationService} from '../auth/authentication.service';
import {LicenseType, Material, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import { Observable } from 'rxjs';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/materials';

@Injectable({
  providedIn: 'root'
})
export class MaterialDataService extends AbstractProjectAwareDataService<Material> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.MATERIAL, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, ['position', 'changedAt']);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return currentUser.role === LicenseType.BASIC || currentUser.role === LicenseType.PROFESSIONAL || currentUser.assignedReportRights;
  }

  getByReportId(reportId: IdType): Observable<Material[]> {
    return this.data.pipe(
      map((materials) => materials.filter((material) => material.reportId === reportId))
    );
  }

  getActiveByReportId(reportId: IdType): Observable<Material[]> {
    return this.data.pipe(
      map((materials) => materials.filter((material) => material.reportId === reportId && !material.isDeleted))
    );
  }
}
