import {Component, Input, OnInit} from '@angular/core';
import {IdType} from 'submodules/baumaster-v2-common';

@Component({
  selector: 'app-pdf-plan-folder-picker-modal',
  templateUrl: './pdf-plan-folder-picker-modal.component.html',
  styleUrls: ['./pdf-plan-folder-picker-modal.component.scss'],
})
export class PdfPlanFolderPickerModalComponent implements OnInit {

  private modal: HTMLIonModalElement;

  @Input()
  folderId?: IdType;

  constructor() { }

  ngOnInit() {
  }

  save() {
    this.modal.dismiss(this.folderId, 'save');
  }

}
