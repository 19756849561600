import { Directive, forwardRef, Input } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {ColorPickerModalComponent} from 'src/app/components/ui/color-picker-modal/color-picker-modal.component';
import {Nullish} from 'src/app/model/nullish';

@Directive({
  selector: '[appColorPicker]',
  exportAs: 'appColorPicker',
  standalone: true,
  providers: [{
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerDirective),
  }]
})
export class ColorPickerDirective implements ControlValueAccessor {

  value: Nullish<string> = undefined;

  private propagateOnChange = (_: any) => { };
  private propagateOnTouched = () => { };

  private modal: HTMLIonModalElement|null = null;

  @Input()
  colors: string[];

  constructor(private modalController: ModalController) { }

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  setValue(value: Nullish<string>) {
    this.value = value;
    this.propagateOnChange(this.value);
    this.propagateOnTouched();
  }

  async openPicker() {
    if (this.modal) {
      // Modal is already opened; ignore
      return;
    }

    const modal = await this.modalController.create({
      component: ColorPickerModalComponent,
      componentProps: {colors: this.colors, color: this.value},
      cssClass: 'omg omg-modal omg-boundary',
    });

    if (this.modal) {
      // Another modal has been created in the meantime;
      // remove the one has been created during this invocation and ignore
      modal.remove();
      return;
    }

    this.modal = modal;
    this.modal.onDidDismiss().then(() => this.modal = undefined);

    await this.modal.present();

    const result = await this.modal.onWillDismiss<Nullish<string>>();

    if (result.role === 'update') {
      this.setValue(result.data);
    }
  }

}
