<app-modal-header>
  <ion-title>{{ title }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <ng-content></ng-content>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" [ngClass]="cancelProps?.color ? ('omg-btn-color-' + cancelProps?.color) : undefined" (click)="close()" [disabled]="cancelProps?.disabled">{{ cancelLabel || ('cancel' | translate) }}</ion-button>
    <ion-button class="omg-btn-primary" [ngClass]="applyProps?.color ? ('omg-btn-color-' + applyProps?.color) : undefined" [disabled]="applyProps?.disabled"
      (click)="applyClick.emit($event)">{{ applyLabel || ('button.apply' | translate) }}</ion-button>
  </ion-buttons>
</app-modal-footer>
