import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {BehaviorSubject, fromEventPattern, Observable} from 'rxjs';
import {Capacitor} from '@capacitor/core';
import {Keyboard} from '@capacitor/keyboard';
import {SMALL_SCREEN_SIZE} from '../../shared/constants';
import {pluck, shareReplay} from 'rxjs/operators';

export enum Breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

export const DISPLAY_SIZE = {
  [Breakpoints.xs]: 0,
  [Breakpoints.sm]: 576,
  [Breakpoints.md]: 768,
  [Breakpoints.lg]: 992,
  [Breakpoints.xl]: 1200,
  [Breakpoints.xxl]: 1504,
};

export const QUERY = {
  [Breakpoints.xs]: `(min-width: ${DISPLAY_SIZE[Breakpoints.xs]}px)`,
  [Breakpoints.sm]: `(min-width: ${DISPLAY_SIZE[Breakpoints.sm]}px)`,
  [Breakpoints.md]: `(min-width: ${DISPLAY_SIZE[Breakpoints.md]}px)`,
  [Breakpoints.lg]: `(min-width: ${DISPLAY_SIZE[Breakpoints.lg]}px)`,
  [Breakpoints.xl]: `(min-width: ${DISPLAY_SIZE[Breakpoints.xl]}px)`,
  [Breakpoints.xxl]: `(min-width: ${DISPLAY_SIZE[Breakpoints.xxl]}px)`,
};

export enum Devices {
  NATIVE_MOBILE = 'native_mobile',
  NATIVE_TABLET = 'native_tablet',
  WEB = 'web',
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private isAboveMediaQuerySubjects = {};
  private keyboardIsShownSubject = new BehaviorSubject(false);
  private keyboardHeightSubject = new BehaviorSubject(0);
  public keyboardIsShown$ = this.keyboardIsShownSubject.asObservable();
  public keyboardHeight$ = this.keyboardHeightSubject.asObservable();

  constructor(private platform: Platform) {
    if (Capacitor.getPlatform() !== 'web') {
      Keyboard.addListener('keyboardDidShow', (keyboardInfo) => {
        this.keyboardIsShownSubject.next(true);
        this.keyboardHeightSubject.next(keyboardInfo.keyboardHeight);
      });
      Keyboard.addListener('keyboardDidHide', () => {
        this.keyboardIsShownSubject.next(false);
        this.keyboardHeightSubject.next(0);
      });
    }
  }

  isSmallScreen() {
    return Math.min(this.platform.width(), this.platform.height()) <= SMALL_SCREEN_SIZE;
  }

  async isDevice(device: Devices) {
    switch (device) {
      case Devices.NATIVE_MOBILE:
        return this.isNativeMobile();
      case Devices.NATIVE_TABLET:
        return this.isNativeTablet();
      case Devices.WEB:
      default:
        return !(await this.isNativeApp());
    }
  }

  async isNativeApp() {
    return this.platform.is('cordova');
  }

  async isNativeMobile() {
    return await this.isNativeApp() && this.isSmallScreen();
  }

  async isNativeTablet() {
    return await this.isNativeApp() && !this.isSmallScreen();
  }

  isAboveMediaQuerySync(mediaQuery: string): boolean {
    const matchMediaObj = window.matchMedia(mediaQuery);
    return matchMediaObj.matches;
  }

  matchesMediaQuery(mediaQuery: string): Observable<boolean> {
    return this.isAboveMediaQuery(mediaQuery);
  }

  isAboveMediaQuery(mediaQuery: string): Observable<boolean> {
    if (!this.isAboveMediaQuerySubjects[mediaQuery]) {
      this.isAboveMediaQuerySubjects[mediaQuery] = fromEventPattern<MediaQueryListEvent>(
        handler => {
          const matchMediaObj = window.matchMedia(mediaQuery);
          matchMediaObj.addEventListener('change', handler);
          handler({ matches: matchMediaObj.matches });
          return matchMediaObj;
        },
        (handler, matchMediaObj: MediaQueryList) => {
          matchMediaObj.removeEventListener('change', handler);
        }
      ).pipe(
        pluck('matches'),
        // We want to keep single media query to be running
        // and no media query when no subscribers are available
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      );
    }

    return this.isAboveMediaQuerySubjects[mediaQuery];
  }

  isAboveBreakpointSync(breakpoint: Breakpoints): boolean {
    return this.isAboveMediaQuerySync(QUERY[breakpoint]);
  }

  isAboveBreakpoint(breakpoint: Breakpoints): Observable<boolean> {
    return this.isAboveMediaQuery(QUERY[breakpoint]);
  }

  isDesktop(): boolean {
    return this.platform.is('desktop') ||
      ((this.platform.is('mobile') || this.platform.is('mobileweb')) && !this.platform.is('android') && !this.platform.is('ios'));
  }
}
