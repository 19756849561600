<div *ngIf="workflowType === WorkflowTypeEnum.Protocol">
  <ion-item lines="none" class="individual-protocol">
    <!-- [checked]="form.get('individualProtocol').value" (ionChange)="toggleIndividualProtocol()" -->
    <ion-toggle slot="start" [formControl]="form.get('individualProtocol')" class="ion-no-padding" appSlimToggle></ion-toggle>
    <ion-label>
      <div>{{ 'sendProtocol.addIndividualProtocol' | translate }}</div>
      <ion-note>{{ 'sendProtocol.addIndividualProtocolDescription' | translate }} </ion-note>
    </ion-label>
  </ion-item>
</div>

<ng-container *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Protocol">
  <app-next-meeting
    [participants]="participants"
    [nextMeeting]="nextMeeting"
    (nextMeetingChange)="nextMeetingChange.emit($event)"
    [individualNextMeetings]="individualNextMeetings"
    (individualNextMeetingsChange)="individualNextMeetingsChange.emit($event)"></app-next-meeting>
</ng-container>

<ng-container *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Protocol && protocol && !protocol.closedAt && pdfProtocolSignatures">
  <app-pdf-protocol-signatures
    [showSignatures]="showSignatures"
    (showSignaturesChange)="showSignaturesChange.emit($event)"
    [pdfProtocolSignatures]="pdfProtocolSignatures"
    (pdfProtocolSignaturesChange)="pdfProtocolSignaturesChange.emit($event)"></app-pdf-protocol-signatures>
</ng-container>

<ion-item lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report && !report.closedAt">
  <ion-toggle
      slot="start"
      [ngModel]="showReportSignatures"
      (ngModelChange)="showReportSignaturesChange.emit($event)"
      [disabled]="!(isSignatureEnabled$ | async)"
      appSlimToggle
  ></ion-toggle>
  <ion-label>
    <div>{{ "sendProtocol.advancedSettings.sign" | translate }}</div>
    <div class="signatureNoPermission" *ngIf="!(isSignatureEnabled$ | async)">
      {{ "sendProtocol.protocolConfig.signature.noPermission" | translate }}
    </div>
  </ion-label>
</ion-item>

<ion-item lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report && (isUsingTimeBlocks$ | async)">
  <ion-toggle
      slot="start"
      [ngModel]="showStaffTimeBlocks"
      (ngModelChange)="showStaffTimeBlocksChange.emit($event)"
      appSlimToggle
  ></ion-toggle>
  <ion-label>
      {{ "sendProtocol.advancedSettings.showTimeBlocks" | translate }}
  </ion-label>
</ion-item>

<div class="omg-modal-section-header pl-2" *ngIf="workflowType === WorkflowTypeEnum.Report && report">
  {{ 'sendProtocol.advancedSettings.layout' | translate }}
</div>

<ion-item lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
  <ion-toggle
      slot="start"
      [ngModel]="appendActivityAttachments"
      (ngModelChange)="appendActivityAttachmentsChange.emit($event)"
      appSlimToggle
  ></ion-toggle>
  <ion-label>
      {{ "sendProtocol.advancedSettings.activity" | translate }}
  </ion-label>
</ion-item>

<ion-item lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
  <ion-toggle
      slot="start"
      [ngModel]="appendOtherAttachments"
      (ngModelChange)="appendOtherAttachmentsChange.emit($event)"
      appSlimToggle
  ></ion-toggle>
  <ion-label>
      {{ "sendProtocol.advancedSettings.other" | translate }}
  </ion-label>
</ion-item>

<ion-item lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
  <ion-toggle
      slot="start"
      [ngModel]="appendMaterialAttachments"
      (ngModelChange)="appendMaterialAttachmentsChange.emit($event)"
      appSlimToggle
  ></ion-toggle>
  <ion-label>
      {{ "sendProtocol.advancedSettings.material" | translate }}
  </ion-label>
</ion-item>

<ion-item lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
  <ion-toggle
      slot="start"
      [ngModel]="appendEquipmentAttachments"
      (ngModelChange)="appendEquipmentAttachmentsChange.emit($event)"
      appSlimToggle
  ></ion-toggle>
  <ion-label>
      {{ "sendProtocol.advancedSettings.equipment" | translate }}
  </ion-label>
</ion-item>
