import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import _ from 'lodash';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {distinctUntilKeyChanged, map, shareReplay, switchMap} from 'rxjs/operators';
import {ProtocolEntryOrOpen} from 'src/app/model/protocol';
import {ToastService} from 'src/app/services/common/toast.service';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {ProfileDataService} from 'src/app/services/data/profile-data.service';
import {ProjectCompanyDataService} from 'src/app/services/data/project-company-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProtocolEntrySelectionService} from 'src/app/services/protocol/protocol-entry-selection.service';
import {ProtocolEntryService} from 'src/app/services/protocol/protocol-entry.service';
import {SelectedProtocolService} from 'src/app/services/protocol/selected-protocol.service';
import {Breakpoints, DeviceService} from 'src/app/services/ui/device.service';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {getCalendarWeek} from 'src/app/utils/date-utils';
import {getProtocolEntryPagePath, getProtocolEntryPagePathForSearch, getTaskPagePath} from 'src/app/utils/router-utils';
import {getCompanyInitials, getPersonInitials} from 'src/app/utils/text-utils';
import {
  AttachmentChat,
  AttachmentProtocolEntry,
  Company,
  IdType,
  isTaskProtocol,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPageMarking,
  Project,
  ProjectCompany,
  Protocol, PROTOCOL_LAYOUT_NAME_CONTINUOUS,
  ProtocolEntry,
  ProtocolEntryChat,
  ProtocolEntryStatus,
  ProtocolEntryType
} from 'submodules/baumaster-v2-common';
import {AttachmentChatDataService} from '../../../services/data/attachment-chat-data.service';
import {AttachmentEntryDataService} from '../../../services/data/attachment-entry-data.service';
import {CompanyDataService} from '../../../services/data/company-data.service';
import {ProtocolEntryChatDataService} from '../../../services/data/protocol-entry-chat-data.service';
import {ActiveProtocolEntry, ProtocolEntryDataService} from '../../../services/data/protocol-entry-data.service';
import {ProtocolEntryTypeDataService} from '../../../services/data/protocol-entry-type-data.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {PdfPlanService} from '../../../services/pdf/pdf-plan.service';
import {ProtocolEntryFilterService} from '../../../services/protocol/protocol-entry-filter.service';
import {ProtocolService} from '../../../services/protocol/protocol.service';
import {DATE_FORMAT_WITH_SHORT_YEAR, PROTOCOL_LAYOUT_NAME_SHORT, ToastDurationInMs} from '../../../shared/constants';
import {ProtocolDataService} from '../../../services/data/protocol-data.service';
import {v4} from 'uuid';
import {LoggingService} from '../../../services/common/logging.service';
import {convertErrorToMessage} from '../../../shared/errors';

export interface ToggleSubEntries {
  itemIndex: number;
  protocolEntryId: IdType;
  showSubEntries: boolean;
}
const LOG_SOURCE = 'ProtocolEntryComponent';

@Component({
  selector: 'app-protocol-entry',
  templateUrl: './protocol-entry.component.html',
  styleUrls: ['./protocol-entry.component.scss'],
})

export class ProtocolEntryComponent implements OnInit, OnChanges, OnDestroy {
  readonly TAGLINE_DATE_FORMAT = DATE_FORMAT_WITH_SHORT_YEAR;
  protected readonly instanceId = v4();
  @Input() acrossProjects = true;
  @Input() protocolId: IdType;
  @Input() protocolEntryId: IdType;
  @Input() showActiveMarker = false;
  @Input() showSubEntriesCount = true;
  @Input() showFooterButtons = true;
  @Input() showSubEntries: boolean;
  @Input() canEditProtocolEntryStatus = true;
  @Input() canReplaceUrl = true;
  @Input() subEntriesSubset?: IdType[];
  @Input() isNavigable = true;
  @Input() isTask = false;
  @Input() ignoreSelectMode = false;

  @Output() heightChanged = new EventEmitter<ToggleSubEntries>();

  public shouldMarkActive$ = this.deviceService.isAboveBreakpoint(Breakpoints.lg);

  public hasSubEntriesVisible = true;
  public protocolEntryObservable: Observable<ProtocolEntryOrOpen | null>;
  public isTaskProtocol$: Observable<boolean|undefined>;
  public isBeforeCreatedInProtocol$: Observable<boolean|undefined>;
  public createdInProtocolShortName$: Observable<string|undefined>;
  public companyInitials$: Observable<string|null>|undefined;
  public assignedInitials$: Observable<string|null>|undefined;
  public protocolEntryDateObservable: Observable<{dateWeek: string; dateDay: number; date: string|Date} | null>;
  public protocolEntry: ProtocolEntryOrOpen | null | undefined;
  public attachments: Observable<Array<AttachmentProtocolEntry | AttachmentChat>>;
  public protocolEntrySubscription: Subscription | undefined;
  public protocolEntryTypeMap: Record<string, ProtocolEntryType> | null | undefined;
  private selectedProtocol: Protocol | null;
  public selectedProtocolEntry: ProtocolEntry | undefined;
  public selectedProtocolEntrySubscription: Subscription | undefined;
  public attachmentObservable: Observable<Array<AttachmentProtocolEntry>>;
  public pdfPlanMarkerObservable: Observable<Array<PdfPlanMarkerProtocolEntry>>;
  public pdfPlanPageMarkingsObservable: Observable<Array<PdfPlanPageMarking>>;
  public hasPdfPlanMarkingOrMarkerObservable: Observable<boolean>;
  public chatObservable: Observable<Array<ProtocolEntryChat>>;
  public projectCompaniesByCompanyId: Record<IdType, ProjectCompany> | undefined;
  public companiesById: Record<string, Company> | undefined;
  public protocolSubEntriesSubscription: Subscription | undefined;
  public protocolEntryTypeSubscription: Subscription | undefined;
  private selectedProtocolSubscription: Subscription | undefined;
  public isProtocolLayoutShort: boolean|undefined;
  public isProtocolLayoutContinuous: boolean|undefined;
  private typeSubject = new BehaviorSubject<Company | null>(null);
  public readonly type = this.typeSubject.asObservable();
  private protocolLayoutSubscription: Subscription | undefined;
  public protocolSubEntries: Array<ProtocolEntry>|undefined;
  public subEntriesStatusDone = false;
  public subEntriesStatusOpen = false;
  public statusFieldActive = true;
  private companiesByIdSubscription: Subscription;
  private projectCompaniesByCompanyIdSubscription: Subscription;
  private protocolEntryTypeMapSubscription: Subscription;

  get getProtocolEntryPath() {
    if (this.router.url.includes('protocols-search')) {
      return getProtocolEntryPagePathForSearch;
    }
    if (this.router.url.includes('/tasks') || this.isTask) {
      return getTaskPagePath;
    }
    return getProtocolEntryPagePath;
  }

  constructor(
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolDataService: ProtocolDataService,
    private attachmentEntryDataService: AttachmentEntryDataService,
    private attachmentChatDataService: AttachmentChatDataService,
    private companyDataService: CompanyDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private protocolService: ProtocolService,
    private protocolEntryChatDataService: ProtocolEntryChatDataService,
    private protocolEntryService: ProtocolEntryService,
    private protocolEntryFilterService: ProtocolEntryFilterService,
    private systemEventService: SystemEventService,
    private projectService: ProjectDataService,
    private router: Router,
    private toastService: ToastService,
    private cRef: ChangeDetectorRef,
    private selectedProtocolService: SelectedProtocolService,
    private deviceService: DeviceService,
    private addressDataService: AddressDataService,
    private profileDataService: ProfileDataService,
    private pdfPlanService: PdfPlanService,
    private projectCompanyDataService: ProjectCompanyDataService,
    @Optional() public protocolEntrySelectionService: ProtocolEntrySelectionService,
    private loggingService: LoggingService,
  ) {
  }

  async ngOnInit() {
    this.protocolEntryTypeMapSubscription = (this.acrossProjects
      ? this.protocolEntryTypeDataService.dataAcrossClientsGroupedById
      : this.protocolEntryTypeDataService.dataGroupedById
    ).subscribe((protocolEntryTypeMap) => this.protocolEntryTypeMap = protocolEntryTypeMap);
    this.companiesByIdSubscription = (this.getCompanyByIdObservable()
    ).subscribe((companiesById) => this.companiesById = companiesById);
  }

  private getCompanyByIdObservable() {
    return this.acrossProjects
      ? this.companyDataService.dataAcrossClientsGroupedById
      : this.companyDataService.dataGroupedById;
  }

  private getProjectCompaniesByCompanyId$() {
    return this.acrossProjects
      ? this.protocolService.getProjectByEntryId(this.protocolEntryId).pipe(
        switchMap((project) => !project ? of({} as Record<IdType, ProjectCompany>) : this.projectCompanyDataService.getDataByCompanyId$(project.id))
      )
      : this.projectCompanyDataService.dataByCompanyId$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes.protocolEntryId) {
        this.protocolEntryObservable = (this.acrossProjects
          ? this.protocolEntryDataService.getProtocolEntryOrOpenByIdAcrossProjects(this.protocolEntryId)
          : this.protocolEntryDataService.getProtocolEntryOrOpenById(this.protocolEntryId)
        );
        const protocol$ = this.protocolEntryObservable.pipe(
          distinctUntilKeyChanged('protocolId'),
          switchMap((protocolEntry) => !protocolEntry ? undefined :
            (this.acrossProjects ? this.protocolDataService.getByIdAcrossProjects(protocolEntry.protocolId) : this.protocolDataService.getById(protocolEntry.protocolId))));
        this.isTaskProtocol$ = protocol$.pipe(map((protocol) => !protocol ? undefined : isTaskProtocol(protocol)));
        this.projectCompaniesByCompanyIdSubscription?.unsubscribe();
        this.projectCompaniesByCompanyIdSubscription = (this.getProjectCompaniesByCompanyId$()
        ).subscribe((projectCompaniesByCompanyId) => this.projectCompaniesByCompanyId = projectCompaniesByCompanyId);
        this.companyInitials$ = this.getCompanyByIdObservable().pipe(
          switchMap((companyById) => this.protocolEntryObservable.pipe(
            map((entry) => entry.companyId && companyById[entry.companyId] ? getCompanyInitials(companyById[entry.companyId]) : null)
          )),
          shareReplay(1)
        );
        this.assignedInitials$ = this.protocolEntryObservable.pipe(
          switchMap((entry) => (this.acrossProjects ? this.profileDataService.getByIdAcrossClients(entry?.internalAssignmentId) : this.profileDataService.getById(entry?.internalAssignmentId))),
          switchMap((profile) => (this.acrossProjects ? this.addressDataService.getByIdAcrossClients(profile?.addressId) : this.addressDataService.getById(profile?.addressId))),
          map((address) => address ? getPersonInitials(address) : null)
        );
        this.protocolEntryDateObservable = this.protocolEntryObservable.pipe(
          map((entry) => !entry || !entry.todoUntil ? null : {
            date: entry.todoUntil,
            dateWeek: `${getCalendarWeek(new Date(entry.todoUntil))}`,
            dateDay: new Date(entry.todoUntil).getDay(),
          })
        );
        this.attachmentObservable = (this.acrossProjects
          ? this.attachmentEntryDataService.getByProtocolEntryAcrossProjects(this.protocolEntryId)
          : this.attachmentEntryDataService.getByProtocolEntry(this.protocolEntryId)
        );
        this.pdfPlanMarkerObservable = this.pdfPlanService.getLatestPdfPLanMarkers([this.protocolEntryId], this.acrossProjects);
        this.pdfPlanPageMarkingsObservable = this.pdfPlanService.getLatestPdfPlanPageMarkings([this.protocolEntryId], this.acrossProjects);
        this.hasPdfPlanMarkingOrMarkerObservable = combineLatestAsync([
          this.pdfPlanMarkerObservable,
          this.pdfPlanPageMarkingsObservable
        ]).pipe(map(([markers, pdfPlanPageMarkings]) => markers?.length > 0 || pdfPlanPageMarkings?.length > 0));
        this.chatObservable = (this.acrossProjects
          ? this.protocolEntryChatDataService.getByProtocolEntryAcrossProjects(this.protocolEntryId)
          : this.protocolEntryChatDataService.getByProtocolEntry(this.protocolEntryId)
        );
        this.unsubscribeProtocolEntry();
        this.protocolEntrySubscription = this.protocolEntryObservable.subscribe(
          data => {
            this.protocolEntry = data;
            this.protocolLayoutSubscription?.unsubscribe();
            this.protocolLayoutSubscription = undefined;
            if (this.protocolEntry) {
              this.protocolLayoutSubscription = this.protocolService.getProtocolLayoutByProtocolId(this.protocolEntry.protocolId, this.acrossProjects).subscribe(protocolLayout => {
                this.isProtocolLayoutShort = protocolLayout?.name === PROTOCOL_LAYOUT_NAME_SHORT;
                this.isProtocolLayoutContinuous = protocolLayout?.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS;
              });
            }
          }
        );

        this.attachments = combineLatestAsync([
          (this.acrossProjects
            ? this.attachmentEntryDataService.getByProtocolEntryAcrossProjects(this.protocolEntryId)
            : this.attachmentEntryDataService.getByProtocolEntry(this.protocolEntryId)
          ),
          (this.acrossProjects
            ? this.attachmentChatDataService.getByProtocolEntryAcrossProjects(this.protocolEntryId)
            : this.attachmentChatDataService.getByProtocolEntry(this.protocolEntryId)
          ),
        ])
          .pipe(map(([attachmentProtocolEntries, attachmentChats]) => _.concat(attachmentProtocolEntries, attachmentChats)));
      }

      if (changes.protocolEntryId || changes.protocolId) {
        const currentProtocol$ = this.acrossProjects ? this.protocolDataService.getByIdAcrossProjects(this.protocolId) : this.protocolDataService.getById(this.protocolId);
        this.isBeforeCreatedInProtocol$ = this.protocolEntryObservable.pipe(
          distinctUntilKeyChanged('createdInProtocolId'),
          switchMap(({createdInProtocolId}) => this.protocolService.isBeforeCreatedInProtocol$(createdInProtocolId, currentProtocol$, this.acrossProjects))
        );
        this.createdInProtocolShortName$ = this.protocolEntryObservable.pipe(
          distinctUntilKeyChanged('createdInProtocolId'),
          switchMap((protocolEntry) => this.acrossProjects
            ? this.protocolDataService.getByIdAcrossProjects(protocolEntry?.createdInProtocolId)
            : this.protocolDataService.getById(protocolEntry?.createdInProtocolId)),
          switchMap((createdInProtocol) => this.protocolService.getProtocolShortName$(createdInProtocol, this.acrossProjects))
          );
      }

      if (changes.protocolEntryId || changes.showSubEntriesCount || changes.protocolId) {
        this.unsubscribeProtocolSubEntriesSubscription();
        if (this.showSubEntriesCount) {
          this.getProtocolSubEntries();
        }
      }

      this.unsubscribeSelectedProtocolEntry();
      this.selectedProtocolEntrySubscription = this.protocolEntryDataService.getCurrentProtocolEntry()
        .subscribe((activeProtocolEntry: ActiveProtocolEntry) => {
          this.selectedProtocolEntry = activeProtocolEntry?.protocolEntry;
        });

      this.unsubscribeSelectedProtocol();
      this.selectedProtocolSubscription = this.selectedProtocolService.getCurrentProtocol().subscribe((protocol: Protocol) => {
        this.selectedProtocol = protocol;
      });
      if (changes.subEntriesSubset) {
        this.checkSubEntriesSubset();
        this.checkHasSubEntriesVisible();
      }
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `selectedProtocolService.getCurrentProtocol - ${convertErrorToMessage(error)}`);
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' - ngOnChanges', error?.userMessage + '-' + error?.message);
    }
  }

  private checkHasSubEntriesVisible() {
    if (this.protocolSubEntries && this.subEntriesSubset && this.showSubEntries) {
      this.hasSubEntriesVisible = this.protocolSubEntries.some((entry) => this.subEntriesSubset?.includes(entry.id));
    } else {
      this.hasSubEntriesVisible = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeProtocolEntry();
    this.unsubscribeSelectedProtocolEntry();
    this.unsubscribeProtocolSubEntriesSubscription();
    this.unsubscribeProtocolEntryTypeSubscription();
    this.unsubscribeProtocolLayoutSubscription();
    this.unsubscribeSelectedProtocol();
    this.companiesByIdSubscription?.unsubscribe();
    this.projectCompaniesByCompanyIdSubscription?.unsubscribe();
    this.protocolEntryTypeMapSubscription?.unsubscribe();
  }

  private inSplitScreen(): Promise<boolean> {
    return observableToPromise(this.deviceService.isAboveBreakpoint(Breakpoints.lg));
  }

  async editProtocolEntry() {
    const path = this.getProtocolEntryPath(this.selectedProtocol?.id, this.protocolEntryId);
    const isInSplitScreen = await this.inSplitScreen();
    await this.router.navigate(path, {replaceUrl: this.canReplaceUrl && isInSplitScreen});
  }

  private checkSubEntriesSubset() {
    if (this.subEntriesSubset?.length > 0 && !this.showSubEntries) {
      this.heightChanged.emit({
        itemIndex: 0,
        protocolEntryId: this.protocolEntryId,
        showSubEntries: this.showSubEntries
      });
    }
  }

  checkOpenedEntries(protocolSubEntries) {
    if (protocolSubEntries.length > 0) {
      this.showSubEntriesDone();
    }
    protocolSubEntries.forEach((protocolEntry: ProtocolEntry) => {
      if (protocolEntry.status === ProtocolEntryStatus.OPEN) {
        this.showSubEntriesOpen();
        return;
      }
    });
  }

  showSubEntriesDone() {
    this.subEntriesStatusOpen = false;
    this.subEntriesStatusDone = true;
  }

  showSubEntriesOpen() {
    this.subEntriesStatusOpen = true;
    this.subEntriesStatusDone = false;
  }

  toggleSubEntries(event?) {
    event?.stopPropagation();
    if (this.protocolSubEntries?.length) {
      this.showSubEntries = !this.showSubEntries;
      this.heightChanged.emit({
        itemIndex: 0,
        protocolEntryId: this.protocolEntryId,
        showSubEntries: this.showSubEntries
      });
    }
  }

  private getProtocolSubEntries() {
    this.protocolSubEntriesSubscription = combineLatestAsync(
      [this.protocolEntryDataService.getSubEntriesOrOpenByParentEntryId(this.protocolId, this.protocolEntry?.id), this.protocolEntryTypeDataService.dataAcrossClients$,
        this.protocolService.getProtocolById(this.protocolId), this.protocolEntryFilterService.protocolEntryFilters])
      .subscribe(async ([protocolSubEntries, protocolEntryTypes, protocol, protocolEntryFilters]) => {
        if (this.protocolEntry) {
          const subProtocolEntryOrOpen = protocolSubEntries as ProtocolEntryOrOpen[];
          const filterSortEntriesBy = protocolEntryFilters.find((filter) => filter.mode === 'sort' && filter.fieldName === 'sortEntriesBy');
          const sortEntriesBy = filterSortEntriesBy?.value.uniqueId || protocol.sortEntriesBy;
          this.protocolSubEntries = await this.protocolEntryService.sortProtocolEntriesBySortEntryValue(subProtocolEntryOrOpen, sortEntriesBy, {skipSortByOpen: true});
          this.checkOpenedEntries(this.protocolSubEntries);
          this.checkProtocolSubEntriesType(this.protocolSubEntries, protocolEntryTypes);
          this.checkSubEntriesSubset();
          this.checkHasSubEntriesVisible();
          this.cRef.detectChanges();
        }
      });
  }

  private checkProtocolSubEntriesType(protocolEntries, protocolEntryTypes) {
    let isStatusFieldActive = true;
    protocolEntries.forEach((protocolEntry: ProtocolEntry) => {
      if (_.isEmpty(protocolEntry?.typeId)) {
        this.showSubEntriesDone();
        return;
      }

      protocolEntryTypes.forEach((protocolEntryType: ProtocolEntryType) => {
        if (protocolEntry?.typeId === protocolEntryType?.id && !protocolEntryType?.statusFieldActive) {
          isStatusFieldActive = false;
          this.showSubEntriesDone();
          return;
        }
      });

      if (!isStatusFieldActive) {
        return;
      }

    });
  }

  async onChangeProtocolEntryStatus(status: ProtocolEntryStatus) {
    await this.handleStatusOrContinuousInfoChange((protocolEntry) => protocolEntry.status = status);
  }

  async onIsContinuousInfoChange(isContinuousInfo: boolean) {
    await this.handleStatusOrContinuousInfoChange((protocolEntry) => this.protocolEntry.isContinuousInfo = isContinuousInfo);
  }

  private async handleStatusOrContinuousInfoChange(changeFunction: (protocolEntry: ProtocolEntry | null | undefined) => void) {
    if (this.deviceService.isAboveBreakpointSync(Breakpoints.lg)) {
      this.router.navigate(this.getProtocolEntryPath(this.protocolId, this.protocolEntryId));
    }
    changeFunction(this.protocolEntry);
    const project: Project = this.acrossProjects
      ? (await observableToPromise(this.protocolService.getProjectByProtocolId(this.protocolEntry.protocolId)) ?? await this.projectService.getMandatoryCurrentProject())
      : await this.projectService.getMandatoryCurrentProject();
    await this.protocolEntryDataService.update(this.protocolEntry, project.id);
    if (this.protocolEntry.title && this.protocolEntry.title !== '') {
      await this.toastService.toastWithTranslateParams('saving_with_title_success', {title: this.protocolEntry.title}, ToastDurationInMs.INFO);
    } else {
      await this.toastService.savingSuccess();
    }
  }

  protocolClicked() {
    if (this.protocolEntrySelectionService?.isSelectMode && !this.ignoreSelectMode) {
      this.protocolEntrySelectionService.toggle(this.protocolEntry);
    } else {
      this.editProtocolEntry();
    }
  }

  private unsubscribeProtocolEntry(): void {
    if (this.protocolEntrySubscription) {
      this.protocolEntrySubscription.unsubscribe();
      this.protocolEntrySubscription = undefined;
    }
  }

  private unsubscribeSelectedProtocol(): void {
    if (this.selectedProtocolSubscription) {
      this.selectedProtocolSubscription.unsubscribe();
      this.selectedProtocolSubscription = undefined;
    }
  }

  private unsubscribeSelectedProtocolEntry(): void {
    if (this.selectedProtocolEntrySubscription) {
      this.selectedProtocolEntrySubscription.unsubscribe();
      this.selectedProtocolEntrySubscription = undefined;
    }
  }

  private unsubscribeProtocolSubEntriesSubscription() {
    if (this.protocolSubEntriesSubscription) {
      this.protocolSubEntriesSubscription.unsubscribe();
      this.protocolSubEntriesSubscription = undefined;
    }
  }

  private unsubscribeProtocolEntryTypeSubscription() {
    if (this.protocolEntryTypeSubscription) {
      this.protocolEntryTypeSubscription.unsubscribe();
      this.protocolEntryTypeSubscription = null;
    }
  }

  private unsubscribeProtocolLayoutSubscription() {
    if (this.protocolLayoutSubscription) {
      this.protocolLayoutSubscription.unsubscribe();
      this.protocolLayoutSubscription = null;
    }
  }
}
