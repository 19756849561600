<app-modal-header>
  <ion-title *ngIf="!locationId"> {{ 'locationForm.createTitle' | translate }}  </ion-title>
  <ion-title *ngIf="locationId"> {{ 'locationForm.editTitle' | translate }}  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="locationForm" action="#">
    <ion-grid class="grid-gap-4">
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-input label="{{ 'locationForm.name' | translate }}" label-placement="floating" #inputName formControlName="location" maxLength="35" type="text" class="omg-required"></ion-input>
          </ion-item>
          <span *ngIf="locationForm.controls.location.errors?.required && locationForm.controls.location.dirty" class="error-message"> {{ 'formValidation.emptyField' | translate:{field: 'locationForm.name' | translate} }}</span>
          <span *ngIf="locationForm.controls.location.errors?.nameExist && locationForm.controls.location.dirty" class="error-message"> {{ 'locationForm.nameExist' | translate }} </span>
          <span *ngIf="locationForm.controls.location.errors?.nameExistAndInactive && locationForm.controls.location.dirty" class="error-message"> {{ 'locationForm.nameExistAndInactive' | translate }} </span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="start" class="omg-form">
    <ion-item class="omg omg-form-checkbox" (click)="toggleAddToProject()" *ngIf="!locationId && (notConnected$ | async) && !createForSelectable">
     <ion-checkbox label-placement="end" justify="start" [ngModel]="addToProject">
       {{ 'locationForm.addToProject' | translate }}
     </ion-checkbox>
    </ion-item>
    <ion-button *ngIf="locationId" class="omg-btn-secondary omg-btn-color-danger" (click)="deleteLocation(location)">
      <fa-icon slot="start" [icon]="['fal', 'trash-alt']"></fa-icon>
      <span>{{'delete' | translate}}</span>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismissModal()">{{ 'cancel' | translate }}</ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!locationForm?.valid || !locationForm?.dirty || loading" (click)="saveLocation()" >
      <span *ngIf="!locationId">{{'button.create' | translate }}</span>
      <span *ngIf="locationId">{{'button.save' | translate }}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
