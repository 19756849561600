import {ClientAwareKey, NonClientAwareKey, ProjectAwareKey, syncKeyColumnSetMap, SyncKeyColumnSetType} from 'submodules/baumaster-v2-common';
import {DataDependency} from '../model/data-dependency';
import {StorageKeyEnum, StorageKeyOnServerEnum} from './constants';


export const STORAGE_KEY_TO_RAW_SYNC_KEYS: Record<StorageKeyOnServerEnum, ProjectAwareKey | NonClientAwareKey | ClientAwareKey> = {
  [StorageKeyEnum.ADDRESS]: 'addresses',
  [StorageKeyEnum.COUNTRY]: 'countries',
  [StorageKeyEnum.ATTACHMENT_CHAT]: 'attachmentChats',
  [StorageKeyEnum.ATTACHMENT_PROTOCOL_ENTRY]: 'attachmentProtocolEntries',
  [StorageKeyEnum.ATTACHMENT_PROJECT]: 'attachmentProjects',
  [StorageKeyEnum.ATTACHMENT_PROJECT_BANNER]: 'attachmentProjectBanners',
  [StorageKeyEnum.ATTACHMENT_PROJECT_IMAGE]: 'attachmentProjectImages',
  [StorageKeyEnum.ATTACHMENT_CLIENT]: 'attachmentClients',
  [StorageKeyEnum.PROFILE_CRAFT]: 'profileCrafts',
  [StorageKeyEnum.COMPANY_CRAFT]: 'companyCrafts',
  [StorageKeyEnum.COMPANY]: 'companies',
  [StorageKeyEnum.CRAFT]: 'crafts',
  [StorageKeyEnum.PDF_PLAN_MARKER_PROTOCOL_ENTRY]: 'pdfPlanMarkerProtocolEntries',
  [StorageKeyEnum.PDF_PLAN_ATTACHMENT]: 'pdfPlanAttachments',
  [StorageKeyEnum.PDF_PLAN_FOLDER]: 'pdfPlanFolders',
  [StorageKeyEnum.PDF_PLAN_PAGE]: 'pdfPlanPages',
  [StorageKeyEnum.PDF_PLAN]: 'pdfPlans',
  [StorageKeyEnum.PDF_PLAN_VERSION]: 'pdfPlanVersions',
  [StorageKeyEnum.PROFILE]: 'profiles',
  [StorageKeyEnum.PROJECT_COMPANY]: 'projectCompanies',
  [StorageKeyEnum.PROJECT]: 'projects',
  [StorageKeyEnum.PROTOCOL]: 'protocols',
  [StorageKeyEnum.PROTOCOL_ENTRY_CHAT]: 'protocolEntryChats',
  [StorageKeyEnum.PROTOCOL_ENTRY]: 'protocolEntries',
  [StorageKeyEnum.PROTOCOL_ENTRY_LOCATION]: 'protocolEntryLocations',
  [StorageKeyEnum.PROTOCOL_ENTRY_TYPE]: 'protocolEntryTypes',
  [StorageKeyEnum.PROTOCOL_LAYOUT]: 'protocolLayouts',
  [StorageKeyEnum.PROTOCOL_TYPE]: 'protocolTypes',
  [StorageKeyEnum.USER]: 'users',
  [StorageKeyEnum.USER_OFFLINE_PROJECT]: 'userOfflineProjects',
  [StorageKeyEnum.USER_PUBLIC]: 'usersPublic',
  [StorageKeyEnum.USER_INVITE]: 'userInvites',
  [StorageKeyEnum.USER_CONNECTION_INVITE]: 'userConnectionInvites',
  [StorageKeyEnum.PROJECT_CRAFT]: 'projectCrafts',
  [StorageKeyEnum.PROJECT_PROFILE]: 'projectProfiles',
  [StorageKeyEnum.PROJECT_PROTOCOL_ENTRY_TYPE]: 'projectProtocolEntryTypes',
  [StorageKeyEnum.PROJECT_PROTOCOL_LOCATION]: 'projectProtocolLocations',
  [StorageKeyEnum.PROJECT_PROTOCOL_TYPE]: 'projectProtocolTypes',
  [StorageKeyEnum.CLIENT]: 'clients',
  [StorageKeyEnum.NAMEABLE_DROPDOWN]: 'protocolNameableDropdowns',
  [StorageKeyEnum.NAMEABLE_DROPDOWN_ITEM]: 'projectProtocolNameableDropdownItems',
  [StorageKeyEnum.PROTOCOL_OPEN_ENTRY]: 'protocolOpenEntries',
  [StorageKeyEnum.PARTICIPANT]: 'participants',
  [StorageKeyEnum.CUSTOM_PDF_CONFIGURATION]: 'customPdfConfigurations',
  [StorageKeyEnum.PDF_PROTOCOL_SETTING]: 'pdfProtocolSettings',
  [StorageKeyEnum.PDF_PREVIEW]: 'pdfPreviews',
  [StorageKeyEnum.CONSTRUCTION_SCHEDULE_TASK]: 'constructionScheduleTasks',
  [StorageKeyEnum.CONSTRUCTION_SCHEDULE_LINK]: 'constructionScheduleLinks',
  [StorageKeyEnum.REPORT]: 'reports',
  [StorageKeyEnum.REPORT_ACTIVITY]: 'reportActivities',
  [StorageKeyEnum.REPORT_TYPE]: 'reportTypes',
  [StorageKeyEnum.CUSTOM_REPORT_TYPE]: 'customReportTypes',
  [StorageKeyEnum.PROJECT_CUSTOM_REPORT_TYPE]: 'projectCustomReportTypes',
  [StorageKeyEnum.REPORT_EMPLOYEE]: 'reportEmployees',
  [StorageKeyEnum.REPORT_MACHINE]: 'reportMachines',
  [StorageKeyEnum.REPORT_MATERIAL]: 'reportMaterials',
  [StorageKeyEnum.REPORT_SUPPLIER]: 'reportSuppliers',
  [StorageKeyEnum.REPORT_WEEK]: 'reportWeeks',
  [StorageKeyEnum.ATTACHMENT_REPORT_ACTIVITY]: 'attachmentReportActivities',
  [StorageKeyEnum.ATTACHMENT_REPORT_EQUIPMENT]: 'attachmentReportEquipments',
  [StorageKeyEnum.ATTACHMENT_REPORT_MATERIAL]: 'attachmentReportMaterials',
  [StorageKeyEnum.ATTACHMENT_REPORT_COMPANY]: 'attachmentReportCompanies',
  [StorageKeyEnum.ATTACHMENT_REPORT_SIGNATURE]: 'attachmentReportSignatures',
  [StorageKeyEnum.ATTACHMENT_PROTOCOL_SIGNATURE]: 'attachmentProtocolSignatures',
  [StorageKeyEnum.ATTACHMENT_USER_EMAIL_SIGNATURE]: 'attachmentUserEmailSignatures',
  [StorageKeyEnum.ACTIVITY]: 'activities',
  [StorageKeyEnum.EQUIPMENT]: 'equipments',
  [StorageKeyEnum.MATERIAL]: 'materials',
  [StorageKeyEnum.REPORT_COMPANY]: 'reportCompanies',
  [StorageKeyEnum.REPORT_COMPANY_ACTIVITY]: 'reportCompanyActivities',
  [StorageKeyEnum.STAFFING_TYPE]: 'staffingTypes',
  [StorageKeyEnum.STAFF]: 'staffs',
  [StorageKeyEnum.ADDITIONAL_PAY_TYPE]: 'additionalPayTypes',
  [StorageKeyEnum.EMPLOYEE]: 'employees',
  [StorageKeyEnum.PROTOCOL_ENTRY_COMPANY]: 'protocolEntryCompanies',
  [StorageKeyEnum.USER_EMAIL_SIGNATURE]: 'userEmailSignatures',
  [StorageKeyEnum.PDF_PLAN_PAGE_MARKING]: 'pdfPlanPageMarkings',
  [StorageKeyEnum.TAG_CLIENT]: 'tagClients',
  [StorageKeyEnum.TAG_CLIENT_OBJECT]: 'tagClientObjects',
  [StorageKeyEnum.SHARE_VERSIONS_EMAIL_SETTINGS_TEMPLATE]: 'shareVersionsEmailSettingsTemplates',
  [StorageKeyEnum.PDF_PLAN_VERSION_ACCESS]: 'pdfPlanVersionAccesses',
  [StorageKeyEnum.PDF_PLAN_VERSION_DISTRIBUTION_ACCESS]: 'pdfPlanVersionDistributionAccesses',
  [StorageKeyEnum.APP_TOKEN]: 'appTokenPermissions',
  [StorageKeyEnum.APP_TOKEN_PERMISSION]: 'appTokens',
  [StorageKeyEnum.CALENDAR]: 'calendars',
  [StorageKeyEnum.CALENDAR_DAY]: 'calendarDays',
  [StorageKeyEnum.CLIENT_CALENDAR]: 'clientCalendars',
  [StorageKeyEnum.CLIENT_CALENDAR_DAY]: 'clientCalendarDays',
  [StorageKeyEnum.PROJECT_CALENDAR]: 'projectCalendars',
  [StorageKeyEnum.PROJECT_CALENDAR_DAY]: 'projectCalendarDays',
  [StorageKeyEnum.PROJECT_CLIENT_CALENDAR]: 'projectClientCalendars',
  [StorageKeyEnum.TEXT_TEMPLATE]: 'textTemplates',
  [StorageKeyEnum.CLIENT_TEXT_TEMPLATE]: 'clientTextTemplates',
  [StorageKeyEnum.BIM_MARKER]: 'bimMarkers',
  [StorageKeyEnum.ATTACHMENT_BIM_MARKER_SCREENSHOT]: 'attachmentBimMarkerScreenshots',
  [StorageKeyEnum.BIM_PLAN]: 'bimPlans',
  [StorageKeyEnum.BIM_VERSION]: 'bimVersions',
  [StorageKeyEnum.BIM_VERSION_ACCESS]: 'bimVersionAccesses',
  [StorageKeyEnum.USER_DEVICE_OFFLINE_PROJECT]: 'userDeviceOfflineProjects',
  [StorageKeyEnum.NOTIFICATION_EVENT]: 'notificationEvents',
  [StorageKeyEnum.NOTIFICATION_EVENT_RECIPIENT]: 'notificationEventRecipients',
  [StorageKeyEnum.NOTIFICATION_CONFIG]: 'notificationConfigs',
  [StorageKeyEnum.NOTIFICATION_CONFIG_RECIPIENT]: 'notificationConfigRecipients',
  [StorageKeyEnum.UNIT_LEVEL]: 'unitLevels',
  [StorageKeyEnum.UNIT]: 'units',
  [StorageKeyEnum.UNIT_PROFILE]: 'unitProfiles',
};

export const RAW_SYNC_KEY_TO_STORAGE_KEYS: Record<ProjectAwareKey | NonClientAwareKey | ClientAwareKey, StorageKeyOnServerEnum> = {
  addresses: StorageKeyEnum.ADDRESS,
  countries: StorageKeyEnum.COUNTRY,
  attachmentChats: StorageKeyEnum.ATTACHMENT_CHAT,
  attachmentProtocolEntries: StorageKeyEnum.ATTACHMENT_PROTOCOL_ENTRY,
  attachmentProjects: StorageKeyEnum.ATTACHMENT_PROJECT,
  attachmentProjectBanners: StorageKeyEnum.ATTACHMENT_PROJECT_BANNER,
  attachmentProjectImages: StorageKeyEnum.ATTACHMENT_PROJECT_IMAGE,
  attachmentClients: StorageKeyEnum.ATTACHMENT_CLIENT,
  profileCrafts: StorageKeyEnum.PROFILE_CRAFT,
  companyCrafts: StorageKeyEnum.COMPANY_CRAFT,
  companies: StorageKeyEnum.COMPANY,
  crafts: StorageKeyEnum.CRAFT,
  pdfPlanMarkerProtocolEntries: StorageKeyEnum.PDF_PLAN_MARKER_PROTOCOL_ENTRY,
  pdfPlanAttachments: StorageKeyEnum.PDF_PLAN_ATTACHMENT,
  pdfPlanFolders: StorageKeyEnum.PDF_PLAN_FOLDER,
  pdfPlanPages: StorageKeyEnum.PDF_PLAN_PAGE,
  pdfPlans: StorageKeyEnum.PDF_PLAN,
  pdfPlanVersions: StorageKeyEnum.PDF_PLAN_VERSION,
  profiles: StorageKeyEnum.PROFILE,
  projectCompanies: StorageKeyEnum.PROJECT_COMPANY,
  projects: StorageKeyEnum.PROJECT,
  protocols: StorageKeyEnum.PROTOCOL,
  protocolEntryChats: StorageKeyEnum.PROTOCOL_ENTRY_CHAT,
  protocolEntries: StorageKeyEnum.PROTOCOL_ENTRY,
  protocolEntryLocations: StorageKeyEnum.PROTOCOL_ENTRY_LOCATION,
  protocolEntryTypes: StorageKeyEnum.PROTOCOL_ENTRY_TYPE,
  protocolLayouts: StorageKeyEnum.PROTOCOL_LAYOUT,
  protocolTypes: StorageKeyEnum.PROTOCOL_TYPE,
  users: StorageKeyEnum.USER,
  userOfflineProjects: StorageKeyEnum.USER_OFFLINE_PROJECT,
  usersPublic: StorageKeyEnum.USER_PUBLIC,
  userInvites: StorageKeyEnum.USER_INVITE,
  userConnectionInvites: StorageKeyEnum.USER_CONNECTION_INVITE,
  projectCrafts: StorageKeyEnum.PROJECT_CRAFT,
  projectProfiles: StorageKeyEnum.PROJECT_PROFILE,
  projectProtocolEntryTypes: StorageKeyEnum.PROJECT_PROTOCOL_ENTRY_TYPE,
  projectProtocolLocations: StorageKeyEnum.PROJECT_PROTOCOL_LOCATION,
  projectProtocolTypes: StorageKeyEnum.PROJECT_PROTOCOL_TYPE,
  clients: StorageKeyEnum.CLIENT,
  protocolNameableDropdowns: StorageKeyEnum.NAMEABLE_DROPDOWN,
  projectProtocolNameableDropdownItems: StorageKeyEnum.NAMEABLE_DROPDOWN_ITEM,
  protocolOpenEntries: StorageKeyEnum.PROTOCOL_OPEN_ENTRY,
  participants: StorageKeyEnum.PARTICIPANT,
  customPdfConfigurations: StorageKeyEnum.CUSTOM_PDF_CONFIGURATION,
  pdfProtocolSettings: StorageKeyEnum.PDF_PROTOCOL_SETTING,
  pdfPreviews: StorageKeyEnum.PDF_PREVIEW,
  constructionScheduleTasks: StorageKeyEnum.CONSTRUCTION_SCHEDULE_TASK,
  constructionScheduleLinks: StorageKeyEnum.CONSTRUCTION_SCHEDULE_LINK,
  reports: StorageKeyEnum.REPORT,
  reportActivities: StorageKeyEnum.REPORT_ACTIVITY,
  reportTypes: StorageKeyEnum.REPORT_TYPE,
  customReportTypes: StorageKeyEnum.CUSTOM_REPORT_TYPE,
  projectCustomReportTypes: StorageKeyEnum.PROJECT_CUSTOM_REPORT_TYPE,
  reportEmployees: StorageKeyEnum.REPORT_EMPLOYEE,
  reportMachines: StorageKeyEnum.REPORT_MACHINE,
  reportMaterials: StorageKeyEnum.REPORT_MATERIAL,
  reportSuppliers: StorageKeyEnum.REPORT_SUPPLIER,
  reportWeeks: StorageKeyEnum.REPORT_WEEK,
  attachmentReportActivities: StorageKeyEnum.ATTACHMENT_REPORT_ACTIVITY,
  attachmentReportEquipments: StorageKeyEnum.ATTACHMENT_REPORT_EQUIPMENT,
  attachmentReportMaterials: StorageKeyEnum.ATTACHMENT_REPORT_MATERIAL,
  attachmentReportCompanies: StorageKeyEnum.ATTACHMENT_REPORT_COMPANY,
  attachmentReportSignatures: StorageKeyEnum.ATTACHMENT_REPORT_SIGNATURE,
  attachmentProtocolSignatures: StorageKeyEnum.ATTACHMENT_PROTOCOL_SIGNATURE,
  attachmentUserEmailSignatures: StorageKeyEnum.ATTACHMENT_USER_EMAIL_SIGNATURE,
  activities: StorageKeyEnum.ACTIVITY,
  equipments: StorageKeyEnum.EQUIPMENT,
  materials: StorageKeyEnum.MATERIAL,
  reportCompanies: StorageKeyEnum.REPORT_COMPANY,
  reportCompanyActivities: StorageKeyEnum.REPORT_COMPANY_ACTIVITY,
  staffingTypes: StorageKeyEnum.STAFFING_TYPE,
  staffs: StorageKeyEnum.STAFF,
  additionalPayTypes: StorageKeyEnum.ADDITIONAL_PAY_TYPE,
  employees: StorageKeyEnum.EMPLOYEE,
  protocolEntryCompanies: StorageKeyEnum.PROTOCOL_ENTRY_COMPANY,
  userEmailSignatures: StorageKeyEnum.USER_EMAIL_SIGNATURE,
  pdfPlanPageMarkings: StorageKeyEnum.PDF_PLAN_PAGE_MARKING,
  tagClients: StorageKeyEnum.TAG_CLIENT,
  tagClientObjects: StorageKeyEnum.TAG_CLIENT_OBJECT,
  shareVersionsEmailSettingsTemplates: StorageKeyEnum.SHARE_VERSIONS_EMAIL_SETTINGS_TEMPLATE,
  pdfPlanVersionAccesses: StorageKeyEnum.PDF_PLAN_VERSION_ACCESS,
  pdfPlanVersionDistributionAccesses: StorageKeyEnum.PDF_PLAN_VERSION_DISTRIBUTION_ACCESS,
  appTokens: StorageKeyEnum.APP_TOKEN,
  appTokenPermissions: StorageKeyEnum.APP_TOKEN_PERMISSION,
  calendars: StorageKeyEnum.CALENDAR,
  calendarDays: StorageKeyEnum.CALENDAR_DAY,
  clientCalendars: StorageKeyEnum.CLIENT_CALENDAR,
  clientCalendarDays: StorageKeyEnum.CLIENT_CALENDAR_DAY,
  projectCalendars: StorageKeyEnum.PROJECT_CALENDAR,
  projectCalendarDays: StorageKeyEnum.PROJECT_CALENDAR_DAY,
  projectClientCalendars: StorageKeyEnum.PROJECT_CLIENT_CALENDAR,
  textTemplates: StorageKeyEnum.TEXT_TEMPLATE,
  clientTextTemplates: StorageKeyEnum.CLIENT_TEXT_TEMPLATE,
  bimMarkers: StorageKeyEnum.BIM_MARKER,
  attachmentBimMarkerScreenshots: StorageKeyEnum.ATTACHMENT_BIM_MARKER_SCREENSHOT,
  bimPlans: StorageKeyEnum.BIM_PLAN,
  bimVersions: StorageKeyEnum.BIM_VERSION,
  bimVersionAccesses: StorageKeyEnum.BIM_VERSION_ACCESS,
  userDeviceOfflineProjects: StorageKeyEnum.USER_DEVICE_OFFLINE_PROJECT,
  notificationEvents: StorageKeyEnum.NOTIFICATION_EVENT,
  notificationEventRecipients: StorageKeyEnum.NOTIFICATION_EVENT_RECIPIENT,
  notificationConfigs: StorageKeyEnum.NOTIFICATION_CONFIG,
  notificationConfigRecipients: StorageKeyEnum.NOTIFICATION_CONFIG_RECIPIENT,
  unitLevels: StorageKeyEnum.UNIT_LEVEL,
  units: StorageKeyEnum.UNIT,
  unitProfiles: StorageKeyEnum.UNIT_PROFILE,
};

const buildDataDependenciesFromDbColumnSets = (columnSets: SyncKeyColumnSetType): Map<StorageKeyEnum, DataDependency[]> => {
  const output = new Map<StorageKeyEnum, DataDependency[]>();
  Object.entries(columnSets).forEach(([key, value]) => {
    const mappedByColumns = value.filter(({mappedBy}) => Boolean(mappedBy));

    mappedByColumns.forEach((column) => {
      const fkColumn = column.mappedBy.find((c) => c.prop === column.fk || c.name === column.fk);
      // When RAW_SYNC_KEY_TO_STORAGE_KEYS[column.name] is undefined, it means that FK repository is server-only repository
      // Any potential data dependency errors should be handled by the server or/and DB.
      if (fkColumn && RAW_SYNC_KEY_TO_STORAGE_KEYS[column.name]) {
        const dep = output.get(RAW_SYNC_KEY_TO_STORAGE_KEYS[key]) ?? [];
        dep.push({
          sourceKeyPath: 'id',
          destinationKeyPath: column.fk,
          destinationStorageKey: RAW_SYNC_KEY_TO_STORAGE_KEYS[column.name],
          direction: 'DESTINATION_TO_SOURCE',
          optional: fkColumn.def === null
        });
        output.set(RAW_SYNC_KEY_TO_STORAGE_KEYS[key], dep);

        const otherDep = output.get(RAW_SYNC_KEY_TO_STORAGE_KEYS[column.name]) ?? [];
        otherDep.push({
          sourceKeyPath: column.fk,
          destinationKeyPath: 'id',
          destinationStorageKey: RAW_SYNC_KEY_TO_STORAGE_KEYS[key],
          direction: 'SOURCE_TO_DESTINATION',
          optional: fkColumn.def === null
        });
        output.set(RAW_SYNC_KEY_TO_STORAGE_KEYS[column.name], otherDep);
      }
    });
  });

  return output;
};

export const DATA_DEPENDENCIES = buildDataDependenciesFromDbColumnSets(syncKeyColumnSetMap);
