import { ColumnConfig } from '../ColumnConfig';
import { AttachmentReportActivityColumnSet } from './AttachmentReportActivityColumnSet';
import { PdfPlanMarkerProtocolEntryColumnSet } from './PdfPlanMarkerProtocolEntryColumnSet';
import { ReportCompanyActivityColumnSet } from './ReportCompanyActivityColumnSet';

export const ActivityColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'status_editor', prop: 'statusEditor', def: null},
  {name: 'status_employee', prop: 'statusEmployee', def: null},
  {name: 'is_deleted', prop: 'isDeleted'},
  {name: 'position', prop: 'position', def: null},
  {name: 'description', prop: 'description', def: null},
  {name: 'type', prop: 'type'},
  {name: 'hours', prop: 'hours', def: null},
  {name: 'location_id', prop: 'locationId', def: null},
  {name: 'modified_by_id', prop: 'modifiedById', def: null},
  {name: 'report_id', prop: 'reportId'},
  {name: 'attachmentReportActivities', fk: 'activityId', mappedBy: AttachmentReportActivityColumnSet, transient: true},
  {name: 'reportCompanyActivities', fk: 'activityId', mappedBy: ReportCompanyActivityColumnSet, transient: true},
  {name: 'pdfPlanMarkerProtocolEntries', fk: 'activityId', mappedBy: PdfPlanMarkerProtocolEntryColumnSet, transient: true}
];

