<div class="pl-5 pr-5 pt-2 pb-2 d-flex gap-3" *ngIf="hasFilters$ | async">
  <ion-button class="omg-btn-secondary" (click)="resetFilters()">
    <fa-layers slot="start">
      <fa-icon [icon]="['fal', 'filter']" transform="left-2"></fa-icon>
      <fa-icon [icon]="['fas', 'times-circle']" transform="shrink-4 down-4 right-4"></fa-icon>
    </fa-layers>
    <span>{{'reset' | translate}}</span>
  </ion-button>
  <div class="omg-attention omg-attention-warning filter-state">
    {{'tasks.list.filtered'|translate}} {{totalVsFilteredEntryCount$ | async}}
  </div>
</div>

<div class="pl-5 pr-5 pt-4 d-flex ion-align-items-center ion-justify-content-between gap-3" *ngIf="protocolEntrySelectionService.isSelectMode$ | async">
  <ion-item lines="none" color="transparent">
    <ion-checkbox label-placement="end" justify="start" class="omg-checkbox flex-grow-0 margin-right-10" [ngModel]="protocolEntrySelectionService.includingSubentries$ | async" (ngModelChange)="protocolEntrySelectionService.setIncludingSubentries($event)">
      <span class="ion-text-nowrap">{{ 'protocolEntry.selection.includeSubentries' | translate }}</span>
    </ion-checkbox>
  </ion-item>
  <ion-item lines="none" color="transparent">
    <ion-checkbox
      slot="end"
      label-placement="start"
      justify="end"
      class="omg-checkbox flex-grow-0 margin-left-10"
      [ngModel]="allSelected$ | async"
      (ngModelChange)="protocolEntrySelectionService.hasAllSelected ? protocolEntrySelectionService.unselectAll() : protocolEntrySelectionService.selectAll()"
      [indeterminate]="someSelected$ | async"
    >
      <strong>{{ selectedEntriesCount$ | async }}</strong>/{{ allEntriesCount$ | async }}
    </ion-checkbox>
  </ion-item>
</div>

<rx-virtual-scroll-viewport autosize [tombstoneSize]="entryCardHeight">
  <div class="d-flex flex-column task-header-container" *rxVirtualFor="let entry of entries$; trackBy trackByIdAndHeight; strategy: 'local'; renderCallback: entriesRendered" [class.task-space-subtask]="displaySubentriesFlat ? false : entry.isSubtask">
    <ng-container *ngIf="entry.firstInGroup && groupHeaderTemplate">
      <ng-container *ngTemplateOutlet="groupHeaderTemplate; context: {entry}"></ng-container>
    </ng-container>
    <div class="task-space">
      <div class="subtask-line" *ngIf="displaySubentriesFlat ? false : entry.isSubtask"></div>
      <app-entry-card
        [showThreeDots]="!(protocolEntrySelectionService.isSelectMode$ | async)"
        (threeDotsClick)="threeDotsClick.emit({event: $event, entry})"
        [class.selected]="selectedEntryId && selectedEntryId === entry.id"
        (click)="entryClick.emit({event: $event, entry})"
        (statusClick)="showStatusPopover($event, entry)"
        [taskNumber]="entry.taskNumber"
        [protocolEntryNumber]="entry.protocolEntryNumber"
        [titleText]="entry.titleText"
        [text]="entry.text"
        [company]="entry.company"
        [craft]="entry.craft"
        [type]="entry.type"
        [priority]="entry.priority"
        [hasMarker]="entry.hasMarker"
        [hasAttachments]="entry.hasAttachments"
        [hasChat]="entry.hasChat"
        [subEntriesCount]="entry.subEntriesCount"
        [status]="entry.status"
        [isNew]="entry.isNew"
        [date]="entry.todoUntil"
        [isProtocolClosed]="entry.isProtocolClosed"
        [isCarriedOver]="entry.isCarriedOver"
        [isContinuousInfo]="entry.isContinuousInfo"
        [createdInProtocol]="entry.showCreatedInProtocol ? entry.createdInProtocol : undefined"
        [checkboxSelected]="isCheckboxSelected$(entry) | async"
        (checkboxSelectedChange)="toggleSelection(entry)"
        [entryCardSettings]="entryCardSettings"
        [showSelectCheckbox]="showSelectCheckbox === false ? false: isEditEnabled$ | async"
      >
        <ng-template appEntryCardFooterTemplate>
          <ng-container *ngTemplateOutlet="cardFooterTemplate; context: {entry}"></ng-container>
        </ng-template>
      </app-entry-card>
    </div>
  </div>
</rx-virtual-scroll-viewport>
