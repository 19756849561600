import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {observableToPromise} from 'src/app/utils/async-utils';
import {IdType, Protocol, ProtocolEntry, ProtocolEntryCompany, ProtocolType} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolService} from '../protocol/protocol.service';
import {AlertService} from '../ui/alert.service';
import {ProtocolEntryService} from '../protocol/protocol-entry.service';
import {ProtocolEntryCompanyDataService} from '../data/protocol-entry-company-data.service';
import {isTaskProtocol} from '../../utils/protocol-utils';
import {ToastService} from '../common/toast.service';
import {ModalController} from '@ionic/angular';
import {SelectMainEntryModalComponent} from '../../components/entry/select-main-entry-modal/select-main-entry-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {convertErrorToMessage} from '../../shared/errors';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {getProtocolEntryShortId} from '../../utils/protocol-entry-utils';
import {SystemEventService} from '../event/system-event.service';

const LOG_SOURCE = 'ConvertEntryService';

interface ConvertInformation {
  protocolEntry: ProtocolEntry;
  subEntries: Array<ProtocolEntry>;
  protocol: Protocol;
  protocolType: ProtocolType;
  isTaskProtocol: boolean;
  isContinuousProtocol: boolean;
  isCarriedEntry: boolean;
  isCreatedInCurrentProtocolAsSubEntryOfCarried: boolean;
  protocolEntryCompanies: Array<ProtocolEntryCompany>;
}

@Injectable({
  providedIn: 'root'
})
export class ConvertEntryService {

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private protocolEntryService: ProtocolEntryService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolService: ProtocolService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
    private alertService: AlertService,
    private toastService: ToastService,
    private loggingService: LoggingService,
    private systemEventService: SystemEventService,
    private modalController: ModalController,
    private translateService: TranslateService,
  ) { }

  public async convertToMainEntryWithConfirm(protocolId: IdType, protocolEntryId: IdType) {
    let translationPrefix = 'convertToMainOrSubEntry';
    try {
      const convertInformation = await this.getConvertInformation(protocolId, protocolEntryId);
      const canBeConverted = await this.assertConvertToMainEntry(protocolEntryId, convertInformation, true);
      if (!canBeConverted) {
        return;
      }
      const oldShortId = getProtocolEntryShortId(convertInformation.protocol, convertInformation.protocolType, convertInformation.protocolEntry.number, this.locale);
      const nextNumber = await this.protocolEntryService.getNextNumber(convertInformation.protocol);
      const newShortId = getProtocolEntryShortId(convertInformation.protocol, convertInformation.protocolType, nextNumber, this.locale);
      translationPrefix = convertInformation.isTaskProtocol ? 'convertToMainOrSubTask' : 'convertToMainOrSubEntry';
      const confirmed = await this.alertService.confirm({header: `${translationPrefix}.toMain.title`,
        message: this.translateService.instant(
          `${translationPrefix}.toMain.confirmMessage`,
          {oldShortId, newShortId}
        ),
        confirmLabel: 'button.apply',
        confirmButton: {
          color: 'text-primary',
          fill: 'solid',
        }
      });
      if (!confirmed) {
        return;
      }
      await this.convertToMainEntry(protocolEntryId, convertInformation);
      this.toastService.info(`${translationPrefix}.successful`);
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `convertToMainEntryWithConfirm of protocolEntry ${protocolEntryId} failed with error ${convertErrorToMessage(error)}`);
      this.systemEventService.logErrorEvent(LOG_SOURCE + 'convertToMainEntryWithConfirm', error);
      this.toastService.error(this.translateService.instant(`${translationPrefix}.error`, {errorMessage: convertErrorToMessage(error)}));
    }
  }

  public async convertToSubEntryWithConfirm(protocolId: IdType, protocolEntryId: IdType) {
    let translationPrefix = 'convertToMainOrSubEntry';
    try {
      const convertInformation = await this.getConvertInformation(protocolId, protocolEntryId);
      translationPrefix = convertInformation.isTaskProtocol ? 'convertToMainOrSubTask' : 'convertToMainOrSubEntry';
      if (!(await this.assertConvertToSubEntry(protocolEntryId, undefined, convertInformation, true))) {
        return;
      }
      const newParentEntryId = await this.selectParentEntryIdOfProtocol(convertInformation.protocol.id, protocolEntryId,
        convertInformation.subEntries.length, !!convertInformation.protocolEntryCompanies.length, convertInformation.isTaskProtocol);
      if (!newParentEntryId) {
        return;
      }
      if (!(await this.assertConvertToSubEntry(protocolEntryId, newParentEntryId, convertInformation, true))) {
        return;
      }
      await this.convertToSubEntry(protocolEntryId, newParentEntryId, convertInformation);
      this.toastService.info(`${translationPrefix}.successful`);
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `convertToSubEntryWithConfirm of protocolEntry ${protocolEntryId} failed with error ${convertErrorToMessage(error)}`);
      this.systemEventService.logErrorEvent(LOG_SOURCE + 'convertToSubEntryWithConfirm', error);
      this.toastService.error(this.translateService.instant(`${translationPrefix}.error`, {errorMessage: convertErrorToMessage(error)}));
    }
  }

  private async getConvertInformation(protocolId: IdType, protocolEntryId: IdType): Promise<ConvertInformation> {
    const protocolEntry = await this.protocolEntryService.getProtocolEntry(protocolEntryId);
    if (!protocolEntry) {
      throw new Error(`No ProtocolEntry with id ${protocolEntryId} not found.`);
    }
    const subEntries = await observableToPromise(this.protocolEntryDataService.getSubEntriesByParentEntryId(protocolEntryId));
    const protocol = await this.protocolService.getProtocolById(protocolId);
    if (!protocol) {
      throw new Error(`No protocol with id ${protocolEntry.protocolId} not found.`);
    }
    const protocolType = await observableToPromise(this.protocolTypeDataService.getById(protocol.typeId));
    if (!protocolType) {
      throw new Error(`No protocolType with id ${protocol.typeId} not found.`);
    }
    const protocolEntryCompanies = await observableToPromise(this.protocolEntryCompanyDataService.findAllByProtocolEntryIds([protocolEntryId]));
    const isContinuousProtocol = await this.protocolService.isContinuousProtocol(protocol);
    const isCreatedInCurrentProtocolAsSubEntryOfCarried = isContinuousProtocol && protocolEntry.createdInProtocolId && protocolEntry.createdInProtocolId === protocolId;
    const isCarriedEntry = isContinuousProtocol && !isCreatedInCurrentProtocolAsSubEntryOfCarried && (protocolEntry.createdInProtocolId ?? protocolEntry.protocolId) !== protocolId;
    return {protocolEntry, subEntries, protocolEntryCompanies, protocol, protocolType, isContinuousProtocol, isTaskProtocol: isTaskProtocol(protocol),
      isCarriedEntry, isCreatedInCurrentProtocolAsSubEntryOfCarried};
  }

  private async selectParentEntryIdOfProtocol(protocolId: IdType, protocolEntryIdToExclude: IdType,
    numberOfSubEntries: number, observerCompanyValue: boolean, isTaskProtocol: boolean): Promise<IdType|undefined> {
    const translationPrefix = isTaskProtocol ? 'convertToMainOrSubTask' : 'convertToMainOrSubEntry';
    const title = this.translateService.instant(`${translationPrefix}.toSub.title`);
    const subtitle = this.translateService.instant(`${translationPrefix}.toSub.subtitle`);
    const warningMessages = new Array<string>();
    if (numberOfSubEntries) {
      warningMessages.push(this.translateService.instant(`${translationPrefix}.messages.subEntriesAlsoMoved`, {numberOfSubEntries}));
    }
    if (observerCompanyValue) {
      warningMessages.push(this.translateService.instant(`${translationPrefix}.messages.observerCompaniesWillBeRemoved`, {numberOfSubEntries}));
    }
    const modal = await this.modalController.create({
      component: SelectMainEntryModalComponent,
      cssClass: 'omg-boundary omg-modal omg-in-modal-list',
      componentProps: {
        protocolId,
        protocolEntryIdToExclude,
        title,
        subtitle,
        warningMessages
      },
    });

    await modal.present();

    const {role, data} = await modal.onWillDismiss();
    if (role === 'confirm-target') {
      return data;
    }
    return undefined;
  }

  private async assertConvertToMainEntry(protocolEntryId: IdType, convertInformation: ConvertInformation, showToastAndNoThrow: boolean): Promise<boolean> {
    const {protocol, isCarriedEntry, protocolEntry} = convertInformation;
    const translationPrefix = convertInformation.isTaskProtocol ? 'convertToMainOrSubTask' : 'convertToMainOrSubEntry';
    if (protocol.closedAt) {
      if (showToastAndNoThrow) {
        await this.toastService.error('convertToMainOrSubEntry.messages.protocolClosed');
        return false;
      }
      throw new Error(`Cannot convert entry since protocol ${protocol.id} is already closed.`);
    }
    if (!protocolEntry.parentId) {
      if (showToastAndNoThrow) {
        await this.toastService.error(`${translationPrefix}.messages.isAlreadyParent`);
        return false;
      }
      throw new Error(`Cannot convert entry ${protocolEntryId} to a main entry as it is already a main entry (parentId is null).`);
    }
    if (isCarriedEntry) {
      if (showToastAndNoThrow) {
        await this.toastService.error('convertToMainOrSubEntry.messages.isCarriedEntry');
        return false;
      }
      throw new Error(`Cannot convert entry ${protocolEntryId} as it is a carried entry.`);
    }
    return true;
  }

  private async convertToMainEntry(protocolEntryId: IdType, convertInformation: ConvertInformation, expectedProtocolEntryNumber?: number) {
    const {protocol, protocolEntry} = convertInformation;
    const projectId = protocol.projectId;
    await this.assertConvertToMainEntry(protocolEntryId, convertInformation, false);
    const nextNumber = await this.protocolEntryService.getNextNumber(protocol);
    if (expectedProtocolEntryNumber !== undefined && expectedProtocolEntryNumber !== nextNumber) {
      throw new Error(`Cannot convert entry ${protocolEntryId} because nextNumber (${nextNumber}) has changed in the meantime (expectedProtocolEntryNumber=${expectedProtocolEntryNumber}`);
    }
    let protocolId = protocolEntry.protocolId;
    let createdInProtocolId = protocolEntry.createdInProtocolId;
    if (convertInformation.isContinuousProtocol && protocolEntry.createdInProtocolId) {
      if (protocolEntry.createdInProtocolId !== convertInformation.protocol.id) {
        throw new Error(`Cannot convert entry ${protocolEntryId} to a mainEntry nextNumber (${nextNumber}) has changed in the meantime (expectedProtocolEntryNumber=${expectedProtocolEntryNumber}`);
      }
      protocolId = convertInformation.protocol.id
      createdInProtocolId = undefined;
    }
    const protocolEntryToUpdate: ProtocolEntry = {...protocolEntry, parentId: null, number: nextNumber, protocolId, createdInProtocolId};
    await this.protocolEntryDataService.update(protocolEntryToUpdate, projectId);
  }

  private async assertConvertToSubEntry(protocolEntryId: IdType, newParentEntryId: IdType|undefined, convertInformation: ConvertInformation, showToastAndNoThrow: boolean): Promise<boolean> {
    const {protocol, isCarriedEntry, protocolEntry} = convertInformation;
    const translationPrefix = convertInformation.isTaskProtocol ? 'convertToMainOrSubTask' : 'convertToMainOrSubEntry';
    if (protocol.closedAt) {
      if (showToastAndNoThrow) {
        await this.toastService.error('convertToMainOrSubEntry.messages.protocolClosed');
        return false;
      }
      throw new Error(`Cannot convert entry since protocol ${protocol.id} is already closed.`);
    }
    if (protocolEntry.parentId) {
      if (showToastAndNoThrow) {
        await this.toastService.error(`${translationPrefix}.messages.isAlreadyParent`);
        return false;
      }
      throw new Error(`Cannot convert entry ${protocolEntryId} to a sub entry as it is already a sub entry (parentId is not null).`);
    }
    if (isCarriedEntry) {
      if (showToastAndNoThrow) {
        await this.toastService.error('convertToMainOrSubEntry.messages.isCarriedEntry');
        return false;
      }
      throw new Error(`Cannot convert entry ${protocolEntryId} as it is a carried entry.`);
    }
    if (newParentEntryId) {
      const newParentEntry = await observableToPromise(this.protocolEntryDataService.getById(newParentEntryId));
      if (!newParentEntry) {
        throw new Error(`Cannot convert entry ${protocolEntryId} because newParentEntryId ${newParentEntryId} cannot be found.`);
      }
      if (convertInformation.isContinuousProtocol) {
        const newParentEntryOrOpen = await observableToPromise(this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolAndEntryId(convertInformation.protocol.id, newParentEntryId));
        if (!newParentEntryOrOpen) {
          throw new Error(`Cannot convert entry ${protocolEntryId} because newParentEntryId ${newParentEntryId} belongs to a different protocol (${newParentEntry.protocolId}).`);
        }
      } else {
        if (newParentEntry.protocolId !== protocol.id) {
          throw new Error(`Cannot convert entry ${protocolEntryId} because newParentEntryId ${newParentEntryId} belongs to a different protocol (${newParentEntry.protocolId}).`);
        }
      }
    }
    return true;
  }

  private async convertToSubEntry(protocolEntryId: IdType, newParentEntryId: IdType, convertInformation: ConvertInformation, expectedProtocolEntryNumber?: number) {
    const {protocol, protocolEntry} = convertInformation;
    const projectId = protocol.projectId;
    await this.assertConvertToSubEntry(protocolEntryId, newParentEntryId, convertInformation, false);
    const subEntries = await observableToPromise(this.protocolEntryDataService.getSubEntriesByParentEntryId(protocolEntryId));
    let nextNumber = await this.protocolEntryService.getNextNumber(protocol, newParentEntryId);
    if (expectedProtocolEntryNumber !== undefined && expectedProtocolEntryNumber !== nextNumber) {
      throw new Error(`Cannot convert entry ${protocolEntryId} because nextNumber (${nextNumber}) has changed in the meantime (expectedProtocolEntryNumber=${expectedProtocolEntryNumber}`);
    }
    let createdInProtocolId: IdType|undefined;
    let protocolId = convertInformation.protocol.id;
    if (convertInformation.isContinuousProtocol) {
      const newParentEntryOrOpen = await observableToPromise(this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolAndEntryId(convertInformation.protocol.id, newParentEntryId));
      if (!newParentEntryOrOpen) {
        throw new Error(`Cannot convert entry ${protocolEntryId} because newParentEntryId ${newParentEntryId} belongs to a different protocol (${newParentEntryOrOpen.protocolId}).`);
      }
      if (newParentEntryOrOpen.isOpenEntry) {
        createdInProtocolId = convertInformation.protocol.id;
        protocolId = newParentEntryOrOpen.originalProtocolId ?? newParentEntryOrOpen.protocolId;
      }
    }
    const protocolEntryToUpdate: ProtocolEntry = {...protocolEntry, parentId: newParentEntryId, number: nextNumber, protocolId, createdInProtocolId};
    // also move subentries to new subEntry
    const subEntriesToUpdate: Array<ProtocolEntry> = subEntries.map((subEntry) => {
      return {...subEntry, parentId: newParentEntryId, number: ++nextNumber, protocolId, createdInProtocolId};
    })
    await this.protocolEntryDataService.update([protocolEntryToUpdate, ...subEntriesToUpdate], projectId);
    const protocolEntryCompanies = await observableToPromise(this.protocolEntryCompanyDataService.findAllByProtocolEntryId(protocolEntryId));
    if (protocolEntryCompanies.length) {
      this.loggingService.info(LOG_SOURCE, `Deleting ${protocolEntryCompanies.length} protocolEntryCompanies (observer companies) when converting ${protocolEntryId} to a subentry.`);
      await this.protocolEntryCompanyDataService.delete(protocolEntryCompanies, projectId);
    }
  }
}
