import { Injectable } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TextTemplateEditModalComponent} from 'src/app/components/text-template/text-template-edit-modal/text-template-edit-modal.component';
import {ClientTextTemplate, TextTemplate} from 'submodules/baumaster-v2-common';
import {AlertService} from '../ui/alert.service';
import {TextTemplateService} from './text-template.service';

@Injectable({
  providedIn: 'root'
})
export class TextTemplateEditModalService {

  constructor(
    private modalController: ModalController,
    private textTemplateService: TextTemplateService,
    private alertService: AlertService
  ) { }

  async createClientTextTemplate(category: string, prefillTextTemplate?: Partial<TextTemplate>): Promise<ClientTextTemplate | undefined> {
    const createFn = (textTemplate: TextTemplate) => this.textTemplateService.createClientTextTemplate(textTemplate);
    const modal = await this.modalController.create({
      component: TextTemplateEditModalComponent,
      componentProps: {
        onApply: createFn,
        category,
        prefillTextTemplate,
      },
      cssClass: 'omg-modal omg-boundary pdf-workflow-modal'
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    return data;
  }

  async editClientTextTemplate(clientTextTemplate: ClientTextTemplate): Promise<TextTemplate | undefined> {
    const updateFn = (updatedTextTemplate: ClientTextTemplate) => this.textTemplateService.updateClientTextTemplate(updatedTextTemplate);
    const deleteFn = async (textTemplate: ClientTextTemplate) => {
      if (!(await this.alertService.confirm({
        message: {
          key: 'textTemplate.editModal.delete.message',
          params: {
            name: textTemplate.name
          }
        },
        header: 'textTemplate.editModal.delete.header',
        confirmLabel: 'textTemplate.editModal.delete.confirm',
        confirmButton: {
          color: 'danger',
          fill: 'outline'
        }
      }))) {
        return false;
      }
      return this.textTemplateService.deleteClientTextTemplate(textTemplate);
    };
    const modal = await this.modalController.create({
      component: TextTemplateEditModalComponent,
      componentProps: {
        textTemplate: clientTextTemplate,
        onApply: updateFn,
        onDelete: deleteFn,
        category: clientTextTemplate.category,
      },
      cssClass: 'omg-modal omg-boundary pdf-workflow-modal'
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    return data;
  }
}
