import { ColumnConfig } from '../ColumnConfig';
import { AttachmentChatColumnSet } from './AttachmentChatColumnSet';

export const ProtocolEntryChatColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'number'},
  {name: 'message'},
  {name: 'protocolentry_id', prop: 'protocolEntryId'},
  {name: 'created_at', prop: 'createdAt', cnd: true, cast: 'Timestamp', def: null},
  {name: 'created_by_id', prop: 'createdById', cnd: true, def: null},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'attachmentChats', fk: 'chatId', mappedBy: AttachmentChatColumnSet, transient: true}
];
