<app-modal-header>
  <ion-title>
    {{ (title || 'tasks.assignToProtocol.title') | translate }}
  </ion-title>
</app-modal-header>

<ion-content>
  <div *ngIf="warningMessages?.length" class="omg-attention omg-attention-warning d-flex flex-column ion-text-center">
    <span *ngFor="let warningMessage of warningMessages">
      {{ warningMessage }}
    </span>
  </div>
  <div class="pt-6 pl-6 pr-6">
    <ion-searchbar class="slim-searchbar" [placeholder]="'search' | translate" [ngModel]="searchSubject | async" (ngModelChange)="searchSubject.next($event)"></ion-searchbar>
  </div>
  <div *ngIf="subtitle" class="subtitle">
    {{ subtitle }}
  </div>
  <ion-list lines="none" class="omg-boundary">
    <ion-radio-group [(ngModel)]="targetEntryId">
      <div *rxFor="let item of entriesSearch$; parent false">
        <ion-item>
          <ion-radio [value]="item.id" labelPlacement="end" justify="start">
              {{item.shortId}} | {{item.title}}
          </ion-radio>
        </ion-item>
      </div>
    </ion-radio-group>
    <div *ngIf="!(entriesSearch$ | async)?.length" class="ion-padding">
      {{'noItemsFound' | translate}}
    </div>
  </ion-list>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="close()">
      {{ 'cancel' | translate }}
    </ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!targetEntryId" (click)="confirmTarget()">
      {{  confirmButtonLabel || 'assign' | translate }}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
