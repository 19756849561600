import {MbscModule} from '@mobiscroll/angular-ivy';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import {MediaCapture} from '@awesome-cordova-plugins/media-capture/ngx';
import {Media} from '@awesome-cordova-plugins/media/ngx';
import {File} from '@awesome-cordova-plugins/file/ngx';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import {Drivers} from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SelectableInputModule} from './shared/module/selectable-input/selectable-input.module';
import {AuthHttpInterceptor} from './services/auth/auth-http-interceptor';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {faStickyNote} from '@fortawesome/pro-duotone-svg-icons';
import {Insomnia} from '@awesome-cordova-plugins/insomnia/ngx';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FirebaseAnalytics} from '@awesome-cordova-plugins/firebase-analytics/ngx';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {GlobalErrorHandler} from './global-error-handler';
import {TimeoutHttpInterceptor} from './services/auth/timeout-http-interceptor';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import {PipesModule} from './pipes/pipes.module';
import {TermsAndConditionsComponent} from './components/common/terms-and-conditions/terms-and-conditions.component';
import {SendStatusReportComponent} from './components/advanced/send-status-report/send-status-report.component';
import {ResetPasswordComponent} from './components/common/reset-password/reset-password.component';
import {IonicStorageModule} from '@ionic/storage-angular';
import {QuotaExceededComponent} from './components/common/quota-exceeded/quota-exceeded.component';
import {LoginAsComponent} from './components/admin/login-as/login-as.component';
import {BauRouteReuseStrategy} from './shared/BauRouteReuseStrategy';
import {bau, fal6} from './icons/icons';
import {SyncMissingAttachmentsComponent} from './components/common/sync-missing-attachments/sync-missing-attachments.component';
import {ArcElement, CategoryScale, Chart, Filler, LinearScale, LineController, LineElement, PieController, PointElement} from 'chart.js';
import {SidebarMenuComponent} from './components/menu/sidebar-menu/sidebar-menu.component';
import {MenuButtonModule} from './shared/module/menu-button/menu-button.module';
import {TooltipModule} from './shared/module/tooltip/tooltip.module';
import {TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {DevModeComponent} from './components/common/dev-mode/dev-mode.component';
import {SharedSvgModule} from './shared/module/shared-svg/shared-svg.module';
import {UiModule} from './shared/module/ui/ui.module';
import {HttpResponseDeviceLimitInterceptor} from './services/auth/http-response-device-limit-interceptor-interceptor';
import {HttpResponseUnauthorizedInterceptor} from './services/auth/http-response-unauthorized-interceptor';
import {UpdateModalComponent} from './components/common/update-popover/update-modal.component';
import {provideCacheableAnimationLoader, provideLottieOptions} from 'ngx-lottie';
import player from 'lottie-web';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        SidebarMenuComponent,
        DevModeComponent,
        TermsAndConditionsComponent,
        SendStatusReportComponent,
        ResetPasswordComponent,
        QuotaExceededComponent,
        LoginAsComponent,
        SyncMissingAttachmentsComponent,
        UpdateModalComponent
    ],
    providers: [
        StatusBar,
        SplashScreen,
        MediaCapture,
        Media,
        File,
        Insomnia,
        AndroidPermissions,
        FirebaseAnalytics,
        { provide: RouteReuseStrategy, useClass: BauRouteReuseStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpResponseDeviceLimitInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpResponseUnauthorizedInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        ScreenOrientation,
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        provideLottieOptions({ player: () => player }),
        provideCacheableAnimationLoader(),
    ],
    bootstrap: [AppComponent],
    imports: [
        MbscModule,
        FormsModule,
        BrowserModule,
        IonicModule.forRoot({ mode: 'md', innerHTMLTemplatesEnabled: true }),
        AppRoutingModule,
        HttpClientModule,
        SelectableInputModule,
        IonicStorageModule.forRoot({
            name: 'baumaster-offline',
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        FontAwesomeModule,
        HammerModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production && environment.pwa }),
        PipesModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MenuButtonModule,
        TooltipModule,
        SharedSvgModule,
        UiModule
    ]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fal, far, fas, bau, fal6);
    library.addIcons(faStickyNote);
    Chart.register(Filler, ArcElement, LineElement, CategoryScale, LinearScale, PointElement, PieController, LineController);
  }
}
