import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {PdfPlanHolderListHeaderComponent} from 'src/app/components/project-room/pdf-plan-folders/pdf-plan-holder-list-header/pdf-plan-holder-list-header.component';
import {PdfPlansFilterService} from 'src/app/services/project-room/pdf-plans-filter.service';

@Directive({
  selector: 'app-pdf-plan-holder-list-header[appPdfPlansSort]'
})
export class PdfPlansSortDirective implements OnDestroy, OnInit {

  private sub: Subscription;

  constructor(
    private pdfPlanHolderListHeaderComponent: PdfPlanHolderListHeaderComponent,
    private cdRef: ChangeDetectorRef,
    private pdfPlansFilterService: PdfPlansFilterService
  ) {}

  private initSubscriptions() {
    this.sub = this.pdfPlanHolderListHeaderComponent.activeSortChange.subscribe(
      (activeSort) => this.pdfPlansFilterService.sort = activeSort
    );
    this.sub.add(this.pdfPlansFilterService.sort$.subscribe((sort) => {
      this.pdfPlanHolderListHeaderComponent.activeSort = sort;
      this.cdRef.detectChanges();
    }));
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
