import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Address} from 'submodules/baumaster-v2-common';
import {getUserNameFromAddress} from './user-name-utils';

@Pipe({name: 'userNameString'})
export class UserNameString implements PipeTransform {
  constructor(private translationService: TranslateService) {
  }

  transform(address: Address, isActive?: boolean | undefined, isRemoved?: boolean | undefined): string {
    return getUserNameFromAddress(this.translationService, address, isActive, isRemoved);
  }
}
