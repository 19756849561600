import {IdAware, IdType} from 'submodules/baumaster-v2-common';

export interface LocalChange<T extends IdAware> {
  changedAt: string;
  value: T;
  oldValue?: T; // This is the previous value, which is not set for inserts value
  originalValue?: T; // This is the value before the first change. Usually the one, from the server
}

export class LocalChanges<T extends IdAware> {
  public inserted: Array<LocalChange<T>>;
  public updated: Array<LocalChange<T>>;
  public deleted: Array<LocalChange<T>>;

  constructor(initValues?: {inserted: LocalChange<T>[], updated: LocalChange<T>[], deleted: LocalChange<T>[]}) {
    if (initValues) {
      this.inserted = initValues.inserted;
      this.updated = initValues.updated;
      this.deleted = initValues.deleted;
    } else {
      this.inserted = new Array<LocalChange<T>>();
      this.updated = new Array<LocalChange<T>>();
      this.deleted = new Array<LocalChange<T>>();
    }
  }

  public hasChanges(): boolean {
    return this.inserted.length > 0 || this.updated.length > 0 || this.deleted.length > 0;
  }
}

export interface LocalChangesData<T extends IdAware> {
  insert: Array<T>;
  update: Array<T>;
  delete: Array<T>;
  localChangesInsertById: Map<IdType, LocalChange<T>>;
  localChangesUpdateById: Map<IdType, LocalChange<T>>;
  localChangesDeleteById: Map<IdType, LocalChange<T>>;
}
