import {ElementRef, Injectable} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {fromEvent, Observable} from 'rxjs';
import {throttle, delay, take, switchMap, filter, tap} from 'rxjs/operators';
import {ActionsPopoverComponent} from 'src/app/components/ui/actions-popover/actions-popover.component';
import {Nullish} from 'src/app/model/nullish';
import {PopoverAction, PopoverSeparator} from 'src/app/model/popover-actions';
import {DeviceService} from './device.service';
import {OmgToastService} from './omg-toast.service';

type PopoverDismissReasons = 'backdrop' | `feature-disabled-${'connected' | 'license'}`;
type PopoverOptions = Parameters<PopoverController['create']>[0];
type OpenActionsPopoverOptions = {
  toastOnFeatureDisabled?: boolean;
  popoverOptions?: Partial<Omit<PopoverOptions, 'event' | 'component' | 'componentProps'>>;
  toastMessages?: Partial<{
    connectedString: string,
    licenseString: string
  }>
};

export const isPopoverDismissed = <T extends string>(reason: T | PopoverDismissReasons): reason is PopoverDismissReasons => {
  return reason === 'backdrop' || reason.startsWith('feature-disabled-');
};

@Injectable({
  providedIn: 'root',
})
export class PopoverService {

  constructor(private popoverController: PopoverController, private toastService: OmgToastService, private deviceService: DeviceService) {}

  openAndClose(event: Event|undefined, options: Omit<PopoverOptions, 'event'>): {popover: Promise<HTMLIonPopoverElement>, close: () => Promise<unknown>} {
    const popoverPromise = this.popoverController.create({
      event,
      ...options,
      cssClass: `omg-boundary omg${options.cssClass ? ` ${typeof options.cssClass === 'string' ? options.cssClass : options.cssClass.join(' ')}` : ''}`,
    }).then(async (popover) => {
      await popover.present();
      return popover;
    });

    const result = {
      popover: popoverPromise,
      close: () => popoverPromise
        .then((popover) => {
          popover.dismiss();
          return popover.onDidDismiss();
        }),
    };

    return result;
  }

  async openActions<T extends string>(event: Event, actions: (PopoverAction<T> | PopoverSeparator)[], {
    toastOnFeatureDisabled = true,
    popoverOptions = {},
    toastMessages: {
      connectedString = 'connectedDisabled',
      licenseString = 'licenseDisabled'
    } = {}
  }: OpenActionsPopoverOptions = {}): Promise<T | PopoverDismissReasons> {
    const popover = await this.popoverController.create({
      ...popoverOptions,
      event,
      component: ActionsPopoverComponent,
      componentProps: { actions },
      cssClass: `omg-boundary omg${popoverOptions.cssClass ? ` ${popoverOptions.cssClass}` : ''} omg-popover-actions`
    });
    await popover.present();

    const { role } = await popover.onWillDismiss();

    const theRole: T | PopoverDismissReasons = (role as Nullish<T | Exclude<PopoverDismissReasons, 'backdrop'>>) ?? 'backdrop';

    if (toastOnFeatureDisabled) {
      if (theRole === 'feature-disabled-connected') {
        this.toastService.infoWithMessageAndHeader(`toast.${connectedString}.header`, `toast.${connectedString}.message`);
      }
      if (theRole === 'feature-disabled-license') {
        this.toastService.infoWithMessageAndHeader(`toast.${licenseString}.header`, `toast.${licenseString}.message`);
      }
    }

    return theRole;
  }

  getHoverableOrTappable(element: ElementRef, openAndCloseOptions: Parameters<PopoverService['openAndClose']>[1], {
    filterCondition = () => true
  }: { filterCondition?: () => boolean} = {}): Observable<unknown> {
    const getNotHoverableObservable = () => fromEvent<MouseEvent>(element.nativeElement, 'mouseenter').pipe(
      filter(filterCondition),
      tap((event) => this.openAndClose(event, {
        ...openAndCloseOptions,
        cssClass: 'auto-width',
        showBackdrop: true,
      }))
    );
    const getHoverableObservable = () => fromEvent<MouseEvent>(element.nativeElement, 'mouseenter').pipe(
      filter(filterCondition),
      throttle(() => fromEvent<MouseEvent>(element.nativeElement, 'mouseleave').pipe(
        delay(400),
        take(1)
      )),
      switchMap((event) => {
        const {close} = this.openAndClose(event, {
          ...openAndCloseOptions,
          cssClass: `no-pointer-events auto-width`,
          showBackdrop: false,
        });

        return fromEvent<MouseEvent>(element.nativeElement, 'mouseleave').pipe(switchMap(close));
      })
    );

    return this.deviceService.matchesMediaQuery('(hover: none)').pipe(
      switchMap((notHoverable) => notHoverable ? getNotHoverableObservable() : getHoverableObservable())
    );
  }
}
