<ion-list>
  <ion-item [disabled]="!(allowChanging$ | async)" button (click)="changeIsContinuousInfo(false)">
    <img src="/assets/images/bm-status-info.svg" class="protocol-status-img"/>
    <span class="protocol-status-text">{{'protocolEntry.status.info' | translate}}</span>
  </ion-item>

  <ion-item [disabled]="!(allowChanging$ | async)" button (click)="changeIsContinuousInfo(true)">
    <img src="/assets/images/bm-status-continuous-info.svg" class="protocol-status-img"/>
    <span class="protocol-status-text">{{'protocolEntry.status.continuousInfo' | translate}}</span>
  </ion-item>

</ion-list>
