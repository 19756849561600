import {Component, OnInit, ViewChild} from '@angular/core';
import {map} from 'rxjs/operators';
import {ProtocolListComponent} from 'src/app/components/protocol/protocol-list/protocol-list.component';
import {ProtocolFilterService} from 'src/app/services/protocol/protocol-filter.service';
import {SideBarSettingsService} from 'src/app/services/sidebar/sidebar-settings.service';
import {Breakpoints, DeviceService} from 'src/app/services/ui/device.service';
import {WIDTH_ENOUGH_SPACE_FOUR_COLUMN_PINNED} from '../protocols/protocols.page';
import {combineLatestAsync} from 'src/app/utils/async-utils';

@Component({
  selector: 'app-protocol-list-page',
  templateUrl: './protocol-list.page.html',
  styleUrls: ['./protocol-list.page.scss'],
})
export class ProtocolListPage implements OnInit {

  @ViewChild(ProtocolListComponent, {
    static: false
  }) protocolList: ProtocolListComponent;

  private isPinnedWithNotEnoughSpace$ = this.deviceService.matchesMediaQuery(`(max-width: ${WIDTH_ENOUGH_SPACE_FOUR_COLUMN_PINNED-1}px)`);
  private isAboveXxl$ = this.deviceService.isAboveBreakpoint(Breakpoints.xxl);
  showProtocolList$ = combineLatestAsync([this.sideBarSettingsService.sideBarSetting$, this.isPinnedWithNotEnoughSpace$, this.isAboveXxl$]).pipe(
    map(([sideBarSetting, isPinnedWithNotEnoughSpace, isAboveXxl]) => sideBarSetting.pinned ? isPinnedWithNotEnoughSpace : !isAboveXxl)
  );

  protocolTypeFilterObservable = this.protocolFilterService.selectedProtocolType$;

  constructor(
    private deviceService: DeviceService,
    private protocolFilterService: ProtocolFilterService,
    private sideBarSettingsService: SideBarSettingsService
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
  }
}
