import {
  Attachment,
  AttachmentBim,
  AttachmentBimMarkerScreenshot,
  AttachmentChat,
  AttachmentClient,
  AttachmentProject,
  AttachmentProjectBanner,
  AttachmentProjectImage,
  AttachmentProtocolEntry,
  AttachmentProtocolSignature,
  AttachmentReportActivity,
  AttachmentReportCompany,
  AttachmentReportEquipment,
  AttachmentReportMaterial,
  AttachmentReportSignature,
  AttachmentTypeEnum,
  AttachmentUserEmailSignature,
  PdfPlanAttachment,
  PdfPlanPage,
  UserEmailSignature
} from './models';

export const MAX_UPLOAD_SIZE_MB = 100;
export const MAX_UPLOAD_SIZE_BYTES = MAX_UPLOAD_SIZE_MB * 1024 * 2014;
export const ATTACHMENT_DOWNLOAD_MAX_SIZE_IN_BYTES = 10 * 1024 * 1024; // if changed here, also change in attachment-utils.ts of baumaster-v2-client
export const ATTACHMENT_SIZE_IN_BYTES_PROBABLY_CORRUPT_FILE = 172;
export const ATTACHMENT_SIZE_IN_BYTES_PROBABLY_CORRUPT_FILE_WITH_DEVIATION = Math.ceil(ATTACHMENT_SIZE_IN_BYTES_PROBABLY_CORRUPT_FILE + (ATTACHMENT_SIZE_IN_BYTES_PROBABLY_CORRUPT_FILE * 0.05));
export const MIME_TYPES_EXCEL_LEGACY = ['application/msexcel', 'application/vnd.ms-excel'];
export const MIME_TYPES_EXCEL_XLSX = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
export const MIME_TYPE_WHITELIST = ['image/jpeg', 'image/png', 'text/plain', 'text/richtext', 'text/rtf', 'text/xml', 'audio/*', 'video/*', 'application/pdf',
  'application/vnd.openxmlformats-officedocument', 'application/msword', 'application/mspowerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/acad', 'application/x-step', 'application/xml', 'image/x-dwg',
  'image/vnd.dwg', 'application/dxf', 'image/x-dxf', 'image/vnd.dxf', 'application/vnd.ms-outlook', 'message/rfc822', ...MIME_TYPES_EXCEL_LEGACY, ...MIME_TYPES_EXCEL_XLSX];
export const EXTENSION_ADDITIONAL_WHITELIST = ['.dwg', '.dxf', '.ifc'];
export const MIME_TYPE_PDF = 'application/pdf';
export const MIME_TYPES_CAD = ['application/acad', 'image/x-dwg', 'image/vnd.dwg', 'application/dxf', 'image/x-dxf', 'image/vnd.dxf'];
export const MIME_TYPE_EXTENSION_WHITELIST = MIME_TYPE_WHITELIST.concat(EXTENSION_ADDITIONAL_WHITELIST);
export const MIME_TYPE_EXTENSION_WHITELIST_WITHOUT_VIDEOS = MIME_TYPE_EXTENSION_WHITELIST.filter((mimeType) => !mimeType.startsWith('video/'));
export const MIME_TYPE_PROJECT_IMAGE_WHITELIST = ['image/jpeg', 'image/png'];
export const MIME_TYPE_REPORT_WHITELIST = ['image/jpeg', 'image/png', 'application/pdf'];

export const USER_SIGNATURE_LOGO_SIZES: Record<Exclude<UserEmailSignature['size'], 'ORIGINAL'>, { width: number; height: number }> = {
  SMALL: {
    width: 200,
    height: 68,
  },
  MEDIUM: {
    width: 300,
    height: 100,
  },
};

export enum AttachmentPathTypeEnum {
  FILE_PATH = 'filePath',
  THUMBNAIL_PATH = 'thumbnailPath',
  BIG_THUMBNAIL_PATH = 'bigThumbnailPath',
  MEDIUM_THUMBNAIL_PATH = 'mediumThumbnailPath'
}

export type AttachmentFilePath = {[key in AttachmentPathTypeEnum]: string};

const ATTACHMENT_PATH_TYPE_SUFFIX: {[key in AttachmentPathTypeEnum]: string|null} = {
  [AttachmentPathTypeEnum.FILE_PATH]: null,
  [AttachmentPathTypeEnum.THUMBNAIL_PATH]: '_thumbnail.png',
  [AttachmentPathTypeEnum.BIG_THUMBNAIL_PATH]: '_thumbnail_600_600.png',
  [AttachmentPathTypeEnum.MEDIUM_THUMBNAIL_PATH]: '_thumbnail_720_540.jpg',
};

function assertPropertiesExistAndNotNull<T extends AttachmentProtocolEntry | AttachmentChat | AttachmentProject | AttachmentProjectImage | PdfPlanPage | PdfPlanAttachment
  | AttachmentClient | AttachmentReportActivity | AttachmentReportEquipment | AttachmentReportMaterial | AttachmentUserEmailSignature>(attachment: T, properties: Array<keyof T>) {
  for (const property of properties) {
    const propertyValue = property in attachment ? attachment[property] : undefined;
    if (propertyValue === undefined || propertyValue === null) {
      throw new Error(`Property ${String(property)} of attachment ${JSON.stringify(attachment)} is null or undefined.`);
    }
  }
}

function filePathByTypeGenerator(attachment: Attachment, relativeBasePath: string, attachmentPathType: AttachmentPathTypeEnum) {
  const suffix = ATTACHMENT_PATH_TYPE_SUFFIX[attachmentPathType];
  if (attachment.fileExt.indexOf('.') !== -1) {
    throw new Error(`Attachment with id ${attachment.id} has a fileExt that includes a dot ("${attachment.fileExt}"), which is not allowed.`);
  }
  return relativeBasePath + (suffix === null ? '.' + attachment.fileExt : suffix);
}

function generateFilePathProject<T extends AttachmentProtocolEntry | AttachmentChat | AttachmentProject | PdfPlanPage | PdfPlanAttachment | AttachmentReportActivity | AttachmentReportEquipment
  | AttachmentReportMaterial | AttachmentReportCompany | AttachmentReportSignature | AttachmentProtocolSignature>(attachment: T, filePathCallback: (attachment: T) => string): AttachmentFilePath {
  assertPropertiesExistAndNotNull(attachment, ['projectId', 'id', 'hash']);
  const relativeBasePath = `/${attachment.projectId}/${filePathCallback(attachment)}/${attachment.id}_${attachment.hash}`;

  return {
    [AttachmentPathTypeEnum.FILE_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.FILE_PATH),
    [AttachmentPathTypeEnum.THUMBNAIL_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.THUMBNAIL_PATH),
    [AttachmentPathTypeEnum.BIG_THUMBNAIL_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.BIG_THUMBNAIL_PATH),
    [AttachmentPathTypeEnum.MEDIUM_THUMBNAIL_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.MEDIUM_THUMBNAIL_PATH)
  };
}

function generateFilePathProjectImage<T extends AttachmentProjectImage>(attachment: T): AttachmentFilePath {
  assertPropertiesExistAndNotNull(attachment, ['projectId', 'id']);
  const filePath = `/project/${attachment.projectId}/project_image/${attachment.fileName}`;
  return {
    [AttachmentPathTypeEnum.FILE_PATH]: filePath,
    [AttachmentPathTypeEnum.THUMBNAIL_PATH]: filePath,
    [AttachmentPathTypeEnum.BIG_THUMBNAIL_PATH]: filePath,
    [AttachmentPathTypeEnum.MEDIUM_THUMBNAIL_PATH]: filePath
  };
}

function generateFilePathClient<T extends AttachmentClient>(attachment: T): AttachmentFilePath {
  assertPropertiesExistAndNotNull(attachment, ['clientId', 'id']);
  const relativeBasePath = `/${attachment.clientId}/${attachment.id}_${attachment.hash}`;

  return {
    [AttachmentPathTypeEnum.FILE_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.FILE_PATH),
    [AttachmentPathTypeEnum.THUMBNAIL_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.THUMBNAIL_PATH),
    [AttachmentPathTypeEnum.BIG_THUMBNAIL_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.BIG_THUMBNAIL_PATH),
    [AttachmentPathTypeEnum.MEDIUM_THUMBNAIL_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.MEDIUM_THUMBNAIL_PATH)
  };
}

function generateFilePathUser<T extends AttachmentUserEmailSignature>(attachment: T, filePathCallback: (attachment: T) => string): AttachmentFilePath {
  assertPropertiesExistAndNotNull(attachment, ['userId', 'id']);
  const relativeBasePath = `/${filePathCallback(attachment)}/${attachment.id}_${attachment.hash}`;

  return {
    [AttachmentPathTypeEnum.FILE_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.FILE_PATH),
    [AttachmentPathTypeEnum.THUMBNAIL_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.THUMBNAIL_PATH),
    [AttachmentPathTypeEnum.BIG_THUMBNAIL_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.BIG_THUMBNAIL_PATH),
    [AttachmentPathTypeEnum.MEDIUM_THUMBNAIL_PATH]: filePathByTypeGenerator(attachment, relativeBasePath, AttachmentPathTypeEnum.MEDIUM_THUMBNAIL_PATH)
  };
}

function getFilePathProtocolEntry(attachment: AttachmentProtocolEntry): string {
  return `protocolentry/${attachment.protocolEntryId}`;
}

function getFilePathProjectBanner(attachment: AttachmentProjectBanner): string {
  return `project_banners/${attachment.bannerType}`;
}

function getFilePathChat(attachment: AttachmentChat): string {
  return `chat/${attachment.chatId}`;
}

function getFilePathProject(attachment: AttachmentProject): string {
  return 'project';
}

function getFilePathProjectImage(attachment: AttachmentProjectImage): string {
  return `project/${attachment.projectId}/project_image`;
}

function getFilePathPdfPlanPageAndAttachment(attachment: PdfPlanPage | PdfPlanAttachment): string {
  return `pdfplan/${attachment.pdfPlanVersionId}`;
}

function getFilePathAttachmentBim(attachment: AttachmentBim): string {
  return 'bim';
}

function getFilePathAttachmentBimMarkerScreenshot(attachment: AttachmentBimMarkerScreenshot): string {
  return `bim_marker_screenshot/${attachment.bimMarkerId}`;
}

function getFilePathReportActivity(attachment: AttachmentReportActivity): string {
  return `report/activity/${attachment.activityId}`;
}

function getFilePathReportMaterial(attachment: AttachmentReportMaterial): string {
  return `report/material/${attachment.materialId}`;
}

function getFilePathReportSignature(attachment: AttachmentReportSignature): string {
  return `report/signature/${attachment.reportId}`;
}

function getFilePathReportEquipment(attachment: AttachmentReportEquipment): string {
  return `report/equipment/${attachment.equipmentId}`;
}

function getFilePathReportCompany(attachment: AttachmentReportCompany): string {
  return `report/company/${attachment.reportCompanyId}`;
}

function getFilePathProtocolSignature(attachment: AttachmentProtocolSignature): string {
  return `protocol/signature/${attachment.protocolId}`;
}

function getFilePathUserEmailSignature(attachment: AttachmentUserEmailSignature): string {
  return `user/${attachment.userId}/signature`;
}

export function generateFilePathsForAttachmentProtocolEntry(attachment: AttachmentProtocolEntry): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathProtocolEntry);
}

export function generateFilePathsForAttachmentChat(attachment: AttachmentChat): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathChat);
}

export function generateFilePathsForAttachmentProject(attachment: AttachmentProject): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathProject);
}

export function generateFilePathsForAttachmentProjectBanner(attachment: AttachmentProjectBanner): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathProjectBanner);
}

export function generateFilePathsForAttachmentProjectImage(attachment: AttachmentProjectImage): AttachmentFilePath {
  return generateFilePathProjectImage(attachment);
}

export function generateFilePathsForPdfPlanAttachmentOrPage(attachment: PdfPlanAttachment | PdfPlanPage): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathPdfPlanPageAndAttachment);
}

export function generateFilePathsForAttachmentBim(attachment: AttachmentBim): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathAttachmentBim);
}

export function generateFilePathsForAttachmentBimMarkerScreenshot(attachment: AttachmentBimMarkerScreenshot): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathAttachmentBimMarkerScreenshot);
}

export function generateFilePathsForAttachmentClient(attachment: AttachmentClient): AttachmentFilePath {
  return generateFilePathClient(attachment);
}

export function generateFilePathsForAttachmentReportActivity(attachment: AttachmentReportActivity): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathReportActivity);
}

export function generateFilePathsForAttachmentReportEquipment(attachment: AttachmentReportEquipment): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathReportEquipment);
}

export function generateFilePathsForAttachmentReportMaterial(attachment: AttachmentReportMaterial): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathReportMaterial);
}

export function generateFilePathsForAttachmentReportSignature(attachment: AttachmentReportSignature): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathReportSignature);
}

export function generateFilePathsForAttachmentReportCompany(attachment: AttachmentReportCompany): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathReportCompany);
}

export function generateFilePathsForAttachmentProtocolSignature(attachment: AttachmentProtocolSignature): AttachmentFilePath {
  return generateFilePathProject(attachment, getFilePathProtocolSignature);
}

export function generateFilePathsForAttachmentUserEmailSignature(attachment: AttachmentUserEmailSignature): AttachmentFilePath {
  return generateFilePathUser(attachment, getFilePathUserEmailSignature);
}

export function isMimeTypeAllowedForUpload(mimeType: string): boolean {
  return isMimeTypeAllowed(mimeType, MIME_TYPE_WHITELIST);
}

export function isUploadSizeAllowed(sizeInBytes: number): boolean {
  return sizeInBytes <= MAX_UPLOAD_SIZE_BYTES;
}

export function isMimeTypeAllowedForProjectImageUpload(mimeType: string): boolean {
  return isMimeTypeAllowed(mimeType, MIME_TYPE_PROJECT_IMAGE_WHITELIST);
}

export function isMimeTypeAllowed(mimeType: string, mimeTypes: Array<string>): boolean {
  return !!mimeTypes.find((whitelistMimeType) => {
    const wildcard = whitelistMimeType.endsWith('*');
    return wildcard ? mimeType.startsWith(whitelistMimeType.substring(0, whitelistMimeType.length - 1)) : whitelistMimeType === mimeType;
  });
}

export function getAttachmentType(attachment: Attachment): AttachmentTypeEnum {
  if ('chatId' in attachment) {
    return AttachmentTypeEnum.AttachmentChat;
  } else if ('pdfPlanVersionId' in attachment && 'pageNumber' in attachment) {
    return AttachmentTypeEnum.PdfPlanPage;
  } else if ('pdfPlanVersionId' in attachment) {
    return AttachmentTypeEnum.PdfPlanAttachment;
  } else if ('protocolEntryId' in attachment) {
    return AttachmentTypeEnum.AttachmentProtocolEntry;
  } else if ('reportCompanyId' in attachment) {
    return AttachmentTypeEnum.AttachmentReportCompany;
  } else if ('activityId' in attachment) {
    return AttachmentTypeEnum.AttachmentReportActivity;
  } else if ('equipmentId' in attachment) {
    return AttachmentTypeEnum.AttachmentReportEquipment;
  } else if ('materialId' in attachment) {
    return AttachmentTypeEnum.AttachmentReportMaterial;
  } else if ('reportId' in attachment && 'code' in attachment) {
    return AttachmentTypeEnum.AttachmentReportSignature;
  }  else if ('protocolId' in attachment && ('profileId' in attachment || 'name' in attachment)) {
    return AttachmentTypeEnum.AttachmentProtocolSignature;
  } else if ('bimMarkerId' in attachment) {
    return AttachmentTypeEnum.AttachmentBimMarkerScreenshot;
  } else if ('bimVersionId' in attachment) {
    return AttachmentTypeEnum.AttachmentBim;
  } else if ('projectId' in attachment && 'bannerType' in attachment) {
    return AttachmentTypeEnum.AttachmentProjectBanner;
  } else if ('projectId' in attachment && 'relativePath' in attachment) {
    return AttachmentTypeEnum.AttachmentProjectImage;
  } else if ('projectId' in attachment) {
    return AttachmentTypeEnum.AttachmentProject;
  } else if ('clientId' in attachment) {
    return AttachmentTypeEnum.AttachmentClient;
  } else if ('userId' in attachment) {
    return AttachmentTypeEnum.AttachmentUserEmailSignature;
  } else {
    throw new Error(`Unsupported attachment type. ${JSON.stringify(attachment)}`);
  }
}

function getAttachmentTypeOrNull(attachment: Attachment): AttachmentTypeEnum|null {
  try {
    return getAttachmentType(attachment);
  } catch (e) {
    return null;
  }
}

export function isAttachmentChat(attachment: Attachment): attachment is AttachmentChat {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentChat;
}
export function isPdfPlanPage(attachment: Attachment): attachment is PdfPlanPage {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.PdfPlanPage;
}
export function isPdfPlanAttachment(attachment: Attachment): attachment is PdfPlanAttachment {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.PdfPlanAttachment;
}
export function isAttachmentBimMarkerScreenshot(attachment: Attachment): attachment is AttachmentBimMarkerScreenshot {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentBimMarkerScreenshot;
}
export function isAttachmentBim(attachment: Attachment): attachment is AttachmentBim {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentBim;
}
export function isAttachmentProtocolEntry(attachment: Attachment): attachment is AttachmentProtocolEntry {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentProtocolEntry;
}
export function isAttachmentReportCompany(attachment: Attachment): attachment is AttachmentReportCompany {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentReportCompany;
}
export function isAttachmentReportActivity(attachment: Attachment): attachment is AttachmentReportActivity {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentReportActivity;
}
export function isAttachmentReportEquipment(attachment: Attachment): attachment is AttachmentReportEquipment {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentReportEquipment;
}
export function isAttachmentReportMaterial(attachment: Attachment): attachment is AttachmentReportMaterial {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentReportMaterial;
}
export function isAttachmentReportSignature(attachment: Attachment): attachment is AttachmentReportSignature {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentReportSignature;
}
export function isAttachmentProtocolSignature(attachment: Attachment): attachment is AttachmentProtocolSignature {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentProtocolSignature;
}
export function isAttachmentProjectImage(attachment: Attachment): attachment is AttachmentProjectImage {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentProjectImage;
}
export function isAttachmentProject(attachment: Attachment): attachment is AttachmentProject {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentProject;
}
export function isAttachmentProjectBanner(attachment: AttachmentProjectBanner): attachment is AttachmentProjectBanner {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentProjectBanner;
}
export function isAttachmentClient(attachment: Attachment): attachment is AttachmentClient {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentClient;
}
export function isAttachmentUserEmailSignature(attachment: Attachment): attachment is AttachmentUserEmailSignature {
  return getAttachmentTypeOrNull(attachment) === AttachmentTypeEnum.AttachmentUserEmailSignature;
}
type AttachmentReport = AttachmentReportCompany | AttachmentReportActivity | AttachmentReportEquipment | AttachmentReportMaterial;
export function isAttachmentReport(attachment: Attachment): attachment is AttachmentReport {
  return isAttachmentReportCompany(attachment) ||
         isAttachmentReportActivity(attachment) ||
         isAttachmentReportEquipment(attachment) ||
         isAttachmentReportMaterial(attachment);
}

export function generateFilePaths(attachment: Attachment): AttachmentFilePath {
  const attachmentType = getAttachmentType(attachment);
  let filePaths: AttachmentFilePath;
  switch (attachmentType) {
    case AttachmentTypeEnum.AttachmentChat: filePaths = generateFilePathsForAttachmentChat(attachment as AttachmentChat); break;
    case AttachmentTypeEnum.PdfPlanPage: filePaths = generateFilePathsForPdfPlanAttachmentOrPage(attachment as PdfPlanPage); break;
    case AttachmentTypeEnum.PdfPlanAttachment: filePaths = generateFilePathsForPdfPlanAttachmentOrPage(attachment as PdfPlanAttachment); break;
    case AttachmentTypeEnum.AttachmentProtocolEntry: filePaths = generateFilePathsForAttachmentProtocolEntry(attachment as AttachmentProtocolEntry); break;
    case AttachmentTypeEnum.AttachmentReportCompany: filePaths = generateFilePathsForAttachmentReportCompany(attachment as AttachmentReportCompany); break;
    case AttachmentTypeEnum.AttachmentReportActivity: filePaths = generateFilePathsForAttachmentReportActivity(attachment as AttachmentReportActivity); break;
    case AttachmentTypeEnum.AttachmentReportEquipment: filePaths = generateFilePathsForAttachmentReportEquipment(attachment as AttachmentReportEquipment); break;
    case AttachmentTypeEnum.AttachmentReportMaterial: filePaths = generateFilePathsForAttachmentReportMaterial(attachment as AttachmentReportMaterial); break;
    case AttachmentTypeEnum.AttachmentReportSignature: filePaths = generateFilePathsForAttachmentReportSignature(attachment as AttachmentReportSignature); break;
    case AttachmentTypeEnum.AttachmentProtocolSignature: filePaths = generateFilePathsForAttachmentProtocolSignature(attachment as AttachmentProtocolSignature); break;
    case AttachmentTypeEnum.AttachmentProjectImage: filePaths = generateFilePathProjectImage(attachment as AttachmentProjectImage); break;
    case AttachmentTypeEnum.AttachmentProject: filePaths = generateFilePathsForAttachmentProject(attachment as AttachmentProject); break;
    case AttachmentTypeEnum.AttachmentProjectBanner: filePaths = generateFilePathsForAttachmentProjectBanner(attachment as AttachmentProjectBanner); break;
    case AttachmentTypeEnum.AttachmentClient: filePaths = generateFilePathsForAttachmentClient(attachment as AttachmentClient); break;
    case AttachmentTypeEnum.AttachmentUserEmailSignature: filePaths = generateFilePathsForAttachmentUserEmailSignature(attachment as AttachmentUserEmailSignature); break;
    case AttachmentTypeEnum.AttachmentBim: filePaths = generateFilePathsForAttachmentBim(attachment as AttachmentBim); break;
    case AttachmentTypeEnum.AttachmentBimMarkerScreenshot: filePaths = generateFilePathsForAttachmentBimMarkerScreenshot(attachment as AttachmentBimMarkerScreenshot); break;
    default: throw new Error(`Unsupported attachment type. ${JSON.stringify(attachment)}`);
  }
  if (!filePaths.filePath) {
    throw new Error(`filePath property not defined for ${filePaths}`);
  }
  return filePaths;
}

export function equalWithDeviation(value: number, compareValue: number, deviationFactor = 0.05, deviationAbsoluteMin = 10, deviationAbsoluteMax = 100): boolean {
  const deviationValue = Math.min(Math.max(compareValue * deviationFactor, deviationAbsoluteMin), deviationAbsoluteMax);
  const lowerCompareValue = compareValue - deviationValue;
  const upperCompareValue = compareValue + deviationValue;
  return value >= lowerCompareValue && value <= upperCompareValue;
}
