import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const fal6DiagramSubtask: IconDefinition = {
  prefix: 'fal6' as IconPrefix,
  iconName: 'diagram-subtask' as IconName,
  icon: [
    512,
    512,
    [],
    null,
    // eslint-disable-next-line max-len
    'M448 64c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H144 112 64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32H448zM144 224H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96v64c0 35.3 28.7 64 64 64h48v96c0 44.2 35.8 80 80 80h32v16c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V352c0-35.3-28.7-64-64-64H288c-35.3 0-64 28.7-64 64v16H192c-26.5 0-48-21.5-48-48V224zm304 96c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V352c0-17.7 14.3-32 32-32H448z'
  ]
};
