import {ColumnConfig} from '../ColumnConfig';
import {PdfPlanPageColumnSet} from './PdfPlanPageColumnSet';
import {PdfPlanAttachmentColumnSet} from './PdfPlanAttachmentColumnSet';
import {PdfPlanVersionAccessColumnSet} from './PdfPlanVersionAccessColumnSet';

const PdfPLanVersionUnique = ['pdfplan_id', 'number'];

export const PdfPlanVersionColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'pdfplan_id', prop: 'pdfPlanId', uniqueWith: [PdfPLanVersionUnique]},
  {name: 'number', uniqueWith: [PdfPLanVersionUnique]},
  {name: 'index'},
  {name: 'name'},
  {name: 'description', def: null},
  {name: 'location_id', prop: 'locationId', def: null},
  {name: 'scale', def: null},
  {name: 'date', cast: 'Timestamp'},
  {name: 'quality', def: null},
  {name: 'created_by_id', prop: 'createdById', def: null},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'created_at_db', prop: 'createdAtDb', cast: 'Timestamp', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'pdfPlanPages', fk: 'pdfPlanVersionId', mappedBy: PdfPlanPageColumnSet, transient: true},
  {name: 'pdfPlanAttachments', fk: 'pdfPlanVersionId', mappedBy: PdfPlanAttachmentColumnSet, transient: true},
  {name: 'pdfPlanVersionAccesses', fk: 'pdfPlanVersionId', mappedBy: PdfPlanVersionAccessColumnSet, transient: true},
];
