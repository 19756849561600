import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {IdType, LicenseType, ReportCompanyActivity, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ProjectDataService} from './project-data.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import { map } from 'rxjs/operators';
import {AbstractProjectAwareMappingDataService} from './abstract-project-aware-mapping-data.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/reportCompanyActivities';

@Injectable({
  providedIn: 'root'
})
export class ReportCompanyActivityDataService extends AbstractProjectAwareMappingDataService<ReportCompanyActivity> {

  constructor(http: HttpClient,
              storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.REPORT_COMPANY_ACTIVITY, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return currentUser.role === LicenseType.BASIC || currentUser.role === LicenseType.PROFESSIONAL || currentUser.assignedReportRights;
  }

  getByReportCompanyAndActivity(reportCompanyId: IdType, activityId: IdType) {
    return this.data.pipe(
      map((reportCompanyActivities) => reportCompanyActivities.find(
        (reportCompanyActivity) => reportCompanyActivity.reportCompanyId === reportCompanyId && reportCompanyActivity.activityId === activityId
      ))
    );
  }

  getByReportCompany(reportCompanyId: IdType) {
    return this.data.pipe(
      map((reportCompanyActivities) => reportCompanyActivities.filter(
        (reportCompanyActivity) => reportCompanyActivity.reportCompanyId === reportCompanyId
      ))
    );
  }
}
