<div class="d-flex flex-column person">
  <div class="name">
    {{ address$ | async | userNameString }} 
  </div>
  <div class="email">
    {{ (user$ | async)?.email }} 
  </div>
  <ion-button class="omg-btn-text close-button" (click)="dismiss()">
    <fa-icon [fixedWidth]="true" [icon]="['fal', 'times']" slot="icon-only"></fa-icon>
  </ion-button>
</div>
<div class="list d-flex flex-column">
  <ion-button
    class="omg-btn-primary omg-btn-popover-list omg-btn-color-popover-list"
    (click)="openNotificationsCenter(); dismiss()"
  >
    <fa-icon [fixedWidth]="true" slot="start" [icon]="['fal', 'bell']"></fa-icon>
    <span class="omg-btn-popover-list-label d-flex ion-align-items-center gap-2">
      <span>{{ "notificationsCenter.title" | translate }}</span>
      <span *ngIf="(totalUnseenCount$ | async) as totalUnseenCount" class="omg-notification-badge">{{totalUnseenCount > 99 ? '99+' : totalUnseenCount}}</span>
    </span>
  </ion-button>
  <ng-container *ngIf="isTakingPictureEnabled$ | async">
    <ion-button
      class="omg-btn-primary omg-btn-popover-list omg-btn-color-popover-list"
      (click)="takePicture()"
      [disabled]="!takingPhotosSupported"
    >
      <fa-icon [fixedWidth]="true" slot="start" [icon]="['fal', 'camera']"></fa-icon>
      <span class="omg-btn-popover-list-label">
        {{ 'header.take_single_photo' | translate }}
      </span>
    </ion-button>
    <ion-button
      class="omg-btn-primary omg-btn-popover-list omg-btn-color-popover-list"
      (click)="takePictures()"
      [disabled]="!takingPhotosSupported"
    >
      <fa-icon [fixedWidth]="true" slot="start" [icon]="['bau', 'foto-series']"></fa-icon>
      <span class="omg-btn-popover-list-label">
        {{ 'header.take_photo_series' | translate }}
      </span>
    </ion-button>
  </ng-container>
  <hr class="omg-divider" />
  <ion-button
    class="omg-btn-primary omg-btn-popover-list omg-btn-color-popover-list"
    (click)="navigateToSettings('/the-settings/general-settings-account'); dismiss()"
  >
    <span class="omg-btn-popover-list-label">{{ "the_settings.general_settings_account.title" | translate }}</span>
  </ion-button>
  <ng-container *ngIf="clientAdminGuard.isClientAdmin$ | async">
    <ion-button
      class="omg-btn-primary omg-btn-popover-list omg-btn-color-popover-list"
      (click)="navigateToSettings('/the-settings/admin-settings-client-data'); dismiss()"
    >
      <span class="omg-btn-popover-list-label">{{ "the_settings.admin_settings_client_data.title" | translate }}</span>
    </ion-button>
    <ion-button
      class="omg-btn-primary omg-btn-popover-list omg-btn-color-popover-list"
      (click)="navigateToSettings('/the-settings/admin-settings-logo-design'); dismiss()"
    >
      <span class="omg-btn-popover-list-label">{{ "the_settings.admin_settings_logo_design.title" | translate }}</span>
    </ion-button>
    <ion-button
      class="omg-btn-primary omg-btn-popover-list omg-btn-color-popover-list"
      (click)="navigateToSettings('/the-settings/admin-settings-global'); dismiss()"
    >
      <span class="omg-btn-popover-list-label">{{ "the_settings.admin_settings_global.title" | translate }}</span>
    </ion-button>
  </ng-container>
  <hr class="omg-divider" />
  <ion-button
    class="omg-btn-primary omg-btn-popover-list omg-btn-color-popover-list"
    (click)="logout(); dismiss()" routerDirection="root" routerLink=""
  >
    <fa-icon [fixedWidth]="true" slot="start" [icon]="['fal', 'sign-out']"></fa-icon>
    <span class="omg-btn-popover-list-label">{{'MENU.logout' | translate}}</span>
  </ion-button>
</div>