import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {IonInput} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {IdType, ProjectProtocolEntryType, ProtocolEntryType} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {observableToPromise} from 'src/app/utils/async-utils';
import {LoggingService} from 'src/app/services/common/logging.service';
import {Subject} from 'rxjs';
import {v4 as uuid4} from 'uuid';
import {ProtocolEntryTypeDataService} from 'src/app/services/data/protocol-entry-type-data.service';
import {ProjectProtocolEntryTypeDataService} from '../../../services/data/project-protocol-entry-type-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {takeUntil} from 'rxjs/operators';
import {SystemEventService} from '../../../services/event/system-event.service';
import {ClientService} from '../../../services/client/client.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {AlertService} from '../../../services/ui/alert.service';
import {LoadingService} from '../../../services/common/loading.service';
import {ProjectService} from '../../../services/project/project.service';

const LOG_SOURCE = 'ProtocolEntryTypeComponent';

@Component({
  selector: 'app-protocol-entry-type',
  templateUrl: './protocol-entry-type.component.html',
  styleUrls: ['./protocol-entry-type.component.scss'],
})
export class ProtocolEntryTypeComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() protocolEntryTypeId: IdType | null = null;
  @Input() projectId: IdType | undefined;
  @Input() prePopulateMainFieldWithText?: string;
  @Input() createForSelectable = false;

  @ViewChild('inputName', {static: false}) inputName: IonInput;

  public protocolEntryTypeForm: UntypedFormGroup = this.formBuilder.group({
    name: ['', [Validators.required, this.uniqueName()]],
    statusFieldActive: [true],
    taskDefault: [false],
  });
  public addToProject = true;
  public loading = false;
  public restoreMode = false;
  private destroy$ = new Subject<void>();
  public protocolEntryType: ProtocolEntryType | undefined;
  private protocolEntryTypes: ProtocolEntryType[] | undefined;
  private modal: HTMLIonModalElement;

  notConnected$ = this.featureEnabledService.isFeatureEnabled$(false, true, null);

  constructor(private formBuilder: UntypedFormBuilder,
              private translateService: TranslateService,
              private alertService: AlertService,
              private clientService: ClientService,
              private systemEventService: SystemEventService,
              private loggingService: LoggingService,
              private toastService: ToastService,
              private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
              private projectProtocolEntryTypeDataService: ProjectProtocolEntryTypeDataService,
              private projectDataService: ProjectDataService,
              private featureEnabledService: FeatureEnabledService,
              private loadingService: LoadingService,
              private projectService: ProjectService) {
  }

  private uniqueName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const protocolEntryName: string | null = control.value;
      if (_.isEmpty(protocolEntryName) || this.protocolEntryType?.name === protocolEntryName) {
        return null;
      }

      const existingRecord = _.filter(this.protocolEntryTypes, protocolEntryType =>
        protocolEntryType.name === protocolEntryName && (protocolEntryType.isActive || protocolEntryType.isActive === undefined));
      if (!_.isEmpty(existingRecord)) {
        return {protocolEntryTypeNameExist: true};
      }
      if (!this.restoreMode){
        const recordDeletedExist = _.filter(this.protocolEntryTypes, protocolEntryType => protocolEntryType.name === protocolEntryName && protocolEntryType.isActive === false);
        if (!_.isEmpty(recordDeletedExist)) {
          return {nameExistAndInactive: true};
        }
      }
      return null;
    };
  }

  async ngOnInit() {
    this.protocolEntryTypeDataService.dataForOwnClient$
      .pipe(takeUntil(this.destroy$))
      .subscribe((protocolEntryTypes: ProtocolEntryType[]) => {
        this.protocolEntryTypes = protocolEntryTypes;
      });

    this.protocolEntryTypeForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((formValueChanges) => {
        this.setFormValueDisabled();
      });
    if (this.protocolEntryTypeId) {
      this.protocolEntryTypeDataService.getByIdForOwnClient(this.protocolEntryTypeId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((protocolEntryType: ProtocolEntryType) => {
          this.protocolEntryTypeForm.patchValue(protocolEntryType);
          this.protocolEntryType = protocolEntryType;
        });
    }
    if (this.prePopulateMainFieldWithText) {
      this.protocolEntryTypeForm.controls.name.setValue(this.prePopulateMainFieldWithText);
      this.protocolEntryTypeForm.controls.name.markAsDirty();
    }
    this.setCanDismiss();
  }

  ngAfterViewInit() {
    setTimeout(async () => {
      await this.inputName.setFocus();
    }, 500);
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (role === 'delete') {
      return true;
    }

    if (!this.protocolEntryTypeForm?.dirty) {
      return true;
    }

    return await this.alertService.confirm({header: 'protocolCreation.data_loss_header', message: 'protocolCreation.data_loss_message'});
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async saveProtocolEntryType() {
    try {
      this.loading = true;
      const rawData = this.protocolEntryTypeForm.getRawValue();

      const protocolEntryTypeData = {
        name: rawData.name,
        statusFieldActive: rawData.statusFieldActive,
        taskDefault: rawData.taskDefault ?? false
      } as ProtocolEntryType;

      const ensureTaskDefaultConsistent = (protocolEntryType: ProtocolEntryType) => {
        if (!protocolEntryType.statusFieldActive && protocolEntryType.taskDefault) {
          protocolEntryType.taskDefault = false;
        }
      };

      if (!this.protocolEntryTypeId) {
        let protocolEntryType: ProtocolEntryType = this.protocolEntryTypes.find((data) => data.name === protocolEntryTypeData.name);
        if (protocolEntryType) {
          protocolEntryType.statusFieldActive = protocolEntryTypeData.statusFieldActive;
          await this.undoLogicalDelete(protocolEntryType);
          return;
        }
        const client = await this.clientService.getOwnClientMandatory();
        protocolEntryType = {
          id: uuid4(),
          clientId: client.id,
          changedAt: new Date().toISOString(),
          isActive: true,
          ...protocolEntryTypeData
        };
        ensureTaskDefaultConsistent(protocolEntryType);

        await this.createProtocolEntryType(protocolEntryType);
      } else {
        const protocolEntryType: ProtocolEntryType = {...this.protocolEntryType, ...protocolEntryTypeData};
        ensureTaskDefaultConsistent(protocolEntryType);
        await this.updateProtocolEntryType(protocolEntryType);
      }

    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - save protocol type ', error?.userMessage + '-' + error?.message);
      this.loggingService.error(LOG_SOURCE, `Error save protocol type. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(error));
      throw error;
    } finally {
      this.loading = false;
    }
  }

  private async createProtocolEntryType(protocolEntryType: ProtocolEntryType) {
    await this.loadingService.withLoading(async (loadingOverlay) => {
      await this.protocolEntryTypeDataService.insert(protocolEntryType, protocolEntryType.clientId);
      if (protocolEntryType.taskDefault) {
        await this.addProtocolEntryTypeToAllProjects(protocolEntryType, loadingOverlay);
      } else {
        await this.addProtocolEntryTypeToProject(protocolEntryType);
      }
      await this.ensureOnlyOneTaskDefault(protocolEntryType);
      this.protocolEntryTypeForm.markAsPristine();
      await this.dismissModal('ok', protocolEntryType);
    }, {message: this.translateService.instant('copyWorkflow.savingCopies')});
  }

  private async addProtocolEntryTypeToProject(protocolEntryType: ProtocolEntryType) {
    if (this.addToProject && await observableToPromise(this.notConnected$)) {
      const projectId = this.projectId ?? (await this.projectDataService.getCurrentProject()).id;
      const projectProtocolEntryType: ProjectProtocolEntryType = {
        id: projectId + protocolEntryType.id,
        projectId,
        protocolentrytypeId: protocolEntryType.id,
        changedAt: new Date().toISOString()
      };
      await this.projectProtocolEntryTypeDataService.insert(projectProtocolEntryType, projectId);
    }
  }

  private async addProtocolEntryTypeToAllProjects(protocolEntryType: ProtocolEntryType, loadingOverlay?: HTMLIonLoadingElement) {
    const projects = await observableToPromise(this.projectDataService.dataActive$);
    const projectIds = projects.map((project) => project.id);
    this.loggingService.debug(LOG_SOURCE, `addProtocolEntryTypeToAllProjects - calling ensureProjectDataOfflineAvailable for the following projects: ${projectIds?.join()}`);
    const loadingMessageBefore = loadingOverlay?.message;
    if (loadingOverlay) {
      loadingOverlay.message = this.translateService.instant('projectLoadingNotification.usingUnavailableAlert.messageShort');
    }
    try {
      const ensureOfflineResult = await this.projectService.ensureProjectDataOfflineAvailable(projectIds,
        {throwErrorIfMakingProjectsAvailableFailed: true, useModal: false, temporarily: true, modalAllowInBackground: false, modalAllowCancel: true});
      if (!ensureOfflineResult.success) {
        this.loggingService.warn(LOG_SOURCE, `addProtocolEntryTypeToAllProjects - ensureProjectDataOfflineAvailable not successful`);
        return;
      }
    } finally {
      if (loadingOverlay) {
        loadingOverlay.message = loadingMessageBefore;
      }
    }
    this.loggingService.info(LOG_SOURCE, `addProtocolEntryTypeToAllProjects - ensureProjectDataOfflineAvailable successfully finished.`);
    const projectProtocolEntryTypes = await observableToPromise(this.projectProtocolEntryTypeDataService.dataAcrossProjects$);
    for (const project of projects) {
      const projectId = project.id;
      if (!projectProtocolEntryTypes.some((projectProtocolEntryType) => projectProtocolEntryType.projectId === projectId && projectProtocolEntryType.protocolentrytypeId === protocolEntryType.id)) {
        const projectProtocolEntryType: ProjectProtocolEntryType = {
          id: projectId + protocolEntryType.id,
          projectId,
          protocolentrytypeId: protocolEntryType.id,
          changedAt: new Date().toISOString()
        };
        await this.projectProtocolEntryTypeDataService.insert(projectProtocolEntryType, projectId);
      }
    }
  }

  private async updateProtocolEntryType(protocolEntryType: ProtocolEntryType) {
    if (await this.alertService.confirm({message: 'protocolEntryTypeForm.editMessage'})) {
      await this.loadingService.withLoading(async (loadingOverlay) => {
        await this.protocolEntryTypeDataService.update(protocolEntryType, protocolEntryType.clientId);
        if (protocolEntryType.taskDefault) {
          await this.addProtocolEntryTypeToAllProjects(protocolEntryType, loadingOverlay);
        }
        await this.ensureOnlyOneTaskDefault(protocolEntryType);
        this.protocolEntryTypeForm.markAsPristine();
        await this.dismissModal();
      }, {message: this.translateService.instant('copyWorkflow.savingCopies')});
    }
  }

  private async ensureOnlyOneTaskDefault(protocolEntryTypeTaskDefault: ProtocolEntryType) {
    if (!protocolEntryTypeTaskDefault.taskDefault) {
      this.loggingService.warn(LOG_SOURCE, `ensureOnlyOneTaskDefault called with protocolEntryType ${protocolEntryTypeTaskDefault.id} but property taskDefault is set to false.`);
      return;
    }
    const otherProtocolEntryTypesWithTaskDefault = this.protocolEntryTypes.filter((protocolEntryType) => protocolEntryType.taskDefault && protocolEntryType.id !== protocolEntryTypeTaskDefault.id);
    if (!otherProtocolEntryTypesWithTaskDefault.length) {
      return;
    }
    const clientId = otherProtocolEntryTypesWithTaskDefault[0].clientId;
    otherProtocolEntryTypesWithTaskDefault.forEach((protocolEntryType) => protocolEntryType.taskDefault = false);
    await this.protocolEntryTypeDataService.update(otherProtocolEntryTypesWithTaskDefault, clientId);
  }

  dismissModal(role?: 'ok' | 'cancel', data?: ProtocolEntryType) {
    return this.modal.dismiss(data, role);
  }

  toggleAddToProject() {
    this.addToProject = !this.addToProject;
  }

  async deleteProtocolEntryType(protocolEntryType: ProtocolEntryType) {
    const confirmDelete = await this.alertService.confirm({
      message: 'protocolEntryTypeForm.confirmDelete',
      confirmButton: {
        color: 'danger',
        fill: 'solid',
      },
      confirmLabel: 'button.delete'
    });
    if (confirmDelete) {
      await this.doLogicalDelete(protocolEntryType);
      await this.toastService.info('protocolEntryTypeForm.deleteSuccessful');
      await this.modal.dismiss(undefined, 'delete');
    }
  }

  async doLogicalDelete(protocolEntryType: ProtocolEntryType) {
    protocolEntryType.isActive = false;
    await this.protocolEntryTypeDataService.update(protocolEntryType, protocolEntryType.clientId);
    const allProjectProtocolTypes = await observableToPromise(this.projectProtocolEntryTypeDataService.getByProtocolEntryTypeAcrossProjects(protocolEntryType));
    allProjectProtocolTypes.forEach((projectProtocolEntryType) => this.projectProtocolEntryTypeDataService.delete(projectProtocolEntryType, projectProtocolEntryType.projectId));
  }

  async undoLogicalDelete(protocolEntryType: ProtocolEntryType) {
    protocolEntryType.isActive = true;
    await this.protocolEntryTypeDataService.update(protocolEntryType, protocolEntryType.clientId);
    await this.addProtocolEntryTypeToProject(protocolEntryType);
    this.protocolEntryTypeForm.markAsPristine();
    await this.dismissModal('ok', protocolEntryType);
  }

  restoreByName() {
    const formData = this.protocolEntryTypeForm.getRawValue();
    const protocolEntryType = this.protocolEntryTypes.find((data) => data.name === formData.name);
    this.protocolEntryTypeForm.get('name').setValue(protocolEntryType.name);
    this.protocolEntryTypeForm.get('name').disable();
    this.protocolEntryTypeForm.get('statusFieldActive').setValue(protocolEntryType.statusFieldActive);
    this.protocolEntryTypeForm.get('statusFieldActive').disable();
    this.restoreMode = true;
    this.protocolEntryTypeForm.markAsPristine();
  }

  private setFormValueDisabled() {
    const newStatusFieldActiveDisabledValue = Boolean(this.protocolEntryTypeForm.controls.statusFieldActive.value && this.protocolEntryTypeForm.controls.taskDefault.value);
    if (this.protocolEntryTypeForm.controls.statusFieldActive.disabled !== newStatusFieldActiveDisabledValue) {
      if (newStatusFieldActiveDisabledValue) {
        this.protocolEntryTypeForm.controls.statusFieldActive.disable();
      } else {
        this.protocolEntryTypeForm.controls.statusFieldActive.enable();
      }
    }
    const newTaskDefaultDisabledValue = !this.protocolEntryTypeForm.controls.statusFieldActive.value;
    if (this.protocolEntryTypeForm.controls.taskDefault.disabled !== newTaskDefaultDisabledValue) {
      if (newTaskDefaultDisabledValue) {
        this.protocolEntryTypeForm.controls.taskDefault.disable();
      } else {
        this.protocolEntryTypeForm.controls.taskDefault.enable();
      }
    }
  }
}
