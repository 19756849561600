<app-modal-header>
  <ion-title>{{'buyingWorkflow.licenseFeatures' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <ion-grid class="with-padding">
    <ion-row>
      <ion-col size="12">
        <ion-card class="scrollable-container">
          <div class="scrollable" [id]="annual ? 'bm_license_annual' : 'bm_license_monthly' "></div>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-primary" (click)="dismissModal()">{{'close' | translate}}</ion-button>
  </ion-buttons>
</app-modal-footer>
