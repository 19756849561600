import { ColumnConfig } from '../ColumnConfig';
import { PdfPreviewColumnSet } from './PdfPreviewColumnSet';
import { PdfProtocolSettingColumnSet } from './PdfProtocolSettingColumnSet';
import { ProjectProtocolTypeColumnSet } from './ProjectProtocolTypeColumnSet';
import { ProtocolColumnSet } from './ProtocolColumnSet';

export const ProtocolTypeColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'name'},
  {name: 'code'},
  {name: 'client_id', prop: 'clientId', cnd: true},
  {name: 'layout_id', prop: 'layoutId'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'pdfPreviews', fk: 'typeId', mappedBy: PdfPreviewColumnSet, transient: true},
  {name: 'pdfProtocolSettings', fk: 'protocolTypeId', mappedBy: PdfProtocolSettingColumnSet, transient: true},
  {name: 'projectProtocolTypes', fk: 'protocoltypeId', mappedBy: ProjectProtocolTypeColumnSet, transient: true},
  {name: 'protocols', fk: 'typeId', mappedBy: ProtocolColumnSet, transient: true},
];
