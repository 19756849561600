import {CommonModule} from '@angular/common';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {BimVersionDataService} from 'src/app/services/data/bim-version-data.service';
import {BimViewerModalService} from 'src/app/services/project-room/bim-viewer-modal.service';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {BimVersion, ProtocolEntry} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {PipesModule} from '../../../pipes/pipes.module';
import {BimPlanDataService} from '../../../services/data/bim-plan-data.service';
import {combineLatestAsync} from '../../../utils/async-utils';
import {BimPlanTreeViewComponentData, BimViewerComponentData, BimViewerComponentReturnType} from '../../../model/bim-plan-with-deletable';

const LOG_SOURCE = 'BimPlanTreeViewComponent';

@Component({
  selector: 'app-bim-plan-tree-view',
  templateUrl: './bim-plan-tree-view.component.html',
  styleUrls: ['./bim-plan-tree-view.component.scss'],
  standalone: true,
  imports: [
    UiModule,
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    PipesModule
  ]
})
export class BimPlanTreeViewComponent implements OnInit, OnDestroy {

  @Input() returnType: BimViewerComponentReturnType = BimViewerComponentReturnType.PERSIST_CHANGES;
  @Input() selectedProtocolEntry: ProtocolEntry|undefined;
  @Input() acrossProjects = false;

  public selectedBimVersion: BimVersion|undefined;
  public bimVersions: Array<BimVersion>|undefined;

  private modal: HTMLIonModalElement;
  private bimPlanFileTreeSubscription: Subscription|undefined;

  constructor(
    private bimVersionDataService: BimVersionDataService,
    private bimViewerModalService: BimViewerModalService,
    private bimPlanDataService: BimPlanDataService
  ) {}

  ngOnInit() {
    this.bimPlanFileTreeSubscription = combineLatestAsync([this.bimPlanDataService.data, this.bimVersionDataService.dataLatestFinal$])
      .subscribe(([bimPlans, bimVersions]) => {
        const bimVersionsFiltered = bimVersions.filter((bimVersion) => bimPlans.some((bimPlan) => bimPlan.id === bimVersion.bimPlanId && bimPlan.active));
        this.bimVersions = _.orderBy(bimVersionsFiltered, ['name', 'createdAt']);
    });
  }

  ngOnDestroy() {
    if (this.bimPlanFileTreeSubscription) {
      this.bimPlanFileTreeSubscription.unsubscribe();
      this.bimPlanFileTreeSubscription = undefined;
    }
  }

  public async openBimViewerMarker(selectedBimVersion: BimVersion) {
    const modal = await this.bimViewerModalService.createModal({
      returnType: this.returnType,
      selectedBimVersion,
      selectedProtocolEntry: this.selectedProtocolEntry,
      acrossProjects: this.acrossProjects
    });
    const data: BimPlanTreeViewComponentData = {
      selectedBimVersion,
      bimViewerDismiss: modal.onDidDismiss<BimViewerComponentData>()
    };
    await this.modal.dismiss(data, 'ok');
    await modal.present();
  }
}
