import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LicenseType } from 'submodules/baumaster-v2-common';
import { FeatureEnabledService } from '../services/feature/feature-enabled.service';

@Injectable({
  providedIn: 'root'
})
export class ViewerGuard  {
  constructor(private featureEnabledService: FeatureEnabledService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.featureEnabledService.isFeatureEnabled$(false, null, [LicenseType.VIEWER]);
  }
}
