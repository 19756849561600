import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const bauBim: IconDefinition = {
  prefix: 'bau' as IconPrefix,
  iconName: 'bim' as IconName,
  icon: [
    24,
    11,
    [],
    null,
    // eslint-disable-next-line max-len
    'M0.232955 11V0.818182H3.79261C4.50189 0.818182 5.08688 0.940814 5.54759 1.18608C6.00829 1.42803 6.35133 1.7545 6.5767 2.16548C6.80208 2.57315 6.91477 3.02557 6.91477 3.52273C6.91477 3.96023 6.83688 4.3215 6.68111 4.60653C6.52865 4.89157 6.32647 5.11695 6.07457 5.28267C5.82599 5.44839 5.55587 5.57102 5.2642 5.65057V5.75C5.57576 5.76989 5.88897 5.87926 6.20384 6.07812C6.5187 6.27699 6.7822 6.56203 6.99432 6.93324C7.20644 7.30445 7.3125 7.75852 7.3125 8.29545C7.3125 8.80587 7.1965 9.26491 6.96449 9.67259C6.73248 10.0803 6.36624 10.4034 5.86577 10.642C5.36529 10.8807 4.71402 11 3.91193 11H0.232955ZM1.46591 9.90625H3.91193C4.71733 9.90625 5.28906 9.75047 5.62713 9.43892C5.96851 9.12405 6.1392 8.7429 6.1392 8.29545C6.1392 7.95076 6.05137 7.63258 5.87571 7.34091C5.70005 7.04593 5.44981 6.81061 5.125 6.63494C4.80019 6.45597 4.41572 6.36648 3.97159 6.36648H1.46591V9.90625ZM1.46591 5.29261H3.75284C4.12405 5.29261 4.45881 5.2197 4.7571 5.07386C5.05871 4.92803 5.29735 4.72254 5.47301 4.45739C5.65199 4.19223 5.74148 3.88068 5.74148 3.52273C5.74148 3.07528 5.5857 2.69579 5.27415 2.38423C4.9626 2.06937 4.46875 1.91193 3.79261 1.91193H1.46591V5.29261ZM10.5714 0.818182V11H9.33842V0.818182H10.5714ZM13.0435 0.818182H14.5151L17.9753 9.26989H18.0946L21.5549 0.818182H23.0265V11H21.873V3.2642H21.7736L18.5918 11H17.4782L14.2963 3.2642H14.1969V11H13.0435V0.818182Z'
  ]
};
