import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import _ from 'lodash';

const getRegExpWithOptionalDecimal = (wholePartMin: number, wholePartMax: number, decimalPartMin: number, decimalPartMax: number) => {
  if (decimalPartMin === 0) {
    return new RegExp(`^\\d{${wholePartMin},${wholePartMax}}([,.]\\d{1,${decimalPartMax}})?$`);
  }
  return new RegExp(`^\\d{${wholePartMin},${wholePartMax}}[,.]\\d{${decimalPartMin},${decimalPartMax}}$`);
};

export const DecimalValidator: (wholePartMin: number, wholePartMax: number, decimalPartMin: number, decimalPartMax: number) => ValidatorFn = (
  wholePartMin: number,
  wholePartMax: number,
  decimalPartMin: number,
  decimalPartMax: number
) => Validators.pattern(getRegExpWithOptionalDecimal(wholePartMin, wholePartMax, decimalPartMin, decimalPartMax));

export const HoursValidators: ValidatorFn[] = [Validators.pattern(/^\d{1,2}([,\.]\d{1,2})?$/), Validators.maxLength(5), Validators.max(24)];

export const TIME_REGEX = /^\d{2}:\d{2}$/;
export const TimeValidator: ValidatorFn = Validators.pattern(TIME_REGEX);
export const TimeRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value || _.isArray(control.value)) {
    return null;
  }
  const [timeStart, timeEnd] = control.value;
  if (TIME_REGEX.test(timeStart) && TIME_REGEX.test(timeEnd)) {
    return null;
  }
  return {'pattern': 'Not a valid time.'}
};
