import {convertErrorToMessage} from 'src/app/shared/errors';
import {SyncConflict, SyncStrategy} from '../sync-utils';
import {IdAware} from 'submodules/baumaster-v2-common';

export function getPerformanceMeasurer(syncStrategy: SyncStrategy, commonSyncId: string, includePerformanceMarks = true) {
  const getTimestamp = () => performance ? performance.now() : Date.now();
  const round = (n: number) => Math.round(n * 10) / 10;
  const startTime = round(getTimestamp());
  const startCp = `${commonSyncId}-start`;
  const perf: ([number, string[]])[] = [[0, [startCp]]];
  if (performance && includePerformanceMarks) {
    performance.mark(startCp);
  }
  const syncCountByType = {
    NCA: 0,
    CA: 0,
    PA: 0,
  };
  const deltaSyncCountByType = {
    NCA: 0,
    CA: 0,
    PA: 0,
  };
  let errored = false;
  let errorMessage: string|undefined;
  return {
    errored: (error: unknown) => {
      errored = true;
      errorMessage = `${error instanceof Error ? error.name : 'non-Error object'}: ${convertErrorToMessage(error)}`;
    },
    increaseSyncCount: (type: keyof typeof syncCountByType) => syncCountByType[type]++,
    increaseDeltaSyncCount: (type: keyof typeof deltaSyncCountByType) => deltaSyncCountByType[type]++,
    checkpoint: (cp: string) => {
      const timeInSync = round(getTimestamp() - startTime);
      if (perf.length && perf[perf.length - 1][0] === timeInSync) {
        perf[perf.length - 1][1].push(cp);
      } else {
        perf.push([timeInSync, [cp]]);
      }
      if (performance && includePerformanceMarks) {
        performance.mark(cp);
      }
    },
    getPerf: () => ({
      id: commonSyncId,
      syncStrategy,
      duration: perf[perf.length - 1]?.[0] ?? null,
      perf,
      syncCountByType,
      deltaSyncCountByType,
      changedAt: new Date().toISOString(),
      errored,
      errorMessage,
      hasDelta: Boolean(deltaSyncCountByType.CA || deltaSyncCountByType.NCA || deltaSyncCountByType.PA),
      hasFull: Boolean((syncCountByType.CA - deltaSyncCountByType.CA) || (syncCountByType.NCA - deltaSyncCountByType.NCA) || (syncCountByType.PA - deltaSyncCountByType.PA)),
    }),
    measureTotal: () => {
      if (performance && includePerformanceMarks && perf.length > 1) {
        performance.measure(`sync-${commonSyncId}`, perf[0][1][0], perf[perf.length - 1][1][perf[perf.length - 1][1].length - 1]);
      }
    },
    measure: (label: string, from: string, to: string) => {
      if (performance && includePerformanceMarks && perf.length > 1) {
        performance.measure(`sync-${commonSyncId}: ${label}`, from, to);
      }
    }
  };
}

export type PerformanceMeasurer = ReturnType<typeof getPerformanceMeasurer>;
export type BasePerformanceMeasurerResult = ReturnType<PerformanceMeasurer['getPerf']>;
export type PerformanceMeasurerResult = BasePerformanceMeasurerResult & {syncConflicts: SyncConflict<IdAware>[]; conflicts: boolean; unresolvedConflicts: boolean};
