import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {StepIndicatorProps} from '../step-indicator/step-indicator-props';

@Component({
  selector: 'app-modal-step-indicator',
  templateUrl: './modal-step-indicator.component.html',
  styleUrls: ['./modal-step-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalStepIndicatorComponent implements StepIndicatorProps {

  @Input()
  stepsCount: number;

  @Input()
  current: number;

  @Input()
  workflowName: string;

  @Input()
  workflowStep: string;
}
