import {Attachment, IdType, isMimeTypeAllowedForUpload, isUploadSizeAllowed, MAX_UPLOAD_SIZE_MB} from 'submodules/baumaster-v2-common';
import {DataServiceInsertOptions} from './abstract-data.service';
import {AbstractFileAccessUtil, AttachmentPathProperty, deleteThumbnailAttachmentFromCache, ensureMimeTypeSet} from '../../utils/attachment-utils';
import {convertErrorToMessage, ErrorWithUserMessage} from '../../shared/errors';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {AbstractProjectAwareAttachmentDataService} from './abstract-project-aware-attachment-data.service';
import {AbstractClientAwareAttachmentDataService} from './abstract-client-aware-attachment-data.service';
import {AbstractNonClientAwareAttachmentDataService} from './abstract-non-client-aware-attachment-data.service';
import {Observable} from 'rxjs';
import {observableToPromise} from '../../utils/async-utils';
import {SystemEventService} from '../event/system-event.service';
import {DevModeService} from '../common/dev-mode.service';
import {LoggingService} from '../common/logging.service';
import {environment} from 'src/environments/environment';

const LOG_SOURCE = 'AttachmentDataHelperServiceImpl';

const MEDIA_URL = environment.serverUrl + 'media';

export class AttachmentDataHelperServiceImpl<T extends Attachment> {

  constructor(private dataService: AbstractProjectAwareAttachmentDataService<T> | AbstractClientAwareAttachmentDataService<T> | AbstractNonClientAwareAttachmentDataService<T>,
              private translateService: TranslateService, private fileAccessUtil$: Observable<AbstractFileAccessUtil>,
              private systemEventService: SystemEventService, private devModeService: DevModeService, private loggingService: LoggingService) {
  }

  public getMediaFileUrl(attachment: T, pathProperty: AttachmentPathProperty, changedAt?: Date|string): string|null {
    const pathValue = attachment[pathProperty];
    if (pathValue === null || pathValue === undefined) {
      return null;
    }
    const changedAtToUse = changedAt || attachment.changedAt;
    return MEDIA_URL + pathValue + '?version=' + this.changedAtToVersion(changedAtToUse);
  }

  private changedAtToVersion(changedAt: Date|string): number {
    const changedAtDate: Date = _.isDate(changedAt) ? changedAt as Date : new Date(changedAt);
    return changedAtDate.getTime();
  }

  public async updatedChangedAt(ids: Array<IdType>, newChangedAt: Date|string, projectId?: IdType,
                                changedCallback?: (changedItem: T, oldChangedAt: Date|string) => Promise<void>): Promise<(changedItem: T, oldChangedAt: (Date | string)) => Promise<void>> {
    if (this.devModeService.settings.disableChangeAttachmentVersion) {
      this.loggingService.warn(LOG_SOURCE, `DevMode disableChangeAttachmentVersion activated. Do not change attachment version for attachments ${ids}`);
      return;
    }
    const pathProperties = new Array<AttachmentPathProperty>('filePath', 'thumbnailPath', 'bigThumbnailPath', 'mediumThumbnailPath');
    return async (changedItem: T, oldChangedAt: Date|string): Promise<void> => {
      for (const pathProperty of pathProperties) {
        if (changedItem[pathProperty]) {
          const fileAccessUtil = await observableToPromise(this.fileAccessUtil$);
          const hasMediaFileChanged = await fileAccessUtil.changeAttachmentVersion(changedItem, pathProperty, oldChangedAt, newChangedAt);
          let hasUploadFileChanged = false;
          let hasUploadErrorFileChanged = false;
          try {
            hasUploadFileChanged = await fileAccessUtil.changeAttachmentVersionForUploadIfExists(changedItem, pathProperty, oldChangedAt, newChangedAt, 'upload');
          } catch (error) {
            this.loggingService.warn(LOG_SOURCE, '.updatedChangedAt', () => `changedAttachmentVersion of ${changedItem?.id} for file in "upload" queue failed with error "${
              convertErrorToMessage(error)
            }". Error "Load failed" probably means the file does not exist.`);
            this.systemEventService.logEvent(LOG_SOURCE, `.updatedChangedAt, changedAttachmentVersion of ${changedItem?.id} for file in "upload" queue failed with error "${
              convertErrorToMessage(error)
            }". Error "Load failed" probably means the file does not exist.`);
          }
          try {
            hasUploadErrorFileChanged = await fileAccessUtil.changeAttachmentVersionForUploadIfExists(changedItem, pathProperty, oldChangedAt, newChangedAt, 'uploadError');
          } catch (error) {
            this.loggingService.warn(LOG_SOURCE, '.updatedChangedAt', () => `changedAttachmentVersion of ${changedItem?.id} for file in "uploadError" queue failed with error "${
              convertErrorToMessage(error)
            }". Error "Load failed" probably means the file does not exist.`);
            this.systemEventService.logEvent(LOG_SOURCE, `changedAttachmentVersion of ${changedItem?.id} for file in "uploadError" queue failed with error "${
              convertErrorToMessage(error)
            }". Error "Load failed" probably means the file does not exist.`);
          }
          this.loggingService.debug(LOG_SOURCE, '.updatedChangedAt', () => `changedAttachmentVersion of ${changedItem?.id} (pathProperty=${pathProperty}) from ${oldChangedAt} to ${
            newChangedAt
          }. hasMediaFileChanged=${hasMediaFileChanged}, hasUploadFileChanged=${hasUploadFileChanged}, hasUploadErrorFileChanged=${hasUploadErrorFileChanged}`);
          this.systemEventService.logEvent(LOG_SOURCE, `.updatedChangedAt - changedAttachmentVersion of ${changedItem?.id} (pathProperty=${pathProperty}) from ${oldChangedAt} to ${
            newChangedAt
          }. hasMediaFileChanged=${hasMediaFileChanged}, hasUploadFileChanged=${hasUploadFileChanged}, hasUploadErrorFileChanged=${hasUploadErrorFileChanged}`);
        }
      }
    };
  }

  public async saveBlob(attachment: T, projectId: IdType, blob: Blob) {
    const fileAccessUtil = await observableToPromise(this.fileAccessUtil$);
    this.loggingService.debug(LOG_SOURCE, `.saveBlob - writing attachment ${attachment?.id}...`);
    this.systemEventService.logEvent(LOG_SOURCE, `.saveBlob - writing attachment ${attachment?.id}...`);
    await fileAccessUtil.writeAttachment(attachment, 'filePath', blob);
    this.loggingService.debug(LOG_SOURCE, `.saveBlob, attachment ${attachment?.id} written.`);
    this.systemEventService.logEvent(LOG_SOURCE,  `.saveBlob - attachment ${attachment?.id} written.`);
  }

  public assertArraysSameLength(values: Array<T>, blobs: Array<Blob>) {
    if (values.length !== blobs.length) {
      throw new Error(`Length of values (${values.length}) does not match length of blobs (${blobs.length}).`);
    }
  }

  public assertMimeTypeAndSize(blob: Blob, filename?: string|null) {
    if (filename) {
      blob = ensureMimeTypeSet(blob, filename);
    }
    const mimeType = blob.type;
    if (!isMimeTypeAllowedForUpload(mimeType)) {
      throw new ErrorWithUserMessage(`Not allowed to uploaded file with mime type "${mimeType}"`,
        this.translateService.instant('attachment.error.mimeTypeNotAllowed', {mimeType}));
    }
    if (!isUploadSizeAllowed(blob.size)) {
      const sizeInMb = Math.round(blob.size / 1024 / 1024);
      throw new ErrorWithUserMessage(`Not allowed to uploaded file as the size of ${sizeInMb} MB exceeds the maximum allowed size of ${MAX_UPLOAD_SIZE_MB} MB.`,
        this.translateService.instant('attachment.error.maxUploadSizeExceeded', {sizeInMb, maxUploadSizeInMb: MAX_UPLOAD_SIZE_MB}));
    }
  }

  public assertBlobProvided(blob?: Blob) {
    if (!blob) {
      throw new Error('Argument "blob" is not provided.');
    }
  }

  public async insertOrUpdate(value: T, projectId?: IdType, blob?: Blob): Promise<void> {
    if (blob) {
      this.assertBlobProvided(blob);
      this.assertMimeTypeAndSize(blob, value.fileName);
      await this.saveBlob(value, projectId, blob);
    }
    await this.deleteThumbnailsFromCacheIfAttachmentHasMarkings(value, projectId);
  }

  public async insert(valueOrArray: T | Array<T>, projectId?: IdType, options?: DataServiceInsertOptions, blobOrArray?: Blob | Array<Blob>): Promise<void> {
    const values: Array<T> = _.isArray(valueOrArray) ? valueOrArray as Array<T> : new Array<T>(valueOrArray as T);
    const blobs: Array<Blob> = _.isArray(blobOrArray) ? blobOrArray as Array<Blob> : new Array<Blob>(blobOrArray as Blob);
    this.assertArraysSameLength(values, blobs);
    for (let i = 0; i < values.length; i++) {
      this.assertBlobProvided(blobs[i]);
      this.assertMimeTypeAndSize(blobs[i], values[i].fileName);
      await this.saveBlob(values[i], projectId, blobs[i]);
    }
  }

  public async update(valueOrArray: T | Array<T>, projectId?: IdType): Promise<void> {
    const values: Array<T> = _.isArray(valueOrArray) ? valueOrArray as Array<T> : new Array<T>(valueOrArray as T);
    for (const value of values) {
      await this.deleteThumbnailsFromCacheIfAttachmentHasMarkings(value, projectId);
    }
  }

  public async delete(valueOrArray: T | Array<T>, projectId?: IdType): Promise<void> {
    const values: Array<T> = _.isArray(valueOrArray) ? valueOrArray as Array<T> : new Array<T>(valueOrArray as T);
    const fileAccessUtil = await observableToPromise(this.fileAccessUtil$);
    for (const value of values) {
      // the attachments itself will be deleted by the attachment sync. Deleting them here would maybe cause them to be downloaded again, if the image is shown on the screen (ImageUriPipe)
      await fileAccessUtil.deleteAttachment(value, 'filePath', true, 'upload');
      this.systemEventService.logEvent(LOG_SOURCE + '.delete', () => `attachment ${value?.id} (filePath, upload) deleted.`);
    }
  }

  public async deleteThumbnailsFromCacheIfAttachmentHasMarkings(value: T, projectId: IdType) {
    if (_.isEmpty(value.markings)) {
      return;
    }
    const fileAccessUtil = await observableToPromise(this.fileAccessUtil$);
    const deleted = await deleteThumbnailAttachmentFromCache(value, fileAccessUtil);
    this.systemEventService.logEvent(LOG_SOURCE + '.deleteThumbnailsFromCacheIfAttachmentHasMarkings', () => `thumbnails of attachment ${value?.id} deleted. actually deleted = ${deleted} `);
  }
}
