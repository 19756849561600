import {ColumnConfig} from '../ColumnConfig';
import {TagClientObjectColumnSet} from './TagClientObjectColumnSet';

const UniqueNameInClientUniq = ['client_id', 'name'];

export const TagClientColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'client_id', cnd: true, prop: 'clientId', uniqueWith: [UniqueNameInClientUniq]},
  {name: 'name', uniqueWith: [UniqueNameInClientUniq]},
  {name: 'color'},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'created_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'tagClientObjects', fk: 'tagId', mappedBy: TagClientObjectColumnSet, transient: true},
];
