import { ColumnConfig } from '../ColumnConfig';
import { CompanyCraftColumnSet } from './CompanyCraftColumnSet';
import { ConstructionScheduleTaskColumnSet } from './ConstructionScheduleTaskColumnSet';
import { ProfileCraftColumnSet } from './ProfileCraftColumnSet';
import { ProjectCraftColumnSet } from './ProjectCraftColumnSet';
import { ProtocolEntryColumnSet } from './ProtocolEntryColumnSet';
import { ReportColumnSet } from './ReportColumnSet';

export const CraftColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'name'},
  {name: 'client_id', prop: 'clientId', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'color', def: null},
  {name: 'companyCrafts', fk: 'craftId', mappedBy: CompanyCraftColumnSet, transient: true},
  {name: 'constructionScheduleTasks', fk: 'craftId', mappedBy: ConstructionScheduleTaskColumnSet, transient: true},
  {name: 'profileCrafts', fk: 'craftId', mappedBy: ProfileCraftColumnSet, transient: true},
  {name: 'projectCrafts', fk: 'craftId', mappedBy: ProjectCraftColumnSet, transient: true},
  {name: 'protocolEntries', fk: 'craftId', mappedBy: ProtocolEntryColumnSet, transient: true},
  {name: 'reports', fk: 'craftId', mappedBy: ReportColumnSet, transient: true},
];
