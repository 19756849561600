<ion-content class="with-padding">
  <div class="d-flex flex-column gap-7">
    <div>
      <ion-button class="omg-btn-text" (click)="dismiss.emit()">
        <fa-icon slot="start" [icon]="['fal', 'arrow-left']"></fa-icon>
        <span>{{backLabel | translate}}</span>
      </ion-button>
    </div>

    <div class="d-flex flex-column gap-6 main-content">
      <div class="d-flex flex-column gap-2">
        <div class="ion-text-center omg-t-header-3-bold">
          {{'twoFactor.login.totp.title' | translate}}
        </div>
        <div class="ion-text-center omg-t-body omg-f-italic">
          {{'twoFactor.login.totp.description' | translate}}
        </div>
      </div>

      <div class="d-flex flex-column gap-2">
        <div class="code-input-wrapper">
          <code-input [initialFocusField]="0" [codeLength]="6" (codeChanged)="codeUpdated($event)"></code-input>
        </div>
        <ion-note><ng-content></ng-content>&nbsp;</ion-note>
      </div>

      <ion-button class="omg-btn-primary" block type="button" (click)="verify.emit()"
        [disabled]="loading || details.code?.length !== 6">
        <ion-spinner class="spinner-center-button" *ngIf="loading"></ion-spinner>
        <span>{{'twoFactor.login.verify' | translate}}</span>
      </ion-button>

      <div class="ion-text-center omg-t-body omg-f-italic text-secondary">
        {{'twoFactor.login.totp.changeToRecoveryCodesPrefix' | translate}} <br />
        <span class="omg-link" (click)="changeMethod.emit('recovery_code')">{{'twoFactor.login.totp.changeToRecoveryCodes' | translate}}</span>
      </div>
    </div>
  </div>
</ion-content>