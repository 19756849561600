import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {SyncStatusService} from '../../../services/sync/sync-status.service';
import {SyncService} from '../../../services/sync/sync.service';
import {SyncStrategy} from '../../../services/sync/sync-utils';
import {AsyncPipe, NgIf} from '@angular/common';
import {INIT_AFTER_APP_START_DELAY_IN_MS} from '../../../shared/constants';

@Component({
  selector: 'app-initial-sync-placeholder',
  templateUrl: './initial-sync-placeholder.component.html',
  styleUrls: ['./initial-sync-placeholder.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, TranslateModule, IonicModule, NgIf, AsyncPipe]
})
export class InitialSyncPlaceholderComponent implements OnInit {

  schedulerStartedPromise: Promise<boolean> = new Promise((resolve) => setTimeout(resolve, INIT_AFTER_APP_START_DELAY_IN_MS)).then(() => true);
  dataSyncInProgress$ = this.syncStatusService.dataSyncInProgressObservable;

  @Output()
  logout = new EventEmitter<void>();

  constructor(private syncStatusService: SyncStatusService, private syncService: SyncService) { }

  ngOnInit() {}

  async startSync() {
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
  }
}
