<app-modal-header>
  <ion-item lines="none" class="header-wrapper">
    <ion-segment [(ngModel)]="showPlanVersion" slot="start" *ngIf="!(showBothPlans$ | async)">
      <ion-segment-button value="old">
        <ion-label>{{ "project_room.planMarkerMigration.showPlanVersion.old" | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="new">
        <ion-label>{{ "project_room.planMarkerMigration.showPlanVersion.new" | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>
    <div class="title-wrapper">
      <ion-buttons *ngIf="multiplePages">
        <ion-button class="omg-btn-text nav-icon-btn" (click)="goToPreviousPage()" [disabled]="currentPage === 0">
          <fa-icon [icon]="['fal', 'chevron-left']"></fa-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ ('project_room.planMarkerMigration.title') | translate }} <span *ngIf="multiplePages">({{currentPage + 1}}/{{pdfPlanPages.length}})</span></ion-title>
      <ion-buttons *ngIf="multiplePages">
        <ion-button class="omg-btn-text nav-icon-btn" (click)="goToNextPage()" [disabled]="currentPage >= this.pdfPlanPages.length - 1">
          <fa-icon [icon]="['fal', 'chevron-right']"></fa-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </ion-item>
</app-modal-header>

<ion-content>
  <ion-grid class="canvas-grid" [ngClass]="{'both-plans': (showBothPlans$ | async), 'single-plan': !(showBothPlans$ | async)}">
    <ion-row *ngIf="(showBothPlans$ | async)" class="ion-justify-content-center title-row">
      <ion-col class="ion-text-center">
        {{ 'project_room.planMarkerMigration.oldPlanVersion' | translate }}
      </ion-col>
      <ion-col class="ion-text-center">
        {{ 'project_room.planMarkerMigration.newPlanVersion' | translate }}
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center canvas-row">
      <ion-col [hidden]="oldImageLoaded && !(showBothPlans$ | async) && showPlanVersion !== 'old'" [ngClass]="{'imageLoading': oldImageLoaded, 'imageLoaded': oldImageLoaded}">
        <app-pdf-plan-marker-migration *ngIf="latestPdfPlanPages && latestPdfPlanMarkerProtocolEntriesByPageId && latestPdfPlanPageMarkingsByPageId"
                                       (loadedImage)="oldImageLoaded = true"
                                       [pdfPlanPage]="latestPdfPlanPages[currentPage]" [migratePdfPlanPageMarkingGeneral]="migratePdfPlanPageMarkingGeneral"
                                       [pdfPlanMarkerProtocolEntries]="latestPdfPlanMarkerProtocolEntriesByPageId[latestPdfPlanPages[currentPage].id]" [pdfPlanPageMarkings]="latestPdfPlanPageMarkingsByPageId[latestPdfPlanPages[currentPage].id]" [readonly]="true"
                                       [width]="canvasWidth" [height]="canvasHeight">
        </app-pdf-plan-marker-migration>
      </ion-col>
      <ion-col [hidden]="newImageLoaded && !(showBothPlans$ | async) && showPlanVersion !== 'new'" [ngClass]="{'imageLoading': newImageLoaded, 'imageLoaded': newImageLoaded}">
        <app-pdf-plan-marker-migration *ngIf="pdfPlanPages && pdfPlanMarkerProtocolEntriesByPageId && pdfPlanPageMarkingsByPageId"
                                       (loadedImage)="newImageLoaded = true"
                                       [pdfPlanPage]="pdfPlanPages[currentPage]" [imageBlob]="imageBlobs[currentPage]" [migratePdfPlanPageMarkingGeneral]="migratePdfPlanPageMarkingGeneral"
                                       [pdfPlanMarkerProtocolEntries]="pdfPlanMarkerProtocolEntriesByPageId[pdfPlanPages[currentPage].id]" (pdfPlanMarkerProtocolEntriesChange)="pdfPlanMarkerProtocolEntriesChanged($event)"
                                       [pdfPlanPageMarkings]="pdfPlanPageMarkingsByPageId[pdfPlanPages[currentPage].id]" (pdfPlanPageMarkingsChange)="pdfPlanPageMarkingsChanged($event)"
                                       [moveChoice]="moveChoice" (moveChoiceChange)="changeMoveChoiceExternally($event)"
                                       [moveEntriesWhenOutsideVisibleArea]="true" (entriesMovedFromOutsideVisibleArea)="entriesMovedFromOutsideVisibleArea($event)"
                                       [width]="canvasWidth" [height]="canvasHeight">
        </app-pdf-plan-marker-migration>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="start">
    <ion-radio-group [ngModel]="moveChoice" (ngModelChange)="changeMoveChoice($event)">
      <ion-radio [value]="'single'" labelPlacement="end">{{ 'project_room.planMarkerMigration.moveChoice.single' | translate }}</ion-radio>
      <ion-radio [value]="'all'" labelPlacement="end">{{ 'project_room.planMarkerMigration.moveChoice.all' | translate }}</ion-radio>
      <ion-radio [value]="'allMarkers'" labelPlacement="end">{{ 'project_room.planMarkerMigration.moveChoice.allMarkers' | translate }}</ion-radio>
      <ion-radio [value]="'allSketches'" labelPlacement="end">{{ 'project_room.planMarkerMigration.moveChoice.allSketches' | translate }}</ion-radio>
    </ion-radio-group>
    <div class="ion-hide-xxl-down pl-4">{{'project_room.planMarkerMigration.newPlanVersionMigrationHint' | translate}}</div>
  </ion-buttons>
  <ion-range *ngIf="devModeEnabled$ | async"
             [min]="0.001"
             [max]="2"
             [step]="0.001"
             (ionChange)="changeScale($event)"
             [value]="scale">
  </ion-range>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">
      {{ 'button.cancel' | translate }}
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="save()">
      {{ 'button.save' | translate }}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
