import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-screen-too-small',
  templateUrl: './screen-too-small.component.html',
  styleUrls: ['./screen-too-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenTooSmallComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
