import { ColumnConfig } from '../ColumnConfig';
import { skipColumn } from './skipColumn';

const CompanyCraftsUniq = ['craft_id', 'company_id'];

export const CompanyCraftColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true, skip: skipColumn},
  {name: 'company_id', prop: 'companyId', compositeKey: true, uniqueWith: [CompanyCraftsUniq]},
  {name: 'craft_id', prop: 'craftId', compositeKey: true, uniqueWith: [CompanyCraftsUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'}
];
