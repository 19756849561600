import {Attachment, BimMarker, BimPlan, BimVersion, ProtocolEntry} from 'submodules/baumaster-v2-common';
import {OverlayEventDetail} from '@ionic/core';
import _ from 'lodash';

export interface BimVersionWithAttachment extends BimVersion {
  deletable: boolean;
  isLatestVersion: boolean;
  isLatestFinalVersion: boolean;
  bimMarkers: BimMarker[];
  createdByInitials?: string;
  createdByName?: string;
  lastMarkerAt?: Date|string;
  lastMarkerByName?: string;
  lastMarkerByInitials?: string;
}

export interface BimPlanWithDeletable extends BimPlan {
  deletable: boolean;
  latestBimVersionDeletable: boolean;
  hasDraftVersion: boolean;
  latestBimVersion?: BimVersionWithAttachment;
  latestBimVersionByDisplayOrder?: BimVersionWithAttachment;
  latestFinalBimVersion?: BimVersionWithAttachment;
  bimVersions: BimVersionWithAttachment[];
}

export type AutodeskDocumentLoadingState = 'loading' | 'success' | 'autodeskStillProcessing' | 'failed' | 'failedOffline';

export type BimViewerComponentRole = 'cancel' | 'save' | 'close';

export interface BimMarkerChanges {
  insert?: BimMarkerCreation|Array<BimMarkerCreation>;
  delete?: BimMarker|BimMarkerCreation|Array<BimMarker|BimMarkerCreation>;
}

export interface BimViewerComponentData {
  selectedBimVersion: BimVersion;
  bimMarkers?: Array<BimMarker|BimMarkerCreation>;
  markerChanges?: BimMarkerChanges;
}

export type BimPlanTreeViewComponentRole = 'cancel' | 'ok';

export interface BimPlanTreeViewComponentData {
  selectedBimVersion: BimVersion;
  bimViewerDismiss: Promise<OverlayEventDetail<BimViewerComponentData>>;
}

export enum BimViewerComponentReturnType {
  PERSIST_CHANGES,
  RETURN_CHANGES
}

export interface BimViewerComponentParam {
  returnType: BimViewerComponentReturnType,
  selectedProtocolEntry?: ProtocolEntry | undefined,
  selectedBimVersion: BimVersion,
  acrossProjects?: boolean;
  markers?: Array<BimMarker|BimMarkerCreation>;
}

export interface BimMarkerCreation extends BimMarker {
  attachment: Attachment,
  blob: Blob
}

export function toBimMarker(bimMarker: BimMarker|BimMarkerCreation): BimMarker {
  if (!isBimMarkerCreation(bimMarker)) {
    return bimMarker;
  }
  return _.omit(bimMarker, 'attachment', 'blob');
}

export function isBimMarkerCreation(bimMarker: BimMarker|BimMarkerCreation): boolean {
  return 'attachment' in bimMarker && 'blob' in bimMarker;
}
