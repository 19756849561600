import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TagBase} from 'submodules/baumaster-v2-common';

@Component({
  selector: 'app-all-tags-popover',
  templateUrl: './all-tags-popover.component.html',
  styleUrls: ['./all-tags-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllTagsPopoverComponent {
  @Input()
  tags: TagBase[];
}
