import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {skipWhile, take, takeUntil} from 'rxjs/operators';
import {AppDataPageService} from 'src/app/services/data/app-data-page.service';

const redirectNotSet = Symbol('LoadingAppDataComponent.redirectNotSet');

@Component({
  selector: 'app-loading-app-data',
  templateUrl: './loading-app-data.component.html',
  styleUrls: ['./loading-app-data.component.scss'],
  standalone: true,
  imports: [],
})
export class LoadingAppDataComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  private redirectTo: string|undefined|(typeof redirectNotSet) = redirectNotSet;
  private canRedirect = false;

  constructor(private route: ActivatedRoute, private appDataPageService: AppDataPageService, private router: Router) { }

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const {redirectTo} = params ?? {};
      this.redirectTo = redirectTo;
      this.redirectIfCan();
    });
    this.appDataPageService.allowInteraction$.pipe(skipWhile((v) => !v), take(1)).subscribe(() => {
      this.canRedirect = true;
      this.redirectIfCan();
    });
  }

  private redirectIfCan() {
    if (this.canRedirect && this.redirectTo !== redirectNotSet) {
      this.router.navigateByUrl(this.redirectTo, {replaceUrl: true});
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
