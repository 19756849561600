import { Injectable } from '@angular/core';
import {PdfPlan} from 'submodules/baumaster-v2-common';
import {EditMode} from './abstract-edit-mode.service';
import {PdfPlanHolderEditModeService} from './pdf-plan-holder-edit-mode.service';
import {PdfPlanHolderSelectionService} from './pdf-plan-holder-selection.service';

@Injectable({
  providedIn: 'root'
})
export class PdfPlanHoldersActionsService {

  constructor(
    private pdfPlanHolderEditModeService: PdfPlanHolderEditModeService,
    private pdfPlanHolderSelectionService: PdfPlanHolderSelectionService
  ) { }

  enterEditMode(holders?: PdfPlan[], mode?: EditMode) {
    if (this.pdfPlanHolderEditModeService.editMode) {
      return;
    }

    if (holders?.length) {
      this.pdfPlanHolderSelectionService.select(holders);
    }

    if (this.pdfPlanHolderSelectionService.anySelected()) {
      this.pdfPlanHolderEditModeService.enterEditMode(mode);
    }
  }
}
