<app-modal-header>
  <ion-title>{{ 'Tags' | translate }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <app-tag-list [selectedIds]="tagIds" (tagClick)="toggleTag($event)" [tags]="allTags$ | async" [interactive]="true">
    <ng-template appTagEndTemplate let-tag="tag">
      <fa-icon [icon]="isTagSelected(tag) ? ['fal', 'times'] : ['fal', 'tag']"></fa-icon>
    </ng-template>
  </app-tag-list>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-secondary" (click)="clearAndClose()">{{(clearLabel || 'clear') | translate}}</ion-button>
    <ion-button class="omg-btn-primary" (click)="save()">{{(applyLabel || 'button.apply') | translate}}</ion-button>
  </ion-buttons>
</app-modal-footer>
