import {Directive, HostBinding, Input} from '@angular/core';
import {AvatarComponent} from 'src/app/components/ui/avatar/avatar.component';
import {BauAccentColors} from 'src/app/shared/colors';

const getAccentColor = (initials = '') => {
  const pseudoHash = (initials[0] ?? 'A').charCodeAt(0) + (initials[1] ?? 'B').charCodeAt(0);
  return BauAccentColors[pseudoHash % BauAccentColors.length];
};

@Directive({
  selector: 'app-avatar[appAvatarBauColor]'
})
export class AvatarBauColorDirective {

  @Input()
  set initials(initials: string) {
    this.avatarComponent.color = `var(--bau-color-${getAccentColor(initials).color})`;
  }

  @HostBinding('style.color')
  get color() { return 'var(--ion-color-white)'; }

  constructor(private avatarComponent: AvatarComponent) {
    this.avatarComponent.dark = false;
  }

}
