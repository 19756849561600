import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {BimPlan, IdType, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {Observable} from 'rxjs';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/bimPlans';

@Injectable({
  providedIn: 'root'
})
export class BimPlanDataService extends AbstractProjectAwareDataService<BimPlan> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, protected projectDataService: ProjectDataService,
              loggingService: LoggingService, projectAvailabilityExpirationService: ProjectAvailabilityExpirationService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.BIM_PLAN, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService,
          projectAvailabilityExpirationService, integrityResolverService, VERSION_INTRODUCED_DEFAULT, ['id']);
  }

  getByProjectId$(projectId: IdType): Observable<Array<BimPlan>> {
    return this.dataByProjectId$.pipe(map((byProjectId) => byProjectId[projectId] ?? []));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

}
