<ion-grid class="omg-form">
  <ion-row *ngFor="let folder of folders$ | async; trackBy: trackById">
    <ion-col size="12">
      <ion-item button class="cursor-pointer omg-form-checkbox" (click)="chooseFolder(folder)">
         <ion-checkbox label-placement="end" justify="start" [checked]="folderId === folder.id">
           <fa-icon class="pr-2" [fixedWidth]="true" [icon]="(folder.icon | iconToArray) || ['fal', 'folder']"></fa-icon>
           {{folder.name}}
         </ion-checkbox>
        <div slot="end">
          <app-chip-with-label [chip]="'' + (folder.pdfPlanHolderCount > 999 ? '999+' : folder.pdfPlanHolderCount)" color="neutral-dimmed"></app-chip-with-label>
        </div>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>
