import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {ProtocolLayoutDataService} from 'src/app/services/data/protocol-layout-data.service';
import {IdType, ProjectProtocolType, ProtocolLayout, ProtocolType, TASK_PROTOCOL_TYPE_CODE, TASK_PROTOCOL_TYPE_NAME} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {AlertController, IonInput} from '@ionic/angular';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ProtocolTypeDataService} from 'src/app/services/data/protocol-type-data.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {ProjectProtocolTypeDataService} from 'src/app/services/data/project-protocol-type-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {v4 as uuid4} from 'uuid';
import {SystemEventService} from '../../../services/event/system-event.service';
import {ClientService} from '../../../services/client/client.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {SyncService} from 'src/app/services/sync/sync.service';
import {SyncStrategy} from 'src/app/services/sync/sync-utils';
import {LoadingService} from 'src/app/services/common/loading.service';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';
import {ProtocolDataService} from 'src/app/services/data/protocol-data.service';
import {compareUnsortedArraysByObjectKeys} from 'src/app/utils/compare-utils';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {IonicSelectableComponent} from 'ionic-selectable';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';
import {isHttpError} from 'src/app/utils/error-utils';

interface ProtocolLayoutOption extends ProtocolLayout {
  label: string;
}

const LOG_SOURCE = 'ProtocolTypeComponent';

@Component({
  selector: 'app-protocol-type',
  templateUrl: './protocol-type.component.html',
  styleUrls: ['./protocol-type.component.scss'],
})
export class ProtocolTypeComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() protocolTypeId: IdType | null = null;
  @Input() projectId: IdType | undefined;
  @Input() prePopulateMainFieldWithText?: string;
  @Input() layoutId?: IdType;
  @Input() createForSelectable = false;
  @ViewChild('inputCode', {static: false}) inputCode: IonInput;

  public protocolTypeForm: UntypedFormGroup = this.formBuilder.group({
    code: ['', [Validators.required, this.uniqueCode(), this.systemCodeValidator()]],
    name: ['', [Validators.required, this.uniqueName(), this.systemNameValidator()]],
    layout: ['', [Validators.required, this.protocolTypeInUseValidator()]]
  });
  public protocolLayouts$: Observable<ProtocolLayoutOption[]>;
  public addToProject = true;
  public loading = false;
  public restoreMode = false;

  private modal: HTMLIonModalElement;
  private destroy$ = new Subject<void>();
  private patchLayoutSubscription: Subscription | undefined;
  public protocolType: ProtocolType | undefined;
  private protocolTypes: ProtocolType[] | undefined;
  private protocolsCountByTypeId: Record<IdType, number> | undefined;

  notConnected$ = this.featureEnabledService.isFeatureEnabled$(false, true, null);

  isOffline$ = this.networkStatusService.networkConnectedObservable.pipe(
    map((connected) => !connected)
  );
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  constructor(private formBuilder: UntypedFormBuilder,
              private protocolLayoutDataService: ProtocolLayoutDataService,
              private protocolDataService: ProtocolDataService,
              private translateService: TranslateService,
              private alertCtrl: AlertController,
              private systemEventService: SystemEventService,
              private loggingService: LoggingService,
              private toastService: ToastService,
              private protocolTypeDataService: ProtocolTypeDataService,
              private projectProtocolTypeDataService: ProjectProtocolTypeDataService,
              private projectDataService: ProjectDataService,
              private clientService: ClientService,
              private featureEnabledService: FeatureEnabledService,
              private syncService: SyncService,
              private loadingService: LoadingService,
              private networkStatusService: NetworkStatusService,
              private selectableUtilService: SelectableUtilService) {
  }

  async ngOnInit() {
    this.protocolTypeDataService.dataWithoutHiddenForOwnClient$
      .pipe(takeUntil(this.destroy$))
      .subscribe((protocolTypes: ProtocolType[]) => {
        this.protocolTypes = protocolTypes;
      });
    this.protocolDataService.dataAcrossProjects$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(compareUnsortedArraysByObjectKeys(['typeId']))
      )
      .subscribe((protocols) => {
        this.protocolsCountByTypeId = _.mapValues(_.groupBy(protocols, 'typeId'), (group) => group.length);
        this.protocolTypeForm.controls.layout.updateValueAndValidity();
      });

    this.protocolLayouts$ = this.protocolLayoutDataService.data
      .pipe(map(layouts => {
        const clonedLayouts = _.cloneDeep(layouts);
        return _.map(clonedLayouts, (layout) => {
          const protocolLayoutOption: ProtocolLayoutOption = {
            ...layout,
            label: this.getTranslatedName(layout.name)
          };
          return protocolLayoutOption;
        });
      }));

    if (this.protocolTypeId) {
      this.patchProtocolType();
    } else if (this.layoutId) {
      this.patchLayoutWithId(this.layoutId);
    }
    if (this.prePopulateMainFieldWithText) {
      this.protocolTypeForm.controls.name.setValue(this.prePopulateMainFieldWithText);
      this.protocolTypeForm.controls.name.markAsDirty();
    }
    this.setCanDismiss();
  }

  private protocolTypeInUseValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.protocolTypeId) {
        return null;
      }

      if (this.protocolsCountByTypeId && this.protocolsCountByTypeId?.[this.protocolTypeId] > 0 && this.protocolType?.layoutId !== control.value?.id) {
        return {protocolTypeInUse: true};
      }

      return null;
    };
  }

  private uniqueName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const protocolTypeName: string | null = control.value;
      if (_.isEmpty(protocolTypeName) || this.protocolType?.name === protocolTypeName) {
        return null;
      }

      const recordExist = _.filter(this.protocolTypes, protocolType => protocolType.name === protocolTypeName && (protocolType.isActive || protocolType.isActive === undefined));
      if (!_.isEmpty(recordExist)) {
        return {nameExist: true};
      }

      if (!this.restoreMode){
        const recordDeletedExist = _.filter(this.protocolTypes, protocolType => protocolType.name === protocolTypeName && !protocolType.isActive);
        if (!_.isEmpty(recordDeletedExist)) {
          return {nameExistAndInactive: true};
        }
      }
      return null;
    };
  }

  private uniqueCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const protocolTypeCode: string | null = control.value;

      if (_.isEmpty(protocolTypeCode) || this.protocolType?.code === protocolTypeCode) {
        return null;
      }

      const recordExist = _.filter(this.protocolTypes, protocolType => protocolType.code === protocolTypeCode && (protocolType.isActive || protocolType.isActive === undefined));
      if (!_.isEmpty(recordExist)) {
        return {codeExist: true};
      }
      if (!this.restoreMode){
        const recordDeletedExist = _.filter(this.protocolTypes, protocolType => protocolType.code === protocolTypeCode && protocolType.isActive === false);
        if (!_.isEmpty(recordDeletedExist)) {
          return {codeExistAndInactive: true};
        }
      }
      return null;
    };
  }

  private systemCodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const protocolTypeCode: string | null = control.value;
      if (protocolTypeCode && protocolTypeCode.toLowerCase() === TASK_PROTOCOL_TYPE_CODE.toLowerCase()) {
        return {codeSystemReserved: true};
      }
      return null;
    };
  }

  private systemNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const protocolTypeName: string | null = control.value;
      if (protocolTypeName && protocolTypeName.toLowerCase() === TASK_PROTOCOL_TYPE_NAME.toLowerCase()) {
        return {nameSystemReserved: true};
      }
      return null;
    };
  }

  ngAfterViewInit() {
    setTimeout(async () => {
      await this.inputCode.setFocus();
    }, 500);
  }

  private getTranslatedName(name: string): string {
    const key = 'protocolLayout.' + name;
    const translated = this.translateService.instant(key);
    return !translated || translated === key ? name : translated;
  }

  toggleAddToProject() {
    this.addToProject = !this.addToProject;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private patchProtocolType() {
    this.protocolTypeDataService.getByIdForOwnClient(this.protocolTypeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((protocolType: ProtocolType) => {
        this.protocolType = protocolType;
        this.protocolTypeForm.patchValue(protocolType);
        this.patchLayout(protocolType);
        this.protocolTypeForm.markAsPristine();
      });
  }

  private patchLayout(protocolType: ProtocolType) {
    this.patchLayoutWithId(protocolType.layoutId);
  }

  private patchLayoutWithId(layoutId: IdType) {
    this.unsubscribePatchLayoutSubscription();
    this.patchLayoutSubscription = this.protocolLayouts$.pipe(takeUntil(this.destroy$))
      .subscribe((protocolLayoutOptions: ProtocolLayoutOption[]) => {
        const layoutOption = _.find(protocolLayoutOptions, (protocolLayoutOption: ProtocolLayoutOption) => protocolLayoutOption.id === layoutId);
        this.protocolTypeForm.get('layout').setValue(layoutOption);
      });
  }

  private unsubscribePatchLayoutSubscription() {
    this.patchLayoutSubscription?.unsubscribe();
    this.patchLayoutSubscription = undefined;
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (role === 'delete') {
      return true;
    }

    if (!this.protocolTypeForm?.dirty) {
      return true;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          role: 'dismiss'
        }
      ]
    });

    await alert.present();
    return (await alert.onWillDismiss()).role === 'dismiss';
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  dismissModal(role?: 'ok' | 'cancel', data?: ProtocolType) {
    return this.modal.dismiss(data, role);
  }

  async saveProtocolType() {
    try {
      this.loading = true;

      const rawData = this.protocolTypeForm.getRawValue();

      const protocolTypeData = {
        name: rawData.name,
        code: rawData.code,
        layoutId: rawData.layout.id
      };

      if (!this.protocolTypeId) {
        let protocolType: ProtocolType = this.protocolTypes.find((data) => data.name === protocolTypeData.name && data.code === protocolTypeData.code);
        if (protocolType) {
          await this.undoLogicalDelete(protocolType);
          return;
        }
        const client = await this.clientService.getOwnClientMandatory();
        protocolType = {
          id: uuid4(),
          clientId: client.id,
          changedAt: new Date().toISOString(),
          isActive: true,
          ...protocolTypeData
        };

        await this.createProtocolType(protocolType);
      } else {
        const protocolType: ProtocolType = {...this.protocolType, ...protocolTypeData};
        await this.updateProtocolType(protocolType, this.protocolType);
      }
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - save protocol type ', error?.userMessage + '-' + error?.message);
      this.loggingService.error(LOG_SOURCE, `Error save protocol type. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(error));
      throw error;
    } finally {
      this.loading = false;
    }
  }

  private async createProtocolType(protocolType: ProtocolType) {
    await this.protocolTypeDataService.insert(protocolType, protocolType.clientId);
    await this.addProtocolTypeToProject(protocolType);
    this.protocolTypeForm.markAsPristine();
    await this.dismissModal('ok', protocolType);
  }

  private async addProtocolTypeToProject(protocolType: ProtocolType) {
    if (this.addToProject && await observableToPromise(this.notConnected$)) {
      const projectId = this.projectId ?? (await this.projectDataService.getCurrentProject()).id;
      const projectProtocolType: ProjectProtocolType = {
        id: projectId + protocolType.id,
        projectId,
        protocoltypeId: protocolType.id,
        changedAt: new Date().toISOString()
      };
      await this.projectProtocolTypeDataService.insert(projectProtocolType, projectId);
    }
  }

  private async updateProtocolType(protocolType: ProtocolType, oldProtocolType: ProtocolType) {
    const alert = await this.alertCtrl.create({
      message: this.translateService.instant('protocolTypeForm.editMessage'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            await alert.dismiss();
            const hasProtocolLayoutChanged = protocolType.layoutId !== oldProtocolType.layoutId;
            if (!hasProtocolLayoutChanged) {
              await this.protocolTypeDataService.update(protocolType, protocolType.clientId);
              this.protocolTypeForm.markAsPristine();
              await this.dismissModal('ok', protocolType);
              return;
            }
            await this.loadingService.withLoading(async () => {
              try {
                await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES);
                await this.protocolTypeDataService.updateProtocolType(protocolType);
                this.protocolTypeForm.markAsPristine();
                await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
                this.toastService.savingSuccess();
                await this.dismissModal('ok', protocolType);
              } catch (e) {
                this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
                if (!isHttpError(e, 406)) {
                  await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(e));
                } else {
                  this.protocolTypeForm.get('layout').setErrors({protocolTypeInUse: true});
                }
                this.systemEventService.logErrorEvent(() => `${LOG_SOURCE} - updateProtocolType failed`, e);
                this.loggingService.error(LOG_SOURCE, `updateProtocolType failed: ${convertErrorToMessage(e)}`);
              }
            });
          }
        }
      ]
    });
    await alert.present();
  }

  async deleteProtocolType(protocolType: ProtocolType) {
    const alert = await this.alertCtrl.create({
      message: this.translateService.instant('protocolTypeForm.confirmDelete'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            await this.doLogicalDelete(protocolType);
            await this.toastService.info('protocolTypeForm.deleteSuccessful');
            await this.modal.dismiss(undefined, 'delete');
          }
        }
      ]
    });
    await alert.present();
  }

  async doLogicalDelete(protocolType: ProtocolType) {
    protocolType.isActive = false;
    await this.protocolTypeDataService.update(protocolType, protocolType.clientId);
    const allProjectProtocolTypes = await observableToPromise(this.projectProtocolTypeDataService.getByProtocolTypeAcrossProjects(protocolType));
    allProjectProtocolTypes.forEach((projectCraft) => this.projectProtocolTypeDataService.delete(projectCraft, projectCraft.projectId));
  }

  async undoLogicalDelete(protocolType: ProtocolType) {
    protocolType.isActive = true;
    await this.protocolTypeDataService.update(protocolType, protocolType.clientId);
    await this.addProtocolTypeToProject(protocolType);
    this.protocolTypeForm.markAsPristine();
    await this.dismissModal('ok', protocolType);
  }

  private async doRestore(protocolType: ProtocolType) {
    this.protocolTypeForm.get('code').setValue(protocolType.code);
    this.protocolTypeForm.get('code').disable();
    this.protocolTypeForm.get('name').setValue(protocolType.name);
    this.protocolTypeForm.get('name').disable();
    const layouts = await observableToPromise(this.protocolLayouts$);
    const layoutOption = layouts.find((layout) => layout.id === protocolType.layoutId);
    this.protocolTypeForm.get('layout').setValue(layoutOption);
    this.protocolTypeForm.get('layout').disable();
    this.restoreMode = true;
    this.protocolTypeForm.markAsPristine();
  }

  async restoreByCode() {
    const formData = this.protocolTypeForm.getRawValue();
    const protocolType = this.protocolTypes.find((data) => data.code === formData.code);
    await this.doRestore(protocolType);
  }

  async restoreByName() {
    const formData = this.protocolTypeForm.getRawValue();
    const protocolType = this.protocolTypes.find((data) => data.name === formData.name);
    await this.doRestore(protocolType);
  }

  async onOpen($event: { component: IonicSelectableComponent }) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: { component: IonicSelectableComponent }) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
