import {Injectable} from '@angular/core';
import {observableToPromise} from 'src/app/utils/async-utils';
import {DashboardFilterService} from '../dashboard/dashboard-filter.service';
import {Router} from '@angular/router';
import {SelectedProtocolService} from '../protocol/selected-protocol.service';

const LOG_SOURCE = 'NavigateHelperService';

@Injectable({
  providedIn: 'root'
})
export class NavigateHelperService {

  constructor(private dashboardFilterService: DashboardFilterService, private router: Router, private selectedProtocolService: SelectedProtocolService) { }

  public currentlyNavigatingToTaskOrEntry = false;
  public avoidSettingProjectWhenNavigateFromProjects = false;
  public navigationFromTaskToTaskWithNotification = false;

  async resetPagesBeforeEntryNavigation(projectId: string, protocolId: string | undefined) {
    if (this.router.url.includes('dashboard')) {
      const filter = await observableToPromise(this.dashboardFilterService.filters$);
      filter.projects = [projectId];
      this.dashboardFilterService.setFilters(filter);
    }
    if (this.router.url.includes('projects')) {
      this.avoidSettingProjectWhenNavigateFromProjects = true;
    }
    if (protocolId && this.router.url.includes('protocols')) {
      await this.selectedProtocolService.setCurrentProtocol(protocolId);
    }
  }
}
