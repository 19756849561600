import {Component, Input, OnInit} from '@angular/core';
import {AutodeskDocumentLoadingState} from '../../../model/bim-plan-with-deletable';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {UiModule} from '../../../shared/module/ui/ui.module';
import {TranslateModule} from '@ngx-translate/core';
import {OfflineInfoComponent} from '../../common/offline-info/offline-info.component';

@Component({
  selector: 'app-autodesk-document-loading-state',
  templateUrl: './autodesk-document-loading-state.component.html',
  styleUrls: ['./autodesk-document-loading-state.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    UiModule,
    TranslateModule,
    OfflineInfoComponent,
  ],
})
export class AutodeskDocumentLoadingStateComponent implements OnInit {

  @Input()
  autodeskDocumentLoadingState?: AutodeskDocumentLoadingState;

  constructor() { }

  ngOnInit() {
  }

}
