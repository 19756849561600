import { ReportCompanyColumnSet } from './ReportCompanyColumnSet';
import { ProtocolEntryColumnSet } from './ProtocolEntryColumnSet';
import { ColumnConfig } from '../ColumnConfig';
import { CompanyCraftColumnSet } from './CompanyCraftColumnSet';
import { ProfileColumnSet } from './ProfileColumnSet';
import { ProjectCompanyColumnSet } from './ProjectCompanyColumnSet';
import {ProtocolEntryCompanyColumnSet} from './ProtocolEntryCompanyColumnSet';

export const CompanyColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'name'},
  {name: 'sector', def: null},
  {name: 'note', def: null},
  {name: 'address_id', prop: 'addressId', def: null},
  {name: 'client_id', prop: 'clientId', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'companyCrafts', fk: 'companyId', mappedBy: CompanyCraftColumnSet, transient: true},
  {name: 'profiles', fk: 'companyId', mappedBy: ProfileColumnSet, transient: true},
  {name: 'projectCompanies', fk: 'companyId', mappedBy: ProjectCompanyColumnSet, transient: true},
  {name: 'protocolEntries', fk: 'companyId', mappedBy: ProtocolEntryColumnSet, transient: true},
  {name: 'reportCompanies', fk: 'companyId', mappedBy: ReportCompanyColumnSet, transient: true},
  {name: 'protocolEntryCompanies', fk: 'companyId', mappedBy: ProtocolEntryCompanyColumnSet, transient: true},
];
