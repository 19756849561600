import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  IdType,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPage,
  PdfPlanPageMarking,
  PdfPlanVersion,
  Protocol,
  PROTOCOL_LAYOUT_NAME_SHORT,
  ProtocolEntry,
  ProtocolEntryStatus,
  ProtocolEntryType,
  ProtocolLayout,
  ProtocolType
} from 'submodules/baumaster-v2-common';
import {PdfPlanService} from '../../../services/pdf/pdf-plan.service';
import {PdfPlanMarkerProtocolEntryDataService} from '../../../services/data/pdf-plan-marker-protocol-entry-data.service';
import {PdfPlanPageDataService} from '../../../services/data/pdf-plan-page-data.service';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {PdfPlanPageMarkingDataService} from '../../../services/data/pdf-plan-page-marking-data.service';
import {combineLatestAsync} from '../../../utils/async-utils';
import {ProtocolEntryDataService} from '../../../services/data/protocol-entry-data.service';
import _ from 'lodash';
import {ProtocolEntryTypeDataService} from '../../../services/data/protocol-entry-type-data.service';
import {ProtocolDataService} from '../../../services/data/protocol-data.service';
import {ProtocolLayoutDataService} from '../../../services/data/protocol-layout-data.service';
import {ProtocolTypeDataService} from '../../../services/data/protocol-type-data.service';

@Component({
  selector: 'app-pdf-plan-marker-form',
  templateUrl: './pdf-plan-marker-form.component.html',
  styleUrls: ['./pdf-plan-marker-form.component.scss'],
})
export class PdfPlanMarkerFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  latestPdfPlanVersion: PdfPlanVersion;

  @Input()
  pdfPlanVersion: PdfPlanVersion;

  @Input()
  pdfPlanPages: Array<PdfPlanPage>;

  @Input()
  imageBlobs: Array<Blob>;

  @Input()
  migratePdfPlanPageMarkingGeneral = true;

  @Output()
  migratePdfPlanPageMarkingGeneralChange = new EventEmitter<boolean>();

  latestPdfPlanVersionPages$: Observable<Array<PdfPlanPage>>;
  pdfPlanMarkerProtocolEntries$: Observable<Array<PdfPlanMarkerProtocolEntry>>;
  pdfPlanPageMarkings$: Observable<Array<PdfPlanPageMarking>>;
  pdfPlanPageMarkingGeneral$: Observable<boolean>;
  protocolEntriesOpenOther$: Observable<{ protocolEntries: ProtocolEntry[], protocolEntriesOther: ProtocolEntry[]; protocolEntriesOpen: ProtocolEntry[] }>;

  constructor(private pdfPlanService: PdfPlanService, private pdfPlanPageDataService: PdfPlanPageDataService, private protocolDataService: ProtocolDataService,
              private protocolLayoutDataService: ProtocolLayoutDataService, private protocolTypeDataService: ProtocolTypeDataService,  private protocolEntryDataService: ProtocolEntryDataService,
              private protocolEntryTypeDataService: ProtocolEntryTypeDataService, private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
              private pdfPlanPageMarkingDataService: PdfPlanPageMarkingDataService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.latestPdfPlanVersion) {
      this.latestPdfPlanVersionPages$ = this.pdfPlanPageDataService.getPlanPageByPlanVersionId(this.latestPdfPlanVersion.id);
      this.pdfPlanMarkerProtocolEntries$ = this.latestPdfPlanVersionPages$.pipe(switchMap((pdfPlanVersionPages) => this.pdfPlanMarkerProtocolEntryDataService.getByPlanPages(pdfPlanVersionPages)));
      this.pdfPlanPageMarkings$ = this.latestPdfPlanVersionPages$.pipe(switchMap((pdfPlanVersionPages) => this.pdfPlanPageMarkingDataService.getByPlanPages(pdfPlanVersionPages)));
      this.protocolEntriesOpenOther$ = combineLatestAsync(
        [this.pdfPlanMarkerProtocolEntries$, this.protocolDataService.dataWithoutHiddenGroupedById$, this.protocolTypeDataService.dataWithoutHiddenGroupedById$,
          this.protocolLayoutDataService.dataGroupedById, this.protocolEntryTypeDataService.dataGroupedById, this.protocolEntryDataService.data])
        .pipe(map(([pdfPlanMarkerProtocolEntries, protocolsById, protocolTypesById, protocolLayoutsById, protocolEntryTypesById, allProtocolEntries]) => {
          const protocolEntryIds: Array<IdType> = _.uniq(_.compact(pdfPlanMarkerProtocolEntries.map((value) => value.protocolEntryId)
            .concat(pdfPlanMarkerProtocolEntries.map((value) => value.protocolEntryId))));
          const isProtocolLayoutShort = (protocolEntry: ProtocolEntry): boolean => {
            const protocol: Protocol | undefined = protocolsById[protocolEntry.protocolId];
            const protocolType: ProtocolType | undefined = protocolTypesById[protocol?.typeId];
            const protocolLayout: ProtocolLayout | undefined = protocolLayoutsById[protocolType?.layoutId];
            return protocolLayout?.name === PROTOCOL_LAYOUT_NAME_SHORT;
          };
          const isStatusFieldActiveType = (protocolEntry: ProtocolEntry): boolean => {
            const protocolEntryType: ProtocolEntryType|undefined = protocolEntry.typeId ? protocolEntryTypesById[protocolEntry.typeId] : undefined;
            return protocolEntry.typeId && protocolEntryType && protocolEntryType.statusFieldActive;
          };
          const protocolEntries = allProtocolEntries.filter((protocolEntry) => protocolEntryIds.includes(protocolEntry.id));
          const protocolEntriesOpen = protocolEntries.filter((protocolEntry) => (isStatusFieldActiveType(protocolEntry) || isProtocolLayoutShort(protocolEntry))
            && protocolEntry.status !== ProtocolEntryStatus.DONE);
          const protocolEntriesOther = protocolEntries.filter((protocolEntry) => (!isStatusFieldActiveType(protocolEntry) && !isProtocolLayoutShort(protocolEntry))
            || protocolEntry.status === ProtocolEntryStatus.DONE);
          return {protocolEntries, protocolEntriesOpen, protocolEntriesOther};
      }));
      this.pdfPlanPageMarkingGeneral$ = this.pdfPlanPageMarkings$.pipe(map((pdfPlanPageMarkings) => pdfPlanPageMarkings.some((pdfPlanPageMarking) => !pdfPlanPageMarking.protocolEntryId)));
    }
  }

  ngOnInit() {}

  ngOnDestroy(): void {
  }

  changeMigratePdfPlanPageMarkingGeneral(value: boolean) {
    this.migratePdfPlanPageMarkingGeneral = value;
    this.migratePdfPlanPageMarkingGeneralChange.emit(value);
  }
}
