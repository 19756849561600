import {Injectable} from '@angular/core';
import {DownloadService} from '../download/download.service';

@Injectable({
  providedIn: 'root'
})
export class CsvExportService {
  constructor(private downloadService: DownloadService) {}

  public async exportToCsv(filename: string, rows: object[]): Promise<void> {
    if (!rows || !rows.length) {
      return;
    }

    const separator = ';';
    const keys = Object.keys(rows[0]);
    const csvContent = keys.join(separator) + '\n' +
      rows.map(row => {
        return keys.map(key => {
          let cell = row[key] === null || row[key] === undefined ? '' : row[key];
          cell = cell.toString().replace(/"/g, '""');
          if (/[";\n]/g.test(cell)) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (this.downloadService.isDownloadNativePlatform()) {
      await this.downloadService.downloadNativePlatform(blob, filename);
      return;
    }
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}