import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ProtocolEntryModalComponent} from 'src/app/components/protocol/protocol-entry-modal/protocol-entry-modal.component';
import {IdType, ProtocolEntry} from 'submodules/baumaster-v2-common';
import {v4} from 'uuid';

export interface OpenModalOptions {
  awaitDismiss: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ProtocolEntryModalService {

  private modals: { uuid: string; protocolEntryId: IdType; protocolId: IdType }[] = [];

  constructor(private modalController: ModalController) {}

  private modalEntry(uuid: string, protocolEntryId: IdType, protocolId: IdType) { return { uuid, protocolEntryId, protocolId }; }

  private compareWithTopModal(protocolEntryId: IdType, protocolId: IdType) {
    return this.modals[this.modals.length - 1]?.protocolEntryId === protocolEntryId &&
           this.modals[this.modals.length - 1]?.protocolId === protocolId;
  }

  private pushModalEntry(protocolEntryId: IdType, protocolId: IdType): string {
    const uuid = v4();
    this.modals = [...this.modals, this.modalEntry(uuid, protocolEntryId, protocolId)];

    return uuid;
  }

  private removeModalEntry(uuidToRemove: string) {
    this.modals = this.modals.filter(({ uuid }) => uuid !== uuidToRemove);
  }

  openModal(protocolEntry: ProtocolEntry, context?: string, options?: Partial<OpenModalOptions>): Promise<void|{role?: string}>;
  openModal(protocolEntryId: IdType, protocolId: IdType, options?: Partial<OpenModalOptions>): Promise<void|{role?: string}>;
  async openModal(protocolEntryOrId: IdType|ProtocolEntry, protocolIdOrContext?: IdType|string, {
    awaitDismiss = false
  }: Partial<OpenModalOptions> = {}) {
    let protocolEntryId: IdType;
    let context: string;
    let protocolId: IdType;
    if (typeof protocolEntryOrId === 'object') {
      protocolEntryId = protocolEntryOrId.id;
      context = protocolIdOrContext;
      protocolId = protocolEntryOrId.createdInProtocolId ?? protocolEntryOrId.protocolId;
    } else {
      protocolEntryId = protocolEntryOrId;
      protocolId = protocolIdOrContext;
    }

    if (this.compareWithTopModal(protocolEntryId, protocolId)) {
      return;
    }

    const uuid = this.pushModalEntry(protocolEntryId, protocolId);

    const modal = await this.modalController.create({
      component: ProtocolEntryModalComponent,
      backdropDismiss: false,
      componentProps: {
        protocolEntryId,
        protocolId,
        context
      },
      cssClass: 'protocol-entry-modal max-950-modal'
    });
    modal.onWillDismiss().then(() => this.removeModalEntry(uuid));

    await modal.present();

    if (awaitDismiss) {
      return await modal.onDidDismiss();
    }
  }
}
