import {ColumnConfig} from '../ColumnConfig';
import {BimVersionColumnSet} from './BimVersionColumnSet';

export const BimPlanColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'project_id', prop: 'projectId', cnd: true},
  {name: 'name', prop: 'name'},
  {name: 'active', prop: 'active'},
  {name: 'bimVersions', fk: 'bimPlanId', mappedBy: BimVersionColumnSet, transient: true},
];