import {Injectable, OnDestroy} from '@angular/core';
import {IdType, Project} from 'submodules/baumaster-v2-common';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from './logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {map, shareReplay} from 'rxjs/operators';
import {StorageService} from '../storage.service';
import {RecentlyUsed} from '../../model/project-with-offline';

const STORAGE_KEY = StorageKeyEnum.LAST_USED_PROJECT;
const RECENTLY_USED_LIMIT_IN_DAYS = 30;
const RECENTLY_USED_LIMIT_IN_MS = RECENTLY_USED_LIMIT_IN_DAYS * 24 * 60 * 60 * 1000;
const VERY_RECENTLY_USED_LIMIT_IN_DAYS = 5;
const VERY_RECENTLY_USED_LIMIT_IN_MS = VERY_RECENTLY_USED_LIMIT_IN_DAYS * 24 * 60 * 60 * 1000;

const LOG_SOURCE = 'LastUsedProjectService';

@Injectable({
  providedIn: 'root'
})

export class LastUsedProjectService implements OnDestroy {
  private readonly defaultStorageValue: { [key in IdType]: number } = {};
  private readonly dataSubject = new BehaviorSubject<{ [key in IdType]: number }>(this.defaultStorageValue);
  public readonly data: Observable<{ [key in IdType]: number }> = this.dataSubject.asObservable();
  public readonly recentlyUsedByProjectId$: Observable<Record<IdType, RecentlyUsed>>;
  private readonly authSubscription: Subscription;
  protected isAuthenticated: boolean|undefined;

  constructor(private storage: StorageService,
              private authenticationService: AuthenticationService,
              private loggingService: LoggingService) {
    this.loggingService.debug(LOG_SOURCE, 'constructor - called');
    this.initFromStorage();
    this.recentlyUsedByProjectId$ = this.data.pipe(map((lastUsedByProjectId) => {
      const recentlyUsedByProjectId: Record<IdType, RecentlyUsed> = {};
      const now = Date.now();
      for (const projectId of Object.keys(lastUsedByProjectId)) {
        const lastUsed = lastUsedByProjectId[projectId];
        const recentlyUsed = lastUsed >= now - RECENTLY_USED_LIMIT_IN_MS;
        const veryRecentlyUsed = lastUsed >= now - VERY_RECENTLY_USED_LIMIT_IN_MS;
        if (recentlyUsed || veryRecentlyUsed) {
          recentlyUsedByProjectId[projectId] = {
            lastUsed,
            recentlyUsed,
            veryRecentlyUsed
          };
        }
      }
      return recentlyUsedByProjectId;
    }))
      .pipe(shareReplay({
        refCount: true,
        bufferSize: 1,
      }));
    this.authSubscription = this.authenticationService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
      try {
        this.loggingService.debug(LOG_SOURCE, `authSubscription submitted a value of ${isAuthenticated}`);
        if (isAuthenticated === null) {
          this.loggingService.debug(LOG_SOURCE, 'authSubscription submitted value of false/undefined. Wait for storage ready to remove data from storage.');
          this.loggingService.debug(LOG_SOURCE, 'authSubscription submitted value of false/undefined. storage ready awaited.');
          await this.storage.remove(STORAGE_KEY);
          this.loggingService.info(LOG_SOURCE, 'authSubscription submitted value of false/undefined. Removed data from storage.');
          this.dataSubject.next(this.defaultStorageValue);
        }
      } catch (err) {
        this.loggingService.error(LOG_SOURCE, `authSubscription failed with "${err.message}".`);
      }
    });
  }

  ngOnDestroy(): void {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called.');
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  async initFromStorage() {
    try {
      this.loggingService.debug(LOG_SOURCE, 'initFromStorage.');
      const storageValues: { [key in IdType]: number } = await this.storage.get(STORAGE_KEY);
      if (!storageValues) {
        this.loggingService.debug(LOG_SOURCE, 'initFromStorage - No value in storage. Init with defaultStorageValue.');
        await this.storage.set(STORAGE_KEY, this.defaultStorageValue, {
          ensureStored: false,
        });
        this.dataSubject.next(this.defaultStorageValue);
      } else {
        this.loggingService.debug(LOG_SOURCE, 'initFromStorage - Init dataSubject with storage value.', JSON.stringify(storageValues));
        this.dataSubject.next(storageValues);
      }
    } catch (err) {
      this.loggingService.error(LOG_SOURCE, `initFromStorage failed with error ${err.message}`);
      throw err;
    }
  }

  public async markProjectAsUsed(project: Project): Promise<void> {
    this.loggingService.debug(LOG_SOURCE, 'markProjectAsUsed called', project);
    const storageValues = await this.getStorageValue();
    this.loggingService.debug(LOG_SOURCE, `markProjectAsUsed - current storageValues ${JSON.stringify(storageValues)}`);
    storageValues[project.id] = new Date().getTime();
    this.assertAuthenticated();
    await this.storage.set(STORAGE_KEY, storageValues, {
      ensureStored: false,
    });
    this.loggingService.debug(LOG_SOURCE, `markProjectAsUsed - new storageValues ${JSON.stringify(storageValues)}`);
    this.dataSubject.next(storageValues);
  }

  async getStorageValue(): Promise<{ [key in IdType]: number }> {
    return await this.storage.get(STORAGE_KEY) || this.defaultStorageValue;
  }

  protected assertAuthenticated() {
    if (!this.isAuthenticated) {
      throw new Error('User not authenticated (isAuthenticated is false or undefined)');
    }
  }

}
