<div class="tabs">
  <ion-toolbar class="mobile-padding">
    <div class="center">
      <ion-button *ngFor="let tabMenu of tabMenus; let arrIndex = index" fill="clear"  (click)="navigateTo(tabMenu)" [class.active]="(isNotificationsCenterOpen$ | async) ? tabMenu?.title === notificationsMenuTitle : (tabMenu?.url === selectedMenu?.url || tabMenu?.url === router.url)" [hidden]="tabMenu.disabled$ | async">
        <div class="d-flex flex-column gap-1 ion-align-items-center">
          <div class="pos-rel d-inline-flex">
            <fa-icon class="omg-t-header-3 text-secondary" [icon]="tabMenu.icon"></fa-icon>
            <span *ngIf="(tabMenu?.title === notificationsMenuTitle && (totalUnseenCount$ | async)) as totalUnseenCount" class="omg-notification-badge">{{totalUnseenCount > 99 ? '99+' : totalUnseenCount}}</span>
          </div>
          <div class="ion-hide-sm-down ion-text-center tab-menu-label">
            <span class="text-secondary">
              {{ tabMenu.titleTab ? (tabMenu.titleTab | translate): (tabMenu.title | translate) }}
            </span>
          </div>
        </div>
      </ion-button>
    </div>
  </ion-toolbar>
</div>
