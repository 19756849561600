import {Injectable, OnDestroy} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {PaymentOverdueModalComponent} from 'src/app/components/client/payment-overdue-modal/payment-overdue-modal.component';
import {isPaymentOverdueClient} from 'src/app/utils/client-status-utils';
import {Client} from 'submodules/baumaster-v2-common';
import {SyncStatusService} from '../sync/sync-status.service';
import {ClientService} from './client.service';

type OpenModal = {
  component: any;
  modal: Promise<HTMLIonModalElement>;
};

@Injectable({
  providedIn: 'root'
})
export class ClientStatusWatcherService implements OnDestroy {

  private dataSyncSubscription: Subscription;
  private ownClientStatusSubscription: Subscription;

  openModals: OpenModal[] = [];
  ownClient: Client = undefined;

  constructor(
    private syncStatusService: SyncStatusService,
    private clientService: ClientService,
    private modalController: ModalController
  ) {
    this.ownClientStatusSubscription = this.clientService.ownClient$.subscribe((client) => this.ownClient = client);

    this.dataSyncSubscription = this.syncStatusService.dataSyncInProgressObservable.pipe(
      filter((v) => !v.inProgress)
    ).subscribe(() => {
      if (isPaymentOverdueClient(this.ownClient)) {
        this.showPaymentOverdueModal();
      }
    });
  }

  private async showPaymentOverdueModal() {
    if (this.openModals.some(({component}) => component === PaymentOverdueModalComponent)) {
      return;
    }

    const modalPromise = this.modalController.create({
      component: PaymentOverdueModalComponent,
      cssClass: 'full-screen-sketch omg-modal',
    });

    const openModal = {component: PaymentOverdueModalComponent, modal: modalPromise};

    this.openModals.push(openModal);

    const modal = await modalPromise;

    modal.canDismiss = () => Promise.resolve(false);
    modal.onWillDismiss().then(() => this.removeOpenModal(openModal));

    await modal.present();
  }

  private removeOpenModal(openModal: OpenModal) {
    const openModalIndex = this.openModals.indexOf(openModal);
    if (openModalIndex > -1) {
      this.openModals.splice(openModalIndex, 1);
    }
  }

  ngOnDestroy() {
    this.dataSyncSubscription?.unsubscribe();
    this.ownClientStatusSubscription?.unsubscribe();
  }
}
