import {Injectable} from '@angular/core';
import {OfflineInfoModalComponent} from '../../components/common/offline-info-modal/offline-info-modal.component';
import {AlertController, ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class OfflineInfoService {

  constructor(private modalController: ModalController, private alertController: AlertController, private translateService: TranslateService) { }

  public async showOfflineModal(showRetry = true, showHint = true): Promise<boolean> {
    const modal = await this.modalController.create({
      component: OfflineInfoModalComponent,
      backdropDismiss: true,
      animated: false,
      componentProps: {
        showRetry,
        showHint
      }
    });
    await modal.present();
    const modalResult = await modal.onDidDismiss();
    return modalResult.role === 'retry';
  }

  public async showOfflineAlert(differentMessage?: string) {
    const alert = await this.alertController.create({
      header: this.translateService.instant('offlineInfo.title'),
      message: differentMessage ? differentMessage : this.translateService.instant('offlineInfo.description1') + ' ' + this.translateService.instant('offlineInfo.description2'),
      buttons : [
        {
          text: this.translateService.instant('cancel'),
          cssClass: 'ion-color-alternative alert-button',
          role: 'cancel'
        },
        {
          text: this.translateService.instant('offlineInfo.retryLabel'),
          cssClass: 'ion-color-text-primary alert-button-solid',
          role: 'retry'
        }
      ]
    });
    await alert.present();
    const alertResult = await alert.onDidDismiss();
    return alertResult?.role === 'retry';
  }
}

