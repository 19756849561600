<app-cancel-apply-modal-frame
  [title]="createMode ? ('project_room.pdf_plan_folder.createTitle' | translate) : 'project_room.pdf_plan_folder.renameTitle' | translate"
  [applyLabel]="createMode ? ('button.create' | translate) : 'button.apply' | translate"
  [applyProps]="{disabled: !componentForm?.valid || !componentForm?.dirty || loading}"
  (applyClick)="save()"
>
  <form class="omg-form" [appLayout]="1.5" [appLayoutColumn]="true" [formGroup]="componentForm" action="#">
    <ion-list>
      <ion-item>
        <ion-input label="{{ 'project_room.pdf_plan_folder.name' | translate }}" label-placement="floating" formControlName="name" maxlength="50" type="text" #nameInput class="omg-required"></ion-input>
      </ion-item>
      <span class="helper-text without-padding error-message" lines="none" *ngIf="componentForm.controls.name.errors?.required && componentForm.controls.name.dirty"> {{ 'formValidation.emptyField' | translate:{field: 'project_room.pdf_plan_folder.name' | translate} }}</span>
      <span class="helper-text without-padding error-message" lines="none" *ngIf="componentForm.controls.name.errors?.nameExist && componentForm.controls.name.dirty"> {{ 'project_room.pdf_plan_folder.nameExist' | translate }} </span>
    </ion-list>
    <app-pdf-plan-folder-icon-picker formControlName="icon"></app-pdf-plan-folder-icon-picker>
  </form>
</app-cancel-apply-modal-frame>
