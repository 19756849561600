import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const bauChat: IconDefinition = {
  prefix: 'bau' as IconPrefix,
  iconName: 'chat' as IconName,
  icon: [
    16,
    16,
    [],
    null,
    // eslint-disable-next-line max-len
    'M1.87387 0C0.836036 0 0 0.836036 0 1.87387V11.2432C0 12.2811 0.836036 13.1171 1.87387 13.1171H11.7911L14.5442 15.8702C14.6307 15.9567 14.7315 16 14.8468 16C14.9045 16 14.9621 15.9857 15.0053 15.9713C15.1639 15.8992 15.2793 15.7405 15.2793 15.5676V1.87387C15.2793 0.836036 14.4432 0 13.4054 0H1.87387ZM1.87387 0.864865H13.4054C13.9676 0.864865 14.4144 1.31171 14.4144 1.87387V14.5298L12.2666 12.382C12.1945 12.2956 12.0793 12.2523 11.964 12.2523H1.87387C1.31171 12.2523 0.864865 11.8054 0.864865 11.2432V1.87387C0.864865 1.31171 1.31171 0.864865 1.87387 0.864865ZM4.18018 5.04505C3.93514 5.04505 3.74775 5.23243 3.74775 5.47748C3.74775 5.72252 3.93514 5.90991 4.18018 5.90991H11.0991C11.3441 5.90991 11.5315 5.72252 11.5315 5.47748C11.5315 5.23243 11.3441 5.04505 11.0991 5.04505H4.18018ZM4.18018 7.20721C3.93514 7.20721 3.74775 7.39459 3.74775 7.63964C3.74775 7.88468 3.93514 8.07207 4.18018 8.07207H8.93694C9.18198 8.07207 9.36937 7.88468 9.36937 7.63964C9.36937 7.39459 9.18198 7.20721 8.93694 7.20721H4.18018ZM11.0991 7.20721C10.9844 7.20721 10.8744 7.25277 10.7933 7.33386C10.7122 7.41496 10.6667 7.52495 10.6667 7.63964C10.6667 7.75433 10.7122 7.86432 10.7933 7.94542C10.8744 8.02651 10.9844 8.07207 11.0991 8.07207C11.2138 8.07207 11.3238 8.02651 11.4049 7.94542C11.486 7.86432 11.5315 7.75433 11.5315 7.63964C11.5315 7.52495 11.486 7.41496 11.4049 7.33386C11.3238 7.25277 11.2138 7.20721 11.0991 7.20721Z'
  ]
};
