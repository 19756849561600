import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {TranslateService} from '@ngx-translate/core';
import {ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER, StorageKeyEnum} from '../../shared/constants';
import {AbstractNonClientAwareAttachmentDataService} from './abstract-non-client-aware-attachment-data.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {SystemEventService} from '../event/system-event.service';
import {DevModeService} from '../common/dev-mode.service';
import {AttachmentUserEmailSignature, generateFilePathsForAttachmentUserEmailSignature} from 'submodules/baumaster-v2-common';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/attachmentUserEmailSignatures/';

@Injectable({
  providedIn: 'root'
})
export class AttachmentUserEmailSignatureDataService extends AbstractNonClientAwareAttachmentDataService<AttachmentUserEmailSignature> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService,
              loggingService: LoggingService, systemEventService: SystemEventService, protected devModeService: DevModeService,
              integrityResolverService: IntegrityResolverService, protected translateService: TranslateService, protected attachmentSettingService: AttachmentSettingService) {
    super(StorageKeyEnum.ATTACHMENT_USER_EMAIL_SIGNATURE, REST_ENDPOINT_URI, [], http, storage, authenticationService, loggingService, systemEventService, devModeService,
          integrityResolverService, translateService, attachmentSettingService, VERSION_INTRODUCED_DEFAULT, ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER);
  }

  protected setGeneratedFilePath(attachment: AttachmentUserEmailSignature) {
    attachment.filePath = generateFilePathsForAttachmentUserEmailSignature(attachment).filePath;
  }
}
