<app-modal-header>
  <ion-title>{{ ('project_room.manageCadFiles.title') | translate }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <a [href]="downloadObjectUrl | safeUrl" [download]="downloadFilename" class="ion-hide" #downloadLink>download</a>
  <ion-grid class="grid-gap-4">
    <ion-row *ngIf="!allFiles?.length" class="ion-align-items-center">
      <ion-col>
        {{'project_room.manageCadFiles.noFiles' | translate}}
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let file of allFiles" class="ion-align-items-center">
      <ion-col>{{file.name || file.fileName}}</ion-col>
      <ion-col size="2" size-xs="3" size-sm="2" class="ion-justify-content-end">
        <ion-buttons>
          <ion-button class="omg-btn-text"><fa-icon (click)="downloadFile(file)" [icon]="['fal', 'download']"></fa-icon></ion-button>
          <ion-button class="omg-btn-text" (click)="deleteFile(file)" [disabled]="processing">
            <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row>
    <app-file-upload title="{{'project_room.manageCadFiles.dragDropTitle' | translate}}" [acceptedMimeTypes]="acceptedMimeTypes" [showFilesList]="false" [processingFiles]="processingUploadedFile" [files]="files" (filesChange)="filesChange($event)" >
    </app-file-upload>
  </ion-grid>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">
      {{ 'button.cancel' | translate }}
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="save()" [disabled]="processing || !isDirty()">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="processing"></ion-spinner>
      <span>{{ 'button.save' | translate }}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
