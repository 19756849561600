<app-modal-header>
  <ion-title>{{ 'LOGIN.superuser.title' | translate }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <p class="padding-left-10 padding-right-10">
    {{ 'LOGIN.superuser.body' | translate }}
  </p>
  <ion-list class="omg-form">
    <ion-item>
      <ion-input label="{{ 'LOGIN.email' | translate }}" labelPlacement="floating"
        type="email"
        [(ngModel)]="email"
        required
        #usernameInput
        enterkeyhint="next"
        (keyup.enter)="submitForm()"
      >
        <div slot="start"><ion-icon name="mail-open" aria-hidden="true"></ion-icon></div>
      </ion-input>
    </ion-item>
    <p class="error-message" *ngIf="lastError">
      {{lastError|translate}}
    </p>
  </ion-list>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismiss()">{{ 'MENU.logout' | translate }}</ion-button>
    <ion-button class="omg-btn-primary" (click)="submitForm()">{{ 'LOGIN.login_btn' | translate }}</ion-button>
  </ion-buttons>
</app-modal-footer>
