<app-modal-header>
  <ion-title>{{ 'companyOrder.manage.title' | translate }}</ion-title>
</app-modal-header>
<ion-content class="with-padding">
  <ng-container *ngIf="(companiesWithProject$ | async) as companiesWithProject">
    <app-company-order *ngIf="companiesWithProject.companies" [companies]="companiesWithProject.companies"
      [projectCompanyByCompanyId]="companiesWithProject.projectCompanyByCompanyId" (projectCompaniesChange)="orderChanged($event)">
    </app-company-order>
  </ng-container>
</ion-content>
<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">
      {{ 'cancel' | translate }}
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="save()" [disabled]="!(pendingProjectCompaniesSubject | async)">
      {{ 'button.save' | translate }}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
