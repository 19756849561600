<ion-grid>
  <ion-row>
    <ion-item lines="none" class="protocol-entry-chats-label no-flex">
      <ion-label color="primary">{{'Comments' | translate}}</ion-label>
      <ion-badge class="ion-margin-start" color="primary">{{chatData.length}}</ion-badge>
    </ion-item>

    <ion-buttons>
      <ion-button ph-custom-id="add-comment-plus-btn" class="omg-btn-text" (click)="openCreationWindow()">
        <fa-icon size="lg" [icon]="['fal', 'plus-circle']"></fa-icon>
      </ion-button>
    </ion-buttons>

  </ion-row>

  <ion-row *ngFor="let chat of chatData" class="ion-padding-top">
    <app-protocol-entry-chat (attachmentDeleted)="onAttachmentDeleted($event.attachment)"
                             (markingsChanged)="onMarkingsChanged($event.attachment, $event.markings)"
                             [acrossProjects]="acrossProjects"
                             [chat]="chat"
                             [isCarriedEntry]="isCarriedEntry"
                             [protocolEntry]="protocolEntry"
                             [protocol]="protocol"
                             class="full-width"></app-protocol-entry-chat>
  </ion-row>
</ion-grid>
