import {Injectable} from '@angular/core';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {IdType, LicenseType, PdfPreview, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {combineLatest, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import _ from 'lodash';
import {FeatureEnabledService} from '../feature/feature-enabled.service';
import {UserService} from '../user/user.service';
import {ParticipantDataService} from './participant-data.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/pdfPreviews';

@Injectable({
  providedIn: 'root'
})
export class PdfPreviewDataService extends AbstractProjectAwareDataService<PdfPreview>{

  constructor(http: HttpClient, storage: StorageService,
              authenticationService: AuthenticationService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService, private featureEnabledService: FeatureEnabledService,
              private participantDataService: ParticipantDataService,
              protected userService: UserService) {
    super(StorageKeyEnum.PDF_PREVIEW, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

  public getByProtocolIdSortedByIndexNumber(protocolId: IdType): Observable<Array<PdfPreview>> {
    return this.getByReportIdOrProtocolIdSortedByIndexNumber(protocolId, null);
  }

  public getByReportIdSortedByIndexNumber(reportId: IdType): Observable<Array<PdfPreview>> {
    return this.getByReportIdOrProtocolIdSortedByIndexNumber(null, reportId);
  }

  private getByReportIdOrProtocolIdSortedByIndexNumber(protocolId: IdType, reportId: IdType): Observable<Array<PdfPreview>> {
    if (!protocolId && !reportId) {
      throw new Error('protocolId and reportId can not be empty at the same time.');
    }
    return combineLatest([
      this.data,
      this.userService.currentUser$.pipe(
        switchMap((user) =>  this.participantDataService.getOwnParticipants(user.profileId))
      ),
      this.featureEnabledService.isFeatureEnabled$(true, null, [LicenseType.VIEWER]),
      this.userService.isCurrentUserConnected$,
      this.userService.hasCurrentUserReportRights$
    ]).pipe(map(([pdfPreviews, participants, isViewer, isCurrentUserConnected, hasCurrentUserReportRights]) => {
      if (protocolId) {
        if (isViewer || isCurrentUserConnected) {
          const pdfpreviewIds = participants.map((participant) => participant.pdfpreviewId);
          return _.sortBy(pdfPreviews.filter((pdfPreview) => pdfPreview.protocolId === protocolId && pdfpreviewIds.includes(pdfPreview.id)), 'indexNumber');
        } else {
          return _.sortBy(pdfPreviews.filter((pdfPreview) => pdfPreview.protocolId === protocolId), 'indexNumber');
        }
      } else if (reportId) {
        if ((isViewer && !hasCurrentUserReportRights) || isCurrentUserConnected) {
          const pdfpreviewIds = participants.map((participant) => participant.pdfpreviewId);
          return _.sortBy(pdfPreviews.filter((pdfPreview) => pdfPreview.reportId === reportId && pdfpreviewIds.includes(pdfPreview.id)), 'indexNumber');
        } else {
          return _.sortBy(pdfPreviews.filter((pdfPreview) => pdfPreview.reportId === reportId), 'indexNumber');
        }
      }
    }));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
