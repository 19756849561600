import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AlertController, NavParams} from '@ionic/angular';
import {Attachment, IdType} from 'submodules/baumaster-v2-common';
import {LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY} from '../../../shared/constants';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-project-room-attachments-selector',
  templateUrl: './project-room-attachments-selector.component.html',
  styleUrls: ['./project-room-attachments-selector.component.scss'],
})
export class ProjectRoomAttachmentsSelectorComponent implements OnInit{

  private modal: HTMLIonModalElement;
  public viewportWidth: number|undefined;
  public selectedAttachments = new Array<Attachment>();
  public maxSelectionLimit: number;
  public projectId: IdType|null = null;

  @ViewChild
  ('attachmentContainer', {static: false}) attachmentContainer: ElementRef<HTMLDivElement>;

  constructor(private navParams: NavParams, private alertController: AlertController, private translateService: TranslateService) { }

  async ngOnInit() {
    this.maxSelectionLimit = this.navParams.data.maxSelectionLimit || LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY;
    this.setCanDismiss();
  }

  async ionViewDidEnter() {
    this.viewportWidth = this.attachmentContainer?.nativeElement?.clientWidth;
  }

  public onSelectionChanged(attachments: Array<Attachment>) {
    this.selectedAttachments = attachments;
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (role === 'select') {
      return true;
    }

    if (!this.selectedAttachments.length) {
      return true;
    }

    const alert = await this.alertController.create({
      message: this.translateService.instant('modal.cancel.confirm'),
      buttons: [{
        text: this.translateService.instant('no'),
        role: 'no',
        cssClass: 'secondary',
      }, {
        text: this.translateService.instant('yes'),
        role: 'yes',
      }]
    });
    await alert.present();

    return (await alert.onWillDismiss()).role === 'yes';
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  public async select() {
    await this.modal.dismiss({
      attachments: this.selectedAttachments
    }, 'select');
  }

  public cancel() {
    return this.modal.dismiss();
  }
}
