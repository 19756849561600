<ion-toolbar>
  <ion-title>
    <span>
      {{title || selectable.label}}
    </span>
  </ion-title>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text close-button" (click)="selectable._close()">
      <fa-icon [icon]="['fal', 'times']"></fa-icon>
    </ion-button>
  </ion-buttons>
  <ng-content></ng-content>
</ion-toolbar>
