import type {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, ObservableInput, ObservedValueOf, of, OperatorFunction, Subscription} from 'rxjs';
import {debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {AuthTokenWithExpireAt} from '../model/auth';
import {Nullish} from '../model/nullish';
import {NetworkError, TimeoutError} from '../shared/errors';
import {TIMEOUT_ERROR} from './fetch-utils';
import {observableToPromise} from './observable-to-promise';
import {getObjectNameWithDeletedSuffix, getObjectWithDeletedSuffix} from './text-utils';

const DEFAULT_TIMEOUT_IN_MS = 2 * 60 * 1000;

export const waitAndResolve = <T>(fn: () => T|Promise<T>, timeout: number) => new Promise<T>((res) => setTimeout(() => res(fn()), timeout));

function startWorker(worker: Worker, message: any): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      worker.onmessage = ({data}) => {
        worker.terminate();
        if (data.success) {
          resolve(data.data);
        } else {
          if (data.networkError) {
            reject(new NetworkError(data.error?.message || data.message || data.error));
          } else {
            reject(data.error?.message || data.message || data.error);
          }
        }
      };
      worker.onerror = (error) => {
        worker.terminate();
        reject(error);
      };
      worker.postMessage(message);
    } catch (e) {
      reject(e);
    }
  });
}

export function startWorkerWithAuthToken(worker: Worker, authObservable: Observable<AuthTokenWithExpireAt>, onInvalidToken: () => void, message: any): Promise<any> {
  let sub: Subscription|undefined;
  return new Promise((resolve, reject) => {
    try {
      worker.onmessage = ({data}) => {
        if (data.invalidToken) {
          onInvalidToken();
          return;
        }
        worker.terminate();
        if (data.success) {
          sub?.unsubscribe();
          resolve(data.data);
        } else {
          if (data.networkError) {
            sub?.unsubscribe();
            reject(new NetworkError(data.error?.message || data.message || data.error));
          } else {
            sub?.unsubscribe();
            reject(data.error?.message || data.message || data.error);
          }
        }
      };
      worker.onerror = (error) => {
        worker.terminate();
        sub?.unsubscribe();
        reject(error);
      };
      worker.postMessage({message, type: 'message'});
      sub = authObservable.pipe(tap((token) => {
        if (!token) {
          sub?.unsubscribe();
          const error = new Error('token is not defined');
          worker.terminate();
          reject(error);
          throw error;
        }
      })).subscribe((token) => {
        worker.postMessage({token, type: 'token'});
      });
    } catch (e) {
      sub?.unsubscribe();
      reject(e);
    }
  });
}

export function combineLatestAsync<O1 extends ObservableInput<any>>(sources: [O1]): Observable<[ObservedValueOf<O1>]>;
export function combineLatestAsync<O1 extends ObservableInput<any>, O2 extends ObservableInput<any>>(sources: [O1, O2]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>]>;
export function combineLatestAsync<
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>
>(sources: [O1, O2, O3]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>]>;
export function combineLatestAsync<
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>
>(sources: [O1, O2, O3, O4]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>]>;
export function combineLatestAsync<
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>
>(sources: [O1, O2, O3, O4, O5]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>]>;
export function combineLatestAsync<
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
  O6 extends ObservableInput<any>
>(sources: [O1, O2, O3, O4, O5, O6]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>, ObservedValueOf<O6>]>;
export function combineLatestAsync<
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
  O6 extends ObservableInput<any>,
  O7 extends ObservableInput<any>
>(sources: [O1, O2, O3, O4, O5, O6, O7]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>, ObservedValueOf<O6>,
  ObservedValueOf<O7>]>;
export function combineLatestAsync<
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
  O6 extends ObservableInput<any>,
  O7 extends ObservableInput<any>,
  O8 extends ObservableInput<any>
>(sources: [O1, O2, O3, O4, O5, O6, O7, O8]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>, ObservedValueOf<O6>,
  ObservedValueOf<O7>, ObservedValueOf<O8>]>;
export function combineLatestAsync<
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
  O6 extends ObservableInput<any>,
  O7 extends ObservableInput<any>,
  O8 extends ObservableInput<any>,
  O9 extends ObservableInput<any>
>(sources: [O1, O2, O3, O4, O5, O6, O7, O8, O9]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>, ObservedValueOf<O6>,
  ObservedValueOf<O7>, ObservedValueOf<O8>, ObservedValueOf<O9>]>;
export function combineLatestAsync<
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
  O6 extends ObservableInput<any>,
  O7 extends ObservableInput<any>,
  O8 extends ObservableInput<any>,
  O9 extends ObservableInput<any>,
  O10 extends ObservableInput<any>
>(sources: [O1, O2, O3, O4, O5, O6, O7, O8, O9, O10]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>, ObservedValueOf<O6>,
  ObservedValueOf<O7>, ObservedValueOf<O8>, ObservedValueOf<O9>, ObservedValueOf<O10>]>;
export function combineLatestAsync<O extends ObservableInput<any>>(sources: O[]): Observable<ObservedValueOf<O>[]>;
export function combineLatestAsync<O extends ObservableInput<any>, R>(sources: any[]): Observable<R> | Observable<ObservedValueOf<O>[]> {
  return combineLatest(sources).pipe(debounceTime(0)) as Observable<R>;
}

export async function resolveWithMinimumTimeout<T>(promise: Promise<T>, timeout: number): Promise<T> {
  return await Promise.all([
    promise,
    new Promise((res) => setTimeout(res, timeout))
  ]).then(([value]) => value);
}

export async function resolveWithTimeout<T>(promise: Promise<T>, timeout = DEFAULT_TIMEOUT_IN_MS, abortController?: AbortController): Promise<T> {
  let signal: AbortSignal;
  if (abortController) {
    signal = abortController.signal;
    const abortControllerFixed = abortController as (AbortController & {abort(reason?: any): void});
    signal.addEventListener('abort', abortControllerFixed.abort);
  }

  return await Promise.race([
    promise,
    new Promise<T>((_, reject) => setTimeout(
      () => reject(new TimeoutError(TIMEOUT_ERROR)),
      timeout
    )),
  ]);
}

type ParentTypeInArray<T> = T extends Array<infer R> ? Array<R> : T;

export const defaultIfNullish = <Value, Default extends ParentTypeInArray<Value>>(
  defaultValue: Default
) =>
  map<Nullish<Value>, ParentTypeInArray<Value> | Value>(
    (value) => value ?? defaultValue
  );

export const mapObjectNameWithDeletedSuffix = <T extends {isActive: boolean; name: string}>(translateService: TranslateService) =>
  map<T, T>((obj) => getObjectNameWithDeletedSuffix(obj, translateService));

export const mapObjectsNameWithDeletedSuffix = <T extends {isActive: boolean; name: string;}>(translateService: TranslateService) =>
  map<T[], T[]>((objects) => objects?.map((obj) => getObjectNameWithDeletedSuffix(obj, translateService)));

export const mapObjectWithDeletedSuffix = <T extends {isActive: boolean}, TKey extends keyof T>(
  translateService: TranslateService,
  key: TKey,
  getName: (v: T) => string) => map<T, T>((obj) => getObjectWithDeletedSuffix(obj, key, translateService, getName));

export const mapObjectsWithDeletedSuffix = <T extends {isActive: boolean}, TKey extends keyof T>(
  translateService: TranslateService,
  key: TKey,
  getName: (v: T) => string) => map<T[], T[]>((objects) => objects?.map((obj) => getObjectWithDeletedSuffix(obj, key, translateService, getName)));

export const switchMapOrDefault =
  <T, U>(fn: (data: T) => Observable<U>, defaultValue: Nullish<U> = undefined) => switchMap<T, Observable<Nullish<U>>>((data: T) => data ? fn(data) : of(defaultValue));

type KeysMatching<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];


export const filterByObservableObjectProperty = <TSource, SourceKey extends keyof TSource, TFilterBy, FilterKey extends KeysMatching<TFilterBy, TSource[SourceKey]>>
(filterBy$: Observable<TFilterBy>, sourceKey: SourceKey, targetKey: FilterKey): OperatorFunction<TSource[], TSource[]> =>
  switchMap<TSource[], Observable<TSource[]>>((data) => filterBy$.pipe(
    map((filterBy) => data.filter((item) => item?.[sourceKey] as any === filterBy?.[targetKey]))
  ));

export {observableToPromise, startWorker};

