import {ColumnConfig} from '../ColumnConfig';

export const PdfReportGroupColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'project_id', prop: 'projectId'},
  {name: 'year', prop: 'year'},
  {name: 'week_number', prop: 'weekNumber', def: null},
  {name: 'month', prop: 'month', def: null},
  {name: 'pdf_preview_ids', prop: 'pdfPreviewIds'},
  {name: 'report_ids', prop: 'reportIds'},
  {name: 'participant_ids', prop: 'participantIds'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'}
];
