<ion-button mode="md" class="omg-btn-secondary" *ngFor="let btn of buttons; trackBy trackByValue; let i = index"
  [class.omg-btn-color-selected]="isSelected[btn.value]" [style.opacity]="isSelected[btn.value] ? undefined : '0.6'" (click)="buttonClick(btn)">
  <div class="d-flex ion-align-items-center ion-justify-content-between button-content gap-1">
    <div class="d-flex ion-align-items-center gap-1">
      <fa-icon class="button-icon" *ngIf="btn.icon" [icon]="btn.icon"
        [ngClass]="btn.iconClasses"></fa-icon>
      <span class="text-ellipsis">{{ btn.label }}</span>
    </div>
    <fa-icon class="button-icon" *ngIf="btn.withClearIcon && isSelected[btn.value]" [icon]="['fal', 'times']"></fa-icon>
  </div>
</ion-button>
