<button
  type="button"
  class="row"
  [class.ion-activatable]="!disabled"
  [appLayout]="1.5"
  appLayoutAlignItems="center"
  (click)="!disabled && holderClick.emit($event)"
>
  <div class="cell cell__auto" [appLayout]="0.5">
    <div class="checkbox" [class.ion-activatable]="!hideCheckbox" (click)="$event.stopPropagation(); selectedChange.emit(!selected)">
      <ion-checkbox class="omg-checkbox" disabled [checked]="selected" [hidden]="hideCheckbox"></ion-checkbox>
    </div>
    <div class="name-box" [class.ion-activatable]="!!nameTemplate" (click)="!!nameTemplate && $event.stopPropagation()">
      <ng-template #defaultNameTemplate>
        <span class="plan-name" appEllipsisTooltip>{{ pdfPlanVersion?.name }}</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="(nameTemplate || defaultNameTemplate); context: {holder: pdfPlanHolder}"></ng-container>
      <ion-text color="text-secondary" class="filename d-flex">
        <span class="pdf-filename flex-shrink-1" appEllipsisTooltip>{{ pdfPlanVersion?.pdfPlanAttachment?.fileName }}</span>
        <span *ngIf="pdfPlanVersion?.pdfPlanCadAttachments?.length" class="badge" id="cad-attachment-{{pdfPlanVersion.id}}">
          <fa-icon [icon]="['fal', 'drafting-compass']"></fa-icon>
          {{'project_room.pdf_plan_holders.cadFiles' | translate: {files: pdfPlanVersion.pdfPlanCadAttachments.length} }}
        </span>
        <app-tooltip *ngIf="pdfPlanVersion?.pdfPlanCadAttachments?.length" target="cad-attachment-{{pdfPlanVersion.id}}" placement="right">
          <div *ngFor="let cadAttachment of pdfPlanVersion.pdfPlanCadAttachments">
            {{cadAttachment.fileName}}
          </div>
        </app-tooltip>
      </ion-text>

    </div>
  </div>
  <div [class.ion-hide-md-up]="!highlightAsNew()" [style.visibility]="!highlightAsNew() ? 'hidden' : undefined" class="cell cell__newHighlight">
    {{ 'project_room.pdf_plan_holders.newTag' | translate }}
  </div>
  <div class="cell cell__index">
    <ng-template #defaultIndexTemplate>{{ pdfPlanVersion?.index }}</ng-template>
    <ng-container *ngTemplateOutlet="(indexTemplate || defaultIndexTemplate); context: {holder: pdfPlanHolder}"></ng-container>
  </div>
  <div class="cell cell__scale ion-hide-xl-down">
    <ng-template #defaultScaleTemplate>{{ pdfPlanVersion?.scale }}</ng-template>
    <ng-container *ngTemplateOutlet="(scaleTemplate || defaultScaleTemplate); context: {holder: pdfPlanHolder}"></ng-container>
  </div>
  <div class="cell cell__date">
    <ng-template #defaultDateTemplate>{{ pdfPlanVersion?.date | date:DATE_FORMAT }}</ng-template>
    <ng-container *ngTemplateOutlet="(dateTemplate || defaultDateTemplate); context: {holder: pdfPlanHolder}"></ng-container>
  </div>
  <div class="cell cell__tags ion-activatable" (click)="$event.stopPropagation()">
    <ng-template #defaultTagsTemplate>
      <app-tag-list
        appAllTagsPopover
        appUseTagsClient
        #useTagsClient="appUseTagsClient"
        (click)="analytics.planTagsClick(pdfPlanVersion?.id, 'details')"
        [objectId]="pdfPlanVersion?.id"
        [objectType]="'pdfPlanVersions'"
        [maxTags]="1"
        [showMoreTagsCount]="true"
      ></app-tag-list>
      <div class="cell__no-tags">
        <ion-text color="text-secondary" *ngIf="!((useTagsClient.tags$ | async)?.length)">{{ 'project_room.pdf_plan_holders.noTags' | translate }}</ion-text>
      </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="(tagsTemplate || defaultTagsTemplate); context: {holder: pdfPlanHolder}"></ng-container>
  </div>
  <div class="cell cell__location">
    <ng-template #defaultLocationTemplate>{{ ((locationById$ | async) || {})[pdfPlanVersion?.locationId]?.location }}</ng-template>
    <ng-container *ngTemplateOutlet="(locationTemplate || defaultLocationTemplate); context: {holder: pdfPlanHolder}"></ng-container>
  </div>
  <ion-text [color]="pdfPlanVersion?.description ? 'primary' : 'inactive'" class="cell cell--flex cell__icon cell__comment ion-activatable" (click)="$event.stopPropagation()">
    <ng-template #defaultCommentTemplate><fa-icon appPdfPlanCommentPopover [comment]="pdfPlanVersion?.description" [icon]="['fal', 'comment-alt-lines']"></fa-icon></ng-template>
    <ng-container *ngTemplateOutlet="(commentTemplate || defaultCommentTemplate); context: {holder: pdfPlanHolder}"></ng-container>
  </ion-text>
  <ion-text [color]="pdfPlanVersion?.hasGeneralMarkings ? 'primary' : 'inactive'" class="cell cell--flex cell__icon cell__markings">
    <fa-icon [icon]="['fal', 'signature']"></fa-icon>
  </ion-text>
  <div class="cell cell--flex cell__marker">
    <app-chip-with-label size="small" [chip]="markersCount" color="neutral-light-badge">
    </app-chip-with-label>
  </div>
  <div [style.visibility]="hideActive ? 'hidden' : undefined" class="cell cell--flex cell__active ion-activatable" (click)="$event.stopPropagation()">
    <ion-toggle [disabled]="activeToggleDisabled || disabled" appSlimToggle [ngModel]="pdfPlanHolder.active" (ngModelChange)="activeChange.emit($event)"></ion-toggle>
  </div>
  <div class="cell cell__actions omg-boundary" (click)="$event.stopPropagation()">
    <ion-button [style.visibility]="hideActions ? 'hidden' : undefined" [disabled]="disabled" class="omg-btn-text" (click)="analytics.planActionsClicked(pdfPlanHolder.id); actionsClick.emit($event)">
      <fa-icon class="three-dots" [icon]="['far', 'ellipsis-h']"></fa-icon>
    </ion-button>
    <ion-button [style.visibility]="hideExpand ? 'hidden' : undefined" [disabled]="disabled" class="omg-btn-text" (click)="expandClick.emit($event)">
      <fa-icon class="chevron" [icon]="expanded ? ['fal', 'chevron-up'] : ['fal', 'chevron-down']"></fa-icon>
    </ion-button>
  </div>
  <ion-ripple-effect *ngIf="!disabled"></ion-ripple-effect>
</button>
