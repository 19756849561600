import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {orderBy} from 'lodash';
import {STORAGE_KEY_PROJECT_SEPARATOR, StorageKeyEnum} from 'src/app/shared/constants';
import {IdType, Protocol} from 'submodules/baumaster-v2-common';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {LastUsedProtocol} from 'src/app/model/last-used-protocol';
import {StorageService} from '../storage.service';
import {isTaskProtocol} from 'src/app/utils/protocol-utils';

const STORAGE_KEY = StorageKeyEnum.LAST_USED_PROTOCOL;
const MAX_LAST_USED_PROTOCOLS_PER_PROJECT = 10;

/**
 * lastUsedProtocols is expected to be sorted
 */
const joinLastProtocols = (
  lastUsedProtocols: LastUsedProtocol[],
  protocols: Protocol[]
): Protocol[] => orderBy(
  protocols.filter((protocol) => !isTaskProtocol(protocol) && lastUsedProtocols.some(({ protocolId }) => protocolId === protocol.id)),
  [(protocol) => lastUsedProtocols.findIndex(({ protocolId }) => protocolId === protocol.id)]
);

@Injectable({
  providedIn: 'root',
})
export class LastUsedProtocolService {
  private readonly defaultValue = [];

  private readonly lastProtocolsByProjectIdSubject = new BehaviorSubject<Map<IdType, Array<LastUsedProtocol>>>(new Map());
  public readonly dataByProjectId$: Observable<Map<IdType, Array<Protocol>>> = this.lastProtocolsByProjectIdSubject.pipe(
    switchMap((lastProtocolsMap) =>
      this.protocolDataService.dataWithoutHiddenByProjectId$.pipe(
        map(
          (protocolsMap) =>
            new Map(Array.from(lastProtocolsMap.entries()).map(([projectId, lastProtocols]) => [
              projectId,
              joinLastProtocols(
                lastProtocols,
                protocolsMap.get(projectId) || []
              ),
            ]))
        )
      )
    )
  );

  public readonly dataAcrossProjects$: Observable<Protocol[]> = this.lastProtocolsByProjectIdSubject.pipe(
    switchMap((lastProtocolsMap) => {
      const allLastProtocols: LastUsedProtocol[] = orderBy(
        Array.from(lastProtocolsMap.values()).reduce((acc, lastProtocols) => acc.concat(lastProtocols), []),
        ['visitedAt'], ['desc']
      );

      return this.protocolDataService.dataWithoutHiddenByProjectId$.pipe(
        map((protocolsMap) =>
          joinLastProtocols(
            allLastProtocols,
            Array.from(protocolsMap.values())
              .reduce((acc, protocols) => acc.concat(protocols), [])
              .filter((protocol) => allLastProtocols.some(({ protocolId }) => protocolId === protocol.id))
          )
        )
      );
    })
  );

  public readonly data: Observable<Array<Protocol>> = this.projectDataService.currentProjectObservable.pipe(
    switchMap((currentProject) =>
      this.dataByProjectId$.pipe(
        map(
          (dataByProjectId) =>
            (currentProject && dataByProjectId.get(currentProject.id)) || this.defaultValue
        )
      )
    )
  );

  constructor(private storage: StorageService,
              private authenticationService: AuthenticationService,
              private projectDataService: ProjectDataService,
              private protocolDataService: ProtocolDataService) {
    this.authenticationService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      if (isAuthenticated) {
        this.lastProtocolsByProjectIdSubject.next(
          await this.getProtocolIdsByProjectIdFromStorage()
        );
      } else {
        await this.removeAllStorageData();
        this.clearDataSubjectByProjectId();
      }
    });
  }

  private async getProtocolIdsByProjectIdFromStorage() {
    const storageKeysToGet = (await this.storage.keys()).filter((key) =>
      this.isStorageKey(key)
    );

    const dataByProjectId = new Map<IdType, LastUsedProtocol[]>();

    for (const key of storageKeysToGet) {
      const data: LastUsedProtocol[] = await this.storage.get(key);
      const projectId = this.extractProjectIdFromStorageKey(key);

      dataByProjectId.set(projectId, data);
    }

    return dataByProjectId;
  }

  private clearDataSubjectByProjectId() {
    this.lastProtocolsByProjectIdSubject.next(new Map());
  }

  private getStorageKey(projectId: IdType): string {
    return STORAGE_KEY + STORAGE_KEY_PROJECT_SEPARATOR + projectId;
  }

  private isStorageKey(key: string) {
    return key.startsWith(STORAGE_KEY + STORAGE_KEY_PROJECT_SEPARATOR);
  }

  private extractProjectIdFromStorageKey(key: string) {
    const index = key.lastIndexOf(STORAGE_KEY_PROJECT_SEPARATOR);
    if (index === -1) {
      throw new Error(
        `StorageKey "${key}" is supposed to have a suffix with a projectId but no "_" was found.`
      );
    }
    return key.substring(index + 1);
  }

  private async removeAllStorageData() {
    const storageKeysToDelete = (await this.storage.keys()).filter((key) =>
      this.isStorageKey(key)
    );

    for (const key of storageKeysToDelete) {
      await this.storage.remove(key);
    }
  }

  async touchProtocol(protocol: Protocol, projectId?: never);
  async touchProtocol(protocolId: IdType, projectId: IdType);
  async touchProtocol(
    protocolOrId: Protocol | IdType,
    projectIdOrUndef: IdType | never
  ) {
    let protocolId: IdType;
    let projectId: IdType;

    if (typeof protocolOrId === 'string') {
      protocolId = protocolOrId;
      projectId = projectIdOrUndef as IdType;
    } else {
      protocolId = protocolOrId?.id;
      projectId = protocolOrId?.projectId;
    }

    if (!protocolId || !projectId) {
      throw new Error(
        `Touching protocol failed due to missing protocol id (${protocolId}) or project id (${projectId})`
      );
    }

    const touchedProtocols =
      this.lastProtocolsByProjectIdSubject.getValue().get(projectId) || [];

    const newTouchedProtocols = touchedProtocols
      .filter((touchedProtocol) => touchedProtocol.protocolId !== protocolId)
      .filter((_, index) => index < MAX_LAST_USED_PROTOCOLS_PER_PROJECT - 1);

    newTouchedProtocols.unshift({
      protocolId,
      visitedAt: new Date().toISOString(),
    });

    await this.storage.set(this.getStorageKey(projectId), newTouchedProtocols);

    this.lastProtocolsByProjectIdSubject
      .getValue()
      .set(projectId, newTouchedProtocols);
    this.lastProtocolsByProjectIdSubject.next(
      this.lastProtocolsByProjectIdSubject.getValue()
    );
  }
}
