import {Protocol, ProtocolEntry, ProtocolEntryStatus, ProtocolEntryType, ProtocolType} from 'submodules/baumaster-v2-common';
import {Nullish} from '../model/nullish';
import {memoizedFormatNumber} from './number-utils';
import {isTaskProtocol} from './protocol-utils';

export const protocolShortIdWithDepsAndValues = (
    locale: string,
    protocolTypeCode: ProtocolType['code'],
    protocolNumber: Protocol['number'],
) => `${
    protocolTypeCode
}${
    memoizedFormatNumber(protocolNumber, locale, '2.0')
}`;

export const protocolShortIdWithDeps = (
    locale: string,
    protocolType: ProtocolType,
    protocol: Protocol,
) => protocolShortIdWithDepsAndValues(locale, protocolType?.code || '', protocol?.number);

export const entryShortIdWithDepsAndValuesAndProtocolShortId = (
    locale: string,
    protocolShortIdValue: string,
    entryNumber: ProtocolEntry['number'],
    parentEntryNumber?: ProtocolEntry['number'],
) => {
    const parts = [protocolShortIdValue];

    if (typeof parentEntryNumber === 'number') {
        parts.push(memoizedFormatNumber(parentEntryNumber, locale, '3.0'));
    }
    parts.push(memoizedFormatNumber(entryNumber, locale, '3.0'));

    return parts.join('.');
};

export const entryShortIdWithDepsAndValues = (
    locale: string,
    protocolTypeCode: ProtocolType['code'],
    protocolNumber: Protocol['number'],
    entryNumber: ProtocolEntry['number'],
    parentEntryNumber?: ProtocolEntry['number'],
) => {
    const parts = [protocolShortIdWithDepsAndValues(locale, protocolTypeCode, protocolNumber)];

    if (typeof parentEntryNumber === 'number') {
        parts.push(memoizedFormatNumber(parentEntryNumber, locale, '3.0'));
    }
    parts.push(memoizedFormatNumber(entryNumber, locale, '3.0'));

    return parts.join('.');
};

export const entryShortIdWithDeps = (
    locale: string,
    protocolType: ProtocolType,
    protocol: Protocol,
    entry: ProtocolEntry,
    parentEntry?: ProtocolEntry,
) => {
    const parts = [protocolShortIdWithDeps(locale, protocolType, protocol)];

    if (parentEntry) {
        parts.push(memoizedFormatNumber(parentEntry?.number, locale, '3.0'));
    }
    parts.push(memoizedFormatNumber(entry?.number, locale, '3.0'));

    return parts.join('.');
};

export const protocolShortId = (
    locale: string,
    protocolTypesById: Record<string, ProtocolType>,
    protocol: Protocol,
) => protocolShortIdWithDeps(locale, protocolTypesById[protocol?.typeId], protocol);

export const entryShortId = (
    locale: string,
    protocolTypesById: Record<string, ProtocolType>,
    protocol: Protocol,
    entry: ProtocolEntry,
    parentEntry?: ProtocolEntry,
) => entryShortIdWithDeps(
    locale,
    protocolTypesById[protocol?.typeId],
    protocol,
    entry,
    parentEntry
);

export function taskShortId(taskNumber: number, parentTaskNumber?: number): string {
  const taskNumberAsString = taskNumber.toString().padStart(3, '0');
  if (parentTaskNumber === undefined) {
      return taskNumberAsString;
  }
  return parentTaskNumber.toString().padStart(3, '0') + '.' + taskNumberAsString;
}

export const costStringToNumber = (cost: Nullish<string | number>): Nullish<number> => {
    if (cost === null || cost === undefined) {
        return cost as Nullish<number>;
    }

    const costStr = `${cost}`;
    const costInParts = costStr.split(/[,.]/g);
    let decimalPart = '00';
    if (costInParts[costInParts.length - 1].length <= 2 && costInParts.length > 1) {
        decimalPart = costInParts.pop();
    }

    return +`${costInParts.join('')}.${decimalPart}`;
};

export const getColorName = (protocolEntry: Nullish<ProtocolEntry>, statusFieldActive: boolean, isProtocolLayoutShort: boolean) => {
    if (statusFieldActive === false && isProtocolLayoutShort !== true) {
        return 'primary';
    }

    const status = protocolEntry?.status;

    if (status === ProtocolEntryStatus.OPEN) {
        return 'danger';
    }

    if (status === ProtocolEntryStatus.COMPANY_DONE) {
        return 'warning';
    }

    if (status === ProtocolEntryStatus.DONE) {
        return 'success';
    }

    return 'primary';
};

export const isEntryTypeTask = (entryType: ProtocolEntryType) => entryType.taskDefault === true;

export function getProtocolEntryShortId(protocol: Protocol|undefined, protocolType: ProtocolType|undefined, protocolEntryNumber: number, locale: string): string {
    let protocolShortId: string;
    let formatNumber: string|undefined;
    if (!protocol || !protocolType || isTaskProtocol(protocol)) {
        protocolShortId = 'Nr. ';
    } else {
        protocolShortId = protocolShortIdWithDeps(locale, protocolType, protocol) + '.';
        formatNumber = '3.0';
    }
    return protocolShortId + (formatNumber ?  memoizedFormatNumber(protocolEntryNumber, locale, formatNumber) : protocolEntryNumber);
}
