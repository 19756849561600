import {Component} from '@angular/core';
import {debounceTime} from 'rxjs';
import {MenuService} from 'src/app/services/common/menu.service';
import {SideBarSettingsService} from 'src/app/services/sidebar/sidebar-settings.service';
import {IonMenuService} from 'src/app/services/ui/ion-menu.service';
import {observableToPromise} from 'src/app/utils/observable-to-promise';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent {

  sideBarSetting$ = this.sideBarSettingsService.sideBarSetting$;
  isHovered: boolean = false;
  isMenuExpandedOnScreen$ = this.menuService.isMenuExpandedOnScreen$.pipe(debounceTime(0));
  isDesktop = this.menuService.isDesktop;

  constructor(private ionMenuService: IonMenuService,
              private sideBarSettingsService: SideBarSettingsService,
              private menuService: MenuService
            ) { }

  hoverIcon(hover: boolean) {
    this.isHovered = hover;
  }

  async openMenu() {
    if (!(await observableToPromise(this.isMenuExpandedOnScreen$))) {
      await this.ionMenuService.ionMenu?.open();
    } 
    if (this.isDesktop) {
      this.sideBarSettingsService.toggleCurrentSideBarSetting();
    }
  }

}
