import { Component, forwardRef } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Nullish} from 'src/app/model/nullish';
import {LoggingService} from 'src/app/services/common/logging.service';

export const ICON_SPLIT_CHARACTER = '|';

const LOG_SOURCE = 'PdfPlanFolderIconPickerComponent';
@Component({
  selector: 'app-pdf-plan-folder-icon-picker',
  templateUrl: './pdf-plan-folder-icon-picker.component.html',
  styleUrls: ['./pdf-plan-folder-icon-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PdfPlanFolderIconPickerComponent),
    multi: true,
  }]
})
export class PdfPlanFolderIconPickerComponent implements ControlValueAccessor {

  readonly icons = [
    ['fal', 'columns'],
    ['fal', 'ruler-triangle'],
    ['fal', 'table'],
    ['fal', 'house'],
    ['fal', 'bolt'],
    ['fal', 'bath'],
    ['fal', 'rectangle-wide'],
    ['fal', 'window-frame'],
    ['fal', 'cube'],
    ['fal', 'home'],
    ['fal6', 'chart-gantt'],
    ['fal', 'industry'],
    ['fal', 'swatchbook'],
    ['fal', 'ruler-combined'],
    ['fal', 'folder'],
  ] as const;

  value: Nullish<string>;
  iconValue: Nullish<[string, string]>;

  private propagateOnChange = (_: any) => { };
  private propagateOnTouched = () => { };

  constructor(private loggingService: LoggingService) { }

  writeValue(value: any): void {
    const hasSplitCharacter = typeof value === 'string' ? value.includes(ICON_SPLIT_CHARACTER) : false;
    const normalizedValue = !value || !hasSplitCharacter ? undefined : value;
    if (value && !hasSplitCharacter) {
      this.loggingService.warn(LOG_SOURCE, `Value "${value}" does not have "${ICON_SPLIT_CHARACTER}" in it; using undefined as a value`);
    }
    this.value = normalizedValue;
    this.iconValue = normalizedValue?.split(ICON_SPLIT_CHARACTER);
  }
  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  changeIcon(icon: readonly [string, string]) {
    this.iconValue = [...icon];
    this.value = `${icon[0]}${ICON_SPLIT_CHARACTER}${icon[1]}`;
    this.propagateOnChange(this.value);
    this.propagateOnTouched();
  }
}
