import {CommonModule} from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {UserService} from 'src/app/services/user/user.service';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {observableToPromise} from 'src/app/utils/async-utils';
import {TextTemplate} from 'submodules/baumaster-v2-common';
import {TextTemplateEditFormComponent} from '../text-template-edit-form/text-template-edit-form.component';
import {v4} from 'uuid';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-text-template-edit-modal',
  templateUrl: './text-template-edit-modal.component.html',
  styleUrls: ['./text-template-edit-modal.component.scss'],
  standalone: true,
  imports: [
    TextTemplateEditFormComponent,
    UiModule,
    IonicModule,
    TranslateModule,
    CommonModule,
    FontAwesomeModule
  ]
})
export class TextTemplateEditModalComponent implements OnInit {

  private modal: HTMLIonModalElement;

  @ViewChild('form', {read: TextTemplateEditFormComponent})
  formComponent: TextTemplateEditFormComponent;

  @Input()
  category: string;

  @Input()
  textTemplate?: TextTemplate;

  @Input()
  prefillTextTemplate?: Partial<TextTemplate>;

  @Input()
  onApply?: (textTemplate: TextTemplate) => Promise<TextTemplate>;

  @Input()
  onDelete?: (textTemplate: TextTemplate) => Promise<boolean>;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {}

  async getEmptyTextTemplate(): Promise<TextTemplate> {
    const user = await observableToPromise(this.userService.currentUser$);
    if (!user) {
      throw new Error('TextTemplateEditModalComponent.getEmptyTextTemplate: current user is not defined');
    }

    const changedAt = new Date().toISOString();

    return {
      id: v4(),
      name: '',
      category: this.category,
      changedAt,
      createdAt: changedAt,
      createdById: user.id,
      textTemplate: ''
    };
  }

  async apply() {
    const combinedTextTemplate = {
      ...(this.textTemplate ?? await this.getEmptyTextTemplate()),
      ...this.formComponent.form.getRawValue()
    };
    const newTextTemplate = await this.onApply?.(combinedTextTemplate) ?? combinedTextTemplate;
    this.modal.dismiss(newTextTemplate);
  }

  async remove() {
    if (!this.onDelete || !this.textTemplate) {
      return;
    }
    if (await this.onDelete?.(this.textTemplate)) {
      this.cancel();
    }
  }

  cancel() {
    this.modal.dismiss();
  }

}
