import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {PdfPlansFilteredDataService} from 'src/app/services/project-room/pdf-plans-filtered-data.service';
import {TagBase} from 'submodules/baumaster-v2-common';

@Component({
  selector: 'app-edit-tags-modal',
  templateUrl: './edit-tags-modal.component.html',
  styleUrls: ['./edit-tags-modal.component.scss'],
})
export class EditTagsModalComponent implements OnInit {
  private modal: HTMLIonModalElement;

  @HostBinding('class.omg-boundary')
  readonly omgUi = true;

  @Input()
  tags: TagBase[];

  @Input()
  clearLabel = 'clear';
  @Input()
  applyLabel = 'button.apply';

  get tagIds() {
    return this.tags?.map(({id}) => id);
  }

  allTags$ = this.pdfPlansFilteredDataService.tagsUsedInPdfVersions$;

  constructor(
    private pdfPlansFilteredDataService: PdfPlansFilteredDataService
  ) {}

  ngOnInit() { }

  isTagSelected(tag: TagBase) {
    return this.tags.some(({id}) => id === tag.id);
  }

  toggleTag(tag: TagBase) {
    if (this.isTagSelected(tag)) {
      this.tags = this.tags.filter(({id}) => id !== tag.id);
    } else {
      this.tags = [...this.tags, tag];
    }
  }

  clearAndClose() {
    this.tags = [];
    this.save();
  }

  save() {
    this.modal.dismiss(this.tags, 'save');
  }

}
