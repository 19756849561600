<div appLayout [appLayoutColumn]="true">
  <div class="omg-modal-section-header">
    {{ 'project_room.pdf_plan_folder.icon_picker_header' | translate }}
  </div>
  <div [appLayout]="1.5" appLayoutJustifyContent="var(--justify-content)" class="icons-container">
    <ion-button class="omg-btn-secondary" [class.omg-btn-color-selected]="iconValue && icon[0] === iconValue[0] && icon[1] === iconValue[1]" *ngFor="let icon of icons" (click)="changeIcon(icon)">
      <fa-icon slot="icon-only" [icon]="icon"></fa-icon>
    </ion-button>
  </div>
</div>
