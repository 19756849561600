import {Injectable} from '@angular/core';
import {generateFilePathsForPdfPlanAttachmentOrPage, IdType, PdfPlanPage, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {map} from 'rxjs/operators';
import {AbstractProjectAwareAttachmentDataService} from './abstract-project-aware-attachment-data.service';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {SystemEventService} from '../event/system-event.service';
import {DevModeService} from '../common/dev-mode.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/pdfPlanPages';

@Injectable({
  providedIn: 'root'
})


export class PdfPlanPageDataService extends AbstractProjectAwareAttachmentDataService<PdfPlanPage> {

  constructor(http: HttpClient, storage: StorageService, private toastController: ToastController, authenticationService: AuthenticationService, userService: UserService,
              protected projectDataService: ProjectDataService, loggingService: LoggingService, systemEventService: SystemEventService,
              protected devModeService: DevModeService, integrityResolverService: IntegrityResolverService, protected translateService: TranslateService,
              protected attachmentSettingService: AttachmentSettingService, protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService) {
    super(StorageKeyEnum.PDF_PLAN_PAGE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, systemEventService,
          devModeService, integrityResolverService, translateService, attachmentSettingService, projectAvailabilityExpirationService, VERSION_INTRODUCED_DEFAULT,
      ['pageNumber', 'createdAt', 'id'], ['asc', 'asc', 'asc']);
  }

  public getPlanPageByPlanVersionId(...pdfPlanVersionIds: Array<IdType>): Observable<Array<PdfPlanPage>> {
    return this.getPlanPageByPlanVersionIds(pdfPlanVersionIds);
  }

  public getPlanPageByPlanVersionIdAcrossProjects(...pdfPlanVersionIds: Array<IdType>): Observable<Array<PdfPlanPage>> {
    return this.getPlanPageByPlanVersionIdsAcrossProjects(pdfPlanVersionIds);
  }

  public getPlanPageByPlanVersionIds(pdfPlanVersionIds: Array<IdType>): Observable<Array<PdfPlanPage>> {
    return this.data.pipe(
      map(planPages => planPages.filter(planPage => pdfPlanVersionIds.includes(planPage.pdfPlanVersionId)))
    );
  }

  public getPlanPageByPlanVersionIdsAcrossProjects(pdfPlanVersionIds: Array<IdType>): Observable<Array<PdfPlanPage>> {
    return this.dataAcrossProjects$.pipe(
      map(planPages => planPages.filter(planPage => pdfPlanVersionIds.includes(planPage.pdfPlanVersionId)))
    );
  }

  protected setGeneratedFilePath(attachment: PdfPlanPage) {
    attachment.filePath = generateFilePathsForPdfPlanAttachmentOrPage(attachment).filePath;
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}

