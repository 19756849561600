<ionic-selectable
  #selectable
  [canSearch]="true"
  searchPlaceholder="{{ 'search' | translate }}"
  searchFailText="{{ 'noItemsFound' | translate }}"
  [items]="folders$ | async"
  [ngModel]="folderId$ | async"
  (ngModelChange)="navigateToFolder($event)"
  itemValueField="id"
  itemTextField="name"
  appSelectableCommon
  #fs="appSelectableCommon"
  [shouldFocusSearchbar]="fs.isDesktop"
  [shouldStoreItemValue]="true"
  (onOpen)="onOpen($event)"
  (onClose)="onClose($event)">
  <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
    <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
  </ng-template>
  <ng-template ionicSelectableValueTemplate let-value="value">
    <app-selectable-value [value]="value"></app-selectable-value>
  </ng-template>
  <ng-template ionicSelectableHeaderTemplate>
    <app-selectable-header></app-selectable-header>
  </ng-template>
  <ng-template ionicSelectableFooterTemplate>
    <app-selectable-footer [canClear]="false"></app-selectable-footer>
  </ng-template>
</ionic-selectable>
