import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {CompanyCraft, IdType, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {map} from 'rxjs/operators';
import {AbstractClientAwareMappingDataService} from './abstract-client-aware-mapping-data.service';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';

const REST_ENDPOINT_URI = 'api/data/companyCrafts';

@Injectable({
  providedIn: 'root'
})
export class CompanyCraftDataService extends AbstractClientAwareMappingDataService<CompanyCraft> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.COMPANY_CRAFT, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService);
  }

  getByCompanyId(companyId: IdType) {
    return this.dataForOwnClient$.pipe(
      map((companiesCrafts) =>
        companiesCrafts.filter((cCraft) => cCraft.companyId === companyId))
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
