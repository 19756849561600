import { ColumnConfig } from '../ColumnConfig';
import { skipColumn } from './skipColumn';

const UniqueProfilesInProjectUniq = ['project_id', 'profile_id'];

export const ProjectProfileColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true, skip: skipColumn},
  {name: 'project_id', prop: 'projectId', compositeKey: true, uniqueWith: [UniqueProfilesInProjectUniq]},
  {name: 'profile_id', prop: 'profileId', compositeKey: true, uniqueWith: [UniqueProfilesInProjectUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'}
];
