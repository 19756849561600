import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {CompanySource, Employee} from 'src/app/model/contacts';
import {PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {PdfMailingListCompany, PdfMailingListEmployee} from 'src/app/model/send-protocol';
import {LoadingService} from 'src/app/services/common/loading.service';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';
import {ContactService} from 'src/app/services/contact/contact.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {SyncStrategy} from 'src/app/services/sync/sync-utils';
import {SyncService} from 'src/app/services/sync/sync.service';
import {OmgToastService} from 'src/app/services/ui/omg-toast.service';
import {UserEmailSignatureService} from 'src/app/services/user/user-email-signature.service';
import {DATE_FORMAT} from 'src/app/shared/constants';
import {v4} from 'uuid';
import {SharePdfPlanVersionsEmailSettings} from '../../../model/share-pdf-plan-versions-email-settings';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {PdfPlanHolderService} from '../../../services/project-room/pdf-plan-holder.service';
import {ShareVersionsEmailSettingsTemplateService} from '../../../services/project-room/share-versions-email-settings-template.service';
import {combineLatestAsync, observableToPromise} from '../../../utils/async-utils';
import {ManageCompanyOrderComponent} from '../../company/manage-company-order/manage-company-order.component';
import { PdfMailingListComponent } from '../../pdf/pdf-mailing-list/pdf-mailing-list.component';

interface SharePdfPlansWorkflowStep {
  key: 'MAILING_LIST' | 'EMAIL_SETTINGS';
  titleTranslationKey: string;
}

export const WORKFLOW_STEPS: SharePdfPlansWorkflowStep[] = [
  {key: 'MAILING_LIST', titleTranslationKey: 'project_room.share_pdf_plans_workflow.step.MAILING_LIST.title'},
  {key: 'EMAIL_SETTINGS', titleTranslationKey: 'project_room.share_pdf_plans_workflow.step.EMAIL_SETTINGS.title'},
];

const LOG_SOURCE = 'SharePdfPlansWorkflowComponent';

@Component({
  selector: 'app-share-pdf-plans-workflow',
  templateUrl: './share-pdf-plans-workflow.component.html',
  styleUrls: ['./share-pdf-plans-workflow.component.scss'],
})
export class SharePdfPlansWorkflowComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  private destroy$ = new Subject<void>();
  readonly DATE_FORMAT = DATE_FORMAT;

  @ViewChild('appPdfMailingList') appPdfMailingList: PdfMailingListComponent;

  @Input()
  plans: PdfPlanWithDeletable[];

  readonly workflowSteps = WORKFLOW_STEPS;
  currentStepIndex = 0;
  currentStep = this.workflowSteps[0];

  private checkedEmployeesSubject = new BehaviorSubject<PdfMailingListEmployee[]>([]);

  get checkedEmployees(): PdfMailingListEmployee[] {
    return this.checkedEmployeesSubject.value;
  }

  set checkedEmployees(employees: PdfMailingListEmployee[]) {
    this.checkedEmployeesSubject.next(employees);
  }

  mailingListCompanies$: Observable<PdfMailingListCompany[]> = combineLatestAsync([this.contactService.sortedCompanies$]).pipe(
    map(([sortedCompanies]) => sortedCompanies
      .filter((company) => !!company.projectCompany && (company.isActive === undefined || company.isActive))
      .map(this.companySourceWithEmployeesMap)
    )
  );

  public searchTextInput: string | undefined;

  isNetworkConnected: boolean | undefined;

  get checkedEmployeeEmails() {
    return this.checkedEmployees.map(({email}) => email);
  }

  emailSettingsForm = this.shareVersionsEmailSettingsTemplateService.getEmptyFormGroup();

  constructor(
    private contactService: ContactService,
    private networkStatusService: NetworkStatusService,
    private modalController: ModalController,
    private pdfPlanHolderService: PdfPlanHolderService,
    private projectDataService: ProjectDataService,
    private userEmailSignatureService: UserEmailSignatureService,
    private toastService: OmgToastService,
    private loadingService: LoadingService,
    private systemEventService: SystemEventService,
    private syncService: SyncService,
    private shareVersionsEmailSettingsTemplateService: ShareVersionsEmailSettingsTemplateService
  ) { }

  companySourceWithEmployeesMap = (company: CompanySource) => {
    const employees = company.employees
      ?.filter((employee) => !!employee.projectProfile && (employee.profile.isActive === undefined || employee.profile.isActive))
      .map(this.employeeToMailingEmployee) ?? [];
    return ({
      ...company,
      allEmployees: employees,
      preSelectedEmployees: [],
      assignedEmployees: [],
      unassignedEmployees: employees,
    });
  };

  employeeToMailingEmployee = (employee: Employee): PdfMailingListEmployee => ({
    ...employee,
    hasReport: false,
    hasProtocol: false,
    participant: {
      id: v4(),
      changedAt: new Date().toISOString(),
      invited: false,
      mailingList: this.checkedEmployees.some(({id}) => employee.id === id),
      present: false,
      profileId: employee.profile?.id,
      pdfpreviewId: null,
      presentFrom: null,
      presentTo: null,
      seenAt: null,
    },
  });

  ngOnInit() {
    this.networkStatusService.networkStatusObservable.pipe(takeUntil(this.destroy$))
      .subscribe((connectionStatus) => this.isNetworkConnected = connectionStatus?.connected);
    this.shareVersionsEmailSettingsTemplateService.template$.pipe(takeUntil(this.destroy$)).subscribe((template) => {
      if (template && !this.emailSettingsForm.dirty) {
        this.emailSettingsForm.setValue(template);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleRecipientChanged(employee: PdfMailingListEmployee) {
    if (employee.participant.mailingList) {
      this.checkedEmployees = [...this.checkedEmployees, employee];
    } else {
      this.checkedEmployees = this.checkedEmployees.filter(({id}) => id !== employee.id);
    }
  }

  async back() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
      this.currentStep = this.workflowSteps[this.currentStepIndex];
    }
  }

  async next() {
    if (this.currentStepIndex < this.workflowSteps.length - 1) {
      this.currentStepIndex++;
      this.currentStep = this.workflowSteps[this.currentStepIndex];
    }
  }

  private getEmailSettings(): SharePdfPlanVersionsEmailSettings {
    const {arrangeNewAppointment: __, individualProtocol: ___, ...result} = this.emailSettingsForm.getRawValue();
    return result;
  }

  async finish() {
    try {
      await this.loadingService.withLoading(async () => {
        await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
        await this.pdfPlanHolderService.sharePdfPlanVersions({
          profileIds: this.checkedEmployees.map(({profile: {id}}) => id),
          emailSettings: this.getEmailSettings(),
          planVersionIds: this.plans.map(({latestPdfPlanVersion}) => latestPdfPlanVersion.id),
        }, (await this.projectDataService.getMandatoryCurrentProject()).id);
        this.modal.dismiss(undefined, 'sent');
      });
    } catch (e) {
      this.systemEventService.logErrorEvent(`${LOG_SOURCE} - finish (sharePdfPlanVersions)`, e);
      this.toastService.errorWithMessageAndHeader(
        'project_room.pdf_plan_holders.shareWorkflow.shareFailed.header',
        'project_room.pdf_plan_holders.shareWorkflow.shareFailed.message'
      );
    }
  }

  isCurrentStepValid(): boolean {
    return this.isStepValid(this.currentStep);
  }

  isAllStepsValid(): boolean {
    for (const step of this.workflowSteps) {
      if (!this.isStepValid(step)) {
        return false;
      }
    }
    return true;
  }

  private isStepValid(step: SharePdfPlansWorkflowStep): boolean {
    switch (step.key) {
      case 'MAILING_LIST': return this.isNetworkConnected !== false && this.checkedEmployees.length > 0;
      case 'EMAIL_SETTINGS': return this.isNetworkConnected !== false;
      default: throw new Error(`isStepValid - currentStep with key "${this.currentStep.key}" is not supported.`);
    }
  }

  handleAttachmentUserEmailSignatureCreate({blob, filename}: {blob: Blob; filename: string}) {
    return this.userEmailSignatureService.uploadAttachmentUserEmailSignature(blob, filename);
  }

  async saveAsDefaultSettings() {
    try {
      const {emailSignature, ...template} = this.emailSettingsForm.getRawValue();
      await this.shareVersionsEmailSettingsTemplateService.saveAsDefaultTemplate(template);
    } catch (e) {
      this.systemEventService.logErrorEvent(`${LOG_SOURCE} - saveAsDefaultSettings`, e);
      this.toastService.errorWithMessageAndHeader(
        'project_room.pdf_plan_holders.shareWorkflow.saveEmailTemplate.header',
        'project_room.pdf_plan_holders.shareWorkflow.saveEmailTemplate.message'
      );
    }
  }

  async restoreToDefaultSettings() {
    const defaultTemplate = await observableToPromise(this.shareVersionsEmailSettingsTemplateService.template$);
    this.emailSettingsForm.reset(defaultTemplate);
  }

  async openCompanyOrderModal() {
    const modal = await this.modalController.create({
      component: ManageCompanyOrderComponent,
      cssClass: 'omg-modal omg-boundary'
    });

    await modal.present();
  }

  onSearchTextChanged() {
    if (this.appPdfMailingList) {
      this.appPdfMailingList.searchTextInput = this.searchTextInput;
      this.appPdfMailingList.searchEmployee();
    }
  }
}
