import {Injectable} from '@angular/core';
import {IdType, ProtocolOpenEntry, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractProjectAwareMappingDataService} from './abstract-project-aware-mapping-data.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/protocolOpenEntries';

@Injectable({
  providedIn: 'root'
})
export class ProtocolOpenEntryDataService extends AbstractProjectAwareMappingDataService<ProtocolOpenEntry> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.PROTOCOL_OPEN_ENTRY, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

  public getByProtocolEntryId(protocolEntryId: IdType): Observable<ProtocolOpenEntry> {
    return this.data.pipe(
      map(protocolOpenEntries => protocolOpenEntries.find(protocolEntry => protocolEntry.protocolEntryId === protocolEntryId))
    );
  }

  public getByProtocolEntryIdAcrossProjects(protocolEntryId: IdType): Observable<ProtocolOpenEntry> {
    return this.dataAcrossProjects$.pipe(
      map(protocolOpenEntries => protocolOpenEntries.find(protocolEntry => protocolEntry.protocolEntryId === protocolEntryId))
    );
  }

  public getAllByProtocolEntryIds(protocolEntryIds: IdType[]): Observable<ProtocolOpenEntry[]> {
    return this.data.pipe(
      map(protocolOpenEntries => protocolOpenEntries.filter(protocolEntry => protocolEntryIds.includes(protocolEntry.protocolEntryId)))
    );
  }

  public getByProtocolId(protocolId: IdType): Observable<Array<ProtocolOpenEntry>> {
    return this.data.pipe(
      map(protocolOpenEntries => protocolOpenEntries.filter(protocolEntry => protocolEntry.protocolId === protocolId))
    );
  }

  public getByProtocolIdAcrossProjects(protocolId: IdType): Observable<Array<ProtocolOpenEntry>> {
    return this.dataAcrossProjects$.pipe(
      map(protocolOpenEntries => protocolOpenEntries.filter(protocolEntry => protocolEntry.protocolId === protocolId))
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
