<app-loading appActionWithLoading #actionWithLoading="appActionWithLoading" [loading]="actionWithLoading.loading$ | async"></app-loading>

<app-tags-searchbar-popover
  [placeholder]="(viewMode$ | async) === 'search' ? 'tags.popover.searchbar.search_placeholder' : 'tags.popover.searchbar.placeholder'"
  [actionButtonIcon]="searchButtonIcon$ | async"
  [actionButtonDisabled]="searchbarActionDisabled$ | async"
  (actionButtonClick)="actionWithLoading.performAction(handleSearchbarActionClick)"
  (searchbarEnterDown)="actionWithLoading.performAction(handleEnterDown)"
  (inputChange)="handleInputChange($event)"
  (willBackdropClick)="handleWillBackdropClick()"
  (searchbarBlur)="searchbarFocus = false"
  (searchbarFocus)="searchbarFocus = true"
></app-tags-searchbar-popover>
<ng-container *ngIf="(viewMode$ | async) as viewMode" [ngSwitch]="viewMode">
  <ng-container *ngSwitchCase="'manage'">
    <app-all-tag-list-popover
      [filteredTags]="filteredTags$ | async"
      (tagEdit)="editTag($event)"
    ></app-all-tag-list-popover>
  </ng-container>
  <ng-container *ngSwitchCase="'search'">
    <app-object-tag-list-popover
      [class.focus-on-searchbar]="searchbarFocus && (search$ | async)"
      [tags]="tags"
      [filteredTags]="filteredSearchTags$ | async"
      (tagRemove)="removeTag($event)"
      (tagAdd)="addTag($event)"
      (editClick)="goToManage()"
    ></app-object-tag-list-popover>
  </ng-container>
  <ng-container *ngSwitchCase="'edit'">
    <ion-text *ngIf="tagAlreadyExists$ | async" color="danger" class="section-header">Tag name '{{tagToEdit.name}}' already exists; choose different name</ion-text>
    <app-tag-edit-popover
      [(tag)]="tagToEdit"
      (tagDelete)="actionWithLoading.performAction(deleteTag, $event)"
    ></app-tag-edit-popover>
  </ng-container>
</ng-container>
