import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit {

  @Input()
  color: string;

  @Input()
  dark = true;

  @Input()
  overlap = false;

  @Input()
  image?: string;

  @Input()
  initials: string;

  @Input()
  size?: 'small' | 'medium' | undefined = 'small';

  @HostBinding('class.size-small')
  get sizeSmallBinding() {
    return !this.size || this.size === 'small';
  }

  @HostBinding('class.size-medium')
  get sizeMediumBinding() {
    return this.size === 'medium';
  }

  @HostBinding('style.--background')
  get backgroundBinding() {
    if (this.color?.[0] === '#' || this.color?.startsWith('var(')) {
      return this.color;
    }
    return `var(--ion-color-${this.color})`;
  }

  @HostBinding('style.--color')
  get colorBinding() {
    if (this.color?.[0] !== '#' && !this.color?.startsWith('var(')) {
      return `var(--ion-color-${this.color}-contrast)`;
    }
    return undefined;
  }

  @HostBinding('style.background-image')
  get imageBinding() {
    if (!this.image) {
      return undefined;
    }

    return `url(${this.image})`;
  }

  @HostBinding('style.font-size')
  get fontSizeBinding() {
    if (this.image) {
      return 0;
    }

    return undefined;
  }

  @HostBinding('class.dark-text')
  get darkTextClass() { return this.dark; }

  @HostBinding('class.overlap')
  get overlapClass() { return this.overlap; }

  constructor() { }

  ngOnInit() {}

}
