import { Component, Input } from '@angular/core';
import {IonicSelectableComponent} from 'ionic-selectable';

@Component({
  selector: 'app-selectable-header',
  templateUrl: './selectable-header.component.html',
  styleUrls: ['./selectable-header.component.scss'],
})
export class SelectableHeaderComponent {

  @Input()
  title?: string;

  constructor(public selectable: IonicSelectableComponent) {}

}
