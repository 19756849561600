<ion-content #lt="appLicenseType" appLicenseType>
  <ion-split-pane [when]="foldersListMediaQuery$ | async" [disabled]="!isPlanFolderPage" contentId="pdf-plan-folders-main">
    <ion-content [hidden]="isHoldersEditMode$ | async" class="split-pane-side">
      <app-pdf-plan-folder-list></app-pdf-plan-folder-list>
      <ion-fab slot="fixed" *appFeatureEnabled="false; forConnected true; forLicenses [lt.LicenseType.VIEWER]" vertical="bottom" horizontal="end">
        <ion-button class="omg-btn-primary ion-hide-sm-down" (click)="addNewFolder()">
          {{ 'project_room.pdf_plan_folder.new_folder_button' | translate }}
        </ion-button>
      </ion-fab>
    </ion-content>

    <ion-content id="pdf-plan-folders-main">
      <ion-router-outlet></ion-router-outlet>
    </ion-content>
  </ion-split-pane>
</ion-content>
