import {UserEmailSignature} from 'submodules/baumaster-v2-common';


export type UserEmailSignatureFormType = Omit<UserEmailSignature, 'id' | 'changedAt'>;

export const EmptyUserEmailSignature: UserEmailSignatureFormType = {
  includeCompanyLogo: true,
  text: '',
  size: 'MEDIUM'
};
