import {Component, OnInit, TrackByFunction} from '@angular/core';
import {PdfProtocolSignatureForm} from '../pdf-protocol-signatures/pdf-protocol-signatures.interface';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';

@Component({
  selector: 'app-pdf-protocol-signatures-dialog',
  templateUrl: './pdf-protocol-signatures-dialog.component.html',
  styleUrls: ['./pdf-protocol-signatures-dialog.component.scss'],
})
export class PdfProtocolSignaturesDialogComponent implements OnInit {
  private modal: HTMLIonModalElement;
  pdfProtocolSignatures: PdfProtocolSignatureForm[] = [];
  anythingSigned = false;

  public readonly trackByProfileOrName: TrackByFunction<PdfProtocolSignatureForm> =
    (index, pdfProtocolSignature: PdfProtocolSignatureForm) => pdfProtocolSignature.profileId || pdfProtocolSignature.name;

  constructor(private alertController: AlertController, private translateService: TranslateService) { }

  async ngOnInit() {
    this.pdfProtocolSignatures = _.cloneDeep(this.pdfProtocolSignatures);
    this.modal.canDismiss = () => this.canDismiss();
  }

  public async handlePdfProtocolSignatureChange(pdfProtocolSignature: PdfProtocolSignatureForm, index: number) {
    this.anythingSigned = true;
  }

  public async save() {
    this.modal.canDismiss = true;
    await this.modal.dismiss(this.pdfProtocolSignatures, 'ok');
  }

  public async cancel() {
    await this.modal.dismiss(undefined, 'cancel');
  }

  private async canDismiss(): Promise<boolean> {
    if (!this.anythingSigned) {
      return true;
    }
    const alert = await this.alertController.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          role: 'ok'
        }
      ]
    });
    await alert.present();
    const result = await alert.onDidDismiss();

    return result.role === 'ok';
  }
}
