<ng-container *rxLet="hasNotifications$; let hasNotifications">
  <div class="pl-4 pr-4 pt-6 d-flex flex-column ion-align-items-center" *ngIf="!hasNotifications">
    <div class="omg-t-body-bold">{{'notificationsCenter.noNotifications.header' | translate}}</div>
    <div class="omg-t-body omg-f-italic">{{'notificationsCenter.noNotifications.body' | translate}}</div>
    <img class="brian-news pt-4" src="/assets/images/brian-news.png" />
  </div>
  <rx-virtual-scroll-viewport autosize [tombstoneSize]="showProjectName ? 132 : 110" [hidden]="!hasNotifications">
    <div class="pl-4 pr-4 pb-3 full-width" *rxVirtualFor="let item of notifications$; trackBy 'id'">
      <div *ngIf="item.firstInGroup" class="d-flex ion-align-items-center ion-justify-content-center pb-2 pt-3">
        <span class="group-badge omg-t-medium-body-bold">{{ ('notificationCenter.group.' + item.groupId) | translate }}</span>
      </div>
      <app-notification-card
        [project]="showProjectName ? item.projectName : undefined"
        [seen]="!!item.seenAt"
        [date]="item.createdAt"
        [icon]="item.notificationIcon"
        [iconClasses]="item.notificationIconClasses"
        (statusClick)="$event.stopPropagation(); handleStatusClick(item)"
        (click)="handleNotificationClick(item)"
      >
        {{item.notificationText}}
      </app-notification-card>
    </div>
  </rx-virtual-scroll-viewport>
</ng-container>