<ion-grid>
  <ion-row class="ion-align-items-center">
    <ion-col *ngIf="pdfProtocolSignatures?.length">
      <ion-buttons>
        <ion-button (click)="sign()" [disabled]="readonly || operationInProgress" class="omg-btn-secondary">
          <span class="ion-hide-sm-down">{{'pdfProtocol.signature.sign' | translate}}</span>
          <fa-icon class="ion-hide-sm-up" [icon]="['fal','signature']"></fa-icon>
        </ion-button>
        <span *ngIf="pdfProtocolSignatures?.length" class="signerCount">{{signedCount}}/{{pdfProtocolSignatures.length}}</span>
      </ion-buttons>
    </ion-col>
    <ion-col *ngIf="showSendButtons">
      <ion-buttons class="ion-justify-content-end">
        <ion-button class="omg-btn-primary" (click)="triggerClose()" [disabled]="operationInProgress">
          {{'next' | translate}}
        </ion-button>
      </ion-buttons>
    </ion-col>
  </ion-row>
</ion-grid>
