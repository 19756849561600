import { ColumnConfig } from '../ColumnConfig';
import { skipColumn } from './skipColumn';

const UniqueCompaniesInProjectUniq = ['project_id', 'company_id'];

export const ProjectCompanyColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true, skip: skipColumn},
  {name: 'project_id', prop: 'projectId', compositeKey: true, uniqueWith: [UniqueCompaniesInProjectUniq]},
  {name: 'company_id', prop: 'companyId', compositeKey: true, uniqueWith: [UniqueCompaniesInProjectUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'sort_order', prop: 'sortOrder', def: null}
];
