<ion-item lines="none">
  {{'project_room.upload_pdf_plan_version.step.MARKER.textCount' | translate: {entryCount: (protocolEntriesOpenOther$ | async)?.protocolEntries.length} }}
</ion-item>

<ion-item lines="none">
  {{'project_room.upload_pdf_plan_version.step.MARKER.open' | translate}} <ion-badge class="ion-margin-start" color="neutral-light-badge">{{(protocolEntriesOpenOther$ | async)?.protocolEntriesOpen.length}}</ion-badge>&nbsp;
  {{'project_room.upload_pdf_plan_version.step.MARKER.other' | translate}} <ion-badge class="ion-margin-start" color="neutral-light-badge">{{(protocolEntriesOpenOther$ | async)?.protocolEntriesOther.length}}</ion-badge><br>
</ion-item>

<ion-item lines="none">

</ion-item>

<ion-item lines="none" *ngIf="pdfPlanPageMarkingGeneral$ | async">
  <ion-item lines="none">
    <ion-toggle appSlimToggle [ngModel]="migratePdfPlanPageMarkingGeneral" (ngModelChange)="changeMigratePdfPlanPageMarkingGeneral($event)"></ion-toggle>
    <ion-text class="size-sm">{{ 'project_room.upload_pdf_plan_version.step.MARKER.textSketchesGeneral' | translate }}</ion-text>
  </ion-item>
</ion-item>
