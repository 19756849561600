import {UntypedFormGroup} from '@angular/forms';
import {isValidDate} from './date-utils';

export function convertFormValuesFromISOStringToDate(form: UntypedFormGroup, ...formFields: Array<string>) {
  for (const formField of formFields) {
    const value = form.get(formField).value;
    if (value === undefined || value === null || value instanceof Date || value === '') {
      continue;
    }
    if (!(typeof value === 'string')) {
      throw new Error(`Form Field "${formField}" with value "${value}" is not of type Date nor String.`);
    }
    const valueAsDate = new Date(value as string);
    if (!isValidDate(valueAsDate)) {
      throw new Error(`Unable to convert date string "${value}" to a valid date.`);
    }
    form.get(formField).setValue(valueAsDate, { emitEvent: false });
  }
}
