import {distinctUntilChanged} from 'rxjs/operators';
import {IdAware} from 'submodules/baumaster-v2-common';
import {Nullish} from '../model/nullish';
import {haveObjectsEqualProperties} from './object-utils';

export type Primitive = number | string | symbol | boolean | undefined | null;
type IncludePrimitiveKeys<T> = (keyof {
  [P in keyof T as T[P] extends Primitive ? P : never]: T[P];
});

export const comparePrimitiveSets = <T extends Primitive>(oldSet: Set<T>, newSet: Set<T>) => {
  if (oldSet.size !== newSet.size) {
    return false;
  }

  return Array.from(oldSet.keys()).every((value) => newSet.has(value));
};

export const comparePrimitiveArrays = <T extends Primitive>(oldArray: Array<T>, newArray: Array<T>) => {
  if (oldArray.length !== newArray.length) {
    return false;
  }

  return oldArray.every((value) => newArray.includes(value));
};

export const compareArrays = <T>(oldArray: Array<T>, newArray: Array<T>, comparator: (a: T, b: T) => boolean) => {
  if (oldArray.length !== newArray.length) {
    return false;
  }

  return oldArray.every((value) => newArray.some((innerValue) => comparator(innerValue, value)));
};

export const compareObjectsWithPrimitiveValues = <T extends Record<string, Primitive>>(oldObject: T, newObject: T) => {
  const oldKeys = Object.keys(oldObject);
  if (oldKeys.length !== Object.keys(newObject).length) {
    return false;
  }

  return oldKeys.every((key) => newObject[key] && oldObject[key] === newObject[key]);
};

export const compareObjectsWithPrimitiveArrays = <T extends Record<string, Primitive[]>>(oldObject: T, newObject: T) => {
  const oldKeys = Object.keys(oldObject);
  if (oldKeys.length !== Object.keys(newObject).length) {
    return false;
  }

  return oldKeys.every((key) => newObject[key] && comparePrimitiveArrays(oldObject[key], newObject[key]));
};

export const compareObjectsWithArrays = <T>(oldObject: Record<string, T[]>, newObject: Record<string, T[]>, comparator: (a: T, b: T) => boolean) => {
  const oldKeys = Object.keys(oldObject);
  if (oldKeys.length !== Object.keys(newObject).length) {
    return false;
  }

  return oldKeys.every((key) => newObject[key] && compareArrays(oldObject[key], newObject[key], comparator));
};

export const compareObjectsWithObjectWithComparator = <T>(comparator: (a: T|null|undefined, b: T|null|undefined) => boolean) =>
  (oldObject: Record<string, T>, newObject: Record<string, T>): boolean => {
  const oldKeys = Object.keys(oldObject);
  if (oldKeys.length !== Object.keys(newObject).length) {
    return false;
  }

  return oldKeys.every((key) => newObject[key] && comparator(
    oldObject[key],
    newObject[key]
  ));
};

export const compareObjectsWithObjectWithPrimitiveValues = <T>(fieldsToCompare: IncludePrimitiveKeys<T>[]) => compareObjectsWithObjectWithComparator((a: T, b: T) =>
  haveObjectsEqualProperties(a, b, fieldsToCompare));

/**
 * Compares arrays by elements, counting in only fieldsToCompare.
 *
 * Two arrays with the same objects, but with different order are considered the same.
 */
export const compareUnsortedArraysByObjectKeys = <T extends IdAware>(fieldsToCompare: (keyof T)[]) => (oldArray: Nullish<T[]>, newArray: Nullish<T[]>) => {
  if (!oldArray && !newArray) {
    return true;
  }
  if (!oldArray || !newArray) {
    return false;
  }

  if (oldArray.length !== newArray.length) {
    return false;
  }

  const oldEntriesMap = new Map(oldArray.map((item) => [item.id, item]));

  return newArray.every((item) => {
    const oldItem = oldEntriesMap.get(item.id);
    if (!oldItem) {
      return false;
    }

    return haveObjectsEqualProperties(
      oldItem,
      item,
      fieldsToCompare
    );
  });
};

/**
 * Suspends emissions of the next elements, if subsequent emissions emits same array, counting in only fieldsToCompare.
 *
 * Two arrays with the same objects, but with different order are considered the same.
 */
export const distinctUntilUnsortedArraysByObjectKeysChanged = <T extends IdAware>(fieldsToCompare: (keyof T)[]) => distinctUntilChanged(compareUnsortedArraysByObjectKeys(fieldsToCompare));
