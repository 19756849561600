import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import _ from 'lodash';
import {combineLatest, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {isTaskProtocol} from 'src/app/utils/protocol-utils';
import {IdType, LicenseType, Protocol, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {FeatureEnabledService} from '../feature/feature-enabled.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ParticipantDataService} from './participant-data.service';
import {ProjectDataService} from './project-data.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/protocols';

@Injectable({
  providedIn: 'root'
})
export class ProtocolDataService extends AbstractProjectAwareDataService<Protocol> {
  public readonly isHidden = isTaskProtocol;
  private readonly filterHidden = (protocol: Protocol): boolean => !this.isHidden(protocol);
  public readonly dataWithoutHidden$ = this.data.pipe(map((protocols) => protocols.filter(this.filterHidden)));
  public readonly dataWithoutHiddenAcrossProjects$ = this.dataAcrossProjects$.pipe(map((protocols) => protocols.filter(this.filterHidden)));
  public readonly dataWithoutHiddenGroupedById$: Observable<Record<IdType, Protocol>> = this.dataWithoutHidden$.pipe(
    map(entities => entities === null ? {} : _.keyBy(entities, 'id'))
  );
  public readonly dataAcrossProjectsWithoutHiddenGroupedById$: Observable<Record<IdType, Protocol>> = this.dataWithoutHiddenAcrossProjects$.pipe(
    map(entities => entities === null ? {} : _.keyBy(entities, 'id'))
  );
  public readonly dataWithoutHiddenByProjectId$ = this.dataByProjectId$.pipe(map((dataByProjectId) =>
    new Map<IdType, Protocol[]>([...dataByProjectId.entries()].filter(([projectId, protocols]) => [projectId, protocols.filter(this.filterHidden)]))));

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService, private featureEnabledService: FeatureEnabledService,
              private participantDataService: ParticipantDataService) {
    super(StorageKeyEnum.PROTOCOL, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  public findOpenProtocolsOfType(protocolTypeId: IdType): Observable<Array<Protocol>> {
    return this.dataWithoutHidden$.pipe(map((protocols) => protocols.filter((protocol) => protocol.typeId === protocolTypeId && protocol.closedAt === null)));
  }

  public findProtocolsOfType(protocolTypeId: IdType): Observable<Array<Protocol>> {
    return combineLatest([
      this.dataWithoutHidden$,
      this.userService.currentUser$.pipe(
        switchMap((user) =>  this.participantDataService.getOwnParticipants(user.profileId))
      ),
      this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER])
    ]).pipe(
      map(([protocols, participants, notForConnectedAndViewer]) => {
        if (!notForConnectedAndViewer) {
          const protocolIds = participants.map((participant) => participant.protocolId);

          return protocols.filter((protocol) => protocol.typeId === protocolTypeId && protocolIds.includes(protocol.id));
        } else {
          return protocols.filter((protocol) => protocol.typeId === protocolTypeId);
        }
      })
    );
  }

  getProtocolsCountByTypeId(): Observable<{ [key: string]: number; }> {
    return this.dataWithoutHidden$.pipe(
      map((protocols) => {
        const grouped: { [key: string]: number } = _.countBy(protocols, 'typeId');
        grouped.all = protocols.length;

        return grouped;
      })
    );
  }

}
