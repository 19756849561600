import {Component, Input, OnInit} from '@angular/core';
import {UiModule} from '../../../shared/module/ui/ui.module';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {OfflineInfoComponent} from '../offline-info/offline-info.component';

@Component({
  selector: 'app-offline-info-modal',
  templateUrl: './offline-info-modal.component.html',
  styleUrls: ['./offline-info-modal.component.scss'],
  standalone: true,
  imports: [
    UiModule,
    CommonModule,
    IonicModule,
    TranslateModule,
    OfflineInfoComponent,
  ]
})
export class OfflineInfoModalComponent implements OnInit {

  @Input()
  showRetry = true;

  @Input()
  showHint = true;

  private modal: HTMLIonModalElement;

  constructor() {
  }

  ngOnInit() {
    this.modal.canDismiss = true;
  }

  close() {
    this.modal.dismiss(undefined,'cancel');
  }

  retry() {
    this.modal.dismiss(undefined, 'retry');
  }

}
