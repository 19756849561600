import {Injectable} from '@angular/core';
import {IdType} from 'submodules/baumaster-v2-common';
import {AnalyticsService} from '../firebase/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class PlanAnalyticsService {

  constructor(private analyticsService: AnalyticsService) { }

  planActionsClicked(planId: IdType) {
    this.analyticsService.logEvent('PlanAnalyticsService', 'planActionsClicked', {planId, debug_mode: true});
  }

  planActionClicked(planId: IdType, actionType: string) {
    this.analyticsService.logEvent('PlanAnalyticsService', `planActionClicked:${actionType}`, {planId, debug_mode: true});
  }

  planVersionActionClicked(versionId: IdType, actionType: string) {
    this.analyticsService.logEvent('PlanAnalyticsService', `planVersionActionClicked:${actionType}`, {versionId, debug_mode: true});
  }

  planSearchChanged(search: string) {
    this.analyticsService.logEvent('PlanAnalyticsService', `planSearchChange:${search}`, {debug_mode: true});
  }

  planFilterOpened() {
    this.analyticsService.logEvent('PlanAnalyticsService', `planFilterOpened`, {debug_mode: true});
  }

  planFilterApplied(options: {location: boolean, tags: boolean, active: boolean, inactive: boolean}) {
    this.analyticsService.logEvent('PlanAnalyticsService', `planFilterApplied:${Object.entries(options).map(([k, v]) => `${k}=${v}`).join(',')}`, {debug_mode: true});
  }

  planHeaderClicked(headerName: string) {
    this.analyticsService.logEvent('PlanAnalyticsService', `planHeaderClicked:${headerName}`, {debug_mode: true});
  }

  plansEntered() {
    this.analyticsService.logEvent('PlanAnalyticsService', `plansEntered`, {debug_mode: true});
  }

  planSelected(selectedCount: number) {
    this.analyticsService.logEvent('PlanAnalyticsService', `planSelected:${selectedCount}`, {debug_mode: true});
  }

  plansActionClicked(selectedCount: number, actionType: string) {
    this.analyticsService.logEvent('PlanAnalyticsService', `plansActionClicked:${actionType}:${selectedCount}`, {debug_mode: true});
  }

  planTagsClick(planVersionId: IdType, source: 'details' | 'edit') {
    this.analyticsService.logEvent('PlanAnalyticsService', `planTagsClick:${source}`, {planVersionId, debug_mode: true});
  }
}
