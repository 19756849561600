import {IdType} from 'submodules/baumaster-v2-common';
import _ from 'lodash';

export const assertSameProject = <T extends {projectId: IdType}>(data: T[], dataSource: string): IdType => {
  const projectIds = _.compact(_.uniq(data.map(({projectId}) => projectId)));
  if (projectIds.length === 0) {
    throw new Error(`Trying to update ${dataSource}, but none of the data has no projectId set`);
  }
  if (projectIds.length > 1) {
    throw new Error(`Trying to update ${dataSource} of different projectIds (${projectIds})`);
  }

  return projectIds[0];
};
