import enLocale from './locale/en.json';
import deLocale from './locale/de.json';
import frLocale from './locale/fr.json';

export default class Translation {

    private language = new Map<string, Map<string, string>>();
    private english = new Map<string, string>();
    private german = new Map<string, string>();
    private french = new Map<string, string>();

    constructor() {
        this.loadEnglishTranslation();
        this.loadGermanTranslation();
        this.loadFrenchTranslation();
    }

    getTranslation(lng: string): Map<string, string>|undefined {
        return this.language.get(lng);
    }

    private loadEnglishTranslation() {
        for (const [key, value] of Object.entries(enLocale)) {
            this.english.set(key, value);
        }
        this.language.set('en', this.english);
    }

    private loadGermanTranslation() {
        for (const [key, value] of Object.entries(deLocale)) {
            this.german.set(key, value);
        }
        this.language.set('de', this.german);
    }

    private loadFrenchTranslation() {
        for (const [key, value] of Object.entries(frLocale)) {
            this.french.set(key, value);
        }
        this.language.set('fr', this.french);
    }

}
