import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {AutoSizeVirtualScrollStrategy, RxVirtualFor, RxVirtualScrollViewportComponent} from '@rx-angular/template/experimental/virtual-scrolling';
import {BehaviorSubject, distinctUntilChanged, map} from 'rxjs';
import {TranslatedNotificationEventWithGroup} from 'src/app/model/notifications';
import {NotificationCardComponent} from "../notification-card/notification-card.component";
import {RxLet} from '@rx-angular/template/let';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  standalone: true,
  imports: [
    RxVirtualScrollViewportComponent,
    AutoSizeVirtualScrollStrategy,
    RxVirtualFor,
    NotificationCardComponent,
    NgIf,
    RxLet,
    TranslateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent {

  private notificationsSubject = new BehaviorSubject<TranslatedNotificationEventWithGroup[]>([]);
  protected notifications$ = this.notificationsSubject.asObservable();
  protected hasNotifications$ = this.notifications$.pipe(map((notifications) => notifications.length > 0), distinctUntilChanged());
  
  @Input()
  set notifications(notifications: TranslatedNotificationEventWithGroup[]) { this.notificationsSubject.next(notifications); }
  
  get notifications(): TranslatedNotificationEventWithGroup[] { return this.notificationsSubject.value; }

  @Input()
  showProjectName = false;

  @Output()
  statusClick = new EventEmitter<TranslatedNotificationEventWithGroup>();

  @Output()
  notificationClick = new EventEmitter<TranslatedNotificationEventWithGroup>();

  constructor() {}

  protected handleStatusClick(notification: TranslatedNotificationEventWithGroup) {
    this.statusClick.emit(notification);
  }

  protected handleNotificationClick(notification: TranslatedNotificationEventWithGroup) {
    this.notificationClick.emit(notification);
  }

}
