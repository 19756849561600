import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import _, {groupBy, orderBy} from 'lodash';
import {Observable, of} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {CreateUnitInLevelEvent} from 'src/app/components/units/unit-levels-grid/unit-levels.grid.model';
import {UnitForBreadcrumbsWithProfileAddresses, UnitProfileAddress} from 'src/app/model/unit';
import {haveObjectsEqualProperties} from 'src/app/utils/object-utils';
import {Address, IdType, isUnitFeatureEnabledForClient, Project, ProtocolEntry, Unit, UnitForBreadcrumbs, UnitLevel, unitsToUnitForBreadcrumbs} from 'submodules/baumaster-v2-common';
import {v4} from 'uuid';
import {PROTOCOL_LAYOUT_NAME_STANDARD} from '../../shared/constants';
import {combineLatestAsync, observableToPromise} from '../../utils/async-utils';
import {ClientService} from '../client/client.service';
import {LoggingService} from '../common/logging.service';
import {AddressDataService} from '../data/address-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolLayoutDataService} from '../data/protocol-layout-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {UnitDataService} from '../data/unit-data.service';
import {UnitLevelDataService} from '../data/unit-level-data.service';
import {UnitProfileDataService} from '../data/unit-profile-data.service';

const LOG_SOURCE = 'UnitService';

@Injectable({
  providedIn: 'root',
})
export class UnitService {

  unitsForBreadcrumbs$: Observable<Array<UnitForBreadcrumbs>>;
  unitsForBreadcrumbsAcrossProjects$: Observable<Array<UnitForBreadcrumbs>>;
  unitProfileAddresses$: Observable<Array<UnitProfileAddress>>;
  isFeatureEnabled$: Observable<boolean|undefined>
  unitProfileAddressesByUnitId$: Observable<Record<IdType, Array<UnitProfileAddress>>>;

  constructor(private unitProfileDataService: UnitProfileDataService,
              private profileDataService: ProfileDataService,
              private addressDataService: AddressDataService,
              private unitDataService: UnitDataService,
              private unitLevelDataService: UnitLevelDataService,
              private protocolDataService: ProtocolDataService,
              private protocolTypeDataService: ProtocolTypeDataService,
              private protocolLayoutDataService: ProtocolLayoutDataService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private loggingService: LoggingService,
              private clientService: ClientService,
              private projectProfileDataService: ProjectProfileDataService,
              private projectDataService: ProjectDataService,
              private translateService: TranslateService
              ) {
    this.unitsForBreadcrumbs$ = unitDataService.data.pipe(map((units) => unitsToUnitForBreadcrumbs(units)));
    this.unitsForBreadcrumbsAcrossProjects$ = unitDataService.dataAcrossProjects$.pipe(map((units) => unitsToUnitForBreadcrumbs(units)))
      .pipe(shareReplay({ bufferSize: 1, refCount: true}));
    this.unitProfileAddresses$ =
      combineLatestAsync([this.unitDataService.dataGroupedById, this.unitProfileDataService.data, this.profileDataService.dataGroupedById, this.addressDataService.dataGroupedById,
        this.projectProfileDataService.data])
      .pipe(map(([unitsById, unitProfiles, profilesById, addressesById, projectProfiles]) => {
        const unitProfileAddresses = new Array<UnitProfileAddress>();
        for (const unitProfile of unitProfiles.filter((up) => up.isActive && projectProfiles.some((projectProfile) => projectProfile.profileId === up.profileId))) {
          const unit = unitsById[unitProfile.unitId];
          const profile = profilesById[unitProfile.profileId];
          const address = addressesById[profile?.addressId];
          if (!unit || !profile || !address) {
            this.loggingService.warn(LOG_SOURCE, `unit, profile and/or address for unitProfile ${unitProfile.id} not found.`);
            continue;
          }
          unitProfileAddresses.push({
            ...unit,
            unitProfile,
            profile,
            address
          });
        }
        return _.orderBy(unitProfileAddresses, ['id', 'unitProfile.isDefault', 'unitProfile.name', 'address.lastName', 'address.firstName', 'unitProfile.id']);
        }
      )).pipe(shareReplay({ bufferSize: 1, refCount: true}));
    this.unitProfileAddressesByUnitId$ = this.unitProfileAddresses$.pipe(map((unitProfileAddresses) => _.groupBy(unitProfileAddresses, 'id')))
    this.isFeatureEnabled$ = this.clientService.getOwnClient().pipe(map((client) => client ? isUnitFeatureEnabledForClient(client.id) : undefined));
  }

  getUnitForBreadcrumbsById$(unitId: IdType): Observable<UnitForBreadcrumbs|undefined> {
    return this.unitsForBreadcrumbs$.pipe(map((units) => units.find((unit) => unit.id === unitId)));
  }

  getUnitForBreadcrumbsByIdAcrossProjects$(unitId: IdType): Observable<UnitForBreadcrumbs|undefined> {
    return this.unitsForBreadcrumbsAcrossProjects$.pipe(map((units) => units.find((unit) => unit.id === unitId)));
  }

  getUnitProfileAddressesByUnitId$(unitId: IdType): Observable<Array<UnitProfileAddress>> {
    return this.unitProfileAddressesByUnitId$.pipe(map((dataByUnitId) => dataByUnitId[unitId] ?? []));
  }

  getUnitForBreadcrumbAndProfileAddressesByProtocolId$(protocolId: IdType): Observable<UnitForBreadcrumbsWithProfileAddresses | undefined> {
    return this.protocolDataService.getById(protocolId)
      .pipe(switchMap((protocol) => !protocol?.unitId ? of(undefined) :
        combineLatestAsync([this.getUnitForBreadcrumbsById$(protocol.unitId), this.getUnitProfileAddressesByUnitId$(protocol.unitId)])
          .pipe(map(([unitForBreadcrumbs, unitProfileAddresses]) => {
            if (!unitForBreadcrumbs) {
              return undefined;
            }
            return {...unitForBreadcrumbs, unitProfileAddresses: unitProfileAddresses ?? []}
          }))));
  }

  isUnitOfAllProtocolEntriesEmpty$(protocolId: IdType): Observable<boolean> {
    return this.protocolEntryDataService.getByProtocolId(protocolId)
      .pipe(map((protocolEntries) => !protocolEntries.some((protocolEntry) => !!protocolEntry.unitId)));
  }

  async updateUnitOfAllProtocolEntriesIfAllEmpty(protocolId: IdType, unitId: IdType): Promise<Array<ProtocolEntry>> {
    const protocol = await observableToPromise(this.protocolDataService.getById(protocolId));
    const unit = await observableToPromise(this.unitDataService.getById(unitId));
    if (!protocol) {
      throw new Error(`updateUnitOfAllProtocolEntriesIfAllEmpty - Protocol with id ${protocolId} not found.`);
    }
    if (protocol.closedAt) {
      throw new Error(`updateUnitOfAllProtocolEntriesIfAllEmpty - Unable to update entries of Protocol with id ${protocolId} as it is already closed.`);
    }
    if (!unit) {
      throw new Error(`updateUnitOfAllProtocolEntriesIfAllEmpty - Unit with id ${unitId} not found.`);
    }
    const protocolEntries = await observableToPromise(this.protocolEntryDataService.getByProtocolId(protocolId));
    if (!protocolEntries.length) {
      return [];
    }
    const protocolEntriesWithUnit = protocolEntries.filter((protocolEntry) => protocolEntry.unitId);
    if (protocolEntriesWithUnit.length) {
      throw new Error(`Unable to set unitId of all entries of Protocol with id ${protocolId} because ${protocolEntriesWithUnit.length} already have a unit assigned.`);
    }
    const protocolEntriesToUpdate: Array<ProtocolEntry> = protocolEntries.map((protocolEntry) => {
      return {...protocolEntry, unitId};
    })
    await this.protocolEntryDataService.update(protocolEntriesToUpdate, protocol.projectId);
  }

  // This is a duplicate of the method in ProtocolService but we cannot use that without having a circular reference
  private getIsProtocolLayoutStandard$(protocolId: IdType, acrossProject: boolean = false): Observable<boolean|undefined> {
    const protocol$ = acrossProject ? this.protocolDataService.getByIdAcrossProjects(protocolId) : this.protocolDataService.getById(protocolId);
    return protocol$
      .pipe(switchMap((protocol) => !protocol?.typeId ? of (undefined) :
        (acrossProject ? this.protocolTypeDataService.getByIdAcrossClients(protocol.typeId) : this.protocolTypeDataService.getById(protocol.typeId))))
      .pipe(switchMap((protocolType) => !protocolType?.layoutId ? of (undefined) :
        (acrossProject ? this.protocolLayoutDataService.getByIdAcrossClients(protocolType.layoutId) : this.protocolLayoutDataService.getById(protocolType.layoutId))))
      .pipe(map((protocolLayout) => protocolLayout.name === PROTOCOL_LAYOUT_NAME_STANDARD));
  }

  getUnitDefaultForProtocol$(protocolId: IdType): Observable<UnitForBreadcrumbs|undefined|null> {
    return this.isFeatureEnabled$.pipe(switchMap((isFeatureEnabled) => !isFeatureEnabled ? of(undefined) :
      combineLatestAsync([this.protocolDataService.getById(protocolId), this.getIsProtocolLayoutStandard$(protocolId), this.unitsForBreadcrumbs$])))
      .pipe(map(([protocol, isProtocolLayoutStandard, units]) => {
        if (!protocol || !isProtocolLayoutStandard) {
          return undefined;
        }
        if (!protocol.isUnitEntryDefault || !protocol.unitId) {
          return null;
        }
        return units.find((unit) => unit.id === protocol.unitId);
      }));
  }

  getUnitsForBreadcrumbsForProjectId(projectId: IdType): Observable<Array<UnitForBreadcrumbs>> {
    return this.unitLevelDataService.getDataForProject$(projectId)
      .pipe(map((unitLevels) => unitLevels ? unitLevels.map((unitLevel) => unitLevel.id) : []))
      .pipe(switchMap((unitLevelIds) => this.unitsForBreadcrumbsAcrossProjects$
        .pipe(map((unitsForBreadcrumbs) => unitsForBreadcrumbs.filter((unitForBreadcrumbs) => unitLevelIds.includes(unitForBreadcrumbs.unitLevelId))))));
  }

  async createFromTemplate() {
    const project = await this.projectDataService.getMandatoryCurrentProject();
    const isGerman = project.language === 'de';

    const unitLevel1 = await this.createLevelForTemplate(project, 0, isGerman ? 'Ebene 1/Haus' : 'Level 1/Section');
    const unitLevel2 = await this.createLevelForTemplate(project, 1, isGerman ? 'Ebene 2/Haus' : 'Level 2/Section');
    const unitLevel3 = await this.createLevelForTemplate(project, 2, isGerman ? 'Ebene 3/Haus' : 'Level 3/Section');

    const house1 = await this.createUnitForTemplate(project, isGerman ? 'Haus 01' : 'Building 01', 0, unitLevel1.id);
    const house2 = await this.createUnitForTemplate(project, isGerman ? 'Haus 02' : 'Building 02', 1, unitLevel1.id);

    const floor1House1 = await this.createUnitForTemplate(project, isGerman ? 'OG' : '1', 0, unitLevel2.id, house1.id);
    const floor2House1 = await this.createUnitForTemplate(project, isGerman ? 'EG' : 'G', 1, unitLevel2.id, house1.id);

    const floor1House2 = await this.createUnitForTemplate(project, isGerman ? 'OG' : '1', 0, unitLevel2.id, house2.id);
    const floor2House2 = await this.createUnitForTemplate(project, isGerman ? 'EG' : 'G', 1, unitLevel2.id, house2.id);

    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 04' : 'Apartment 04', 0, unitLevel3.id, floor1House1.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 05' : 'Apartment 05', 1, unitLevel3.id, floor1House1.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 06' : 'Apartment 06', 2, unitLevel3.id, floor1House1.id);

    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 01' : 'Apartment 01', 0, unitLevel3.id, floor2House1.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 02' : 'Apartment 02', 1, unitLevel3.id, floor2House1.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 03' : 'Apartment 03', 2, unitLevel3.id, floor2House1.id);

    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 04' : 'Apartment 04', 0, unitLevel3.id, floor1House2.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 05' : 'Apartment 05', 1, unitLevel3.id, floor1House2.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 06' : 'Apartment 06', 2, unitLevel3.id, floor1House2.id);

    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 01' : 'Apartment 01', 0, unitLevel3.id, floor2House2.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 02' : 'Apartment 02', 1, unitLevel3.id, floor2House2.id);
    await this.createUnitForTemplate(project, isGerman ? 'Wohnung 03' : 'Apartment 03', 2, unitLevel3.id, floor2House2.id);
  }

  async createLevelForTemplate(project: Project, index: number, name: string): Promise<UnitLevel> {
    const unitLevel: UnitLevel = {
      id: v4(),
      changedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      index,
      name,
      projectId: project.id,
    };
    return (await this.unitLevelDataService.insert(unitLevel, project.id))?.[0];
  }

  async createUnitForTemplate(project: Project, name: string, index: number, unitLevelId: IdType, parentId?: IdType): Promise<Unit> {
    const address: Address = {
      id: v4(),
      changedAt: new Date().toISOString(),
      clientId: project.clientId,
    };

    await this.addressDataService.insert(address, address.clientId);

    const unit: Unit = {
      addressId: address.id,
      id: v4(),
      name,
      changedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      index,
      unitLevelId,
      parentId
    };

    return (await this.unitDataService.insert(unit, project.id))?.[0];
  }

  async switchUnitPositions([unitA, unitB]: [Unit, Unit]) {
    if (!haveObjectsEqualProperties(unitA, unitB, ['unitLevelId', 'parentId'])) {
      throw new Error(`Cannot switch unit positions; units are not part of the same level and parent (${
        unitA.id
      }[level=${unitA.unitLevelId},parent=${unitA.parentId}]) vs (${
        unitB.id
      }[level=${unitB.unitLevelId},parent=${unitB.parentId}])`);
    }

    const unitsOfLevel = await observableToPromise(this.unitDataService.getByUnitLevel(unitA.unitLevelId));
    const unitsWithSameParent = unitsOfLevel.filter((unit) => haveObjectsEqualProperties(unit, unitA, ['parentId']));
    const indexOfA = unitsWithSameParent.findIndex((unit) => unit.id === unitA.id);
    const indexOfB = unitsWithSameParent.findIndex((unit) => unit.id === unitB.id);

    if (indexOfA === -1 || indexOfB === -1) {
      throw new Error(`switchUnitPositions - unitA (${unitA.id}) and/or unitB (${unitA.id}) not found in list of units.`);
    }

    unitsWithSameParent[indexOfA] = unitB;
    unitsWithSameParent[indexOfB] = unitA;

    const unitsToUpdate = this.ensureSuccessiveUniqueIndex(unitsWithSameParent);

    if (unitsToUpdate.length) {
      const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
      await this.unitDataService.update(unitsToUpdate, projectId);
    }
  }

  async updateAndEnsureSuccessiveUniqueIndex(unit: Unit) {
    const unitsOfLevel = await observableToPromise(this.unitDataService.getByUnitLevel(unit.unitLevelId));
    const unitsWithSameParent = unitsOfLevel.filter((u) => haveObjectsEqualProperties(u, unit, ['parentId']));
    const indexOfUnit = unitsWithSameParent.findIndex((u) => u.id === unit.id);

    if (indexOfUnit === -1) {
      throw new Error(`updateAndEnsureSuccessiveUniqueIndex - unit (${unit.id}) not found in list of units.`);
    }

    unitsWithSameParent[indexOfUnit] = unit;

    const unitsToUpdate = this.ensureSuccessiveUniqueIndex(unitsWithSameParent);

    const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    if (!unitsToUpdate.includes(unit)) {
      unitsToUpdate.push(unit);
    }
    await this.unitDataService.update(unitsToUpdate, projectId);
  }

  ensureSuccessiveUniqueIndex(units: Unit[]) {
    const unitsToUpdate = new Array<Unit>();

    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      if (unit.index !== i) {
        unit.index = i;
        unitsToUpdate.push(unit);
      }
    }

    return unitsToUpdate;
  }

  getEnsureIndexUpdatesForInsertWithPosition(unit: Unit, insertPosition?: CreateUnitInLevelEvent['insertPosition']): (storageData: Unit[]) => Unit[] {
    const updates = (storageData: Unit[]): Unit[] => {
      const unitsInLevelAndParent = orderBy(storageData
        .filter((u) => haveObjectsEqualProperties(u, unit, ['parentId', 'unitLevelId'])), ['index', 'name', 'id']);

      let indexOfNewUnit = unitsInLevelAndParent.findIndex((u) => u.id === unit.id);
      if (indexOfNewUnit === -1) {
        unitsInLevelAndParent.push(unit);
        indexOfNewUnit = unitsInLevelAndParent.length - 1;
      }

      const [newUnit] = unitsInLevelAndParent.splice(indexOfNewUnit, 1);
      if (!insertPosition) {
        unitsInLevelAndParent.push(newUnit);
      } else {
        const indexOfReferenceUnit = unitsInLevelAndParent.findIndex((u) => u.id === insertPosition.unit.id);
        if (indexOfReferenceUnit === -1) {
          unitsInLevelAndParent.push(newUnit);
        } else {
          // splice at `indexOfReferenceUnit` = insert above
          // splice at `indexOfReferenceUnit + 1` = insert below
          unitsInLevelAndParent.splice(indexOfReferenceUnit + (insertPosition.placement === 'below' ? 1 : 0), 0, newUnit);
        }
      }

      return this.ensureSuccessiveUniqueIndex(unitsInLevelAndParent);
    };

    return updates;
  }
  

  async duplicateUnit(unit: Unit) {
    const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    const allUnits = await observableToPromise(this.unitDataService.data);
    const unitsByParentId = groupBy(allUnits, 'parentId');
    const descendantUnits = this.getAllDescendantUnits(unit, unitsByParentId);
    const addressesById = await observableToPromise(this.addressDataService.dataGroupedById);

    const oldToNewId = new Map<IdType, IdType>();
    const unitsToInsert: Unit[] = [];
    const addressesToInsert: Address[] = [];

    const changedAt = new Date().toISOString();
    const createdAt = changedAt;

    const {unit: baseUnit, address: baseAddress} = this.getClonedUnitAndAddress(addressesById, oldToNewId, unit, changedAt, createdAt);

    const copySuffix = this.translateService.instant('copyWorkflow.copySuffix');
    if (baseUnit.name.length + copySuffix.length > 255) {
      baseUnit.name = baseUnit.name.slice(0, 255 - baseUnit.name.length - copySuffix.length);
    }
    baseUnit.name += copySuffix;
    baseUnit.index = 0; // Will be updated by `updates`
    unitsToInsert.push(baseUnit);
    if (baseAddress) {
      addressesToInsert.push(baseAddress);
    }

    for (const descendantUnit of descendantUnits) {
      const {unit: copiedUnit, address: copiedAddress} = this.getClonedUnitAndAddress(addressesById, oldToNewId, descendantUnit, changedAt, createdAt);
      unitsToInsert.push(copiedUnit);
      if (copiedAddress) {
        addressesToInsert.push(copiedAddress);
      }
    }

    const unitsToUpdate = this.getEnsureIndexUpdatesForInsertWithPosition(baseUnit, {unit, placement: 'below'});

    await this.addressDataService.insert(addressesToInsert);
    await this.unitDataService.insertUpdateDelete({
      inserts: unitsToInsert,
      updates: unitsToUpdate,
    }, projectId);
  }

  private getClonedUnitAndAddress(
    addressesById: Record<IdType, Address>,
    oldToNewId: Map<IdType, IdType>,
    sourceUnit: Unit,
    changedAt: string,
    createdAt: string
  ): {unit: Unit, address?: Address} {
    oldToNewId.set(sourceUnit.id, v4());
    if (sourceUnit.addressId) {
      oldToNewId.set(sourceUnit.addressId, v4());
    }

    const unit: Unit = {
      ...sourceUnit,
      parentId: oldToNewId.get(sourceUnit.parentId) ?? sourceUnit.parentId,
      id: oldToNewId.get(sourceUnit.id),
      changedAt,
      createdAt,
      addressId: sourceUnit.addressId ? oldToNewId.get(sourceUnit.addressId) : undefined,
    };

    if (!sourceUnit.addressId) {
      return {unit};
    }

    const sourceAddress = addressesById[sourceUnit.addressId];
    if (!sourceAddress) {
      throw new Error(`Inconsistent data; cannot find address with id ${sourceUnit.addressId} (source unit: ${sourceUnit.id})`);
    }

    const address: Address = {
      ...sourceAddress,
      changedAt,
      id: oldToNewId.get(sourceUnit.addressId)
    };

    return {unit, address};
  }

  private getAllDescendantUnits(unit: Unit, unitsByParentId: Record<IdType, Unit[]>): Unit[] {
    const childUnits = unitsByParentId[unit.id] ?? [];

    if (childUnits.length === 0) {
      return [];
    }

    return childUnits.concat(...childUnits.map((child) => this.getAllDescendantUnits(child, unitsByParentId)))
  }
}
