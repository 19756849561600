import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SyncStatusService} from '../../../services/sync/sync-status.service';
import {Observable} from 'rxjs';
import {LoggingService} from '../../../services/common/logging.service';
import {map} from 'rxjs/operators';
import {NetworkStatusService} from '../../../services/common/network-status.service';

const LOG_SOURCE = 'SyncStatusComponent';

@Component({
  selector: 'app-sync-status',
  templateUrl: './sync-status.component.html',
  styleUrls: ['./sync-status.component.scss'],
})
export class SyncStatusComponent implements OnInit, OnDestroy {
  @Input() public inverseColor = true;
  public dataSyncInProgressObservable: Observable<boolean>;
  public localChangesObservable: Observable<boolean>;
  public attachmentSyncInProgressObservable: Observable<boolean>;
  public attachmentUploadInProgressObservable: Observable<boolean>;
  public numberOfAttachmentsInUploadQueueObservable: Observable<number | undefined>;
  public numberOfAttachmentsInErrorUploadQueueObservable: Observable<number | undefined>;
  public online$: Observable<boolean | undefined>;

  constructor(private syncStatusService: SyncStatusService, private networkStatusService: NetworkStatusService, private loggingService: LoggingService) { }

  ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit called.');
    this.dataSyncInProgressObservable = this.syncStatusService.dataSyncInProgressObservable.pipe(
      map((dataSyncStatus) => dataSyncStatus.inProgress));
    this.attachmentSyncInProgressObservable = this.syncStatusService.attachmentSyncInProgressObservable.pipe(
      map((attachmentSyncStatus) => attachmentSyncStatus.inProgress));
    this.attachmentUploadInProgressObservable = this.syncStatusService.attachmentUploadInProgressObservable;
    this.numberOfAttachmentsInUploadQueueObservable = this.syncStatusService.numberOfAttachmentsInUploadQueueObservable;
    this.numberOfAttachmentsInErrorUploadQueueObservable = this.syncStatusService.numberOfAttachmentsInErrorUploadQueueObservable;
    this.localChangesObservable = this.syncStatusService.clientOrProjectsWithLocalChanges$.pipe(
      map((projectsWithLocalChanges) => projectsWithLocalChanges.size > 0));
    this.online$ = this.networkStatusService.online$;
  }

  ngOnDestroy(): void {
  }

}
