import {Injectable} from '@angular/core';
import {ProjectProtocolType, ProtocolType, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProjectDataService} from './project-data.service';
import {ProtocolTypeDataService} from './protocol-type-data.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareMappingDataService} from './abstract-project-aware-mapping-data.service';
import _ from 'lodash';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/projectProtocolTypes';

@Injectable({
  providedIn: 'root'
})
export class ProjectProtocolTypeDataService extends AbstractProjectAwareMappingDataService<ProjectProtocolType> {

  constructor(http: HttpClient,
              storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService,
              private protocolTypeService: ProtocolTypeDataService) {
    super(StorageKeyEnum.PROJECT_PROTOCOL_TYPE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

  getProjectProtocolTypes(): Observable<ProtocolType[]> {
    return combineLatest([this.data, this.protocolTypeService.dataWithoutHiddenActive$]).pipe(
      map(([projectProtocolTypes, protocolTypes]) =>
        protocolTypes.filter((protocolType) => projectProtocolTypes.find((projectProtocolType) => projectProtocolType.protocoltypeId === protocolType.id)))
    );
  }

  getByProtocolTypeAcrossProjects(protocolType: ProtocolType): Observable<ProjectProtocolType[]> {
    return this.dataByProjectId$.pipe(map((allProjectProtocolTypesMap) => _.flatten(Array.from(allProjectProtocolTypesMap.values()))
      .filter((projectProtocolType: ProjectProtocolType) => projectProtocolType.protocoltypeId === protocolType.id)));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
