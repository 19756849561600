import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-no-tasks',
  templateUrl: './no-tasks.component.html',
  styleUrls: ['./no-tasks.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    IonicModule,
    FontAwesomeModule,
    NgIf
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoTasksComponent {
  @Output()
  ctaClick = new EventEmitter<void>();
  @Input()
  showCta = true;
  @Input()
  errorMessage?: string;
}
