<app-cancel-apply-modal-frame
  [title]="pdfPlan.latestPdfPlanVersion.name"
  [applyLabel]="'button.save' | translate"
  [applyProps]="{
    disabled: !form?.get('description').valid || !form?.get('description').dirty
  }"
  (applyClick)="save()"
>
  <form class="omg-form" [formGroup]="form" action="#">
    <ion-list *ngIf="showContent">
      <ion-item lines="inset">
        <ion-textarea label="{{ 'project_room.pdf_plan_version_form.description' | translate }}" label-placement="floating" #textarea [autoGrow]="true" color="text-dark" formControlName="description" type="text"></ion-textarea>
      </ion-item>
    </ion-list>
  </form>
</app-cancel-apply-modal-frame>
