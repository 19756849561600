import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {IdType, PdfPlanVersionAccess, PdfPlanVersionAccessType, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import _ from 'lodash';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/pdfPlanVersionAccesses';

@Injectable({
  providedIn: 'root'
})
export class PdfPlanVersionAccessDataService extends AbstractProjectAwareDataService<PdfPlanVersionAccess>{

  constructor(http: HttpClient, storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.PDF_PLAN_VERSION_ACCESS, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
      integrityResolverService, VERSION_INTRODUCED_DEFAULT, ['pdfPlanVersionId', 'type', 'changedAt']);
  }

  getFirstByPlanVersionIdUserIdAndType$(pdfPlanVersionId: IdType, userId: IdType, type: PdfPlanVersionAccessType): Observable<PdfPlanVersionAccess|undefined> {
    return this.data.pipe(map((data) => _.first(data.filter((value) => value.pdfPlanVersionId === pdfPlanVersionId && value.userId === userId && value.type === type))));
  }

  getAllByPlanVersionId$(pdfPlanVersionId: IdType) {
    return this.data.pipe(map((data) => data.filter((item) => item.pdfPlanVersionId === pdfPlanVersionId)));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
