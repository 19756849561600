import {Injectable, OnDestroy} from '@angular/core';
import userflow from 'userflow.js';
import {UserDataService} from 'src/app/services/data/user-data.service';
import _ from 'lodash';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {Client, User} from 'submodules/baumaster-v2-common';
import {environment} from '../../../environments/environment';
import {LanguageService} from '../i18n/language.service';
import {Platform} from '@ionic/angular';
import {NavigationEnd, Router} from '@angular/router';
import {MEDIUM_SCREEN_SIZE} from '../../shared/constants';
import {Device, DeviceInfo} from '@capacitor/device';
import {LicenseService} from '../auth/license.service';
import {Breakpoints, DeviceService, DISPLAY_SIZE} from '../ui/device.service';
import {distinctUntilChanged} from 'rxjs/operators';
import {LoggingService} from '../common/logging.service';
import {convertErrorToMessage} from '../../shared/errors';
import {ClientService} from '../client/client.service';

const LOG_SOURCE = 'UserflowService';

const contentIdStart = '48cf015b-0a5e-421d-973c-f4dee3edc23e';
const introIdStart = '875dd7f2-016e-432d-8163-b2c49305a711';
type Orientation = 'portrait' | 'landscape';

@Injectable({
  providedIn: 'root'
})
export class UserflowService implements OnDestroy {
  private user: User|undefined;
  private client: Client|undefined;
  private lngAndOrientationSubscription: Subscription|undefined;
  private screenOrientationChangeSubject = new BehaviorSubject<Orientation>(this.getOrientation());
  private userflowEnabledForScreenSizeSubject = new BehaviorSubject<boolean>(this.isUserflowEnabledForScreenSize());
  private resizeSubscription: Subscription|undefined;
  private userRouterSubscription: Subscription|undefined;
  private readonly environmentVersion: string;
  private deviceInfo: DeviceInfo | undefined;

  constructor(private userDataService: UserDataService,
              private language: LanguageService,
              private platform: Platform,
              private router: Router,
              private licenseService: LicenseService,
              private deviceService: DeviceService,
              private loggingService: LoggingService,
              private clientService: ClientService) {
    this.environmentVersion = environment.version;
    Device.getInfo().then((deviceInfo) => this.deviceInfo = deviceInfo);
  }

  public async startUserflow() {
    try {
      this.resizeSubscription = this.platform.resize.subscribe(() => {
        try {
          const orientation = this.getOrientation();
          if (this.screenOrientationChangeSubject.getValue() === undefined || this.screenOrientationChangeSubject.getValue() !== orientation) {
            this.screenOrientationChangeSubject.next(orientation);
          }
          const enabledForScreenSize = this.isUserflowEnabledForScreenSize();
          if (this.userflowEnabledForScreenSizeSubject.getValue() === undefined || this.userflowEnabledForScreenSizeSubject.getValue() !== enabledForScreenSize) {
            this.userflowEnabledForScreenSizeSubject.next(enabledForScreenSize);
          }
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `startUserflow - resizeSubscription - subscribe - ${convertErrorToMessage(error)}`);
        }
      }, (error) => {
        this.loggingService.error(LOG_SOURCE, `startUserflow - resizeSubscription - observableError-  ${convertErrorToMessage(error)}`);
      });
      this.userRouterSubscription = combineLatest([this.userDataService.currentUser$, this.router.events, this.userflowEnabledForScreenSizeSubject.asObservable(), this.clientService.currentClient$])
        .subscribe(async ([currentUser, routerEvent, userflowEnabledForScreenSize, currentClient]) => {
          try {
            if (!userflowEnabledForScreenSize) {
              this.user = undefined;
              this.client = undefined;
              await userflow.reset();
              return;
            }
            if (routerEvent instanceof NavigationEnd) {
              if (!_.isEmpty(currentUser) && !_.isEmpty(currentClient)) {
                this.user = currentUser;
                this.client = currentClient;
                await this.initUserFlow(this.user, this.client);
              } else {
                this.user = undefined;
                this.client = undefined;
                await userflow.reset();
              }
            }
          } catch (error) {
            this.loggingService.error(LOG_SOURCE, `startUserflow - userRouterSubscription - subscribe - ${convertErrorToMessage(error)}`);
          }
        }, (error) => {
          this.loggingService.error(LOG_SOURCE, `startUserflow - userRouterSubscription - observableError- ${convertErrorToMessage(error)}`);
        });
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `startUserflow - ${convertErrorToMessage(error)}`);
    }
  }

  public async startUserflowIntro() {
    try {
      await this.startUserflowWithContent(introIdStart);
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `startUserflowIntro - ${convertErrorToMessage(error)}`);
    }
  }

  public async startUserflowWithContent(contentId = contentIdStart) {
    try {
      await userflow.start(contentId);
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `startUserflowWithContent - ${convertErrorToMessage(error)}`);
    }
  }

  public async endUserflow() {
    try {
      await this.ngOnDestroy();
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `endUserflow - ${convertErrorToMessage(error)}`);
    }
  }

  public isUserflowEnabledForScreenSize(): boolean {
    if (!this.platform?.width()) {
      return true;
    }
    return this.platform.width() >= MEDIUM_SCREEN_SIZE;
  }

  async ngOnDestroy(): Promise<void> {
    try {
      this.resizeUnsubscribe();
      this.userRouterUnsubscribe();
      this.lngAndOrientationAndMCSubscriptionUnsubscribe();
      await userflow.reset();
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `ngOnDestroy - ${convertErrorToMessage(error)}`);
    }
  }

  private async initUserFlow(user: User, client: Client) {
    userflow.init(environment.userFlowToken);
    const selectedLanguage = await this.language.getSelectedLanguage();
    await userflow.identify(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      signed_up_at: user.lastLogin,
      language: selectedLanguage,
      orientation: this.getOrientation(),
      version: this.environmentVersion,
      clientStatus: client.status,
      operatingSystem: this.deviceInfo?.operatingSystem || 'unknown',
      licenseType: this.user?.role ? this.licenseService.getReadableLicenseType(this.user.role) : undefined,
      multiColumn: this.platform.width() >= DISPLAY_SIZE[Breakpoints.xxl]
    });
    this.startObservingLanguageAndOrientationAndMultiColumn();
  }

  private startObservingLanguageAndOrientationAndMultiColumn() {
    this.lngAndOrientationAndMCSubscriptionUnsubscribe();
    this.lngAndOrientationSubscription = combineLatest([
      this.language.selectedLanguage,
      this.screenOrientationChangeSubject,
      this.deviceService.isAboveBreakpoint(Breakpoints.xxl).pipe(
        distinctUntilChanged()
      )
    ])
    .subscribe(([language, orientation, multiColumn]) => {
      userflow.updateUser({
        language,
        orientation,
        multiColumn,
      });
    });
  }

  private getOrientation(): Orientation {
    return this.platform.isPortrait() ? 'portrait' : 'landscape';
  }

  public async updateUserFlow(attributes: any): Promise<void> {
    try {
      await userflow.updateUser(attributes);
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `updateUserFlow - ${convertErrorToMessage(error)}`);
    }
  }

  private lngAndOrientationAndMCSubscriptionUnsubscribe() {
    if (this.lngAndOrientationSubscription) {
      this.lngAndOrientationSubscription.unsubscribe();
      this.lngAndOrientationSubscription = undefined;
    }
  }

  private resizeUnsubscribe() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
      this.resizeSubscription = undefined;
    }
  }

  private userRouterUnsubscribe() {
    if (this.userRouterSubscription) {
      this.userRouterSubscription.unsubscribe();
      this.userRouterSubscription = undefined;
    }
  }

}
