<app-main-header
  [withoutHeaderShadow]="true"
  [title]="title | translate"
  [showBackButton]="isSubPage || (!(splitPaneService.isVisible$ | async) && !isSelector) && (defaultPage !== 'project-room')"
  [defaultPage]="defaultPage"
>
  <ng-template #searchbarTemplate>
    <ion-searchbar mode="md" #autofocus class="omg pl-4 slim-searchbar" [placeholder]="'search' | translate" (ionInput)="handleSearchChange($event)" [ngModel]="search"></ion-searchbar>
  </ng-template>
  <ion-grid class="ion-no-padding subheader-grid" [class.ion-hide]="isBimVersionPage || isBimMigrateVersionPage" [class.is-pdf-plan-folder-edit-mode]="isPdfPlanFolderPage && (isPdfPlanHolderEditMode$ | async)">
    <ion-row>
      <app-project-selector-col class="d-flex ion-align-items-center" [class.pdf-plan-folder-page]="isPdfPlanFolderPage" [class.ion-hide-md-down]="isPdfPlanFolderPage">
        <app-project-selector></app-project-selector>
      </app-project-selector-col>
      <ion-col size-xs="12" size-sm *ngIf="(splitPaneService.isVisible$ | async) || !isSelector" class="no-padding-bottom pl-2">
        <ng-container *ngIf="!isPageWithoutSearchbar && !isPdfPlanFolderPage">
          <ng-container *ngTemplateOutlet="searchbarTemplate"></ng-container>
        </ng-container>
        <app-pdf-plan-folder-toolbar *ngIf="isPdfPlanFolderPage" [search]="search" [searchbarTemplate]="searchbarTemplate" (searchFocus)="searchbar?.setFocus()"></app-pdf-plan-folder-toolbar>
      </ion-col>
    </ion-row>
  </ion-grid>
</app-main-header>

<ion-content>
  <ion-split-pane
    when="lg"
    contentId="selector"
    (ionSplitPaneVisible)="handleSplitPaneChange($event)"
    [disabled]="isSplitScreenDisabled$ | async"
  >
    <ion-content class="selector-list split-pane-side" color="omg-sidebar-bg">
      <app-project-room-selector-list></app-project-room-selector-list>
    </ion-content>

    <ion-router-outlet id="selector"></ion-router-outlet>
  </ion-split-pane>
</ion-content>

<app-tab-menu></app-tab-menu>
