import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-no-filtered-tasks',
  templateUrl: './no-filtered-tasks.component.html',
  styleUrls: ['./no-filtered-tasks.component.scss'],
  standalone: true,
  imports: [TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoFilteredTasksComponent {}
