import {ColumnConfig} from '../ColumnConfig';
import {EmployeeColumnSet} from './EmployeeColumnSet';

const ClientIdNameUniq = ['client_id', 'name'];

export const AdditionalPayTypeColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'name', uniqueWith: [ClientIdNameUniq]},
  {name: 'client_id', prop: 'clientId', uniqueWith: [ClientIdNameUniq]},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'employees', fk: 'additionalPayTypeId', mappedBy: EmployeeColumnSet, transient: true},
];
