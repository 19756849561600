import {Injectable, OnDestroy} from '@angular/core';
import {INIT_AFTER_APP_START_DELAY_IN_MS, StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {Subscription} from 'rxjs';
import {SyncStrategy} from './sync-utils';
import {DataSyncStatus, SyncStatusService} from './sync-status.service';
import {SyncOptions, SyncService} from './sync.service';
import _ from 'lodash';
import {NetworkStatusService} from '../common/network-status.service';
import {StorageService} from '../storage.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {ProjectDataService} from '../data/project-data.service';
import {DataServiceFactoryService} from '../data/data-service-factory.service';
import {filter, shareReplay, skipUntil, switchMap, throttleTime} from 'rxjs/operators';
import {ClientService} from '../client/client.service';
import {convertErrorToMessage} from '../../shared/errors';
import {observableToPromise} from '../../utils/observable-to-promise';
import {SystemEventService} from '../event/system-event.service';

const LOG_SOURCE = 'SyncSchedulerService';
const STORAGE_KEY = StorageKeyEnum.SYNC_SCHEDULER;
const SYNC_INTERVALS_IN_MS: {[key in SyncStrategy]: number|null} = {
  [SyncStrategy.AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE]: 24 * 60 * 60 * 1000,
  [SyncStrategy.DOWNLOADED_AND_PROJECTS_WITH_CHANGES]: 30 * 60 * 1000,
  [SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES]: 5 * 60 * 1000,
  [SyncStrategy.PROJECTS_WITH_CHANGES]: 2 * 60 * 1000
};
const IGNORE_SUBSEQUENT_VALUES_IN_MS = 60 * 1000;

interface LastSyncCompletion {
  syncStrategy: SyncStrategy;
  lastCompleteDate: string;
}

@Injectable({
  providedIn: 'root'
})
export class SyncSchedulerService implements OnDestroy {
  private scheduledNextSyncAfterSyncComplete: SyncStrategy|null = null;
  private dataSyncStatus: DataSyncStatus|undefined;
  private readonly timeoutIds = new Map<SyncStrategy, number>();

  private authenticationSubscription: Subscription;
  private dataSyncInProgressSubscription: Subscription;
  private networkStatusSubscription: Subscription;
  private isAuthenticated: boolean;

  private projectAvailabilitySubscription: Subscription|undefined;

  constructor(private storage: StorageService, private authenticationService: AuthenticationService, private loggingService: LoggingService,
              private syncService: SyncService, private syncStatusService: SyncStatusService, private networkStatusService: NetworkStatusService,
              private projectAvailabilityExpirationService: ProjectAvailabilityExpirationService, private projectDataService: ProjectDataService,
              private dataServiceFactoryService: DataServiceFactoryService, private clientService: ClientService, private systemEventService: SystemEventService) {
    this.loggingService.debug(LOG_SOURCE, 'constructor called.');
    this.authenticationSubscription = this.authenticationService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      this.loggingService.debug(LOG_SOURCE, 'authenticationService subscribed.');
      this.isAuthenticated = isAuthenticated;
      if (!isAuthenticated) {
        try {
          this.loggingService.info(LOG_SOURCE, `no user Authenticated. calling stopScheduler...`);
          this.stopScheduler();
          this.loggingService.info(LOG_SOURCE, `no user Authenticated. calling clearStorageData...`);
          await this.clearStorageData();
          this.loggingService.info(LOG_SOURCE, `no user Authenticated. clearStorageData called.`);
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `No user authenticated. ${convertErrorToMessage(error)}`);
        }
      } else {
        try {
          this.loggingService.info(LOG_SOURCE, `User Authenticated. calling startScheduler.`);
          await this.startSchedulerWithDelay();
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `User authenticated. Error starting scheduler${convertErrorToMessage(error)}`);
        }
      }
    });
    this.dataSyncInProgressSubscription = this.syncStatusService.dataSyncInProgressObservable.subscribe(async (dataSyncStatus) => {
      this.dataSyncStatus = dataSyncStatus;
      if (!dataSyncStatus.inProgress && dataSyncStatus.syncStrategy !== undefined && this.isAuthenticated) {
        await this.handleSyncComplete(dataSyncStatus.syncStrategy);
      }
      if (this.isAuthenticated && !dataSyncStatus.inProgress && this.scheduledNextSyncAfterSyncComplete !== null) {
        this.loggingService.info(LOG_SOURCE, `Previous Sync complete. Starting scheduledNextSyncAfterSyncComplete ${this.scheduledNextSyncAfterSyncComplete}`);
        const syncStrategy = this.scheduledNextSyncAfterSyncComplete;
        this.scheduledNextSyncAfterSyncComplete = null;
        await this.startSync(syncStrategy);
      }
    });
    this.networkStatusSubscription = this.networkStatusService.networkConnectedChangedObservable.subscribe(async (networkConnected) => {
      this.loggingService.info(LOG_SOURCE, `Network status changed (connected = ${networkConnected}).`);
      if (networkConnected && this.isAuthenticated) {
        await this.startScheduler();
      }
      if (networkConnected === false) {
        this.stopScheduler();
      }
    });
  }

  public async startScheduler() {
    this.loggingService.debug(LOG_SOURCE, 'startScheduler called.');
    await this.startOrResetScheduler();
    this.startListeningToAvailableProjectIds();
  }

  public async startSchedulerWithDelay(delayInMs = INIT_AFTER_APP_START_DELAY_IN_MS) {
    this.loggingService.debug(LOG_SOURCE, `startSchedulerWithDelay(${delayInMs}) called.`);
    const delayPromise = new Promise<void>((resolve) => setTimeout(resolve, delayInMs));
    await delayPromise;
    await this.startOrResetScheduler();
    this.startListeningToAvailableProjectIds();
  }

  public stopScheduler() {
    this.loggingService.debug(LOG_SOURCE, 'stopScheduler called.');
    this.clearTimeouts();
    this.stopListeningToAvailableProjectIds();
  }

  private async startOrResetScheduler(syncCompletions?: Array<LastSyncCompletion>) {
    this.loggingService.debug(LOG_SOURCE, 'startOrResetScheduler called.');
    if (!syncCompletions) {
      syncCompletions = await this.loadFromStorage();
    }
    const nextRunTimes = this.calculateNextRunTimes(syncCompletions);

    const syncStrategies: Array<SyncStrategy> = _.keys(nextRunTimes).map((key) => _.toNumber(key));
    this.clearTimeouts();
    let earliestNextRunTime: number|undefined;
    for (const syncStrategy of syncStrategies) {
      const nextRunTime = nextRunTimes[syncStrategy];
      if (nextRunTime === null) {
        continue;
      }
      if (earliestNextRunTime === undefined || earliestNextRunTime > nextRunTime) {
        earliestNextRunTime = nextRunTime;
        const timeout = Math.max(0, nextRunTime - new Date().getTime());
        const timeoutId = window.setTimeout(() => this.startOrScheduleSync(syncStrategy), timeout);
        this.timeoutIds.set(syncStrategy, timeoutId);
        this.loggingService.info(LOG_SOURCE, `next scheduled sync of ${syncStrategy} in ${timeout} ms resp. at ${new Date(nextRunTime).toISOString()}`);
      } else {
        this.loggingService.debug(LOG_SOURCE,
          `Skipping setTimeout (${new Date(nextRunTime)}) for ${syncStrategy} because higher rated syncStrategy is scheduled earlier ${new Date(earliestNextRunTime)}.`);
      }
    }
  }

  private startListeningToAvailableProjectIds() {
    this.loggingService.info(LOG_SOURCE, 'startListeningToAvailableProjectIds called.');

    const ownClientStorageInitialized$ = this.clientService.ownClient$
      .pipe(shareReplay(1))
      .pipe((filter((ownClient) => !!ownClient)))
      .pipe(switchMap((ownClient) => this.dataServiceFactoryService.isClientAwareStorageDataInitialized$(ownClient.id)
        .pipe(shareReplay(1))
      ))
      .pipe(filter((isClientAwareStorageDataInitialized) => isClientAwareStorageDataInitialized));

    this.projectAvailabilitySubscription?.unsubscribe();
    this.projectAvailabilitySubscription = this.projectAvailabilityExpirationService.notExpiredProjectIds$
      .pipe(skipUntil(ownClientStorageInitialized$))
      .pipe(filter((notExpiredProjectIds) => !!notExpiredProjectIds))
      .pipe(throttleTime(IGNORE_SUBSEQUENT_VALUES_IN_MS, undefined, {leading: true, trailing: true}))
      .subscribe(async (notExpiredProjectIds) => {
        this.loggingService.info(LOG_SOURCE, `startListeningToAvailableProjectIds - projectAvailabilityExpirationService.notExpiredProjectIds - ${notExpiredProjectIds}`);
        try {
          await this.syncService.removeExpiredUserDeviceOfflineProjects(notExpiredProjectIds);
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `projectAvailabilityExpirationService.notExpiredProjectIds$ - removeExpiredUserDeviceOfflineProjects failed. ${convertErrorToMessage(error)}`);
          this.systemEventService.logErrorEvent(LOG_SOURCE + 'projectAvailabilityExpirationService.notExpiredProjectIds$', error);
        }
    });
  }

  private stopListeningToAvailableProjectIds() {
    this.loggingService.debug(LOG_SOURCE, 'stopListeningToAvailableProjectIds called.');
    this.projectAvailabilitySubscription?.unsubscribe();
    this.projectAvailabilitySubscription = undefined;
  }

  private clearTimeouts() {
    this.loggingService.debug(LOG_SOURCE, 'clearTimeouts called.');
    this.timeoutIds.forEach(((value, key) => clearTimeout(value)));
    this.timeoutIds.clear();
  }

  private initLastSyncCompletions(): Array<LastSyncCompletion> {
    this.loggingService.debug(LOG_SOURCE, 'initLastSyncCompletions called.');
    const bufferInMs = 30 * 1000;
    const nowTime = new Date().getTime();
    const nowString = new Date().toISOString();
    const allProjectsTime = nowTime - SYNC_INTERVALS_IN_MS[SyncStrategy.AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE] - bufferInMs;
    const allProjectsDate = new Date(allProjectsTime);
    this.loggingService.debug(LOG_SOURCE, `initLastSyncCompletions - allProjectsDate=${allProjectsDate}`);
    return [
      {syncStrategy: SyncStrategy.AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE, lastCompleteDate: allProjectsDate.toISOString()},
      {syncStrategy: SyncStrategy.DOWNLOADED_AND_PROJECTS_WITH_CHANGES, lastCompleteDate: nowString},
      {syncStrategy: SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES, lastCompleteDate: nowString},
      {syncStrategy: SyncStrategy.PROJECTS_WITH_CHANGES, lastCompleteDate: nowString},
    ] as Array<LastSyncCompletion>;
  }

  private calculateNextRunTimes(syncCompletions: Array<LastSyncCompletion>): {[key in SyncStrategy]: number|null} {
    this.loggingService.debug(LOG_SOURCE, 'calculateNextRunTimes called. syncCompletions.length=' + syncCompletions.length);
    const syncCompletionBySyncStrategy = this.syncCompletionsToMap(syncCompletions);
    const syncStrategies: Array<SyncStrategy> = _.keys(SYNC_INTERVALS_IN_MS).map((key) => _.toNumber(key));
    const nextRunTimes = {} as {[key in SyncStrategy]: number};
    for (const syncStrategy of syncStrategies) {
      const interval = SYNC_INTERVALS_IN_MS[syncStrategy];
      if (interval === null) {
        nextRunTimes[syncStrategy] = null;
        continue;
      }
      const lastSyncCompletion = syncCompletionBySyncStrategy.get(syncStrategy);
      const nextRunTime = !lastSyncCompletion ? new Date().getTime() : Math.max(new Date().getTime(), new Date(lastSyncCompletion.lastCompleteDate).getTime() + interval);
      nextRunTimes[syncStrategy] = nextRunTime;
    }
    return nextRunTimes;
  }

  private async startOrScheduleSync(syncStrategy: SyncStrategy): Promise<boolean> {
    this.loggingService.debug(LOG_SOURCE, 'startOrScheduleSync called.');
    if (!this.isAuthenticated) {
      this.loggingService.info(LOG_SOURCE, 'Cannot start sync because user is not authenticated.');
      return false;
    }
    if (this.dataSyncStatus?.inProgress) {
      this.loggingService.debug(LOG_SOURCE, 'startOrScheduleSync - sync already in progress.');
      const syncStrategyInProgress = this.dataSyncStatus.syncStrategy;
      const syncStrategyToSchedule = syncStrategyInProgress <= syncStrategy ? SyncStrategy.PROJECTS_WITH_CHANGES : syncStrategy;
      this.loggingService.debug(LOG_SOURCE, `startOrScheduleSync- dataSync with strategy ${this.dataSyncStatus?.syncStrategy} already in progress. ` +
        `Requested syncStrategy is ${syncStrategy}, syncStrategyToSchedule is ${syncStrategyToSchedule}`);
      if (!this.scheduledNextSyncAfterSyncComplete || this.scheduledNextSyncAfterSyncComplete < syncStrategyToSchedule) {
        this.loggingService.info(LOG_SOURCE,
          `startOrScheduleSync- Scheduling sync ${syncStrategyToSchedule} after syncComplete. scheduledNextSyncAfterSyncComplete was ${this.scheduledNextSyncAfterSyncComplete}`);
        this.scheduledNextSyncAfterSyncComplete = syncStrategyToSchedule;
      } else {
        this.loggingService.info(LOG_SOURCE,
          `startOrScheduleSync - Ignoring requested ${syncStrategyToSchedule} because the higher ordered sync ${this.scheduledNextSyncAfterSyncComplete} is already scheduled.`);
      }
      return false;
    }
    await this.startSync(syncStrategy);
    return true;
  }

  private async startSync(syncStrategy: SyncStrategy) {
    this.loggingService.info(LOG_SOURCE, `startSync called. syncStrategy is ${syncStrategy}`);
    if (syncStrategy === SyncStrategy.AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE) {
      const currentProjectId = (await this.projectDataService.getCurrentProject())?.id;
      if (currentProjectId) {
        const activeProjects = await observableToPromise(this.projectDataService.dataAcrossClientsActive$);
        await this.projectAvailabilityExpirationService.storeProjectExpirationDateAndInit(currentProjectId, activeProjects.map((p) => p.id));
      }
    }
    let options: SyncOptions|undefined;
    if (syncStrategy === SyncStrategy.AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE) {
      options = {forceReadingAttachmentFileInfo: true};
    }
    await this.syncService.startSync(syncStrategy, options);
  }

  private async handleSyncComplete(syncStrategy: SyncStrategy): Promise<Array<LastSyncCompletion>> {
    this.loggingService.debug(LOG_SOURCE, 'handleSyncComplete called.');
    const syncCompletions = await this.writeSyncCompleteInStorage(syncStrategy);
    await this.startOrResetScheduler(syncCompletions);
    return syncCompletions;
  }

  private async loadFromStorage(): Promise<Array<LastSyncCompletion>> {
    this.loggingService.debug(LOG_SOURCE, 'loadFromStorage called.');
    let lastSyncCompletions: Array<LastSyncCompletion>|null = await this.storage.get(STORAGE_KEY);

    if (!lastSyncCompletions || lastSyncCompletions.length === 0) {
      lastSyncCompletions = this.initLastSyncCompletions();
      await this.storage.set(STORAGE_KEY, lastSyncCompletions);
    }

    return lastSyncCompletions;
  }

  private syncCompletionsToMap(syncCompletions: Array<LastSyncCompletion>): Map<SyncStrategy, LastSyncCompletion> {
    this.loggingService.debug(LOG_SOURCE, 'syncCompletionsToMap called.');
    const map = new Map<SyncStrategy, LastSyncCompletion>();
    syncCompletions.forEach((syncCompletion) => map.set(syncCompletion.syncStrategy, syncCompletion));
    return map;
  }

  private async writeSyncCompleteInStorage(syncStrategy: SyncStrategy): Promise<Array<LastSyncCompletion>> {
    this.loggingService.debug(LOG_SOURCE, 'writeSyncCompleteInStorage called.');
    const syncCompletions = await this.loadFromStorage();
    const newSyncCompletion: LastSyncCompletion = {
      syncStrategy,
      lastCompleteDate: new Date().toISOString()
    };
    const index = syncCompletions.findIndex((syncCompletion) => syncCompletion.syncStrategy === syncStrategy);
    if (index === -1) {
      syncCompletions.push(newSyncCompletion);
    } else {
      syncCompletions[index] = newSyncCompletion;
    }
    this.assertAuthenticated();
    await this.storage.set(STORAGE_KEY, syncCompletions);
    return syncCompletions;
  }

  private async clearStorageData() {
    this.loggingService.debug(LOG_SOURCE, 'clearStorageData called.');
    await this.storage.remove(STORAGE_KEY);
  }

  ngOnDestroy(): void {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called.');
    this.stopScheduler();
    this.authenticationSubscription.unsubscribe();
    this.dataSyncInProgressSubscription.unsubscribe();
    this.networkStatusSubscription.unsubscribe();
  }

  protected assertAuthenticated() {
    if (!this.isAuthenticated) {
      throw new Error('User not authenticated (auth is null or undefined)');
    }
  }

}
