import { ColumnConfig } from '../ColumnConfig';

export const UserConnectionInviteColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'email', prop: 'email', cnd: true},
  {name: 'client_id', prop: 'clientId', cnd: true},
  {name: 'profile_id', prop: 'profileId', cnd: true},
  {name: 'language'},
  {name: 'project_id', prop: 'projectId', def: null},
  {name: 'valid_until', prop: 'validUntil', cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
