import { ColumnConfig } from '../ColumnConfig';
import { ActivityColumnSet } from './ActivityColumnSet';
import { EmployeeColumnSet } from './EmployeeColumnSet';
import { EquipmentColumnSet } from './EquipmentColumnSet';
import { MaterialColumnSet } from './MaterialColumnSet';
import { ParticipantColumnSet } from './ParticipantColumnSet';
import { PdfPreviewColumnSet } from './PdfPreviewColumnSet';
import { ReportCompanyColumnSet } from './ReportCompanyColumnSet';
import { StaffColumnSet } from './StaffColumnSet';
import {AttachmentReportSignatureColumnSet} from './AttachmentReportSignatureColumnSet';

export const ReportColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'title', prop: 'title', def: null},
  {name: 'closed_at', prop: 'closedAt', cast: 'Timestamp', def: null},
  {name: 'weather', prop: 'weather', def: null},
  {name: 'report_number', prop: 'reportNumber'},
  {name: 'date', prop: 'date', cast: 'Date'},
  {name: 'min_temp', prop: 'minTemp', def: null},
  {name: 'max_temp', prop: 'maxTemp', def: null},
  {name: 'humidity', prop: 'humidity', def: null},
  {name: 'windspeed', prop: 'windspeed', def: null},
  {name: 'weather_from_api', prop: 'weatherFromApi', def: null},
  {name: 'has_damage', prop: 'hasDamage'},
  {name: 'additional_info', prop: 'additionalInfo', def: null},
  {name: 'internal_number', prop: 'internalNumber', def: null},
  {name: 'dependency_link', prop: 'dependencyLink', def: null},
  {name: 'refusal_note', prop: 'refusalNote', def: null},
  {name: 'in_review', prop: 'inReview'},
  {name: 'internal_notice', prop: 'internalNotice', def: null},
  {name: 'working_time_1_from', prop: 'workingTime1From', cast: 'Time', def: null},
  {name: 'working_time_1_to', prop: 'workingTime1To', cast: 'Time', def: null},
  {name: 'working_time_2_from', prop: 'workingTime2From', cast: 'Time', def: null},
  {name: 'working_time_2_to', prop: 'workingTime2To', cast: 'Time', def: null},
  {name: 'bad_weather_time_1_from', prop: 'badWeatherTime1From', cast: 'Time', def: null},
  {name: 'bad_weather_time_1_to', prop: 'badWeatherTime1To', cast: 'Time', def: null},
  {name: 'bad_weather_time_2_from', prop: 'badWeatherTime2From', cast: 'Time', def: null},
  {name: 'bad_weather_time_2_to', prop: 'badWeatherTime2To', cast: 'Time', def: null},
  {name: 'craft_id', prop: 'craftId', def: null},
  {name: 'editor_id', prop: 'editorId', def: null},
  {name: 'employer_id', prop: 'employerId', def: null},
  {name: 'report_week_id', prop: 'reportWeekId'},
  {name: 'created_by_id', prop: 'createdById', def: null},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp', def: null},
  {name: 'created_at_db', prop: 'createdAtDb', cnd: true, cast: 'Timestamp', def: null},
  {name: 'participants', fk: 'reportId', mappedBy: ParticipantColumnSet, transient: true},
  {name: 'pdfPreviews', fk: 'reportId', mappedBy: PdfPreviewColumnSet, transient: true},
  {name: 'activities', fk: 'reportId', mappedBy: ActivityColumnSet, transient: true},
  {name: 'equipments', fk: 'reportId', mappedBy: EquipmentColumnSet, transient: true},
  {name: 'materials', fk: 'reportId', mappedBy: MaterialColumnSet, transient: true},
  {name: 'reportCompanies', fk: 'reportId', mappedBy: ReportCompanyColumnSet, transient: true},
  {name: 'staffs', fk: 'reportId', mappedBy: StaffColumnSet, transient: true},
  {name: 'employees', fk: 'reportId', mappedBy: EmployeeColumnSet, transient: true},
  {name: 'attachmentReportSignatures', fk: 'reportId', mappedBy: AttachmentReportSignatureColumnSet, transient: true}
];
