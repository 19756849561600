import {ColumnConfig} from '../ColumnConfig';

export const ProtocolFieldHistColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'action', prop: 'action'},
  {name: 'protocol_id', prop: 'protocolId'},
  {name: 'column_name', prop: 'columnName'},
  {name: 'value_old', prop: 'valueOld', def: null},
  {name: 'value_new', prop: 'valueNew', def: null},
  {name: 'changed_by_id', prop: 'changedById'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'}
];
