<div class="fixed-header-container ion-padding-start ion-padding-end" *ngIf="!hideHeader">
  <ion-searchbar #autofocus [(ngModel)]="searchTextInput" (ngModelChange)="searchEmployee()" debounce="100"
                 placeholder="{{'sendProtocol.mailingList.searchName' | translate}}"></ion-searchbar>
  <ion-grid [ngClass]="{'ion-margin-end': this.platform.is('desktop')}">
    <ion-row>
      <ion-col size="{{workflowType === WorkflowTypeEnum.Report ? 10 : 9}}">
      </ion-col>
      <ion-col size="{{workflowType === WorkflowTypeEnum.Report ? 2 : 3}}" class="size-xs text-secondary" [ngClass]="{'ion-text-center': workflowType !== WorkflowTypeEnum.Report}">
          <ion-label>{{'sendProtocol.mailingList.recipient' | translate}}</ion-label>
          <ion-label *ngIf="showParticipantCheckbox()">/{{'sendProtocol.mailingList.participant' | translate}}</ion-label>
          <ion-label *ngIf="pdfPreview">/{{'sendProtocol.mailingList.seen' | translate}}</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<div [class.content-container]="!hideHeader">
  <ion-grid class="group-header ion-no-padding" *ngIf="isUnitsEnabled && !isUnitSectionEmpty">
    <ion-row>
      <ion-col size="10">
        <ion-label class="omg-modal-section-header margin-left"><strong>{{'sendProtocol.mailingList.unitSection' | translate}}</strong></ion-label>
      </ion-col>
      <ion-col *ngIf="!hideSelectAllButtons" size="2" class="ion-no-padding d-flex ion-justify-content-end">
        <div class="checkbox-container" [ngClass]="{'cursor-pointer': !readOnly}" (click)="checkAllPresentOrRecipient(!allCheckedRecipientUnit, 'recipient', 'unit')" *ngIf="!searchTextInput">
          <ion-checkbox class="omg-checkbox cursor-pointer ion-no-padding ion-no-margin" [checked]="allCheckedRecipientUnit"></ion-checkbox>
        </div>
        <div class="checkbox-container" [ngClass]="{'cursor-pointer': !readOnly}" (click)="checkAllPresentOrRecipient(!allCheckedPresentUnit, 'present', 'unit')" *ngIf="!searchTextInput && showParticipantCheckbox()">
          <ion-checkbox class="omg-checkbox cursor-pointer ion-no-padding ion-no-margin" [checked]="allCheckedPresentUnit"></ion-checkbox>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="isUnitsEnabled && !isUnitSectionEmpty">
    <ng-template *ngFor="let mailingListCompany of filteredMailingListCompanies" [ngIf]="mailingListCompany.unitContacts?.length">
      <app-pdf-mailing-list-table [readOnly]="readOnly" [showSeen]="!!pdfPreview" [company]="mailingListCompany.unitPdfMailingListCompany ?? mailingListCompany"
                                  [showCompanyPin]="showCompanyPin"
                                  [isSearchMode]="searchTextInput | isEmpty:'reverse'"
                                  [employees]="mailingListCompany.unitContacts"
                                  (presentChanged)="onPresentChanged($event.employee)"
                                  (recipientChanged)="onRecipientChanged($event.employee)"
                                  [showMailingList]="showParticipantCheckbox()"
      ></app-pdf-mailing-list-table>
    </ng-template>
  </ion-grid>

  <ion-grid class="group-header ion-no-padding" *ngIf="!isPreSelectedSectionEmpty">
    <ion-row>
      <ion-col size="10">
        <ion-label class="omg-modal-section-header margin-left"><strong *ngIf="!isPreSelectedSectionEmpty">{{'sendProtocol.mailingList.preselection' | translate}}</strong></ion-label>
      </ion-col>
      <ion-col *ngIf="!hideSelectAllButtons" size="2" class="ion-no-padding d-flex ion-justify-content-end">
        <div class="checkbox-container" [ngClass]="{'cursor-pointer': !readOnly}" (click)="checkAllPresentOrRecipient(!allCheckedRecipient, 'recipient')" *ngIf="!searchTextInput">
         <ion-checkbox class="omg-checkbox cursor-pointer ion-no-padding ion-no-margin" [checked]="allCheckedRecipient"></ion-checkbox>
        </div>
        <div class="checkbox-container" [ngClass]="{'cursor-pointer': !readOnly}" (click)="checkAllPresentOrRecipient(!allCheckedPresent, 'present')" *ngIf="!searchTextInput && showParticipantCheckbox()">
         <ion-checkbox class="omg-checkbox cursor-pointer ion-no-padding ion-no-margin" [checked]="allCheckedPresent"></ion-checkbox>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="!isPreSelectedSectionEmpty">
    <ng-template *ngFor="let mailingListCompany of filteredMailingListCompanies" [ngIf]="mailingListCompany.preSelectedEmployees.length">
      <app-pdf-mailing-list-table [readOnly]="readOnly" [showSeen]="!!pdfPreview" [company]="mailingListCompany"
                                  [showCompanyPin]="showCompanyPin"
                                  [isSearchMode]="searchTextInput | isEmpty:'reverse'"
                                  [employees]="mailingListCompany.preSelectedEmployees"
                                  (presentChanged)="onPresentChanged($event.employee)"
                                  (recipientChanged)="onRecipientChanged($event.employee)"
                                  [showMailingList]="showParticipantCheckbox()"
      ></app-pdf-mailing-list-table>
    </ng-template>
  </ion-grid>

  <ion-grid class="group-header ion-no-padding" *ngIf="!isAssignedEmployeeSectionEmpty && showAssignedInProject">
    <ion-row>
      <ion-col size="10">
        <ion-label class="omg-modal-section-header margin-left" *ngIf="workflowType === WorkflowTypeEnum.Protocol"><strong>{{'sendProtocol.mailingList.assignedInProtocol' | translate}}</strong></ion-label>
        <ion-label class="omg-modal-section-header margin-left" *ngIf="workflowType === WorkflowTypeEnum.GlobalSearch || workflowType === WorkflowTypeEnum.EntryMail"><strong>{{'sendProtocol.mailingList.assignedInProtocolEntries' | translate}}</strong></ion-label>
        <ion-label class="omg-modal-section-header margin-left" *ngIf="workflowType === WorkflowTypeEnum.Report"><strong>{{'sendProtocol.mailingList.assignedInSheet' | translate}}</strong></ion-label>
      </ion-col>
      <ion-col *ngIf="!hideSelectAllButtons" size="2" class="ion-no-padding d-flex ion-justify-content-end">
        <div class="checkbox-container" [ngClass]="{'cursor-pointer': !readOnly}" (click)="checkAllPresentOrRecipient(!allCheckedRecipientAssigned, 'recipient', 'assigned')" *ngIf="!searchTextInput">
         <ion-checkbox class="omg-checkbox cursor-pointer ion-no-padding ion-no-margin" [checked]="allCheckedRecipientAssigned"></ion-checkbox>
        </div>
        <div class="checkbox-container" [ngClass]="{'cursor-pointer': !readOnly}" (click)="checkAllPresentOrRecipient(!allCheckedPresentAssigned, 'present', 'assigned')" *ngIf="!searchTextInput && showParticipantCheckbox()">
         <ion-checkbox class="omg-checkbox cursor-pointer ion-no-padding ion-no-margin" [checked]="allCheckedPresentAssigned"></ion-checkbox>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid>
    <ng-template *ngFor="let mailingListCompany of filteredMailingListCompanies" [ngIf]="mailingListCompany.assignedEmployees.length && showAssignedInProject">
      <app-pdf-mailing-list-table [readOnly]="readOnly" [showSeen]="!!pdfPreview" [company]="mailingListCompany"
                                  [showCompanyPin]="showCompanyPin"
                                  [isSearchMode]="searchTextInput | isEmpty:'reverse'"
                                  [employees]="mailingListCompany.assignedEmployees"
                                  (presentChanged)="onPresentChanged($event.employee)"
                                  (recipientChanged)="onRecipientChanged($event.employee)"
                                  [showMailingList]="showParticipantCheckbox()"
      ></app-pdf-mailing-list-table>
    </ng-template>
  </ion-grid>

  <ion-grid class="group-header ion-no-padding" *ngIf="!isProjectTeamSectionEmpty && showProjectTeam">
    <ion-row>
      <ion-col size="10">
        <ion-label class="omg-modal-section-header margin-left"><strong>{{'sendProtocol.mailingList.projectTeam' | translate}}</strong></ion-label>
      </ion-col>
      <ion-col *ngIf="!hideSelectAllButtons" size="2" class="ion-no-padding d-flex ion-justify-content-end">
        <div class="checkbox-container" [ngClass]="{'cursor-pointer': !readOnly}" (click)="checkAllPresentOrRecipient(!allCheckedRecipientUnassigned, 'recipient', 'unassigned')" *ngIf="!searchTextInput">
         <ion-checkbox class="omg-checkbox cursor-pointer ion-no-padding ion-no-margin" [checked]="allCheckedRecipientUnassigned"></ion-checkbox>
        </div>
        <div class="checkbox-container" [ngClass]="{'cursor-pointer': !readOnly}" (click)="checkAllPresentOrRecipient(!allCheckedPresentUnassigned, 'present', 'unassigned')" *ngIf="!searchTextInput && showParticipantCheckbox()">
         <ion-checkbox class="omg-checkbox cursor-pointer ion-no-padding ion-no-margin" [checked]="allCheckedPresentUnassigned"></ion-checkbox>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="showProjectTeam">
    <app-pdf-mailing-list-table [readOnly]="readOnly" [showSeen]="!!pdfPreview" *ngFor="let mailingListCompany of filteredMailingListCompanies"
                                [showCompanyPin]="showCompanyPin"
                                [company]="mailingListCompany"
                                [isSearchMode]="searchTextInput | isEmpty:'reverse'"
                                [showEmptyResult]="!skipEmptyCompanies"
                                [employees]="mailingListCompany.unassignedEmployees"
                                (presentChanged)="onPresentChanged($event.employee)"
                                (recipientChanged)="onRecipientChanged($event.employee)"
                                [showMailingList]="showParticipantCheckbox()"
    ></app-pdf-mailing-list-table>
  </ion-grid>

</div>
