import {AfterViewInit, Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, Output, SimpleChange, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MbscDatepicker, MbscDatepickerOptions} from '@mobiscroll/angular-ivy';
import {Observable, Subject} from 'rxjs';
import {debounceTime, map, takeUntil} from 'rxjs/operators';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {CompanyDataService} from 'src/app/services/data/company-data.service';
import {timeStringToDate} from 'src/app/utils/date-utils';
import {TimeRangeValidator} from 'src/app/utils/validation-utils';
import {Address, Company} from 'submodules/baumaster-v2-common';
import {IndividualNextMeetingForm, NextMeetingForm} from '../next-meeting.interface';
import {MobiscrollService} from '../../../../services/common/mobiscroll.service';
import _ from 'lodash';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-individual-next-meeting-form',
  templateUrl: './individual-next-meeting-form.component.html',
  styleUrls: ['./individual-next-meeting-form.component.scss'],
})
export class IndividualNextMeetingFormComponent implements OnChanges, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();

  readonly rangePickerSettings: MbscDatepickerOptions = {
    select: 'range',
    timeFormat: this.mobiscrollService.TIME_FORMAT,
    returnFormat: 'locale',
    controls: ['time'],
    stepMinute: 15,
  };

  @ViewChild(MbscDatepicker, {
    static: true
  }) range: MbscDatepicker;

  @Input()
  disableTimePicker = false;
  @Input()
  nextMeeting: NextMeetingForm;
  @Input()
  individualNextMeeting!: IndividualNextMeetingForm;
  @Output()
  individualNextMeetingChange = new EventEmitter<IndividualNextMeetingForm>();

  @Output()
  deleteClick = new EventEmitter<Event>();

  form: UntypedFormGroup;

  address$: Observable<Address>;
  company$: Observable<Company>;

  public mbscThemeVariant$ = this.mobiscrollService.themeVariant$;
  public mbscLocale$ = this.mobiscrollService.locale$;
  public MBSC_DATE_FORMAT = this.mobiscrollService.DATE_FORMAT;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private fb: UntypedFormBuilder,
    private addressDataService: AddressDataService,
    private companyDataService: CompanyDataService,
    private mobiscrollService: MobiscrollService,
  ) {
    this.initializeForm();
  }

  private handleDifferentProfileChange(change: SimpleChange) {
    if (!(change.previousValue?.profile.id !== change.currentValue?.profile.id)) {
      return;
    }

    if (change.firstChange) {
      this.patchForm();
    } else {
      this.resetForm();
    }
    this.address$ = this.addressDataService.dataGroupedById.pipe(
      map((addresses) => addresses[this.individualNextMeeting.profile.addressId])
    );
    this.company$ = this.companyDataService.dataGroupedById.pipe(
      map((companies) => companies[this.individualNextMeeting.profile.companyId])
    );
  }

  private handleIndividualNextMeetingChange(change: SimpleChange) {
    this.handleDifferentProfileChange(change);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.individualNextMeeting) {
      this.handleIndividualNextMeetingChange(changes.individualNextMeeting);
    }
    if (changes.nextMeeting) {
      this.fillTimeIfEmpty();
      this.trimTime();
    }
    if (changes.disableTimePicker && !changes.disableTimePicker.firstChange) {
      this.setInRangeInstance();
    }
  }

  ngAfterViewInit() {
    this.setInRangeInstance();
  }

  private setInRangeInstance() {
    if (this.individualNextMeeting.timeStart && this.individualNextMeeting.timeEnd) {
      this.range?.setVal([
        timeStringToDate(this.individualNextMeeting.timeStart),
        timeStringToDate(this.individualNextMeeting.timeEnd),
      ]);
    }
  }

  getFormValue(): IndividualNextMeetingForm {
    const formRaw = this.form.getRawValue();
    const [timeStart, timeEnd] = formRaw.timeStartEnd ? formRaw.timeStartEnd : [null, null];
    return {
      ..._.omit(formRaw, 'timeStartEnd'),
      timeStart: this.toTimeString(timeStart),
      timeEnd: this.toTimeString(timeEnd),
      profile: this.individualNextMeeting.profile,
    };
  }

  private initializeForm() {
    this.form = this.fb.group({
      timeStartEnd: [['', ''], [Validators.required, TimeRangeValidator]],
    });

    this.resetForm();
    this.initializeValueChange();
  }

  private fillTimeIfEmpty() {
    if (this.form.value.timeStart || this.form.value.timeEnd) {
      return;
    }

    if (!this.nextMeeting?.timeStart || !this.nextMeeting?.timeEnd) {
      return;
    }

    this.form.patchValue({
      timeStartEnd: [timeStringToDate(this.nextMeeting?.timeStart), timeStringToDate(this.nextMeeting?.timeEnd)],
    });
  }

  private trimTime() {
    if (!this.nextMeeting || !this.nextMeeting.timeStart || !this.nextMeeting.timeEnd) {
      return;
    }

    const { timeStartEnd } = this.form.value;
    const timeStart = timeStartEnd?.length ? timeStartEnd[0] : undefined;
    const timeEnd = timeStartEnd?.length ? timeStartEnd[1] : undefined;

    const changeStart = timeStart < this.nextMeeting.timeStart;
    const changeEnd = timeEnd > this.nextMeeting.timeEnd;

    if (!changeStart && !changeEnd) {
      return;
    }

    this.setInRangeInstance();

    this.form.patchValue({
      timeStartEnd: [timeStringToDate(changeStart ? this.nextMeeting.timeStart : timeStart), timeStringToDate(changeEnd ? this.nextMeeting.timeEnd : timeEnd)]
    });
  }

  private initializeValueChange() {
    this.form.valueChanges.pipe(debounceTime(0), takeUntil(this.destroy$)).subscribe(
      () => {
        this.individualNextMeeting = this.getFormValue();
        this.individualNextMeetingChange.emit(this.individualNextMeeting);
      }
    );
  }

  private resetForm(meeting: IndividualNextMeetingForm = this.individualNextMeeting) {
    this.form.reset(this.setTimeStartEndToMeeting(meeting));
  }

  private patchForm(meeting: IndividualNextMeetingForm = this.individualNextMeeting) {
    this.form.patchValue(this.setTimeStartEndToMeeting(meeting), { emitEvent: false });
  }

  private setTimeStartEndToMeeting(meeting: IndividualNextMeetingForm): IndividualNextMeetingForm {
    if (!meeting || !meeting.timeStart || !meeting.timeEnd) {
      return meeting;
    }
    const meetingCloned = {...meeting};
    _.set(meetingCloned, 'timeStartEnd', [timeStringToDate(meeting.timeStart), timeStringToDate(meeting.timeEnd)]);
    return meetingCloned;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toTimeString(dateOrTimeString: string|Date|null|undefined): string|null|undefined {
    if (dateOrTimeString === null) {
      return null;
    }
    if (dateOrTimeString === undefined) {
      return undefined;
    }
    if (typeof dateOrTimeString === 'string') {
      return dateOrTimeString;
    }
    return formatDate(dateOrTimeString, 'HH:mm', this.locale);
  }

}
