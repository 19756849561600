import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ProtocolType} from 'submodules/baumaster-v2-common';
import {IonicSelectableComponent} from 'ionic-selectable';
import _ from 'lodash';
import {ProtocolTypeService} from 'src/app/services/protocol/protocol-type.service';
import {ProtocolDataService} from 'src/app/services/data/protocol-data.service';
import {ProtocolLayoutDataService} from 'src/app/services/data/protocol-layout-data.service';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';

@Component({
  selector: 'app-protocol-type-selector',
  templateUrl: './protocol-type-selector.component.html',
  styleUrls: ['./protocol-type-selector.component.scss'],
})
export class ProtocolTypeSelectorComponent implements OnInit, OnDestroy {
  @ViewChild('protocolTypeSelectable', {static: false}) protocolTypeSelectable: IonicSelectableComponent|undefined;

  @Input() layout: 'list' | 'selectable' = 'selectable';
  @Input() protocolType: ProtocolType|null;
  @Output() protocolTypeChange = new EventEmitter<ProtocolType|null>();

  public protocolTypes: ProtocolType[]|undefined;
  public groupedProtocolsCount$ = this.protocolDataService.getProtocolsCountByTypeId();
  public groupedProtocolLayouts$ = this.protocolLayoutDataService.dataGroupedById;

  private protocolTypesSubscription: Subscription|undefined;
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  constructor(
    private protocolTypeService: ProtocolTypeService,
    private protocolDataService: ProtocolDataService,
    private protocolLayoutDataService: ProtocolLayoutDataService,
    private selectableUtilService: SelectableUtilService,
  ) { }

  ngOnInit() {
    this.protocolTypesSubscription = this.protocolTypeService.getActiveAndUsedProtocolTypesOrOnlyUsed().subscribe(protocolTypeList => {
      this.protocolTypes = _.orderBy(protocolTypeList, ['name'], ['asc']);
    });
  }

  ngOnDestroy() {
    if (this.protocolTypesSubscription) {
      this.protocolTypesSubscription.unsubscribe();
      this.protocolTypesSubscription = null;
    }
  }

  onFilterChange(event: { component?: IonicSelectableComponent, value: ProtocolType|null }) {
    const newProtocolType = event?.value;
    this.protocolType = newProtocolType;
    this.protocolTypeChange.emit(newProtocolType);
  }

  clear() {
    this.protocolTypeSelectable?.clear();
    this.protocolType = null;
    this.protocolTypeChange.emit(null);
  }

  async onOpen($event: { component: IonicSelectableComponent }) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: { component: IonicSelectableComponent }) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
