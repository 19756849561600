<ion-header *ngIf="showFilter" #lt="appLicenseType" appLicenseType class="ion-no-border">
  <ion-grid class="top-bar subheader ion-no-padding">
    <ion-row class="ion-align-items-center ion-justify-content-between gap-2">
      <ion-col size="auto">
        <ng-container *appFeatureEnabled="false; forConnected true; forLicenses [lt.LicenseType.VIEWER]">
          <ion-button id="protocol-entries-send-pdf" [disabled]="loading || (protocolEntrySelectionService.isSelectMode$ | async) && !(protocolEntrySelectionService.hasAnySelected$ | async)" class="omg-btn-primary" (click)="openSendPdfDialog()">
            <fa-icon [icon]="['fal','paper-plane']"></fa-icon>
          </ion-button>
          <app-tooltip target="protocol-entries-send-pdf" placement="bottom">
            {{ 'sendPdf' | translate }}
          </app-tooltip>
        </ng-container>
      </ion-col>
      <ng-container *ngIf="protocolEntrySelectionService.isSelectMode$ | async">
        <ion-col size="auto">
          <ion-button id="protocol-entries-select-mode-copy" [disabled]="!(protocolEntrySelectionService.hasAnySelected$ | async)" class="omg-btn-secondary" (click)="copySelectedEntries()">
            <fa-icon [icon]="['fal','copy']"></fa-icon>
          </ion-button>
          <app-tooltip target="protocol-entries-select-mode-copy" placement="bottom">
            {{ 'copy' | translate }}
          </app-tooltip>
        </ion-col>
        <ion-col size="auto">
          <ion-button id="protocol-entries-select-mode-move-to" [disabled]="!(protocolEntrySelectionService.hasAnySelected$ | async) || (isMoveToProtocolDisabled$ | async)" class="omg-btn-secondary" (click)="moveSelectedEntries()">
            <fa-icon [icon]="['fal','arrow-from-left']"></fa-icon>
          </ion-button>
          <app-tooltip target="protocol-entries-select-mode-move-to" placement="bottom">
            {{ 'move_to' | translate }}
          </app-tooltip>
        </ion-col>
        <ion-col size="auto">
          <ion-button id="protocol-entries-select-mode-edit" [disabled]="!(protocolEntrySelectionService.hasAnySelected$ | async)" class="omg-btn-secondary" (click)="massEdit()">
            <fa-icon [icon]="['fal','pencil']"></fa-icon>
          </ion-button>
          <app-tooltip target="protocol-entries-select-mode-edit" placement="bottom">
            {{ 'edit' | translate }}
          </app-tooltip>
        </ion-col>
        <ion-col size="auto">
          <ion-button id="protocol-entries-select-mode-delete" [disabled]="!(protocolEntrySelectionService.hasAnySelected$ | async) || (hasCarriedOverOrClosedEntriesSelected$ | async)" class="omg-btn-secondary" (click)="removeSelectedEntries()">
            <fa-icon [icon]="['fal','trash-alt']"></fa-icon>
          </ion-button>
          <app-tooltip target="protocol-entries-select-mode-delete" placement="bottom">
            {{ 'delete' | translate }}
          </app-tooltip>
        </ion-col>
      </ng-container>
      <ion-col class="ion-col-static" *ngIf="!(protocolEntrySelectionService.isSelectMode$ | async)">
        <div class="entry-searchbar-container">
          <ion-searchbar class="omg slim-searchbar" showClearButton="always" [placeholder]="'search' | translate" [value]="textSearch" [debounce]="200" (ionInput)="textSearch = $event.detail.value"></ion-searchbar>
        </div>
      </ion-col>
      <ion-col size="auto" class="d-flex gap-2">
        <ng-container *ngIf="!(protocolEntrySelectionService.isSelectMode$ | async)">
          <ion-button id="protocol-entries-filter" class="omg-btn-secondary" [class.omg-btn-color-selected]="hasFilters$ | async" (click)="openEntryFilter()">
            <fa-icon [icon]="['fal','filter']"></fa-icon>
          </ion-button>
          <app-tooltip target="protocol-entries-filter" placement="bottom">
            {{ 'filter' | translate }}
          </app-tooltip>
        </ng-container>
        <ng-container *ngIf="!(protocolEntrySelectionService.isSelectMode$ | async)">
          <ion-button id="protocol-expand-subentries" class="omg-btn-secondary" [disabled]="disableSubentryButton" (click)="toggleSubentriesVisibility()" [class.omg-btn-color-selected]="(withSubEntries$ | async)">
            <fa-icon [icon]="['bau', 'entry-expand']"></fa-icon>
          </ion-button>
          <app-tooltip target="protocol-expand-subentries" placement="bottom">
            {{ 'expand_collapse_entries' | translate }}
          </app-tooltip>
        </ng-container>
        <ion-button id="protocol-entries-card-settings" class="omg-btn-secondary" (click)="openEntryCardSettings()">
          <fa-icon slot="icon-only" [icon]="['fal', 'cog']"></fa-icon>
        </ion-button>
        <app-tooltip target="protocol-entries-card-settings" placement="bottom">{{'entryCardSettings.title' | translate}}</app-tooltip>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="ion-text-center list-title">
    <ion-text color="text-secondary" class="size-xs">
      {{ 'Entries' | translate }}
    </ion-text>
  </div>
</ion-header>

<ion-content color="omg-card-surface" #content *rxLet="hasEntriesStatus$; let hasEntriesStatus; renderCallback: contentRendered$">
  <app-entry-card-list
    (entryClick)="navigateToEntry($event.entry)"
    (threeDotsClick)="entryActions($event.event, $event.entry)"
    [showSelectCheckbox]="canMultiselect$ | async"
    [selectedEntryId]="currentEntryId$ | async"
    [groupAdditionalHeightFn]="groupAdditionalHeightFn"
  >
    <ng-template appEntryListGroupHeaderTemplate let-entry="entry">
      <div *ngIf="entry.firstInGroup && companyIdFilter" class="entry-card-list-group-header d-flex ion-align-items-end ion-justify-content-center" [class.is-subtask]="entry.isSubtask">
        <ion-text color="text-primary">
          <strong>{{ (entry.groupId === companyIdFilter.in[0] ? 'Company' : 'observerCompanies') | translate }}:</strong>
          {{ ((companyById$|async)[companyIdFilter.in[0]]) | companyNameString:(!((isCompanyInProject$|async)[companyIdFilter.in[0]])) }}
        </ion-text>
      </div>
    </ng-template>
  </app-entry-card-list>

  <ion-grid slot="fixed" class="no-entries ion-hide-lg-up" *rxIf="hasEntriesStatus === 'no-entries'">
    <ion-row>
      <app-no-content-svg [messageString]="'protocol.add_entry' | translate"></app-no-content-svg>
    </ion-row>
  </ion-grid>

  <ion-grid slot="fixed" class="no-entries ion-hide-lg-up" *rxIf="hasEntriesStatus === 'empty-results'">
    <ion-row>
      <app-no-content-svg [messageString]="'protocol.no_entries_with_filter' | translate"></app-no-content-svg>
    </ion-row>
  </ion-grid>
</ion-content>
