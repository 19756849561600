<app-modal-header>
  <ion-title> {{ 'contactForm.createPersonalCreate' | translate }}  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <app-contact-person-form [(form)]="contactEmployeeForm" [company]="company"  [prePopulateMainFieldWithText]="prePopulateMainFieldWithText"></app-contact-person-form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="start" class="omg-form" *ngIf="(notConnected$ | async) && !createForSelectable">
    <ion-item class="omg omg-form-checkbox" (click)="toggleAddToProject()">
     <ion-checkbox label-placement="end" justify="start" [ngModel]="addToProject">
       {{ 'contactForm.addToProject' | translate }}
     </ion-checkbox>
    </ion-item>
  </ion-buttons>
  <ion-buttons slot="end" class="omg-form">
    <ion-button class="omg-btn-text" (click)="dismissModal()">
      {{ 'cancel' | translate }}
    </ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!contactEmployeeForm?.valid || loading" (click)="createNewContact()" >
      {{ 'button.create' | translate }}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
