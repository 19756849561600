import {Injectable} from '@angular/core';
import {ContactService} from '../contact/contact.service';
import {AttachmentProtocolSignatureDataService} from '../data/attachment-protocol-signature-data.service';
import {LoggingService} from '../common/logging.service';
import {AttachmentProtocolSignature, IdType, Participant} from 'submodules/baumaster-v2-common';
import {observableToPromise} from '../../utils/async-utils';
import {PdfProtocolSignatureForm} from '../../components/pdf/pdf-protocol-signatures/pdf-protocol-signatures.interface';
import {AttachmentBlob} from '../../model/attachments';

const LOG_SOURCE = 'ProtocolSignatureService';

@Injectable({
  providedIn: 'root'
})
export class ProtocolSignatureService {

  constructor(private attachmentProtocolSignatureDataService: AttachmentProtocolSignatureDataService, private contactService: ContactService,
              private loggingService: LoggingService) {
  }

  public async createPdfProtocolSignaturesForParticipantsPresent(participants: Participant[]): Promise<PdfProtocolSignatureForm[]> {
    const participantsPresent = participants.filter((participant) => participant.present);
    const pdfProtocolSignatures: PdfProtocolSignatureForm[] = participantsPresent.map((participant) => {
        return {profileId: participant.profileId} as PdfProtocolSignatureForm;
      }
    );
    return await this.sortPdfProtocolSignatures(pdfProtocolSignatures);
  }

  public filterProtocolSignaturesNotParticipants(
    participants: Participant[],
    pdfProtocolSignatures: PdfProtocolSignatureForm[]
  ): {changed: boolean, filteredPdfProtocolSignatures: PdfProtocolSignatureForm[]} {
    const filteredPdfProtocolSignatures = pdfProtocolSignatures.filter((pdfProtocolSignature) => pdfProtocolSignature.name ||
      (pdfProtocolSignature.profileId && participants.some((participant) => pdfProtocolSignature.profileId === participant.profileId)));
    const changed = filteredPdfProtocolSignatures.length !== pdfProtocolSignatures.length;
    return {
      changed,
      filteredPdfProtocolSignatures
    };
  }

  public async cleanupAttachmentProtocolSignaturesForProtocol(protocolId: IdType): Promise<number> {
    const attachments = await observableToPromise(this.attachmentProtocolSignatureDataService.getByProtocolId(protocolId));
    if (!attachments?.length) {
      this.loggingService.debug(LOG_SOURCE, `No attachmentProtocolSignatures for protocol ${protocolId}`);
      return 0;
    }
    const projectId = attachments[0].projectId;
    this.loggingService.info(LOG_SOURCE, `Deleting ${attachments.length} attachmentProtocolSignatures for protocol ${protocolId}`);
    await this.attachmentProtocolSignatureDataService.delete(attachments, projectId);
    return attachments.length;
  }

  private pdfProtocolSignatureEqualsAttachmentProtocolSignature(pdfProtocolSignature: PdfProtocolSignatureForm, attachmentProtocolSignature: AttachmentProtocolSignature): boolean {
    return (pdfProtocolSignature.profileId && attachmentProtocolSignature.profileId && pdfProtocolSignature.profileId === attachmentProtocolSignature.profileId) ||
      (!pdfProtocolSignature.profileId && !attachmentProtocolSignature.profileId
        && pdfProtocolSignature.name && attachmentProtocolSignature.name && pdfProtocolSignature.name === attachmentProtocolSignature.name);
  }

  public async persistAttachmentProtocolSignaturesFromFormValues(projectId: IdType, protocolId: IdType,
                                                                 pdfProtocolSignatureFormValues: Array<PdfProtocolSignatureForm>,
                                                                 forceDeleteInsertForUpdate = false): Promise<Array<AttachmentProtocolSignature>> {
    const attachments = await observableToPromise(this.attachmentProtocolSignatureDataService.getByProtocolId(protocolId));
    const insertAttachments: Array<AttachmentProtocolSignature> = [];
    const insertBlobs: Array<Blob> = [];
    const deleteAttachments: Array<AttachmentProtocolSignature> = [];

    const signedPdfProtocolSignatureFormValues = pdfProtocolSignatureFormValues.filter((pdfProtocolSignatureFormValue) => pdfProtocolSignatureFormValue.signature);
    for (const pdfProtocolSignatureForm of signedPdfProtocolSignatureFormValues) {
      const existingAttachment = attachments.find((value) => this.pdfProtocolSignatureEqualsAttachmentProtocolSignature(pdfProtocolSignatureForm, value));
      if (!existingAttachment) {
        const newAttachment = this.createAttachmentProtocolSignatureFromAttachmentBlob(pdfProtocolSignatureForm.signature,
          {projectId, protocolId, profileId: pdfProtocolSignatureForm.profileId, name: pdfProtocolSignatureForm.name});
        insertAttachments.push(newAttachment);
        insertBlobs.push(pdfProtocolSignatureForm.signature.blob);
      } else {
        // updated
        if (forceDeleteInsertForUpdate) {
          deleteAttachments.push(existingAttachment);
          const newAttachment = this.createAttachmentProtocolSignatureFromAttachmentBlob(pdfProtocolSignatureForm.signature,
            {projectId, protocolId, profileId: pdfProtocolSignatureForm.profileId, name: pdfProtocolSignatureForm.name});
          insertAttachments.push(newAttachment);
          insertBlobs.push(pdfProtocolSignatureForm.signature.blob);
        }
      }
    }

    for (const attachment of attachments) {
      const existingPdfProtocolSignatureFormValue = signedPdfProtocolSignatureFormValues.find((value) => this.pdfProtocolSignatureEqualsAttachmentProtocolSignature(value, attachment));
      if (!existingPdfProtocolSignatureFormValue) {
        deleteAttachments.push(attachment);
      }
    }

    if (deleteAttachments.length) {
      await this.attachmentProtocolSignatureDataService.delete(deleteAttachments, projectId);
    }
    if (insertAttachments.length) {
      await this.attachmentProtocolSignatureDataService.insert(insertAttachments, projectId, undefined, insertBlobs);
    }

    return await observableToPromise(this.attachmentProtocolSignatureDataService.getByProtocolId(protocolId));
  }

  public createAttachmentProtocolSignatureFromAttachmentBlob(attachmentBlob: AttachmentBlob,
                                                             values: Pick<AttachmentProtocolSignature, 'projectId' | 'protocolId' | 'profileId' | 'name'>): AttachmentProtocolSignature {
    return {
      id: attachmentBlob.id,
      hash: attachmentBlob.hash,
      mimeType: attachmentBlob.mimeType,
      fileExt: attachmentBlob.fileExt,
      changedAt: attachmentBlob.changedAt,
      createdAt: attachmentBlob.createdAt,
      createdById: attachmentBlob.createdById,
      markings: attachmentBlob.markings,
      projectId: values.projectId,
      protocolId: values.protocolId,
      profileId: values.profileId,
      name: values.name,
      forClosed: false
    };
  }

  public removeSignatures(pdfProtocolSignatures: PdfProtocolSignatureForm[]): boolean {
    if (!pdfProtocolSignatures?.length) {
      return;
    }
    let changed = false;
    for (const pdfProtocolSignature of pdfProtocolSignatures) {
      if (pdfProtocolSignature.signature) {
        pdfProtocolSignature.signature = undefined;
        changed = true;
      }
    }
    return changed;
  }

  public async sortPdfProtocolSignatures(pdfProtocolSignatures: PdfProtocolSignatureForm[]): Promise<PdfProtocolSignatureForm[]> {
    const profileIds = pdfProtocolSignatures.map((pdfProtocolSignature) => pdfProtocolSignature.profileId);
    const profileCompanyAddresses = await observableToPromise(this.contactService.getProfileCompanyAddresses$(profileIds));
    const sortedPdfProtocolSignatures = pdfProtocolSignatures.sort((a, b) => {
      if (a.profileId && b.profileId) {
        const indexA = profileCompanyAddresses.findIndex((profileCompanyAddress) => profileCompanyAddress.profile.id === a.profileId);
        const indexB = profileCompanyAddresses.findIndex((profileCompanyAddress) => profileCompanyAddress.profile.id === b.profileId);
        return indexA - indexB;
      }
      if (a.profileId && !b.profileId) {
        return -1;
      }
      if (!a.profileId && b.profileId) {
        return 1;
      }
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });
    return sortedPdfProtocolSignatures;
  }

  filterProtocolSignaturesCustomName(pdfProtocolSignatures: PdfProtocolSignatureForm[] | undefined): PdfProtocolSignatureForm[] {
    if (!pdfProtocolSignatures?.length) {
      return [];
    }
    return pdfProtocolSignatures.filter((pdfProtocolSignature) => !pdfProtocolSignature.profileId && pdfProtocolSignature.name);
  }
}
