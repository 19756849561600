import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Observable, of} from 'rxjs';
import {switchMap, shareReplay, map} from 'rxjs/operators';
import {Nullish} from 'src/app/model/nullish';
import {AttachmentUserEmailSignature, generateFilePathsForAttachmentUserEmailSignature, UserEmailSignature, USER_SIGNATURE_LOGO_SIZES} from 'submodules/baumaster-v2-common';
import {v4} from 'uuid';
import {AttachmentFullScreenViewerComponent} from '../../components/common/attachment-full-screen-viewer/attachment-full-screen-viewer.component';
import {UserEmailSignatureFormType} from '../../model/email-signature-form-model';
import {observableToPromise} from '../../utils/async-utils';
import {ensureMimeTypeSet, isQuotaExceededError} from '../../utils/attachment-utils';
import {AttachmentService} from '../attachment/attachment.service';
import {ToastService} from '../common/toast.service';
import {AttachmentUserEmailSignatureDataService} from '../data/attachment-user-email-signature-data.service';
import {UserEmailSignatureDataService} from '../data/user-email-signature-data.service';
import {PhotoService} from '../photo/photo.service';
import {UserService} from './user.service';
import {convertErrorToMessage} from '../../shared/errors';
import {LoggingService} from '../common/logging.service';
import {SystemEventService} from '../event/system-event.service';

const LOG_SOURCE = 'UserEmailSignatureService';

@Injectable({
  providedIn: 'root'
})
export class UserEmailSignatureService {

  userEmailSignatureImage$ = this.attachmentUserEmailSignatureDataService.dataReally.pipe(map(([image]) => image));

  logoSize$: Observable<Nullish<{width: number; height: number}>> = this.userEmailSignatureImage$.pipe(
    switchMap((logo) => !logo ? null : this.photoService.getImageSize(logo)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );
  disabledSizes$: Observable<{id: UserEmailSignature['size']}[]> = this.logoSize$.pipe(
    map((size) => {
      if (!size) {
        return [];
      }

      const disabled: {id: UserEmailSignature['size']}[] = [];
      Object.entries(USER_SIGNATURE_LOGO_SIZES).forEach(([id, value]: [keyof typeof USER_SIGNATURE_LOGO_SIZES, (typeof USER_SIGNATURE_LOGO_SIZES)['SMALL']]) => {
        if (size.width < value.width || size.height < value.height) {
          disabled.push({id});
        }
      });

      return disabled;
    })
  );

  constructor(
    private userService: UserService,
    private photoService: PhotoService,
    private attachmentUserEmailSignatureDataService: AttachmentUserEmailSignatureDataService,
    private toastService: ToastService,
    private attachmentService: AttachmentService,
    private modalController: ModalController,
    private userEmailSignatureDataService: UserEmailSignatureDataService,
    private loggingService: LoggingService,
    private systemEventService: SystemEventService,
  ) { }

  private async insertAttachment(blob: Blob, filename: string): Promise<AttachmentUserEmailSignature> {
    const user = await observableToPromise(this.userService.currentUser$);
    if (!user) {
      throw new Error('No user found');
    }
    const {fileNameWithoutExt, fileExt} = this.photoService.fileNameSplitToNameAndExt(filename);
    blob = ensureMimeTypeSet(blob, filename);
    const attachment: AttachmentUserEmailSignature = {
      id: v4(),
      hash: v4(),
      mimeType: blob.type,
      fileName: fileNameWithoutExt,
      createdAt: new Date().toISOString(),
      changedAt: new Date().toISOString(),
      createdById: user.id,
      fileExt,
      userId: user.id,
    };
    const filePath = generateFilePathsForAttachmentUserEmailSignature(attachment).filePath;
    attachment.filePath = filePath;

    await this.attachmentUserEmailSignatureDataService.insert(attachment, {}, blob);

    return attachment;
  }

  async uploadAttachmentUserEmailSignature(blobOriginal: Blob, filename: string) {
    try {
      const blob = await this.photoService.scaleImage(blobOriginal);
      await this.insertAttachment(blob, filename);

      await this.toastService.savingSuccess();
    } catch (error) {
      if (isQuotaExceededError(error)) {
        await this.attachmentService.showToastQuotaExceeded();
      } else {
        this.loggingService.error(LOG_SOURCE, `uploadAttachmentUserEmailSignature - ${convertErrorToMessage(error)}`)
        this.systemEventService.logErrorEvent(LOG_SOURCE + ' uploadAttachmentUserEmailSignature', error);
        await this.toastService.savingError();
      }
    }
  }

  async openAttachmentPreview(selectedAttachment: AttachmentUserEmailSignature) {
    const modal = await this.modalController.create({
      component: AttachmentFullScreenViewerComponent,
      backdropDismiss: false,
      componentProps: {
        selectedAttachment,
        index: 0,
        attachmentsObservable: of([selectedAttachment]),
        onAttachmentDeleted: async (attachment) => {
          await this.performDeleteAttachment(selectedAttachment);
        },
        forceDisableDownload: true
      }
    });
    return await modal.present();
  }

  async performDeleteAttachment(selectedAttachment: AttachmentUserEmailSignature) {
    await this.attachmentUserEmailSignatureDataService.delete(selectedAttachment);
  }

  async performSaveSignature(signatureData: UserEmailSignatureFormType, userEmailSignature?: UserEmailSignature): Promise<UserEmailSignature> {
    if (userEmailSignature) {
      const signature: UserEmailSignature = {
        ...userEmailSignature,
        ...signatureData
      };

      return (await this.userEmailSignatureDataService.update(signature))[0];
    } else {
      const user = await observableToPromise(this.userService.currentUser$);
      if (!user) {
        throw new Error(`User is not defined!`);
      }
      const signature: UserEmailSignature = {
        ...signatureData,
        id: user.id,
        changedAt: new Date().toISOString(),
      };

      return (await this.userEmailSignatureDataService.insert(signature))[0];
    }
  }
}
