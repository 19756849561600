import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Attachment, IdType} from 'submodules/baumaster-v2-common';
import {ImageUriPipe} from '../../../pipes/image-uri.pipe';
import {AttachmentBlob} from '../../../model/attachments';
import {LoggingService} from '../../../services/common/logging.service';
import {PhotoService} from '../../../services/photo/photo.service';
import _ from 'lodash';
import {isAttachmentBlob} from '../../../utils/attachment-utils';
import {SizeType} from 'src/app/utils/canvas-utils';

const LOG_SOURCE = 'AttachmentViewerComponent';

@Component({
  selector: 'app-attachment-viewer',
  templateUrl: './attachment-viewer.component.html',
  styleUrls: ['./attachment-viewer.component.scss'],
  providers: [ImageUriPipe]
})
export class AttachmentViewerComponent implements OnInit, OnDestroy, OnChanges {
  private static nextInstanceNumber = 1;
  private static numberOfInstances = 0;

  @Input() attachment: Attachment | AttachmentBlob;
  @Input() size: SizeType = 'large';
  @Input() showChatImageIcon = true;
  @Input() showSelectedImageIcon = false;
  @ViewChild('imageCanvas') canvas: HTMLCanvasElement | undefined;
  public isContentAvailable: boolean|undefined;
  public isAudio: boolean|undefined;
  public isImage: boolean|undefined;
  public isChatImage: boolean|undefined;
  public isShowThumbnail: boolean|undefined;
  public lastMarking: {attachmentId: IdType, version?: number};
  private objectUrls = new Array<string>();
  public onObjectUrlCreated: (objectUrl: string) => void;
  private readonly instanceNumber: number;
  private abortController: AbortController|undefined;
  public abortSignal: AbortSignal|undefined;

  constructor(public imageUriPipe: ImageUriPipe, private photoService: PhotoService, private loggingService: LoggingService) {
    this.onObjectUrlCreated = (objectUrl) => this.addObjectUrl(objectUrl);
    this.instanceNumber = AttachmentViewerComponent.nextInstanceNumber++;
    AttachmentViewerComponent.numberOfInstances++;
    this.loggingService.debug(LOG_SOURCE, `constructor - instanceNumber=${this.instanceNumber}, numberOfInstances=${AttachmentViewerComponent.numberOfInstances}`);
  }

  ngOnInit(): void {
    this.loggingService.debug(LOG_SOURCE, `ngOnInit called.`);
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.loggingService.debug(LOG_SOURCE, `ngOnChanges called. changes.attachment=${!!changes.attachment}`);
    if (changes.attachment) {
      if (changes.attachment.firstChange || !this.lastMarking || this.lastMarking.attachmentId !== this.attachment.id) {
        this.lastMarking = this.hasAttachmentMarkings(this.attachment) ? {attachmentId: this.attachment.id, version: this.extractVersionFromMarkings(this.attachment)} :
          {attachmentId: this.attachment.id};
      }

      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      this.abortSignal = this.abortController.signal;
      this.revokeObjectUrls();
      this.isAudio = this.getIsAudio();
      this.isImage = this.getIsImage();
      this.isChatImage = this.getIsChatImage();
      this.isShowThumbnail = !this.markingsChanged() && (await this.getIsThumbnailAvailableInCache() || !(await this.getIsImageAvailableInCache()));
      this.isContentAvailable = this.getIsContentAvailable();
    }
  }

  ngOnDestroy(): void {
    this.loggingService.debug(LOG_SOURCE, `ngOnDestroy called.`);
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
      this.abortSignal = undefined;
    }
    this.revokeObjectUrls();
    AttachmentViewerComponent.numberOfInstances--;
    this.loggingService.debug(LOG_SOURCE, `ngOnDestroy - instanceNumber=${this.instanceNumber}, numberOfInstances=${AttachmentViewerComponent.numberOfInstances}`);
    if (this.canvas) {
      this.canvas.width = 0;
      this.canvas.height = 0;
    }
  }

  public markingsChanged(): boolean {
    if (!this.hasAttachmentMarkings(this.attachment)) {
      return false;
    }
    if (this.lastMarking.attachmentId !== this.attachment.id) {
      this.loggingService.debug(LOG_SOURCE, `markingsChanged - attachmentId (${this.attachment.id}) is different from lastMarking's attachmentId (${this.lastMarking.attachmentId})`);
      return false;
    }
    return isAttachmentBlob(this.attachment) || this.extractVersionFromMarkings(this.attachment) !== this.lastMarking.version;
  }

  private extractVersionFromMarkings(attachment: Attachment|AttachmentBlob): number|undefined {
    if (!this.hasAttachmentMarkings(attachment)) {
      return undefined;
    }
    try {
      return _.isString(this.attachment.markings) ? JSON.parse(this.attachment.markings).version : this.attachment.markings.version;
    } catch (e) {
      this.loggingService.warn(LOG_SOURCE, `Unable to parse markings: ${this.attachment.markings}`);
      return undefined;
    }
  }

  private hasAttachmentMarkings(attachment: Attachment|AttachmentBlob): boolean {
    return attachment.markings && attachment.markings !== '';
  }

  private getIsContentAvailable(): boolean {
    return this.attachment && (this.getIsImageAvailable() || this.getIsThumbnailAvailable());
  }

  private getIsAudio(): boolean {
    return this.attachment?.mimeType && this.attachment.mimeType.startsWith('audio/');
  }

  private getIsImage(): boolean {
    return this.attachment?.mimeType && this.attachment.mimeType.startsWith('image/');
  }

  private getIsChatImage(): boolean {
    return this.getIsImage() && this.attachment.hasOwnProperty('chatId');
  }

  public getIsImageAvailable(): boolean {
    return !!('filePath' in this.attachment && this.attachment.filePath) || isAttachmentBlob(this.attachment);
  }

  public getIsThumbnailAvailable(): boolean {
    return !!(('mediumThumbnailPath' in this.attachment && this.attachment.mediumThumbnailPath) ||
      ('thumbnailPath' in this.attachment && this.attachment.thumbnailPath) ||
      ('bigThumbnailPath' in this.attachment && this.attachment.bigThumbnailPath));
  }

  private async getIsThumbnailAvailableInCache(): Promise<boolean> {
    if (this.abortSignal?.aborted) {
      return false;
    }
    if (isAttachmentBlob(this.attachment)) {
      return false;
    }
    return this.getIsThumbnailAvailable() && !!(await this.photoService.isAttachmentThumbnailInCache(this.attachment, this.abortSignal));
  }

  private async getIsImageAvailableInCache(): Promise<boolean> {
    if (this.abortSignal?.aborted) {
      return false;
    }
    if (isAttachmentBlob(this.attachment)) {
      return false;
    }
    return this.getIsImageAvailable() && !!(await this.photoService.isAttachmentImageInCache(this.attachment, this.abortSignal));
  }

  public addObjectUrl(objectUrl: string) {
    this.objectUrls.push(objectUrl);
  }

  private revokeObjectUrls() {
    if (this.objectUrls.length) {
      this.objectUrls.forEach((objectUrl) => URL.revokeObjectURL(objectUrl));
      this.objectUrls = [];
    }
  }

}
