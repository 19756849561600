<div class="subheader" [ngClass]="toolbarClass$ | async">
  <app-project-selector [labelTextClass]="'ion-hide-xl-down'" [ngClass]="{'ion-hide': showFullWidthSearchbar, 'searchbar-has-value': !!search}"></app-project-selector>

  <ion-button *ngIf="isEditEnabled$ | async" (click)="leaveSelectMode()" id="action-leave-select-mode" class="omg-btn-secondary omg-btn-color-selected" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="icon-only" [icon]="['fal', 'tasks']"></fa-icon>
  </ion-button>
  <app-tooltip *ngIf="isEditEnabled$ | async" target="action-leave-select-mode" placement="bottom">{{'toggleSelectionMode' | translate}}</app-tooltip>

  <ion-button id="action-card-settings" class="omg-btn-secondary" (click)="openEntryCardSettings()" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="icon-only" [icon]="['fal', 'cog']"></fa-icon>
  </ion-button>
  <app-tooltip target="action-card-settings" placement="bottom">{{'entryCardSettings.title' | translate}}</app-tooltip>

  <ion-button [disabled]="!(hasPrevTask$ | async)" id="action-prev-task" class="omg-btn-secondary" (click)="prevTask()" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="icon-only" [icon]="['fal', 'arrow-left']"></fa-icon>
  </ion-button>
  <app-tooltip target="action-prev-task" placement="bottom">{{'Previous' | translate}}</app-tooltip>

  <ion-button [disabled]="!(hasNextTask$ | async)" id="action-next-task" class="omg-btn-secondary" (click)="nextTask()" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="icon-only" [icon]="['fal', 'arrow-right']"></fa-icon>
  </ion-button>
  <app-tooltip target="action-next-task" placement="bottom">{{'Next' | translate}}</app-tooltip>

  <ion-button id="action-sort-order" class="omg-btn-secondary" (click)="toggleSortOrder()" [class.omg-btn-color-selected]="!(sortOrderAsc$ | async)">
    <fa-icon slot="icon-only" [icon]="['fal', 'sort-alt']"></fa-icon>
  </ion-button>
  <app-tooltip target="action-sort-order" placement="bottom">{{'Sort' | translate}}</app-tooltip>

  <ion-button (click)="toggleSubEntries()" [class.omg-btn-color-selected]="withSubEntries" id="action-sub-entries" class="omg-btn-secondary" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="icon-only" [icon]="['fal6', 'diagram-subtask']"></fa-icon>
  </ion-button>
  <app-tooltip target="action-sub-entries" placement="bottom">{{'expand_collapse_tasks' | translate}}</app-tooltip>

  <ion-button (click)="openEntryFilter()" id="action-entry-filter" class="omg-btn-secondary" [ngClass]="{'ion-hide': showFullWidthSearchbar}" [class.omg-btn-color-selected]="hasFilter$ | async">
    <fa-icon slot="icon-only" [icon]="['fal', 'filter']"></fa-icon>
  </ion-button>
  <app-tooltip target="action-entry-filter" placement="bottom">{{'filter' | translate}}</app-tooltip>

  <ion-button id="action-show-searchbar" class="omg-btn-secondary" [ngClass]="{'ion-hide': showFullWidthSearchbar, 'omg-btn-color-selected': !!search}"
      (click)="toggleShowFullWidthSearchbar()">
    <fa-icon slot="icon-only" [icon]="['fal', 'search']"></fa-icon>
  </ion-button>
  <app-tooltip target="action-show-searchbar" placement="bottom">{{'search' | translate}}</app-tooltip>

  <div class="filler" [ngClass]="{'ion-hide': showFullWidthSearchbar}"></div>

  <ion-searchbar #searchbar
                 class="searchbar omg slim-searchbar" [ngClass]="{'full-width show-full-width-searchbar': showFullWidthSearchbar}"
                 (focusout)="searchbarFocusOut()"
                 [placeholder]="'search' | translate" (ngModelChange)="handleSearchChange($event)" [ngModel]="search" debounce="100"></ion-searchbar>

              
  <ion-button *ngIf="isEditEnabled$ | async" id="action-remove" class="omg-btn-secondary" (click)="removeTasks()" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="icon-only" [icon]="['fal', 'trash-alt']"></fa-icon>
  </ion-button>
  <app-tooltip *ngIf="isEditEnabled$ | async" target="action-remove" placement="bottom">{{'delete' | translate}}</app-tooltip>

  <ion-button *ngIf="isEditEnabled$ | async" id="action-edit" class="omg-btn-secondary" (click)="massEdit()" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="icon-only" [icon]="['fal', 'pencil']"></fa-icon>
  </ion-button>
  <app-tooltip *ngIf="isEditEnabled$ | async" target="action-edit" placement="bottom">{{'edit' | translate}}</app-tooltip>

  <ion-button *ngIf="isEditEnabled$ | async" id="action-more-select" class="omg-btn-secondary" (click)="openSelectActions($event)" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="icon-only" [icon]="['far', 'ellipsis-h']"></fa-icon>
  </ion-button>
  <app-tooltip *ngIf="isEditEnabled$ | async" target="action-more-select" placement="bottom">{{'tasks.toolbar.actions.moreActionsSelect' | translate}}</app-tooltip>

  <ion-button *ngIf="isEditEnabled$ | async" id="action-more-default" class="omg-btn-secondary" (click)="openMoreActions($event)" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="icon-only" [icon]="['far', 'ellipsis-h']"></fa-icon>
  </ion-button>
  <app-tooltip *ngIf="isEditEnabled$ | async" target="action-more-default" placement="bottom">{{'tasks.toolbar.actions.moreActionsDefault' | translate}}</app-tooltip>

  <ion-button *ngIf="isEditEnabled$ | async" id="action-assign" class="omg-btn-secondary" (click)="assignTasks()" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="start" [icon]="['fal', 'clipboard']"></fa-icon>
    <span class="ion-hide-md-down">{{ 'tasks.toolbar.actions.assignToProtocol' | translate }}</span>
  </ion-button>
  <app-tooltip *ngIf="isEditEnabled$ | async" target="action-assign" class="ion-hide-md-up" placement="bottom">{{'tasks.toolbar.actions.assignToProtocol' | translate}}</app-tooltip>

  <div *ngIf="isEditEnabled$ | async" class="omg-btn-split" id="action-create">
    <ion-button class="omg-btn-primary" (click)="createTask()" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
      <fa-icon slot="start" [icon]="entryCreationModeIcon$ | async"></fa-icon>
      <span>{{ 'tasks.toolbar.actions.addTask' | translate }}</span>
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="openButtonActions($event)" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
      <fa-icon slot="start" [icon]="['fal', 'chevron-up']"></fa-icon>
    </ion-button>
  </div> 

  <ion-button *ngIf="isEditEnabled$ | async" id="action-send" class="omg-btn-primary" (click)="startSendHtml()" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="start" [icon]="['fal', 'paper-plane']"></fa-icon>
    <span class="ion-hide-md-down">{{ 'tasks.toolbar.actions.send' | translate }}</span>
  </ion-button>
  <app-tooltip *ngIf="isEditEnabled$ | async" target="action-send" class="ion-hide-md-up" placement="bottom">{{'tasks.toolbar.actions.send' | translate}}</app-tooltip>
  <ion-button *ngIf="isEditEnabled$ | async" id="action-new-subentry" class="omg-btn-secondary" (click)="createSubTask()" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="start" [icon]="['fal', 'plus']"></fa-icon>
    <span class="ion-hide-md-down">{{ 'Subentry' | translate }}</span>
  </ion-button>
  <app-tooltip target="action-new-subentry" class="ion-hide-md-up" placement="bottom">{{'Subentry' | translate}}</app-tooltip>
  <ion-button *ngIf="isEditEnabled$ | async" id="action-popover" class="omg-btn-secondary" (click)="openActionsPopopver()" [ngClass]="{'ion-hide': showFullWidthSearchbar}">
    <fa-icon slot="start" [icon]="['fal', 'ellipsis-h']"></fa-icon>
  </ion-button>
  <app-tooltip target="action-popover" placement="bottom">{{'actions' | translate}}</app-tooltip>
</div>
