import {formatNumber} from '@angular/common';
import {Nullish} from '../model/nullish';
import * as _ from 'lodash';

export const convertStringToNumber = (str: Nullish<string | number>): Nullish<number> => {
  if (str === undefined || str === null) {
    return str as Nullish<number>;
  }

  const numberStr = `${str}`;
  const [integerPart, decimalPart = '0'] = numberStr.split(/[,.]/g);

  return +`${integerPart}.${decimalPart}`;
};

export const memoizedFormatNumber: (num: Nullish<number>, locale: string, format: string) => string =
  _.memoize((num: Nullish<number>, locale: string, format: string) => formatNumber(num, locale, format), (num: Nullish<number>, locale: string, format: string) => `${num}-${locale}-${format}`);
