<app-modal-header>
  <ion-title class="ion-text-center title">
    {{ 'missingAttachmentsDialog.title' | translate }}
  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <ion-list *ngIf="missingAttachments?.length">
    <ion-item *ngFor="let missingAttachment of missingAttachments">
      <ion-grid>
        <ion-row>
          <ion-col>
            {{missingAttachment.fileName}}
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            {{'missingAttachmentsDialog.type' | translate}}: {{'missingAttachmentsDialog.attachmentType.' + missingAttachment.type | translate}}
            <span *ngIf="missingAttachment.text">&nbsp;({{missingAttachment.text}})</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-text>{{'missingAttachmentsDialog.author' | translate}}: {{missingAttachment.author}}</ion-text>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="missingAttachment.missingReason">
          <ion-col>
            <ion-text>{{ 'missingAttachmentsDialog.missingReason.' + missingAttachment.missingReason | translate}}</ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-list>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismiss()">
      {{ 'close' | translate }}
    </ion-button>
  </ion-buttons>
</app-modal-footer>


