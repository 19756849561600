import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {PdfPlanCommentModalComponent} from 'src/app/components/project-room/pdf-plan-comment-modal/pdf-plan-comment-modal.component';
import {PdfPlanVersionWithAttachment, PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {ToastDurationInMs} from 'src/app/shared/constants';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {MIME_TYPES_PDF_PLAN} from 'src/app/utils/attachment-utils';
import {
  IdType,
  PdfPlan,
  PdfPlanAttachment,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPage,
  PdfPlanPageMarking,
  PdfPlanVersion,
  Protocol,
  ProtocolEntry,
  ProtocolEntryIconStatus,
  ProtocolEntryType,
  ProtocolOpenEntry
} from 'submodules/baumaster-v2-common';
import {environment} from '../../../environments/environment';
import {SharePdfPlanVersionsEmailSettings} from '../../model/share-pdf-plan-versions-email-settings';
import {LoadingService} from '../common/loading.service';
import {PdfPlanAttachmentDataService} from '../data/pdf-plan-attachment-data.service';
import {PdfPlanDataService} from '../data/pdf-plan-data.service';
import {PdfPlanFolderDataService} from '../data/pdf-plan-folder-data.service';
import {PdfPlanMarkerProtocolEntryDataService} from '../data/pdf-plan-marker-protocol-entry-data.service';
import {PdfPlanPageDataService} from '../data/pdf-plan-page-data.service';
import {PdfPlanPageMarkingDataService} from '../data/pdf-plan-page-marking-data.service';
import {PdfPlanVersionDataService} from '../data/pdf-plan-version-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {PdfPlanService} from '../pdf/pdf-plan.service';
import {ProtocolEntryService} from '../protocol/protocol-entry.service';
import {AlertService} from '../ui/alert.service';
import {OmgToastService} from '../ui/omg-toast.service';
import {SyncService} from '../sync/sync.service';
import {SyncStrategy} from '../sync/sync-utils';
import {convertISOStringToDate} from '../../utils/date-utils';
import {ProtocolOpenEntryDataService} from '../data/protocol-open-entry-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';

const LOG_SOURCE = 'PdfPlanHolderService';

const SHARE_PDF_PLANS_ENDPOINT = 'api/emailDistribute/pdfPlanVersions';

@Injectable({
  providedIn: 'root'
})
export class PdfPlanHolderService {
  readonly acceptedMimeTypesForUpload = MIME_TYPES_PDF_PLAN.join(',');

  constructor(
    private pdfPlanDataService: PdfPlanDataService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private pdfPlanFolderDataService: PdfPlanFolderDataService,
    private pdfPlanPageDataService: PdfPlanPageDataService,
    private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private pdfPlanPageMarkingDataService: PdfPlanPageMarkingDataService,
    private protocolEntryService: ProtocolEntryService,
    private translateService: TranslateService,
    private pdfPlanService: PdfPlanService,
    private projectDataService: ProjectDataService,
    private toastService: OmgToastService,
    private systemEventService: SystemEventService,
    private pdfPlanAttachmentDataService: PdfPlanAttachmentDataService,
    private modalController: ModalController,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private http: HttpClient,
    private syncService: SyncService,
    private protocolDataService: ProtocolDataService,
    private protocolOpenEntryDataService: ProtocolOpenEntryDataService
  ) { }

  private getPdfPlansWithDeletableAndQuery(pdfPlans$: Observable<PdfPlan[]>, query$: Observable<string|undefined>): Observable<PdfPlanWithDeletable[]> {
    return combineLatestAsync(
      [pdfPlans$, this.pdfPlanVersionDataService.data,
      this.pdfPlanPageDataService.data, this.pdfPlanMarkerProtocolEntryDataService.data, this.pdfPlanPageMarkingDataService.data, this.protocolEntryDataService.data,
        this.protocolEntryTypeDataService.data, this.pdfPlanAttachmentDataService.dataGroupedByPdfPlanVersionId$, this.pdfPlanAttachmentDataService.dataCadFilesGroupedByPdfPlanVersionId$,
        this.protocolDataService.dataWithoutHidden$, this.protocolOpenEntryDataService.data])
      .pipe(map(([
                   pdfPlanHolders,
                   pdfPlanVersions,
                   pdfPlanPages,
                   pdfPlanMarkers,
                   pdfPlanPageMarkings,
                   protocolEntries,
                   protocolEntryTypes,
                   attachmentByPdfPlanVersion,
                   attachmentCadFilesByPdfPlanVersion,
                   protocols,
                   protocolOpenEntries
      ]: [PdfPlan[], PdfPlanVersion[], PdfPlanPage[], PdfPlanMarkerProtocolEntry[], PdfPlanPageMarking[], ProtocolEntry[], ProtocolEntryType[],
        Record<IdType, PdfPlanAttachment>, Record<IdType, PdfPlanAttachment[]>, Protocol[], ProtocolOpenEntry[]]) => pdfPlanHolders.map((pdfPlanHolder) =>
        this.toPdfPlanHolderWithDeletable(pdfPlanHolder, pdfPlanVersions, pdfPlanPages, pdfPlanMarkers, pdfPlanPageMarkings, protocols, protocolEntries, protocolOpenEntries,
          protocolEntryTypes, attachmentByPdfPlanVersion, attachmentCadFilesByPdfPlanVersion))))
      .pipe(switchMap((pdfPlanHolders) =>
        query$.pipe(
          map((query) => {
            return _.sortBy(pdfPlanHolders.filter((pdfPlanHolder) =>
              !query ||
              pdfPlanHolder.pdfPlanVersions.some((pdfPlanVersion) => pdfPlanVersion.name.toLowerCase().includes(query.toLowerCase()))
            ), [pdfPlanHolder => pdfPlanHolder.latestPdfPlanVersion?.name.toLowerCase()]);
          }
          )
        )
      ));
  }

  getPdfPlansForFolderId$(folderId: IdType, query$: Observable<string|undefined>): Observable<PdfPlanWithDeletable[]> {
    return this.getPdfPlansWithDeletableAndQuery(this.pdfPlanDataService.getByPdfPlanFolder(folderId), query$);
  }

  getAllPdfPlans$(query$: Observable<string|undefined>): Observable<PdfPlanWithDeletable[]> {
    return this.getPdfPlansWithDeletableAndQuery(this.pdfPlanDataService.data, query$);
  }

  getPdfPlanById$(pdfPlanId: IdType, query$: Observable<string|undefined>): Observable<PdfPlanWithDeletable> {
    return this.getPdfPlansWithDeletableAndQuery(this.pdfPlanDataService.getByIds([pdfPlanId]), query$).pipe(map(planArray => planArray[0]));
  }

  private toPdfPlanHolderWithDeletable(
    pdfPlan: PdfPlan,
    pdfPlanVersions: Array<PdfPlanVersion>,
    allPdfPlanPages: Array<PdfPlanPage>,
    allPdfPlanMarkers: Array<PdfPlanMarkerProtocolEntry>,
    allPdfPlanPageMarkings: Array<PdfPlanPageMarking>,
    allProtocols: Array<Protocol>,
    allProtocolEntries: Array<ProtocolEntry>,
    allProtocolOpenEntries: Array<ProtocolOpenEntry>,
    allProtocolEntryTypes: Array<ProtocolEntryType>,
    attachmentByPdfPlanVersion: Record<IdType, PdfPlanAttachment>,
    attachmentCadFilesByPdfPlanVersion: Record<IdType, PdfPlanAttachment[]>,
  ): PdfPlanWithDeletable {
    const allPdfPlanVersions = this.pdfPlanVersionDataService.filterByPdfPlanOrderByNumber(pdfPlanVersions, pdfPlan.id);
    const pdfPlanVersionsWithAttachment = new Array<PdfPlanVersionWithAttachment>();
    for (const pdfPlanVersion of allPdfPlanVersions) {
      const pdfPlanPages = allPdfPlanPages.filter((pdfPlanPage) => pdfPlanPage.pdfPlanVersionId === pdfPlanVersion.id);
      const pdfPlanMarkersReferenced = allPdfPlanMarkers.filter((pdfPlanMarker) => pdfPlanPages.some((pdfPlanPage) => pdfPlanMarker.pdfPlanPageId === pdfPlanPage.id));
      const pdfPlanPage4MarkingsReferenced = allPdfPlanPageMarkings.filter((pdfPlanPageMarking) => pdfPlanPages.some((pdfPlanPage) => pdfPlanPageMarking.pdfPlanPageId === pdfPlanPage.id));
      const protocolEntriesReferenced = allProtocolEntries.filter((protocolEntry) =>
        pdfPlanMarkersReferenced.some((pdfPlanMarker) => pdfPlanMarker.protocolEntryId === protocolEntry.id) ||
        pdfPlanPage4MarkingsReferenced.some((pdfPlanPageMarking) => pdfPlanPageMarking.protocolEntryId === protocolEntry.id));
      const openProtocolEntriesReferenced = this.filterOpenProtocolEntries(protocolEntriesReferenced, allProtocolEntryTypes);
      const {pdfPlanPageMarkings} = this.getPdfPlanMarkersAndMarkingsForPlanVersion(allPdfPlanMarkers, allPdfPlanPageMarkings, allPdfPlanPages, pdfPlanVersion);
      const hasGeneralMarkings = Boolean(pdfPlanPageMarkings.filter((pdfPlanPageMarking) => !pdfPlanPageMarking.protocolEntryId).length);
      const hasMarkings = Boolean(pdfPlanPageMarkings.length);
      const pdfPlanVersionDeletable = this.isPdfPlanVersionDeletable(allPdfPlanVersions, allPdfPlanMarkers, allPdfPlanPageMarkings, allPdfPlanPages, pdfPlanVersion, allProtocols, allProtocolEntries,
        allProtocolOpenEntries);

      pdfPlanVersionsWithAttachment.push({
        ...pdfPlanVersion,
        pdfPlanAttachment: attachmentByPdfPlanVersion[pdfPlanVersion.id],
        pdfPlanCadAttachments: attachmentCadFilesByPdfPlanVersion[pdfPlanVersion.id],
        pdfPlanMarkers: pdfPlanMarkersReferenced,
        protocolEntries: protocolEntriesReferenced,
        openProtocolEntries: openProtocolEntriesReferenced,
        hasGeneralMarkings,
        hasMarkings,
        deletable: pdfPlanVersionDeletable
      });
    }

    const latestPdfPlanVersion: PdfPlanVersionWithAttachment|undefined = _.last(pdfPlanVersionsWithAttachment);
    const deletable = pdfPlanVersionsWithAttachment.length === 0 || !pdfPlanVersionsWithAttachment.some((pdfPlanVersion) => !pdfPlanVersion.deletable);

    return {
      ...pdfPlan,
      pdfPlanVersions: [...pdfPlanVersionsWithAttachment].reverse(),
      latestPdfPlanVersion,
      deletable,
      latestPdfPlanVersionDeletable: !!latestPdfPlanVersion?.deletable
    };
  }

  private isPdfPlanVersionDeletable(allPdfPlanVersions: Array<PdfPlanVersion>, allPdfPlanMarkers: Array<PdfPlanMarkerProtocolEntry>, allPdfPlanPageMarkings: Array<PdfPlanPageMarking>,
                                    allPdfPlanPages: Array<PdfPlanPage>, pdfPlanVersion: PdfPlanVersion,
                                    allProtocols: Array<Protocol>, allProtocolEntries: Array<ProtocolEntry>, allProtocolOpenEntries: Array<ProtocolOpenEntry>): boolean {
    const pdfPlanVersions = this.pdfPlanVersionDataService.filterByPdfPlanOrderByNumber(allPdfPlanVersions, pdfPlanVersion.pdfPlanId);
    if (!this.isLatestPdfPlanVersion(pdfPlanVersions, pdfPlanVersion)) {
      return false;
    }
    const pdfPlanPageMarkingsProtocolEntry = allPdfPlanPageMarkings.filter((pdfPlanPageMarking) => pdfPlanPageMarking.protocolEntryId);
    const previousPdfPlanVersion = this.getPreviousPdfPlanVersion(pdfPlanVersions, pdfPlanVersion);
    const referenceDate = pdfPlanVersion.createdAtDb ?? pdfPlanVersion.changedAt;
    if (previousPdfPlanVersion) {
      const markersEqualToPreviousVersionAndNotChanged = this.areMarkingsEqualAndNotNewerThan(allPdfPlanMarkers, pdfPlanPageMarkingsProtocolEntry, allPdfPlanPages,
        pdfPlanVersion, previousPdfPlanVersion, allProtocols, allProtocolEntries, allProtocolOpenEntries, convertISOStringToDate(referenceDate));
      return markersEqualToPreviousVersionAndNotChanged;
    } else {
      const {pdfPlanMarkers, pdfPlanPageMarkings} = this.getPdfPlanMarkersAndMarkingsForPlanVersion(allPdfPlanMarkers, pdfPlanPageMarkingsProtocolEntry, allPdfPlanPages, pdfPlanVersion);
      return Boolean(!pdfPlanMarkers.length && !pdfPlanPageMarkings.length);
    }
  }

  private isLatestPdfPlanVersion(allPdfPlanVersions: Array<PdfPlanVersion>, pdfPlanVersion: PdfPlanVersion): boolean {
    const pdfPlanVersions = this.pdfPlanVersionDataService.filterByPdfPlanOrderByNumber(allPdfPlanVersions, pdfPlanVersion.pdfPlanId);
    if (!pdfPlanVersion || pdfPlanVersions.length < 1) {
      throw new Error('Unable to check isLatestVersion because pdfPlanVersion was not provided or pdfPlanVersions is empty.');
    }
    const index = pdfPlanVersions.findIndex((value) => value.id === pdfPlanVersion.id);
    return index === pdfPlanVersions.length - 1;
  }

  private getPreviousPdfPlanVersion(allPdfPlanVersions: Array<PdfPlanVersion>, pdfPlanVersion: PdfPlanVersion): PdfPlanVersion|undefined {
    const pdfPlanVersions = this.pdfPlanVersionDataService.filterByPdfPlanOrderByNumber(allPdfPlanVersions, pdfPlanVersion.pdfPlanId);
    if (!pdfPlanVersion || pdfPlanVersions.length <= 0) {
      throw new Error('Unable to check getPreviousVersion because pdfPlanVersion was not provided or pdfPlanVersions is empty.');
    }
    const index = pdfPlanVersions.findIndex((value) => value.id === pdfPlanVersion.id);
    const previousIndex = index - 1;
    return previousIndex < 0 ? undefined : pdfPlanVersions[previousIndex];
  }

  private areMarkingsEqualAndNotNewerThan(allPdfPlanMarkers: Array<PdfPlanMarkerProtocolEntry>, allPdfPlanPageMarkings: Array<PdfPlanPageMarking>, allPdfPlanPages: Array<PdfPlanPage>,
                                          pdfPlanVersion: PdfPlanVersion, previousPdfPlanVersion: PdfPlanVersion,
                                          allProtocols: Array<Protocol>, allProtocolEntries: Array<ProtocolEntry>, allProtocolOpenEntries: Array<ProtocolOpenEntry>, refDate: Date): boolean {
    if (!pdfPlanVersion || !previousPdfPlanVersion) {
      throw new Error('Unable to check areMarkingsEqualAndNotNewerThan since pdfPlanVersion or previousPdfPlanVersion were not provided.');
    }
    const {pdfPlanMarkers, pdfPlanPageMarkings} = this.getPdfPlanMarkersAndMarkingsForPlanVersion(allPdfPlanMarkers, allPdfPlanPageMarkings, allPdfPlanPages, pdfPlanVersion);
    const {pdfPlanMarkers: previousPdfPlanMarkers, pdfPlanPageMarkings: previousPdfPlanPageMarkings} =
      this.getPdfPlanMarkersAndMarkingsForPlanVersion(allPdfPlanMarkers, allPdfPlanPageMarkings, allPdfPlanPages, previousPdfPlanVersion);
    const diffPdfPlanMarkers = _.xorBy(pdfPlanMarkers, previousPdfPlanMarkers, 'protocolEntryId');
    const diffPdfPlanPageMarkings = _.xorBy(pdfPlanPageMarkings, previousPdfPlanPageMarkings, 'protocolEntryId');
    const pdfPlanMarkersChangedAfter = pdfPlanMarkers.filter((pdfPlanMarker) => convertISOStringToDate(pdfPlanMarker.changedAt).getTime() > refDate.getTime());
    const diffPdfPlanPageMarkingsChangedAfter = pdfPlanPageMarkings.filter((pdfPlanPageMarking) => convertISOStringToDate(pdfPlanPageMarking.changedAt).getTime() > refDate.getTime());

    const protocolEntryIds = _.compact(_.uniq(pdfPlanMarkers.map((value) => value.protocolEntryId)
      .concat(pdfPlanPageMarkings.map((value) => value.protocolEntryId))));
    const anyProtocolsSince = this.anyProtocolsClosedSince(protocolEntryIds, allProtocols, allProtocolEntries, allProtocolOpenEntries, refDate);

    return !diffPdfPlanMarkers.length && !diffPdfPlanPageMarkings.length && !pdfPlanMarkersChangedAfter.length && !diffPdfPlanPageMarkingsChangedAfter.length && !anyProtocolsSince;
  }

  private anyProtocolsClosedSince(protocolEntryIds: Array<IdType>, allProtocols: Array<Protocol>, allProtocolEntries: Array<ProtocolEntry>, allProtocolOpenEntries: Array<ProtocolOpenEntry>,
                                  refDate: Date): boolean {
    const protocolEntries = allProtocolEntries.filter((value) => protocolEntryIds.includes(value.id));
    const protocolOpenEntries = allProtocolOpenEntries.filter((value) => protocolEntryIds.includes(value.protocolEntryId));
    const protocolIds: Array<IdType> = _.compact(_.uniq(protocolEntries.map((value) => value.protocolId).concat(protocolOpenEntries.map((value) => value.protocolId))));
    const closedProtocols = allProtocols.filter((protocol) => protocolIds.includes(protocol.id) && protocol.closedAt);
    return closedProtocols.some((protocol) => convertISOStringToDate(protocol.closedAt).getTime() > refDate.getTime());
  }

  private getPdfPlanMarkersAndMarkingsForPlanVersion(allPdfPlanMarkers: Array<PdfPlanMarkerProtocolEntry>, allPdfPlanPageMarkings: Array<PdfPlanPageMarking>, allPdfPlanPages: Array<PdfPlanPage>,
                                                     pdfPlanVersion: PdfPlanVersion):
    {pdfPlanMarkers: Array<PdfPlanMarkerProtocolEntry>, pdfPlanPageMarkings: Array<PdfPlanPageMarking>} {
    const pdfPlanPageIds = allPdfPlanPages.filter((pdfPlanPage) => pdfPlanVersion.id === pdfPlanPage.pdfPlanVersionId).map((pdfPlanPage) => pdfPlanPage.id);
    const pdfPlanMarkers = allPdfPlanMarkers.filter((pdfPlanMarker) => pdfPlanPageIds.includes(pdfPlanMarker.pdfPlanPageId));
    const pdfPlanPageMarkings = allPdfPlanPageMarkings.filter((pdfPlanPageMarking) => pdfPlanPageIds.includes(pdfPlanPageMarking.pdfPlanPageId));
    return {pdfPlanMarkers, pdfPlanPageMarkings};
  }

  private filterOpenProtocolEntries(protocolEntries: ProtocolEntry[], protocolEntryTypes: ProtocolEntryType[]): ProtocolEntry[] {
    return protocolEntries.filter((protocolEntry) => {
      const protocolEntryType = protocolEntryTypes.find((value) => value.id === protocolEntry.typeId);
      const status = this.protocolEntryService.getProtocolEntryIconStatusByEntry(protocolEntry, protocolEntryType);
      return status === ProtocolEntryIconStatus.OPEN || status === ProtocolEntryIconStatus.ON_HOLD;
    });
  }

  private async confirmDeletePdfPlanVersion(planName: string): Promise<boolean> {
    const  header = this.translateService.instant('project_room.pdf_plan_holders.confirmDeletePdfPlanVersionHeader');
    const  message = this.translateService.instant('project_room.pdf_plan_holders.confirmDeletePdfPlanVersionMessage', { planName });
    return await this.confirmDelete(header, message);
  }

  private async confirmDelete(header: string, message: string): Promise<boolean> {
    return await this.alertService.confirm({
      header,
      message,
      confirmButton: {
        color: 'danger',
        fill: 'solid',
      },
      confirmLabel: 'button.delete',
    });
  }

  async deleteLatestPdfPlanVersions(pdfPlans: PdfPlanWithDeletable[]): Promise<void> {
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Delete PDF Plans (ids=${pdfPlans.map((plan) => plan?.id)})`);
    if (!(await this.alertService.confirm({
      header: 'project_room.pdf_plan_holders.confirmMultipleDelete.header',
      message: 'project_room.pdf_plan_holders.confirmMultipleDelete.message',
      confirmButton: {
        color: 'danger',
        fill: 'solid',
      },
      confirmLabel: 'button.delete',
    }))) {
      logInstance.success('canceled');
      return;
    }

    await this.loadingService.withLoading(async () => {
      await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
      for (const pdfPlan of pdfPlans) {
        try {
          if (!pdfPlan.latestPdfPlanVersion) {
            throw new Error(`Unable to delete latestPdfPlanVersion of PdfPlan ${pdfPlan.id} since it does not have a latestPdfPlanVersion.`);
          }
          await this.deletePdfPLanVersionInternal(pdfPlan.latestPdfPlanVersion, false);
        } catch (e) {
          logInstance.failure(e);
          await this.toastService.errorWithMessageAndHeader('project_room.pdf_plan_holders.multipleDeleteFailed', convertErrorToMessage(e) || '');
          throw e;
        }
      }
      await this.toastService.infoWithMessageAndHeader('project_room.pdf_plan_holders.multipleDeleteSuccess.header', 'project_room.pdf_plan_holders.multipleDeleteSuccess.message');
      logInstance.success();
    }, {message: this.translateService.instant('project_room.pdf_plan_holders.deletingPdfPlanVersions')});
  }

  async deletePdfPlan(pdfPlan: PdfPlan): Promise<boolean> {
    const latestPdfPlanVersion = await observableToPromise(this.pdfPlanVersionDataService.getLatestByPdfPlan$(pdfPlan.id));
    if (!(await this.confirmDeletePdfPlanVersion(latestPdfPlanVersion?.name))) {
      return;
    }
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Delete PDF Plan (pdfPlanId=${pdfPlan.id})`);

    try {
      await this.loadingService.withLoading(async () => {
        await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
        await this.pdfPlanService.deletePdfPlan(pdfPlan);
      }, {message: this.translateService.instant('project_room.pdf_plan_holders.deletingPdfPlan')});
      await this.toastService.info('project_room.pdf_plan_holders.deleteSuccessful');
      logInstance.success();
      return true;
    } catch (error) {
      logInstance.failure(error);
      await this.toastService.toastWithTranslateParams('project_room.pdf_plan_holders.deleteFailed', { message: convertErrorToMessage(error) || '' }, ToastDurationInMs.ERROR);
      throw error;
    }
  }

  async deletePdfPLanVersion(pdfPlanVersion: PdfPlanVersion): Promise<boolean> {
    if (!pdfPlanVersion) {
      throw new Error('deletePdfPLanVersion - pdfPLanVersion was not provided.');
    }
    const pdfPlanId = pdfPlanVersion.pdfPlanId;
    const latestPdfPlanVersion = await observableToPromise(this.pdfPlanVersionDataService.getLatestByPdfPlan$(pdfPlanId));
    if (!latestPdfPlanVersion) {
      await this.toastService.error('project_room.pdf_plan_holders.deleteNotPossibleNoVersions');
      return false;
    } else if (latestPdfPlanVersion.id !== pdfPlanVersion.id) {
      await this.toastService.error('project_room.pdf_plan_holders.deleteNotPossibleNotLatestVersion');
      return false;
    }
    if (!(await this.confirmDeletePdfPlanVersion(pdfPlanVersion.name))) {
      return false;
    }
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Delete PDF Plan (pdfPlanId=${pdfPlanId})`);
    try {
      this.loadingService.withLoading(async () => {
        await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
        const success = await this.deletePdfPLanVersionInternal(pdfPlanVersion);
        if (success) {
          await this.toastService.info('project_room.pdf_plan_holders.deleteSuccessful');
          logInstance.success();
          return true;
        }
        logInstance.failure(`Delete of planVersion ${pdfPlanVersion?.id} not possible`);
        return false;
      }, {message: this.translateService.instant('project_room.pdf_plan_holders.deletingPdfPlanVersion')});
    } catch (error) {
      logInstance.failure(error);
      await this.toastService.toastWithTranslateParams('project_room.pdf_plan_holders.deleteFailed', { message: convertErrorToMessage(error) || '' }, ToastDurationInMs.ERROR);
      throw error;
    }
  }

  async deletePdfPLanVersionInternal(pdfPlanVersion: PdfPlanVersion, showToastInsteadOfThrow = true): Promise<boolean> {
    const deletable = this.isPdfPlanVersionDeletable(...(await observableToPromise(combineLatestAsync([
      this.pdfPlanVersionDataService.data,
      this.pdfPlanMarkerProtocolEntryDataService.data,
      this.pdfPlanPageMarkingDataService.data,
      this.pdfPlanPageDataService.data,
      of(pdfPlanVersion),
      this.protocolDataService.dataWithoutHidden$,
      this.protocolEntryDataService.data,
      this.protocolOpenEntryDataService.data,
    ]))));
    if (!deletable) {
      if (showToastInsteadOfThrow) {
        await this.toastService.error('project_room.pdf_plan_holders.deleteNotPossible');
        return false;
      } else {
        throw new Error(`PdfPlanVersion ${pdfPlanVersion.id} is not deletable.`);
      }
    }
    await this.pdfPlanService.deletePdfPlanVersion(pdfPlanVersion, true);
    return true;
  }

  async changePdfPlanActive(pdfPlan: PdfPlan, activeNewValue: boolean) {
    pdfPlan.active = activeNewValue;
    const currentProject = await this.projectDataService.getCurrentProject();
    await this.pdfPlanDataService.update(pdfPlan, currentProject.id);
  }

  async savePdfPlans(plans: PdfPlanWithDeletable[]): Promise<void> {
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    const pdfPlanVersions = plans.filter((plan) => plan.latestPdfPlanVersion).map((plan) => plan.latestPdfPlanVersion);
    await this.pdfPlanVersionDataService.update(pdfPlanVersions, currentProject.id);
  }

  async openEditPdfPlanCommentModal(pdfPlan: PdfPlanWithDeletable, form?: UntypedFormGroup|undefined): Promise<PdfPlanWithDeletable|undefined> {
    if (!pdfPlan.latestPdfPlanVersion) {
      pdfPlan = {
        ...pdfPlan,
        latestPdfPlanVersion: {
          ...await observableToPromise(this.pdfPlanVersionDataService.getLatestByPdfPlan$(pdfPlan.id)),
          deletable: false,
          pdfPlanMarkers: [],
          protocolEntries: [],
          openProtocolEntries: [],
          hasGeneralMarkings: false,
          hasMarkings: false,
        }
      };
    }

    if (!pdfPlan.latestPdfPlanVersion) {
      throw new Error(`Cannot edit comment for pdfPlan ${pdfPlan.id}, because there is no latest pdf plan version for that.`);
    }

    const modal = await this.modalController.create({
      component: PdfPlanCommentModalComponent,
      componentProps: { pdfPlan, form },
      cssClass: 'omg-modal',
      backdropDismiss: false,
    });

    await modal.present();

    const { role, data } = await modal.onDidDismiss<{ pdfPlan: PdfPlanWithDeletable }>();
    if (role === 'save' && data?.pdfPlan) {
      return data.pdfPlan;
    }

    return undefined;
  }

  async updatePdfPlansFolder(plans: PdfPlan[], folderId: IdType) {
    const folder = await observableToPromise(this.pdfPlanFolderDataService.getById(folderId));
    if (!folder) {
      throw new Error(`Folder with id ${folderId} does not exist!`);
    }
    await this.pdfPlanDataService.update(plans.map((plan) => ({
      ...plan,
      folderId,
    })), folder.projectId);
  }

  async sharePdfPlanVersions(
    data: {
      profileIds: IdType[];
      emailSettings: SharePdfPlanVersionsEmailSettings;
      planVersionIds: IdType[];
    },
    projectId: IdType
  ) {
    return await observableToPromise(this.http.post<{success: true}>(`${environment.serverUrl}${SHARE_PDF_PLANS_ENDPOINT}?projectId=${projectId}`, data));
  }

}
