<ion-list appLicenseType #lt="appLicenseType" class="omg-boundary omg-hoverable ion-no-padding">
  <ion-item>
    <div [appLayout]="0.75" class="icon-label">
      <ion-text color="inactive">
        <fa-icon [fixedWidth]="true" [icon]="['fas', 'folder']"></fa-icon>
      </ion-text>
      <ion-text color="text-primary" class="size-sm">{{ 'project_room.pdf_plan_folder.folder_structure' | translate }}</ion-text>
    </div>
  </ion-item>
  <ion-item class="cursor-pointer" (click)="navigateToFolder()" [class.active-item]="(routeFolderId$ | async) === ALL_FOLDERS_PAGE_SLUG">
    <div [appLayout]="0.75" class="icon-label">
      <fa-icon [fixedWidth]="true" [icon]="['fal', 'folder']"></fa-icon>
      <ion-text appEllipsisTooltip bindToClosest="ion-item">{{ 'project_room.pdf_plan_folder.all_plans' | translate }}</ion-text>
    </div>
    <div slot="end" [appLayout]="0.5" class="end-slot">
      <app-chip-with-label [chip]="(allPdfPlanHoldersCount$ | async) || ''" color="neutral-badge">
        <div *ngIf="(folderIdsWithNewPlans$ | async)?.length > 0" class="new-tag"></div>
      </app-chip-with-label>
      <ion-button *appFeatureEnabled="false; forConnected true; forLicenses [lt.LicenseType.VIEWER]"
                  class="pdf-plans-open-context-menu-button three-dots omg-btn-text" disabled>
        <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
      </ion-button>
    </div>
  </ion-item>
  <ion-item *ngFor="let pdfPlanFolder of pdfPlanFolders$ | async; trackBy: trackById" class="cursor-pointer" (click)="navigateToFolder(pdfPlanFolder)" [class.active-item]="(routeFolderId$ | async) === pdfPlanFolder.id">
    <div [appLayout]="0.75" class="icon-label">
      <fa-icon [fixedWidth]="true" [icon]="(pdfPlanFolder.icon | iconToArray) || ['fal', 'folder']"></fa-icon>
      <ion-text appEllipsisTooltip bindToClosest="ion-item">{{pdfPlanFolder.name}}</ion-text>
    </div>
    <div slot="end" [appLayout]="0.5" class="end-slot">
      <app-chip-with-label [chip]="'' + (pdfPlanFolder.pdfPlanHolderCount > 999 ? '999+' : pdfPlanFolder.pdfPlanHolderCount)" color="neutral-badge">
        <div *ngIf="(folderIdsWithNewPlans$ | async)?.includes(pdfPlanFolder.id)" class="new-tag"></div>
      </app-chip-with-label>
      <ion-button *appFeatureEnabled="false; forConnected true; forLicenses [lt.LicenseType.VIEWER]"
              (click)="onClickEllipsis($event, pdfPlanFolder)" class="pdf-plans-open-context-menu-button three-dots omg-btn-text">
        <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
      </ion-button>
    </div>
  </ion-item>
</ion-list>
