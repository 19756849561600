<ion-button expand="block" class="selector omg-btn-primary omg-btn-color-neutral">
  <div class="selector__label">
    <fa-icon [icon]="['fal', 'list']"></fa-icon>
    <span [ngClass]="labelTextClass ? labelTextClass : 'ion-hide-lg-down'">
      {{'projects.title' | translate}}
    </span>
  </div>
  <ion-text><fa-icon [icon]="['fal','link']" *ngIf="selectedProjectClient?.type === clientType.CONNECTED"></fa-icon></ion-text>
  <ionic-selectable #projectSelectorComponent [(ngModel)]="selectedProject" [items]="projects"
    placeholder="Select project" closeButtonText="Cancel" closeButtonSlot="end" itemValueField="id" itemTextField="displayName"
    [hasVirtualScroll]="true" [virtualScrollHeaderFn]="getGroupText" groupValueField="recentlyUsed" groupTextField="recentlyUsed"
                    [canSearch]="true" searchPlaceholder="{{'search' | translate}}" searchFailText="{{'noItemsFound' | translate}}"
    (onSelect)="onSelectProject($event)" (onOpen)="onOpen($event)" (onClose)="onClose($event)" (onChange)="onSelectedProjectChanged($event)" [className]="'selector__name'" modalCssClass="project-selector" appSelectableCommon
    #fs="appSelectableCommon" [shouldFocusSearchbar]="fs.isDesktop">

    <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
      <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
    </ng-template>
    <ng-template ionicSelectableHeaderTemplate>
      <app-selectable-header [title]="'projectSelector.title' | translate"></app-selectable-header>
    </ng-template>
    <ng-template ionicSelectableFooterTemplate>
      <app-selectable-footer [canClear]="false">
        <ng-template appProjectSelectorFooterStartTemplate>
          <ion-button routerLink="/projects" class="omg-btn-secondary" (click)="projectSelectorComponent.close()">
            {{ 'projectSelector.go_to_project_overview' | translate }}
          </ion-button>
        </ng-template>
      </app-selectable-footer>
    </ng-template>
    <ng-template ionicSelectableItemTemplate let-project="item" let-isSelected="isItemSelected">
      <app-project-selector-item [project]="project"></app-project-selector-item>
    </ng-template>
    <ng-template ionicSelectableValueTemplate let-project="value">
      <div class="project-display-name-button">
        <span>{{project.displayName}}</span>
        <fa-icon [icon]="['fal', 'chevron-down']" class="margin-left-10"></fa-icon>
      </div>
    </ng-template>
    <ng-template ionicSelectableGroupTemplate let-group="group">
      {{ getGroupTextSimple(group) }}
    </ng-template>
  </ionic-selectable>
</ion-button>
