import {Component, Input} from '@angular/core';
import {map} from 'rxjs/operators';
import {EmptyUserEmailSignature} from 'src/app/model/email-signature-form-model';
import {AttachmentUserEmailSignatureDataService} from '../../../../services/data/attachment-user-email-signature-data.service';

@Component({
  selector: 'app-email-signature-raw-preview',
  templateUrl: './email-signature-raw-preview.component.html',
  styleUrls: ['./email-signature-raw-preview.component.scss'],
})
export class EmailSignatureRawPreviewComponent {

  @Input()
  signature = { ...EmptyUserEmailSignature };

  get textLines() {
    return this.signature.text.split(/\r\n|\r|\n/g);
  }

  userEmailSignatureImage$ = this.attachmentUserEmailSignatureDataService.dataReally.pipe(map(([image]) => image));

  constructor(private attachmentUserEmailSignatureDataService: AttachmentUserEmailSignatureDataService) { }

}
