import { Component, HostBinding, Input } from '@angular/core';
import {IonicSelectableComponent} from 'ionic-selectable';

@Component({
  selector: 'app-selectable-item-icon',
  templateUrl: './selectable-item-icon.component.html',
  styleUrls: ['./selectable-item-icon.component.scss'],
})
export class SelectableItemIconComponent {

  @HostBinding('slot')
  readonly slot = 'start';

  @Input()
  selected: boolean;

  constructor(public selectable: IonicSelectableComponent) {}

}
