import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SelectTargetProtocolModalComponent} from 'src/app/components/entry/select-target-protocol-modal/select-target-protocol-modal.component';
import {ProtocolWithTypeAndLayout} from 'src/app/model/protocol';
import {ProtocolInList} from 'src/app/model/protocol-in-list';
import {PROTOCOL_LAYOUT_NAME_STANDARD, ToastDurationInMs} from 'src/app/shared/constants';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {isTaskProtocol} from 'src/app/utils/protocol-utils';
import {IdType, Protocol, ProtocolEntry, PROTOCOL_LAYOUT_NAME_CONTINUOUS} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {ToastOptions} from '../common/toast-service.interface';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {ProtocolLayoutDataService} from '../data/protocol-layout-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {SystemEventService} from '../event/system-event.service';
import { PosthogService } from '../posthog/posthog.service';
import {ProtocolEntryService} from '../protocol/protocol-entry.service';
import {ProtocolService} from '../protocol/protocol.service';
import {OmgToastService} from '../ui/omg-toast.service';
import {EntryService} from './entry.service';

const LOG_SOURCE = 'MoveEntryService';

@Injectable({
  providedIn: 'root'
})
export class MoveEntryService {

  readonly onlyStandardAndContinuousProtocolFn = (protocols: ProtocolInList[]) =>
    protocols.filter((protocol) => protocol.protocolLayout.name === PROTOCOL_LAYOUT_NAME_STANDARD || protocol.protocolLayout.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS);

  readonly protocolsWithTypeAndLayoutAndTasks$ = combineLatestAsync([
    this.protocolService.openProtocolsWithTypeAndLayout$,
    this.protocolTypeDataService.dataGroupedById,
    this.protocolLayoutDataService.dataGroupedById,
    this.entryService.taskProtocol$,
    this.translateService.get('Tasks')
  ]).pipe(map(([protocols, protocolTypeById, protocolLayoutById, taskProtocol, tasksLabel]) => {
    const result: (ProtocolWithTypeAndLayout & Partial<ProtocolInList>)[] = [
      {
        ...taskProtocol,
        displayName: tasksLabel,
        protocolType: protocolTypeById?.[taskProtocol.typeId],
        protocolLayout: protocolLayoutById?.[protocolTypeById[taskProtocol.typeId]?.layoutId],
        groupSubtext: 'tasks.assignToProtocol.groupSubtext.task',
        inGroup: 'task',
      },
      ...protocols,
    ];

    return result;
  }));

  constructor(
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolDataService: ProtocolDataService,
    private systemEventService: SystemEventService,
    private protocolEntryService: ProtocolEntryService,
    private projectDataService: ProjectDataService,
    private modalController: ModalController,
    private protocolService: ProtocolService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private protocolLayoutDataService: ProtocolLayoutDataService,
    private entryService: EntryService,
    private translateService: TranslateService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private toastService: OmgToastService,
    private loggingService: LoggingService,
    private router: Router,
    private posthogService: PosthogService
  ) { }

  moveTasks(entryIds: IdType[]) {
    return this.moveEntries(entryIds, {
      toastTranslationKeys: {
        getMessage: () => 'entry.move.successFromTasksToProtocol.message',
        getMessageParams: (targetProtocol) => ({
          toProtocolName: targetProtocol.name
        }),
        getActionText: () => 'entry.move.successFromTasksToProtocol.action',
        getAction: (targetProtocol) => {
          this.router.navigate(['/protocols', 'view', targetProtocol.id]);
        }
      },
      modalOptions: {
        mapProtocols: this.onlyStandardAndContinuousProtocolFn,
        selectedCountTranslationKey: 'tasks.assignToProtocol.selectedTasksCount',
        allSubEntriesWillMoveTranslationKey: 'tasks.assignToProtocol.allSubTasksWillBeMoved',
        withoutParentWillSkipTranslationKey: 'tasks.assignToProtocol.subTasksSelectedWithoutParent',
      }
    });
  }

  moveProtocolEntries(entryIds: IdType[], sourceProtocol: Protocol) {
    const protocolsWithTypeAndLayout$ = this.protocolsWithTypeAndLayoutAndTasks$.pipe(map((protocols) =>
      protocols.filter((protocol) => protocol.id !== sourceProtocol.id)));
    return this.moveEntries(entryIds, {
      toastTranslationKeys: {
        getMessage: (targetProtocol) => isTaskProtocol(targetProtocol) ? 'entry.move.successFromProtocolToTasks.message' : 'entry.move.successFromProtocolToProtocol.message',
        getMessageParams: (targetProtocol) => isTaskProtocol(targetProtocol) ? ({
          fromProtocolName: sourceProtocol.name
        }) : ({
          fromProtocolName: sourceProtocol.name,
          toProtocolName: targetProtocol.name
        }),
        getActionText: (targetProtocol) => isTaskProtocol(targetProtocol) ? 'entry.move.successFromProtocolToTasks.action' : 'entry.move.successFromProtocolToProtocol.action',
        getAction: (targetProtocol) => {
          if (isTaskProtocol(targetProtocol)) {
            this.router.navigate(['/tasks', 'card', 'entry', entryIds[0]]);
          } else {
            this.router.navigate(['/protocols', 'view', targetProtocol.id]);
          }
        }
      },
      modalOptions: {
        mapProtocols: this.onlyStandardAndContinuousProtocolFn,
        protocolsWithTypeAndLayout$,
        boldTitle: 'tasks.moveToProtocol.title',
        title: 'tasks.moveToProtocol.subtitle',
      }
    });
  }

  async moveEntries(entryIds: IdType[], {
    toastTranslationKeys: {
      getMessage: getToastSuccessMessage,
      getMessageParams: getToastSuccessMessageParams,
      getActionText: getToastSuccessActionText,
      getAction: getToastSuccessAction,
    },
    modalOptions: {
      mapProtocols,
      boldTitle,
      title,
      protocolsWithTypeAndLayout$,
      selectedCountTranslationKey,
      allSubEntriesWillMoveTranslationKey,
      withoutParentWillSkipTranslationKey,
    } = {}
  }: {
    toastTranslationKeys: {
      getMessage: (protocol: Protocol) => string;
      getMessageParams: (protocol: Protocol) => any;
      getActionText?: (protocol: Protocol) => string;
      getAction?: (protocol: Protocol) => void | Promise<void>;
    },
    modalOptions?: {
      mapProtocols?: (protocols: ProtocolInList[]) => ProtocolInList[];
      boldTitle?: string;
      title?: string;
      protocolsWithTypeAndLayout$?: Observable<(ProtocolWithTypeAndLayout & Partial<ProtocolInList>)[]>;
      selectedCountTranslationKey?: string;
      allSubEntriesWillMoveTranslationKey?: string;
      withoutParentWillSkipTranslationKey?: string;
    }
  }) {
    const entries = await observableToPromise(this.protocolEntryDataService.getByIds(entryIds));
    const parentEntryIds = _.uniq([
      ...entries.filter((entry) => entry.parentId && entryIds.includes(entry.parentId)).map((entry) => entry.parentId),
      ...entries.filter((entry) => !entry.parentId).map((entry) => entry.id),
    ]);
    const subEntries = await observableToPromise(this.protocolEntryDataService.getSubEntriesByParentEntryIds(parentEntryIds));
    const entryIdsToMove = [...parentEntryIds, ...subEntries.map(({id}) => id)];
    const entriesToMove = entries.filter(entry => entryIdsToMove.includes(entry.id));

    const hasAllChildren = subEntries.every(({id}) => entryIds.includes(id));
    const hasAllParents = entries.every(({parentId}) => !parentId || entryIds.includes(parentId));

    const modal = await this.modalController.create({
      component: SelectTargetProtocolModalComponent,
      cssClass: 'omg-boundary omg-modal omg-in-modal-list',
      componentProps: {
        mapProtocols,
        boldTitle,
        title,
        protocolsWithTypeAndLayout$,
        hasAllChildren,
        hasAllParents,
        entryIds,
        entryIdsToMove,
        entriesToMove,
        selectedCountTranslationKey,
        allSubEntriesWillMoveTranslationKey,
        withoutParentWillSkipTranslationKey,
      },
    });

    await modal.present();

    const {role, data} = await modal.onWillDismiss();

    if (role === 'confirm-target') {
      try {
        const {destinationProtocol} = await this.moveEntriesToProtocol(entryIdsToMove, data);
        const buttons: ToastOptions['buttons'] = [];
        if (getToastSuccessActionText && getToastSuccessAction) {
          const text = getToastSuccessActionText(destinationProtocol);
          if (text) {
            buttons.push({
              text: this.translateService.instant(text),
              handler: () => getToastSuccessAction(destinationProtocol),
            });
          }
        }
        this.toastService.toastWithTranslateParamsAndButton(
          getToastSuccessMessage(destinationProtocol),
          {
            ...getToastSuccessMessageParams(destinationProtocol),
            count: entryIdsToMove.length,
          },
          ToastDurationInMs.INFO_WITH_MESSAGE,
          buttons
        );
        this.posthogService.captureEvent('Tasks moved', {
          amount: entryIdsToMove.length
        });
        return true;
      } catch (e) {
        this.loggingService.error(LOG_SOURCE, `moveEntries failed - ${convertErrorToMessage(e)}`);
        this.systemEventService.logErrorEvent(LOG_SOURCE, `moveEntries failed - ${convertErrorToMessage(e)}`);
        this.toastService.error('error_saving_message');
        throw e;
      }
    }
    return false;
  }

  async moveEntriesToProtocol(entryIds: IdType[], destinationProtocolId: IdType): Promise<{destinationProtocol: Protocol}> {
    const logAction = this.systemEventService.logAction(LOG_SOURCE, 'Move entries');
    logAction.logCheckpoint(() => `Moving ${entryIds.join(', ')} to ${destinationProtocolId}`);
    try {
      const [entries, destinationProtocol] = await observableToPromise(combineLatestAsync([this.protocolEntryDataService.getByIds(entryIds), this.protocolDataService.getById(destinationProtocolId)]));

      if (entries.length !== entryIds.length) {
        throw new Error(`Cannot move entries: Got ${entries.length} entries, but requested for ${entryIds.length} entries.`);
      }

      if (!destinationProtocol) {
        throw new Error(`Cannot move entries: protocol ${destinationProtocolId} not found`);
      }

      await this.performMoveEntriesToProtocol(entries, destinationProtocol);

      logAction.success();

      return {destinationProtocol};
    } catch (e) {
      logAction.failure(e);
      throw e;
    }
  }

  async performMoveEntriesToProtocol(entries: ProtocolEntry[], destinationProtocol: Protocol) {
    const mapEntry = await this.getMapEntryFn(destinationProtocol);

    let lastNumber = await this.protocolEntryService.getNextNumber(destinationProtocol);
    const movedEntries = _.orderBy(entries, ['parentId', 'number'], ['asc', 'asc']).map((entry) => mapEntry({
      ...entry,
      number: entry.parentId ? entry.number : lastNumber++,
      protocolId: destinationProtocol.id,
    }));

    await this.protocolEntryDataService.update(movedEntries, (await this.projectDataService.getMandatoryCurrentProject()).id);
  }

  private async getMapEntryFn(destinationProtocol: Protocol) {
    if (!isTaskProtocol(destinationProtocol)) {
      return (e: ProtocolEntry) => e;
    }

    const [protocolEntryTypeById, defaultEntryType] = await observableToPromise(combineLatestAsync([
      this.protocolEntryTypeDataService.dataGroupedById,
      this.protocolEntryTypeDataService.taskEntryType$
    ]));
    if (!protocolEntryTypeById) {
      throw new Error('Couldn\'t find protocol entry types by id!');
    }
    if (!defaultEntryType) {
      throw new Error('Couldn\'t find default entry type!');
    }
    return (entry: ProtocolEntry) => ({
      ...entry,
      // If the destination protocol is a task protocol, we need to ensure typeId is defined and not set to info field
      typeId: !entry.typeId || !protocolEntryTypeById[entry.typeId]?.statusFieldActive ? defaultEntryType.id : entry.typeId,
    });
  }
}
