import { Pipe, PipeTransform } from '@angular/core';
import {convertISOStringToDate} from '../utils/date-utils';

@Pipe({
  name: 'daysAgo'
})
export class DaysAgoPipe implements PipeTransform {

  transform(value: Date|string|undefined|null, ...args: unknown[]): number|undefined|null {
    if (value === undefined) {
      return undefined;
    }
    if (value === null) {
      return null;
    }
    const date = convertISOStringToDate(value);
    return Math.round((new Date().getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
  }

}
