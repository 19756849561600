import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user/user.service';
import {filter, map} from 'rxjs/operators';
import {LicenseType} from 'submodules/baumaster-v2-common';

@Injectable({
  providedIn: 'root'
})
export class ReportGuard  {
  constructor(private userService: UserService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.currentUser$.pipe(filter((user) => Boolean(user)), map((user) => user.role === LicenseType.BASIC || user.role === LicenseType.PROFESSIONAL || !!user.assignedReportRights));
  }
}
