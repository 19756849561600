import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {AttachmentBimMarkerScreenshotDataService} from 'src/app/services/data/attachment-bim-marker-screenshot-data.service';
import {BimMarkerDataService} from 'src/app/services/data/bim-marker-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {AttachmentBimMarkerScreenshot, BimMarker, BimVersion, IdType, ProtocolEntry} from 'submodules/baumaster-v2-common';
import {AutodeskViewerComponent} from '../autodesk-viewer/autodesk-viewer.component';
import _ from 'lodash';
import {LoggingService} from '../../../services/common/logging.service';
import {convertErrorToMessage} from '../../../shared/errors';
import {ToastService} from '../../../services/common/toast.service';
import {ProtocolEntryService} from '../../../services/protocol/protocol-entry.service';
import {BimMarkerChanges, BimMarkerCreation, BimViewerComponentReturnType, isBimMarkerCreation, toBimMarker} from '../../../model/bim-plan-with-deletable';

const LOG_SOURCE = 'BimViewerComponent';

@Component({
  selector: 'app-bim-viewer',
  templateUrl: './bim-viewer.component.html',
  styleUrls: ['./bim-viewer.component.scss'],
  standalone: true,
  imports: [AutodeskViewerComponent]
})
export class BimViewerComponent implements OnInit, OnDestroy {

  @Input() returnType: BimViewerComponentReturnType = BimViewerComponentReturnType.PERSIST_CHANGES;
  @Input() selectedProtocolEntry: ProtocolEntry|undefined;
  @Input() selectedBimVersion: BimVersion;
  @Input() acrossProjects = false;
  @Input() modal: HTMLIonModalElement|undefined;
  @Input() readonly = false;

  @Input() @Output() markers: Array<BimMarker|BimMarkerCreation>|undefined;
  @Output() protocolEntryMarkerClick = new EventEmitter<IdType>();
  @ViewChild(AutodeskViewerComponent) viewer: AutodeskViewerComponent;

  isClosedEntry: boolean|undefined;
  private bimMarkerSubscription: Subscription|undefined;
  private isCurrentProtocolEntryClosedSubscription: Subscription|undefined;

  constructor(private bimMarkerDataService: BimMarkerDataService,
              private attachmentBimMarkerScreenshotDataService: AttachmentBimMarkerScreenshotDataService,
              private projectDataService: ProjectDataService,
              private loggingService: LoggingService,
              private toastService: ToastService,
              private protocolEntryService: ProtocolEntryService,
              ){}

  async ngOnInit() {
    if (this.selectedProtocolEntry?.id) {
      if (this.markers) {
        throw new Error(LOG_SOURCE + ' - selectedProtocolEntry and markers provided. It should not be both provided.');
      }
      const data$ = this.acrossProjects ? this.bimMarkerDataService.dataByBimVersionIdAcrossProjects$ : this.bimMarkerDataService.dataByBimVersionId$;
      this.bimMarkerSubscription = data$.subscribe(bimMarkers => {
        this.markers = bimMarkers ? bimMarkers[this.selectedBimVersion.id]?.filter((bimMarker) => bimMarker.protocolEntryId === this.selectedProtocolEntry.id) : undefined;
      });
      this.isCurrentProtocolEntryClosedSubscription = this.protocolEntryService.isProtocolEntryClosed(this.selectedProtocolEntry?.id).subscribe((isClosedEntry) => this.isClosedEntry = isClosedEntry);
    }
  }

  ngOnDestroy(){
    this.bimMarkerSubscription.unsubscribe();
    this.isCurrentProtocolEntryClosedSubscription.unsubscribe();
    this.markers = [];
  }

  ionViewDidEnter() {
    this.viewer.ionViewDidEnter();
  }

  ionViewWillLeave() {
    this.viewer.ionViewWillLeave();
  }

  public async markerChanges(changes: BimMarkerChanges){
    if (this.returnType !== BimViewerComponentReturnType.PERSIST_CHANGES) {
      return;
    }
    if (changes.delete) {
      await this.deleteMarker(changes.delete);
    }
    if (changes.insert) {
      await this.createMarker(changes.insert);
    }
  }

  private async createMarker(dataOrDataArray: BimMarkerCreation | Array<BimMarkerCreation>){
    const project = await this.projectDataService.getCurrentProject();
    const projectId = project.id;
    const dataArray: Array<BimMarkerCreation> = _.isArray(dataOrDataArray) ? dataOrDataArray : [dataOrDataArray];
    for (const data of dataArray) {
      const bimMarker = toBimMarker(data);
      await this.bimMarkerDataService.insert({
        ...bimMarker,
      }, projectId);
      const attachment: AttachmentBimMarkerScreenshot = {
        ...data.attachment,
        bimMarkerId: bimMarker.id,
        projectId
      };
      await this.attachmentBimMarkerScreenshotDataService.insert(attachment, projectId, undefined, [data.blob]);
    }
  }

  private async deleteMarker(markerOrMarkers: BimMarker|BimMarkerCreation|Array<BimMarker|BimMarkerCreation>){
    try {
      let markers: Array<BimMarker> = _.isArray(markerOrMarkers) ? markerOrMarkers : [markerOrMarkers];
      markers = markers.filter((marker) => !isBimMarkerCreation(marker) && marker.id);
      if (!markers.length) {
        return;
      }
      const project = await this.projectDataService.getCurrentProject();
      const projectId = project.id;
      let screenshots = new Array<AttachmentBimMarkerScreenshot>();
      for (const marker of markers) {
        screenshots = screenshots.concat(await observableToPromise(this.attachmentBimMarkerScreenshotDataService.getByBimMarkerId$(marker.id, this.acrossProjects)));
      }
      screenshots = _.uniqBy(screenshots, 'id');
      if (screenshots.length) {
        await this.attachmentBimMarkerScreenshotDataService.delete(screenshots, projectId);
      }
      await this.bimMarkerDataService.delete(markers, projectId);
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error deleting bimMarker: ${convertErrorToMessage(error)}`);
      this.toastService.error('error_deleting_message');
    }
  }

  public emitOpenEntryModal(entryId: IdType){
    this.protocolEntryMarkerClick.emit(entryId);
  }

}
