<app-modal-header [dismissModalOnClose]="false" (close)="onCancel()">
</app-modal-header>

<ion-content class="app-recording-content with-padding">
  <ion-grid class="grid-content">
    <ion-row class="parent-row">
      <ion-col size="auto">
        <div>
          <fa-icon class="app-audio-recording" size="10x" [icon]="['fal', 'microphone']"></fa-icon>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="parent-row">
      <ion-col>
        <ion-label>{{ 'audioRecording.timeElapsedText' | translate }}: {{timeElapsed | stopwatch}}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row class="parent-row">
    <ion-button (click)="onStopClick()" class="omg-btn-text">
      <ion-grid>
        <ion-row>
          <ion-col>
              <fa-icon size="2x" [icon]="['fal', 'stop-circle']"></fa-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-button>
    </ion-row>
    <ion-row class="parent-row">
      <ion-col>
        {{ 'audioRecording.stop' | translate }}
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
