import {ColumnConfig} from '../ColumnConfig';
import {AppTokenPermissionColumnSet} from './AppTokenPermissionColumnSet';

const UniqueKey = ['key'];
const UniqueClientName = ['client_id', 'name'];

export const AppTokenColumnSet: Array<ColumnConfig> = [
  {name: 'id', cnd: true},
  {name: 'key', cnd: true, uniqueWith: [UniqueKey]},
  {name: 'name', uniqueWith: [UniqueClientName]},
  {name: 'user_id', prop: 'userId', def: null},
  {name: 'description', def: null},
  {name: 'client_id', prop: 'clientId', uniqueWith: [UniqueClientName]},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById'},
  {name: 'appTokenPermissions', fk: 'appTokenId', mappedBy: AppTokenPermissionColumnSet, transient: true},
];
