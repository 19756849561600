<app-modal-header>
  <app-modal-step-indicator
    [stepsCount]="workflowSteps.length"
    [current]="currentStepIndex"
    workflowName="{{ (pdfPlanId ? 'project_room.upload_pdf_plan_version.title' : 'project_room.upload_pdf_plan_version.titleNewPlan') | translate }}"
    workflowStep="{{ currentStep.titleTranslationKey | translate }}">
  </app-modal-step-indicator>
  <div class="ion-text-center danger-label">
    <ion-label class="omg-attention omg-attention-danger" *ngIf="!isNetworkConnected">{{ 'offlineInfo.headerBanner'| translate }}</ion-label>
  </div>
</app-modal-header>

<ion-content class="with-padding">
  <ion-grid class="grid-gap-4">
    <ion-row>
      <ion-col>
        <div *ngIf="currentStep.key === 'FILE_SELECT'">
          <ion-item *ngIf="printWarningReducedQualityUsed" class="warning" lines="none">
            {{ 'project_room.upload_pdf_plan_version.step.FILE_SELECT.reducedQuality' | translate}}
          </ion-item>
          <app-file-upload title="{{'project_room.upload_pdf_plan_version.step.FILE_SELECT.drag_drop_text' | translate}}" [acceptedMimeTypes]="acceptedMimeTypes" [processingFiles]="processingUploadedFile" [files]="files" (filesChange)="filesChange($event)"
                           [showAbortProcessFile]="true" (abortProcessingFile)="abortProcessingFile()" >
          </app-file-upload>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-radio-group *ngIf="currentStep.key === 'FILE_SELECT'" [ngModel]="pdfPlanVersionQuality" (ngModelChange)="changeQuality($event)">
          <ion-row>
            <ion-col>
              <div class="d-flex gap-3 omg-form">
                <ion-label>{{('project_room.pdf_plan_version_form.quality' | translate) + ':'}}</ion-label>
                <ion-item class="omg-form-checkbox">
                  <ion-radio label-placement="end" justify="start" [disabled]="processing || files?.length >= 1" slot="start" [value]="2">
                    {{ 'project_room.pdf_plan_version_form.pdfPlanVersionQuality.2' | translate }}
                  </ion-radio>
                </ion-item>
                <ion-item class="omg-form-checkbox">
                  <ion-radio label-placement="end" justify="start" [disabled]="processing || files?.length >= 1" slot="start" [value]="1">
                    {{ 'project_room.pdf_plan_version_form.pdfPlanVersionQuality.1' | translate }}
                  </ion-radio>
                </ion-item>
              </div>
            </ion-col>
          </ion-row>
        </ion-radio-group>
      </ion-col>
    </ion-row>


  

  <app-pdf-plan-version-form *ngIf="currentStep.key === 'DETAILS' && pdfPlanVersion && tags"
                             [(pdfPlanVersion)]="pdfPlanVersion"
                             [(tags)]="tags"
                             (validChange)="pdfPlanVersionValidChanged($event)">

  </app-pdf-plan-version-form>
  <app-pdf-plan-marker-form *ngIf="currentStep.key === 'MARKER' && pdfPlanVersion && (latestPdfPlanVersion$ | async)"
                            [latestPdfPlanVersion]="latestPdfPlanVersion$ | async"
                            [pdfPlanPages]="pdfPlanPages"
                            [imageBlobs]="imageBlobs"
                            [migratePdfPlanPageMarkingGeneral]="migratePdfPlanPageMarkingGeneral"
                            (migratePdfPlanPageMarkingGeneralChange)="migratePdfPlanPageMarkingGeneralChanged($event)">

  </app-pdf-plan-marker-form>
  <ion-item *ngIf="currentStep.key === 'SUMMARY'" lines="none">{{'project_room.upload_pdf_plan_version.step.SUMMARY.text' | translate}}</ion-item>
  </ion-grid>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="start">
    <ion-button class="omg-btn-text" (click)="back()" *ngIf="currentStepIndex > 0">{{ 'back' | translate }}</ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-primary" (click)="next()" *ngIf="currentStepIndex < workflowSteps.length -1" [disabled]="processing || !isCurrentStepValid()">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="processing"></ion-spinner>
      <span>{{ 'next' | translate }}</span>
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="finish()" *ngIf="currentStepIndex === workflowSteps.length -1" [disabled]="processing || !isAllStepsValid()">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="processing"></ion-spinner>
      <span>{{ 'project_room.upload_pdf_plan_version.button.create' | translate }}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
