import {formatDate} from '@angular/common';
import {AfterViewInit, Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {IonInput, IonTextarea, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {IonicSelectableComponent} from 'ionic-selectable';
import {Observable, Subscription} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {CompanyDataService} from 'src/app/services/data/company-data.service';
import {ProjectCompanyDataService} from 'src/app/services/data/project-company-data.service';
import {TextTemplateEditModalService} from 'src/app/services/text-template/text-template-edit-modal.service';
import {TextTemplateModalService} from 'src/app/services/text-template/text-template-modal.service';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {trackById} from 'src/app/utils/track-by-id';
import {
  Address,
  Company,
  LicenseType,
  ONLY_ADMIN_CAN_CLOSE_PROTOCOL_CLIENT_IDS,
  PdfPrintEntryDetails,
  PdfProtocolLayout,
  PdfProtocolSetting,
  Protocol,
  ProtocolSortEntriesByEnum,
  ShowPicturesEnum
} from 'submodules/baumaster-v2-common';
import {WorkflowType} from '../../../model/send-protocol';
import {ClientService} from '../../../services/client/client.service';
import {PdfWorkflowService} from '../../../services/pdf/pdf-workflow.service';
import {ThemeService} from '../../../services/ui/theme.service';
import {DATE_FORMAT, PROTOCOL_COLORS} from '../../../shared/constants';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {LicenseService} from '../../../services/auth/license.service';
import {InfoPopoverComponent} from '../../common/info-popover/info-popover.component';
import {PopoverService} from 'src/app/services/ui/popover.service';
import {UnitService} from 'src/app/services/unit/unit.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';

const formControlNameToTextTemplateCategory = {
  startingText: 'protocols/pdf/startingText',
  endingText: 'protocols/pdf/endingText'
} as const;

type ImportMode = 'manual' | 'company' | 'unitContact';

@Component({
  selector: 'app-pdf-configuration',
  templateUrl: './pdf-configuration.component.html',
  styleUrls: ['./pdf-configuration.component.scss']
})

export class PdfConfigurationComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly trackById = trackById;
  readonly MAX_ROWS_LETTERHEAD = 5;
  @Input() workflowType: WorkflowType = WorkflowType.Protocol;
  @Input() form: UntypedFormGroup;
  @Output() formChange = new EventEmitter<UntypedFormGroup>();
  @Input() formDirty: boolean;
  @Input() pdfProtocolSettings: PdfProtocolSetting | undefined;
  @Output() formDirtyChange = new EventEmitter<boolean>();
  @Input() isContinuousProtocol = false;
  @Input() hasCarriedOverEntries = false;
  @Input() protocolSortEntriesBy?: string;
  @Input() hasProtocolEntriesFiltered = false;
  @Input() usedInProtocolSettings = false;
  @Input() weatherActivated = false;
  @Input() protocol: Protocol | undefined;
  @ViewChild('input') nameInput: IonInput;
  @Input() protocolTypeName?: string;
  @ViewChild('letterHeadTextArea') letterHeadTextArea: IonTextarea;

  private valueChangesSubscription: Subscription | undefined;
  private unitProfileAddressesSubscription: Subscription | undefined;

  public protocolConfigurationForm: UntypedFormGroup;
  public imageSizes: Array<{ id: ShowPicturesEnum, name: string }> = [];
  public showAdvancedSettings = false;
  public showLetterHeadOptions = false;
  public hideDescription = false;
  public readonly colors = PROTOCOL_COLORS;
  public readonly protocolLayout = PdfProtocolLayout;
  public WorkflowTypeEnum = WorkflowType;
  public companyData: Observable<Array<Company>>;
  public importMode: ImportMode = 'manual';
  public unitProfileAddresses: Array<Address> | undefined;
  public isUnitsEnabled = false;
  public selectedUnitContact: Address | undefined;

  public entryDetailsItems = Object.values(PdfPrintEntryDetails).map((value) => ({
    detail: value,
    label: this.translateService.instant(`sendProtocol.protocolConfig.entryDetails.${value}`),
  }));

  get showPhotosOnCommentsOption() {
    const { showComments, imagesSize } = this.protocolConfigurationForm?.getRawValue() || {};

    return showComments && imagesSize !== ShowPicturesEnum.NONE;
  }

  get showAdditionalSettingsSection() {
    return this.hasFilteredEntries || this.showHideMainEntryOption || this.showAppendCarriedOverEntriesOption;
  }

  get showAppendCarriedOverEntriesOption() {
    return this.isContinuousProtocol && this.hasCarriedOverEntries;
  }

  get hasFilteredEntries(): boolean {
    return this.hasProtocolEntriesFiltered;
  }

  get showHideMainEntryOption() {
    return [
      ProtocolSortEntriesByEnum.COMPANY,
      ProtocolSortEntriesByEnum.NAMEABLE_DROPDOWN,
      ProtocolSortEntriesByEnum.CRAFT
    ].some((sortEntriesByEnum) => sortEntriesByEnum === this.protocolSortEntriesBy);
  }

  featureTextTemplatesEnabled$ = combineLatestAsync([this.licenseService.currentUserLicense$, this.clientService.currentClient$])
    .pipe(map(([currentUserLicense, currentClient]) => currentUserLicense === LicenseType.PROFESSIONAL || ONLY_ADMIN_CAN_CLOSE_PROTOCOL_CLIENT_IDS.includes(currentClient?.id)));

  constructor(@Inject(LOCALE_ID) private locale: string,
              private pdfWorkflowService: PdfWorkflowService,
              public translateService: TranslateService,
              private clientService: ClientService,
              public themeService: ThemeService,
              private platform: Platform,
              private textTemplateModalService: TextTemplateModalService,
              private textTemplateEditModalService: TextTemplateEditModalService,
              private projectCompanyDataService: ProjectCompanyDataService,
              private addressDataService: AddressDataService,
              private companyDataService: CompanyDataService,
              private unitService: UnitService,
              private posthogService: PosthogService,
              private licenseService: LicenseService,
              private protocolService: ProtocolService,
              private popoverService: PopoverService) {
  }

  ngOnDestroy(): void {
    this.valueChangesUnsubscribe();
    this.unitProfileAddressesSubscription?.unsubscribe();
    this.unitProfileAddressesSubscription = undefined;
  }

  async ngOnInit() {
    this.protocolConfigurationForm = this.form;
    this.showAdvancedSettings = this.form.get('showAdvancedSettings').value;
    this.imageSizes = this.pdfWorkflowService.getImageSizeOptions();
    this.showLetterHeadOptions = this.form.get('showLetterhead').value;
    this.isUnitsEnabled = await observableToPromise(this.unitService.isFeatureEnabled$) && this.workflowType === WorkflowType.Protocol &&
      this.protocol && await this.protocolService.isStandardProtocol(this.protocol) && !!this.protocol.unitId;
    this.ensureImageSize();
    this.fillNameableDropdownName();
    this.companyData = this.workflowType === WorkflowType.GlobalSearch ? this.companyDataService.dataActive$ :
      this.projectCompanyDataService.getProjectCompanies().pipe(map(companies => companies.filter(company => company.isActive !== false)));
    this.unitProfileAddressesSubscription = !this.isUnitsEnabled ? undefined : this.unitService.getUnitForBreadcrumbAndProfileAddressesByProtocolId$(this.protocol.id)
      .pipe(map(unit => unit.unitProfileAddresses.map(item => item.address)))
      .subscribe(unitProfileAddresses => {
        if (!unitProfileAddresses.length) {
          this.selectedUnitContact = undefined;
        } else {
          if (!this.selectedUnitContact) {
            this.selectedUnitContact = unitProfileAddresses[0];
          } else {
            const indexOfExisting = unitProfileAddresses.findIndex((unitProfileAddress) => unitProfileAddress.id === this.selectedUnitContact.id);
            if (indexOfExisting === -1) {
              this.selectedUnitContact = unitProfileAddresses[0];
            } else {
              this.selectedUnitContact = unitProfileAddresses[indexOfExisting];
            }
          }
        }
        this.unitProfileAddresses = unitProfileAddresses;
      });
    if (!this.weatherActivated) {
      this.protocolConfigurationForm.get('showWeather').setValue(false);
    }
    this.hideDescription = this.form.get('hideDescription').value;
  }

  async getTextTemplate(formControlName: keyof typeof formControlNameToTextTemplateCategory) {
    const template = await this.textTemplateModalService.getTextTemplate(
      formControlNameToTextTemplateCategory[formControlName],
      {
        ...(this.protocolTypeName ? {name: `${this.protocolTypeName} - ${formatDate(new Date(), DATE_FORMAT, this.locale)}`} : {}),
      }
    );
    if (!template) {
      return;
    }

    this.protocolConfigurationForm?.get(formControlName)?.setValue(template);
    if (this.usedInProtocolSettings) {
      this.posthogService.captureEvent('[TextTemplate] Used Template in PDF Settings', {});
    }
  }

  async saveAsTextTemplate(formControlName: keyof typeof formControlNameToTextTemplateCategory) {
    const template = await this.textTemplateEditModalService.createClientTextTemplate(
      formControlNameToTextTemplateCategory[formControlName],
      {
        textTemplate: this.protocolConfigurationForm?.get(formControlName)?.value,
        ...(this.protocolTypeName ? {name: `${this.protocolTypeName} - ${formatDate(new Date(), DATE_FORMAT, this.locale)}`} : {}),
      }
    );

    if (!template) {
      return;
    }

    this.protocolConfigurationForm?.get(formControlName)?.setValue(template.textTemplate);
    if (this.usedInProtocolSettings) {
      this.posthogService.captureEvent('[TextTemplate] Used Template in PDF Settings', {});
    }
  }

  private async fillNameableDropdownName() {
    const dropdownName = await observableToPromise(this.clientService.currentClient$.pipe(
      filter((client) => client !== undefined),
      map((client) => client.nameableDropdownName),
      take(1)
    ));

    this.entryDetailsItems.find((value) => value.detail === PdfPrintEntryDetails.ADDITIONAL_FIELD).label = dropdownName;
  }

  ngAfterViewInit() {
    this.checkFormIsDirty();
    if (this.workflowType === this.WorkflowTypeEnum.GlobalSearch && this.platform.is('desktop')) {
      setTimeout(() => {
        this.nameInput?.setFocus();
      }, 200);
    }
  }

  private ensureImageSize() {
    const imagesSize = this.protocolConfigurationForm.get('imagesSize').value;
    if (!this.imageSizes.some((value) => value.id === imagesSize)) {
      this.protocolConfigurationForm.get('imagesSize').setValue(
        this.imageSizes[0].id,
        { onlySelf: true }
      );
    }
  }

  checkFormIsDirty() {
    this.valueChangesUnsubscribe();
    this.valueChangesSubscription = this.protocolConfigurationForm.valueChanges
      .subscribe((formValues) => {
        this.imageSizes = this.pdfWorkflowService.getImageSizeOptions();
        this.ensureImageSize();
        this.updateForm();
        this.updateFormDirty(this.protocolConfigurationForm.dirty);
      });
  }

  public toggleAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
    this.protocolConfigurationForm.get('showAdvancedSettings').setValue(this.showAdvancedSettings, {onlySelf: true});
  }

  private updateFormDirty(formDirty: boolean) {
    this.formDirty = formDirty;
    this.formDirtyChange.emit(formDirty);
  }

  private updateForm() {
    this.form = this.protocolConfigurationForm;
    this.formChange.emit(this.protocolConfigurationForm);
  }

  private valueChangesUnsubscribe() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
      this.valueChangesSubscription = undefined;
    }
  }

  onLetterheadChange(event: Event) {
    this.showLetterHeadOptions = (event.target as HTMLInputElement).checked;
  }

  changeMode(mode: ImportMode) {
    if (mode !== this.importMode) {
      this.importMode = mode;
      this.letterHeadTextArea.value = '';
      this.form.get('letterheadText').setValue(this.letterHeadTextArea.value);
    }
  }

  async companyChange(event: { component: IonicSelectableComponent, value: Company}) {
    const company = event.value;
    const address = await observableToPromise(this.addressDataService.getById(company.addressId));
    this.letterHeadTextArea.value = `${company.name}\n\n${address.street1} ${address.street2}\n${address.zipCode} ${address.city}\n${address.country}`;
    this.form.get('letterheadText').setValue(this.letterHeadTextArea.value);
  }

  async unitContactChange(event: { component: IonicSelectableComponent, value: Address} | Address) {
    const address = 'value' in event ? event.value : event;
    if (!address) {
      return;
    }
    this.letterHeadTextArea.value =
      `${address.firstName ?? ''} ${address.lastName ?? ''}\n\n${address.street1 ?? ''} ${address.street2 ?? ''}\n${address.zipCode ?? ''} ${address.city ?? ''}\n${address.country ?? ''}`;
    this.form.get('letterheadText').setValue(this.letterHeadTextArea.value);
  }

  onTextareaInput(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    const lines = textarea.value.split('\n').length;

    if (lines > this.MAX_ROWS_LETTERHEAD) {
      const valueArray = textarea.value.split('\n');
      textarea.value = valueArray.slice(0, this.MAX_ROWS_LETTERHEAD).join('\n');
    }
  }

  async checkWeatherSetup() {
    if (!this.weatherActivated) {
        this.popoverService.openAndClose(undefined, {
          component: InfoPopoverComponent,
          cssClass: 'omg-popover-info omg-popover-info-border',
          componentProps: {
            title: this.translateService.instant('protocol.weatherNotActive.header'),
            text: this.translateService.instant('protocol.weatherNotActive.message'),
            hintText: this.translateService.instant('protocol.weatherNotActive.hintText')
          }
        });
      this.protocolConfigurationForm.get('showWeather').setValue(false);
    }
  }

  changeHideDescription() {
    this.hideDescription = !this.hideDescription;
    this.form.get('hideDescription').setValue(this.hideDescription);
  }
}
