import {ColumnConfig} from '../ColumnConfig';
import {CalendarDayColumnSet, ClientCalendarDayColumnSet, ProjectCalendarDayColumnSet} from './CalendarDayColumnSet';

const calendarUnique = ['name'];
const clientCalendarUnique = ['name', 'client_id'];
const projectCalendarUnique = ['name', 'client_id', 'project_id'];

const CalendarBaseColumnSet: Array<ColumnConfig> = [
  {name: 'id', cnd: true},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById', def: null}
];

export const CalendarColumnSet: Array<ColumnConfig> = [
  ...CalendarBaseColumnSet,
  {name: 'name', uniqueWith: [calendarUnique]},
  {name: 'calendarDays', fk: 'calendarId', mappedBy: CalendarDayColumnSet, transient: true}
];

export const ClientCalendarColumnSet: Array<ColumnConfig> = [
  ...CalendarBaseColumnSet,
  {name: 'name', uniqueWith: [clientCalendarUnique]},
  {name: 'client_id', prop: 'clientId', cnd: true, uniqueWith: [clientCalendarUnique]},
  {name: 'clientCalendarDays', fk: 'calendarId', mappedBy: ClientCalendarDayColumnSet, transient: true},
];

export const ProjectCalendarColumnSet: Array<ColumnConfig> = [
  ...CalendarBaseColumnSet,
  {name: 'name', uniqueWith: [projectCalendarUnique]},
  {name: 'client_id', prop: 'clientId', cnd: true, uniqueWith: [projectCalendarUnique]},
  {name: 'project_id', prop: 'projectId', cnd: true, uniqueWith: [projectCalendarUnique]},
  {name: 'projectCalendarDays', fk: 'calendarId', mappedBy: ProjectCalendarDayColumnSet, transient: true},
];