<app-modal-header>
  <ion-title>{{ 'entryCardSettings.title' | translate }}</ion-title>
</app-modal-header>
<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="form" action="#">
    <ion-grid>
      <ion-row>
        <ion-col>
          <div class="omg-modal-header show-hide-title">{{ 'entryCardSettings.showHide.title' | translate }}</div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="showId">
             {{ 'entryCardSettings.showHide.showId' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="showCraft">
             {{ 'entryCardSettings.showHide.showCraft' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="showText">
             {{ 'entryCardSettings.showHide.showText' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="showType">
             {{ 'entryCardSettings.showHide.showType' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-sm="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="showPriorityDate">
             {{ 'entryCardSettings.showHide.showPriorityDate' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col size="12" size-sm="6">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="showAttachmentsSub">
             {{ 'entryCardSettings.showHide.showAttachmentsSub' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div class="omg-modal-header preview-title">{{ 'entryCardSettings.preview.title' | translate }}</div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <app-entry-card
            [taskNumber]="useProtocolEntryNumber ? undefined : 1"
            [protocolEntryNumber]="useProtocolEntryNumber ? 'BB01.002' : undefined"
            [titleText]="'entryCardSettings.preview.taskTitle' |translate"
            [text]="'entryCardSettings.preview.taskText' |translate"
            [company]="'entryCardSettings.preview.company' |translate"
            [craft]="'entryCardSettings.preview.craft' |translate"
            [type]="'entryCardSettings.preview.type' |translate"
            [hasMarker]="false"
            [hasAttachments]="false"
            [hasChat]="false"
            [subEntriesCount]="2"
            [status]="ProtocolEntryIconStatus.OPEN"
            [isNew]="true"
            [checkboxSelected]="false"
            [date]="date"
            [isProtocolClosed]="false"
            [entryCardSettings]="entryCardSettings"
            [showSelectCheckbox]="isEditEnabled$ | async"
          >
          </app-entry-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">
      {{ 'cancel' | translate }}
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="save()" [disabled]="!form.dirty">
      {{ 'button.save' | translate }}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
