import {ColumnConfig} from '../ColumnConfig';

export const UsageStatisticColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'user_id', prop: 'userId', cnd: true},
  {name: 'device_uuid', prop: 'deviceUuid'},
  {name: 'device_manufacturer', prop: 'deviceManufacturer', def: null},
  {name: 'device_name', prop: 'deviceName', def: null},
  {name: 'device_model', prop: 'deviceModel', def: null},
  {name: 'app_version', prop: 'appVersion'},
  {name: 'storage_driver', prop: 'storageDriver'},
  {name: 'media_storage', prop: 'mediaStorage'},
  {name: 'platform', prop: 'platform'},
  {name: 'operating_system', prop: 'operatingSystem'},
  {name: 'os_version', prop: 'osVersion'},
  {name: 'platforms', prop: 'platforms'},
  {name: 'user_agent', prop: 'userAgent'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'}
];
