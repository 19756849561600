<svg viewBox="0 0 69 98" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M0 7.20003C0 3.22357 3.22356 0 7.20003 0H67.2003C68.1944 0 69.0003 0.805891 69.0003 1.80001V96.0004C69.0003 96.9945 68.1944 97.8004 67.2003 97.8004H1.80001C0.805897 97.8004 0 96.9945 0 96.0004V7.20003Z"
    class="svg-background" />
  <rect x="5.1001" y="6" width="58.8002" height="6.60003" rx="0.900004" class="svg-text" />
  <rect x="5.1001" y="18.2998" width="10.2" height="10.2" rx="0.900004" class="svg-text" />
  <rect x="18.2998" y="18.2998" width="45.6002" height="10.2" rx="0.900004" class="svg-text" />
  <rect x="5.2168" y="30.4312" width="58.6888" height="2.60839" rx="0.900004" class="svg-highlight" />
  <rect x="5.1001" y="35.3784" width="49.8002" height="2.40001" rx="0.900004" class="svg-text" />
  <rect x="61.5" y="35.3784" width="2.40001" height="2.40001" rx="0.900004" class="svg-text" />
  <rect x="57.9004" y="35.3784" width="2.40001" height="2.40001" rx="0.900004" class="svg-text" />
  <mask id="path-9-inside-1_1089_110231" fill="white">
    <path d="M5.1001 34.7788H64.5003V39.8788H5.1001V34.7788Z" />
  </mask>
  <path d="M64.5003 39.2788H5.1001V40.4788H64.5003V39.2788Z" class="svg-text" mask="url(#path-9-inside-1_1089_110231)" />
  <rect x="5.1001" y="41.9785" width="49.8002" height="2.40001" rx="0.900004" class="svg-text" />
  <rect x="61.5" y="41.9785" width="2.40001" height="2.40001" rx="0.900004" class="svg-text" />
  <rect x="57.9004" y="41.9785" width="2.40001" height="2.40001" rx="0.900004" class="svg-text" />
  <mask id="path-14-inside-2_1089_110231" fill="white">
    <path d="M5.1001 41.3784H64.5003V46.4784H5.1001V41.3784Z" />
  </mask>
  <path d="M64.5003 45.8784H5.1001V47.0784H64.5003V45.8784Z" class="svg-text" mask="url(#path-14-inside-2_1089_110231)" />
  <rect x="57.8193" y="62.1665" width="6.52098" height="8.69464" rx="0.900004" class="svg-text" />
  <rect x="5.2168" y="62.1665" width="49.5595" height="8.69464" rx="0.900004" class="svg-text" />
  <mask id="path-18-inside-3_1089_110231" fill="white">
    <path d="M5.1001 68.3647H64.5003V73.4648H5.1001V68.3647Z" />
  </mask>
  <path d="M64.5003 72.8648H5.1001V74.0648H64.5003V72.8648Z" class="svg-text" mask="url(#path-18-inside-3_1089_110231)" />
  <mask id="path-20-inside-4_1089_110231" fill="white">
    <path d="M5.40723 59.5586H64.5308V89.1204H5.40723V59.5586Z" />
  </mask>
  <path d="M5.40723 60.1586H64.5308V58.9586H5.40723V60.1586ZM64.5308 88.5204H5.40723V89.7204H64.5308V88.5204Z"
    class="svg-text" mask="url(#path-20-inside-4_1089_110231)" />
  <path
    d="M16.9378 77.6763C16.9378 78.9102 15.3007 81.1092 14.5818 82.0154C14.4093 82.2323 14.0891 82.2323 13.9189 82.0154C13.1999 81.1092 11.5628 78.9102 11.5628 77.6763C11.5628 76.1779 12.7655 74.9644 14.2503 74.9644C15.7352 74.9644 16.9378 76.1779 16.9378 77.6763ZM17.117 79.4933C17.1954 79.3374 17.267 79.1814 17.332 79.0277C17.3432 79.0006 17.3544 78.9712 17.3656 78.9441L19.9635 77.8955C20.3174 77.7531 20.7003 78.0153 20.7003 78.3995V84.5194C20.7003 84.7409 20.566 84.9398 20.3622 85.0234L17.117 86.3319V79.4933ZM10.882 78.0899C10.9357 78.4085 11.0432 78.7294 11.1686 79.0277C11.2336 79.1814 11.3053 79.3374 11.3836 79.4933V85.1748L8.53712 86.3251C8.18326 86.4675 7.80029 86.2053 7.80029 85.8211V79.7012C7.80029 79.4797 7.93467 79.2809 8.13847 79.1972L10.8842 78.0899H10.882ZM15.1417 82.4674C15.453 82.0742 15.9412 81.4346 16.4003 80.7272V86.3613L12.1003 85.1206V80.7272C12.5594 81.4346 13.0477 82.0742 13.359 82.4674C13.8181 83.0459 14.6826 83.0459 15.1417 82.4674ZM14.2503 78.3995C14.4879 78.3995 14.7158 78.3042 14.8838 78.1347C15.0518 77.9652 15.1462 77.7352 15.1462 77.4955C15.1462 77.2557 15.0518 77.0258 14.8838 76.8563C14.7158 76.6868 14.4879 76.5915 14.2503 76.5915C14.0127 76.5915 13.7849 76.6868 13.6169 76.8563C13.4489 77.0258 13.3545 77.2557 13.3545 77.4955C13.3545 77.7352 13.4489 77.9652 13.6169 78.1347C13.7849 78.3042 14.0127 78.3995 14.2503 78.3995Z"
    class="svg-text" />
  <rect x="24.6001" y="74.9644" width="17.1001" height="11.4" rx="0.900004" class="svg-text" />
  <path
    d="M31.6502 81.5644C31.6502 81.5644 33.6002 79.4925 33.6002 78.3769C33.6002 77.321 32.7268 76.4644 31.6502 76.4644C30.5736 76.4644 29.7002 77.321 29.7002 78.3769C29.7002 79.4925 31.6502 81.5644 31.6502 81.5644ZM31.6502 77.7394C31.8226 77.7394 31.9879 77.8065 32.1098 77.9261C32.2317 78.0456 32.3002 78.2078 32.3002 78.3769C32.3002 78.5459 32.2317 78.7081 32.1098 78.8276C31.9879 78.9472 31.8226 79.0144 31.6502 79.0144C31.4778 79.0144 31.3125 78.9472 31.1906 78.8276C31.0687 78.7081 31.0002 78.5459 31.0002 78.3769C31.0002 78.2078 31.0687 78.0456 31.1906 77.9261C31.3125 77.8065 31.4778 77.7394 31.6502 77.7394Z"
    class="svg-white" />
  <path d="M31.8003 86.3645V83.0645H44.1003" class="svg-white-stroke" stroke-width="0.900004" />
  <rect x="4.97266" y="48.6899" width="59.5583" height="8.69464" rx="0.900004" class="svg-highlight" />
</svg>