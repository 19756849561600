import {Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {Nullish} from 'src/app/model/nullish';
import {PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {PdfPlanVersionDataService} from 'src/app/services/data/pdf-plan-version-data.service';
import {ProjectProtocolLocationDataService} from 'src/app/services/data/project-protocol-location-data.service';
import {ProtocolEntryLocationDataService} from 'src/app/services/data/protocol-entry-location-data.service';
import {PdfPlanHolderService} from 'src/app/services/project-room/pdf-plan-holder.service';
import {PlanAnalyticsService} from 'src/app/services/project-room/plan-analytics.service';
import {isPopoverDismissed, PopoverService} from 'src/app/services/ui/popover.service';
import {DATE_FORMAT} from 'src/app/shared/constants';
import {observableToPromise} from 'src/app/utils/async-utils';
import {trackById} from 'src/app/utils/track-by-id';
import {IdType, PdfPlanVersion, ProtocolEntryLocation, TagBase} from 'submodules/baumaster-v2-common';
import {MobiscrollService} from '../../../../services/common/mobiscroll.service';

export interface PdfPlanEditEvent {
  pdfPlans: PdfPlanWithDeletable[];
  tagsById: Record<IdType, TagBase[]>;
}

@Component({
  selector: 'app-pdf-plan-edit-holders',
  templateUrl: './pdf-plan-edit-holders.component.html',
  styleUrls: ['./pdf-plan-edit-holders.component.scss'],
})
export class PdfPlanEditHoldersComponent implements OnChanges, OnInit, OnDestroy {
  readonly trackById = trackById;
  readonly DATE_FORMAT = DATE_FORMAT;

  @Input()
  pdfPlanHolders: PdfPlanWithDeletable[];

  @Input()
  tagsById: Record<IdType, TagBase[]>;

  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  submitEdit = new EventEmitter<PdfPlanEditEvent>();

  @Output()
  submitAndShare = new EventEmitter<PdfPlanEditEvent>();

  holdersForm: UntypedFormGroup = new UntypedFormGroup({});

  @HostBinding('class.is-edit-mode')
  readonly editClass = true;

  locationById$ = this.locationDataService.dataGroupedById;
  versionsByPlanId$ = this.pdfPlanVersionDataService.dataByPlanId$;
  versionsByPlanId: Record<IdType, PdfPlanVersion[]>|undefined;

  public mbscThemeVariant$ = this.mobiscrollService.themeVariant$;
  public mbscLocale$ = this.mobiscrollService.locale$;
  public MBSC_DATE_FORMAT = this.mobiscrollService.DATE_FORMAT;

  private versionsByPlanIdSubscription?: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private popoverService: PopoverService,
    private locationDataService: ProtocolEntryLocationDataService,
    private projectLocationDataService: ProjectProtocolLocationDataService,
    private pdfPlanHolderService: PdfPlanHolderService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    public analytics: PlanAnalyticsService,
    private translateService: TranslateService,
    private mobiscrollService: MobiscrollService,
  ) { }

  ngOnInit() {
    this.versionsByPlanIdSubscription = this.versionsByPlanId$.subscribe((versionsByPlanId) => {
      this.versionsByPlanId = versionsByPlanId;
    });
  }

  ngOnDestroy() {
    this.versionsByPlanIdSubscription?.unsubscribe();
  }

  ngOnChanges({ pdfPlanHolders }: SimpleChanges) {
    if (pdfPlanHolders) {
      const existingHolders = new Set(Object.keys(this.holdersForm.controls));
      this.pdfPlanHolders.forEach((holder) => {
        existingHolders.delete(holder.id);
        if (this.holdersForm.contains(holder.id)) {
          this.patchHolderFormGroup(holder, pdfPlanHolders);
        } else {
          this.addHolderFormGroup(holder);
        }
      });
      existingHolders.forEach((id) => this.removeHolderFormGroup(id));
    }
  }

  private getFreshHolderFormGroup(holder: PdfPlanWithDeletable) {
    return this.fb.group({
      id: [holder.id, []],
      name: [holder.latestPdfPlanVersion?.name ?? '', [Validators.required, Validators.maxLength(60)]],
      index: [holder.latestPdfPlanVersion?.index ?? '', [Validators.maxLength(4), this.uniqueIndexValidator()]],
      date: [holder.latestPdfPlanVersion?.date ?? '', [Validators.required]],
      scale: [holder.latestPdfPlanVersion?.scale ?? '', [Validators.maxLength(10)]],
      locationId: [holder.latestPdfPlanVersion?.locationId ?? ''],
      description: [holder.latestPdfPlanVersion?.description ?? '']
    });
  }

  private uniqueIndexValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const index: string | null | undefined = control.value;
      if (!index) {
        return null;
      }
      if (!this.versionsByPlanId) {
        return null;
      }
      const holderId = control.parent?.getRawValue()?.id;
      const holder = this.pdfPlanHolders?.find(({id}) => holderId === id);
      if (!holder || !holderId || !this.versionsByPlanId[holderId]) {
        return null;
      }

      const planVersions = this.versionsByPlanId[holderId];

      const recordExist = planVersions.some((pdfPlanVersion) => pdfPlanVersion.id !== holder.latestPdfPlanVersion?.id && pdfPlanVersion.index && pdfPlanVersion.index === index);
      if (recordExist) {
        return {indexExists: true};
      }
      return null;
    };
  }

  private addHolderFormGroup(holder: PdfPlanWithDeletable) {
    this.holdersForm.addControl(holder.id, this.getFreshHolderFormGroup(holder));
  }

  private removeHolderFormGroup(holderId: IdType) {
    this.holdersForm.removeControl(holderId);
  }

  private patchHolderFormGroup(holder: PdfPlanWithDeletable, change: SimpleChange) {
    const holderForm = this.holdersForm.get(holder.id);
    if (!holderForm.dirty) {
      holderForm.patchValue({
        name: holder.latestPdfPlanVersion?.name ?? '',
        index: holder.latestPdfPlanVersion?.index ?? '',
        date: holder.latestPdfPlanVersion?.date ?? '',
        scale: holder.latestPdfPlanVersion?.scale ?? '',
        locationId: holder.latestPdfPlanVersion?.locationId ?? '',
        description: holder.latestPdfPlanVersion?.description ?? '',
      });
    } else {
      // TODO: What to do with updated form group? Update? Ignore? Inform?
    }
  }

  getHolderFormGroup(holderId: IdType) {
    return this.holdersForm.get(holderId) as UntypedFormGroup;
  }

  async handleEditLocation(event: MouseEvent, holderId: IdType) {
    const currentLocationId = this.holdersForm.get(holderId).get('locationId').value;
    let locations = await observableToPromise(this.projectLocationDataService.getProjectProtocolLocationsWithDeletedSuffix(currentLocationId ? [currentLocationId] : []));
    const noLocationName = this.translateService.instant('project_room.pdf_plan_version_form.noLocation');
    const noLocation: ProtocolEntryLocation[] = [{id: '', location: noLocationName, clientId: '', changedAt: '', isActive: true}];
    locations = noLocation.concat(locations);
    const result = await this.popoverService.openActions(event, locations.map((location) => ({
      role: location.id,
      label: location.location,
      itemClass: location.id === this.holdersForm.get(holderId).get('locationId').value ? 'active-item' : undefined,
    })), {
      popoverOptions: {
        cssClass: 'omg-popover-list-no-lines omg-popover-list-condensed omg-popover-grey-item'
      }
    });
    if (isPopoverDismissed(result)) {
      return;
    }
    this.holdersForm.get(holderId).get('locationId').setValue(result);
  }

  handleCancel() {
    this.cancel.emit();
  }

  handleSubmitAndShare() {
    this.submitAndShare.emit(this.getSubmitEvent());
  }

  handleSubmit() {
    this.submitEdit.emit(this.getSubmitEvent());
  }

  handleTagsChange(tags: TagBase[], pdfPlanVersionId: Nullish<IdType>) {
    if (!pdfPlanVersionId) {
      return;
    }
    this.tagsById = {
      ...this.tagsById,
      [pdfPlanVersionId]: tags,
    };
  }

  async handleEditComment(pdfPlan: PdfPlanWithDeletable, form: UntypedFormGroup) {
    await this.pdfPlanHolderService.openEditPdfPlanCommentModal(pdfPlan, form);
  }

  private getSubmitEvent(): PdfPlanEditEvent {
    return {
      pdfPlans: this.getUpdatedHolders(),
      tagsById: this.tagsById,
    };
  }

  private getUpdatedHolders() {
    const holdersUpdateById = this.holdersForm.getRawValue();

    return this.pdfPlanHolders.map((holder) => {
      if (!holder.latestPdfPlanVersion) {
        return holder;
      }

      return {
        ...holder,
        latestPdfPlanVersion: Object.entries(holdersUpdateById[holder.id] ?? {}).reduce((acc, [key, value]) => {
          if (value === undefined || value === null || key === 'id') {
            return acc;
          }

          if (value === '' && key === 'locationId') {
            return {...acc, [key]: null};
          }

          return {...acc, [key]: value};
        }, {...holder.latestPdfPlanVersion})
      };
    });
  }

}
