import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IdType, UnitProfile, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const REST_ENDPOINT_URI = 'api/data/unitProfiles';

@Injectable({
  providedIn: 'root'
})
export class UnitProfileDataService extends AbstractProjectAwareDataService<UnitProfile> {

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, protected projectDataService: ProjectDataService,
              loggingService: LoggingService, projectAvailabilityExpirationService: ProjectAvailabilityExpirationService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.UNIT_PROFILE, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService,
          projectAvailabilityExpirationService, integrityResolverService, '2.30.0', ['unitId', 'name', 'id']);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  public getByUnitId(unitId: IdType): Observable<Array<UnitProfile>> {
    return this.data.pipe(map((unitProfiles) => unitProfiles.filter((unitProfile) => unitProfile.unitId === unitId)));
  }

  public getByProfileId(profileId: IdType): Observable<Array<UnitProfile>> {
    return this.data.pipe(map((unitProfiles) => unitProfiles.filter((unitProfile) => unitProfile.profileId === profileId)));
  }

  public getByProfileIds(profileIds: IdType[]): Observable<Array<UnitProfile>> {
    return this.data.pipe(map((unitProfiles) => unitProfiles.filter((unitProfile) => profileIds.includes(unitProfile.profileId))));
  }

}
