<app-modal-header>
  <ion-title>{{'termsAndConditions.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <div class="d-flex flex-column ion-align-items-stretch">
    <div class="termsAndConditionsText ion-padding ion-text-center">{{'termsAndConditions.text-0' | translate}}
    </div>
    <img src="/assets/images/safe-security.png" class="safe-image" />
    <div class="termsAndConditionsText ion-padding">{{'termsAndConditions.text-1' | translate}}
      <a [href]="privacyPolicyUrl" target="_blank">{{'termsAndConditions.text-2-terms-of-use' | translate}}
        <fa-icon [icon]="['far', 'external-link']"></fa-icon>
      </a>
      {{'termsAndConditions.text-3' | translate}}

    </div>
    <div class="termsAndConditionsText ion-padding">{{'termsAndConditions.text-4' | translate}}
      <a [href]="termsOfUseUrl" target="_blank">{{'termsAndConditions.text-7' | translate}}
        <fa-icon [icon]="['far', 'external-link']"></fa-icon>
      </a>
      {{'termsAndConditions.text-6' | translate}}
    </div>
  </div>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button id="logoutButton" class="omg-btn-text" (click)="logout()" [disabled]="buttonActionInProgress">{{'termsAndConditions.logout' | translate}}</ion-button>
    <ion-button id="acceptButton" class="omg-btn-primary" (click)="accept()" [disabled]="buttonActionInProgress">
      <ion-spinner *ngIf="buttonActionInProgress" class="spinner-center-button"></ion-spinner>
      <span>
        {{'termsAndConditions.accept' | translate}}
      </span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
