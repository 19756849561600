<app-modal-header>
  <ion-title>{{
    "protocolEntry.massEdit.title" | translate: { count: entriesCount }
  }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <app-protocol-entry-form
    [massEditView]="true"
    [massEditClosedView]="isClosedOrHasCarriedOverEntries"
    [protocolId]="protocolId"
    (formDataChange)="updateData($event)"
    (additionalFieldsChange)="updateData({ nameableDropdownId: $event })"
    [acrossProjects]="false"
    [isChild]="hasChildEntries"
    [statusTemplate]="statusTemplate"
    [onlyActionableEntryTypes]="onlyActionableEntryTypes"
    [typeRequired]="typeRequired"
  ></app-protocol-entry-form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismiss()">{{
      "button.cancel" | translate
    }}</ion-button>
    <ion-button class="omg-btn-primary" (click)="accept()">{{
      "button.save" | translate
    }}</ion-button>
  </ion-buttons>
</app-modal-footer>

<ng-template #statusTemplate>
  <div
      *ngIf="
    (hasActionableTypeSelected$ | async) ||
    (hasActionableItems && !hasNonActionableItems && !((data$ | async).type)) ||
    isShortProtocolLayout">
    <ion-item (click)="statusPopover($event)">
      <ion-label position="floating" color="text-secondary">{{
        "Status" | translate
        }}</ion-label>
      <ng-container *ngIf="protocolEntryStatus.OPEN === data.status">
        <img
            slot="start"
            src="/assets/images/bm-status-open.svg"
            class="protocol-status-img"
        />
        <ion-input
            readonly
            value="{{ 'protocolEntry.status.open' | translate }}"
        ></ion-input>
      </ng-container>

      <ng-container
          *ngIf="
        !isShortProtocolLayout &&
        protocolEntryStatus.COMPANY_DONE === data.status
      "
      >
        <img
            slot="start"
            src="/assets/images/bm-status-on-hold.svg"
            class="protocol-status-img"
        />
        <ion-input
            readonly
            value="{{ 'protocolEntry.status.waiting' | translate }}"
        ></ion-input>
      </ng-container>

      <ng-container *ngIf="protocolEntryStatus.DONE === data.status">
        <img
            slot="start"
            src="/assets/images/bm-status-done.svg"
            class="protocol-status-img"
        />
        <ion-input
            readonly
            value="{{ 'protocolEntry.status.done' | translate }}"
        ></ion-input>
      </ng-container>

      <ng-container *ngIf="!data.status">
        <img
            slot="start"
            src="/assets/images/bm-status-empty.svg"
            class="protocol-status-img"
        />
        <ion-input readonly value=""></ion-input>
      </ng-container>
    </ion-item>
  </div>

  <div
      *ngIf="
      isContinuousProtocolLayout &&
    !(hasActionableTypeSelected$ | async) &&
    !hasActionableItems && hasNonActionableItems && !((data$ | async).type) &&
    !isShortProtocolLayout"
  >
    <ion-item (click)="showContinuousInfoPopover($event)">
      <ion-label position="floating" color="text-secondary">{{"Status" | translate}}</ion-label>
      <ng-container *ngIf="data.isContinuousInfo === false">
        <img
            slot="start"
            src="/assets/images/bm-status-info.svg"
            class="protocol-status-img"
        />
        <ion-input
            readonly
            value="{{ 'protocolEntry.status.info' | translate }}"
        ></ion-input>
      </ng-container>

      <ng-container *ngIf="data.isContinuousInfo === true">
        <img
            slot="start"
            src="/assets/images/bm-status-continuous-info.svg"
            class="protocol-status-img"
        />
        <ion-input
            readonly
            value="{{ 'protocolEntry.status.continuousInfo' | translate }}"
        ></ion-input>
      </ng-container>

      <ng-container *ngIf="data.isContinuousInfo === undefined || data.isContinuousInfo === null">
        <img
            slot="start"
            src="/assets/images/bm-status-empty.svg"
            class="protocol-status-img"
        />
        <ion-input readonly value=""></ion-input>
      </ng-container>

    </ion-item>
  </div>
</ng-template>

