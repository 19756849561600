import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {BauAccentColors} from 'src/app/shared/colors';
import {TagBase} from 'submodules/baumaster-v2-common';

@Component({
  selector: 'app-tag-edit-popover',
  templateUrl: './tag-edit-popover.component.html',
  styleUrls: ['./tag-edit-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagEditPopoverComponent {

  readonly colors = BauAccentColors;

  @Input()
  tag: TagBase;
  @Output()
  tagChange = new EventEmitter<TagBase>();
  @Output()
  tagDelete = new EventEmitter<TagBase>();

  changeColor(color: string) {
    this.tagChange.emit({
      ...this.tag,
      color,
    });
  }

}
