import {ProtocolDataService} from '../../../services/data/protocol-data.service';
import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Observable, Subject, Subscription, throwError} from 'rxjs';
import {Address, Client, formatOneLineAddress, IdType, Project, Protocol, ProtocolLayout, ProtocolType, TASK_PROTOCOL_NAME} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';
import {LoggingService} from '../../../services/common/logging.service';
import {ProjectProtocolTypeDataService} from 'src/app/services/data/project-protocol-type-data.service';
import {ProtocolLayoutDataService} from '../../../services/data/protocol-layout-data.service';
import {PROTOCOL_LAYOUT_NAME_CONTINUOUS, PROTOCOL_LAYOUT_NAME_SHORT, PROTOCOL_LAYOUT_NAME_STANDARD} from '../../../shared/constants';
import {observableToPromise} from '../../../utils/async-utils';
import {SystemEventService} from '../../../services/event/system-event.service';
import {IonInput, Platform} from '@ionic/angular';
import {ProtocolTypeDataService} from 'src/app/services/data/protocol-type-data.service';
import {convertDateTimeToISOString, convertISOStringToDate} from 'src/app/utils/date-utils';
import {ClientService} from '../../../services/client/client.service';
import moment from 'moment';
import {ProtocolService} from '../../../services/protocol/protocol.service';
import {SelectableService} from '../../../services/common/selectable.service';
import {SelectableChangeType, SelectableComponent} from '../../common/selectable/selectable.component';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {FormDirty} from '../protocol-entry-form/protocol-entry-form.component';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {ToastService} from 'src/app/services/common/toast.service';
import {IonicSelectableComponent} from 'ionic-selectable';
import {DeviceService, QUERY} from 'src/app/services/ui/device.service';
import {PopoverService} from 'src/app/services/ui/popover.service';
import {MobiscrollService} from 'src/app/services/common/mobiscroll.service';
import {MbscDatepicker} from '@mobiscroll/angular-ivy';
import {Keyboard, KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';
import {Capacitor} from '@capacitor/core';
import {UnitService} from '../../../services/unit/unit.service';
import {UnitProfileAddress} from '../../../model/unit';
import {Nullish} from '../../../model/nullish';
import {isAddressFilled} from 'src/app/utils/address-utils';
import {UnitLevelDataService} from 'src/app/services/data/unit-level-data.service';

const LOG_SOURCE = 'ProtocolFormComponent';
const ONLY_POSITIVE_NUMBER = /^[0-9]\d*$/;
const NUMBER_ONLY_REGEX = /[0-9]/;

interface IonSelectableDropdown {
  uniqueId: number|string;
  label: string;
  value?: any;
}

export enum ProtocolLocationEnum {
  PROJECT_ADDRESS = 1,
  OFFICE_ADDRESS = 2,
  OTHER = 3,
  NEW_ADDRESS = 4
}

@Component({
  selector: 'app-protocol-form',
  templateUrl: './protocol-form.component.html',
  styleUrls: ['./protocol-form.component.scss'],
})
export class ProtocolFormComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() protocol: Protocol;
  @Input() enabledForm = true;
  @Input() protocolData: Array<Protocol>;

  @Input() formDirty: FormDirty;
  @Output() formDirtyChange = new EventEmitter<FormDirty>();

  @Input() form: UntypedFormGroup;
  @Output() formChange = new EventEmitter<UntypedFormGroup>();
  @Output() navigateAway = new EventEmitter<string>();

  @Output()
  protocolTypeChange = new EventEmitter<ProtocolType>();

  @ViewChild('inputName', {static: false}) inputName: IonInput;
  @ViewChild('inputLocation', {static: false}) inputLocation: IonInput;
  @ViewChild('typeSelectable', {static: false}) typeSelectable: SelectableComponent<any, any, any>;

  private clientAndProjectDataSubscription: Subscription|undefined;
  private clientAddressSubscription: Subscription|undefined;
  private protocolFormValueChangeSubscription: Subscription|undefined;
  private selectedProtocolTypeSubscription: Subscription|undefined;
  private unitSubscription: Subscription|undefined;
  private unitProfileAddressSubscription: Subscription|undefined;
  private protocolLayoutSubscription: Subscription|undefined;

  public readonly allowCreatedProjectSettings$ = this.selectableService.allowCreatedProjectSettings$;
  public protocolTypeData: Array<ProtocolType> | undefined;
  public allProtocolTypeData: Array<ProtocolType> | undefined;
  public selectableProtocolTypes: Array<ProtocolType>|undefined;
  public filteredSelectableProtocolTypes: Array<ProtocolType>|undefined;
  public allSelectedProtocolTypes: Array<ProtocolType>|undefined;
  public filteredAllSelectedProtocolTypes: Array<ProtocolType>|undefined;
  public protocolLocation: Array<string>;
  public protocolGroupBy: Array<string>;
  public clientData: Client|undefined;
  public currentProject: Project|undefined;
  public currentAddress: Address|undefined;
  public clientAddress: Address|null|undefined;
  public protocolLocations: Array<IonSelectableDropdown>|undefined;
  public protocolSortEntries: IonSelectableDropdown[]|undefined;
  public protocolTypeMessage: string;
  public ionicSelectableProtocolTypeMessage: string;
  public selectedLocation;
  public isNativeApp: boolean;
  public isAboveBreakpointMd: Observable<boolean> | undefined;
  private destroy$ = new Subject<void>();

  public protocolForm = this.formBuilder.group({
    name: ['', [Validators.required, this.systemNameValidator()]],
    number: [null, Validators.compose([Validators.required, Validators.pattern(ONLY_POSITIVE_NUMBER), Validators.max(999), this.uniqueNumberValidator()]),
      Validators.composeAsync([this.isFirstOfContinuousAsyncValidator()])],
    type: ['', [Validators.required], [this.continuousTypeAsyncValidator()]],
    selectedLocation: [''],
    location: [''],
    date: [new Date().toISOString()],
    timeFrom: [''],
    timeUntil: [''],
    sortEntriesBy: [''],
    includesVat: [false],
    weather: [],
    temperatureMin: [],
    temperatureMax: [],
    humidity: [null, [Validators.min(0), Validators.max(100), Validators.pattern(/^[\d|\.]+$/)]],
    windspeed: [null, [Validators.min(0), Validators.max(200), Validators.pattern(/^[\d|\.]+$/)]],
    weatherFromApi: [false],
    weatherActivated: [false],
    unit: [],
    isUnitEntryDefault: [false],
  });

  public showLocationInputText = false;
  public readOnlyLocationInputText = false;
  public protocolTypeEnabled = true;
  public locationInputLabel: string;
  public locationInputPlaceholder: string;
  public defaultProtocolLocation: IonSelectableDropdown;
  public timeUntilMinTime: Date|null = null;

  private protocolLayouts: ProtocolLayout[]|undefined;
  private selectedProtocolType: ProtocolType|undefined;
  private nextNumberAutomatic: number|undefined;

  public mbscThemeVariant$ = this.mobiscrollService.themeVariant$;
  public mbscLocale$ = this.mobiscrollService.locale$;
  public MBSC_DATE_FORMAT = this.mobiscrollService.DATE_FORMAT;
  public MBSC_TIME_FORMAT = this.mobiscrollService.TIME_FORMAT;

  @ViewChild('dateDatepicker', {static : false}) dateDatepicker: MbscDatepicker;
  @ViewChild('timeFromDatePicker', {static : false}) timeFromDatePicker: MbscDatepicker;
  @ViewChild('timeUntilDatePicker', {static : false}) timeUntilDatePicker: MbscDatepicker;

  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;
  units$ = this.unitService.unitsForBreadcrumbs$;
  unitLevels$ = this.unitLevelDataService.data;
  unitProfileAddresses: Array<UnitProfileAddress>;
  isProtocolLayoutStandard: boolean|undefined;
  isUnitFeatureEnabled: boolean|undefined;

  constructor(private formBuilder: UntypedFormBuilder,
              public translateService: TranslateService,
              private projectProtocolTypeDataService: ProjectProtocolTypeDataService,
              private protocolTypeDataService: ProtocolTypeDataService,
              private protocolTypeService: ProtocolTypeDataService,
              private protocolDataService: ProtocolDataService,
              private protocolLayoutDataService: ProtocolLayoutDataService,
              private projectDataService: ProjectDataService,
              private addressDataService: AddressDataService,
              private clientService: ClientService,
              private systemEventService: SystemEventService,
              private platform: Platform,
              private loggingService: LoggingService,
              private protocolService: ProtocolService,
              private selectableService: SelectableService,
              private selectableUtilService: SelectableUtilService,
              private toastService: ToastService,
              private deviceService: DeviceService,
              private popoverService: PopoverService,
              private mobiscrollService: MobiscrollService,
              private unitService: UnitService,
              private unitLevelDataService: UnitLevelDataService) { }

  async ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit called.');
    try {
      this.clientAndProjectDataSubscription = combineLatestAsync([this.projectProtocolTypeDataService.getProjectProtocolTypes(),
                                                            this.protocolTypeDataService.dataWithoutHiddenActive$,
                                                            this.clientService.currentClient$,
                                                            this.projectDataService.currentProjectObservable,
                                                            this.protocolLayoutDataService.data,
                                                            this.projectDataService.currentProjectObservable.pipe(switchMap((project) => this.addressDataService.getById(project.addressId)))])
      .subscribe(async ([protocolTypes, allProtocolTypes, currentClient, currentProject, protocolLayouts, currentAddress]) => {
        this.clientData = currentClient;
        this.protocolSortEntries = this.protocolService.getSortEntriesByValues(this.clientData);
        this.protocolTypeData = protocolTypes;
        this.allProtocolTypeData = allProtocolTypes;
        this.currentProject = currentProject;
        this.currentAddress = currentAddress;
        this.protocolLayouts = protocolLayouts;
        this.initProtocolTypes();
        await this.subscribeToClientAddress(this.clientData?.addressId);
        this.setGroupEntryDefaultValue();
      });
      this.isNativeApp = await this.deviceService.isNativeApp();
      this.isAboveBreakpointMd = this.deviceService.isAboveMediaQuery(QUERY.md);
      if (!this.protocol) {
        setTimeout(async () => {
          if (_.isEmpty(this.typeSelectable.ionicSelectable.value)){
            await this.typeSelectable.ionicSelectable.open();
          }
        }, 500);
      }
      if (!this.enabledForm) {
        this.protocolForm.get('sortEntriesBy').disable();
        this.protocolForm.get('unit').disable();
      }
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - ngOnInit', error?.userMessage + '-' + error?.message);
    }
    this.unitService.isFeatureEnabled$.pipe(takeUntil(this.destroy$)).subscribe((isEnabled) => this.isUnitFeatureEnabled = isEnabled);
  }

  private initProtocolTypes(selectedProtocolType?: ProtocolType) {
    if (_.isEmpty(selectedProtocolType)) {
      this.selectableProtocolTypes = this.protocolTypeData;
      this.filteredSelectableProtocolTypes = this.selectableProtocolTypes;
      this.allSelectedProtocolTypes = this.allProtocolTypeData;
    } else {
      this.selectableProtocolTypes = this.protocolTypeData ? this.protocolTypeData?.filter(protocoltype => protocoltype.layoutId === selectedProtocolType.layoutId) : [];
      this.filteredSelectableProtocolTypes = this.selectableProtocolTypes;
      this.allSelectedProtocolTypes = this.allProtocolTypeData ? this.allProtocolTypeData?.filter(protocoltype => protocoltype.layoutId === selectedProtocolType.layoutId) : [];
    }
  }

  ngOnDestroy() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called.');
    if (this.clientAndProjectDataSubscription) {
      this.clientAndProjectDataSubscription.unsubscribe();
      this.clientAndProjectDataSubscription = undefined;
    }

    this.clientAddressUnsubscribe();
    this.unsubscribeSelectedProtocolTypeSubscription();
    this.protocolFormValueChangeUnsubscribe();
    this.unitSubscription?.unsubscribe();
    this.unitSubscription = undefined;
    this.unitProfileAddressSubscription?.unsubscribe();
    this.unitProfileAddressSubscription = undefined;
    this.protocolLayoutSubscription?.unsubscribe();
    this.protocolLayoutSubscription = undefined;
    this.destroy$.next();
    this.destroy$.complete();
  }

  private clientAddressUnsubscribe() {
    if (this.clientAddressSubscription) {
      this.clientAddressSubscription.unsubscribe();
      this.clientAddressSubscription = undefined;
    }
  }

  private setProtocolLocationDefaultValue() {
    const defaultValue = this.protocolLocations?.length ? _.first(this.protocolLocations) : undefined;
    this.selectedLocation = defaultValue;
    this.protocolForm.get('selectedLocation').setValue(defaultValue);
    this.onLocationChange({defaultValue});
  }

  private initProtocolLocations(): Array<IonSelectableDropdown> {
    const protocolLocationDropdowns: Array<IonSelectableDropdown> = [];
    if (this.currentProject?.addressSite && !this.currentProject?.addressId) {
      protocolLocationDropdowns.push({
        uniqueId: ProtocolLocationEnum.PROJECT_ADDRESS,
        label: this.translateService.instant('protocolLocation.project_address'),
        value: this.currentProject?.addressSite
      });
    }
    if (this.currentProject?.addressId) {
      let addressString = '';
      if (isAddressFilled(this.currentAddress)) {
        addressString = formatOneLineAddress(this.currentAddress);
      }
      protocolLocationDropdowns.push({
        uniqueId: ProtocolLocationEnum.NEW_ADDRESS,
        label: this.translateService.instant('protocolLocation.project_address'),
        value: addressString
      });
    }
    if (!this.currentProject?.addressId && !this.currentProject.addressSite) {
      const addressString = '';
      protocolLocationDropdowns.push({
        uniqueId: ProtocolLocationEnum.NEW_ADDRESS,
        label: this.translateService.instant('protocolLocation.project_address'),
        value: addressString
      });
    }
  
    if (!_.isEmpty(this.clientAddress)) {
      protocolLocationDropdowns.push({
        uniqueId: ProtocolLocationEnum.OFFICE_ADDRESS,
        label: this.translateService.instant('protocolLocation.office_address'),
        value: this.getOfficeAddress()
      });
    }
    protocolLocationDropdowns.push({ uniqueId: ProtocolLocationEnum.OTHER, label: this.translateService.instant('protocolLocation.other'), value: '' });

    this.protocolLocations = protocolLocationDropdowns;
    return protocolLocationDropdowns;
  }

  private async subscribeToClientAddress(addressId: IdType | undefined) {
    this.clientAddressUnsubscribe();
    if (!addressId) {
      this.clientAddress = null;
      await this.initFormValues();
      return;
    }
    this.clientAddressUnsubscribe();
    this.clientAddressSubscription = this.addressDataService.getById(addressId)
      .subscribe(async (clientAddress) => {
        this.clientAddress = clientAddress;
        await this.initFormValues();
      });
  }

  private async initFormValues() {
    this.initProtocolLocations();
    this.setProtocolLocationDefaultValue();
    await this.initProtocolValues();
  }

  onLocationChange(event) {
    this.showLocationInputText = true;
    const uniqueId: ProtocolLocationEnum|undefined = event?.uniqueId;

    switch (uniqueId) {
      case ProtocolLocationEnum.NEW_ADDRESS: {
        this.locationInputPlaceholder = '';
        this.readOnlyLocationInputText = true;
        this.locationInputLabel = event.label;
        const addressString = formatOneLineAddress(this.currentAddress);
        this.protocolForm.get('location').setValue(addressString);
        break;
      }

      case ProtocolLocationEnum.PROJECT_ADDRESS: {
        this.locationInputPlaceholder = '';
        this.readOnlyLocationInputText = true;
        this.locationInputLabel = event.label;
        this.protocolForm.get('location').setValue(this.currentProject?.addressSite);
        break;
      }

      case ProtocolLocationEnum.OFFICE_ADDRESS: {
        this.locationInputPlaceholder = '';
        this.readOnlyLocationInputText = true;
        this.locationInputLabel = event.label;
        let address = '';
        if (!_.isEmpty(this.clientAddress)) {
          address = this.getOfficeAddress();
        }
        this.protocolForm.get('location').setValue(address);
        break;
      }

      case ProtocolLocationEnum.OTHER: {
        this.locationInputPlaceholder = this.translateService.instant('protocol.type_in_location');
        this.readOnlyLocationInputText = false;
        this.locationInputLabel = this.translateService.instant('protocol.location_details');
        this.protocolForm.get('location').setValue('');
        break;
      }
    }
  }

  async focusLocationOnOther(location: IonSelectableDropdown) {
    if (this.enabledForm && location.uniqueId === ProtocolLocationEnum.OTHER) {
      setTimeout(async () => {
        this.inputLocation.setFocus();
        if (Capacitor.isPluginAvailable('Keyboard')) {
          await Keyboard.show();
        }
      }, 300);
    }
  }

  private continuousTypeAsyncValidator(): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      const protocolType: ProtocolType | null = control.value;
      if (protocolType === null) {
        return null;
      }
      try {
        if (!this.checkProtocolTypeExist(protocolType) && _.isEmpty(this.protocol)) {
          return { unsupportedProtocolType: true, message: this.translateService.instant('protocol.type.not_supported', { type: protocolType.name }) };
        } else if (!this.checkProtocolTypeExist(protocolType)) {
          this.protocolTypeMessage = this.translateService.instant('protocol.type.not_supported', { type: protocolType.name });
        }

        if (!this.isProtocolTypeContinues(protocolType?.layoutId)) {
          return null;
        }

        const openContinuousProtocols = await observableToPromise(this.protocolDataService.findOpenProtocolsOfType(protocolType.id));
        return openContinuousProtocols.length > 0 && _.isEmpty(this.protocol) ? {openContinuousProtocolExists: true} : null;
      } catch (error) {
        await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - continuousTypeAsyncValidator', error?.userMessage + '-' + error?.message);
        return null;
      }
    };
  }

  private checkProtocolTypeExist(protocolType: ProtocolType): boolean {
    if (!this.protocolTypeData) {
      return true;
    }
    const existingProtocolType = this.protocolTypeData?.find((projectProtocolType) => projectProtocolType.id === protocolType.id);
    return !_.isEmpty(existingProtocolType);
  }

  private isProtocolTypeContinues(layoutId: IdType) {
    const protocolLayout: ProtocolLayout|undefined = this.protocolLayouts?.find(layout => layout.id === layoutId);
    return protocolLayout?.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS;
  }

  getOfficeAddress() {
    return formatOneLineAddress(this.clientAddress);
  }

  private setGroupEntryDefaultValue() {
    if (_.isEmpty(this.protocol?.sortEntriesBy)) {
      const defaultValue = _.first(this.protocolService.getSortEntriesByValues(this.clientData));
      this.protocolForm.get('sortEntriesBy').setValue(defaultValue);
    }
  }

  ngAfterViewInit() {
    this.checkFormIsDirty();
    this.subscribeFormValueChanges();
  }

  private checkFormIsDirty() {
    this.protocolFormValueChangeUnsubscribe();
    this.protocolFormValueChangeSubscription = this.protocolForm.valueChanges
    .pipe(debounceTime(100))
    .pipe(
      catchError((error, caught) => this.observableCatchRethrowErrorHandler('checkFormIsDirty - valueChangesSubscription(catchError)', error)),
      distinctUntilChanged((a, b) => {
        return _.isEqualWith(a, b, (value, other) => {
          if ((value === undefined || value === null) && (other === undefined || other === null)) {
            return true;
          }

          if ((_.isDate(value) || _.isDate(other))) {
            const vDate = convertDateTimeToISOString(value);
            const oDate = convertDateTimeToISOString(other);
            return Boolean(vDate && oDate && vDate === oDate);
          }

          return undefined;
        });
      })
    ).subscribe((value) => {
        try {
          this.updateFormDirty(this.protocolForm.dirty);
          this.updateForm();
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `checkFormIsDirty - valueChangesSubscription - subscribe. ${convertErrorToMessage(error)}`);
          this.systemEventService.logErrorEvent(LOG_SOURCE + ' checkFormIsDirty - valueChangesSubscription - subscribe.', error);
          this.toastService.error(`checkFormIsDirty - valueChangesSubscription - subscribe. ${convertErrorToMessage(error)}`);
        }
      });
  }

  private subscribeFormValueChanges() {
    this.protocolForm.get('unit').valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((unit) => {
        if (!unit) {
          if (this.enabledForm && this.isUnitFeatureEnabled && this.protocolForm.controls.isUnitEntryDefault.value) {
            this.protocolForm.controls.isUnitEntryDefault.setValue(null);
          }
        }
        this.subscribeToUnitProfileAddress(unit?.id);
      });
    this.protocolForm.get('type').valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((protocolType) => {
        this.subscribeToProtocolTypeLayout(protocolType?.id);
      });
  }

  private observableCatchRethrowErrorHandler(message: string, error: any): Observable<never> {
    this.loggingService.error(LOG_SOURCE, `${message} - ${convertErrorToMessage(error)}`);
    this.systemEventService.logErrorEvent(LOG_SOURCE + ' ' + message, error);
    this.toastService.error(`${message} - ${convertErrorToMessage(error)}`);
    return throwError(error);
  }

  private updateForm() {
    this.form = this.protocolForm;
    this.formChange.emit(this.protocolForm);
  }

  private updateFormDirty(dirty: boolean) {
    this.loggingService.debug(LOG_SOURCE, `updateFormDirty(${dirty}) called.`);
    const dirtyFormFields: Array<string> = dirty ? this.getDirtyFormFields() : [];
    const formDirty = {dirty, dirtyFormFields} as FormDirty;
    this.formDirty = formDirty;
    this.formDirtyChange.emit(formDirty);
  }

  private getDirtyFormFields(): Array<string> {
    return Object.entries(this.protocolForm.controls).filter(([key, value]) => value.dirty).map(([key, value]) => key);
  }

  private showProtocolTypeMessage(layoutId: IdType) {
    const protocolLayout: ProtocolLayout = this.protocolLayouts?.find(layout => layout.id === layoutId);
    if (protocolLayout?.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS) {
      this.protocolTypeMessage = this.translateService.instant('protocol.type.continues');
    } else if (protocolLayout?.name === PROTOCOL_LAYOUT_NAME_SHORT) {
      this.ionicSelectableProtocolTypeMessage = this.translateService.instant('protocol.type.short');
    } else if (protocolLayout?.name === PROTOCOL_LAYOUT_NAME_STANDARD) {
      this.ionicSelectableProtocolTypeMessage = this.translateService.instant('protocol.type.standard');
    }
  }

  setProtocolType(protocolType: ProtocolType, protocolTypeMessage?: string) {
    this.protocolForm.get('type').setValue(protocolType);
    if (_.isEmpty(this.form.get('name').value)) {
      this.form.get('name').setValue(protocolType.name);
    }
    if (!_.isEmpty(protocolTypeMessage)) {
      this.protocolTypeMessage = protocolTypeMessage;
    } else {
      this.protocolTypeMessage = '';
    }
  }

  setGroupByEntries(selectedSortEntriesBy: string|null) {
    const sortEntriesBy = this.protocolService.getSortEntriesByValues(this.clientData).find(groupEntry => groupEntry.uniqueId === selectedSortEntriesBy);
    if (!_.isEmpty(sortEntriesBy)) {
      this.protocolForm.get('sortEntriesBy').setValue(sortEntriesBy);
    } else {
      this.setGroupEntryDefaultValue();
    }
  }

  setIncludesVat(includesVat: boolean) {
    this.protocolForm.get('includesVat').setValue(includesVat);
  }

  private async initProtocolValues() {
    if (!_.isEmpty(this.protocol)) {
      this.unsubscribeSelectedProtocolTypeSubscription();
      this.selectedProtocolTypeSubscription = this.protocolTypeService.getById(this.protocol.typeId)
      .subscribe(selectedProtocolType => {
        this.selectedProtocolType = selectedProtocolType;
        this.protocolTypeEnabled = !this.isProtocolTypeContinues(selectedProtocolType?.layoutId);
        this.showProtocolTypeMessage(selectedProtocolType?.layoutId);
        this.protocolForm.get('type').setValue(selectedProtocolType);
        this.initProtocolTypes(selectedProtocolType);
      });
      this.protocolForm.get('name').setValue(this.protocol.name);
      this.protocolForm.get('number')?.setValue(this.protocol.number);
      this.protocolForm.get('includesVat').setValue(this.protocol.includesVat);
      if (!_.isEmpty(this.protocol?.date)) {
        this.protocolForm.get('date').setValue(convertISOStringToDate(this.protocol.date));
      }
      if (!_.isEmpty(this.protocol?.timeFrom)) {
        this.protocolForm.get('timeFrom').setValue(this.getTimeForDisplay(this.protocol.timeFrom));
        this.timeUntilMinTime = this.protocolForm.get('timeFrom').value;
      } else {
        this.timeUntilMinTime = null;
      }
      if (!_.isEmpty(this.protocol?.timeUntil)) {
        this.protocolForm.get('timeUntil').setValue(this.getTimeForDisplay(this.protocol.timeUntil));
      }

      this.protocolForm.get('weatherActivated').setValue(this.protocol.weatherActivated ?? false);

      if (this.protocol?.weather) {
        this.protocolForm.get('weather').setValue(this.protocol.weather);
      }
      if (this.protocol?.minTemp || this.protocol?.minTemp === 0) {
        this.protocolForm.get('temperatureMin').setValue(this.protocol.minTemp);
      }
      if (this.protocol?.maxTemp || this.protocol?.maxTemp === 0) {
        this.protocolForm.get('temperatureMax').setValue(this.protocol.maxTemp);
      }
      if (this.protocol?.humidity || this.protocol?.humidity === 0) {
        this.protocolForm.get('humidity').setValue(this.protocol.humidity);
      }
      if (this.protocol?.windspeed || this.protocol?.windspeed === 0) {
        this.protocolForm.get('windspeed').setValue(this.protocol.windspeed);
      }
      if (this.protocol?.weatherFromApi) {
        this.protocolForm.get('weatherFromApi').setValue(this.protocol.weatherFromApi);
      }

      const selectedLocation = this.protocolLocations?.find(location => location.value === this.protocol.location);
      if (selectedLocation) {
        this.selectedLocation = selectedLocation;
        this.protocolForm.get('selectedLocation').setValue(selectedLocation);
      }
      this.protocolForm.get('location').setValue(this.protocol.location);
      this.unitSubscription?.unsubscribe();
      this.unitSubscription = undefined;
      if (this.protocol.unitId) {
        this.unitSubscription = this.unitService.getUnitForBreadcrumbsById$(this.protocol.unitId).subscribe((unit) => {
          if (!this.protocolForm.get('unit').dirty || this.protocolForm.get('unit').value?.id === unit?.id) {
            this.protocolForm.get('unit').setValue(unit, {onlySelf: true});
          }
        });
      } else {
        this.protocolForm.get('unit').setValue(null);
        this.unitProfileAddresses = undefined;
      }
      this.protocolForm.get('isUnitEntryDefault').setValue(this.protocol.isUnitEntryDefault)
      this.subscribeToUnitProfileAddress(this.protocol.unitId);
      this.subscribeToProtocolTypeLayout(this.protocol.typeId);

      this.setGroupByEntries(this.protocol.sortEntriesBy);
    }
  }

  private getTimeForDisplay(date: Date | string | null): Date | string | null {
    if (date === null) {
      return null;
    }
    const momentDate = moment(date);
    return momentDate.isValid() ? momentDate.format('HH:mm:ss') : date;
  }

  private unsubscribeSelectedProtocolTypeSubscription() {
    if (this.selectedProtocolTypeSubscription) {
      this.selectedProtocolTypeSubscription.unsubscribe();
      this.selectedProtocolTypeSubscription = undefined;
    }
  }

  private protocolFormValueChangeUnsubscribe() {
    if (this.protocolFormValueChangeSubscription) {
      this.protocolFormValueChangeSubscription.unsubscribe();
      this.protocolFormValueChangeSubscription = undefined;
    }
  }

  closeCalender() {
    // workaround for ios, mbsc opens sometimes multiple calender dialogs
    if (this.platform.is('ios')) {
      const calenderModals = document.querySelectorAll('div.mbsc-calendar');
      for (const modal of calenderModals[Symbol.iterator]()) {
        modal.remove();
      }
    }
  }

  private subscribeToUnitProfileAddress(unitId: Nullish<IdType>) {
    this.unitProfileAddressSubscription?.unsubscribe();
    this.unitProfileAddressSubscription = undefined;
    if (!unitId) {
      this.unitProfileAddresses = undefined;
      return;
    }
    this.unitProfileAddressSubscription = this.unitService.getUnitProfileAddressesByUnitId$(unitId)
      .subscribe((unitProfileAddress) => this.unitProfileAddresses = unitProfileAddress);
  }

  private subscribeToProtocolTypeLayout(protocolTypeId: Nullish<IdType>) {
    this.protocolLayoutSubscription?.unsubscribe();
    this.protocolLayoutSubscription = undefined;
    if (!protocolTypeId) {
      this.isProtocolLayoutStandard = undefined;
      return;
    }
    this.protocolLayoutSubscription = this.protocolService.getProtocolLayoutByTypeId(protocolTypeId)
      .subscribe((protocolLayout) => {
        this.isProtocolLayoutStandard = protocolLayout.name === PROTOCOL_LAYOUT_NAME_STANDARD;
        if (this.enabledForm  && this.isUnitFeatureEnabled && !this.isProtocolLayoutStandard && this.protocolForm.controls.unit.value) {
          this.protocolForm.controls.unit.setValue(null);
        }
      })
  }

  createNewProtocolTypeFunction = (text?: string): Promise<ProtocolType|undefined> => {
    return this.selectableService.createNewProtocolType(text, this.selectedProtocolType?.layoutId);
  };

  assignProtocolTypeToProjectFunction = (item: ProtocolType, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignProtocolTypeToProject(item, assign);
  };

  protocolTypeChanged(event: SelectableChangeType<ProtocolType>) {
    if (!event.value) {
      this.loggingService.warn(LOG_SOURCE, 'protocolTypeChanged changed to an empty value even though it is a mandatory field.');
      return;
    }
    this.protocolTypeChange?.emit(event.value);
  }

  setNextNumberForThisType(number: number) {
    this.protocolForm.get('number').setValue(number);
    this.nextNumberAutomatic = number;
  }

  private systemNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const protocolName: string | null = control.value;
      if (protocolName && protocolName.toLowerCase() === TASK_PROTOCOL_NAME.toLowerCase()) {
        return {nameSystemReserved: true};
      }
      return null;
    };
  }

  private uniqueNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: number|null = control.value;
      if (value === null) {
        return null;
      }
      const typeId: IdType|null = this.protocolForm.get('type')?.getRawValue()?.id;
      if (!typeId) {
        return null;
      }
      if (!this.protocolData) {
        return null;
      }
      try {
        const protocolNumber: number = control.value;
        const protocolTypeId: string = this.protocolForm.get('type').getRawValue().id;
        if (this.protocol && this.protocol.number === protocolNumber) {
          return null;
        }
        const filteredProtocols = this.protocolData.filter((protocol) => protocol.typeId === protocolTypeId);
        if (filteredProtocols.some(protocol => protocol.number === protocolNumber)) {
          return {numberAlreadyUsed: true};
        } else {
          return null;
        }
      } catch (error) {
        this.loggingService.warn(LOG_SOURCE, `uniqueNumberValidator failed with error ${convertErrorToMessage(error)}`);
        return null;
      }
    };
  }


  private isFirstOfContinuousAsyncValidator(): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      if (control?.value >= 0 && this.protocolForm.get('type')?.getRawValue()?.id) {
        try {
          const protocolNumber: number = control.value;
          if (this.protocol && this.protocol.number === protocolNumber) {
            return null;
          }
          if (this.nextNumberAutomatic === protocolNumber) {
            return null;
          }
          const protocolTypeId: string = this.protocolForm.get('type').getRawValue().id;
          const isContinuous = await this.protocolService.isContinuousProtocolType(protocolTypeId);
          if (isContinuous && !this.protocol && !(await this.protocolService.isFirstOfTypeCreation(protocolTypeId))) {
            return {notFirstOfContinuous: true};
          } else if (this.protocol && isContinuous && !(await this.protocolService.isFirstOfTypeEdit(protocolTypeId))) {
            return {notFirstOfContinuous: true};
          } else {
            return null;
          }
        } catch (error) {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  numberOnlyValidation(event: any): void {
    const inputChar = String.fromCharCode(event.charCode);
    if (!NUMBER_ONLY_REGEX.test(inputChar)) {
      event.preventDefault();
    }
  }

  searchTypes(event: { component: IonicSelectableComponent; text: string }) {
    if (!event.text || event.text === '') {
      this.filteredSelectableProtocolTypes = this.selectableProtocolTypes;
      this.filteredAllSelectedProtocolTypes = this.allSelectedProtocolTypes;
    } else {
      event.component.startSearch();
      this.filteredSelectableProtocolTypes =
        this.selectableProtocolTypes.filter(type => type.code.toLowerCase().includes(event.text.toLowerCase()) || type.name.toLowerCase().includes(event.text.toLowerCase()));
      this.filteredAllSelectedProtocolTypes =
        this.allSelectedProtocolTypes.filter(type => type.code.toLowerCase().includes(event.text.toLowerCase()) || type.name.toLowerCase().includes(event.text.toLowerCase()));
      event.component.endSearch();
    }
  }

  async invalidProjectAddress() {
    const project = await this.projectDataService.getCurrentProject();
    const routerUrl = '/projects/' + project.id;
    this.navigateAway.emit(routerUrl);
  }

  invalidOfficeAddress() {
    const routerUrl = '/the-settings/admin-settings-client-data';
    this.navigateAway.emit(routerUrl);
  }

  resetDate = (event: any) => {
    this.dateDatepicker.setVal(null)
    this.dateDatepicker.close();
  }

  resetTimeFrom = (event: any) => {
    this.timeFromDatePicker.setVal(null)
    this.timeFromDatePicker.close();
  }

  resetTimeUntil = (event: any) => {
    this.timeUntilDatePicker.setVal(null)
    this.timeUntilDatePicker.close();
  }

  async onOpen($event: { component: IonicSelectableComponent }) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: { component: IonicSelectableComponent }) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
