import {Injectable, OnDestroy} from '@angular/core';
import {
  ChangedAtAware,
  IdType,
  PdfPlan,
  PdfPlanAttachment,
  PdfPlanFolder,
  PdfPlanMarker,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPage,
  PdfPlanPageMarking,
  PdfPlanVersion,
  PdfPlanVersionAccess,
  PdfPlanVersionQualityEnum,
  TagBase,
  TagClient
} from 'submodules/baumaster-v2-common';
import {ProjectDataService} from '../data/project-data.service';
import {v4 as uuid4} from 'uuid';
import {AuthenticationService} from '../auth/authentication.service';
import {Observable, of, Subscription} from 'rxjs';
import {PdfPlanDataService} from '../data/pdf-plan-data.service';
import {PdfPlanAttachmentDataService} from '../data/pdf-plan-attachment-data.service';
import {PdfPlanPageDataService} from '../data/pdf-plan-page-data.service';
import {getDocument, GlobalWorkerOptions, PDFDocumentProxy, RenderTask, PDFWorker} from 'pdfjs-dist';
import {LoggingService} from '../common/logging.service';
import {combineLatestAsync, observableToPromise} from '../../utils/async-utils';
import {PdfPlanFolderDataService} from '../data/pdf-plan-folder-data.service';
import {PdfPlanMarkerProtocolEntryDataService} from '../data/pdf-plan-marker-protocol-entry-data.service';
import {PhotoService} from '../photo/photo.service';
import {BlobFilenameBundle, BlobWidthHeightBundle, ensureMimeTypeSet} from '../../utils/attachment-utils';
import {PdfPlanPageMarkingDataService} from '../data/pdf-plan-page-marking-data.service';
import {PdfPlanVersionDataService} from '../data/pdf-plan-version-data.service';
import {map, switchMap} from 'rxjs/operators';
import {Nullish} from '../../model/nullish';
import _ from 'lodash';
import {SyncStrategy} from '../sync/sync-utils';
import {SyncService} from '../sync/sync.service';
import {ToastService} from '../common/toast.service';
import {TagService} from '../tags/tag.service';
import {ProtocolService} from '../protocol/protocol.service';
import {getProtocolEntryStatus, scalePdfPlanPageMarkingObjects} from '../../../../submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';
import {MarkerData, MarkerType, OldMarkerSize, OTHER_MARKER_OPACITY} from '../../../../submodules/baumaster-v2-common/dist/planMarker/fabricPdfPlanMarker';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolEntryService} from '../protocol/protocol-entry.service';
import {AbortError} from '../../shared/errors';
import {createTimeoutPromise} from '../../utils/fetch-utils';
import {PDFJS_DIST_WORKER_PATH, PDF_TO_IMAGE_CONVERSION_TIMEOUT_IN_MS} from '../../shared/constants';
import {PdfPlanVersionAccessDataService} from '../data/pdf-plan-version-access-data.service';

const LOG_SOURCE = 'PdfPlanService';

/**
 * 1 = highest quality (large files)
 * 0 = lowest quality (small files)
 */
const PDF_PLAN_JPEG_QUALITY = 0.5;

@Injectable({
  providedIn: 'root'
})
export class PdfPlanService implements OnDestroy {
  private authenticatedUserId: IdType | undefined;
  private authSubscription: Subscription;
  private pdfWorker: PDFWorker;

  constructor(private projectDataService: ProjectDataService,
              private authenticationService: AuthenticationService,
              private pdfPlanFolderDataService: PdfPlanFolderDataService,
              private pdfPlanDataService: PdfPlanDataService,
              private pdfPlanVersionDataService: PdfPlanVersionDataService,
              private pdfPlanAttachmentDataService: PdfPlanAttachmentDataService,
              private pdfPlanPageDataService: PdfPlanPageDataService,
              private pdfPlanPageMarkingDataService: PdfPlanPageMarkingDataService,
              private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
              private loggingService: LoggingService,
              private photoService: PhotoService,
              private syncService: SyncService,
              private toastService: ToastService,
              private tagService: TagService,
              private protocolService: ProtocolService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private protocolEntryService: ProtocolEntryService,
              private pdfPlanVersionAccessDataService: PdfPlanVersionAccessDataService
  ) {
    this.authSubscription = this.authenticationService.authenticatedUserId$.subscribe((authenticatedUserId) => this.authenticatedUserId = authenticatedUserId);
    // make sure the pdfjs folder is in sync with angular.json and the pdfjs-dist dependency in package.json
    GlobalWorkerOptions.workerSrc = PDFJS_DIST_WORKER_PATH;
    this.pdfWorker = new PDFWorker();
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  public async upload(pdfPlanFolder: PdfPlanFolder|undefined, pdfFile: BlobFilenameBundle, cadFiles: Array<BlobFilenameBundle>, planDate?: Date, pdfPlan?: PdfPlan, persistChanges = true,
                      pdfPlanVersionQuality = PdfPlanVersionQualityEnum.DEFAULT, abortSignal?: AbortSignal):
    Promise<{pdfPlanAttachment: PdfPlanAttachment, pdfPlanCadAttachments: Array<PdfPlanAttachment>, pdfPlan: PdfPlan, pdfPlanVersion: PdfPlanVersion, pdfPlanPages: Array<PdfPlanPage>,
      pdfBlob: Blob, imageBlobs: Array<Blob>, cadBlobs: Blob[],
      tags: Array<TagClient>}> {
    this.loggingService.debug(LOG_SOURCE, 'upload called.');
    const project = (await this.projectDataService.getCurrentProject());
    const projectId = project.id;
    const createdById = this.authenticatedUserId;
    const changedAt = new Date().toISOString();
    let insertPdfPlan = false;
    let latestPdfPlanVersion: PdfPlanVersion|undefined;
    let tagsSource: Array<TagClient>|undefined;

    if (abortSignal?.aborted) {
      throw new AbortError();
    }

    if (!pdfPlan) {
      insertPdfPlan = true;
      pdfPlan = {
        id: uuid4(),
        active: true,
        folderId: pdfPlanFolder?.id,
        createdById,
        createdAt: changedAt,
        changedAt
      };
    } else {
      latestPdfPlanVersion = await observableToPromise(this.pdfPlanVersionDataService.getLatestByPdfPlan$(pdfPlan.id));
      tagsSource = await observableToPromise(this.tagService.getTagsForObject$(latestPdfPlanVersion.id, 'pdfPlanVersions'));
    }

    const pdfPlanVersion: PdfPlanVersion = {
      id: uuid4(),
      pdfPlanId: pdfPlan.id,
      date: planDate ?? changedAt,
      name: latestPdfPlanVersion?.name ?? pdfFile.fileName?.substring(0, 60),
      number: latestPdfPlanVersion ? latestPdfPlanVersion.number + 1 : 1,
      index: this.getNextPdfPlanVersionIndex(latestPdfPlanVersion?.index),
      locationId: latestPdfPlanVersion?.locationId,
      scale: latestPdfPlanVersion?.scale,
      quality: pdfPlanVersionQuality,
      createdById,
      createdAt: changedAt,
      changedAt
    };

    const tags: Array<TagClient> = !tagsSource?.length ? [] : _.cloneDeep(tagsSource);

    const {fileNameWithoutExt, fileExt} = this.photoService.fileNameSplitToNameAndExt(pdfFile.fileName);
    const pdfBlob = ensureMimeTypeSet(pdfFile.blob, pdfFile.fileName);
    const pdfPlanAttachment: PdfPlanAttachment = {
      id: uuid4(),
      hash: uuid4(),
      mimeType: pdfBlob.type,
      fileName: pdfFile.fileName,
      fileExt,
      changedAt,
      createdAt: changedAt,
      createdById,
      projectId,
      pdfPlanVersionId: pdfPlanVersion.id
    };

    if (abortSignal?.aborted) {
      throw new AbortError();
    }

    const pdfPlanCadAttachments: Array<PdfPlanAttachment> = cadFiles.map((blobFileNameBundle) => {
      return {
        id: uuid4(),
        hash: uuid4(),
        mimeType: blobFileNameBundle.blob.type,
        fileName: blobFileNameBundle.fileName,
        fileExt,
        changedAt,
        createdAt: changedAt,
        createdById,
        projectId,
        pdfPlanVersionId: pdfPlanVersion.id
      };
    });
    const cadBlobs = cadFiles.map((cadFile) => cadFile.blob);

    const imageBlobWidthHeightBundles = await this.convertPdfToImages(pdfBlob, pdfPlanVersionQuality, abortSignal);
    if (abortSignal?.aborted) {
      throw new AbortError();
    }
    const pdfPlanPages = new Array<PdfPlanPage>();
    for (let i = 0; i < imageBlobWidthHeightBundles.length; i++) {
      const imageBlobWidthHeightBundle = imageBlobWidthHeightBundles[i];
      const pageNumber = i;
      const pdfPlanPage: PdfPlanPage = {
        id: uuid4(),
        hash: uuid4(),
        mimeType: imageBlobWidthHeightBundle.blob.type,
        fileName: `${fileNameWithoutExt}-${pageNumber}.jpg`,
        fileExt: 'jpg',
        width: imageBlobWidthHeightBundle.width,
        height: imageBlobWidthHeightBundle.height,
        changedAt,
        createdAt: changedAt,
        createdById: this.authenticatedUserId,
        projectId,
        pdfPlanVersionId: pdfPlanVersion.id,
        pageNumber
      };
      pdfPlanPages.push(pdfPlanPage);
    }

    const imageBlobs = imageBlobWidthHeightBundles.map((v) => v.blob);
    if (persistChanges) {
      if (insertPdfPlan) {
        if (abortSignal?.aborted) {
          throw new AbortError();
        }
        if (!pdfPlanFolder) {
          throw new Error('pdfPlanFolder is required, if persistChanges is set to true');
        }
        await this.pdfPlanDataService.insert(pdfPlan, projectId);
      }
      await this.pdfPlanVersionDataService.insert(pdfPlanVersion, projectId);
      await this.pdfPlanAttachmentDataService.insert(pdfPlanAttachment, projectId, {}, pdfBlob);
      await this.pdfPlanAttachmentDataService.insert(pdfPlanCadAttachments, projectId, {}, cadBlobs);
      await this.pdfPlanPageDataService.insert(pdfPlanPages, projectId, {}, imageBlobs);
      if (tagsSource?.length) {
        await this.tagService.saveGroupedTags({ [pdfPlanVersion.id]: tagsSource }, 'pdfPlanVersions');
      }
      this.loggingService.info(LOG_SOURCE, 'Successfully added a PDF plan to the local storage.');
    } else {
      this.loggingService.info(LOG_SOURCE, 'Successfully created PDF plan objects but did not persist changes to the local storage.');
    }
    return {
      pdfPlanAttachment,
      pdfPlanCadAttachments,
      pdfPlan,
      pdfPlanVersion,
      pdfPlanPages,
      pdfBlob,
      imageBlobs,
      cadBlobs,
      tags
    };
  }

  public latestPdfPlanVersionPages$(pdfPlanId: IdType): Observable<Array<PdfPlanPage>|undefined> {
    return this.pdfPlanVersionDataService.getLatestByPdfPlan$(pdfPlanId).pipe(
      switchMap((pdfPlanVersion) => pdfPlanVersion ? this.pdfPlanPageDataService.getPlanPageByPlanVersionId(pdfPlanVersion?.id) : of(undefined)));
  }

  public async copyMarkers(sourcePlanPagesId: Array<IdType>, sourceQuality: PdfPlanVersionQualityEnum, targetPlanPages: Array<PdfPlanPage>, targetQuality: PdfPlanVersionQualityEnum,
                           onlyOpenProtocols = false, includePdfPlanPageMarkingGeneral = true):
    Promise<{pdfPlanPageMarkings: Array<PdfPlanPageMarking>, pdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry>}> {
    const sourcePdfPlanPagesSorted: Array<PdfPlanPage> = _.orderBy(await observableToPromise(this.pdfPlanPageDataService.getByIds(sourcePlanPagesId)), ['pageNumber']);
    const targetPdfPlanPagesSorted: Array<PdfPlanPage> = _.orderBy(targetPlanPages, ['pageNumber']);

    if (sourcePdfPlanPagesSorted.length !== targetPdfPlanPagesSorted.length) {
      throw new Error(`Unable to copy markers from sourcePlanPages (length=${
        sourcePdfPlanPagesSorted.length
      }) to targetPdfPlanPages (length=${targetPdfPlanPagesSorted.length}) as the length does not match.`);
    }

    let filterProtocolEntry: (protocolEntryId: IdType|undefined) => boolean;
    if (onlyOpenProtocols) {
      const protocolOpenByProtocolEntryId = await observableToPromise(this.protocolService.protocolOpenByProtocolEntryId$());
      filterProtocolEntry = (protocolEntryId) => {
        if (!protocolEntryId) {
          return includePdfPlanPageMarkingGeneral;
        }
        return !!protocolOpenByProtocolEntryId.get(protocolEntryId);
      };
    } else {
      filterProtocolEntry = (protocolEntryId) => {
        if (!protocolEntryId) {
          return includePdfPlanPageMarkingGeneral;
        }
        return true;
      };
    }

    const pdfPlanPageMarkings = new Array<PdfPlanPageMarking>();
    const pdfPlanMarkerProtocolEntries = new Array<PdfPlanMarkerProtocolEntry>();
    const changedAt = new Date().toISOString();
    const createdAt = changedAt;

    for (let i = 0; i < sourcePdfPlanPagesSorted.length; i++) {
      const sourcePdfPlanPage = sourcePdfPlanPagesSorted[i];
      const targetPdfPlanPage = targetPdfPlanPagesSorted[i];
      if (sourcePdfPlanPage.pageNumber !== targetPdfPlanPage.pageNumber) {
        throw new Error(`Unable to copy markers from sourcePdfPlanPage with pageNumber ${
          sourcePdfPlanPage.pageNumber
        } to targetPdfPlanPage with pageNumber ${targetPdfPlanPage.pageNumber} as the pageNumbers do not match.`);
      }
      const sourcePdfPlanPageMarkings = (await observableToPromise(this.pdfPlanPageMarkingDataService.getByPlanPagesId([sourcePdfPlanPage.id])))
        .filter((value) => filterProtocolEntry(value.protocolEntryId));
      const sourcePdfPlanMarkerProtocolEntries = (await observableToPromise(this.pdfPlanMarkerProtocolEntryDataService.getByPlanPagesId([sourcePdfPlanPage.id])))
        .filter((value) => filterProtocolEntry(value.protocolEntryId));

      const iterPdfPlanPageMarkings: Array<PdfPlanPageMarking> = sourcePdfPlanPageMarkings.map((pdfPlanPageMarking) => {
          const newPdfPlanPageMarking = {
            ..._.cloneDeep(pdfPlanPageMarking),
            id: uuid4(),
            pdfPlanPageId: targetPdfPlanPage.id,
            changedAt,
            createdAt
          };
          if (sourceQuality !== targetQuality) {
            const factor = targetQuality / sourceQuality;
            scalePdfPlanPageMarkingObjects(newPdfPlanPageMarking, factor);
          }
          return newPdfPlanPageMarking;
        }
      );

      const iterPdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry> = sourcePdfPlanMarkerProtocolEntries.map((pdfPlanMarkerProtocolEntry) => {
          const newPdfPlanMarkerProtocolEntry = {
            ...pdfPlanMarkerProtocolEntry,
            id: uuid4(),
            pdfPlanPageId: targetPdfPlanPage.id,
            changedAt,
            createdAt
          };
          if (sourceQuality !== targetQuality) {
            const factor = targetQuality / sourceQuality;
            newPdfPlanMarkerProtocolEntry.positionX = Math.floor(newPdfPlanMarkerProtocolEntry.positionX * factor);
            newPdfPlanMarkerProtocolEntry.positionY = Math.floor(newPdfPlanMarkerProtocolEntry.positionY * factor);
          }
          return newPdfPlanMarkerProtocolEntry;
        }
      );

      pdfPlanPageMarkings.push(...iterPdfPlanPageMarkings);
      pdfPlanMarkerProtocolEntries.push(...iterPdfPlanMarkerProtocolEntries);
    }

    return {pdfPlanPageMarkings, pdfPlanMarkerProtocolEntries};
  }

  private canvasToBlob(canvas: HTMLCanvasElement, type: string, quality: number): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      canvas.toBlob(
        convertedBlob => {
          resolve(convertedBlob);
        },
        type,
        quality
      );
    });
  }

  public getNextPdfPlanVersionIndex(lastIndex: Nullish<string>): Nullish<string> {
    if (lastIndex === null || lastIndex === undefined || lastIndex === '') {
      return lastIndex;
    }
    const lastIndexAsNumber: number = +lastIndex;
    if (!isNaN(lastIndexAsNumber)) {
      return (lastIndexAsNumber + 1).toString();
    }
    if (lastIndex.length === 1 && lastIndex.match('[a-y]|[A-Y]')) {
      return String.fromCharCode(lastIndex.charCodeAt(0) + 1);
    }
    return undefined;
  }

  private async convertPdfToImages(pdfBlob: Blob, scale = 1, abortSignal?: AbortSignal, timeoutInMs = PDF_TO_IMAGE_CONVERSION_TIMEOUT_IN_MS): Promise<Array<BlobWidthHeightBundle>> {
    let canvas: HTMLCanvasElement|undefined;
    let pdfRenderTask: RenderTask|undefined;
    try {
      this.loggingService.debug(LOG_SOURCE, 'convertPdfToImages called');
      const startTime = new Date().getTime();

      if (abortSignal?.aborted) {
        throw new AbortError();
      }

      if (abortSignal) {
        abortSignal.onabort = () => {
          if (pdfRenderTask) {
            pdfRenderTask.cancel();
          }
        };
      }

      const type = 'image/jpeg';
      const arrayBuffer = await new Response(pdfBlob).arrayBuffer();
      canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D,
            data = arrayBuffer;
      // new Uint8Array(data), because getDocument accepts only TypedArray (even though ArrayBuffer would work just fine)
      // Because we currently use version where it is not _officially_ said it will work, a new Unit8Array is used.
      // See https://github.com/mozilla/pdf.js/issues/15269
      const pdf: PDFDocumentProxy = await getDocument({data: new Uint8Array(data), worker: this.pdfWorker}).promise;
      this.loggingService.debug(LOG_SOURCE, 'await getDocument(data).promise');
      const pages = new Array<BlobWidthHeightBundle>();
      for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
        if (abortSignal?.aborted) {
          throw new AbortError();
        }
        const page = await pdf.getPage(pageNumber);
        this.loggingService.debug(LOG_SOURCE, `convertPdfToImages - after getPage(${pageNumber})`);
        const viewport = page.getViewport({scale});
        const renderContext = { canvasContext: ctx, viewport };

        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderStart = new Date().getTime();
        this.loggingService.debug(LOG_SOURCE, `convertPdfToImages - before page.render`);
        pdfRenderTask = page.render(renderContext);
        await Promise.race([pdfRenderTask.promise, createTimeoutPromise(timeoutInMs)]);
        if (abortSignal?.aborted) {
          throw new AbortError();
        }
        pdfRenderTask = undefined;
        this.loggingService.debug(LOG_SOURCE, `page.render finished in ${new Date().getTime() - renderStart} ms`);
        const pageBlob = await this.canvasToBlob(ctx.canvas, type, PDF_PLAN_JPEG_QUALITY);
        const pageBlobWidthHeightBundle: BlobWidthHeightBundle = {
          blob: pageBlob,
          width: canvas.width,
          height: canvas.height,
        };
        this.loggingService.debug(LOG_SOURCE, `convertPdfToImages - after canvasToBlob`);
        if (abortSignal?.aborted) {
          throw new AbortError();
        }
        pages.push(pageBlobWidthHeightBundle);
      }

      this.loggingService.info(LOG_SOURCE, `Pdf document with ${pages.length} pages successfully converted to jpeg in ${new Date().getTime() - startTime}ms.`);
      return pages;
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, 'Error convertPdfToImages. ' + error.message);
      if (pdfRenderTask) {
        pdfRenderTask.cancel();
      }
      throw error;
    } finally {
      if (canvas) {
        canvas.height = 0;
        canvas.width = 0;
      }
    }
  }

  async deletePdfPlan(pdfPlan: PdfPlan) {
    const projectId = (await this.projectDataService.getCurrentProject()).id;

    const pdfPlanVersions = await observableToPromise(this.pdfPlanVersionDataService.findByPdfPlan$(pdfPlan.id));
    let pdfPlanPagesToDelete = new Array<PdfPlanPage>();
    let pdfPlanAttachmentsToDelete = new Array<PdfPlanAttachment>();
    let pdfPlanVersionAccessesToDelete: PdfPlanVersionAccess[] = [];
    const pdfPlanVersionsToDelete = new Array<PdfPlanVersion>();
    for (const pdfPlanVersion of pdfPlanVersions) {
      const {pdfPlanPages, pdfPlanAttachments, pdfPlanVersionAccesses} = await this.deletePdfPlanVersionInternal(pdfPlanVersion, projectId, false, false);
      pdfPlanPagesToDelete = pdfPlanPagesToDelete.concat(pdfPlanPages);
      if (pdfPlanAttachments?.length) {
        pdfPlanAttachmentsToDelete = pdfPlanAttachmentsToDelete.concat(pdfPlanAttachments);
      }
      if (pdfPlanVersionAccesses?.length) {
        pdfPlanVersionAccessesToDelete = pdfPlanVersionAccessesToDelete.concat(pdfPlanVersionAccesses);
      }
      pdfPlanVersionsToDelete.push(pdfPlanVersion);
    }
    await this.pdfPlanPageDataService.delete(pdfPlanPagesToDelete, projectId);
    await this.pdfPlanAttachmentDataService.delete(pdfPlanAttachmentsToDelete, projectId);
    if (pdfPlanVersionAccessesToDelete.length) {
      await this.pdfPlanVersionAccessDataService.delete(pdfPlanVersionAccessesToDelete, projectId);
    }
    await this.pdfPlanVersionDataService.delete(pdfPlanVersionsToDelete, projectId);
    await this.pdfPlanDataService.delete(pdfPlan, projectId);
  }

  async deletePdfPlanVersion(pdfPlanVersion: PdfPlanVersion, deleteMarkers = false) {
    const projectId = (await this.projectDataService.getCurrentProject()).id;

    const pdfPlan = await observableToPromise(this.pdfPlanDataService.getById(pdfPlanVersion.pdfPlanId));
    if (!pdfPlan) {
      throw new Error(`pdfPlan with id ${pdfPlanVersion.pdfPlanId} referenced by pdfPlanVersion ${pdfPlanVersion.id} was not found.`);
    }
    const pdfPlanVersions = await observableToPromise(this.pdfPlanVersionDataService.findByPdfPlan$(pdfPlanVersion.pdfPlanId));
    const indexOfPdfPlanVersion = pdfPlanVersions.findIndex((value) => value.id === pdfPlanVersion.id);
    if (pdfPlanVersions.length === 0 || indexOfPdfPlanVersion === -1) {
      throw new Error(`Deleting of pdfPlanVersion ${pdfPlanVersion.id} not possible as it was not found in the list of planVersions of plan ${pdfPlanVersion.pdfPlanId}`);
    } else if (indexOfPdfPlanVersion !== pdfPlanVersions.length - 1) {
      throw new Error(`Deleting of pdfPlanVersion ${pdfPlanVersion.id} not possible as a newer pdfPlanVersion ${pdfPlanVersions[pdfPlanVersions.length - 1]}} was found.`);
    }

    await this.deletePdfPlanVersionInternal(pdfPlanVersion, projectId, deleteMarkers);
    if (pdfPlanVersions.length === 1) {
      // the latest plan version was the only plan version. Also delete the PDF-Plan
      await this.pdfPlanDataService.delete(pdfPlan, projectId);
    }
    return true;
  }

  private async deletePdfPlanVersionInternal(pdfPlanVersion: PdfPlanVersion, projectId: IdType, deleteMarkers = false, performDelete = true):
    Promise<{pdfPlanPages: Array<PdfPlanPage>, pdfPlanAttachments: Array<PdfPlanAttachment>, pdfPlanVersion: PdfPlanVersion,
      pdfPlanMarkers: Array<PdfPlanMarker>, pdfPlanPageMarkings: Array<PdfPlanPageMarking>, pdfPlanVersionAccesses: Array<PdfPlanVersionAccess>}> {
    const pdfPlanPages = await observableToPromise(this.pdfPlanPageDataService.getPlanPageByPlanVersionId(pdfPlanVersion.id));
    const pdfPlanPageIds: Array<IdType> = pdfPlanPages.map((pdfPlanPage) => pdfPlanPage.id);
    const pdfPlanMarkers = await observableToPromise(this.pdfPlanMarkerProtocolEntryDataService.getByPlanPagesId(pdfPlanPageIds));
    const pdfPlanPageMarkings = await observableToPromise(this.pdfPlanPageMarkingDataService.getByPlanPagesId(pdfPlanPageIds));
    const pdfPlanPageMarkingsGeneral = pdfPlanPageMarkings.filter((v) => !v.protocolEntryId);
    const pdfPlanPageMarkingsProtocolEntry = pdfPlanPageMarkings.filter((v) => v.protocolEntryId);
    const pdfPlanAttachment = await observableToPromise(this.pdfPlanAttachmentDataService.getByPdfPlanVersionId$(pdfPlanVersion.id));
    const pdfPlanCadAttachments = await observableToPromise(this.pdfPlanAttachmentDataService.getCadFilesByPdfPlanVersionId$(pdfPlanVersion.id));
    const pdfPlanAttachments: Array<PdfPlanAttachment> = pdfPlanAttachment ? pdfPlanCadAttachments.concat([pdfPlanAttachment]) : pdfPlanCadAttachments;
    const pdfPlanVersionAccesses: Array<PdfPlanVersionAccess> = await observableToPromise(this.pdfPlanVersionAccessDataService.getAllByPlanVersionId$(pdfPlanVersion.id));

    if (!deleteMarkers && pdfPlanMarkers.length) {
      throw new Error(`Unable to delete PdfPlanVersion "${pdfPlanVersion.name}" with id ${pdfPlanVersion.id} because there are existing PlanMarkers referencing it.`);
    }
    if (!deleteMarkers && pdfPlanPageMarkingsProtocolEntry?.length) {
      throw new Error(`Unable to delete PdfPlanVersion "${pdfPlanVersion.name}" with id ${pdfPlanVersion.id} because there are existing PdfPlanPageMarkings referencing it.`);
    }

    if (performDelete) {
      this.loggingService.info(LOG_SOURCE, `deletePdfPlanVersionInternal - Deleting pdfPlanVersion ${pdfPlanVersion} and everything else since parameter performDelete is set to ${performDelete}.`);
      if (deleteMarkers) {
        if (pdfPlanMarkers?.length) {
          await this.pdfPlanMarkerProtocolEntryDataService.delete(pdfPlanMarkers, projectId);
        }
        if (pdfPlanPageMarkings?.length) {
          await this.pdfPlanPageMarkingDataService.delete(pdfPlanPageMarkings, projectId);
        }
      } else if (pdfPlanPageMarkingsGeneral.length) {
        await this.pdfPlanPageMarkingDataService.delete(pdfPlanPageMarkingsGeneral, projectId);
      }
      if (pdfPlanPages?.length) {
        await this.pdfPlanPageDataService.delete(pdfPlanPages, projectId);
      }
      if (pdfPlanAttachments?.length) {
        await this.pdfPlanAttachmentDataService.delete(pdfPlanAttachments, projectId);
      }
      if (pdfPlanVersionAccesses?.length) {
        await this.pdfPlanVersionAccessDataService.delete(pdfPlanVersionAccesses, projectId);
      }
      await this.pdfPlanVersionDataService.delete(pdfPlanVersion, projectId);
    } else {
      this.loggingService.info(LOG_SOURCE, `deletePdfPlanVersionInternal - Skip deleting pdfPlanVersion ${pdfPlanVersion} since parameter performDelete is set to false.`);
    }
    return {pdfPlanPages, pdfPlanAttachments, pdfPlanVersion, pdfPlanMarkers, pdfPlanPageMarkings, pdfPlanVersionAccesses};
  }

  async deletePdfPlanFolder(pdfPlanFolder: PdfPlanFolder) {
    const projectId = (await this.projectDataService.getCurrentProject()).id;
    const pdfPlans = await observableToPromise(this.pdfPlanDataService.getByPdfPlanFolder(pdfPlanFolder.id));

    for (const pdfPlan of pdfPlans) {
      await this.deletePdfPlan(pdfPlan);
    }
    await this.pdfPlanFolderDataService.delete(pdfPlanFolder, projectId);
  }

  async finishUploadingPdfPlanVersion(latestPdfPlanVersion$: Observable<PdfPlanVersion|undefined>, latestPdfPlanVersionAfterFileUpload: PdfPlanVersion|undefined,
                                      pdfPlanId: IdType|undefined, pdfPlan: PdfPlan|undefined, pdfPlanAttachment: PdfPlanAttachment|undefined,
                                      pdfPlanCadAttachments: Array<PdfPlanAttachment>|undefined, pdfPlanVersion: PdfPlanVersion | undefined, pdfPlanPages: Array<PdfPlanPage> | undefined,
                                      pdfBlob: Blob | undefined, imageBlobs: Array<Blob>|undefined, pdfPlanCadBlobs: Array<Blob> | undefined,
                                      pdfPlanPageMarkings: Array<PdfPlanPageMarking> | undefined, pdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry> | undefined,
                                      tagsAfterFileUpload: Array<TagClient> | undefined, tags: Array<TagBase> | undefined): Promise<void> {
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    const latestPdfPlanVersion = await observableToPromise(latestPdfPlanVersion$);
    if (latestPdfPlanVersion && latestPdfPlanVersionAfterFileUpload && !this.isEqualPdfPlanVersion(latestPdfPlanVersion, latestPdfPlanVersionAfterFileUpload)) {
      this.toastService.error('project_room.upload_pdf_plan_version.step.SUMMARY.errorLatestPdfPlanVersionChanged');
      return;
    }
    if (latestPdfPlanVersion) {
      const currentTags = await observableToPromise(this.tagService.getTagsForObject$(latestPdfPlanVersion.id, 'pdfPlanVersions'));
      const diff: Array<TagClient> = _.differenceBy(currentTags, tagsAfterFileUpload ?? [], 'id');
      if (diff.length) {
        this.toastService.error('project_room.upload_pdf_plan_version.step.SUMMARY.errorTagsChanged');
        return;
      }
    }

    const project = (await this.projectDataService.getMandatoryCurrentProject());
    const changedAt = new Date().toISOString();
    const projectId = project.id;
    if (!pdfPlanId) {
      await this.pdfPlanDataService.insert(this.updatedValueCreatedAtAndChangedAt(pdfPlan, changedAt), projectId);
    }
    await this.pdfPlanVersionDataService.insert(this.updatedValueCreatedAtAndChangedAt(pdfPlanVersion, changedAt), projectId);
    await this.pdfPlanAttachmentDataService.insert(this.updatedValueCreatedAtAndChangedAt(pdfPlanAttachment, changedAt), projectId, undefined, pdfBlob);
    if (pdfPlanCadAttachments?.length) {
      pdfPlanCadAttachments.forEach((pdfPlanCadAttachment) => this.updatedValueCreatedAtAndChangedAt(pdfPlanCadAttachment, changedAt));
      await this.pdfPlanAttachmentDataService.insert(pdfPlanCadAttachments, projectId, undefined, pdfPlanCadBlobs);
    }
    await this.pdfPlanPageDataService.insert(this.updatedValuesCreatedAtAndChangedAt(pdfPlanPages, changedAt), projectId, undefined, imageBlobs);
    await this.pdfPlanPageMarkingDataService.insert(this.updatedValuesCreatedAtAndChangedAt(pdfPlanPageMarkings, changedAt), projectId);
    await this.pdfPlanMarkerProtocolEntryDataService.insert(this.updatedValuesCreatedAtAndChangedAt(pdfPlanMarkerProtocolEntries, changedAt), projectId);
    if (tags) {
      await this.tagService.saveGroupedTags({[pdfPlanVersion.id]: tags}, 'pdfPlanVersions');
    }
  }

  private isEqualPdfPlanVersion(v1: PdfPlanVersion, v2: PdfPlanVersion): boolean {
    const propertiesToOmit = ['createdAtDb', 'changedAt', 'pdfPlanAttachment'];
    return _.isEqual(_.omit(v1, propertiesToOmit), _.omit(v2, propertiesToOmit));
  }

  private updatedValueCreatedAtAndChangedAt<T extends ChangedAtAware>(value: T, createdAtChangedAt: string): T {
    value.changedAt = createdAtChangedAt;
    if ('createdAt' in value) {
      _.set(value, 'createdAt', createdAtChangedAt);
    }
    return value;
  }

  private updatedValuesCreatedAtAndChangedAt<T extends ChangedAtAware>(values: Array<T>|undefined, createdAtChangedAt: string): Array<T>|undefined {
    if (!values) {
      return undefined;
    }
    values.forEach((value) => this.updatedValueCreatedAtAndChangedAt(value, createdAtChangedAt));
    return values;
  }

  async convertPdfPlanMarkerToMarkerData(pdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry>, pdfPlanPageId: IdType, oldMarkerSize = OldMarkerSize.SMALL, selectedProtocolEntryId?: IdType,
                                         isCurrentEntryMarkerFn = (value: PdfPlanMarkerProtocolEntry) => false):
    Promise<{markers: Array<MarkerData>, selectedMarker: MarkerData|undefined}> {
    const markers = new Array<MarkerData>();
    let selectedMarker: MarkerData|undefined;
    const oldMarkers = pdfPlanMarkerProtocolEntries.filter((marker: PdfPlanMarkerProtocolEntry) => marker.pdfPlanPageId === pdfPlanPageId);
    for (const oldMarker of oldMarkers) {
      const protocolEntry = await observableToPromise(this.protocolEntryDataService.getById(oldMarker.protocolEntryId));
      const isLayoutShort = await observableToPromise(this.protocolService.getIsProtocolLayoutShort$(protocolEntry?.protocolId));
      const title = await this.protocolEntryService.getShortId(protocolEntry);
      const status = isLayoutShort ? getProtocolEntryStatus(protocolEntry) : await this.protocolEntryService.getProtocolEntryIconStatusByEntryId(protocolEntry?.id);
      const isCurrentMarker = selectedProtocolEntryId && selectedProtocolEntryId === protocolEntry?.id;
      const isCurrentEntryMarker = isCurrentEntryMarkerFn(oldMarker);
      const oldMarkerData: MarkerData = {
        id: oldMarker.id,
        title,
        status,
        protocolEntry,
        pdfPlanMarker: oldMarker,
        x: oldMarker.positionX,
        y: oldMarker.positionY,
        lockDrag: true,
        markerType: isCurrentMarker || isCurrentEntryMarker ? MarkerType.NEW : MarkerType.OLD,
        pdfPlanPageId,
        oldMarkerSize,
        opacity: isCurrentMarker || isCurrentEntryMarker ? undefined : OTHER_MARKER_OPACITY
      };

      if (isCurrentMarker) {
        selectedMarker = oldMarkerData;
      }
      markers.push(oldMarkerData);
    }
    return {markers, selectedMarker};
  }

  public convertMarkerDataToPdfPlanMarkers(markerData: Array<MarkerData>, existingPdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry>): Array<PdfPlanMarkerProtocolEntry> {
    const pdfPlanMarkerProtocolEntries = new Array<PdfPlanMarkerProtocolEntry>();

    for (const marker of markerData) {
      const pdfPlanMarkerProtocolEntryId = marker.id;
      const existingPdfPlanMarkerProtocolEntry = existingPdfPlanMarkerProtocolEntries.find((value) => value.id === pdfPlanMarkerProtocolEntryId);
      if (!existingPdfPlanMarkerProtocolEntry) {
        throw new Error(`convertMarkerDataToPdfPlanMarkers - PdfPlanMarkerProtocolEntry with id "${pdfPlanMarkerProtocolEntryId}" not found.`);
      }
      const pdfPlanMarkerProtocolEntry: PdfPlanMarkerProtocolEntry = {
        ...existingPdfPlanMarkerProtocolEntry,
        positionX: marker.x,
        positionY: marker.y
      };
      pdfPlanMarkerProtocolEntries.push(pdfPlanMarkerProtocolEntry);
    }

    return pdfPlanMarkerProtocolEntries;
  }

  getLatestPdfPlanPageIds$(acrossProjects = false): Observable<Array<IdType>> {
    const pdfPlanVersions$ = acrossProjects ? this.pdfPlanVersionDataService.dataLatestVersionAcrossProjects$ : this.pdfPlanVersionDataService.dataLatestVersion$;
    return pdfPlanVersions$.pipe(switchMap((pdfPlanVersions) => {
      const pdfPlanVersionIds: Array<IdType> = pdfPlanVersions.map((pdfPlanVersion) => pdfPlanVersion.id);
      return acrossProjects ? this.pdfPlanPageDataService.getPlanPageByPlanVersionIds(pdfPlanVersionIds) : this.pdfPlanPageDataService.getPlanPageByPlanVersionIds(pdfPlanVersionIds);
    }))
      .pipe(map((pdfPlanPages) => pdfPlanPages.map((pdfPlanPage) => pdfPlanPage.id)));
  }

  getLatestMarkers(protocolEntryIds: Array<IdType>, acrossProjects = false):
    Observable<{pdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry>, pdfPlanPageMarkings: Array<PdfPlanPageMarking>}> {
    const pdfPlanMarkers$ = acrossProjects ? this.pdfPlanMarkerProtocolEntryDataService.dataAcrossProjects$ : this.pdfPlanMarkerProtocolEntryDataService.data;
    const pdfPlanPageMarkings$ = acrossProjects ? this.pdfPlanPageMarkingDataService.dataAcrossProjects$ : this.pdfPlanPageMarkingDataService.data;
    return combineLatestAsync([this.getLatestPdfPlanPageIds$(acrossProjects), pdfPlanMarkers$, pdfPlanPageMarkings$])
      .pipe((map(([pdfPlanPageIds,  pdfPlanMarkers, pdfPlanPageMarkings]) => {
        return {
          pdfPlanMarkerProtocolEntries: pdfPlanMarkers.filter((pdfPlanMarker) =>
            pdfPlanPageIds.includes(pdfPlanMarker.pdfPlanPageId) && protocolEntryIds.includes(pdfPlanMarker.protocolEntryId)),
          pdfPlanPageMarkings: pdfPlanPageMarkings.filter((pdfPlanPageMarking) =>
            pdfPlanPageIds.includes(pdfPlanPageMarking.pdfPlanPageId) && protocolEntryIds.includes(pdfPlanPageMarking.protocolEntryId))
        };
      })));
  }

  getLatestPdfPLanMarkers(protocolEntryIds: Array<IdType>, acrossProjects = false): Observable<Array<PdfPlanMarkerProtocolEntry>> {
    const pdfPlanMarkers$ = acrossProjects ? this.pdfPlanMarkerProtocolEntryDataService.dataAcrossProjects$ : this.pdfPlanMarkerProtocolEntryDataService.data;
    return combineLatestAsync([this.getLatestPdfPlanPageIds$(acrossProjects), pdfPlanMarkers$])
      .pipe((map(([pdfPlanPageIds, pdfPlanMarkers]) =>
        pdfPlanMarkers.filter((pdfPlanMarker) =>
          pdfPlanPageIds.includes(pdfPlanMarker.pdfPlanPageId) && protocolEntryIds.includes(pdfPlanMarker.protocolEntryId))
      )));
  }

  getLatestPdfPlanPageMarkings(protocolEntryIds: Array<IdType>, acrossProjects = false): Observable<Array<PdfPlanPageMarking>> {
    const pdfPlanPageMarkings$ = acrossProjects ? this.pdfPlanPageMarkingDataService.dataAcrossProjects$ : this.pdfPlanPageMarkingDataService.data;
    return combineLatestAsync([this.getLatestPdfPlanPageIds$(acrossProjects), pdfPlanPageMarkings$])
      .pipe((map(([pdfPlanPageIds, pdfPlanPageMarkings]) =>
        pdfPlanPageMarkings.filter((pdfPlanPageMarking) =>
          pdfPlanPageIds.includes(pdfPlanPageMarking.pdfPlanPageId) && protocolEntryIds.includes(pdfPlanPageMarking.protocolEntryId))
      )));
  }
}
