import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {TextTemplate} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-text-template-edit-form',
  templateUrl: './text-template-edit-form.component.html',
  styleUrls: ['./text-template-edit-form.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    UiModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class TextTemplateEditFormComponent implements OnInit {

  @Input()
  textTemplate?: TextTemplate;

  @Input()
  prefillTextTemplate?: Partial<TextTemplate>;

  form = this.fb.group({
    name: ['', Validators.required],
    textTemplate: ['', Validators.required],
  });

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    if (this.textTemplate) {
      this.form.patchValue(_.pick(this.textTemplate, ['name', 'textTemplate']));
    }
    if (this.prefillTextTemplate) {
      this.form.patchValue(_.pick(this.prefillTextTemplate, ['name', 'textTemplate']));
    }
  }

}
