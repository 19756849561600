<ion-list>
  <ion-item button (click)="changePriority(null)">
    <ion-buttons>
      <ion-button class="ion-margin-horizontal app-no-priority">
        <fa-icon [icon]="['bau', 'flag']"></fa-icon>
      </ion-button>
    </ion-buttons> {{ 'no_priority' | translate }}
  </ion-item>
  <ion-item button (click)="changePriority(priorityLevel.LOW)">
    <ion-buttons>
      <ion-button class="ion-margin-horizontal app-priority-3">
        <fa-icon [icon]="['bau', 'flag']"></fa-icon>
      </ion-button>
    </ion-buttons> {{ 'lowPriority' | translate }}
  </ion-item>
  <ion-item button (click)="changePriority(priorityLevel.MEDIUM)">
    <ion-buttons>
      <ion-button class="ion-margin-horizontal app-priority-2">
        <fa-icon [icon]="['bau', 'flag']"></fa-icon>
      </ion-button>
    </ion-buttons> {{ 'mediumPriority' | translate }}
  </ion-item>
  <ion-item button (click)="changePriority(priorityLevel.HIGH)">
    <ion-buttons>
      <ion-button class="ion-margin-horizontal app-priority-1">
        <fa-icon [icon]="['bau', 'flag']"></fa-icon>
      </ion-button>
    </ion-buttons> {{ 'highPriority' | translate }}
  </ion-item>
</ion-list>
