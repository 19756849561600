import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, ContentChild, Directive, EventEmitter, HostBinding, Input, Output, TemplateRef} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {Nullish} from 'src/app/model/nullish';
import {DATE_FORMAT_WITH_SHORT_YEAR} from 'src/app/shared/constants';
import {getCalendarWeek} from 'src/app/utils/date-utils';
import {ProtocolEntryIconStatus, ProtocolEntryPriorityLevel, ProtocolEntryPriorityType} from 'submodules/baumaster-v2-common';
import {ENTRY_CARD_SETTINGS_DEFAULT} from '../../../model/entry-card-model';
import {v4} from 'uuid';
import {TooltipModule} from 'src/app/shared/module/tooltip/tooltip.module';

@Directive({standalone: true, selector: '[appEntryCardFooterTemplate]'})
export class EntryCardFooterTemplateDirective {}

@Directive({standalone: true, selector: '[appEntryCardCheckboxTemplate]'})
export class EntryCardCheckboxTemplateDirective {}

@Component({
  selector: 'app-entry-card',
  templateUrl: './entry-card.component.html',
  styleUrls: ['./entry-card.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IonicModule,
    FontAwesomeModule,
    TranslateModule,
    FormsModule,
    TooltipModule,
  ]
})
export class EntryCardComponent {

  protected readonly instanceId = v4();
  readonly ProtocolEntryIconStatus = ProtocolEntryIconStatus;
  readonly ProtocolEntryPriorityLevel = ProtocolEntryPriorityLevel;
  readonly TAGLINE_DATE_FORMAT = DATE_FORMAT_WITH_SHORT_YEAR;

  @Input()
  protocolEntryNumber?: Nullish<string>;

  @Input()
  taskNumber?: Nullish<string | number>;

  @HostBinding('class.is-carried-over')
  @Input()
  isCarriedOver?: Nullish<boolean>;

  @Input()
  createdInProtocol?: Nullish<string>;

  @Input()
  isContinuousInfo: boolean;

  @Input()
  titleText: string;

  @Input()
  text: string;

  @HostBinding('class.is-protocol-closed')
  @Input()
  isProtocolClosed: boolean;

  @Input()
  company?: Nullish<string>;

  @Input()
  craft?: Nullish<string>;

  @Input()
  type?: Nullish<string>;

  @Input()
  date?: Nullish<string | Date>;

  @Input()
  priority?: Nullish<ProtocolEntryPriorityType>;

  @Input()
  hasMarker?: Nullish<boolean> = false;

  @Input()
  hasAttachments?: Nullish<boolean> = false;

  @Input()
  hasChat?: Nullish<boolean> = false;

  @Input()
  subEntriesCount?: Nullish<number> = 0;

  @Input()
  status?: Nullish<ProtocolEntryIconStatus> = ProtocolEntryIconStatus.INFO;

  @Input()
  isNew?: boolean = false;

  @Input()
  checkboxSelected?: boolean = false;

  @Output()
  checkboxSelectedChange = new EventEmitter<boolean>();

  @Output()
  statusClick = new EventEmitter<MouseEvent>();

  @Output()
  threeDotsClick = new EventEmitter<MouseEvent>();

  @Input()
  entryCardSettings = ENTRY_CARD_SETTINGS_DEFAULT;

  @Input()
  showSelectCheckbox = true;

  @Input()
  showThreeDots = false;

  @ContentChild(EntryCardFooterTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  protected footerTemplate: Nullish<TemplateRef<unknown>>;

  @ContentChild(EntryCardCheckboxTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  protected checkboxTemplate: Nullish<TemplateRef<unknown>>;

  @HostBinding('class.has-task-number')
  get hasTaskNumber() { return this.taskNumber !== undefined && this.taskNumber !== null; }
  @HostBinding('class.has-protocol-entry-number')
  get hasProtocolEntryNumber() { return this.protocolEntryNumber !== undefined && this.protocolEntryNumber !== null; }
  @HostBinding('class.show-id')
  get showId() { return this.entryCardSettings.showId; }
  @HostBinding('class.show-text')
  get showText() { return this.entryCardSettings.showText; }
  @HostBinding('class.show-initials')
  get showInitials() { return this.entryCardSettings.showInitials; }
  @HostBinding('class.show-craft')
  get showCraft() { return this.entryCardSettings.showCraft; }
  @HostBinding('class.show-type')
  get showType() { return this.entryCardSettings.showType; }
  @HostBinding('class.show-date-and-priority')
  get showPriorityDate() { return this.entryCardSettings.showPriorityDate; }
  @HostBinding('class.show-icon-details')
  get showAttachmentsSub() { return this.entryCardSettings.showAttachmentsSub; }
  @HostBinding('class')
  get statusName() {
    if (this.status === ProtocolEntryIconStatus.OPEN) {
      return 'status-open';
    }
    if (this.status === ProtocolEntryIconStatus.ON_HOLD) {
      return 'status-in-review';
    }
    if (this.status === ProtocolEntryIconStatus.DONE) {
      return 'status-done';
    }
    return 'status-info';
  }



  get statusIcon() {
    if (this.status === ProtocolEntryIconStatus.OPEN) {
      return ['bau', 'status-open'];
    }
    if (this.status === ProtocolEntryIconStatus.ON_HOLD) {
      return ['bau', 'status-on-hold'];
    }
    if (this.status === ProtocolEntryIconStatus.DONE) {
      return ['bau', 'status-done'];
    }
    if (this.isContinuousInfo) {
      return ['bau', 'status-continuous-info'];
    }
    return ['bau', 'status-info'];
  }

  get statusClasses() {
    if (this.status === ProtocolEntryIconStatus.OPEN) {
      return 'error';
    }
    if (this.status === ProtocolEntryIconStatus.ON_HOLD) {
      return 'warning';
    }
    if (this.status === ProtocolEntryIconStatus.DONE) {
      return 'success';
    }
    return 'primary';
  }

  get badgeColorClass() {
    if (this.isProtocolClosed || this.isCarriedOver) {
      return 'badged-id--grey';
    }
    if (this.status === ProtocolEntryIconStatus.OPEN) {
      return 'badged-id--red';
    }
    if (this.status === ProtocolEntryIconStatus.ON_HOLD) {
      return 'badged-id--yellow';
    }
    if (this.status === ProtocolEntryIconStatus.DONE) {
      return 'badged-id--green';
    }
    return 'badged-id--blue';
  }

  get priorityClasses() {
    if (this.priority === ProtocolEntryPriorityLevel.LOW) {
      return 'text-primary';
    }
    if (this.priority === ProtocolEntryPriorityLevel.MEDIUM) {
      return 'warning';
    }
    if (this.priority === ProtocolEntryPriorityLevel.HIGH) {
      return 'danger';
    }
    return 'inactive';
  }

  get dateWeek() {
    return getCalendarWeek(new Date(this.date));
  }

  constructor() { }

}
