import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import _ from 'lodash';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {TagClientObject, IdType, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {map, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/tagClientObjects';

@Injectable({
  providedIn: 'root'
})
export class TagClientObjectDataService extends AbstractClientAwareDataService<TagClientObject> {
  public readonly dataGroupedByObjectId$: Observable<Record<IdType, TagClientObject[]>> = this.data.pipe(
    map((tagObjects) => _.groupBy(tagObjects, 'objectId')),
    shareReplay(1)
  );

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, userService: UserService, clientService: ClientService, loggingService: LoggingService,
              integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.TAG_CLIENT_OBJECT, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService,
          integrityResolverService, VERSION_INTRODUCED_DEFAULT, ['changedAt']);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
