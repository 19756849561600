import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';

import {Attachment, Protocol, ProtocolEntry, ProtocolEntryChat} from 'submodules/baumaster-v2-common';
import {ProtocolEntryChatDataService} from '../../../services/data/protocol-entry-chat-data.service';
import {ModalController} from '@ionic/angular';
import {ProtocolEntryChatCreateComponent} from '../protocol-entry-chat-create/protocol-entry-chat-create.component';
import {Subscription} from 'rxjs';
import {AttachmentBlob} from '../../../model/attachments';

@Component({
  selector: 'app-protocol-entry-chats',
  templateUrl: './protocol-entry-chats.component.html',
  styleUrls: ['./protocol-entry-chats.component.scss']
})

export class ProtocolEntryChatsComponent implements OnChanges, OnDestroy {

  @Input() acrossProjects = true;
  @Input() protocolEntry: ProtocolEntry;
  @Input() protocol: Protocol;
  @Input() isCarriedEntry: boolean;
  @Output() public markingsChanged = new EventEmitter<{ attachment: Attachment | AttachmentBlob, markings: string }>();
  @Output() public attachmentDeleted = new EventEmitter<{ attachment: Attachment | AttachmentBlob }>();


  public chatData: ProtocolEntryChat[];
  private chatDataSubscription: Subscription | undefined;

  constructor(private protocolEntryChatDataService: ProtocolEntryChatDataService, private modalController: ModalController) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chatDataUnsubscribe();
    this.chatDataSubscription = (this.acrossProjects
      ? this.protocolEntryChatDataService.getByProtocolEntryAcrossProjects(this.protocolEntry.id)
      : this.protocolEntryChatDataService.getByProtocolEntry(this.protocolEntry.id)
    ).subscribe(chats => {
      this.chatData = chats;
    });
  }

  async openCreationWindow() {
    const modal = await this.modalController.create({
      component: ProtocolEntryChatCreateComponent,
      backdropDismiss: true,
      cssClass: 'half-screen-modal',
      componentProps: {
        protocolEntry: this.protocolEntry,
        acrossProjects: this.acrossProjects,
      }
    });
    return await modal.present();
  }

  public onMarkingsChanged(attachment: Attachment | AttachmentBlob, markings: string) {
    this.markingsChanged.emit({attachment, markings});
  }

  public onAttachmentDeleted(attachment: Attachment | AttachmentBlob) {
    this.attachmentDeleted.emit({attachment});
  }

  ngOnDestroy(): void {
    this.chatDataUnsubscribe();
  }

  private chatDataUnsubscribe() {
    if (this.chatDataSubscription) {
      this.chatDataSubscription.unsubscribe();
      this.chatDataSubscription = undefined;
    }
  }
}
