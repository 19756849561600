import {Injectable} from '@angular/core';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {IdType, PdfProtocolSetting, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/pdfProtocolSettings';

@Injectable({
  providedIn: 'root'
})
export class PdfProtocolSettingDataService extends AbstractProjectAwareDataService<PdfProtocolSetting>{

  constructor(http: HttpClient, storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.PDF_PROTOCOL_SETTING, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

   getByProtocolTypeId(protocolTypeId: IdType): Observable<PdfProtocolSetting> {
    return this.data.pipe(map(settings => {
      return settings.find((setting) => setting.protocolTypeId === protocolTypeId);
    }));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
