import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, OperatorFunction, ReplaySubject, Subscription} from 'rxjs';
import {debounceTime, filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {ReportFilterType} from 'src/app/model/report-filter-type';
import {ReportGroup, ReportMonthGroup, ReportWeekGroup, ReportYearGroup} from 'src/app/model/report-group';
import {StorageKeyEnum} from 'src/app/shared/constants';
import {observableToPromise} from 'src/app/utils/async-utils';
import {getCalendarWeek} from 'src/app/utils/date-utils';
import {Report} from 'submodules/baumaster-v2-common';
import {AuthenticationService} from '../auth/authentication.service';
import {ReportService} from './report.service';
import {StorageService} from '../storage.service';
import {ReportsPageTypeEnum, ReportTypeOrCustomReportType} from '../../model/reports';

const STORAGE_KEY = StorageKeyEnum.REPORTS_FILTER;

interface ReportFilterState {
  type: ReportFilterType;
}

export const isReportWeekGroup = (group: ReportGroup): group is ReportWeekGroup => {
  if (!group) {
    return false;
  }
  return 'weekNumber' in group && 'year' in group && !('month' in group);
};

export const isReportMonthGroup = (group: ReportGroup): group is ReportMonthGroup => {
  if (!group) {
    return false;
  }
  return !('weekNumber' in group) && 'year' in group && 'month' in group;
};

export const isReportYearGroup = (group: ReportGroup): group is ReportYearGroup => {
  if (!group) {
    return false;
  }
  return !('weekNumber' in group) && !('month' in group) && 'year' in group;
};

const findOrCreateGroup = <T extends ReportGroup>(groups: T[], groupDiscriminator: Omit<T, 'reports'>): [boolean, T] => {
  const group = groups.find((g) => Object.entries(groupDiscriminator).every(([key, value]) => g[key] === value));
  if (group) {
    return [true, group];
  }
  return [false, {
    reports: [],
    ...groupDiscriminator,
  } as T]; // https://github.com/microsoft/TypeScript/issues/35858
};

const withCurrentPeriodGroup = <T extends ReportGroup>(groupDiscriminatorMaker: (date: Date) => Omit<T, 'reports'>): OperatorFunction<T[], T[]> => map((groups: T[]): T[] => {
  const [existed, currentGroup] = findOrCreateGroup(groups, groupDiscriminatorMaker(new Date()));

  if (existed) {
    return groups;
  }
  return [currentGroup, ...groups];
});

const groupDiscriminatorWeek = (date: Date): Omit<ReportWeekGroup, 'reports'> => ({
  weekNumber: getCalendarWeek(date),
  year: date.getFullYear(),
});

const groupDiscriminatorMonth = (date: Date): Omit<ReportMonthGroup, 'reports'> => ({
  month: date.getMonth(),
  year: date.getFullYear(),
});

const groupDiscriminatorYear = (date: Date): Omit<ReportYearGroup, 'reports'> => ({
  year: date.getFullYear(),
});

export const groupCompare = (
  a: Partial<ReportWeekGroup & ReportMonthGroup & ReportYearGroup>,
  b: Partial<ReportWeekGroup & ReportMonthGroup & ReportYearGroup>
) => a && b && a.month === b.month && a.weekNumber === b.weekNumber && a.year === b.year;

const groupByMap = <T extends ReportGroup>(groupDiscriminatorMaker: (date: Date) => Omit<T, 'reports'>): OperatorFunction<Report[], T[]> => {
  return map((data: Report[]) => data
    // Sort by id desc
    .sort((a, b) => b.reportNumber > a.reportNumber ? 1 : b.reportNumber < a.reportNumber ? -1 : 0)
    // Group by discriminator
    .reduce((acc: T[], report: Report) => {
      const date = new Date(report.date);

      const [existing, group] = findOrCreateGroup(acc, groupDiscriminatorMaker(date));

      group.reports.push(report);

      if (!existing) {
        acc.push(group);
      }

      return acc;
    }, []));
};

@Injectable()
export class ReportFilterService implements OnDestroy {
  private readonly reportsPageTypeSubject = new BehaviorSubject<ReportsPageTypeEnum|undefined>(undefined);
  public readonly reportsPageType$ = this.reportsPageTypeSubject.asObservable();
  public readonly reportTypes$: Observable<Array<ReportTypeOrCustomReportType>> = this.reportsPageType$
    .pipe(switchMap((reportsPageType) =>
      reportsPageType ? this.reportService.reportTypesByTypeCodes(this.reportService.getReportTypeCodesByReportsPageType(reportsPageType)) : of([])));
  private readonly reportTypeSubject = new BehaviorSubject<ReportTypeOrCustomReportType|undefined>(undefined);
  public readonly reportType$ = this.reportTypeSubject.asObservable();
  data = this.reportType$.pipe(switchMap((reportType) => !reportType ? of(new Array<Report>()) :
    (reportType.customReportTypeId ? this.reportService.getReportsByCustomType(reportType.customReportTypeId) : this.reportService.getReportsByType(reportType.reportTypeId) )));

  dataGroupedByWeek$: Observable<ReportWeekGroup[]> = this.data.pipe(
    groupByMap<ReportWeekGroup>(groupDiscriminatorWeek),
    withCurrentPeriodGroup(groupDiscriminatorWeek),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  dataGroupedByMonth$: Observable<ReportMonthGroup[]> = this.data.pipe(
    groupByMap<ReportMonthGroup>(groupDiscriminatorMonth),
    withCurrentPeriodGroup(groupDiscriminatorMonth),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  dataGroupedByYear$: Observable<ReportYearGroup[]> = this.data.pipe(
    groupByMap<ReportYearGroup>(groupDiscriminatorYear),
    withCurrentPeriodGroup(groupDiscriminatorYear),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  private filterTypeSubject = new ReplaySubject<ReportFilterType>(1);
  filterType$ = this.filterTypeSubject.asObservable();

  private currentGroupSubject = new BehaviorSubject<ReportGroup>(null);
  currentGroup$ = this.currentGroupSubject.asObservable().pipe(
    filter((group) => Boolean(group)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  reportsGrouped$ = this.filterType$.pipe(
    switchMap((reportFilterType) => this.getGroupByFilterType(reportFilterType))
  );

  reportGroup$ = this.reportsGrouped$.pipe(
    switchMap((groups) => this.currentGroupSubject.pipe(
      map((group) => group ?? groups[0])
    )),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  groupsOrCurrentGroupSubscription: Subscription;
  private reportTypesSubscription: Subscription;

  constructor(
    private storage: StorageService,
    private authenticationService: AuthenticationService,
    private reportService: ReportService
  ) {
    this.authenticationService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      if (isAuthenticated) {
        await this.restoreFromStorage();
      } else {
        await this.removeStorage();
      }
    });
    this.groupsOrCurrentGroupSubscription = combineLatest([this.currentGroupSubject, this.reportsGrouped$]).pipe(
      debounceTime(0),
    ).subscribe(([group, groups]) => {
      const existingGroup = groups.find((theGroup) => groupCompare(theGroup, group));
      // Preselect first group if there is no currently selected group or
      // if the groups have changed, so the previous group is no longer available
      if (group === null || !existingGroup) {
        this.setGroup(groups[0]);
      }
      // Reemit group if the reference to the array has changed
      if (existingGroup && existingGroup.reports !== group.reports) {
        this.setGroup(existingGroup);
      }
    });

    this.reportTypesSubscription = this.reportTypes$.subscribe((reportTypes) => {
      if (!reportTypes.length) {
        this.reportType = undefined;
        return;
      }
      if (!this.reportType || !reportTypes.some((value) => value.id === this.reportType.id)) {
        this.reportType = reportTypes[0];
      }
    });
  }

  private async restoreFromStorage() {
    const filterState: ReportFilterState = await this.storage.get(STORAGE_KEY);

    this.filterTypeSubject.next(filterState?.type || 'WEEK');
  }

  private async removeStorage() {
    await this.storage.remove(STORAGE_KEY);
  }

  private async updateStorage(state: ReportFilterState) {
    await this.storage.set(STORAGE_KEY, state, {
      ensureStored: false,
      immediate: false,
    });
  }

  private getGroupByFilterType(reportFilterType: ReportFilterType) {
    switch (reportFilterType) {
      case 'WEEK':
        return this.dataGroupedByWeek$;
      case 'MONTH':
        return this.dataGroupedByMonth$;
      case 'YEAR':
        return this.dataGroupedByYear$;
    }
  }

  async changeFilterType(filterType: ReportFilterType) {
    const group = (await observableToPromise(this.getGroupByFilterType(filterType)))[0];

    this.setGroup(group);
    this.filterTypeSubject.next(filterType);

    await this.updateStorage({
      type: filterType,
    });
  }

  setGroup<T extends ReportGroup>(group: T) {
    this.currentGroupSubject.next(group);
  }

  set reportsPageType(reportsPageType: ReportsPageTypeEnum|undefined) {
    this.reportsPageTypeSubject.next(reportsPageType);
  }

  get reportsPageType(): ReportsPageTypeEnum|undefined {
    return this.reportsPageTypeSubject.value;
  }

  set reportType(reportsPageType: ReportTypeOrCustomReportType|undefined) {
    this.reportTypeSubject.next(reportsPageType);
  }

  get reportType(): ReportTypeOrCustomReportType|undefined {
    return this.reportTypeSubject.value;
  }

  ngOnDestroy() {
    this.groupsOrCurrentGroupSubscription?.unsubscribe();
    this.reportTypesSubscription?.unsubscribe();
  }
}
