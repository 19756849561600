<ion-row *ngIf="employees.length > 0 || (showEmptyResult && company.allEmployees.length === 0 && !isSearchMode)">
  <ion-col size="12" class="ion-no-padding">
    <ion-row class="company-row gap-2">{{company | companyNameString}}<fa-icon *ngIf="showCompanyPin && isCompanyPinned()" class="text-secondary" [icon]="['fal', 'thumbtack']"></fa-icon></ion-row>
    <ion-row *ngIf="company.crafts.length" class="craft-row text-secondary text-wrap-one-line">{{getCraftNames(company)}}</ion-row>
  </ion-col>
  <ion-col *ngIf="showEmptyResult && !company.allEmployees.length" size="12" class="danger size-sm test">
    <strong>{{'sendProtocol.mailingList.noPersonAssigned' | translate}}</strong>
  </ion-col>
</ion-row>

<ion-row *ngFor="let employee of employees" class="employee-row">
  <ion-col size="10">
    <ion-row>
      <ion-col size="12" size-md="6" class="d-flex ion-justify-content-between">
        <ion-col size="9">
          <span class="employee-text text-wrap-one-line">{{employee | userNameString : employee.profile.isActive}}</span>
        </ion-col>
        <ion-col size="3" class="d-flex ion-justify-content-end">
          <div class="employee-icons">
            <fa-icon [icon]="['fal','phone']" class="padding-left-10"
                    [class.icon-active]="employee.profile.dsgvoShowTel"></fa-icon>
            <fa-icon [icon]="['fal','envelope']" class="padding-left-10"
                    [class.icon-active]="employee.profile.dsgvoShowEmail" [class.danger]="!employee.email"></fa-icon>
          </div>
        </ion-col>
      </ion-col>
      <ion-col size="12" size-md="6" class="size-sm">
        <span *ngIf="employee.email" class="text-secondary">{{employee.email}}</span>
        <span *ngIf="!employee.email" class="danger"><strong>{{'sendProtocol.mailingList.emailMissing' | translate}}</strong></span>
      </ion-col>
    </ion-row>
  </ion-col>
  <ion-col size="2" class="ion-no-padding d-flex ion-justify-content-end">
      <div class="checkbox-container recipient" [ngClass]="{'cursor-pointer': !readOnly}" (click)="!readOnly && updateRecipient(employee)" [class.disabled]="!employee.email" *ngIf="showPresent">
       <ion-checkbox class="omg-checkbox cursor-pointer ion-no-padding ion-no-margin" [checked]="employee.participant?.mailingList" [disabled]="readOnly || !employee.email"> </ion-checkbox>
      </div>
      <div class="checkbox-container present" [ngClass]="{'cursor-pointer': !readOnly}" (click)="!readOnly && updatePresent(employee)" *ngIf="showMailingList">
       <ion-checkbox [disabled]="readOnly" class="omg-checkbox cursor-pointer ion-no-padding ion-no-margin" [checked]="employee.participant?.present"> </ion-checkbox>
      </div>
      <ng-container *ngIf="showSeen && employee.participant?.mailingList">
        <div [attr.id]="'eye-icon' + employee.id" class="checkbox-container present">
          <fa-icon [icon]="['fal', employee.participant?.seenAt ? 'eye' : 'eye-slash']"></fa-icon>
        </div>
        <app-tooltip *ngIf="employee.participant?.seenAt; else notseen" [target]="'eye-icon' + employee.id" [placement]="'left'">
          {{('sendProtocol.mailingList.seenAt' | translate) + (employee.participant?.seenAt | date: ('dd.MM.yyyy, HH:mm'))}}
        </app-tooltip>
        <ng-template #notseen>
          <app-tooltip [target]="'eye-icon' + employee.id" [placement]="'left'">
            {{'sendProtocol.mailingList.notSeen' | translate}}
          </app-tooltip>
        </ng-template>
      </ng-container>
  </ion-col>
</ion-row>