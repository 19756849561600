import {Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {IdAware, IdType} from 'submodules/baumaster-v2-common';
import {Subject, Subscription} from 'rxjs';
import {IonicSelectableComponent} from 'ionic-selectable';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {LoggingService} from '../../../services/common/logging.service';
import {AlertService} from '../../../services/ui/alert.service';
import lodash from 'lodash';
import {Nullish} from '../../../model/nullish';
import {TranslateService} from '@ngx-translate/core';
import {RecentlyUsedKeyType, SelectableMetaInformation, SelectableRecentlyUsedItems} from '../../../model/selectable';
import {RecentlyUsedSelectableService} from '../../../services/common/recently-used-selectable.service';
import {takeUntil} from 'rxjs/operators';
import {ToastService} from '../../../services/common/toast.service';
import {ToastDurationInMs} from '../../../shared/constants';
import {convertErrorToMessage} from '../../../shared/errors';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';
import {EMPTY_FILTER_ID, PROJECT_TEAM_PSEUDO_ID} from 'src/app/utils/filter-utils';

const LOG_SOURCE = 'SelectableComponent';

export type SelectableChangeType<T> =
  {
    component: IonicSelectableComponent;
    value: Nullish<T>;
    values: Array<T>
  };

@Component({
  selector: 'app-selectable',
  templateUrl: './selectable.component.html',
  styleUrls: ['./selectable.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectableComponent),
    multi: true
  }]
})
export class SelectableComponent<T extends IdAware, TAll extends IdAware, TDisabled extends IdAware> implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  static NEXT_INSTANCE_NUMBER = 0;
  static ACTIVE_INSTANCES = 0;
  readonly instanceNumber = SelectableComponent.NEXT_INSTANCE_NUMBER++;
  @ViewChild('selectable', {static: false}) ionicSelectable: IonicSelectableComponent;

  private readonly destroy$ = new Subject<void>();

  // mandatory input fields
  @Input()
  items: Array<T>;

  // optional input fields with default value
  @Input()
  disabledItems: Array<TDisabled>;

  @Input()
  allItems?: Array<TAll>;

  @Input()
  isEnabled = true;

  @Input()
  canSearch = true;

  /** Will show a "clear" button to clear the selected value */
  @Input()
  allowEmptyValue = true;

  /** Will show a create button to create new entries. */
  @Input()
  allowCreateNew = false;

  /** Will not call assignToProjectFunction when items are selected */
  @Input()
  suppressAssignToProject = false;

  /** Will not call markRecentlyUsed when items are selected */
  @Input()
  suppressMarkRecentlyUsed = false;

  // optional input fields with default value
  @Input()
  itemValueField?: string;

  @Input()
  itemTextField?: string;

  @Input()
  isMultiple?: boolean;

  @Input()
  shouldStoreItemValue?: boolean;

  @Input()
  modalTitle?: string;

  @Input()
  createNewFunction?: (searchText?: string) => Promise<IdAware|undefined> | IdAware | undefined;

  @Input()
  assignToProjectFunction?: (item: T, assign: boolean) => Promise<boolean>;

  @Input()
  disableDefaultOnSearch = false;

  // outputs
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange = new EventEmitter<SelectableChangeType<T|TAll|TDisabled>>();

  @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onSearch = new EventEmitter<any>();

  @Input()
  itemTemplate?: TemplateRef<{ item: T & SelectableMetaInformation; isItemSelected: boolean}>;

  @Input()
  valueTemplate?: TemplateRef<{ value: T & SelectableMetaInformation}>;

  @Input()
  messageString?: string;

  @Input()
  recentlyUsedKey?: RecentlyUsedKeyType;

  private _canClear = true;

  @Input()
  set canClear(canClear: Nullish<boolean>) {
    this._canClear = canClear ?? true;
  };

  get canClear() { return this._canClear; }

  mergedItems?: Array<T & SelectableMetaInformation | TAll & SelectableMetaInformation | TDisabled & SelectableMetaInformation>;
  mergedItemsAsOriginal?: Array<T|TAll|TDisabled>;

  private itemIdToSelectAndClose: IdType|undefined;

  // elements to implement ControlValueAccessor
  value: T | Array<T>|null;
  private propagateOnChange = (_: any) => { };
  private propagateOnTouched = () => { };
  private recentlyUsedItems: SelectableRecentlyUsedItems|undefined;
  private recentlyUsedSubscription: Subscription|undefined;
  private recentlyUsedAllSubscription: Subscription|undefined;
  private keyChangedCurrentProjectSubscription: Subscription|undefined;
  private keyChangedSubscription: Subscription|undefined;
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  constructor(private loggingService: LoggingService, private alertService: AlertService, private translateService: TranslateService,
              private recentlyUsedSelectableService: RecentlyUsedSelectableService, private toastService: ToastService,
              private selectableUtilService: SelectableUtilService) { }

  ngOnInit() {
    SelectableComponent.ACTIVE_INSTANCES++;
    this.loggingService.debug(LOG_SOURCE, `ngOnInit called - instanceNumber=${this.instanceNumber}, ACTIVE_INSTANCES=${SelectableComponent.ACTIVE_INSTANCES}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.allowCreateNew && !this.createNewFunction) {
      this.loggingService.warn(LOG_SOURCE, 'allowCreateNew=true but no createNewFunction was provided.');
    }
    const recentlyUsedKeyProvided = changes.recentlyUsedKey && changes.recentlyUsedKey.currentValue;
    if (recentlyUsedKeyProvided) {
      this.initRecentlyUsed();
    }
    if (changes.items || changes.disabledItems || changes.allItems) {
      if (!recentlyUsedKeyProvided) { // initRecentlyUsed already calls initMergedItems
        this.initMergedItems();
      }
      if (this.items) {
        this.itemsUpdated();
      }
    }
  }

  ngOnDestroy(): void {
    SelectableComponent.ACTIVE_INSTANCES--;
    this.loggingService.debug(LOG_SOURCE, `ngOnDestroy called - instanceNumber=${this.instanceNumber}, ACTIVE_INSTANCES=${SelectableComponent.ACTIVE_INSTANCES}`);
    this.destroy$.next();
    this.destroy$.complete();
    this.keyChangedCurrentProjectSubscription?.unsubscribe();
    this.keyChangedSubscription?.unsubscribe();
    this.recentlyUsedAllSubscription?.unsubscribe();
  }

  onChangeEvent(event: { component: IonicSelectableComponent, value: Nullish<T|TAll|TDisabled> | Array<T|TAll|TDisabled> }) {
    let value: Nullish<T|TAll|TDisabled>;
    let values: Array<T|TAll|TDisabled>;
    if (lodash.isArray(event.value)) {
      values = event.value as Array<T>;
      value = lodash.head(values);
    } else {
      value = event.value as Nullish<T>;
      values = value ? [value] : [];
    }
    if (value && values && (typeof value !== 'string')) {
      // multiselect my have values of string (IdType)
      value = this.getOriginalItem(value);
      values = this.getOriginalItems(values);
    }

    this.onChange.emit({component: event.component, value, values});
    if (this.isMultiple) {
      this.markRecentlyUsed(values.map((v) => typeof v === 'string' ? v : v.id));
    }
  }

  private initRecentlyUsed() {
    if (!this.recentlyUsedKey) {
      return;
    }
    this.loggingService.debug(LOG_SOURCE, `initRecentlyUsed called (${this.recentlyUsedKey})`);
    this.recentlyUsedSubscription?.unsubscribe();
    this.recentlyUsedSubscription = this.recentlyUsedSelectableService.getRecentlyUsedItemsOfCurrentProject$(this.recentlyUsedKey).pipe(takeUntil(this.destroy$))
      .subscribe((recentlyUsedItems) => {
      this.loggingService.debug(LOG_SOURCE, `initRecentlyUsed - subscribe called (${this.recentlyUsedKey})`);
      this.recentlyUsedItems = recentlyUsedItems;
      this.initMergedItems();
    });
  }

  private async markRecentlyUsed(idOrIds: IdType|Array<IdType>) {
    if (!this.recentlyUsedKey || this.suppressMarkRecentlyUsed) {
      return;
    }
    this.recentlyUsedItems = await this.recentlyUsedSelectableService.markRecentlyUsedForCurrentProject(this.recentlyUsedKey, idOrIds);
    this.loggingService.debug(LOG_SOURCE, `markRecentlyUsed - recentlyUsedKey="${this.recentlyUsedKey}", recentlyUsedItems=${this.recentlyUsedItems?.length}`);
  }

  async createNew(searchText: string) {
    this.loggingService.debug(LOG_SOURCE, `createNew clicked - searchText = "${searchText}"`);
    if (!this.createNewFunction) {
      throw new Error('createNewClicked but no createNewFunction provided.');
    }
    const newObject = await this.createNewFunction(searchText);
    if (newObject) {
      const item = this.items?.find((v) => v.id === newObject.id);
      if (item) {
        await this.selectItemAndClose(item);
      } else {
        this.itemIdToSelectAndClose = newObject.id;
      }
    }
  }

  async onSelectItem(event: { component: IonicSelectableComponent,
    item: T & SelectableMetaInformation | TAll & SelectableMetaInformation | TDisabled & SelectableMetaInformation, isSelected: boolean}): Promise<void> {
    if (this.isMultiple) {
      return;
    }
    this.itemIdToSelectAndClose = undefined;
    if (event.item.notAssignedToProject && event.isSelected && !this.suppressAssignToProject) {
      if (!this.assignToProjectFunction) {
        const errorMessage = `Item ${event.item.id} selected from all items but it is not in items and function assignToProjectFunction was not provided.`;
        this.loggingService.error(LOG_SOURCE, errorMessage);
        throw new Error(errorMessage);
      }
      await this.assignToProjectFunction(this.getOriginalItem(event.item) as T, true);
      this.toastService.infoWithMessageAndButtons(this.translateService.instant('assignedToProjectMessage', {fieldName: this.messageString}),
        [{
          side: 'end',
          text: this.translateService.instant('undo'),
          handler: async () => {
            await this.assignToProjectFunction(this.getOriginalItem(event.item) as T, false);
          }
        }], undefined, {duration: ToastDurationInMs.ERROR});
    }
    // isSelected can be false, if the user selects the same item that is already selected.
    // In that scenario, we can confirm modal only if canClear is set to true.
    if (event.isSelected || this.canClear) {
      event.component.confirm();
      await this.markRecentlyUsed(event.item.id);
    }
    await this.closeSelectableAndIgnoreErrors(event.component);
  }

  private async selectItemAndClose(item: T) {
    this.ionicSelectable._select(item);
    this.ionicSelectable.confirm();
    await this.markRecentlyUsed(item.id);
    await this.closeSelectableAndIgnoreErrors();
  }

  private async closeSelectableAndIgnoreErrors(ionicSelectable?: IonicSelectableComponent) {
    try {
      await (ionicSelectable ?? this.ionicSelectable).close();
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Ignoring error in closeSelectableAndIgnoreErrors. ${convertErrorToMessage(error)}`);
    }
  }

  private initMergedItems() {
    if (!this.items) {
      this.mergedItems = undefined;
      this.mergedItemsAsOriginal = undefined;
      return;
    }
    const applySelectableMetaInformation =
      (item: T|TAll|TDisabled, metaInfo: SelectableMetaInformation): T & SelectableMetaInformation|TAll & SelectableMetaInformation|TDisabled & SelectableMetaInformation => {
      return {...item, ...metaInfo};
    };
    const disabledItemsIds = new Set<IdType>(this.disabledItems?.map((di) => di.id) ?? []);
    const isDisabled = (item: T|TAll|TDisabled): boolean => {
      return disabledItemsIds.has(item.id);
    };
    const applySelectableMetaInformationEmpty = (item: T|TAll|TDisabled) => {return applySelectableMetaInformation(item,
      {recentlyUsed: false, notAssignedToProject: false, disabled: isDisabled(item), groupKey: EMPTY_FILTER_ID + PROJECT_TEAM_PSEUDO_ID, groupText: ''});};
    const applySelectableMetaInformationRecentlyUsed = (item: T|TAll|TDisabled) => {return applySelectableMetaInformation(item,
      {recentlyUsed: true, notAssignedToProject: false, disabled: isDisabled(item), groupKey: 'recentlyUsed', groupText: this.translateService.instant('recently_used')});};
    const applySelectableMetaInformationActive = (item: T|TAll|TDisabled) => {return applySelectableMetaInformation(item,
      {recentlyUsed: false, notAssignedToProject: false, disabled: isDisabled(item), groupKey: 'active', groupText: this.translateService.instant('selectable.active')});};
    const applySelectableMetaInformationInactive = (item: T|TAll|TDisabled) => {return applySelectableMetaInformation(item,
      {recentlyUsed: false, notAssignedToProject: true, disabled: isDisabled(item), groupKey: 'inactive', groupText: this.translateService.instant('selectable.inactive')});};
    
    const itemsActiveWithoutEmptyAndProjectTeam = this.items.filter(item => item.id !== EMPTY_FILTER_ID && item.id !== PROJECT_TEAM_PSEUDO_ID);
    const itemsRecentlyUsed = !this.recentlyUsedItems ? [] : this.items.filter((item) => this.recentlyUsedItems.some((recentlyUsedItem) => recentlyUsedItem.id === item.id));
    const itemsActive = !this.recentlyUsedItems ? itemsActiveWithoutEmptyAndProjectTeam : itemsActiveWithoutEmptyAndProjectTeam.filter((i) => !this.recentlyUsedItems.some((recentlyUsedItem) => recentlyUsedItem.id === i.id));
    const itemsInactive = this.allItems ? this.allItems.filter((a) => !this.items.some((i) => i.id === a.id)) : [];
    const itemsEmptyAndProjectTeam = this.allItems ? this.allItems.filter((a) => a.id === EMPTY_FILTER_ID || a.id === PROJECT_TEAM_PSEUDO_ID) : [];
    this.mergedItems = [...itemsEmptyAndProjectTeam.map(applySelectableMetaInformationEmpty),
      ...itemsRecentlyUsed.map(applySelectableMetaInformationRecentlyUsed),
      ...itemsActive.map(applySelectableMetaInformationActive),
      ...itemsInactive.map(applySelectableMetaInformationInactive)];
    this.mergedItemsAsOriginal = [...itemsEmptyAndProjectTeam, ...itemsRecentlyUsed, ...itemsActive, ...itemsInactive];
  }

  private async itemsUpdated() {
    if (this.itemIdToSelectAndClose) {
      const itemToSelectAndClose = this.items.find((item) => item.id === this.itemIdToSelectAndClose);
      if (itemToSelectAndClose) {
        this.itemIdToSelectAndClose = undefined;
        await this.selectItemAndClose(itemToSelectAndClose);
      }
    }
  }

  valueChangeHandle(value: any) {
    this.value = value;
    this.propagateOnChange(this.value && lodash.isArray(this.value) ? this.getOriginalItems(this.value) : this.getOriginalItem(this.value as T));
  }

  writeValue(value: any): void {
    this.value = value;
  }

  valueChanged() {
    this.propagateOnChange(this.value && lodash.isArray(this.value) ? this.getOriginalItems(this.value) : this.getOriginalItem(this.value as T));
    this.propagateOnTouched();
  }

  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  /**
   * Returns the selected item(s) without meta information.
   *
   * @param item
   * @private
   */
  private getOriginalItem(item: T|TAll|TDisabled|null): T|TAll|TDisabled|null {
    if (!item) {
      return null;
    }
    const itemFound = this.mergedItemsAsOriginal.find((mergedItem) => mergedItem === item ||
      (this.itemValueField && mergedItem[this.itemValueField] === item[this.itemValueField] || mergedItem[this.itemValueField] === item) ||
      (mergedItem.id === item.id || (typeof item === 'string' && mergedItem.id === item)));
    if (!itemFound) {
      throw new Error(`getOriginalItem - Unable to find item with id ${item?.id}`);
    }
    return itemFound;
  }

  private getOriginalItems(items: Array<T|TAll|TDisabled>|null, convertIdsToItems = false): Array<T|TAll|TDisabled>|null {
    if (!items) {
      return null;
    }
    if (!items.length) {
      return [];
    }
    const itemIds = items.map((item) => typeof item === 'string' ? item as IdType : item.id);
    if (typeof items[0] === 'string' && !convertIdsToItems) {
      return items; // items are of type IdType. No conversion or anything needed
    }
    const itemsFound = this.mergedItemsAsOriginal.filter((mergedItem) => itemIds.includes(mergedItem.id));
    if (itemsFound.length !== items.length) {
      throw new Error(`getOriginalItems - Unable to find all items. Found ${itemsFound.length} out of ${items.length}`);
    }
    return itemsFound;
  }

  async onClose($event: { component: IonicSelectableComponent }) {
    this.loggingService.debug(LOG_SOURCE, `onClose called`);
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
    if (this.isMultiple && $event?.component?._selectedItems?.length) {
      const selectedItemIds: Array<IdType> = $event.component._selectedItems.map((selectedItem) => typeof selectedItem === 'string' ? selectedItem as IdType : selectedItem.id);
      const selectedItems = this.mergedItems.filter((mergedItem) => selectedItemIds.includes(mergedItem.id));
      const selectedItemsNotAssignedToProject = selectedItems.filter((item) => item.notAssignedToProject);
      if (selectedItemsNotAssignedToProject.length && !this.suppressAssignToProject) {
        if (!this.assignToProjectFunction) {
          const errorMessage = `${selectedItemsNotAssignedToProject.length} items selected but not assigned to the project but function assignToProjectFunction was not provided.`;
          this.loggingService.error(LOG_SOURCE, errorMessage);
          throw new Error(errorMessage);
        }

        for (const selectedItemsNotAssignedToProjectElement of selectedItemsNotAssignedToProject) {
          await this.assignToProjectFunction(this.getOriginalItem(selectedItemsNotAssignedToProjectElement) as T, true);
        }

        this.toastService.infoWithMessageAndButtons(this.translateService.instant('assignedToProjectMessage', {fieldName: this.messageString}),
          [{
            side: 'end',
            text: this.translateService.instant('undo'),
            handler: async () => {
              for (const selectedItemsNotAssignedToProjectElement of selectedItemsNotAssignedToProject) {
                await this.assignToProjectFunction(this.getOriginalItem(selectedItemsNotAssignedToProjectElement) as T, false);
              }
            }
          }], undefined, {duration: ToastDurationInMs.ERROR});
      }
    }
  }

  getGroupText = (item: T & SelectableMetaInformation | TAll & SelectableMetaInformation | TDisabled & SelectableMetaInformation, index: number,
                  items: Array<T & SelectableMetaInformation | TAll & SelectableMetaInformation | TDisabled & SelectableMetaInformation>): string|null => {
    if (item.groupKey === (EMPTY_FILTER_ID + PROJECT_TEAM_PSEUDO_ID)) {
      return null;
    }
    if (index === 0 || item.groupKey !== items[index - 1]?.groupKey) {
      return item.groupText;
    }

    return null;
  };

  async onOpen($event: { component: IonicSelectableComponent }) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  onSearchEvent(event: any) {
    this.onSearch.emit(event);
  }
}
