<app-entry-filter-form
  *rxLet="filterData$; let filterData"
  [ngModel]="filters$ | async"
  (ngModelChange)="setFilters($event)"
  [showRecentlyUsedInCurrentProject]="filterData.showRecentlyUsedInCurrentProject"
  [nameableDropdownName]="filterData.nameableDropdownName"
  [companies]="filterData.companies"
  [crafts]="filterData.crafts"
  [profiles]="filterData.profiles"
  [entryTypes]="filterData.entryTypes"
  [customFields]="filterData.customFields"
  [locations]="filterData.locations"
  [statuses]="filterData.statuses"
  [priorities]="filterData.priorities"
></app-entry-filter-form>
