import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoggingService} from '../../../services/common/logging.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {SyncService} from '../../../services/sync/sync.service';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {combineLatestAsync, observableToPromise} from '../../../utils/async-utils';
import {Attachment, Project, ProjectStatusEnum} from 'submodules/baumaster-v2-common';
import {convertErrorToMessage} from '../../../shared/errors';
import {SyncStatusService} from '../../../services/sync/sync-status.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ProjectService} from '../../../services/project/project.service';

const LOG_SOURCE = 'SyncMissingAttachmentsComponent';

@Component({
  selector: 'app-sync-missing-attachments',
  templateUrl: './sync-missing-attachments.component.html',
  styleUrls: ['./sync-missing-attachments.component.scss'],
})
export class SyncMissingAttachmentsComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  public readyToSync = false;
  public loading = false;
  public syncing = false;
  public attachmentsMissing: Array<Attachment>|undefined;
  public errorMessage: string|undefined;
  private destroy$ = new Subject<void>();
  public anySyncInProgress: boolean|undefined;
  public currentProject: Project|undefined;
  public onlyCurrentProject = true;

  constructor(private projectDataService: ProjectDataService,
              private syncService: SyncService, private syncStatusService: SyncStatusService,
              private loggingService: LoggingService, private systemEventService: SystemEventService,
              private projectService: ProjectService) { }

  ngOnInit() {
    combineLatestAsync([this.syncStatusService.dataSyncInProgressObservable, this.syncStatusService.attachmentSyncInProgressObservable,
      this.syncStatusService.attachmentUploadInProgressObservable, this.projectDataService.currentProjectObservable])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([dataSyncInProgress, attachmentSyncInProgress, attachmentUploadInProgress, currentProject]) => {
        this.currentProject = currentProject;
        const anySyncInProgressBefore = this.anySyncInProgress;
        this.anySyncInProgress = dataSyncInProgress?.inProgress || attachmentSyncInProgress?.inProgress || attachmentUploadInProgress;
        if (!this.anySyncInProgress && anySyncInProgressBefore !== this.anySyncInProgress) {
          this.loadMissing();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async loadMissing() {
    this.loggingService.info(LOG_SOURCE, 'loadMissing called');
    if (this.loading) {
      this.loggingService.warn(LOG_SOURCE, `Already loading`);
      return;
    }
    try {
      this.loading = true;
      this.readyToSync = false;
      this.errorMessage = undefined;
      const projects = await observableToPromise(this.projectDataService.data);
      const activeProjects = projects.filter((project) => !project.status || project.status === ProjectStatusEnum.ACTIVE);
      const selectedProjects = this.onlyCurrentProject && this.currentProject ? activeProjects.filter((project) => project.id === this.currentProject.id) : activeProjects;
      const projectIds = selectedProjects.map((project) => project.id);
      const ensureDownloadResult = await this.projectService.ensureProjectDataOfflineAvailable(projectIds, {modalAllowCancel: true, temporarily: true, modalAllowInBackground: false});
      if (!ensureDownloadResult.success) {
        return;
      }
      this.attachmentsMissing = await this.syncService.attachmentsMissingOnServer(projectIds);
    } catch (error) {
      this.errorMessage = convertErrorToMessage(error);
      this.loggingService.error(LOG_SOURCE, `Error in loadMissing: ${this.errorMessage}`);
      this.systemEventService.logErrorEvent(LOG_SOURCE, this.errorMessage);
    } finally {
      this.loading = false;
      this.readyToSync = !!this.attachmentsMissing?.length;
      this.loggingService.info(LOG_SOURCE, 'loadMissing finished');
    }
  }

  async cancel() {
    await this.modal.dismiss();
  }

  async startSync() {
    this.loggingService.info(LOG_SOURCE, 'startSync called');
    if (this.syncing) {
      this.loggingService.warn(LOG_SOURCE, `Sync already in progress.`);
      return;
    }
    try {
      this.syncing = true;
      await this.syncService.uploadMissingAttachments(this.attachmentsMissing);
    } catch (error) {
      this.errorMessage = convertErrorToMessage(error);
      this.loggingService.error(LOG_SOURCE, `Error in startSync: ${this.errorMessage}`);
      this.systemEventService.logErrorEvent(LOG_SOURCE, this.errorMessage);
    } finally {
      this.syncing = false;
      this.loggingService.info(LOG_SOURCE, 'startSync finished');
    }
    await this.loadMissing();
  }

  public onOnlyCurrentProject(onlyCurrentProject: boolean) {
    this.onlyCurrentProject = onlyCurrentProject;
    this.loadMissing();
  }
}
