import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'ionic-selectable[appSelectableCommon]',
  exportAs: 'appSelectableCommon'
})
export class SelectableCommonDirective implements OnInit, OnDestroy{

  private onCloseSubscription: Subscription;
  isDesktop = this.platform.is('desktop');

  constructor(private component: IonicSelectableComponent, public platform: Platform) {
  }

  ngOnInit(): void {
    this.onCloseSubscription = this.component.onClose.subscribe(event => event.component.searchText = '');
  }

  ngOnDestroy(): void {
    this.onCloseSubscription.unsubscribe();
  }
}
