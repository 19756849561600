<div class="icon">
  <fa-icon [fixedWidth]="true" [style.opacity]="icon ? 1 : 0" [icon]="icon || ['bau', 'status-open']" [ngClass]="iconClasses"></fa-icon>
</div>
<div class="content">
  <div class="content-text">
    <div class="text"><ng-content></ng-content></div>
    <div class="date">{{ date | date:"HH:mm | dd.MM.yyyy" }}</div>
  </div>
  <div class="project" *ngIf="project">{{ project }}</div>
</div>
<div class="status cursor-pointer" (click)="statusClick.emit($event)">
  <fa-icon [icon]="seen ? ['fal', 'eye'] : ['fal', 'eye-slash']"></fa-icon>
  {{ seen ? ('notificationsCenter.card.seen' | translate) : ('notificationsCenter.card.unseen' | translate) }}
</div>
