import {animate, animation, style} from '@angular/animations';

export const revealAnimation = animation([
  style({opacity: 0, visibility: 'visible'}),
  animate('200ms ease', style({opacity: 1}))
]);

export const concealAnimation = animation([
  style({opacity: 1}),
  animate('200ms ease', style({opacity: 0}))
]);
