<app-modal-header>
  <ion-title>{{ 'textTemplate.modal.title' | translate }}</ion-title>
</app-modal-header>

<ng-template #loading>
  <div class="d-flex ion-justify-content-center">
    <ion-spinner></ion-spinner>
  </div>
</ng-template>

<div class="modal-content" *rxLet="data$; let data; suspense: loading">
  <ion-segment class="omg" [(ngModel)]="templateType" (ngModelChange)="handleTemplateTypeChange()" *ngIf="data.hasGlobalTemplates">
    <ion-segment-button value="client">{{'textTemplate.switch.context.client' | translate}}</ion-segment-button>
    <ion-segment-button value="global">{{'textTemplate.switch.context.global' | translate}}</ion-segment-button>
  </ion-segment>

  <ng-container [ngSwitch]="templateType">
    <ng-container *ngSwitchCase="'client'">
      <ion-searchbar *ngIf="data.clientTextTemplates.length > 0 || data.hasSearch"
                     [(ngModel)]="search"
                     class="searchbar omg slim-searchbar"
                     [placeholder]="'search' | translate" debounce="100"></ion-searchbar>

      <app-text-template-list
        *ngIf="data.clientTextTemplates.length > 0"
        (applySelected)="apply()"
        (editSelected)="edit()"
        (selectedTemplateIdChange)="selectedTemplateId = $event"
        [templates]="data.clientTextTemplates"
        [itemIcon]="['fal', 'pencil']"
        (itemIconClick)="edit($event)"
        [templateType]="templateType"
      ></app-text-template-list>
      <div class="no-items">
        <app-no-items *ngIf="data.clientTextTemplates.length === 0 && !data.hasSearch" [label]="'textTemplate.modal.noTemplates' | translate" [ctaLabel]="'textTemplate.modal.button.createTemplate'|translate" (ctaClick)="create()" ctaClass="omg-btn-secondary"></app-no-items>
        <app-no-filtered-items *ngIf="data.clientTextTemplates.length === 0 && data.hasSearch" [text]="'textTemplate.modal.noFilteredTemplates' | translate"></app-no-filtered-items>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'global'">
      <ion-searchbar *ngIf="data.textTemplates.length > 0 || data.hasSearch"
                     [(ngModel)]="search"
                     class="searchbar omg slim-searchbar"
                     [placeholder]="'search' | translate" debounce="100"></ion-searchbar>

      <app-text-template-list
        *ngIf="data.textTemplates.length > 0"
        (applySelected)="apply()"
        (editSelected)="edit()"
        (copySelected)="copy()"
        (selectedTemplateIdChange)="selectedTemplateId = $event"
        [templates]="data.textTemplates"
        [itemIcon]="['fal', 'arrow-to-bottom']"
        (itemIconClick)="copy($event)"
        [templateType]="templateType"
      ></app-text-template-list>
      <div class="no-items">
        <app-no-filtered-items *ngIf="data.textTemplates.length === 0 && data.hasSearch" [text]="'textTemplate.modal.noFilteredTemplates' | translate"></app-no-filtered-items>
      </div>
    </ng-container>
  </ng-container>
</div>

<app-modal-footer>
  <ion-buttons slot="end">
    <ng-container [ngSwitch]="templateType">
      <ng-container *ngSwitchCase="'client'">
        <ion-button class="omg-btn-text" (click)="dismiss()">{{'cancel'|translate}}</ion-button>
        <ion-button class="omg-btn-secondary" (click)="create()">
          <fa-icon slot="start" [icon]="['fal', 'plus']"></fa-icon>
          <span>
            <span class="ion-hide-sm-down">{{'textTemplate.modal.button.createTemplate'|translate}}</span>
            <span class="ion-hide-sm-up">{{'new'|translate}}</span>
          </span>
        </ion-button>
      </ng-container>
      <ng-container *ngSwitchCase="'global'">
        <ion-button class="omg-btn-text" (click)="dismiss()">{{'cancel'|translate}}</ion-button>
      </ng-container>
    </ng-container>
    <ion-button class="omg-btn-primary" (click)="apply()" [disabled]="!selectedTemplateId">{{'textTemplate.modal.button.applyTemplate'|translate}}</ion-button>
  </ion-buttons>
</app-modal-footer>
