import {CommonModule} from '@angular/common';
import {HttpClientModule, HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {IonInput, IonicModule, LoadingController, ModalController, Platform} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {AuthenticationService, LoginOptions} from 'src/app/services/auth/authentication.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {ThemeService} from 'src/app/services/ui/theme.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {TwoFactorAuthenticate, TwoFactorDeviceMethod} from 'submodules/baumaster-v2-common';
import {ResetPasswordComponent} from '../../common/reset-password/reset-password.component';
import {GatherTwoFactorModalComponent} from '../../user-security/gather-two-factor-modal/gather-two-factor-modal.component';
import {PageDidEnterLifecycleService} from 'src/app/services/common/page-did-enter-lifecycle.service';
import {take} from 'rxjs/operators';
import {PosthogService} from 'src/app/services/posthog/posthog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule
  ],
})
export class LoginComponent implements OnInit {

  @ViewChild('usernameInput', {static: false}) usernameInput: IonInput;
  @ViewChild('passwordInput', {static: false}) passwordInput: IonInput;
  loginInProgress = false;
  loginNotSuccessful = false;
  loginNotSuccessfulMsg = '';
  isUnMask = false;

  @Input()
  forcedUsername: string|undefined;

  @Input()
  loginOptions: Partial<LoginOptions>|undefined;

  @Input()
  redirectOnLoggedIn = true;

  @Input()
  posthogEventType = 'login';

  @Output()
  loggedIn = new EventEmitter<void>();

  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', [Validators.required]],
    staySignedIn: [true, Validators.required]
  });

  constructor(private loadingController: LoadingController, private translateService: TranslateService,
              private toastService: ToastService, public platform: Platform, private fb: UntypedFormBuilder,
              private authenticationService: AuthenticationService, private router: Router, public themeService: ThemeService, private modalController: ModalController,
              private posthogService: PosthogService,
              @Optional() private pageDidEnterLifecycleService?: PageDidEnterLifecycleService) {
  }

  ngOnInit() {
    this.pageDidEnterLifecycleService?.pageDidEnter$.pipe(take(1)).subscribe(() => {
      this.ionViewDidEnter();
    });
    if (this.forcedUsername) {
      const usernameControl = this.loginForm.get('username');
      usernameControl.setValue(this.forcedUsername);
      usernameControl.disable();
    }
  }

  ionViewDidEnter() {
    this.passwordInput.value = '';
    if (!this.forcedUsername) {
      this.usernameInput.value = '';
      this.usernameInput.setFocus();
    }
  }

  public async login() {
    this.loginInProgress = true;
    this.loginNotSuccessful = false;
    this.loginNotSuccessfulMsg = '';
    const loading = await this.loadingController.create({
      message: this.translateService.instant('LOGIN.login_done')
    });

    const loginOptions = {
      ...this.loginOptions,
      staySignedIn: this.loginForm.getRawValue().staySignedIn
    };

    try {
      await loading.present();
      const auth = await this.authenticationService.login(this.loginForm.getRawValue().username, this.loginForm.getRawValue().password, loginOptions);
      this.posthogService.captureEvent(`[Security][${this.posthogEventType}] password_only`, {});
      this.loggedIn.emit();
      if (this.redirectOnLoggedIn) {
        await this.router.navigate(['/'], {replaceUrl: true});
      }
    } catch (error) {
      await this.handleSubscribeError(error, loginOptions);
    } finally {
      this.loginInProgress = false;
      await loading.dismiss();
      this.usernameInput.setFocus();
    }
  }

  public async resetPassword(event: MouseEvent) {
    event.preventDefault();

    const modal = await this.modalController.create({
      component: ResetPasswordComponent,
      backdropDismiss: true,
      cssClass: 'half-screen-modal',
      componentProps: {
      }
    });
    return await modal.present();
  }

  public async submitForm() {
    if (this.platform.is('desktop') && this.loginForm.valid && !this.loginInProgress) {
      this.login();
    }
  }

  private async open2FAModal(loginOptions: Partial<LoginOptions>, method: TwoFactorDeviceMethod = 'totp') {
    const loginData = this.loginForm.getRawValue();
    const modal = await this.modalController.create({
      component: GatherTwoFactorModalComponent,
      backdropDismiss: true,
      cssClass: 'omg-modal omg omg-boundary',
      componentProps: {
        method,
        action: async (twoFactor: TwoFactorAuthenticate) => {
          await this.authenticationService.login(loginData.username, loginData.password, {
            ...loginOptions,
            twoFactor,
          });
          this.posthogService.captureEvent(`[Security][2FA][${this.posthogEventType}] step2_2fa`, {
            method: twoFactor.method
          });
        }
      }
    });
    await modal.present();

    if ((await modal.onDidDismiss()).role === 'performed') {
      this.loggedIn.emit();
      if (this.redirectOnLoggedIn) {
        await this.router.navigate(['/'], {replaceUrl: true});
      }
    }
  }

  private async handleSubscribeError(error: HttpErrorResponse, loginOptions: Partial<LoginOptions>) {
    if (error.status === 400) {
      const errorCode = error.error.errorCode;
      this.loginNotSuccessful = true;
      switch (errorCode) {
        case 'AUTHENTICATION_TWO_FACTOR_REQUIRED':
          this.loginNotSuccessful = false;
          this.posthogService.captureEvent(`[Security][2FA][${this.posthogEventType}] step1_password`, {});
          this.open2FAModal(loginOptions, error.error.errorData?.preferredMethod);
          break;
        case 'AUTHENTICATION_TIMED_BLOCK':
          this.loginNotSuccessfulMsg = 'LOGIN.authentication_timed_block';
          break;
        case 'AUTHENTICATION_INVALID_USERNAME_PASSWORD':
          this.loginNotSuccessfulMsg = 'LOGIN.wrong_username_password';
          break;
        case 'SUPERUSER_LOGIN_CANCELLED':
          this.loginNotSuccessfulMsg = '';
          this.loginNotSuccessful = false;
          break;
        default:
          this.loginNotSuccessfulMsg = 'LOGIN.unknown_error';
          break;
      }

    } else {
      await this.toastService.errorWithMessageAndHeader('LOGIN.unknown_error', convertErrorToMessage(error));
    }
  }

  toggleUnMask() {
    this.isUnMask = !this.isUnMask;
  }

}
