import { ColumnConfig } from '../ColumnConfig';

const UniqueLocationsInProjectUniq = ['project_id', 'protocolentrylocation_id'];

export const ProjectProtocolLocationColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'project_id', prop: 'projectId', compositeKey: true, uniqueWith: [UniqueLocationsInProjectUniq]},
  {name: 'protocolentrylocation_id', prop: 'protocolentrylocationId', compositeKey: true, uniqueWith: [UniqueLocationsInProjectUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'}
];
