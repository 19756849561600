import { ColumnConfig } from '../ColumnConfig';
import { NameableDropdownItemColumnSet } from './NameableDropdownItemColumnSet';
import { ProtocolEntryColumnSet } from './ProtocolEntryColumnSet';

export const NameableDropdownColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'name'},
  {name: 'client_id', prop: 'clientId', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'projectProtocolNameableDropdownItems', fk: 'nameabledropdownId', mappedBy: NameableDropdownItemColumnSet, transient: true},
  {name: 'protocolEntries', fk: 'nameableDropdownId', mappedBy: ProtocolEntryColumnSet, transient: true},
];
