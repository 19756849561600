import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AlertController, IonInput} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {IdType, ProjectProtocolLocation, ProtocolEntryLocation} from 'submodules/baumaster-v2-common';
import {ProtocolEntryLocationDataService} from 'src/app/services/data/protocol-entry-location-data.service';
import {ProjectProtocolLocationDataService} from 'src/app/services/data/project-protocol-location-data.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {v4 as uuid4} from 'uuid';
import _ from 'lodash';
import {takeUntil} from 'rxjs/operators';
import {SystemEventService} from '../../../services/event/system-event.service';
import {ClientService} from '../../../services/client/client.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {convertErrorToMessage} from 'src/app/shared/errors';

const LOG_SOURCE = 'LocationComponent';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() locationId: IdType | null = null;
  @Input() projectId: IdType | undefined;
  @Input() prePopulateMainFieldWithText: string | undefined;
  @Input() createForSelectable = false;
  @ViewChild('inputName', {static: false}) inputName: IonInput;

  public locationForm: UntypedFormGroup = this.formBuilder.group({
    location: ['', [Validators.required, this.uniqueName()]],
  });
  public addToProject = true;
  public loading = false;

  private destroy$ = new Subject<void>();
  private locations: ProtocolEntryLocation[] | undefined;
  public location: ProtocolEntryLocation | undefined;
  private modal: HTMLIonModalElement;

  notConnected$ = this.featureEnabledService.isFeatureEnabled$(false, true, null);

  constructor(private formBuilder: UntypedFormBuilder,
              private translateService: TranslateService,
              private alertCtrl: AlertController,
              private systemEventService: SystemEventService,
              private loggingService: LoggingService,
              private toastService: ToastService,
              private projectDataService: ProjectDataService,
              private clientService: ClientService,
              private protocolEntryLocationDataService: ProtocolEntryLocationDataService,
              private projectProtocolLocationDataService: ProjectProtocolLocationDataService,
              private featureEnabledService: FeatureEnabledService) {
  }

  async ngOnInit() {
    this.protocolEntryLocationDataService.dataForOwnClient$
      .pipe(takeUntil(this.destroy$))
      .subscribe(locations => {
        this.locations = locations;
      });

    if (this.locationId) {
      this.patchLocation();
    } else if (this.prePopulateMainFieldWithText) {
      this.locationForm.controls.location.setValue(this.prePopulateMainFieldWithText);
      this.locationForm.controls.location.markAsDirty();
    }
    this.setCanDismiss();
  }

  ngAfterViewInit() {
    setTimeout(async () => {
      await this.inputName.setFocus();
    }, 500);
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (role === 'delete') {
      return true;
    }

    if (!this.locationForm?.dirty) {
      return true;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          role: 'dismiss'
        }
      ]
    });

    await alert.present();
    return (await alert.onWillDismiss()).role === 'dismiss';
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  private patchLocation() {
    this.protocolEntryLocationDataService.getByIdForOwnClient(this.locationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(location => {
        this.locationForm.patchValue(location);
        this.location = location;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private uniqueName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formName: string | null = control.value;
      if (_.isEmpty(formName) || this.location?.location === formName) {
        return null;
      }

      const nameExisting = _.filter(this.locations, location => location.location === formName && (location.isActive || location.isActive === undefined));
      if (!_.isEmpty(nameExisting)) {
        return {nameExist: true};
      }

      if (this.locationId){ // edit mode
        const recordDeletedExist = _.filter(this.locations, location => location.location === formName && location.isActive === false);
        if (!_.isEmpty(recordDeletedExist)) {
          return {nameExistAndInactive: true};
        }
      }
      return null;
    };
  }

  async saveLocation() {
    try {
      this.loading = true;
      const rawData = this.locationForm.getRawValue();
      const locationData = {
        location: rawData.location,
      };

      if (!this.locationId) {
        let location: ProtocolEntryLocation = this.locations.find((data) => data.location === locationData.location);
        if (location) {
          await this.undoLogicalDelete(location);
          return;
        }
        const client = await this.clientService.getOwnClientMandatory();
        location = {
          id: uuid4(),
          clientId: client.id,
          changedAt: new Date().toISOString(),
          isActive: true,
          ...locationData
        };

        await this.createLocation(location);
      } else {
        const location: ProtocolEntryLocation = {...this.location, ...locationData};
        await this.updateLocation(location);
      }

    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - save location ', error?.userMessage + '-' + error?.message);
      this.loggingService.error(LOG_SOURCE, `Error save location. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(error));
      throw error;
    } finally {
      this.loading = false;
    }
  }

  private async createLocation(location: ProtocolEntryLocation) {
    await this.protocolEntryLocationDataService.insert(location, location.clientId);
    await this.addProtocolEntryLocationToProject(location);
    this.locationForm.markAsPristine();
    await this.dismissModal('ok', location);
  }

  private async addProtocolEntryLocationToProject(location: ProtocolEntryLocation) {
    if (this.addToProject && await observableToPromise(this.notConnected$)) {
      const projectId = this.projectId ?? (await this.projectDataService.getCurrentProject()).id;
      const projectLocation: ProjectProtocolLocation = {
        id: projectId + location.id,
        projectId,
        protocolentrylocationId: location.id,
        changedAt: new Date().toISOString(),
      };
      await this.projectProtocolLocationDataService.insert(projectLocation, projectId);
    }
  }

  private async updateLocation(location: ProtocolEntryLocation) {
    const alert = await this.alertCtrl.create({
      message: this.translateService.instant('locationForm.editMessage'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            await this.protocolEntryLocationDataService.update(location, location.clientId);
            this.locationForm.markAsPristine();
            await this.dismissModal('ok', location);
          }
        }
      ]
    });
    await alert.present();
  }

  dismissModal(role?: 'ok' | 'cancel', data?: ProtocolEntryLocation) {
    return this.modal.dismiss(data, role);
  }

  toggleAddToProject() {
    this.addToProject = !this.addToProject;
  }

  async deleteLocation(location: ProtocolEntryLocation) {
    const alert = await this.alertCtrl.create({
      message: this.translateService.instant('locationForm.confirmDelete'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            await this.doLogicalDelete(location);
            await this.toastService.info('locationForm.deleteSuccessful');
            await this.modal.dismiss(undefined, 'delete');
          }
        }
      ]
    });
    await alert.present();
  }

  async doLogicalDelete(location: ProtocolEntryLocation) {
    location.isActive = false;
    await this.protocolEntryLocationDataService.update(location, location.clientId);
    const allProjectEntryLocations = await observableToPromise(this.projectProtocolLocationDataService.getByProtocolLocationAcrossProjects(location));
    allProjectEntryLocations.forEach((projectEntryLocation) => this.projectProtocolLocationDataService.delete(projectEntryLocation, projectEntryLocation.projectId));
  }

  async undoLogicalDelete(location: ProtocolEntryLocation) {
    location.isActive = true;
    await this.protocolEntryLocationDataService.update(location, location.clientId);
    await this.addProtocolEntryLocationToProject(location);
    this.locationForm.markAsPristine();
    await this.dismissModal('ok', location);
  }
}
