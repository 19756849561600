<ng-container
  #lt="appLicenseType"
  #featureEnabled="appFeatureEnabled"
  appLicenseType
  [appFeatureEnabled]="false"
  [appFeatureEnabledForConnected]="true"
  [appFeatureEnabledForLicenses]="[lt.LicenseType.VIEWER]">
  <rx-virtual-scroll-viewport [dynamic]="getItemHeight">
    <div *rxVirtualFor="let item of pdfPlanHolders$; trackBy trackById; let index = index" class="shadow-padding">
      <div *ngIf="getHeader(item, index, pdfPlanHolders) && firstInGroup(item, index)" class="virtual-header-stop-flickering">
        <app-pdf-plan-folder-title [icon]="getHeader(item, index, pdfPlanHolders)?.icon" [name]="getHeader(item, index, pdfPlanHolders)?.name"></app-pdf-plan-folder-title>
      </div>
      <app-pdf-plan-holder-list-item
          [viewMode]="viewMode"
          [pdfPlanHolder]="item"
          [selected]="selected?.includes(item.id) || false"
          (holderClick)="holderClick.emit({event: $event, holder: item})"
          (actionsClick)="actionsClick.emit({event: $event, holder: item})"
          (activeChange)="featureEnabled.show && activeChange.emit({active: $event, holder: item})"
          (expandClick)="toggleExpanded(item.id, item)"
          (selectedChange)="selectedChange.emit({selected: $event, holder: item})"
          [nameTemplate]="nameTemplate"
          [indexTemplate]="indexTemplate"
          [dateTemplate]="dateTemplate"
          [scaleTemplate]="scaleTemplate"
          [tagsTemplate]="tagsTemplate"
          [locationTemplate]="locationTemplate"
          [expanded]="isExpanded(item.id)"
          [activeToggleDisabled]="!(featureEnabled.show$ | async)">
        <ng-container *ngIf="isExpanded(item.id)">
          <ng-container *ngFor="let version of item.pdfPlanVersions; trackBy: trackById">
            <app-pdf-plan-holder-list-item-row
                *ngIf="item.latestPdfPlanVersion?.id !== version.id"
                [viewMode]="viewMode"
                [pdfPlanHolder]="item"
                [pdfPlanVersion]="version"
                [nameTemplate]="nameTemplate"
                [indexTemplate]="indexTemplate"
                [dateTemplate]="dateTemplate"
                [scaleTemplate]="scaleTemplate"
                [tagsTemplate]="tagsTemplate"
                [locationTemplate]="locationTemplate"
                [inset]="true"
                [hideActive]="true"
                [hideExpand]="true"
                [hideActions]="false"
                (holderClick)="holderClick.emit({event: $event, holder: item, version: version})"
                (actionsClick)="actionsClick.emit({event: $event, holder: item, version: version})">
            </app-pdf-plan-holder-list-item-row>
          </ng-container>
          <div *ngIf="viewMode !== 'select'" class="add-new-version">
            <ion-button [disabled]="!(featureEnabled.show$ | async)" class="omg-btn-text" (click)="uploadVersionClick.emit({event: $event, holder: item})">
              {{'project_room.pdf_plan_holders.uploadNewPlanVersion' | translate}}
            </ion-button>
          </div>
        </ng-container>
      </app-pdf-plan-holder-list-item>
    </div>
  </rx-virtual-scroll-viewport>
</ng-container>


