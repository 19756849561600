<ion-item class="chat-item">
  <ion-grid>
    <ion-row class="chat-item-heading">
      <ion-col class="no-padding-bottom no-padding-top">
        <div class="bottom d-block">
          <ion-text color="text-secondary size-m">
            <strong>{{ addressById && profileById && userById ? (((addressById[profileById[userById[chat.createdById]?.profileId]?.addressId]) || (addressById[profileByAttachedToUserId.get(chat.createdById)?.addressId])) | userNameString) : ''}}</strong>
          </ion-text>
          <ion-text color="text-secondary size-sm" class="ion-padding-start ion-text-nowrap size-sm">{{chat.changedAt | date: 'dd.MM.yyyy - HH:mm'}}</ion-text>
          <fa-icon [icon]="['fal','paperclip']" class="{{(attachmentObservable|async).length > 0 ? 'primary': 'inactive'}} padding-left-10 app-icon-data ion-hide-sm-down"></fa-icon>
        </div>
      </ion-col>
      <ion-col size="2" class="no-padding-bottom no-padding-top ion-hide-sm-down"
        #lt="appLicenseType"
        appLicenseType
        #featureEnabled="appFeatureEnabled"
        [appFeatureEnabled]="false"
        [appFeatureEnabledForConnected]="true"
        [appFeatureEnabledForLicenses]="[lt.LicenseType.VIEWER]">
        <ion-buttons class="ion-float-end">
          <ion-button (click)="editComment()" *ngIf="allowEditOrDelete && ((featureEnabled.show$ | async) || !protocol.closedAt)" [disabled]="loading" class="omg-btn-text">
            <fa-icon [icon]="['fal', 'pencil']"></fa-icon>
          </ion-button>
          <ion-button (click)="deleteComment()" *ngIf="allowEditOrDelete && ((featureEnabled.show$ | async) || !protocol.closedAt)" [disabled]="loading" class="omg-btn-text">
            <ion-spinner slot="start" *ngIf="loading" class="spinner-center-button"></ion-spinner>
            <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row>
    <ion-row class="ion-hide-sm-up">
      <ion-col size="2" class="no-padding-bottom no-padding-top d-flex ion-align-items-center ion-justify-content-start">
        <fa-icon [icon]="['fal','paperclip']" class="{{(attachmentObservable|async).length > 0 ? 'primary': 'inactive'}} app-icon-data"></fa-icon>
      </ion-col>
      <ion-col size="10" class="no-padding-bottom no-padding-top d-flex ion-align-items-center ion-justify-content-end"
        #lt="appLicenseType"
        appLicenseType
        #featureEnabled="appFeatureEnabled"
        [appFeatureEnabled]="false"
        [appFeatureEnabledForConnected]="true"
        [appFeatureEnabledForLicenses]="[lt.LicenseType.VIEWER]">
        <ion-buttons class="ion-float-end">
          <ion-button (click)="editComment()" *ngIf="allowEditOrDelete && ((featureEnabled.show$ | async) || !protocol.closedAt)" [disabled]="loading" class="omg-btn-text">
            <fa-icon [icon]="['fal', 'pencil']"></fa-icon>
          </ion-button>
          <ion-button (click)="deleteComment()" *ngIf="allowEditOrDelete && ((featureEnabled.show$ | async) || !protocol.closedAt)" [disabled]="loading" class="omg-btn-text">
            <ion-spinner slot="start" *ngIf="loading" class="spinner-center-button"></ion-spinner>
            <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="no-padding-bottom no-padding-top">
        <ion-text class="text-primary pre-wrap">{{chat.message}}</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>
