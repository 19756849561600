<app-modal-header>
  <ion-title>{{'tasks.filters.modal.title' | translate}}</ion-title>
</app-modal-header>

<ion-content>
  <app-entry-filter></app-entry-filter>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-secondary" (click)="reset()">
      {{'reset' | translate}}
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="close()">
      {{'button.apply' | translate}}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
