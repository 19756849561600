<app-modal-header>
  <ion-title>{{ selectedBimVersion | bimVersionName }}</ion-title>
  <div class="info-top ion-text-center text-wrap-one-line omg-attention" *ngIf="createMarkerEnabled && (isFeatureEnabled$ | async) && !selectedMarkerPosition">{{ 'bimPlanMarker.add_marker_tip' | translate }}</div>
  <div class="info-top ion-text-center text-wrap-one-line omg-attention" *ngIf="createMarkerEnabled && (isFeatureEnabled$ | async) && selectedMarkerPosition">{{ 'bimPlanMarker.submit_marker_tip' | translate }}</div>
  <div class="info-top ion-text-center text-wrap-one-line" *ngIf="!createMarkerEnabled && (isFeatureEnabled$ | async)"></div>
</app-modal-header>

<ion-content>
  <div *ngIf="!selectedBimVersion?.autodeskUrn">{{ 'bimPlanMarker.no_files_found' | translate }}</div>
  <div #forgeViewer (click)="clickHandler($event)">
    <app-autodesk-document-loading-state *ngIf="autodeskDocumentLoadingState && autodeskDocumentLoadingState !== 'success'" [autodeskDocumentLoadingState]="autodeskDocumentLoadingState"></app-autodesk-document-loading-state>
  </div>
  <div #markersContainer>
    <div [class.selected]="marker.id === selectedMarker?.id" appBimMarkerInViewer class="marker-container" *ngFor="let marker of markers" [id]="marker.id">
      <button class="marker" (click)="handleMarkerClick(marker)"></button>
      <ng-container *ngTemplateOutlet="bimMarkerFooterTemplate; context: {$implicit: marker, isSelected: marker.id === selectedMarker?.id}"></ng-container>
    </div>
    <div appBimMarkerPreview class="marker-container" *ngIf="createMarkerEnabled">
      <button class="marker"></button>
    </div>
  </div>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="start" class="margin-modal-buttons-left" *ngIf="(isFeatureEnabled$ | async)">
    <ion-button class="omg-btn-secondary" *ngIf="mergedMarkers?.length && !readonly" [disabled]="!viewerReady || isExplodeExtensionActive || !mergedMarkers?.length" (click)="deleteMarkersFn()" >
      <fa-icon [icon]="['fal', 'trash-alt']" slot="start"></fa-icon>
      <span>{{ 'bimPlanMarker.button.delete_marker' | translate }}</span>
    </ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!viewerReady || isExplodeExtensionActive || createMarkerEnabled" *ngIf="!mergedMarkers?.length && !readonly" (click)="setMarkerMode()">
      {{ 'bimPlanMarker.button.add_marker' | translate }}
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end" class="margin-modal-buttons-right">
    <ion-button class="omg-btn-text ion-hide-sm-down" *ngIf="!(isFeatureEnabled$ | async) || readonly" (click)="cancel()">{{'close' | translate }}</ion-button>
    <ion-button class="omg-btn-text ion-hide-sm-down" *ngIf="(isFeatureEnabled$ | async) && !readonly" (click)="cancel()">{{'button.cancel' | translate }}</ion-button>
    <ion-button [disabled]="!viewerReady || loading" *ngIf="(isFeatureEnabled$ | async) && !readonly && hasPendingChanges()" (click)="saveAndClose()" class="omg-btn-primary">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="loading"></ion-spinner>
      <span>{{ 'button.save' | translate }}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
