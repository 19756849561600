<ion-item
  *ngIf="toolbarMode"
  appWatchDimensions
  #parentRect="appWatchDimensions"
  lines="full"
  [class.item-has-focus]="editorFocus.focused"
  [class.item-has-value]="value"
  [class.native-show-toolbar]="isNativeDevice && showToolbar"
  [class.native-hide-toolbar]="isNativeDevice && !showToolbar"
  class="mce-item ion-no-padding"
  (click)="!disabled ? editorComponent?.editor?.focus() : undefined"
>
  <ion-label *ngIf="label" [color]="labelColor" [class.omg-required]="required" position="floating">{{ label }}</ion-label>
  <ion-button id="toggleButton-{{instanceId}}" class="omg-btn-text toggle-button" *ngIf="isNativeDevice && !disabled" (click)="toggleToolbar()">{{'richtextEditor.showToolbar' | translate}}</ion-button>
  <div
    [@animation]="editorFocus.focused ? 'in' : 'out'"
    [style.max-width]="'' + (parentRect.rect$ | async).width + 'px'"
    id="editorToolbarContainer-{{instanceId}}"
    class="textEditorToolbarContainer"
    [hidden]="disabled"
  ></div>
  <editor
    appEditorFocus
    #editorFocus="appEditorFocus"
    [style.width]="'' + ((parentRect.rect$ | async).width - 20) + 'px'"
    [ngModel]="mceValue"
    (ngModelChange)="valueChangeHandle($event)"
    class="textEditor"
    [disabled]="disabled"
    [init]="{
      toolbar_mode: toolbarMode,
      base_uri: '/tinymce',
      suffix: '.min',
      inline: true,
      width: '100%',
      menubar: false,
      statusbar: false,
      language: currentLang,
      placeholder: '',
      skin: isDark ? 'oxide-dark' : undefined,
      content_css: isDark ? 'dark' : undefined,
      plugins: [
        'advlist lists charmap autoresize searchreplace visualblocks fullscreen paste link'
      ],
      autoresize_bottom_margin: 0,
      toolbar: 'undo redo | bold italic underline strikethrough | forecolor backcolor | formatselect | bullist numlist outdent indent | alignleft aligncenter alignright alignjustify| link | removeformat | help',
      fixed_toolbar_container: '#editorToolbarContainer-' + instanceId,
      toolbar_persist: true,
      content_style: tinymceContentStyle,
      paste_preprocess: pastePreProcessorConvertToPlainText,
      contextmenu: '',
      browser_spellcheck: true
    }"
    (onInit)="handleInit()"
    (onBlur)="handleBlur()"
  ></editor>
  <ng-content></ng-content>
</ion-item>