import {IdType} from 'submodules/baumaster-v2-common';
import {EntryCardModel} from '../model/entry-card-model';

export function findPreviousTask(tasks: EntryCardModel[], previouslyCurrentTaskId: IdType, oldTasks: EntryCardModel[]): EntryCardModel|undefined {
  if (!tasks.length) {
    return undefined;
  }
  let index = oldTasks.findIndex((task) => task.id === previouslyCurrentTaskId);
  for (;index >=0; index--) {
    const oldTask = oldTasks[index];
    const task = tasks.find((t) => t.id === oldTask.id);
    if (task) {
      return task;
    }
  }
  if (index === -1) {
    return tasks[0]; // should not happen but previousTaskId is not in previousTasks. Just return the first new one.
  }
}
