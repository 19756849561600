import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntryLayout} from 'src/app/model/entry-card-model';
import {ToastDurationInMs} from 'src/app/shared/constants';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {IdType, LicenseType, ProtocolEntryIconStatus, ProtocolEntryStatus} from 'submodules/baumaster-v2-common';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {FeatureEnabledService} from '../feature/feature-enabled.service';
import {OmgToastService} from '../ui/omg-toast.service';
import {PopoverService} from '../ui/popover.service';
import {UserProfileService} from '../user/user-profile.service';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';

const ENTRY_OPEN_STATUS_POPOVER = 'open' as const;
const ENTRY_COMPANY_DONE_STATUS_POPOVER = 'company-done' as const;
const ENTRY_DONE_STATUS_POPOVER = 'done' as const;
const ENTRY_STATUS_POPOVER_ARRAY = [ENTRY_OPEN_STATUS_POPOVER, ENTRY_COMPANY_DONE_STATUS_POPOVER, ENTRY_DONE_STATUS_POPOVER] as const;
type EntryStatusPopover = typeof ENTRY_STATUS_POPOVER_ARRAY[number];
type EntryInfoContinuousPopover = 'info' | 'info-continuous';

const popoverStatusToEntryStatus = (status: EntryStatusPopover): ProtocolEntryStatus => {
  switch (status) {
    case ENTRY_OPEN_STATUS_POPOVER:
      return ProtocolEntryStatus.OPEN;
    case ENTRY_COMPANY_DONE_STATUS_POPOVER:
      return ProtocolEntryStatus.COMPANY_DONE;
    case ENTRY_DONE_STATUS_POPOVER:
      return ProtocolEntryStatus.DONE;
  }
};

const isResultStatus = (result: string): result is EntryStatusPopover => (ENTRY_STATUS_POPOVER_ARRAY as readonly string[]).includes(result);
const isResultInfoOrContinuous = (result: string): result is EntryInfoContinuousPopover => result === 'info' || result === 'info-continuous';

@Injectable({
  providedIn: 'root'
})
export class EntryStatusPopoverService {
  public isViewerOrLight$ = this.featureEnabledService.isFeatureEnabled$(true, null, [LicenseType.VIEWER, LicenseType.LIGHT]);

  constructor(
    private popoverService: PopoverService,
    private userProfileService: UserProfileService,
    private featureEnabledService: FeatureEnabledService,
    private translateService: TranslateService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private projectDataService: ProjectDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private toastService: OmgToastService
  ) { }

  async promptStatus(event: Event, isProtocolLayoutShort: boolean, {
    currentStatus,
    currentIconStatus,
    assignmentId,
    isOwnClient = true,
  }: {
    currentStatus?: ProtocolEntryStatus;
    currentIconStatus?: ProtocolEntryIconStatus;
    assignmentId?: IdType;
    isOwnClient?: boolean;
  } = {}) {
    if (currentIconStatus !== undefined && currentStatus === undefined) {
      switch (currentIconStatus) {
        case ProtocolEntryIconStatus.DONE:
          currentStatus = ProtocolEntryStatus.DONE;
          break;
        case ProtocolEntryIconStatus.ON_HOLD:
          currentStatus = ProtocolEntryStatus.COMPANY_DONE;
          break;
        case ProtocolEntryIconStatus.OPEN:
          currentStatus = ProtocolEntryStatus.OPEN;
          break;
      }
    }

    const assignedToCurrentProfileType$: Observable<'OWN' | 'CONNECTED' | 'NONE'> = this.userProfileService.currentUserProfilesAcrossClients$.pipe(
      map((profiles) => {
        const profile = profiles?.find((theProfile) => theProfile.id === assignmentId);
        if (!profile) {
          return 'NONE';
        }

        return profile.attachedToUserId ? 'CONNECTED' : 'OWN';
      })
    );

    const connectedAssignment$ = assignedToCurrentProfileType$.pipe(map((type) => type === 'CONNECTED'));
    const ownAssignment$ = assignedToCurrentProfileType$.pipe(map((type) => type === 'OWN'));

    const allowOpenOrCompanyDone$ = combineLatest([
      this.isViewerOrLight$,
      connectedAssignment$,
      ownAssignment$,
    ]).pipe(
      map(([isViewer, connectedAssignment, ownAssignment]) => {
        if (isOwnClient) {
          // Protocol is a part of my own client
          if (isViewer) {
            // I'm a viewer, so I can only change status to open or company done when:
            //  1. I'm assigned to this entry AND current entry status IS NOT done, or
            //  2. It is a short layout
            return (ownAssignment && currentStatus !== ProtocolEntryStatus.DONE) || isProtocolLayoutShort;
          }
          // I'm not a viewer, so I can change the status however I want
          return true;
        }
        if (connectedAssignment) {
          // Protocol is a part of connected client AND I'm assigned to the entry
          // I can only change status to open or company done when:
          //  1. Current status IS NOT done, or
          //  2. It is a short layout
          return currentStatus !== ProtocolEntryStatus.DONE || isProtocolLayoutShort;
        }
        // Protocol is a part of a connected client AND I am NOT assigned to the entry
        return false;
      })
    );
    const allowDone$ = this.isViewerOrLight$.pipe(
      map((isViewer) => {
        // I can set status to done if:
        //   1. Protocol layout is short, OR
        //   2. I am not viewer AND an entry is a part of my own client
        return isProtocolLayoutShort || (!isViewer && isOwnClient);
      })
    );

    const [allowOpenOrCompanyDone, allowDone] = await observableToPromise(combineLatestAsync([allowOpenOrCompanyDone$, allowDone$]));

    const result = await this.popoverService.openActions<EntryStatusPopover>(event, [
      {
        role: ENTRY_OPEN_STATUS_POPOVER,
        label: this.translateService.instant('protocolEntry.status.open'),
        icon: ['bau', 'status-open'],
        disabled: !allowOpenOrCompanyDone,
        iconClass: 'omg-t-header-1 error',
      },
      ...(isProtocolLayoutShort ? [] : [{
        role: ENTRY_COMPANY_DONE_STATUS_POPOVER,
        label: this.translateService.instant('protocolEntry.status.waiting'),
        icon: ['bau', 'status-on-hold'] as [string, string],
        disabled: !allowOpenOrCompanyDone,
        iconClass: 'omg-t-header-1 warning',
      }]),
      {
        role: ENTRY_DONE_STATUS_POPOVER,
        label: this.translateService.instant('protocolEntry.status.done'),
        icon: ['bau', 'status-done'],
        disabled: !allowDone,
        iconClass: 'omg-t-header-1 success',
      }
    ]);

    return result;
  }

  async promptInfoContinuous(event: Event, {isOwnClient = true}: {isOwnClient?: boolean} = {}) {
    const isViewer = await this.featureEnabledService.isFeatureEnabled(true, null, [LicenseType.VIEWER]);
    const allowChanging = !isViewer && isOwnClient;

    const result = await this.popoverService.openActions<EntryInfoContinuousPopover>(event, [
      {
        role: 'info',
        label: this.translateService.instant('protocolEntry.status.info'),
        icon: ['bau', 'status-info'],
        disabled: !allowChanging,
        iconClass: 'omg-t-header-1 primary',
      },
      {
        role: 'info-continuous',
        label: this.translateService.instant('protocolEntry.status.continuousInfo'),
        icon: ['bau', 'status-continuous-info'],
        disabled: !allowChanging,
        iconClass: 'omg-t-header-1 primary',
      }
    ]);

    return result;
  }

  async changeStatus(event: Event, entryId: IdType, layout: EntryLayout, {
    currentStatus,
    currentIconStatus,
    assignmentId,
    isOwnClient = true
  }: {
    currentStatus?: ProtocolEntryStatus;
    currentIconStatus?: ProtocolEntryIconStatus;
    assignmentId?: IdType;
    isOwnClient?: boolean;
  } = {}) {
    if (layout === 'CONTINUOUS' && currentIconStatus === ProtocolEntryIconStatus.INFO) {
      const continuousResult = await this.promptInfoContinuous(event, {isOwnClient});

      if (isResultInfoOrContinuous(continuousResult)) {
        await this.performChangeContinuousInfo(entryId, continuousResult === 'info-continuous');
      }
      return;
    }

    if (currentIconStatus === ProtocolEntryIconStatus.INFO) {
      return;
    }

    const result = await this.promptStatus(event, layout === 'SHORT', { currentStatus, currentIconStatus, assignmentId, isOwnClient });

    if (isResultStatus(result)) {
      await this.performChangeStatus(entryId, result);
    }
  }

  async performChangeStatus(entryId: IdType, newStatus: EntryStatusPopover) {
    const protocolEntry = await observableToPromise(this.protocolEntryDataService.getById(entryId));
    if (!protocolEntry) {
      throw new Error(`Protocol entry with id ${entryId} not found!`);
    }
    const status = popoverStatusToEntryStatus(newStatus);
    const project = await this.projectDataService.getMandatoryCurrentProject();
    await this.protocolEntryDataService.update({...protocolEntry, status}, project.id);
    if (protocolEntry.title) {
      await this.toastService.toastWithTranslateParams('saving_with_title_success', {title: protocolEntry.title}, ToastDurationInMs.INFO);
    } else {
      await this.toastService.savingSuccess();
    }
  }

  async performChangeContinuousInfo(entryId: IdType, newIsContinuousInfo: boolean) {
    const protocolEntry = await observableToPromise(this.protocolEntryDataService.getById(entryId));
    if (!protocolEntry) {
      throw new Error(`Protocol entry with id ${entryId} not found!`);
    }
    const project = await this.projectDataService.getMandatoryCurrentProject();
    if (!protocolEntry.status) {
      throw new Error('Unable to performChangeContinuousInfo as status is not set.');
    }
    if (protocolEntry.typeId) {
      const protocolEntryType = await observableToPromise(this.protocolEntryTypeDataService.getById(protocolEntry.typeId));
      if (protocolEntryType?.statusFieldActive) {
        throw new Error(`Unable to performChangeContinuousInfo as entry ${entryId} is of actionable type`);
      }
    }
    await this.protocolEntryDataService.update({...protocolEntry, isContinuousInfo: newIsContinuousInfo}, project.id);
    if (protocolEntry.title) {
      await this.toastService.toastWithTranslateParams('saving_with_title_success', {title: protocolEntry.title}, ToastDurationInMs.INFO);
    } else {
      await this.toastService.savingSuccess();
    }
  }
}
