<app-modal-header>
  <ion-title *ngIf="!additionalFieldId">{{ 'nameableDropdownForm.createTitle' | translate }}</ion-title>
  <ion-title *ngIf="additionalFieldId">{{ 'nameableDropdownForm.editTitle' | translate }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="additionalFieldForm" action="#">
    <ion-grid class="grid-gap-4">
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-input label="{{ 'nameableDropdownForm.name' | translate }}" label-placement="floating" #inputName formControlName="name" maxLength="40" type="text" class="omg-required"></ion-input>
          </ion-item>
          <span *ngIf="additionalFieldForm.controls.name.errors?.required && additionalFieldForm.controls.name.dirty" class="error-message">{{ 'formValidation.emptyField' | translate:{field: 'nameableDropdownForm.name' | translate} }}</span>
          <span *ngIf="additionalFieldForm.controls.name.errors?.nameExist && additionalFieldForm.controls.name.dirty" class="error-message">{{ 'nameableDropdownForm.nameExist' | translate }}</span>
          <span *ngIf="additionalFieldForm.controls.name.errors?.nameExistAndInactive && additionalFieldForm.controls.name.dirty" class="error-message">{{ 'nameableDropdownForm.nameExistAndInactive' | translate }}</span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons class="omg-form" slot="start">
    <ion-item class="omg omg-form-checkbox" (click)="toggleAddToProject()" *ngIf="!additionalFieldId && (notConnected$ | async) && !createForSelectable">
     <ion-checkbox label-placement="end" justify="start" [ngModel]="addToProject">
       {{ 'nameableDropdownForm.addToProject' | translate }}
     </ion-checkbox>
    </ion-item>
    <ion-button *ngIf="additionalFieldId" class="omg-btn-secondary omg-btn-color-danger" (click)="deleteNameableDropdown(nameableDropdown)">
      <fa-icon slot="start" [icon]="['fal', 'trash-alt']"></fa-icon>
      <span>{{'delete' | translate}}</span>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismissModal()">{{ 'cancel' | translate }}</ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!additionalFieldForm?.valid || !additionalFieldForm?.dirty || loading" (click)="saveNameableDropdown()" >
      <span *ngIf="!additionalFieldId">{{'button.create' | translate }}</span>
      <span *ngIf="additionalFieldId">{{'button.save' | translate }}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
