/*
  Here we typed in simple models manually. But there are many tools out there
  for generating database models automatically, from an existing database.

  For example, schemats: https://github.com/sweetiq/schemats
*/
import * as DBColumnSets from './DBColumnSets';
import {PdfPrintEntryDetails, PdfProtocolLayout} from './pdf/protocol/pdfProtocolEnums';
import type {SUPPORTED_LANGUAGES} from './constants';

export type IdType = string;

export interface IdAware {
  id: IdType;
}

export enum LicenseType {
  VIEWER = 1,
  LIGHT = 1.5,
  BASIC = 2,
  PROFESSIONAL = 3,
}

export interface UserLicense {
  isStaff: boolean;
  isClientAdmin: boolean;
  role: LicenseType;
  assignedReportRights: boolean;
}

export interface User extends IdAware, ChangedAtAware, UserLicense {
  id: IdType;
  username: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  language: string;
  isSuperuser: boolean;
  isStaff: boolean;
  isClientAdmin: boolean;
  newsletter: boolean;
  isActive: boolean;
  dateJoined: string;
  lastLogin?: string | null;
  role: LicenseType;
  assignedReportRights: boolean;
  myProjectsActive: boolean;
  profileId: IdType;
  changedAt: string;
  termsAndConditionsVersion?: number|null;
  isTechnicalUser: boolean;
}

export interface UserInvite extends IdAware, ChangedAtAware, UserLicense {
  email: string;
  profileId: IdType;
  language: string;
  /**
   * @deprecated System will **always** assume `isStaff` is set to `false`, meaning it is not possible to create user invite for staff.
   */
  isStaff: boolean;
  isClientAdmin: boolean;
  role: LicenseType;
  assignedReportRights: boolean;
  validUntil: Date|string;
  createdById: IdType;
}

export interface UserConnectionInvite extends IdAware, ChangedAtAware {
  id: IdType;
  email: string;
  clientId: IdType;
  profileId: IdType;
  language: string;
  projectId?: IdType|null|undefined;
  validUntil: Date|string;
  createdById: IdType;
}

export interface UserPublic extends IdAware, ChangedAtAware {
  id: IdType;
  profileId: IdType;
  changedAt: string;
}

export enum ProjectStatusEnum {
  ACTIVE = 10,
  ARCHIVED = 20,
  DELETED = 30
}

export enum ProjectCurrencyEnum {
  EUR = 1,
  GBP = 2,
  CHF = 3,
}

export enum ProjectCurrencySymbolEnum {
  EUR = '€',
  GBP = '£',
  CHF = 'CHF',
}

export enum ShowPicturesEnum {
  NONE = 1,
  SMALL = 2,
  MEDIUM = 4,
  LARGE = 3,
}

export interface Project extends IdAware, ChangedAtAware {
  id: IdType;
  number: number|string;
  name: string;
  description?: string | null;
  status?: ProjectStatusEnum | null;
  contractee: string;
  currency: ProjectCurrencyEnum;
  language: string;
  addressSite?: string | null;
  addressContractee?: string | null;
  startAt?: Date | string | null;
  completeAt?: Date | string | null;
  clientId: string;
  changedAt: Date | string;
  createdAt: Date | string;
  projectImage?: string | null;
  isConnected?: boolean;
  taxRate: number;
  addressId?: IdType | null; 
}

export enum AttachmentTypeEnum {
  AttachmentProtocolEntry = 'AttachmentProtocolEntry',
  AttachmentChat = 'AttachmentChat',
  AttachmentProject = 'AttachmentProject',
  AttachmentProjectImage = 'AttachmentProjectImage',
  AttachmentClient = 'AttachmentClient',
  PdfPlanPage = 'PdfPlanPage',
  PdfPlanAttachment = 'PdfPlanAttachment',
  AttachmentReportCompany = 'AttachmentReportCompany',
  AttachmentReportActivity = 'AttachmentReportActivity',
  AttachmentReportEquipment = 'AttachmentReportEquipment',
  AttachmentReportMaterial = 'AttachmentReportMaterial',
  AttachmentReportSignature = 'AttachmentReportSignature',
  AttachmentProtocolSignature = 'AttachmentProtocolSignature',
  AttachmentUserEmailSignature = 'AttachmentUserEmailSignature',
  AttachmentBim = 'AttachmentBim',
  AttachmentBimMarkerScreenshot = 'AttachmentBimMarkerScreenshot',
  AttachmentProjectBanner = 'AttachmentProjectBanner',
}

export enum ClientAttachmentTypeEnum {
  AttachmentClientPdfStartBanner = 'AttachmentClientPdfStartBanner',
  AttachmentClientPdfEndBanner = 'AttachmentClientPdfEndBanner',
  AttachmentClientLogo = 'AttachmentClientLogo'
}

export interface Attachment extends IdAware, ChangedAtAware {
  id: IdType;
  hash: string;
  fileExt: string;
  fileName?: string | null;
  mimeType: string;
  filePath?: string | null;
  thumbnailPath?: string | null;
  bigThumbnailPath?: string | null;
  mediumThumbnailPath?: string | null;
  markings?: any | null;
  changedAt: Date | string;
  createdAt: Date | string;
  createdById?: IdType | null;
  fileChangedAt?: Date | string;
  fileSize?: number|null;
  width?: number|null;
  height?: number|null;
}

export type HistOperationType = 'INSERT' | 'UPDATE' | 'DELETE';

export interface AbstractHist {
  histTimestamp: Date | string;
  histOperationType: HistOperationType;
}

export interface AttachmentProtocolEntry extends Attachment {
  protocolEntryId: IdType;
  projectId: IdType;
}

export interface AttachmentHist extends Attachment, AbstractHist {
}

export interface AttachmentChat extends Attachment {
  chatId: IdType;
  protocolEntryId: IdType;
  projectId: IdType;
}

export interface AttachmentReportCompany extends Attachment {
  reportCompanyId: IdType;
  projectId: IdType;
}

export interface AttachmentProject extends Attachment {
  projectId: IdType;
}

export interface AttachmentReportActivity extends Attachment {
  activityId: IdType;
  projectId: IdType;
}

export interface AttachmentReportEquipment extends Attachment {
  equipmentId: IdType;
  projectId: IdType;
}

export interface AttachmentReportMaterial extends Attachment {
  materialId: IdType;
  projectId: IdType;
}

export interface AttachmentReportSignature extends Attachment {
  reportId: IdType;
  code: string;
  name: string;
  signerName?: string|null;
  forClosed: boolean;
  pdfPreviewId?: IdType | null;
  projectId: IdType;
}

export interface AttachmentProtocolSignature extends Attachment {
  protocolId: IdType;
  profileId?: IdType | null;
  name?: string | null;
  forClosed: boolean;
  pdfPreviewId?: IdType | null;
  projectId: IdType;
}

export interface Country extends IdAware, ChangedAtAware {
  id: IdType;
  changedAt: Date | string;
  name: string;
  taxRate: number;
  noTaxIfUid: boolean;
}

export interface AttachmentProjectImage extends Attachment {
  projectId: IdType;
  relativePath?: string;
}

// If new value is added, do not forget to adjust attachmentDeletion.service.ts in server repo (MEDIA_STRUCTURE)
export type AttachmentProjectBannerType = 'pdf_start_banner' | 'pdf_end_banner';

export interface AttachmentProjectBanner extends Attachment {
  projectId: IdType;
  bannerType: AttachmentProjectBannerType;
}

export interface AttachmentClient extends Attachment {
  clientId: IdType;
}

export interface AttachmentUserEmailSignature extends Attachment {
  userId: IdType;
}

export interface ProjectCompany extends IdAware, ChangedAtAware {
  id: IdType;
  projectId: IdType;
  companyId: IdType;
  changedAt: Date | string;
  sortOrder?: number | null;
}

export interface ProjectProtocolLocation extends IdAware, ChangedAtAware {
  id: IdType;
  projectId: IdType;
  protocolentrylocationId: IdType;
  changedAt: Date | string;
}

export interface ProjectCraft extends IdAware, ChangedAtAware {
  id: IdType;
  projectId: IdType;
  craftId: IdType;
  changedAt: Date | string;
}

export interface ProjectProtocolType extends IdAware, ChangedAtAware {
  id: IdType;
  projectId: IdType;
  protocoltypeId: IdType;
  changedAt: Date | string;
}

export interface ProjectProtocolEntryType extends IdAware, ChangedAtAware {
  id: IdType;
  projectId: IdType;
  protocolentrytypeId: IdType;
  changedAt: Date | string;
}

export interface ProjectProfile extends IdAware, ChangedAtAware {
  id: IdType;
  projectId: IdType;
  profileId: IdType;
  changedAt: Date | string;
}

export interface ProtocolEntryCompany extends IdAware, ChangedAtAware {
  protocolEntryId: IdType;
  companyId: IdType;
}

export interface Company extends IdAware, ChangedAtAware {
  id: IdType;
  name: string;
  sector?: string | null;
  note?: string | null;
  addressId?: IdType | null;
  clientId: IdType;
  changedAt: Date | string;
  isActive: boolean;
}

export interface CompanyCraft extends IdAware, ChangedAtAware {
  id: IdType;
  companyId: IdType;
  craftId: IdType;
  changedAt: Date | string;
}

export interface ProfileCraft extends IdAware, ChangedAtAware {
  id: IdType;
  profileId: IdType;
  craftId: IdType;
  changedAt: Date | string;
}

export interface Craft extends IdAware, ChangedAtAware {
  id: IdType;
  name: string;
  clientId: IdType;
  changedAt: Date | string;
  isActive: boolean;
  color?: string|null;
}

export enum ProtocolSortEntriesByEnum {
  /** Sort by [number] */
  CREATED_AT = 'created_at',
  /** Sort by [created_at, number] */
  CREATED_AT_DATE = 'created_at_date',
  /** Sort by [company.name] */
  COMPANY = 'company',
  /** Sort by [craft.name] */
  CRAFT = 'craft',
  /** Sort by [nameableDropdown.name] */
  NAMEABLE_DROPDOWN = 'nameable_dropdown',
}

export interface Protocol extends IdAware, ChangedAtAware {
  id: IdType;
  number: number;
  name?: string | null;
  date?: Date | string | null;
  timeFrom?: Date | string | null;
  timeUntil?: Date | string | null;
  location?: string | null;
  closedAt: Date | string | null;
  projectId: IdType;
  typeId: IdType;
  sortEntriesBy?: string | null;
  ownerClientId: IdType;
  changedAt: Date | string;
  createdAt: Date | string;
  createdById?: IdType | null;
  includesVat: boolean;
  weather?: number | null;
  minTemp?: number | null;
  maxTemp?: number | null;
  humidity?: number | null;
  windspeed?: number | null;
  weatherFromApi?: boolean | null;
  weatherActivated?: boolean | null;
  unitId?: IdType|null;
  isUnitEntryDefault?: boolean|null;
}

export type ProtocolEntryPriorityType = 1 | 2 | 3 | 4 | null;

export enum ProtocolEntryPriorityLevel {
  HIGH = 1,
  MEDIUM = 2,
  LOW = 3
}

export enum ProtocolEntryStatus {
  OPEN = 10,
  COMPANY_DONE = 20,
  DONE = 30
}

export enum ProtocolEntryIconStatus {
  INFO,
  DONE,
  ON_HOLD,
  OPEN
}

export interface ProtocolEntry extends IdAware, ChangedAtAware {
  id: IdType;
  number: number;
  title?: string | null;
  text?: string | null;
  status: ProtocolEntryStatus | null;
  markForNext?: boolean | null;
  reminderAt?: Date | string | null;
  todoUntil?: Date | string | null;
  cost?: number | null;
  priority?: ProtocolEntryPriorityType;
  companyId?: IdType | null;
  craftId?: IdType | null;
  locationId?: IdType | null;
  parentId?: IdType | null;
  protocolId: IdType;
  /**
   * If set, it indicates in which protocol that entry has been created.
   *
   * Relevant only when the entry is a sub entry, and in the context of continuous protocols.
   * Otherwise, that value must be ignored.
   *
   * If set, and is a sub entry, protocol entry is not a part of protocolId, but rather
   * a part of open (carried over) entry.
   *
   * Entry with that value set is closed only when createdInProtocolId is closed.
   */
  createdInProtocolId?: IdType | null;
  typeId?: IdType | null;
  allCompanies: boolean;
  nameableDropdownId?: IdType | null;
  internalAssignmentId?: IdType | null;
  startDate?: Date | string | null;
  createdAtDb: Date | string;
  changedAt: Date | string;
  createdAt: Date | string;
  createdById?: IdType | null;
  isContinuousInfo?: boolean | null;
  unitId?: IdType | null;
}

export interface ProtocolOpenEntry extends IdAware, ChangedAtAware {
  id: IdType;
  protocolId: IdType;
  protocolEntryId: IdType;
  changedAt: Date | string;
}

export interface NameableDropdown extends IdAware, ChangedAtAware {
  id: IdType;
  name: string;
  clientId: IdType;
  changedAt: Date | string;
  isActive: boolean;
}

export interface NameableDropdownItem extends IdAware, ChangedAtAware{
  id: IdType;
  projectId: IdType;
  nameabledropdownId: IdType;
  changedAt: Date | string;
}

export enum ClientType {
  OWN = 'OWN',
  CONNECTED = 'CONNECTED'
}

export enum ClientStatusEnum {
  UNDEFINED = 0,
  PAYING_USER = 1,
  EXPIRED_TEST_PHASE = 2,
  TEST_PHASE = 3,
  CANCELLED = 4,
  CONNECTED = 5,
  PAYMENT_OVERDUE = 6,
  DELETED = 9
}

export interface Client extends IdAware, ChangedAtAware {
  id: IdType;
  name: string | null;
  logoId?: IdType | null;
  nameableDropdownName: string;
  changedAt: Date | string;
  addressId?: IdType | null;
  createdAt: Date | string;
  invoiceAddressId?: IdType | null;
  invoiceEmail: string;
  paymentMethodAnnual: string;
  taxRate: number;
  typ: string;
  uid: string;
  customerNumber: number;
  countryId?: IdType | null;
  foundThrough?: string | null;
  pdfendbannerId?: IdType | null;
  pdfstartbannerId?: IdType | null;
  companyFieldRequired: boolean;
  licensesMonthlyStandard: number;
  licenseType: number;
  licensesAnnualBusiness: number;
  licensesAnnualPro: number;
  licensesAnnualStandard: number;
  licensesFirstDueDate?: Date | string;
  paymentMethodMonthly: string;
  comment: string;
  resellerId?: number | null;
  discSpace: number;
  closedProtocols: number;
  licensesAnnualLight: number;
  licensesMonthlyLight: number;
  licensesReports: number;
  type?: ClientType;
  status: ClientStatusEnum;
  trialEndDate?: Date | string | null;
  wentToHostedPageAt?: Date | string | null; 
}

export interface ProtocolEntryLocation extends IdAware, ChangedAtAware {
  id: IdType;
  location: string;
  clientId: IdType;
  changedAt: Date | string;
  isActive: boolean;
}

export interface ProtocolEntryType extends IdAware, ChangedAtAware {
  id: IdType;
  name: string;
  statusFieldActive: boolean;
  clientId: IdType;
  changedAt: Date | string;
  isActive: boolean;
  taskDefault?: boolean | null;
}

export interface ProtocolEntryChat extends IdAware, ChangedAtAware {
  id: IdType;
  number: number;
  message: string;
  protocolEntryId: IdType;
  projectId: IdType;
  createdAt?: Date | string | null;
  createdById?: IdType | null;
  changedAt: Date | string;
}

export interface ProtocolType extends IdAware, ChangedAtAware {
  id: IdType;
  name: string;
  code: string;
  clientId: IdType;
  layoutId: IdType;
  changedAt: Date | string;
  isActive: boolean;
}

export interface ProtocolLayout extends IdAware, ChangedAtAware {
  id: IdType;
  name: string;
  changedAt: Date | string;
}

export interface AuthToken {
  key: string;
  created: string;
  userId: IdType;
}

export type ProfileType = 'UNIT_CONTACT';

export interface Profile extends IdAware, ChangedAtAware {
  id: IdType;
  note?: string | null;
  profession?: string | null;
  addressId?: IdType | null;
  clientId: IdType;
  companyId: IdType;
  changedAt: Date | string;
  attachedToUserId?: IdType | null;
  dsgvoShowEmail: boolean;
  dsgvoShowTel: boolean;
  isActive: boolean;
  type?: ProfileType|null;
  externalId?: string|null;
  legalEntity?: string|null;
}

export interface Address extends IdAware, ChangedAtAware {
  id: IdType;
  firstName?: string | null;
  lastName?: string | null;
  city?: string | null;
  country?: string | null;
  email?: string | null;
  phone?: string | null;
  mobile?: string | null;
  messenger?: string | null;
  fax?: string | null;
  salutation?: string | null;
  street1?: string | null;
  street2?: string | null;
  zipCode?: string | null;
  changedAt: Date | string;
  clientId: IdType;
}

export interface PdfPlanPage extends Attachment {
  pageNumber: number;
  pdfPlanVersionId: IdType;
  projectId: IdType;
}

export interface PdfPlanAttachment extends Attachment {
  pdfPlanVersionId: IdType;
  projectId: IdType; // Only required for the image path
}

export interface BimPlan extends IdAware, ChangedAtAware {
  name: string;
  projectId: IdType;
  active: boolean;
}

export type BimVersionStatus = 'final' | 'draft';

export interface BimVersion extends IdAware, ChangedAtAware {
  createdAt: Date | string;
  createdById: IdType;
  name: string;
  number: number;
  index?: string|null;
  note?: string|null;
  bimPlanId: IdType;
  autodeskUrn?: string | null;
  status: BimVersionStatus;
}

export type BimVersionEditable = Pick<BimVersion, 'name' | 'index' | 'note'>;

export type BimVersionAccessType = 'download' | 'view';

export interface BimVersionAccess extends IdAware, ChangedAtAware {
  bimVersionId: IdType;
  userId: IdType;
  createdAt: Date | string;
  type?: BimVersionAccessType | null;
}


export interface AttachmentBim extends Attachment {
  bimVersionId: IdType;
  projectId: IdType;
}

export interface AttachmentBimMarkerScreenshot extends Attachment {
  bimMarkerId: IdType;
  projectId: IdType;
}

export enum PdfPlanVersionQualityEnum {
  LOW = 1,
  DEFAULT = 2,
}

export interface PdfPlanFolder extends IdAware, ChangedAtAware {
  id: IdType;
  name: string;
  projectId: IdType;
  changedAt: Date | string;
  icon?: string | null;
}

export interface PdfPlan extends IdAware, ChangedAtAware {
  folderId: IdType;
  active: boolean;
  createdById?: IdType|string;
  createdAt: Date | string;
  changedAt: Date | string;
}

export interface PdfPlanVersion extends IdAware, ChangedAtAware {
  pdfPlanId: IdType;
  number: number;
  index?: string|null;
  name: string;
  date: Date | string;
  description?: string|null;
  locationId?: IdType|null;
  scale?: string|null;
  quality?: PdfPlanVersionQualityEnum | null;
  createdById?: IdType|string;
  createdAtDb?: Date | string | null;
  createdAt: Date | string;
  changedAt: Date | string;
}

export interface PdfPlanMarker extends IdAware, ChangedAtAware {
  id: IdType;
  positionX: number;
  positionY: number;
  pdfPlanPageId: IdType;
  changedAt: Date | string;
  activityId?: IdType;
  equipmentId?: IdType;
  materialId?: IdType;
}

export interface PdfPlanMarkerProtocolEntry extends PdfPlanMarker {
  projectId: IdType;
  protocolEntryId: IdType;
}

export type BimMarkerMigrationStatus = 'approved-deleted' | 'approved' | 'deleted-pending' | 'auto-moved-pending' | 'resized-pending';

export interface BimMarkerObjectIdByGuidProperty {
  by: 'item-guid';
  value: string;
}

export interface BimMarkerObjectIdByIfcGuidProperty {
  by: 'ifc-guid';
  value: string;
}

export interface BimMarkerObjectIdByAnyProperty {
  by: 'any-prop';
  category: string;
  name: string;
  value: string;
}

export type BimMarkerObjectIdByProperty = BimMarkerObjectIdByGuidProperty | BimMarkerObjectIdByIfcGuidProperty | BimMarkerObjectIdByAnyProperty;

export interface BimMarkerObjectIds {
  properties: BimMarkerObjectIdByProperty[];
  externalId?: string;
}

export interface BimMarkerCameraDbIdMapping {
  __cameraStateDbMapping?: Record<number, BimMarkerObjectIds>;
}

export type DatabaseNumeric = string | number;

export interface BimMarker extends ChangedAtAware, IdAware {
  createdAt: Date|string;
  createdById: IdType;
  viewerId: DatabaseNumeric;
  positionX: DatabaseNumeric;
  positionY: DatabaseNumeric;
  positionZ: DatabaseNumeric;
  objectBounds: DatabaseNumeric[][];
  objectCenter: DatabaseNumeric[];
  objectIds: BimMarkerObjectIds;
  camera: BimMarkerCameraDbIdMapping & Record<string, unknown>;
  bimVersionId: IdType;
  protocolEntryId: IdType;
  sourceBimMarkerId?: IdType|null;
  migrationStatus?: BimMarkerMigrationStatus|null;
}

export interface UserOfflineProject extends IdAware, ChangedAtAware {
  projectId: IdType;
  userId: IdType;
  changedAt: Date | string;
}

export interface UserDeviceOfflineProject extends IdAware, ChangedAtAware {
  deviceUuid: IdType;
  projectId: IdType;
  userId: IdType;
}

export interface CustomPdfConfiguration extends IdAware, ChangedAtAware {
  id: IdType;
  oemPdfs: boolean;
  hideInternalAssignment: boolean;
  hideCompanyFromProtocol: boolean;
  clientId: IdType;
  changedAt: Date | string;
}

export interface Participant extends IdAware, ChangedAtAware {
  id: IdType;
  invited: boolean;
  mailingList: boolean;
  present: boolean;
  presentFrom?: Date | string | null;
  presentTo?: Date | string | null;
  profileId: IdType;
  protocolId?: IdType | null;
  seenAt?: Date | string | null;
  pdfpreviewId?: IdType | null;
  reportId?: IdType | null;
  changedAt: Date | string;
}

export interface PdfProtocolSetting extends IdAware, ChangedAtAware {
  id: IdType;
  /**
   * @defaultValue PdfProtocolLayout.CLASSIC
   */
  layoutType: PdfProtocolLayout;
  pdfStartText: string;
  pdfEndText: string;
  mailText: string;
  mailSubject: string;
  projectId?: IdType | null;
  protocolTypeId?: IdType | null;
  additionalText: string;
  clientShowAddress: boolean;
  hideTransferredEntries: boolean;
  protocolColor: string;
  showAllContactsInProject: boolean;
  showEntryComments: boolean;
  showParticipantCol?: boolean | null;
  /**
   * Relevant when layoutType == CLASSIC
   */
  showEntryCost: boolean;
  showPdfPlanMarker: boolean;
  showPictures: ShowPicturesEnum;
  /**
   * Relevant when layoutType == CLASSIC
   */
  showStatusBar: boolean;
  startNewPageForEntry: boolean;
  showAttachmentDlLink: boolean;
  showEntryCommentPictures: boolean;
  /**
   * Relevant when layoutType == CLASSIC
   */
  showOnlyOpenEntries: boolean;
  /**
   * Relevant when layoutType == COMPACT
   * @defaultValue [TYPE, PRIORITY, DATES, COMPANY, CRAFT]
   */
  printEntryDetails?: PdfPrintEntryDetails[] | null;
  /**
   * Relevant when layoutType == COMPACT
   * @defaultValue false
   */
  showStatusAsCheckbox?: boolean | null;
  /**
   * Relevant when layoutType == COMPACT
   * @defaultValue false
   */
  highlightEntryTitles?: boolean | null;
  /**
   * Relevant when layoutType == COMPACT
   * @defaultValue false
   */
  moreSpaceBetweenEntries?: boolean | null;
  /**
   * Relevant when layoutType == COMPACT
   * @defaultValue false
   */
  appendCarriedOverEntries?: boolean | null;
  /**
   * Relevant when layoutType == COMPACT
   * @defaultValue true
   */
  hideMainEntry?: boolean | null;
  /**
   * Relevant when layoutType == COMPACT
   * @defaultValue true
   */
  printFilteredEntriesOnly?: boolean | null;
  individualProtocol?: boolean | null;
  compactContactList?: boolean | null;
  isSearchPdf: boolean;
  userId?: IdType | null;
  changedAt: Date | string;
  reportName?: string | null;
  ignoreMissingAttachments?: boolean | null;
  addSignature?: boolean | null;
  mailTextUnderDownloadLink?: string | null;
  showLetterhead?: boolean | null;
  letterheadText?: string | null;
  hideDescription?: boolean | null;
  everyEntryOnNewPage?: boolean | null;
  noSplitEntryInfo?: boolean | null;
  showWeather?: boolean | null;
  showBimMarker?: boolean | null;
  useNewLayout?: boolean | null;
  showBannersOnEveryPage?: boolean | null;
}

export interface PdfPreview extends IdAware, ChangedAtAware {
  id: IdType;
  indexNumber: number;
  name?: string | null;
  date?: Date | string | null;
  timeFrom?: Date | string | null;
  timeUntil?: Date | string | null;
  location?: string | null;
  sortEntriesBy?: string | null;
  isSearchPdf: boolean;
  customText?: string | null;
  createdById?: IdType | null;
  protocolId?: IdType | null;
  typeId?: IdType | null;
  projectId?: IdType | null;
  reportId?: IdType | null;
  changedAt: Date | string;
  isClosed?: boolean | null;
  isClosedMigrated?: boolean | null;
  searchProjectIds?: IdType[] | null;
}

export interface PdfPlanPageMarkingBase {
  pdfPlanPageId: IdType;
  name?: string|null;
  markings: string;
}

export interface PdfPlanPageMarking extends IdAware, ChangedAtAware, PdfPlanPageMarkingBase {
  protocolEntryId?: IdType|null;
  createdAt: Date|string;
}

export interface TagBase extends IdAware, ChangedAtAware {
  name: string;
  color: string;
  isActive: boolean;
  createdAt: Date|string;
}

export interface TagClient extends TagBase {
  clientId: IdType;
}

export interface TagClientObject extends IdAware, ChangedAtAware {
  tagId: IdType;
  objectId: IdType;
  objectType: NonClientAwareKey|ClientAwareKey|ProjectAwareKey;
}

export interface ChangedAtAware {
  changedAt: Date | string;
}

export interface NotChanged extends IdAware {
  id: IdType;
  notChanged: boolean;
  fileSize?: number|null; // undefined=not an attachment or no info available, null=no file on server
}

export enum ConstructionScheduleTaskType {
  PHASE = 'phase',
  TASK = 'task',
  SUBTASK = 'subtask',
  MILESTONE = 'milestone'
}

export enum ConstructionScheduleTaskStatus {
  OPEN = 'OPEN',
  DONE = 'DONE'
}

export interface ConstructionScheduleTask extends ChangedAtAware, IdAware {
  id: IdType;
  projectId: IdType;
  parentId?: IdType | null;
  customType: ConstructionScheduleTaskType;
  text: string;
  sortOrder?: number | null;
  status: ConstructionScheduleTaskStatus;
  startDate: Date | string;
  craftId?: IdType | null;
  endDate?: Date | string | null;
  duration?: number | null; // property was added later so for old data it might be undefined. But for new data it should always be set when endDate is set.
  constraintType?: 'asap' | 'alap' | 'snet' | 'snlt' | 'fnet' | 'fnlt' | 'mso' | 'mfo' | null;
  constraintDate?: Date | string | null;
  groupInline?: boolean | null;
  createdAt: Date | string;
  changedAt: Date | string;
}

export enum GanttLinkType {
  finish_to_start = '0',
  start_to_start = '1',
  finish_to_finish = '2',
  start_to_finish = '3'
}

export interface ConstructionScheduleLink extends ChangedAtAware, IdAware {
  id: IdType;
  source: IdType;
  target: IdType;
  type: GanttLinkType;
  changedAt: Date | string;
  lag?: number|null;
}

export interface EmailSettings {
  signature?: Omit<UserEmailSignature, keyof (ChangedAtAware & IdAware)> | null;
}

export interface SyncResponse {
  startServerTime: Date | string;
  endServerTime: Date | string;
}

export interface NonClientAwareRawSyncResponse {
  clients: Array<Client | NotChanged>;
  users: Array<User | NotChanged>;
  attachmentClients: Array<AttachmentClient | NotChanged>;
  reportTypes: Array<ReportType | NotChanged>;
  userEmailSignatures: Array<UserEmailSignature | NotChanged>;
  attachmentUserEmailSignatures: Array<AttachmentUserEmailSignature | NotChanged>;
  calendars: Array<Calendar | NotChanged>;
  calendarDays: Array<CalendarDay | NotChanged>;
  textTemplates: Array<TextTemplate | NotChanged>;
  notificationEvents: Array<NotificationEvent | NotChanged>;
  notificationEventRecipients: Array<NotificationEventRecipient | NotChanged>;
  notificationConfigs: Array<NotificationConfig | NotChanged>;
  notificationConfigRecipients: Array<NotificationConfigRecipient | NotChanged>;
}

export interface ClientAwareRawSyncResponse {
  addresses: Array<Address | NotChanged>;
  companies: Array<Company | NotChanged>;
  companyCrafts: Array<CompanyCraft | NotChanged>;
  profileCrafts: Array<ProfileCraft | NotChanged>;
  crafts: Array<Craft | NotChanged>;
  profiles: Array<Profile | NotChanged>;
  projects: Array<Project | NotChanged>;
  protocolEntryLocations: Array<ProtocolEntryLocation | NotChanged>;
  protocolEntryTypes: Array<ProtocolEntryType | NotChanged>;
  protocolLayouts: Array<ProtocolLayout | NotChanged>;
  protocolTypes: Array<ProtocolType | NotChanged>;
  usersPublic: Array<UserPublic | NotChanged>;
  userOfflineProjects: Array<UserOfflineProject | NotChanged>;
  protocolNameableDropdowns: Array<NameableDropdown | NotChanged>;
  customPdfConfigurations: Array<CustomPdfConfiguration | NotChanged>;
  countries: Array<Country | NotChanged>;
  attachmentProjectImages: Array<AttachmentProjectImage | NotChanged>;
  customReportTypes: Array<CustomReportType | NotChanged>;
  userInvites: Array<UserInvite | NotChanged>;
  userConnectionInvites: Array<UserConnectionInvite | NotChanged>;
  staffingTypes: Array<StaffingType | NotChanged>;
  additionalPayTypes: Array<AdditionalPayType | NotChanged>;
  tagClients: Array<TagClient | NotChanged>;
  tagClientObjects: Array<TagClientObject | NotChanged>;
  appTokens: Array<AppToken | NotChanged>;
  appTokenPermissions: Array<AppTokenPermission | NotChanged>;
  clientCalendars: Array<ClientCalendar | NotChanged>;
  clientCalendarDays: Array<CalendarDay | NotChanged>;
  clientTextTemplates: Array<ClientTextTemplate | NotChanged>;
  userDeviceOfflineProjects: Array<UserDeviceOfflineProject | NotChanged>;
}

interface ProjectAwareRawSyncResponse {
  attachmentChats: Array<AttachmentChat | NotChanged>;
  attachmentProjects: Array<AttachmentProject | NotChanged>;
  attachmentProjectBanners: Array<AttachmentProjectBanner | NotChanged>;
  attachmentProtocolEntries: Array<AttachmentProtocolEntry | NotChanged>;
  pdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry | NotChanged>;
  pdfPlanAttachments: Array<PdfPlanAttachment | NotChanged>;
  pdfPlanPages: Array<PdfPlanPage | NotChanged>;
  pdfPlans: Array<PdfPlan | NotChanged>;
  pdfPlanVersions: Array<PdfPlanVersion | NotChanged>;
  pdfPlanFolders: Array<PdfPlanFolder | NotChanged>;
  protocols: Array<Protocol | NotChanged>;
  protocolEntryChats: Array<ProtocolEntryChat | NotChanged>;
  protocolEntries: Array<ProtocolEntry | NotChanged>;
  protocolOpenEntries: Array<ProtocolOpenEntry | NotChanged>;
  projectCompanies: Array<ProjectCompany | NotChanged>;
  projectCrafts: Array<ProjectCraft | NotChanged>;
  projectProfiles: Array<ProjectProfile | NotChanged>;
  projectProtocolEntryTypes: Array<ProjectProtocolEntryType | NotChanged>;
  projectProtocolLocations: Array<ProjectProtocolLocation | NotChanged>;
  projectProtocolTypes: Array<ProjectProtocolType | NotChanged>;
  projectProtocolNameableDropdownItems: Array<NameableDropdownItem | NotChanged>;
  pdfProtocolSettings: Array<PdfProtocolSetting | NotChanged>;
  pdfPreviews: Array<PdfPreview | NotChanged>;
  shareVersionsEmailSettingsTemplates: Array<ShareVersionsEmailSettingsTemplate | NotChanged>;
  constructionScheduleTasks: Array<ConstructionScheduleTask | NotChanged>;
  constructionScheduleLinks: Array<ConstructionScheduleLink | NotChanged>;
  attachmentReportActivities: Array<AttachmentReportActivity | NotChanged>;
  attachmentReportEquipments: Array<AttachmentReportEquipment | NotChanged>;
  attachmentReportMaterials: Array<AttachmentReportMaterial | NotChanged>;
  attachmentReportCompanies: Array<AttachmentReportCompany | NotChanged>;
  attachmentReportSignatures: Array<AttachmentReportSignature | NotChanged>;
  attachmentProtocolSignatures: Array<AttachmentProtocolSignature | NotChanged>;
  activities: Array<Activity | NotChanged>;
  equipments: Array<Equipment | NotChanged>;
  materials: Array<Material | NotChanged>;
  reports: Array<Report | NotChanged>;
  reportActivities: Array<ReportActivity | NotChanged>;
  reportEmployees: Array<ReportEmployee | NotChanged>;
  reportMachines: Array<ReportMachine | NotChanged>;
  reportMaterials: Array<ReportMaterial | NotChanged>;
  reportSuppliers: Array<ReportSupplier | NotChanged>;
  reportWeeks: Array<ReportWeek | NotChanged>;
  projectCustomReportTypes: Array<ProjectCustomReportType | NotChanged>;
  reportCompanies: Array<ReportCompany | NotChanged>;
  reportCompanyActivities: Array<ReportCompanyActivity | NotChanged>;
  participants: Array<Participant | NotChanged>;
  staffs: Array<Staff | NotChanged>;
  employees: Array<Employee | NotChanged>;
  protocolEntryCompanies: Array<ProtocolEntryCompany | NotChanged>;
  pdfPlanPageMarkings: Array<PdfPlanPageMarking | NotChanged>;
  pdfPlanVersionAccesses: Array<PdfPlanVersionAccess | NotChanged>;
  pdfPlanVersionDistributionAccesses: Array<PdfPlanVersionDistributionAccess | NotChanged>;
  projectCalendars: Array<ProjectCalendar | NotChanged>;
  projectCalendarDays: Array<CalendarDay | NotChanged>;
  projectClientCalendars: Array<ProjectClientCalendar | NotChanged>;
  bimMarkers: Array<BimMarker | NotChanged>;
  attachmentBimMarkerScreenshots: Array<AttachmentBimMarkerScreenshot | NotChanged>;
  bimPlans: Array<BimPlan | NotChanged>;
  bimVersions: Array<BimVersion | NotChanged>;
  bimVersionAccesses: Array<BimVersionAccess | NotChanged>;
  unitLevels: Array<UnitLevel | NotChanged>;
  units: Array<Unit | NotChanged>;
  unitProfiles: Array<UnitProfile | NotChanged>;
}

export interface NonClientAwareSyncResponse extends SyncResponse, NonClientAwareRawSyncResponse {}

export interface ClientAwareSyncResponse extends SyncResponse, ClientAwareRawSyncResponse {}

export type NonClientAwareKey = keyof NonClientAwareRawSyncResponse;

export type ClientAwareKey = keyof ClientAwareRawSyncResponse;

export const customComparators = new Map<ClientAwareKey|ProjectAwareKey|string, (value1: any, value2: any) => boolean>(
  [
    ['profileCrafts', (value1: ProfileCraft, value2: ProfileCraft) => value1.profileId === value2.profileId && value1.craftId === value2.craftId],
    ['companyCrafts', (value1: CompanyCraft, value2: CompanyCraft) => value1.companyId === value2.companyId && value1.craftId === value2.craftId],
    ['protocolOpenEntries', (value1: ProtocolOpenEntry, value2: ProtocolOpenEntry) => value1.protocolId === value2.protocolId && value1.protocolEntryId === value2.protocolEntryId],
    ['projectCompanies', (value1: ProjectCompany, value2: ProjectCompany) => value1.projectId === value2.projectId && value1.companyId === value2.companyId],
    ['projectCrafts', (value1: ProjectCraft, value2: ProjectCraft) => value1.projectId === value2.projectId && value1.craftId === value2.craftId],
    ['projectProfiles', (value1: ProjectProfile, value2: ProjectProfile) => value1.projectId === value2.projectId && value1.profileId === value2.profileId],
    ['projectProtocolEntryTypes', (value1: ProjectProtocolEntryType, value2: ProjectProtocolEntryType) =>
      value1.projectId === value2.projectId && value1.protocolentrytypeId === value2.protocolentrytypeId],
    ['projectProtocolLocations', (value1: ProjectProtocolLocation, value2: ProjectProtocolLocation) =>
      value1.projectId === value2.projectId && value1.protocolentrylocationId === value2.protocolentrylocationId],
    ['projectProtocolTypes', (value1: ProjectProtocolType, value2: ProjectProtocolType) =>
      value1.projectId === value2.projectId && value1.protocoltypeId === value2.protocoltypeId],
    ['projectProtocolNameableDropdownItems', (value1: NameableDropdownItem, value2: NameableDropdownItem) =>
      value1.projectId === value2.projectId && value1.nameabledropdownId === value2.nameabledropdownId],
    ['protocolEntryCompanies', (value1: ProtocolEntryCompany, value2: ProtocolEntryCompany) =>
      value1.companyId === value2.companyId && value1.protocolEntryId === value2.protocolEntryId],
  ]
);

export interface ProjectAwareSyncResponse extends SyncResponse, ProjectAwareRawSyncResponse {
  totalAttachmentSize?: number;
  totalAttachmentSizeOffline?: number;
}

export type ProjectAwareKey = keyof ProjectAwareRawSyncResponse;

export type AttachmentKey =
  | 'attachmentChats'
  | 'attachmentProjects'
  | 'attachmentProtocolEntries'
  | 'pdfPlanPages'
  | 'attachmentReportActivities'
  | 'attachmentReportEquipments'
  | 'attachmentReportMaterials'
  | 'attachmentReportCompanies'
  | 'attachmentReportSignatures'
  | 'attachmentProtocolSignatures'
  | 'attachmentBimMarkerScreenshots'
  | 'attachmentProjectBanners';

export interface ProjectAwareSyncRequest {
  pdfPlanMarkerProtocolEntries?: SyncRequestData<PdfPlanMarkerProtocolEntry>;
  attachmentChats?: SyncRequestData<AttachmentChat>;
  attachmentProtocolEntries?: SyncRequestData<AttachmentProtocolEntry>;
  attachmentProjects?: SyncRequestData<AttachmentProject>;
  attachmentProjectBanners: SyncRequestData<AttachmentProjectBanner>;
  pdfPlanPages?: SyncRequestData<PdfPlanPage>;
  protocolEntryChats?: SyncRequestData<ProtocolEntryChat>;
  protocolEntries?: SyncRequestData<ProtocolEntry>;
  protocols?: SyncRequestData<Protocol>;
  protocolOpenEntries: SyncRequestData<ProtocolOpenEntry>;
  projectCompanies: SyncRequestData<ProjectCompany>;
  projectCrafts: SyncRequestData<ProjectCraft>;
  projectProfiles: SyncRequestData<ProjectProfile>;
  projectProtocolEntryTypes: SyncRequestData<ProjectProtocolEntryType>;
  projectProtocolLocations: SyncRequestData<ProjectProtocolLocation>;
  projectProtocolTypes: SyncRequestData<ProjectProtocolType>;
  projectProtocolNameableDropdownItems: Array<NameableDropdownItem>;
  pdfProtocolSettings?: Array<PdfProtocolSetting | NotChanged>;
  pdfPreviews?: Array<PdfPreview | NotChanged>;
  shareVersionsEmailSettingsTemplates?: Array<ShareVersionsEmailSettingsTemplate | NotChanged>;
  attachmentReportActivities?: Array<AttachmentReportActivity | NotChanged>;
  attachmentReportEquipments?: Array<AttachmentReportEquipment | NotChanged>;
  attachmentReportMaterials?: Array<AttachmentReportMaterial | NotChanged>;
  attachmentReportCompanies?: Array<AttachmentReportCompany | NotChanged>;
  attachmentReportSignatures?: Array<AttachmentReportSignature | NotChanged>;
  attachmentProtocolSignatures?: Array<AttachmentProtocolSignature | NotChanged>;
  activities?: Array<Activity | NotChanged>;
  equipments?: Array<Equipment | NotChanged>;
  materials?: Array<Material | NotChanged>;
  reports?: Array<Report | NotChanged>;
  reportActivities?: Array<ReportActivity | NotChanged>;
  reportEmployees?: Array<ReportEmployee | NotChanged>;
  reportMachines?: Array<ReportMachine | NotChanged>;
  reportMaterials?: Array<ReportMaterial | NotChanged>;
  reportSuppliers?: Array<ReportSupplier | NotChanged>;
  reportWeeks?: Array<ReportWeek | NotChanged>;
  reportTypes?: Array<ReportType | NotChanged>;
  reportCompanies?: Array<ReportCompany | NotChanged>;
  reportCompanyActivities?: Array<ReportCompanyActivity | NotChanged>;
  participants?: Array<Participant | NotChanged>;
  staffs?: Array<Staff | NotChanged>;
  protocolEntryCompanies?: Array<ProtocolEntryCompany | NotChanged>;
  pdfPlanPageMarkings?: Array<PdfPlanPageMarking | NotChanged>;
  pdfPlanVersionAccesses?: Array<PdfPlanVersionAccess | NotChanged>;
  pdfPlanVersionDistributionAccesses?: Array<PdfPlanVersionDistributionAccess | NotChanged>;
  projectCalendars?: Array<ProjectCalendar | NotChanged>;
  projectCalendarDays?: Array<CalendarDay | NotChanged>;
  projectClientCalendars?: Array<ProjectClientCalendar | NotChanged>;
  bimMarkers?: Array<BimMarker | NotChanged>;
  attachmentBimMarkerScreenshots?: Array<AttachmentBimMarkerScreenshot | NotChanged>;
  bimPlans?: Array<BimPlan | NotChanged>;
  bimVersions?: Array<BimVersion | NotChanged>;
  bimVersionAccesses?: Array<BimVersionAccess | NotChanged>;
}

export interface NonClientAwareSyncRequest {
  users?: SyncRequestData<User>;
  userEmailSignatures?: SyncRequestData<UserEmailSignature>;
  attachmentUserEmailSignatures?: SyncRequestData<AttachmentUserEmailSignature>;
  notificationEvents?: SyncRequestData<NotificationEvent>;
  notificationEventRecipients?: SyncRequestData<NotificationEventRecipient>;
  notificationConfigs?: SyncRequestData<NotificationConfig>;
  notificationConfigRecipients?: SyncRequestData<NotificationConfigRecipient>;
}

export interface ClientAwareSyncRequest {
  projects?: SyncRequestData<Project>;
  addresses?: SyncRequestData<Address>;
  companies?: SyncRequestData<Company>;
  profiles?: SyncRequestData<Profile>;
  crafts?: SyncRequestData<Craft>;
  companyCrafts?: SyncRequestData<CompanyCraft>;
  profileCrafts?: SyncRequestData<ProfileCraft>;
  protocolEntryLocations?: SyncRequestData<ProtocolEntryLocation>;
  protocolEntryTypes?: SyncRequestData<ProtocolEntryType>;
  protocolLayouts?: SyncRequestData<ProtocolLayout>;
  protocolTypes?: SyncRequestData<ProtocolType>;
  usersPublic?: SyncRequestData<UserPublic>;
  userOfflineProjects?: SyncRequestData<UserOfflineProject>;
  projectProtocolNameableDropdowns?: Array<NameableDropdown>;
  customPdfConfigurations?: Array<CustomPdfConfiguration>;
  countries?: SyncRequestData<Country>;
  attachmentClients?: Array<AttachmentClient>;
  constructionScheduleTasks?: Array<ConstructionScheduleTask>;
  constructionScheduleLinks?: Array<ConstructionScheduleLink>;
  userInvites?: Array<UserInvite>;
  userConnectionInvites?: Array<UserConnectionInvite>;
  clientCalendars?: Array<ClientCalendar | NotChanged>;
  clientCalendarDays?: Array<CalendarDay | NotChanged>;
  clientTextTemplates?: Array<ClientTextTemplate>;
  userDeviceOfflineProjects?: SyncRequestData<UserDeviceOfflineProject>;
}

export interface SyncRequestData<T> {
  insert?: Array<T>;
  update?: Array<T>;
  delete?: Array<T>;
}

export type ProjectAwareKeyRepoType = {
  [key in ProjectAwareKey]: string;
};

export type NonClientAwareKeyRepoType = {
  [key in NonClientAwareKey]: string;
};

export type ClientAwareKeyRepoType = {
  [key in ClientAwareKey]: string;
};

// the order of this elements reflects the order of insert/update/delete statements during sync
export const projectAwareKeyRepo: ProjectAwareKeyRepoType = {
  protocols: 'protocol',
  protocolEntries: 'protocolEntry',
  protocolEntryChats: 'protocolEntryChat',
  attachmentChats: 'attachmentChat',
  attachmentProjects: 'attachmentProject',
  attachmentProtocolEntries: 'attachmentProtocolEntry',
  attachmentProjectBanners: 'attachmentProjectBanner',
  pdfPlanFolders: 'pdfPlanFolder',
  pdfPlanAttachments: 'pdfPlanAttachment',
  pdfPlans: 'pdfPlan',
  pdfPlanVersions: 'pdfPlanVersion',
  pdfPlanPages: 'pdfPlanPage',
  pdfPlanMarkerProtocolEntries: 'pdfPlanMarkerProtocolEntry',
  protocolOpenEntries: 'protocolOpenEntry',
  projectCompanies: 'projectCompany',
  projectCrafts: 'projectCraft',
  projectProfiles: 'projectProfile',
  projectProtocolEntryTypes: 'projectProtocolEntryType',
  projectProtocolLocations: 'projectProtocolLocation',
  projectProtocolTypes: 'projectProtocolType',
  projectProtocolNameableDropdownItems: 'nameableDropdownItem',
  pdfProtocolSettings: 'pdfProtocolSetting',
  pdfPreviews: 'pdfPreview',
  shareVersionsEmailSettingsTemplates: 'shareVersionsEmailSettingsTemplate',
  constructionScheduleTasks: 'constructionScheduleTask',
  constructionScheduleLinks: 'constructionScheduleLink',
  reportWeeks: 'reportWeek',
  reports: 'report',
  activities: 'activity',
  equipments: 'equipment',
  materials: 'material',
  attachmentReportActivities: 'attachmentReportActivity',
  attachmentReportEquipments: 'attachmentReportEquipment',
  attachmentReportMaterials:  'attachmentReportMaterial',
  attachmentReportSignatures:  'attachmentReportSignature',
  attachmentProtocolSignatures:  'attachmentProtocolSignature',
  reportActivities: 'reportActivity',
  reportEmployees: 'reportEmployee',
  reportMachines: 'reportMachine',
  reportMaterials: 'reportMaterial',
  reportSuppliers: 'reportSupplier',
  reportCompanies: 'reportCompany',
  reportCompanyActivities: 'reportCompanyActivity',
  attachmentReportCompanies: 'attachmentReportCompany',
  projectCustomReportTypes: 'projectCustomReportType',
  participants: 'participant',
  staffs: 'staff',
  employees: 'employee',
  protocolEntryCompanies: 'protocolEntryCompany',
  pdfPlanPageMarkings: 'pdfPlanPageMarking',
  pdfPlanVersionAccesses: 'pdfPlanVersionAccess',
  pdfPlanVersionDistributionAccesses: 'pdfPlanVersionDistributionAccess',
  projectCalendars: 'projectCalendar',
  projectCalendarDays: 'projectCalendarDay',
  projectClientCalendars: 'projectClientCalendar',
  bimMarkers: 'bimMarker',
  attachmentBimMarkerScreenshots: 'attachmentBimMarkerScreenshot',
  bimVersions: 'bimVersion',
  bimPlans: 'bimPlan',
  bimVersionAccesses: 'bimVersionAccess',
  unitLevels: 'unitLevel',
  units: 'unit',
  unitProfiles: 'unitProfile',
};

// the order of this elements reflects the order of insert/update/delete statements during sync
export const nonClientAwareKeyRepo: NonClientAwareKeyRepoType = {
  clients: 'client',
  users: 'user',
  attachmentClients: 'attachmentClient',
  reportTypes: 'reportType',
  userEmailSignatures: 'userEmailSignature',
  attachmentUserEmailSignatures: 'attachmentUserEmailSignature',
  calendars: 'calendar',
  calendarDays: 'calendarDay',
  textTemplates: 'textTemplate',
  notificationEvents: 'notificationEvent',
  notificationEventRecipients: 'notificationEventRecipient',
  notificationConfigs: 'notificationConfig',
  notificationConfigRecipients: 'notificationConfigRecipient',
};

// the order of this elements reflects the order of insert/update/delete statements during sync
export const clientAwareKeyRepo: ClientAwareKeyRepoType = {
  projects: 'project',
  addresses: 'address',
  companies: 'company',
  profiles: 'profile',
  crafts: 'craft',
  companyCrafts: 'companyCraft',
  profileCrafts: 'profileCraft',
  protocolEntryLocations: 'protocolEntryLocation',
  protocolEntryTypes: 'protocolEntryType',
  protocolLayouts: 'protocolLayout',
  protocolTypes: 'protocolType',
  usersPublic: 'userPublic',
  userOfflineProjects: 'userOfflineProject',
  protocolNameableDropdowns: 'nameableDropdown',
  customPdfConfigurations: 'customPdfConfigurations',
  countries: 'country',
  attachmentProjectImages: 'attachmentProjectImage',
  customReportTypes: 'customReportType',
  userInvites: 'userInvite',
  userConnectionInvites: 'userConnectionInvite',
  staffingTypes: 'staffingType',
  additionalPayTypes: 'additionalPayType',
  tagClients: 'tagClient',
  tagClientObjects: 'tagClientObject',
  appTokens: 'appToken',
  appTokenPermissions: 'appTokenPermission',
  clientCalendars: 'clientCalendar',
  clientCalendarDays: 'clientCalendarDay',
  clientTextTemplates: 'clientTextTemplate',
  userDeviceOfflineProjects: 'userDeviceOfflineProject',
};

export type SyncKeyColumnSetType = {
  [key in ProjectAwareKey|NonClientAwareKey|ClientAwareKey]: Array<DBColumnSets.ColumnConfig>;
};

export const syncKeyColumnSetMap: SyncKeyColumnSetType = {
  protocols: DBColumnSets.ProtocolColumnSet,
  protocolEntries: DBColumnSets.ProtocolEntryColumnSet,
  protocolEntryChats: DBColumnSets.ProtocolEntryChatColumnSet,
  attachmentChats: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentChatColumnSet
  ],
  attachmentProjects: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentProjectColumnSet
  ],
  attachmentProjectBanners: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentProjectBannerColumnSet
  ],
  attachmentProtocolEntries: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentProtocolEntryColumnSet
  ],
  attachmentReportCompanies: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentReportCompanyColumnSet
  ],
  pdfPlanPages: DBColumnSets.PdfPlanPageColumnSet,
  pdfPlanMarkerProtocolEntries: DBColumnSets.PdfPlanMarkerProtocolEntryColumnSet,
  pdfPlanAttachments: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.PdfPlanAttachmentColumnSet
  ],
  pdfPlans: DBColumnSets.PdfPlanColumnSet,
  pdfPlanVersions: DBColumnSets.PdfPlanVersionColumnSet,
  pdfPlanFolders: DBColumnSets.PdfPlanFolderColumnSet,
  protocolOpenEntries: DBColumnSets.ProtocolOpenEntryColumnSet,
  projectCompanies: DBColumnSets.ProjectCompanyColumnSet,
  projectCrafts: DBColumnSets.ProjectCraftColumnSet,
  projectProfiles: DBColumnSets.ProjectProfileColumnSet,
  projectProtocolEntryTypes: DBColumnSets.ProjectProtocolEntryTypeColumnSet,
  projectProtocolLocations: DBColumnSets.ProjectProtocolLocationColumnSet,
  projectProtocolTypes: DBColumnSets.ProjectProtocolTypeColumnSet,
  projectProtocolNameableDropdownItems: DBColumnSets.NameableDropdownItemColumnSet,
  projects: DBColumnSets.ProjectColumnSet,
  addresses: DBColumnSets.AddressColumnSet,
  companies: DBColumnSets.CompanyColumnSet,
  profiles: DBColumnSets.ProfileColumnSet,
  crafts: DBColumnSets.CraftColumnSet,
  clients: DBColumnSets.ClientColumnSet,
  companyCrafts: DBColumnSets.CompanyCraftColumnSet,
  profileCrafts: DBColumnSets.ProfileCraftColumnSet,
  protocolEntryLocations: DBColumnSets.ProtocolEntryLocationColumnSet,
  protocolEntryTypes: DBColumnSets.ProtocolEntryTypeColumnSet,
  protocolLayouts: DBColumnSets.ProtocolLayoutColumnSet,
  protocolTypes: DBColumnSets.ProtocolTypeColumnSet,
  users: DBColumnSets.UserColumnSet,
  userInvites: DBColumnSets.UserInviteColumnSet,
  userConnectionInvites: DBColumnSets.UserConnectionInviteColumnSet,
  usersPublic: DBColumnSets.UserPublicColumnSet,
  userOfflineProjects: DBColumnSets.UserOfflineProjectColumnSet,
  protocolNameableDropdowns: DBColumnSets.NameableDropdownColumnSet,
  participants: DBColumnSets.ParticipantColumnSet,
  customPdfConfigurations: DBColumnSets.CustomPdfConfigurationColumnSet,
  pdfPreviews: DBColumnSets.PdfPreviewColumnSet,
  pdfProtocolSettings: DBColumnSets.PdfProtocolSettingColumnSet,
  shareVersionsEmailSettingsTemplates: DBColumnSets.ShareVersionsEmailSettingsTemplateColumnSet,
  countries: DBColumnSets.CountryColumnSet,
  attachmentClients: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentClientColumnSet
  ],
  attachmentProjectImages: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentProjectImageColumnSet
  ],
  constructionScheduleTasks: DBColumnSets.ConstructionScheduleTaskColumnSet,
  constructionScheduleLinks: DBColumnSets.ConstructionScheduleLinkColumnSet,
  reportTypes: DBColumnSets.ReportTypeColumnSet,
  reportWeeks: DBColumnSets.ReportWeekColumnSet,
  reports: DBColumnSets.ReportColumnSet,
  activities: DBColumnSets.ActivityColumnSet,
  equipments: DBColumnSets.EquipmentColumnSet,
  materials: DBColumnSets.MaterialColumnSet,
  attachmentReportActivities: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentReportActivityColumnSet
  ],
  attachmentReportEquipments: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentReportEquipmentColumnSet
  ],
  attachmentReportMaterials: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentReportMaterialColumnSet
  ],
  attachmentReportSignatures: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentReportSignatureColumnSet
  ],
  attachmentProtocolSignatures: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentProtocolSignatureColumnSet
  ],
  attachmentUserEmailSignatures: [
    ...DBColumnSets.AttachmentColumnSet,
    ...DBColumnSets.AttachmentUserEmailSignatureColumnSet
  ],
  reportActivities: DBColumnSets.ReportActivityColumnSet,
  reportEmployees: DBColumnSets.ReportEmployeeColumnSet,
  reportMachines: DBColumnSets.ReportMachineColumnSet,
  reportMaterials: DBColumnSets.ReportMaterialsColumnSet,
  reportSuppliers: DBColumnSets.ReportSupplierColumnSet,
  reportCompanies: DBColumnSets.ReportCompanyColumnSet,
  reportCompanyActivities: DBColumnSets.ReportCompanyActivityColumnSet,
  customReportTypes: DBColumnSets.CustomReportTypeColumnSet,
  projectCustomReportTypes: DBColumnSets.ProjectCustomReportTypeColumnSet,
  staffingTypes: DBColumnSets.StaffingTypeColumnSet,
  staffs: DBColumnSets.StaffColumnSet,
  additionalPayTypes: DBColumnSets.AdditionalPayTypeColumnSet,
  employees: DBColumnSets.EmployeeColumnSet,
  protocolEntryCompanies: DBColumnSets.ProtocolEntryCompanyColumnSet,
  userEmailSignatures: DBColumnSets.UserEmailSignatureColumnSet,
  pdfPlanPageMarkings: DBColumnSets.PdfPlanPageMarkingColumnSet,
  tagClients: DBColumnSets.TagClientColumnSet,
  tagClientObjects: DBColumnSets.TagClientObjectColumnSet,
  pdfPlanVersionAccesses: DBColumnSets.PdfPlanVersionAccessColumnSet,
  pdfPlanVersionDistributionAccesses: DBColumnSets.PdfPlanVersionDistributionAccessColumnSet,
  appTokens: DBColumnSets.AppTokenColumnSet,
  appTokenPermissions: DBColumnSets.AppTokenPermissionColumnSet,
  calendars: DBColumnSets.CalendarColumnSet,
  clientCalendars: DBColumnSets.ClientCalendarColumnSet,
  projectCalendars: DBColumnSets.ProjectCalendarColumnSet,
  calendarDays: DBColumnSets.CalendarDayColumnSet,
  clientCalendarDays: DBColumnSets.ClientCalendarDayColumnSet,
  projectCalendarDays: DBColumnSets.ProjectCalendarDayColumnSet,
  projectClientCalendars: DBColumnSets.ProjectClientCalendarColumnSet,
  textTemplates: DBColumnSets.TextTemplateColumnSet,
  clientTextTemplates: DBColumnSets.ClientTextTemplateColumnSet,
  userDeviceOfflineProjects: DBColumnSets.UserDeviceOfflineProjectColumnSet,
  notificationEvents: DBColumnSets.NotificationEventColumnSet,
  notificationEventRecipients: DBColumnSets.NotificationEventRecipientColumnSet,
  notificationConfigs: DBColumnSets.NotificationConfigColumnSet,
  notificationConfigRecipients: DBColumnSets.NotificationConfigRecipientColumnSet,
  bimMarkers: DBColumnSets.BimMarkerColumnSet,
  attachmentBimMarkerScreenshots: DBColumnSets.AttachmentBimMarkerScreenshotColumnSet,
  bimPlans: DBColumnSets.BimPlanColumnSet,
  bimVersions: DBColumnSets.BimVersionColumnSet,
  bimVersionAccesses: DBColumnSets.BimVersionAccessColumnSet,
  unitLevels: DBColumnSets.UnitLevelColumnSet,
  units: DBColumnSets.UnitColumnSet,
  unitProfiles: DBColumnSets.UnitProfileColumnSet,
};


export enum SendPdfProtocolOption  {
  SEND_ONLY,
  CLOSE_AND_SEND
}

export interface OldNextMeeting {
  show: boolean;
  /** format: yyyy-mm-dd */
  date: string;
  /** format: HH:MM */
  timeStart: string;
  /** format: HH:MM */
  timeEnd: string;
}

export interface NextMeeting extends IdAware, ChangedAtAware {
  show: boolean;
  /** format: yyyy-mm-dd */
  date: string;
  /** format: HH:MM */
  timeStart: string;
  /** format: HH:MM */
  timeEnd: string;
  subject?: string|null;
  body?: string|null;
  groupMeeting: boolean;
  protocolId: IdType;
  pdfpreviewId?: IdType|null;
  createdById: IdType;
}

export interface IndividualNextMeeting extends IdAware, ChangedAtAware {
  createdAt: Date | string;
  createdById: IdType;
  /** format: HH:MM */
  timeStart: string;
  /** format: HH:MM */
  timeEnd: string;
  profileId: IdType;
  nextMeetingId: IdType;
}

export type NextMeetingRequest = Omit<NextMeeting, keyof IdAware | keyof ChangedAtAware | 'createdById' | 'pdfpreviewId'>;
export type IndividualNextMeetingRequest = Omit<IndividualNextMeeting, keyof IdAware | keyof ChangedAtAware | 'createdById' | 'createdAt' | 'nextMeetingId'>;

export interface SendPdfProtocolAdditionalInfo {
  index?: string;
  customText?: string;
}

export interface StatusReport {
  id: number;
  userId: IdType;
  systemInfo: string;
  localChanges: string;
  events: string;
  changedAt: Date;
}

export enum SystemEventCategoryEnum {
  ERROR = 'ERROR',
  EVENT = 'EVENT'
}

export interface SystemEvent {
  timestamp: number;
  timestampIsoDate?: string;
  category: SystemEventCategoryEnum;
  source: string;
  message?: string;
  page?: string;
}

export interface Report extends IdAware, ChangedAtAware {
  title?: string | null;
  closedAt: Date | string | null;
  weather?: number | null;
  reportNumber: number;
  date: Date | string;
  minTemp?: number | null;
  maxTemp?: number | null;
  humidity?: number | null;
  windspeed?: number | null;
  weatherFromApi?: boolean | null;
  hasDamage: boolean;
  additionalInfo?: string | null;
  internalNumber?: string | null;
  dependencyLink?: string | null;
  refusalNote?: string | null;
  inReview: boolean;
  internalNotice?: string | null;
  workingTime1From?: Date | string | null;
  workingTime1To?: Date | string | null;
  workingTime2From?: Date | string | null;
  workingTime2To?: Date | string | null;
  badWeatherTime1From?: Date | string | null;
  badWeatherTime1To?: Date | string | null;
  badWeatherTime2From?: Date | string | null;
  badWeatherTime2To?: Date | string | null;
  craftId?: IdType;
  editorId?: IdType;
  employerId?: IdType;
  reportWeekId: IdType;
  createdById?: IdType | null;
  createdAt?: Date | string | null;
  createdAtDb?: Date | string | null;
}

export interface ReportCompany extends IdAware, ChangedAtAware {
  reportId: IdType;
  companyId: IdType;
  numberOfPersons: number;
  workingHours: number;
}

export interface ReportCompanyActivity extends IdAware, ChangedAtAware {
  reportCompanyId: IdType;
  activityId: IdType;
}

export interface ReportActivity extends IdAware, ChangedAtAware {
  filename?: string | null;
  edited: boolean;
  tags?: string | null;
  clientId?: IdType | null;
  importById?: IdType | null;
  projectId?: IdType | null;
}

export interface ReportEmployee extends IdAware, ChangedAtAware {
  filename?: string | null;
  edited: boolean;
  tags?: string | null;
  clientId?: IdType | null;
  importById?: IdType | null;
  projectId?: IdType | null;
}

export interface ReportMachine extends IdAware, ChangedAtAware {
  filename?: string | null;
  edited: boolean;
  tags?: string | null;
  clientId?: IdType | null;
  importById?: IdType | null;
  projectId?: IdType | null;
}

export interface ReportMaterial extends IdAware, ChangedAtAware {
  filename?: string | null;
  edited: boolean;
  tags?: string | null;
  clientId?: IdType | null;
  importById?: IdType | null;
  projectId?: IdType | null;
}

export interface ReportSupplier extends IdAware, ChangedAtAware {
  filename?: string | null;
  edited: boolean;
  tags?: string | null;
  clientId?: IdType | null;
  importById?: IdType | null;
  projectId?: IdType | null;
}

export interface Employee extends IdAware, ChangedAtAware {
  statusEditor?: number | null;
  statusEmployee?: number | null;
  isDeleted: boolean;
  personalNumber: number;
  displayGroup: string;
  displayEmployerName?: string | null;
  hours?: number | null;
  workingFrom?: string | null;
  workingUntil?: string | null;
  breakHours?: number | null;
  extraPay?: string | null;
  employerId?: IdType | null;
  groupId?: IdType | null;
  modifiedById?: IdType | null;
  parentId?: IdType | null;
  additionalPayTypeId?: IdType | null;
  reportId: IdType;
}

export interface ReportType extends IdAware, ChangedAtAware {
  name: string;
}

export interface CustomReportType extends  IdAware, ChangedAtAware {
  name: string;
  reportTypeId: IdType;
  isActive: boolean;
  clientId: IdType;
}

export interface ProjectCustomReportType extends  IdAware, ChangedAtAware {
  customReportTypeId: IdType;
  projectId: IdType;
}

export interface ReportWeek extends IdAware, ChangedAtAware {
  year: number;
  calenderWeek: number;
  additionalDescription?: string | null;
  createdAt: Date | string;
  createdById: IdType;
  projectId: IdType;
  typeId: IdType;
  customReportTypeId?: IdType | null;
}

export enum ActivityTypeEnum {
  ACTIVITY = 1,
  LV = 2,
  SPECIAL_OCCURRENCE = 3,
  DIRECTION = 4,
}

export interface Activity extends IdAware, ChangedAtAware {
  statusEditor?: number | null;
  statusEmployee?: number | null;
  isDeleted: boolean;
  position?: number | null;
  description?: string | null;
  type: ActivityTypeEnum;
  hours?: number | null;
  locationId?: IdType | null;
  modifiedById?: IdType | null;
  reportId: IdType;
}

export interface Equipment extends IdAware, ChangedAtAware {
  statusEditor?: number | null;
  statusEmployee?: number | null;
  isDeleted: boolean;
  position?: number | null;
  count?: number | null;
  hours?: number | null;
  equipmentItemDisplay?: string | null;
  privatePerUnit?: number | null;
  modifiedById?: IdType | null;
  reportId: IdType;
}

export interface Material extends IdAware, ChangedAtAware {
  statusEditor?: number | null;
  statusEmployee?: number | null;
  isDeleted: boolean;
  position?: number | null;
  count?: number | null;
  unit?: string | null;
  supplier?: string | null;
  deliveryNumber?: string | null;
  description?: string | null;
  modifiedById?: IdType | null;
  reportId: IdType;
}

export interface Staff extends IdAware, ChangedAtAware {
  statusEditor?: number | null;
  statusEmployee?: number | null;
  isDeleted: boolean;
  displayName?: string | null;
  count?: number | null;
  hours?: number | null;
  modifiedById?: IdType | null;
  nameId?: IdType | null;
  staffingTypeId?: IdType | null;
  parentId?: IdType | null;
  reportId: IdType;
  useTimeBlocks?: boolean;
  workingTimeFrom1?: Date | string | null;
  workingTimeTo1?: Date | string | null;
  workingTimeFrom2?: Date | string | null;
  workingTimeTo2?: Date | string | null;
  workingTimeFrom3?: Date | string | null;
  workingTimeTo3?: Date | string | null;
  workingTimeFrom4?: Date | string | null;
  workingTimeTo4?: Date | string | null;
  workingTimeFrom5?: Date | string | null;
  workingTimeTo5?: Date | string | null;
}

export interface StaffingType extends IdAware, ChangedAtAware {
  clientId: IdType;
  name: string;
  code: string;
  isActive: boolean;
}

export interface AdditionalPayType extends IdAware, ChangedAtAware {
  clientId: IdType;
  name: string;
  isActive: boolean;
}

export interface UserEmailSignature extends IdAware, ChangedAtAware {
  text: string;
  includeCompanyLogo: boolean;
  size: 'SMALL' | 'MEDIUM' | 'ORIGINAL';
}

export interface CopyProjectSetting {
  projectName: string;
  projectNumber: number|string;
  copyProjectInformation: boolean;
  copyProjectTeam: boolean;
  copyPdfPlanFolders: boolean;
  copyPdfPlans: boolean;
  copyProtocols: boolean;
  copyConstructionSchedules: boolean;
  copyAttachments: boolean;
  copyDetails: boolean;
  copyCreationDate: boolean;
  copyContacts: boolean;
  ignoreMissingAttachments: boolean;
}

export interface ShareVersionsEmailSettings {
  subject: string;
  text: string;
  addSignature: boolean;
  mailTextUnderDownloadLink: string;
}

type Optional<T> = {
  [P in keyof T]?: T[P] | null | undefined;
};

export interface ShareVersionsEmailSettingsTemplate extends Optional<ShareVersionsEmailSettings>, IdAware, ChangedAtAware {
  projectId: IdType;
}

export interface SharePdfPlanVersionsEmailSettings extends ShareVersionsEmailSettings {
  emailSignature: Omit<UserEmailSignature, 'id' | 'changedAt'>;
}

export interface PdfPlanVersionDist extends IdAware, ChangedAtAware {
  pdfPlanVersionId: IdType;
  distributionId: IdType;
  createdAt: Date | string;
  createdById?: IdType | string | null;
}

export interface PdfPlanVersionDistRecipient extends IdAware, ChangedAtAware {
  versionDistId: IdType;
  profileId: IdType;
  createdAt: Date | string;
  email?: string | null;
  seenAt?: Date | string | null;
}

export type PdfPlanVersionDistributionAccess = PdfPlanVersionDist & PdfPlanVersionDistRecipient;

export interface PdfReportGroup extends IdAware, ChangedAtAware {
  projectId: IdType;
  year: number;
  weekNumber?: number|null;
  month?: number|null;
  pdfPreviewIds: IdType[];
  reportIds: IdType[];
  participantIds: IdType[];
}

export interface AppToken extends IdAware, ChangedAtAware {
  name: string;
  key: string;
  userId?: IdType|null;
  description?: string|null;
  clientId: IdType;
  createdAt: Date|string;
  createdById: IdType;
}

export type AppTokenPermissionKey = 'PROTOCOL' | 'PROJECT' | 'COMPANY' | 'PROFILE' | 'PROJECT_ROOM';
export type AppTokenPermissionType = 'READ' | 'WRITE';

export interface AppTokenPermission extends IdAware, ChangedAtAware {
  appTokenId: IdType;
  permissionKey: AppTokenPermissionKey;
  permission: AppTokenPermissionType;
  createdAt: Date|string;
}

export enum WeatherEnum {
  SUNNY = 1,
  CLOUDY = 2,
  RAINY = 3,
  FOGGY = 4,
  STORMY = 5,
  SNOWY = 6,
  SNOWRAIN = 7,
  RAINSTORM = 8,
  SNOWSTORM = 9
}

export type PlatformType = 'android' | 'ios' | 'web';
export type OperatingSystemType = 'ios' | 'android' | 'windows' | 'mac' | 'unknown';

export interface UsageStatistic extends IdAware, ChangedAtAware{
  userId: IdType;
  deviceUuid: string;
  deviceManufacturer?: string|null;
  deviceName?: string|null;
  deviceModel?: string|null;
  appVersion: string;
  storageDriver: string;
  mediaStorage: string;
  platform: PlatformType;
  operatingSystem: OperatingSystemType;
  osVersion: string;
  platforms: string[];
  userAgent: string;
}

export interface UserDevice extends IdAware, ChangedAtAware {
  userId: IdType;
  deviceUuid: string;
  deviceManufacturer?: string | null;
  deviceName?: string | null;
  deviceModel?: string | null;
  appVersion?: string | null;
  storageDriver?: string | null;
  mediaStorage?: string | null;
  platform?: PlatformType | null;
  operatingSystem?: OperatingSystemType | null;
  osVersion?: string | null;
  platforms?: string[] | null;
  userAgent?: string | null;
  active?: boolean | null; // if null or undefined it means the device was used but never got activated because it exceeded the maximum allowed devices
  activeChangedAt?: Date | string | null,
  lastDataSync?: Date | string | null,
  lastMediaSync?: Date | string | null,
  createdAt: Date | string;
}

export type PdfPlanVersionAccessType = 'download' | 'view';

export interface PdfPlanVersionAccess extends IdAware, ChangedAtAware {
  id: IdType;
  pdfPlanVersionId: IdType;
  userId: IdType;
  createdAt: Date | string;
  changedAt: Date | string;
  type: PdfPlanVersionAccessType;
}

export interface Calendar extends IdAware, ChangedAtAware {
  name: string;
  isActive: boolean;
  createdAt: Date | string;
  createdById?: IdType | null;
}

export interface ClientCalendar extends Calendar {
  clientId: IdType;
}

export interface ProjectCalendar extends Calendar {
  clientId: IdType;
  projectId: IdType;
}

export interface CalendarDay extends IdAware, ChangedAtAware {
  name: string;
  calendarId: IdType;
  date: Date | string;
  isActive: boolean;
  createdAt: Date | string;
  createdById?: IdType | null;
}

export interface ProjectClientCalendar extends IdAware, ChangedAtAware {
  projectId: IdType;
  calendarId: IdType;
}

export enum ReportTypeCode {
  REPORT_TYPE_CONSTRUCTION_REPORT = 'REPORT_TYPE_CONSTRUCTION_REPORT',
  REPORT_TYPE_DIRECTED_REPORT = 'REPORT_TYPE_DIRECTED_REPORT',
  REPORT_TYPE_CONSTRUCTION_DIARY = 'REPORT_TYPE_CONSTRUCTION_DIARY',
}

export interface EntryMail extends IdAware, ChangedAtAware {
  projectId: IdType,
  protocolIds: Array<IdType>
  protocolEntryIds: Array<IdType>,
  recipientProfileIds: Array<IdType>,
  subject: string,
  text: string,
  mailTextUnderContent?: string|null,
  htmlFileLocation: string,
  emailSignature: boolean,
  language: string,
  createdById: IdType,
  createdAt: Date | string;
}

export enum ZohoPlanCode {
  PLAN_ANNUAL = 'digitY',
  PLAN_MONTHLY = 'digitM'
}

export enum ZohoAddonCode {
  SMART_ANNUAL = 'smartY',
  EXPERT_ANNUAL = 'expertY',
  REPORT_ANNUAL = 'tagebY',
  SMART_MONTHLY = 'smartM',
  EXPERT_MONTHLY = 'expertM',
  REPORT_MONTHLY = 'tagebM'
}

export interface ZohoCustomer {
  customer_id?: string,
  display_name: string,
  first_name: string,
  last_name: string,
  email: string,
  mobile: string,
  company_name: string,
  ach_supported: boolean,
  tax_rule_id: string,
  billing_address: {
    street: string,
    city: string,
    state?: string,
    country: string,
    zip: string,
  },
  custom_fields: [
    {
      label: string, 
      value: string
    }
  ]
}

export interface LicenseProduct {
  id: IdType;
  code: string;
  name: string;
  price: number;
}

export interface HostedPageRequest {
  customerNumber: number;
  termsYearly: boolean;
  vatVerified: boolean;
  contact: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    companyName: string;
    uidNumber: string;
  },
  address: {
    street: string;
    number: string;
    zip: string;
    city: string;
    country: Country;
  },
  license: {
    amountSmart: number;
    amountExpert: number;
    amountReport: number;
    couponCode: string;
  }
}
export interface MailHistory extends IdAware, ChangedAtAware {
  createdAt: Date | string;
  createdById: IdType;
  senderEmail: string;
  mailType: string;
  subject: string;
  body: string;
  receiverProfileId?: IdType | null;
  receiverEmail: string;
  clientId: IdType;
  projectId?: IdType | null;
  reportId?: IdType | null;
  reportIds?: IdType[] | null;
  protocolId?: IdType | null;
  distributionId?: IdType | null;
}

export interface TextTemplate extends IdAware, ChangedAtAware {
  createdAt: Date|string;
  createdAtDb?: Date|string|null;
  createdById?: IdType|null;
  category: string;
  name: string;
  textTemplate: string;
}

export interface ProtocolFieldHist extends IdAware, ChangedAtAware {
  action: 'I' | 'U' | 'D'
  protocolId: IdType;
  columnName: string;
  valueOld?: string|null;
  valueNew?: string|null;
  changedById: IdType;
}

export interface ClientTextTemplate extends TextTemplate {
  clientId: IdType;
}

export type TwoFactorDeviceStatus = 'initialized' | 'configured' | 'activated' | 'in_deactivation';
export type TwoFactorDeviceMethod = 'totp' | 'recovery_code';

export interface TwoFactorDevice extends IdAware, ChangedAtAware {
  name: string;
  createdAt: Date|string;
  activatedAt?: Date|string|null;
  status: TwoFactorDeviceStatus;
  activationCode?: string|null;
  userId: IdType;
  method: TwoFactorDeviceMethod;
}

export type TwoFactorDevicePublic = Omit<TwoFactorDevice, 'activationCode'>;

export interface TwoFactorDeviceAware {
  twoFactorDeviceId: IdType;
}

export interface TwoFactorDeviceRecovery extends IdAware, ChangedAtAware, TwoFactorDeviceAware {
  method: 'recovery_code';
  recoveryCodes: string[];
}

export interface TwoFactorDeviceTotp extends IdAware, ChangedAtAware, TwoFactorDeviceAware {
  method: 'totp';
  secretKey: string;
}

export interface AuthRefreshToken extends IdAware {
  userId: IdType;
  expireAt: Date|string;
}

export interface NotificationConfig extends IdAware, ChangedAtAware {
  projectId: IdType;
  pushNotification: boolean;
  protocolEntryNew: boolean;
  protocolEntryNewSub: boolean;
  protocolEntryChatNew: boolean;
  protocolEntryUpdateStatus: boolean;
  protocolEntryUpdateTodoUntil: boolean;
  protocolEntryUpdateAssignee: boolean;
  protocolEntryToResponsible: boolean;
  protocolEntryToCreator: boolean;
  documentPdfPlanNew: boolean;
  documentBimPlanNew: boolean;
  documentToProjectTeam: boolean;
  documentToOwnCompanyProjectTeam: boolean;
  projectUserAdded: boolean;
  createdAt: Date | string;
}

export type NotificationRecipientType = 'ProtocolEntry';

export interface NotificationConfigRecipient extends IdAware, ChangedAtAware {
  notificationRecipientType: NotificationRecipientType;
  notificationConfigId: IdType;
  profileId: IdType;
  createdAt: Date | string;
}

export type NotificationEventType = 
  | 'ProtocolEntryNew'
  | 'ProtocolEntryNewSub'
  | 'ProtocolEntryUpdateStatus'
  | 'ProtocolEntryUpdateTodoUntil'
  | 'ProtocolEntryUpdateAssignee'
  | 'ProtocolEntryChatNew'
  | 'DocumentPdfPlanNew'
  | 'DocumentBimPlanNew'
  | 'ProjectUserAdded';

export type SupportedLanguageStrict = typeof SUPPORTED_LANGUAGES[number];

export interface NotificationEvent extends IdAware, ChangedAtAware {
  notificationConfigId: IdType;
  notificationEventType: NotificationEventType;
  projectName: string;
  protocolEntryId?: IdType|null;
  pdfPlanVersionId?: IdType|null;
  bimVersionId?: IdType|null;
  notificationText: Record<SupportedLanguageStrict, string>;
  referencingObjectChangedAt: Date|string;
  /** This is the userId of the person who initiated the data change that caused this event. Use that to prevent sending events to the person who made a change. */
  createdById: IdType;
  createdAt: Date | string;
}

export interface NotificationEventRecipient extends IdAware, ChangedAtAware {
  notificationEventId: IdType;
  profileId: IdType;
  seenAt?: Date|string|null;
  sentAt?: Date|string|null;
  lastSendAttempt?: Date|string|null;
  sendAttempts: number;
  createdAt: Date | string;
}

export interface FcmPushToken extends ChangedAtAware {
  id: string;
  userDeviceId: IdType;
}

export interface SyncPerformance extends ChangedAtAware, IdAware {
  syncStrategy: number;
  duration: number;
  errored: boolean;
  unresolvedConflicts: boolean;
  conflicts: boolean;
  deviceUuid?: string | null;
  perf?: any | null;
  createdById: IdType;
}

export interface UnitLevel extends ChangedAtAware, IdAware {
  name: string;
  index: number;
  projectId: IdType;
  createdAt: Date | string;
}

export interface Unit extends ChangedAtAware, IdAware {
  unitLevelId: IdType;
  parentId?: IdType|null;
  name: string;
  index: number;
  addressId?: IdType|null;
  createdAt: Date | string;
  notes?: string|null;
  externalId?: string|null;
}

export interface UnitForBreadcrumbs extends Unit {
  parents: Array<UnitForBreadcrumbs>;
  breadcrumbsName: string;
}

export interface UnitProfile extends ChangedAtAware, IdAware {
  unitId: IdType;
  profileId: IdType;
  name?: string|null;
  isDefault: boolean;
  isActive: boolean;
  createdAt: Date | string;
}

export const DEFAULT_CRAFT_COLOR = '#2691C8';
export const PROTOCOL_LAYOUT_NAME_SHORT = 'Kurz';
export const PROTOCOL_LAYOUT_NAME_CONTINUOUS = 'Fortlaufend';
