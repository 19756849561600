import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const bauStatusOpen: IconDefinition = {
  prefix: 'bau' as IconPrefix,
  iconName: 'status-open' as IconName,
  icon: [
    32,
    32,
    [],
    null,
    'M16 32A16 16 0 1132 16 16 16 0 0116 32ZM16 2.13A13.87 13.87 0 1029.87 16 13.88 13.88 0 0016 2.13ZM16 4A12 12 0 1016 28ZM16 28A12 12 0 1016 4Z',
  ]
};
