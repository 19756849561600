import { ColumnConfig } from '../ColumnConfig';

const SignaturePerCodeInSinglePdfUniq = ['report_id', 'code', 'pdf_preview_id', 'for_closed'];

export const AttachmentReportSignatureColumnSet: Array<ColumnConfig> = [
  {name: 'attachment_ptr_id', prop: 'id', cnd: true},
  {name: 'report_id', prop: 'reportId', cnd: true, uniqueWith: [SignaturePerCodeInSinglePdfUniq]},
  {name: 'code', prop: 'code', cnd: true, uniqueWith: [SignaturePerCodeInSinglePdfUniq]},
  {name: 'name', prop: 'name'},
  {name: 'signer_name', prop: 'signerName', def: null},
  {name: 'for_closed', prop: 'forClosed', uniqueWith: [SignaturePerCodeInSinglePdfUniq]},
  {name: 'pdf_preview_id', prop: 'pdfPreviewId', def: null, uniqueWith: [SignaturePerCodeInSinglePdfUniq]},
];
