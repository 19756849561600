import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IdAware, IdType, PdfPlanMarkerProtocolEntry, PdfPlanPage, PdfPlanPageMarking, PdfPlanVersion} from 'submodules/baumaster-v2-common';
import {Breakpoints, DeviceService} from '../../../services/ui/device.service';
import {MoveChoiceType, ShowPlanVersionType} from '../../../model/pdf-plan-marker-migration';
import {PdfPlanPageMarkingDataService} from '../../../services/data/pdf-plan-page-marking-data.service';
import {PdfPlanMarkerProtocolEntryDataService} from '../../../services/data/pdf-plan-marker-protocol-entry-data.service';
import {PdfPlanPageDataService} from '../../../services/data/pdf-plan-page-data.service';
import _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {ToastService} from '../../../services/common/toast.service';
import {observableToPromise} from '../../../utils/async-utils';
import {LoggingService} from '../../../services/common/logging.service';
import {DevModeService} from '../../../services/common/dev-mode.service';

const LOG_SOURCE = 'PdfPlanMarkerMigrationModalComponent';
const COLUMN_BORDER_WIDTH = 2;
const HEADER_HEIGHT_SINGLE_PLAN = 30;
const HEADER_HEIGHT_BOTH_PLANS = 60;
const GRID_PADDING = 5;
const COLUMN_PADDING = 5;

@Component({
  selector: 'app-pdf-plan-marker-migration-modal',
  templateUrl: './pdf-plan-marker-migration-modal.component.html',
  styleUrls: ['./pdf-plan-marker-migration-modal.component.scss'],
})
export class PdfPlanMarkerMigrationModalComponent implements OnInit, OnDestroy {
  modal: HTMLIonModalElement;
  @Input()
  latestPdfPlanVersion: PdfPlanVersion;

  @Input()
  pdfPlanVersion: PdfPlanVersion;

  @Input()
  pdfPlanPages: Array<PdfPlanPage>;

  @Input()
  imageBlobs: Array<Blob>;

  @Input()
  migratePdfPlanPageMarkingGeneral: boolean|undefined = true;

  @Input()
  pdfPlanPageMarkings: Array<PdfPlanPageMarking>;

  @Input()
  pdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry>;

  oldImageLoaded = false;
  newImageLoaded = false;

  private destroy$ = new Subject<void>();

  pdfPlanPageMarkingsByPageId: Record<IdType, Array<PdfPlanPageMarking>>;
  pdfPlanMarkerProtocolEntriesByPageId: Record<IdType, Array<PdfPlanMarkerProtocolEntry>>;

  showPlanVersion: ShowPlanVersionType = 'new';
  moveChoice: MoveChoiceType = 'single';
  showBothPlans$ = this.deviceService.isAboveBreakpoint(Breakpoints.xxl);
  multiplePages: boolean;
  currentPage = 0;

  latestPdfPlanPages$: Observable<Array<PdfPlanPage>>;
  latestPdfPlanPageMarkings$: Observable<Array<PdfPlanPageMarking>>;
  latestPdfPlanMarkerProtocolEntries$: Observable<Array<PdfPlanMarkerProtocolEntry>>;
  latestPdfPlanPageMarkingsByPageId$: Observable<Record<IdType, Array<PdfPlanPageMarking>>>;
  latestPdfPlanMarkerProtocolEntriesByPageId$: Observable<Record<IdType, Array<PdfPlanMarkerProtocolEntry>>>;

  latestPdfPlanPages?: Array<PdfPlanPage>;
  latestPdfPlanPageMarkings?: Array<PdfPlanPageMarking>;
  latestPdfPlanMarkerProtocolEntries?: Array<PdfPlanMarkerProtocolEntry>;
  latestPdfPlanPageMarkingsByPageId?: Record<IdType, Array<PdfPlanPageMarking>>;
  latestPdfPlanMarkerProtocolEntriesByPageId?: Record<IdType, Array<PdfPlanMarkerProtocolEntry>>;
  contentWidth: number|undefined;
  contentHeight: number|undefined;
  canvasWidth: number|undefined;
  canvasHeight: number|undefined;
  devModeEnabled$ = this.devModeService.enabled$;

  scale = 1;

  constructor(private deviceService: DeviceService, private toastService: ToastService, private loggingService: LoggingService,
              private pdfPlanPageMarkingDataService: PdfPlanPageMarkingDataService, private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
              private pdfPLanPageDataService: PdfPlanPageDataService, private devModeService: DevModeService) { }

  async ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, `ngOnInit`);
    this.multiplePages = this.pdfPlanPages.length > 1;

    this.pdfPlanPageMarkingsByPageId = _.groupBy(this.pdfPlanPageMarkings, 'pdfPlanPageId');
    this.pdfPlanMarkerProtocolEntriesByPageId = _.groupBy(this.pdfPlanMarkerProtocolEntries, 'pdfPlanPageId');

    // It is on purpose that no observables are being used here.
    this.latestPdfPlanPages$ = this.pdfPLanPageDataService.getPlanPageByPlanVersionId(this.latestPdfPlanVersion.id);
    const latestPdfPLanPageIds$ = this.latestPdfPlanPages$.pipe(map((pdfPlanPages) => pdfPlanPages.map((pdfPlanPage) => pdfPlanPage.id)));

    this.latestPdfPlanPageMarkings$ = latestPdfPLanPageIds$.pipe(switchMap((pdfPlanPageIds) => this.pdfPlanPageMarkingDataService.getByPlanPagesId(pdfPlanPageIds)))
      .pipe(map((pdfPlanPageMarkings) => pdfPlanPageMarkings.filter((pdfPlanPageMarking) => this.migratePdfPlanPageMarkingGeneral || pdfPlanPageMarking.protocolEntryId)));
    this.latestPdfPlanMarkerProtocolEntries$ = latestPdfPLanPageIds$.pipe(switchMap((pdfPlanPageIds) => this.pdfPlanMarkerProtocolEntryDataService.getByPlanPagesId(pdfPlanPageIds)));
    this.latestPdfPlanPageMarkingsByPageId$ = this.latestPdfPlanPageMarkings$.pipe(map((pdfPlanPageMarkings) => _.groupBy(pdfPlanPageMarkings, 'pdfPlanPageId')));
    this.latestPdfPlanMarkerProtocolEntriesByPageId$ = this.latestPdfPlanMarkerProtocolEntries$.pipe(map((pdfPlanMarkerProtocolEntries) => _.groupBy(pdfPlanMarkerProtocolEntries, 'pdfPlanPageId')));
    this.latestPdfPlanPages$.pipe(takeUntil(this.destroy$))
      .subscribe(async (latestPdfPlanPages) => {
        if (latestPdfPlanPages.length !== this.pdfPlanPages.length) {
          await this.toastService.error('project-room.planMarkerMigration.error.planPagesDoNotMatch');
          await this.dismissModalWithFailed();
        } else {
          this.latestPdfPlanPages = latestPdfPlanPages;
        }
      });
    this.latestPdfPlanPageMarkings$.pipe(takeUntil(this.destroy$))
      .subscribe(async (latestPdfPlanPageMarkings) => {
        if (await this.compareAndDismissModalIfNotMatching(this.latestPdfPlanPageMarkings, latestPdfPlanPageMarkings, 'pdfPlanPageMarkings')) {
          this.latestPdfPlanPageMarkings = latestPdfPlanPageMarkings;
        }
      });
    this.latestPdfPlanMarkerProtocolEntries$.pipe(takeUntil(this.destroy$))
      .subscribe(async (latestPdfPlanMarkerProtocolEntries) => {
        if (await this.compareAndDismissModalIfNotMatching(this.latestPdfPlanMarkerProtocolEntries, latestPdfPlanMarkerProtocolEntries, 'pdfPlanMarkerProtocolEntries')) {
          this.latestPdfPlanMarkerProtocolEntries = latestPdfPlanMarkerProtocolEntries;
        }
      });
    this.latestPdfPlanPageMarkingsByPageId$.pipe(takeUntil(this.destroy$))
      .subscribe(async (latestPdfPlanPageMarkingsByPageId) => {
        this.latestPdfPlanPageMarkingsByPageId = latestPdfPlanPageMarkingsByPageId;
      });
    this.latestPdfPlanMarkerProtocolEntriesByPageId$.pipe(takeUntil(this.destroy$))
      .subscribe(async (latestPdfPlanMarkerProtocolEntriesByPageId) => {
        this.latestPdfPlanMarkerProtocolEntriesByPageId = latestPdfPlanMarkerProtocolEntriesByPageId;
      });
  }

  async ionViewDidEnter() {
    const ionModalElement = this.modal;
    const ionContent: HTMLIonContentElement = ionModalElement.getElementsByTagName('ion-content')[0];
    this.contentWidth = ionContent.clientWidth;
    this.contentHeight = ionContent.clientHeight;
    const showBothPlans = await observableToPromise(this.showBothPlans$);
    if (showBothPlans) {
      this.canvasWidth = (this.contentWidth / 2) - COLUMN_BORDER_WIDTH - GRID_PADDING - COLUMN_PADDING;
      this.canvasHeight = this.contentHeight - HEADER_HEIGHT_BOTH_PLANS - GRID_PADDING - COLUMN_PADDING;
    } else {
      this.canvasWidth = this.contentWidth - GRID_PADDING - COLUMN_PADDING;
      this.canvasHeight = this.contentHeight - HEADER_HEIGHT_SINGLE_PLAN - GRID_PADDING - COLUMN_PADDING;
    }
    this.loggingService.debug(LOG_SOURCE, `ionViewWillEnter - contentWidth=${
      this.contentWidth
    }, contentHeight=${this.contentHeight}, canvasWidth=${this.canvasWidth}, canvasHeight=${this.canvasHeight}`);
  }

  ngOnDestroy(): void {
    this.loggingService.debug(LOG_SOURCE, `ngOnDestroy`);
    this.destroy$.next();
    this.destroy$.complete();
  }

  async save() {
    const data = {
      pdfPlanMarkerProtocolEntries: _.flatten(Object.values(this.pdfPlanMarkerProtocolEntriesByPageId)),
      pdfPlanPageMarkings: _.flatten(Object.values(this.pdfPlanPageMarkingsByPageId))
    };
    await this.modal.dismiss(data, 'ok');
  }

  async cancel() {
    await this.modal.dismiss(undefined, 'cancel');
  }

  private async dismissModalWithFailed() {
    await this.modal.dismiss(undefined, 'dismissModalWithFailed');
  }

  private async compareAndDismissModalIfNotMatching<T extends IdAware>(value1: Array<T>|undefined, value2: Array<T>, type: 'pdfPlanPageMarkings' | 'pdfPlanMarkerProtocolEntries'): Promise<boolean> {
    if (!value1) {
      return true; // not yet initialized and cannot be compared
    }
    const isEqual = _.isEqual(value1, value2);
    if (isEqual) {
      return true;
    }
    await this.toastService.error('project_room.planMarkerMigration.error.dataChanged.' + type);
    await this.dismissModalWithFailed();
    return false;
  }

  public changeMoveChoice(choice: MoveChoiceType) {
    this.moveChoice = choice;
  }

  public changeMoveChoiceExternally(choice: MoveChoiceType) {
    setTimeout(() => this.moveChoice = choice, 0);
  }

  goToPreviousPage() {
    if (this.currentPage === 0) {
      return;
    }
    this.currentPage = this.currentPage - 1;
  }

  goToNextPage() {
    if (this.currentPage >= this.pdfPlanPages.length - 1) {
      return;
    }
    this.currentPage = this.currentPage + 1;
  }

  pdfPlanPageMarkingsChanged(changedPdfPlanPageMarkings: Array<PdfPlanPageMarking>) {
    const currentPlanPageId = this.pdfPlanPages[this.currentPage].id;
    const pdfPlanPageMarkings = _.differenceBy(this.pdfPlanPageMarkingsByPageId[currentPlanPageId], changedPdfPlanPageMarkings, 'id').concat(changedPdfPlanPageMarkings);
    this.pdfPlanPageMarkingsByPageId = {
      ...this.pdfPlanPageMarkingsByPageId,
      [currentPlanPageId]: pdfPlanPageMarkings
    };
  }

  pdfPlanMarkerProtocolEntriesChanged(pdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry>) {
    this.pdfPlanMarkerProtocolEntriesByPageId = {
      ...this.pdfPlanMarkerProtocolEntriesByPageId,
      [this.pdfPlanPages[this.currentPage].id]: pdfPlanMarkerProtocolEntries
    };
  }

  entriesMovedFromOutsideVisibleArea(moved: boolean) {
    if (moved) {
      this.toastService.info('project_room.planMarkerMigration.entriesMovedFromOutsideVisibleArea');
    }
  }

  changeScale(event) {
    const newScale = parseFloat(event.target.value);
    this.scale = newScale;

    for (let i= 0; i< this.pdfPlanMarkerProtocolEntriesByPageId[this.pdfPlanPages[this.currentPage].id].length; i++) {
      const pdfPlanMarkerProtocolEntry = this.pdfPlanMarkerProtocolEntriesByPageId[this.pdfPlanPages[this.currentPage].id][i];
      const originalPdfPlanMarkerProtocolEntry = this.latestPdfPlanMarkerProtocolEntriesByPageId[this.latestPdfPlanPages[this.currentPage].id][i];
      pdfPlanMarkerProtocolEntry.positionX = originalPdfPlanMarkerProtocolEntry.positionX * newScale;
      pdfPlanMarkerProtocolEntry.positionY = originalPdfPlanMarkerProtocolEntry.positionY * newScale;
    }
    this.pdfPlanMarkerProtocolEntriesByPageId[this.pdfPlanPages[this.currentPage].id] = _.clone(this.pdfPlanMarkerProtocolEntriesByPageId[this.pdfPlanPages[this.currentPage].id]);
  }
}
