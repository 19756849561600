<div class="d-flex protocol-status-flexbox" (click)="showContinuousInfoPopover($event)" *ngIf="currentStatusFieldActive === false && (!isProtocolLayoutContinuous || !protocolEntry?.isContinuousInfo)">
  <div class="protocol-status-img protocol-status-info-img">
    <img src="/assets/images/bm-status-info.svg"/>
  </div>
</div>

<div class="d-flex protocol-status-flexbox" (click)="showContinuousInfoPopover($event)" *ngIf="currentStatusFieldActive === false && (isProtocolLayoutContinuous && protocolEntry?.isContinuousInfo)">
  <div class="protocol-status-img protocol-status-continuous-info-img">
    <img src="/assets/images/bm-status-continuous-info.svg"/>
  </div>
</div>

<div class="d-flex protocol-status-flexbox" (click)="showStatusPopover($event)"  *ngIf="protocolEntry?.status === protocolEntryStatus.OPEN && currentStatusFieldActive !== false">
  <div class="protocol-status-text size-xs" *ngIf="showLabel">
    {{'protocolEntry.status.open' | translate}}
  </div>
  <div  class="protocol-status-img protocol-status-open-img">
    <img src="/assets/images/bm-status-open.svg"/>
  </div>
</div>

<div (click)="showStatusPopover($event)" *ngIf="protocolEntry?.status === protocolEntryStatus.COMPANY_DONE && currentStatusFieldActive !== false && !isProtocolLayoutShort" class="d-flex protocol-status-flexbox">
  <div class="protocol-status-text size-xs" *ngIf="showLabel">
    {{'protocolEntry.status.waiting' | translate}}
  </div>
  <div  class="protocol-status-img protocol-status-on-hold-img">
    <img src="/assets/images/bm-status-on-hold.svg"/>
  </div>
</div>

<div class="d-flex protocol-status-flexbox" (click)="showStatusPopover($event)" *ngIf="protocolEntry?.status === protocolEntryStatus.DONE && currentStatusFieldActive !== false">
  <div class="protocol-status-text size-xs" *ngIf="showLabel">
    {{'protocolEntry.status.done' | translate}}
  </div>
  <div  class="protocol-status-img protocol-status-done-img">
    <img src="/assets/images/bm-status-done.svg"/>
  </div>
</div>
