import {ColumnConfig} from '../ColumnConfig';
import {AttachmentChatColumnSet} from './AttachmentChatColumnSet';
import {AttachmentProtocolEntryColumnSet} from './AttachmentProtocolEntryColumnSet';
import {PdfPlanMarkerProtocolEntryColumnSet} from './PdfPlanMarkerProtocolEntryColumnSet';
import {ProtocolEntryChatColumnSet} from './ProtocolEntryChatColumnSet';
import {ProtocolEntryCompanyColumnSet} from './ProtocolEntryCompanyColumnSet';
import {ProtocolOpenEntryColumnSet} from './ProtocolOpenEntryColumnSet';
import {PdfPlanPageMarkingColumnSet} from './PdfPlanPageMarkingColumnSet';
import {BimMarkerColumnSet} from './BimMarkerColumnSet';

const ProtocolEntryColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'number'},
  {name: 'title', def: null},
  {name: 'text', def: null},
  {name: 'status'},
  {name: 'mark_for_next', prop: 'markForNext', def: null},
  {name: 'reminder_at', prop: 'reminderAt', cast: 'Timestamp', def: null},
  {name: 'todo_until', prop: 'todoUntil', cast: 'Date', def: null},
  {name: 'cost', def: null},
  {name: 'priority', def: null},
  {name: 'company_id', prop: 'companyId', def: null},
  {name: 'craft_id', prop: 'craftId', def: null},
  {name: 'location_id', prop: 'locationId', def: null},
  {name: 'parent_id', prop: 'parentId', def: null},
  {name: 'protocol_id', prop: 'protocolId'},
  {name: 'created_in_protocol_id', prop: 'createdInProtocolId', def: null},
  {name: 'type_id', prop: 'typeId', def: null},
  {name: 'all_companies', prop: 'allCompanies', def: false},
  {name: 'nameable_dropdown_id', prop: 'nameableDropdownId', def: null},
  {name: 'internal_assignment_id', prop: 'internalAssignmentId', def: null},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'start_date', prop: 'startDate', cast: 'Date', def: null},
  {name: 'created_at_db', prop: 'createdAtDb', cnd: true, cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById', def: null},
  {name: 'is_continuous_info', prop: 'isContinuousInfo', def: null},
  {name: 'unit_id', prop: 'unitId', def: null},
];

ProtocolEntryColumnSet.push(
  {name: 'protocolEntries', fk: 'parentId', mappedBy: ProtocolEntryColumnSet, transient: true},
  {name: 'attachmentProtocolEntries', fk: 'protocolEntryId', mappedBy: AttachmentProtocolEntryColumnSet, transient: true},
  {name: 'protocolEntryChats', fk: 'protocolEntryId', mappedBy: ProtocolEntryChatColumnSet, transient: true},
  {name: 'pdfPlanMarkerProtocolEntries', fk: 'protocolEntryId', mappedBy: PdfPlanMarkerProtocolEntryColumnSet, transient: true},
  {name: 'attachmentChats', fk: 'protocolEntryId', mappedBy: AttachmentChatColumnSet, transient: true},
  {name: 'protocolOpenEntries', fk: 'protocolEntryId', mappedBy: ProtocolOpenEntryColumnSet, transient: true},
  {name: 'protocolEntryCompanies', fk: 'protocolEntryId', mappedBy: ProtocolEntryCompanyColumnSet, transient: true},
  {name: 'pdfPlanPageMarkings', fk: 'protocolEntryId', mappedBy: PdfPlanPageMarkingColumnSet, transient: true},
  {name: 'bimMarkers', fk: 'protocolEntryId', mappedBy: BimMarkerColumnSet, transient: true},
);

export { ProtocolEntryColumnSet };
