import {Injectable} from '@angular/core';
import _ from 'lodash';
import {Observable, OperatorFunction} from 'rxjs';
import {distinctUntilChanged, map, shareReplay} from 'rxjs/operators';
import {observableToPromise} from 'src/app/utils/async-utils';
import {isClientTextTemplate} from 'src/app/utils/text-template-utils';
import {ClientTextTemplate, IdType, TextTemplate} from 'submodules/baumaster-v2-common';
import {ClientDataService} from '../data/client-data.service';
import {ClientTextTemplateDataService} from '../data/client-text-template-data.service';
import {TextTemplateDataService} from '../data/text-template-data.service';

const groupByCategoryAndShare: () => OperatorFunction<TextTemplate[], Record<string, TextTemplate[]>> = () => (templates$) => templates$.pipe(
  map((templates) => _.groupBy(templates, 'category')),
  shareReplay(1)
);

const filterTemplatesByCategory = (category: string) => (source$: Observable<Record<string, TextTemplate[]>>): Observable<TextTemplate[]> => {
  return source$.pipe(
    map((templatesByCategory) => templatesByCategory[category] ?? []),
    distinctUntilChanged(_.isEqual)
  );
};

@Injectable({
  providedIn: 'root'
})
export class TextTemplateService {
  clientTextTemplatesByCategory$ = this.clientTextTemplateDataService.dataReally.pipe(groupByCategoryAndShare());
  textTemplatesByCategory$ = this.textTemplateDataService.dataReally.pipe(groupByCategoryAndShare());

  constructor(
    private clientDataService: ClientDataService,
    private textTemplateDataService: TextTemplateDataService,
    private clientTextTemplateDataService: ClientTextTemplateDataService
  ) {}

  getClientTemplates$(category: string) {
    return this.clientTextTemplatesByCategory$.pipe(filterTemplatesByCategory(category));
  }

  getGlobalTemplates$(category: string) {
    return this.textTemplatesByCategory$.pipe(filterTemplatesByCategory(category));
  }

  async createClientTextTemplate(textTemplate: TextTemplate|ClientTextTemplate) {
    let clientId: IdType;
    if (isClientTextTemplate(textTemplate)) {
      clientId = textTemplate.clientId;
    } else {
      const client = await observableToPromise(this.clientDataService.getOwnClient());
      if (!client) {
        throw new Error('TextTemplateService.createClientTextTemplate: Own client is undefined!');
      }
      clientId = client.id;
    }
    return (await this.clientTextTemplateDataService.insert({
      ...textTemplate,
      clientId,
    }, clientId))[0];
  }

  async updateClientTextTemplate(textTemplate: ClientTextTemplate) {
    return (await this.clientTextTemplateDataService.update(textTemplate, textTemplate.clientId))[0];
  }

  async deleteClientTextTemplate(textTemplate: ClientTextTemplate) {
    await this.clientTextTemplateDataService.delete(textTemplate, textTemplate.clientId);

    return true;
  }

}
