import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {PDFDocumentProxy} from 'pdfjs-dist';
import {LoadingController, NavParams} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {Signer, SignerWithSignature} from '../../../model/send-protocol';
import {PdfProtocolSignatureForm} from '../pdf-protocol-signatures/pdf-protocol-signatures.interface';
import {Attachment, IdType, sanitizeForFilename} from 'submodules/baumaster-v2-common';
import {ToastService} from '../../../services/common/toast.service';
import {LoggingService} from '../../../services/common/logging.service';

const LOG_SOURCE = 'PdfViewerComponent';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})

export class PdfViewerComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  @ViewChild('input') input: HTMLIonInputElement;
  public pdfObjectUrl: ArrayBuffer | string;
  public blob: Blob|null = null;
  public pdfObjectUrls = new Array<ArrayBuffer|string>();
  public blobs = new Array<Blob>();
  private pdfDocumentPromises = new Array<Promise<PDFDocumentProxy>>();
  private pdfDocumentPromiseResolvers = new Array<{resolve: (value: PDFDocumentProxy | PromiseLike<PDFDocumentProxy>) => void, reject: (reason?: any) => void}>();
  public numberOfPagesByPdfNumber = new Map<number, number>();
  public pdfsByNumber = new Map<number, PDFDocumentProxy|undefined>();
  public allPdfDocumentsLoaded = false;
  public pdf: PDFDocumentProxy | undefined;
  public currentPdfObjectUrlNumber = 0;
  public currentPageOfDocument = 1;
  public currentPage = 1;
  public numPages: number|undefined;
  public zoom = 1;
  public showDownload = false;
  public filename = '';
  public signers?: Array<Signer>;
  public signersWithSignature?: Array<SignerWithSignature>;
  public signersWithSignatureChanged?: (signersWithSignature: Array<SignerWithSignature>) => void;
  public pdfProtocolSignatures?: Array<PdfProtocolSignatureForm>;
  public pdfProtocolSignaturesChanged?: (pdfProtocolSignatures: Array<PdfProtocolSignatureForm>) => void;
  public showSendButtons = false;
  public showCloseCheckbox = false;
  public requestSend?: (argument: {closeProtocolAfterSend?: boolean}) => void;
  public requestClose?: () => void;
  public requestRender?: (abortSignal?: AbortSignal) => Promise<{blob?: Blob, blobs?: Blob[], attachmentsMissingContent: Array<Attachment>}>;
  public onDownloadClick?: () => void;
  @Output()
  public renderEmitter = new EventEmitter<void>();
  public closeProtocolAfterSend = false;
  public projectId: IdType;
  public protocolId?: IdType;
  public reportId?: IdType;

  @ViewChild('pdfViewer') pdfComponent: PdfViewerComponent;

  constructor(private navParams: NavParams, private loadingController: LoadingController,
              private translateService: TranslateService, private toastService: ToastService, private loggingService: LoggingService) {
  }

  async ngOnInit() {
    this.pdfObjectUrl = this.navParams.data.pdfObjectUrl;
    if (!this.pdfObjectUrl) {
      throw new Error('Pdf url must not be empty or null.');
    }
    if (this.navParams.data.pdfBlob && (this.navParams.data.pdfBlob?.size > 0 || this.navParams.data.pdfBlob?.length === 1) && !_.isEmpty(this.navParams.data.filename)) {
      this.showDownload = true;
      this.blob = _.isArray(this.navParams.data.pdfBlob) ? _.head(this.navParams.data.pdfBlob) : this.navParams.data.pdfBlob;
      this.filename = sanitizeForFilename(this.navParams.data.filename, {replacement: '_', includesFileExt: true});
    }
    this.initPdfObjectUrlsAndBlobs(this.pdfObjectUrl, this.blob);
    if (this.pdfObjectUrls.length === 1) {
      await this.loadSinglePdfDocument();
    } else if (this.pdfObjectUrls.length > 1) {
      await this.loadAllPdfDocuments();
    }
  }

  public isCurrentBlobTheFirst(): boolean {
    return this.currentPdfObjectUrlNumber === 0;
  }

  public isCurrentBlobTheLast(): boolean {
    return this.currentPdfObjectUrlNumber === this.blobs.length - 1;
  }

  public isCurrentPageTheFirst(): boolean {
    return this.currentPage === 1;
  }

  public isCurrentPageTheLast(): boolean|undefined {
    if (!this.pdf) {
      return undefined;
    }
    return this.currentPage >= this.pdf.numPages;
  }

  goPrevious() {
    if (this.pdf === null) {
      return;
    }
    if (this.currentPage === 1) {
      return;
    }
    this.updatePage(this.currentPage - 1);
  }

  goNext() {
    if (this.pdf === null) {
      return;
    }
    if (this.currentPage >= this.numPages) {
      return;
    }
    this.updatePage(this.currentPage + 1);
  }

  updatePage(newValue: number) {
    if (!this.pdf || !this.allPdfDocumentsLoaded) {
      return;
    }
    if (!newValue || (newValue === this.currentPage)) {
      return;
    }
    const {pageNumberOfDocument, pdfObjectUrlNumber} = this.pageNumberToPageNumberOfDocumentAndPdfObjectUrlNumber(newValue);
    if (newValue > this.numPages) {
      if (this.currentPage === this.numPages) {
        this.currentPage = this.numPages;
        this.input.value = this.numPages;
        return;
      }
      this.currentPage = this.numPages;
    } else {
      this.currentPage = newValue;
    }
    this.currentPdfObjectUrlNumber = pdfObjectUrlNumber;
    this.currentPageOfDocument = pageNumberOfDocument;
    this.pdfObjectUrl = this.pdfObjectUrls[this.currentPdfObjectUrlNumber];
    this.blob = this.blobs.length ? this.blobs[this.currentPdfObjectUrlNumber] : undefined;
  }

  private pageNumberToPageNumberOfDocumentAndPdfObjectUrlNumber(pageNumber: number): {pageNumberOfDocument: number|undefined, pdfObjectUrlNumber: number|undefined} {
    if (!this.allPdfDocumentsLoaded) {
      return {pageNumberOfDocument: undefined, pdfObjectUrlNumber: undefined};
    }
    let pageNumberOfDocument = pageNumber;
    for (let pdfObjectUrlNumber = 0; pdfObjectUrlNumber < this.pdfObjectUrls.length; pdfObjectUrlNumber++) {
      const newPageNumber = pageNumberOfDocument - this.numberOfPagesByPdfNumber.get(pdfObjectUrlNumber);
      if (newPageNumber <= 0) {
        return {pageNumberOfDocument, pdfObjectUrlNumber};
      }
      pageNumberOfDocument = newPageNumber;
    }
    // this ist just in case the pageNumber is higher than the total number of pages.
    return {pageNumberOfDocument: this.numberOfPagesByPdfNumber.get(this.pdfObjectUrls.length - 1), pdfObjectUrlNumber: this.pdfObjectUrls.length - 1};
  }

  zoomIn() {
    this.zoom += 0.1;
  }

  zoomOut() {
    this.zoom -= 0.1;
  }

  async close() {
    await this.modal.dismiss();
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.loggingService.debug(LOG_SOURCE, `afterLoadComplete - currentPdfObjectUrlNumber=${this.currentPdfObjectUrlNumber}, numPages=${pdf?.numPages} `);
    this.pdf = pdf;
    this.numberOfPagesByPdfNumber.set(this.currentPdfObjectUrlNumber, this.pdf.numPages);
    this.pdfsByNumber.set(this.currentPdfObjectUrlNumber, pdf);
    if (this.numPages === undefined) {
      // used when only one pdf document is loaded
      this.numPages = this.pdf.numPages;
    }
    // resolving the Promise before "pageRendered" is called leads to the error "pdf_viewer.js:6723 Unable to initialize viewer Error: Transport destroyed" in the console, that can be ignored.
    this.pdfDocumentPromiseResolvers[this.currentPdfObjectUrlNumber]?.resolve(pdf);
  }

  async onError(error: any) {
    this.loggingService.error(LOG_SOURCE, `onError(${error})`);
    await this.toastService.error('pdfProtocol.errorRenderingPreview');
    this.pdfDocumentPromiseResolvers[this.currentPdfObjectUrlNumber]?.reject(error);
    this.pdfsByNumber.set(this.currentPdfObjectUrlNumber, undefined);
  }

  ngOnDestroy(): void {
    this.pdf = null;
    this.pdfComponent = null;
  }

  onProgress(event: any) {}

  pageRendered(event: any) {
    this.loggingService.debug(LOG_SOURCE, `pageRendered - `);
  }

  public async pdfProtocolSignaturesChange(pdfProtocolSignatures: PdfProtocolSignatureForm[]) {
    await this.requestReRenderPdf();
    if (this.pdfProtocolSignaturesChanged) {
      this.pdfProtocolSignaturesChanged(pdfProtocolSignatures);
    }
  }

  private async requestReRenderPdf() {
    if (!this.requestRender) {
      return;
    }

    const loading = await this.loadingController.create({
      message: this.translateService.instant('pdfProtocol.creatingPreview'),
      backdropDismiss: true,
      keyboardClose: true,
      showBackdrop: true
    });
    try {
      const currentPageBefore = this.currentPage;
      const abortController = new AbortController();
      await loading.present();
      loading.onDidDismiss().then((result) => {
        if (result?.role === 'backdrop') {
          abortController.abort();
          this.toastService.info('pdfProtocol.aborted');
        }
      });
      const pdfPreviewResult = await this.requestRender(abortController.signal);
      this.pdfObjectUrls.forEach((pdfObjectUrl) => {
        if (typeof pdfObjectUrl === 'string') {
          URL.revokeObjectURL(pdfObjectUrl);
        }
      });
      const blobs = pdfPreviewResult.blobs ? pdfPreviewResult.blobs : [pdfPreviewResult.blob];
      const pdfObjectUrls = blobs.map((blob) => URL.createObjectURL(blob));
      this.initPdfObjectUrlsAndBlobs(pdfObjectUrls, blobs);
      this.currentPdfObjectUrlNumber = 0;
      this.pdfObjectUrl = this.pdfObjectUrls[this.currentPdfObjectUrlNumber];
      this.currentPage = 1; // it's important to change the currentPage to 1, as updatePage does not do anything if currentPage has not changed.
      this.zoom = 1;
      setTimeout(() => this.updatePage(currentPageBefore), 200);
    } finally {
      await loading.dismiss();
    }
  }

  private initPdfObjectUrlsAndBlobs(pdfObjectUrlOrArray: ArrayBuffer | string | string [], blobOrArray: Blob|Blob[]|null) {
    if (!pdfObjectUrlOrArray) {
      throw new Error('initPdfObjectUrlsAndBlobs - pdfObjectUr must not be empty or null.');
    }
    this.pdfObjectUrls = _.isArray(pdfObjectUrlOrArray) ? pdfObjectUrlOrArray as Array<string | ArrayBuffer> : [pdfObjectUrlOrArray as string | ArrayBuffer];
    if (!this.pdfObjectUrls.length) {
      throw new Error('initPdfObjectUrlsAndBlobs - pdfObjectUr must not be an empty array.');
    }
    this.pdfObjectUrl = this.pdfObjectUrls[0];
    if (blobOrArray) {
      this.blobs = _.isArray(blobOrArray) ? blobOrArray as Array<Blob> : [blobOrArray as Blob];
    } else {
      this.blobs = [];
    }
    if (this.blobs.length > 0) {
      if (this.blobs.length !== this.pdfObjectUrls.length) {
        throw new Error('initPdfObjectUrlsAndBlobs- blobs were provided but do not match the length of pdfObjectUrls.');
      }
      this.blob = this.blobs[0];
    } else {
      this.blob = null;
    }
  }

  public checkCloseProtocol() {
    this.closeProtocolAfterSend = !this.closeProtocolAfterSend;
  }

  public triggerSendPdf() {
    this.requestSend({closeProtocolAfterSend: this.closeProtocolAfterSend});
  }

  private async loadSinglePdfDocument() {
    this.currentPdfObjectUrlNumber = 0;
    this.currentPage = 1;
    this.currentPageOfDocument = 1;
    this.allPdfDocumentsLoaded = true;
  }

  private async loadAllPdfDocuments() {
    this.loggingService.debug(LOG_SOURCE, `loadAllPdfDocuments called`);
    this.pdfDocumentPromises = [];
    this.pdfDocumentPromiseResolvers = [];
    for (const pdfObjectUrl of this.pdfObjectUrls) {
      this.pdfDocumentPromises.push(new Promise<PDFDocumentProxy>((resolve, reject) => {
        this.pdfDocumentPromiseResolvers.push({resolve, reject});
      }));
    }

    if (!this.pdfObjectUrls.length) {
      return;
    }

    if (this.currentPage !== 1) {
      this.currentPage = 1;
    }
    let numPages = 0;

    for (let i = 0; i < this.pdfObjectUrls.length; i++) {
      this.currentPdfObjectUrlNumber = i;
      this.loggingService.debug(LOG_SOURCE, `loadAllPdfDocuments - switching to pdfObjectUrl ${i}`);
      this.pdfObjectUrl = this.pdfObjectUrls[this.currentPdfObjectUrlNumber];
      const pdfDocument = await this.pdfDocumentPromises[i];
      this.loggingService.debug(LOG_SOURCE, `loadAllPdfDocuments - pdfObjectUrl ${i} loaded`);
      numPages += pdfDocument.numPages;
    }
    this.numPages = numPages;
    this.currentPdfObjectUrlNumber = 0;
    this.pdfObjectUrl = this.pdfObjectUrls[this.currentPdfObjectUrlNumber];
    this.allPdfDocumentsLoaded = true;
    this.loggingService.debug(LOG_SOURCE, `loadAllPdfDocuments finished. numPages = ${numPages}`);
  }
}
