import {Content, TDocumentDefinitions} from 'pdfmake/interfaces';
import {PdfPreview} from '../../models';
import {PdfProtocolSendReq} from '../../requestResponse';
import {ProjectContent} from './content/pdfWorkflow/project.content';
import {ProtocolEntriesContent} from './content/pdfWorkflow/protocolEntries.content';
import {HeaderFooterData, PdfProtocolGenerateData} from './pdfProtocol.model';
import {AbstractPdfCommon} from './abstractPdfCommon.service';
import {PdfHelperFunctions} from '../common-report-utils';
import {ProtocolSignaturesContent} from './content/pdfWorkflow/protocolSignatures.content';
import {PDF_NODE_ID_BIM_CONTENT, PDF_NODE_ID_BIM_HEADER, PDF_NODE_ID_DESC_SEARCH_STRING, PDF_NODE_ID_ENTRY_TITLE, PDF_NODE_ID_HEADER_SEARCH_STRING,
        PDF_NODE_ID_HEADER_TABLE_SEARCH_STRING, PDF_NODE_ID_IMAGE_HEADER, PDF_NODE_ID_PLAN_CONTENT, PDF_NODE_ID_PLAN_HEADER, PDF_NODE_ID_SUBENTRY_HEADER} from '../../constants';
import {ProjectContentNew} from './content/pdfWorkflow/project.content.new';
import {ProtocolEntriesContentNew} from './content/pdfWorkflow/protocolEntries.content.new';
import {ProtocolSignaturesContentNew} from './content/pdfWorkflow/protocolSignatures.content.new';


export abstract class AbstractPdfProtocolCommonService extends AbstractPdfCommon {

  getDocDefinition(config: PdfProtocolSendReq, data: PdfProtocolGenerateData, pdfHelperFunctions: PdfHelperFunctions, bannerDimensions?: number[]|undefined,
                   indexNumber?: number, pdfPreview?: PdfPreview): TDocumentDefinitions {
    try {
      const isNewLayout = !!config.pdfProtocolSetting?.useNewLayout;
      const content: Content = [];
      const projectContent = isNewLayout ? new ProjectContentNew(config, data, pdfHelperFunctions, pdfPreview) : new ProjectContent(config, data, pdfHelperFunctions, pdfPreview);
      const protocolEntriesContent = isNewLayout ? new ProtocolEntriesContentNew(config, data, pdfHelperFunctions, pdfPreview) : new ProtocolEntriesContent(config, data, pdfHelperFunctions, pdfPreview);

      projectContent.writeProject(content, indexNumber);
      protocolEntriesContent.writeProtocolEntries(content);
      projectContent.writeAfterProtocolDetails(content);
      if (data.attachmentProtocolSignatures?.length) {
        const protocolSignaturesContent = isNewLayout ? new ProtocolSignaturesContentNew(config, data, pdfHelperFunctions, pdfPreview) : new ProtocolSignaturesContent(config, data, pdfHelperFunctions, pdfPreview);
        protocolSignaturesContent.writeSignatures(content);
      }

      return this.getGeneratedDocDefinitions(data.project.language, content, config, {
        project: data.project,
        protocol: data.protocol,
        attachmentClients: data.attachmentClients ? data.attachmentClients : new Map(),
        attachmentProjectImage: data.attachmentProjectImage,
        pdfProjectBanners: data.pdfProjectBanners,
        lookup: {
          protocolTypes: data.lookup.protocolTypes
        }
      }, bannerDimensions);

    } catch (err) {
      throw new Error(`Error generating pdf. ${err}`);
    }
  }

  protected getGeneratedDocDefinitions(language: string, content: Content[], config: PdfProtocolSendReq, data: HeaderFooterData, bannerDimensions?: number[]|undefined): TDocumentDefinitions {
    const noSplitEntryInfo = config.pdfProtocolSetting?.noSplitEntryInfo ?? false;
    let actualHeightFooter = 0;
    let actualHeightHeader = 0;
    if (bannerDimensions) {
      const ratio = Math.min(515 / bannerDimensions[0], 105 / bannerDimensions[1]);
      actualHeightFooter = ratio * bannerDimensions[1];

      if (bannerDimensions[3] > 1) {
        const ratioHeader = Math.min(515 / bannerDimensions[2], 105 / bannerDimensions[3]);
        actualHeightHeader = ratioHeader * bannerDimensions[3];
      }
    }
    const docDefinition: TDocumentDefinitions = {
      pageSize: 'A4',
      content,
      styles: this.getPdfStyle(config, bannerDimensions),
      images: this.getPdfImage(data),
      header: (currentPage: number, pageCount: number, pageSize) => {
        return this.getPdfHeader(data, config, currentPage);
      },
      footer: (currentPage: number, pageCount: number) => {
        return this.getPdfFooter(currentPage, pageCount, language, config, data);
      },
      defaultStyle: {
        font: 'Inter'
      },
      pageMargins: [ 40, (config.pdfProtocolSetting?.showBannersOnEveryPage && bannerDimensions && bannerDimensions[2] > 1) ? (60 + actualHeightHeader) : 50,
                     40, (config.pdfProtocolSetting?.showBannersOnEveryPage && bannerDimensions && bannerDimensions[0] > 1) ? (50 + actualHeightFooter) : 40 ],
      pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
        if (currentNode.id?.includes(PDF_NODE_ID_HEADER_SEARCH_STRING) && noSplitEntryInfo) {
          const descNode = followingNodesOnPage.find(node => node.id?.includes(PDF_NODE_ID_DESC_SEARCH_STRING));
          if ((descNode && descNode.pageNumbers.length > 1) || (currentNode.pageNumbers.length && descNode?.pageNumbers?.length && currentNode.pageNumbers[0] !== descNode?.pageNumbers[0])) {
            return true;
          }
        }
        if (config.pdfProtocolSetting?.useNewLayout) {
          if (currentNode?.id?.includes(PDF_NODE_ID_HEADER_SEARCH_STRING)) {
            if (!followingNodesOnPage.find(node => node.id?.includes(PDF_NODE_ID_HEADER_TABLE_SEARCH_STRING))) {
              return true;
            }
          }
          if (currentNode?.id?.includes(PDF_NODE_ID_HEADER_SEARCH_STRING)) {
            if (!followingNodesOnPage.find(node => node.id?.includes(PDF_NODE_ID_ENTRY_TITLE))) {
              return true;
            }
          }
          if (currentNode?.id?.includes(PDF_NODE_ID_SUBENTRY_HEADER)) {
            if (!followingNodesOnPage.find(node => node.id?.includes(PDF_NODE_ID_ENTRY_TITLE))) {
              return true;
            }
          }
          if (currentNode?.id?.includes(PDF_NODE_ID_BIM_HEADER)) {
            if (!followingNodesOnPage.find(node => node.id?.includes(PDF_NODE_ID_BIM_CONTENT))) {
              return true;
            }
          }
          if (currentNode?.id?.includes(PDF_NODE_ID_PLAN_HEADER)) {
            if (!followingNodesOnPage.find(node => node.id?.includes(PDF_NODE_ID_PLAN_CONTENT))) {
              return true;
            }
          }
          if (currentNode?.id?.includes(PDF_NODE_ID_IMAGE_HEADER)) {
            const followingNode = followingNodesOnPage.find(node => node.id?.includes('imageRow'));
            if (!followingNode) {
              return true;
            } else if (followingNode && followingNode.pageNumbers.length > 1) {
              return true;
            }
          }
          if (currentNode?.id?.includes(PDF_NODE_ID_HEADER_SEARCH_STRING)) {
            const followingNode = followingNodesOnPage.find(node => node.id?.includes(PDF_NODE_ID_HEADER_TABLE_SEARCH_STRING));
            if (followingNode && followingNode?.pageNumbers.length && followingNode.pageNumbers.length > 1) {
              return true;
            }
          }
        }
        return currentNode.id === 'imageRow' && currentNode.pageNumbers.length > 1;
      }
    };
    return docDefinition;
  }

}
