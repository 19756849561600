import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const bauFotoSeries: IconDefinition = {
  prefix: 'bau' as IconPrefix,
  iconName: 'foto-series' as IconName,
  icon: [
    592.79,
    494.67,
    [],
    null,
    // eslint-disable-next-line max-len
    'M341.49 323.57A88 88 0 11256 214.67c1.63 0 3.26 0 4.87.14A194.71 194.71 0 01254.34 182.67a120 120 0 10116 156.54A195 195 0 01341.49 323.57Zm138.51 29.66V446.67a16 16 0 01-16 16h-416a16 16 0 01-16-16V158.67a16 16 0 0116-16h110.2l20.1-53.6a16 16 0 0115-10.4h77.18a195 195 0 0118.36-32H193.3a47.93 47.93 0 00-44.9 31.1L136 110.67h-88a48 48 0 00-48 48V446.67a48 48 0 0048 48h416a48 48 0 0048-48V345.32A194.6 194.6 0 01480 353.23ZM341.49 323.57A88 88 0 11256 214.67c1.63 0 3.26 0 4.87.14A194.71 194.71 0 01254.34 182.67a120 120 0 10116 156.54A195 195 0 01341.49 323.57Zm0 0A88 88 0 11256 214.67c1.63 0 3.26 0 4.87.14A194.71 194.71 0 01254.34 182.67a120 120 0 10116 156.54A195 195 0 01341.49 323.57ZM430.19 283.27V165.75H316.33V116.67H430.19V0h48.74V116.67H592.79v49.11H478.93V283.27Z'
  ]
};
