import {CraftDataService} from './craft-data.service';
import {Injectable} from '@angular/core';
import {Craft, IdType, ProjectCraft, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProjectDataService} from './project-data.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareMappingDataService} from './abstract-project-aware-mapping-data.service';
import _ from 'lodash';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {TranslateService} from '@ngx-translate/core';
import {combineLatestAsync, mapObjectsNameWithDeletedSuffix} from 'src/app/utils/async-utils';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/projectCrafts';

@Injectable({
  providedIn: 'root'
})
export class ProjectCraftDataService extends AbstractProjectAwareMappingDataService<ProjectCraft> {

  constructor(http: HttpClient,
              storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService,
              private craftService: CraftDataService,
              private translateService: TranslateService) {
    super(StorageKeyEnum.PROJECT_CRAFT, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService, projectAvailabilityExpirationService,
          integrityResolverService);
  }

  async update(
    valueArrayOrFunction: Array<ProjectCraft> | ((storageData: Array<ProjectCraft>) => (Array<ProjectCraft> | ProjectCraft | undefined)) | ProjectCraft,
    projectId: IdType
  ): Promise<Array<ProjectCraft>> {
    return this.updateInternal(valueArrayOrFunction, projectId);
  }

  getByCraftId(craftId: IdType): Observable<ProjectCraft> {
    return this.data.pipe(map((crafts) => crafts.find(((craft) => craft.craftId === craftId))));
  }

  getProjectCraftsInProject(projectId: IdType, additionalCraftIdsToInclude?: Array<IdType>): Observable<Craft[]> {
    const additionalCraftToIncludeSet = new Set(additionalCraftIdsToInclude || []);
    return combineLatestAsync([this.dataAcrossProjects$, this.craftService.dataAcrossClients$]).pipe(
      map(([projectCrafts, crafts]) =>
        crafts.filter((craft) => projectCrafts
          .filter((projectCraft) => projectCraft.projectId === projectId)
          .find((projectCraft) => (projectCraft.craftId === craft.id) && craft.isActive !== false) || additionalCraftToIncludeSet.has(craft.id)))
    );
  }

  getProjectCraftsInProjectWithDeletedSuffix(projectId: IdType|undefined, additionalCraftIdsToInclude?: Array<IdType>): Observable<Craft[]> {
    if (!projectId) {
      return of([]);
    }
    return this.getProjectCraftsInProject(projectId, additionalCraftIdsToInclude).pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  }

  getProjectCrafts(additionalCraftIdsToInclude?: Array<IdType>): Observable<Craft[]> {
    const additionalCraftToIncludeSet = new Set(additionalCraftIdsToInclude || []);
    return combineLatestAsync([this.data, this.craftService.data]).pipe(
      map(([projectCrafts, crafts]) => crafts.filter((craft) => (projectCrafts.some((value) => value.craftId === craft.id) && craft.isActive !== false) ||
      additionalCraftToIncludeSet.has(craft.id)))
    );
  }

  getProjectCraftsWithDeletedSuffix(additionalCraftIdsToInclude?: Array<IdType>): Observable<Craft[]> {
    return this.getProjectCrafts(additionalCraftIdsToInclude).pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  }

  getByCraftAcrossProjects(craft: Craft): Observable<ProjectCraft[]> {
    return this.dataByProjectId$.pipe(map((allProjectCraftsMap) => _.flatten(Array.from(allProjectCraftsMap.values())).filter((projectCraft: ProjectCraft) => projectCraft.craftId === craft.id) ));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
