import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const bauStatusInfo: IconDefinition = {
  prefix: 'bau' as IconPrefix,
  iconName: 'status-info' as IconName,
  icon: [
    32,
    32,
    [],
    null,
    // eslint-disable-next-line max-len
    'M16 32A16 16 0 1132 16 16 16 0 0116 32ZM16 2.13A13.87 13.87 0 1029.87 16 13.88 13.88 0 0016 2.13ZM15 21 15 17 13.5 17C12.6687 17 12 16.3313 12 15.5 12 14.6687 12.6687 14 13.5 14L16.5 14C17.3313 14 18 14.6687 18 15.5L18 21 18.5 21C19.3313 21 20 21.6688 20 22.5 20 23.3313 19.3313 24 18.5 24L13.5 24C12.6687 24 12 23.3313 12 22.5 12 21.6688 12.6687 21 13.5 21L15 21ZM16 8C16.5304 8 17.0391 8.2107 17.4142 8.5858 17.7893 8.9609 18 9.4696 18 10 18 10.5304 17.7893 11.0391 17.4142 11.4142 17.0391 11.7893 16.5304 12 16 12 15.4696 12 14.9609 11.7893 14.5858 11.4142 14.2107 11.0391 14 10.5304 14 10 14 9.4696 14.2107 8.9609 14.5858 8.5858 14.9609 8.2107 15.4696 8 16 8ZM16 3.8C9.2621 3.8 3.8 9.2621 3.8 16 3.8 22.7379 9.2621 28.2 16 28.2 22.7379 28.2 28.2 22.7379 28.2 16 28.2 9.2621 22.7379 3.8 16 3.8Z',
  ]
};
