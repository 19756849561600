import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {CompanyDetails} from 'src/app/model/company-details';
import {ToastService} from 'src/app/services/common/toast.service';
import {CompanyService} from 'src/app/services/company/company.service';
import {ProjectCompanyDataService} from 'src/app/services/data/project-company-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {ClientService} from '../../../services/client/client.service';
import {Client, Company, IdType, ProjectCompany} from 'submodules/baumaster-v2-common';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {convertErrorToMessage} from '../../../shared/errors';
import {LoggingService} from '../../../services/common/logging.service';

const LOG_SOURCE = 'CompanyCreateComponent';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss'],
})
export class CompanyCreateComponent implements OnInit, OnDestroy {

  @Input() projectId: IdType | undefined;
  @Input() prePopulateMainFieldWithText?: string;
  @Input() createForSelectable = false;

  public isCreating = false;
  public addToProject = true;
  public isValid = false;
  public isDirty = false;
  private resetSearchFilter?: () => void;
  private modal: HTMLIonModalElement;
  private company: CompanyDetails|undefined;
  private clientDataSubscription: Subscription|undefined;
  private clientData: Client|undefined;

  notConnected$ = this.featureEnabledService.isFeatureEnabled$(false, true, null);

  constructor(
            private translateService: TranslateService,
            private alertCtrl: AlertController,
            private toastService: ToastService,
            private modalController: ModalController,
            private companyService: CompanyService,
            private clientService: ClientService,
            private projectCompanyDataService: ProjectCompanyDataService,
            private projectDataService: ProjectDataService,
            private navParams: NavParams,
            private router: Router,
            private featureEnabledService: FeatureEnabledService,
            private systemEventService: SystemEventService,
            private loggingService: LoggingService) { }

  async ngOnInit() {
    this.initClientData();
    this.resetSearchFilter = this.navParams.data.resetSearchFilter;
    this.setCanDismiss();
  }

  ngOnDestroy() {
    this.unsubscribeClientDataSubscription();
  }

  async createNewCompany() {
    if (!this.isDirty) {
      return true;
    }
    this.isCreating = true;
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Create company (clientId=${this.clientData.id})`);
    try {
      this.company.clientId = this.clientData.id;
      this.company.address.clientId = this.clientData.id;
      this.company.address.changedAt = new Date().toISOString();

      const createdCompany = await this.companyService.insertDetailed(this.company);
      logInstance.logCheckpoint('company created');

      if (this.addToProject && await observableToPromise(this.notConnected$)) {
        await this.insertToProject(createdCompany);
        logInstance.logCheckpoint('company added to the project');
      }

      this.isCreating = false;
      await this.toastService.savingSuccess();

      this.isDirty = false;
      await this.dismissModal('ok', createdCompany);
      if (!this.createForSelectable) {
        await this.redirectToCompanyDetails(createdCompany);
      }
      if (this.resetSearchFilter) {
        this.resetSearchFilter();
      }
      logInstance.success();
      return true;
    } catch (e) {
      logInstance.failure(e);
      this.isCreating = false;
      this.loggingService.error(LOG_SOURCE, `createNewCompany - ${convertErrorToMessage(e)}`)
      await this.toastService.savingError();
      return false;
    }
  }

  private async redirectToCompanyDetails(company: Company) {
    await this.router.navigate(['/company/' + company.id]);
  }

  toggleAddToProject() {
    this.addToProject = !this.addToProject;
  }

  private async insertToProject(company: Company) {
    const projectId = this.projectId ?? (await this.projectDataService.getCurrentProject()).id;
    const projectCompany: ProjectCompany = {
      id: projectId + company.id,
      projectId,
      companyId: company.id,
      changedAt: new Date().toISOString()
    };
    await this.projectCompanyDataService.insert(projectCompany, projectId);
  }

  private initClientData() {
    this.unsubscribeClientDataSubscription();
    this.clientDataSubscription = this.clientService.ownClient$.subscribe(clientData => {
      this.clientData = clientData;
    });
  }

  private unsubscribeClientDataSubscription() {
    this.clientDataSubscription?.unsubscribe();
    this.clientDataSubscription = undefined;
  }

  dismissModal(role?: 'ok' | 'cancel', data?: Company) {
    return this.modal.dismiss(data, role);
  }

  private canDismiss = async () => {
    if (!this.isDirty) {
      return true;
    }
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          role: 'dismiss'
        }
      ]
    });
    await alert.present();
    return (await alert.onWillDismiss()).role === 'dismiss';
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  companyChanged(company: CompanyDetails) {
    this.isDirty = true;
    this.company = company;
  }

  validityChanged(isValid: boolean) {
    this.isValid = isValid;
  }
}
