<app-modal-header>
  <ion-title>{{'project_room.attachments' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <div #attachmentContainer class="attachmentContainer">
    <app-project-room-attachments-list [projectId]="projectId" *ngIf="viewportWidth" [viewportWidth]="viewportWidth" selectMode="true" [maxSelectionLimit]="maxSelectionLimit" (selectionChanged)="onSelectionChanged($event)"></app-project-room-attachments-list>
  </div>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">
      {{ 'cancel' | translate }}
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="select()" [disabled]="!selectedAttachments?.length">
      {{ 'button.add' | translate }}
      <span *ngIf="selectedAttachments?.length">({{selectedAttachments.length}})</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
