import {CommonModule} from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, forwardRef, Optional, OnDestroy, ViewChild } from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {CodeInputComponent, CodeInputModule} from 'angular-code-input';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TwoFactorAuthenticateTotp, TwoFactorDeviceMethod} from 'submodules/baumaster-v2-common';
import {TranslateModule} from '@ngx-translate/core';
import {PageDidEnterLifecycleService} from 'src/app/services/common/page-did-enter-lifecycle.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-gather-two-factor-totp',
  templateUrl: './gather-two-factor-totp.component.html',
  styleUrls: ['./gather-two-factor-totp.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, CodeInputModule, UiModule, IonicModule, FontAwesomeModule, TranslateModule],
  providers: [{
    multi: true,
    useExisting: forwardRef(() => GatherTwoFactorTotpComponent),
    provide: NG_VALUE_ACCESSOR
  }]
})
export class GatherTwoFactorTotpComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private destroy$ = new Subject<void>();

  @Input()
  backLabel = 'twoFactor.login.backToLogin';

  @Input()
  loading: boolean;

  @Output()
  verify = new EventEmitter<void>();

  @Output()
  changeMethod = new EventEmitter<TwoFactorDeviceMethod>();

  @Output()
  dismiss = new EventEmitter<void>();

  @ViewChild(CodeInputComponent, {
    static: true
  })
  codeInput: CodeInputComponent;

  details: TwoFactorAuthenticateTotp;

  private propagateOnChange = (_: any) => { };
  private propagateOnTouched = () => { };

  constructor(@Optional() private pageDidEnterLifecycleService: PageDidEnterLifecycleService) { }

  ngOnInit() {
    this.pageDidEnterLifecycleService?.pageDidEnter$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.codeInput?.focusOnField(0);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(obj: any): void {
    this.details = obj;
  }
  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  codeUpdated(code: string) {
    this.details = {
      ...this.details,
      code,
    };
    this.propagateOnChange(this.details);
    this.propagateOnTouched();
  }

}
