import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {ToastOptions} from '@ionic/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastDurationInMs} from 'src/app/shared/constants';
import {ToastServiceInterface} from '../common/toast-service.interface';

const fixParts = (toast: HTMLIonToastElement) => {
  const closeButton = toast.shadowRoot.querySelector('button.toast__close-button');

  if (closeButton) {
    closeButton.setAttribute('part', 'close-button');
  }

  const buttonGroup = toast.shadowRoot.querySelector('.toast-button-group');

  if (buttonGroup) {
    buttonGroup.setAttribute('part', 'button-group');
  }

  const toastContent = toast.shadowRoot.querySelector('.toast-content');

  if (toastContent) {
    toastContent.setAttribute('part', 'content');
  }
};

@Injectable({
  providedIn: 'root'
})
export class OmgToastService implements ToastServiceInterface {

  constructor(private toastController: ToastController, private translateService: TranslateService) { }

  async toast(stringToTranslate: string, duration: number, opts?: ToastOptions) {
    const toast = await this.toastController.create({
      ...opts,
      message: this.translateService.instant(stringToTranslate),
      duration,
      buttons: [
        {
          role: 'cancel',
          icon: 'close-outline',
          htmlAttributes: {
            'cssClass': 'toast__close-button'
          }
        },
        ...(opts?.buttons ?? []),
      ],
    });

    fixParts(toast);

    await toast.present();
  }

  async toastWithTranslateParams(stringToTranslate: string, params: any, duration: number, opts?: ToastOptions) {
    const toast = await this.toastController.create({
      ...opts,
      message: this.translateService.instant(stringToTranslate, params),
      duration,
      buttons: [
        {
          role: 'cancel',
          icon: 'close-outline',
          htmlAttributes: {
            'cssClass': 'toast__close-button'
          }
        },
      ],
    });

    fixParts(toast);

    await toast.present();
  }

  async toastWithTranslateParamsAndButton(stringToTranslate: string, params: any, duration: number, buttons: ToastOptions['buttons'], opts?: ToastOptions) {
    const toast = await this.toastController.create({
      ...opts,
      message: this.translateService.instant(stringToTranslate, params),
      buttons: [
        {
          role: 'cancel',
          icon: 'close-outline',
          htmlAttributes: {
            'cssClass': 'toast__close-button'
          }
        },
        ...buttons,
      ],
      duration
    });

    fixParts(toast);

    await toast.present();
  }

  async errorWithMessageAndHeader(stringToTranslate: string, errorMessage: string, opts?: ToastOptions): Promise<void> {
    const toast = await this.toastController.create({
      ...opts,
      header: this.translateService.instant(stringToTranslate),
      message: errorMessage,
      duration: ToastDurationInMs.ERROR,
      buttons: [
        {
          text: 'Close',
          role: 'cancel'
        }
      ]
    });

    await toast.present();
  }

  async info(stringToTranslate: string, opts?: ToastOptions) {
    await this.toast(stringToTranslate, ToastDurationInMs.INFO, opts);
  }

  async infoWithMessage(stringToTranslate: string, opts?: ToastOptions) {
    await this.toast(stringToTranslate, ToastDurationInMs.INFO_WITH_MESSAGE, opts);
  }

  async infoWithMessageAndButtons(stringToTranslate: string, buttons: ToastOptions['buttons'], opts?: ToastOptions) {
    await this.toast(stringToTranslate, ToastDurationInMs.INFO_WITH_MESSAGE, {
      buttons,
      ...(opts ?? {})
    });
  }

  async infoWithMessageAndHeader(headerToTranslate: string, stringToTranslate: string, opts?: ToastOptions) {
    await this.toast(stringToTranslate, ToastDurationInMs.INFO_WITH_MESSAGE, {
      header: this.translateService.instant(headerToTranslate),
      ...(opts ?? {}),
    });
  }

  async error(stringToTranslate: string, opts?: ToastOptions) {
    await this.toast(stringToTranslate, ToastDurationInMs.ERROR, opts);
  }

  async savingSuccess(title?: string) {
    if (title) {
      await this.toastWithTranslateParams('saving_with_title_success', { title, }, ToastDurationInMs.INFO);
    } else {
      await this.info('saving_success');
    }
  }

  async savingError() {
    await this.error('error_saving_message');
  }
}
