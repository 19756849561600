<app-modal-header>
  <ion-title *ngIf="!protocolEntryTypeId"> {{ 'protocolEntryTypeForm.createTitle' | translate }}  </ion-title>
  <ion-title *ngIf="protocolEntryTypeId"> {{ 'protocolEntryTypeForm.editTitle' | translate }}  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="protocolEntryTypeForm" action="#">
    <ion-grid class="grid-gap-4">
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-input label="{{ 'protocolEntryTypeForm.name' | translate }}" label-placement="floating" #inputName formControlName="name" maxLength="21" type="text" class="omg-required"></ion-input>
          </ion-item>
          <span *ngIf="protocolEntryTypeForm.controls.name.errors?.protocolEntryTypeNameExist && protocolEntryTypeForm.dirty" class="error-message">
            {{ 'protocolEntryTypeForm.nameExist' | translate }}
          </span>
          <span *ngIf="protocolEntryTypeForm.controls.name.errors?.required && protocolEntryTypeForm.controls.name.dirty" class="error-message">
            {{ 'formValidation.emptyField' | translate:{field: 'protocolEntryTypeForm.name' | translate} }}
          </span>
          <span *ngIf="protocolEntryTypeForm.controls.name.errors?.nameExistAndInactive && protocolEntryTypeForm.dirty && !restoreMode" class="error-message">
             {{ 'protocolEntryTypeForm.nameExistAndInactive' | translate }} <ion-text class="cursor-pointer" color="primary" (click)="restoreByName()" *ngIf="protocolEntryTypeId === null"> {{ 'button.restore' | translate }}</ion-text>
          </span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="statusFieldActive">
             {{ 'protocolEntryTypeForm.task' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-label> {{ 'protocolEntryTypeForm.taskDescription' | translate }} </ion-label>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="protocolEntryTypeForm.controls.statusFieldActive.value">
        <ion-col size="12">
          <ion-item class="omg omg-form-checkbox">
           <ion-checkbox label-placement="end" justify="start" formControlName="taskDefault">
             {{ 'protocolEntryTypeForm.taskDefault' | translate }}
           </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="protocolEntryTypeForm.controls.statusFieldActive.value">
        <ion-col size="12">
          <ion-label> {{ 'protocolEntryTypeForm.taskDefaultDescription' | translate }} </ion-label>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="start" class="omg-form">
    <ion-item class="omg omg-form-checkbox" (click)="toggleAddToProject()" *ngIf="!protocolEntryTypeId && (notConnected$ | async) && !createForSelectable">
     <ion-checkbox label-placement="end" justify="start" [ngModel]="addToProject">
       {{ 'protocolEntryTypeForm.addToProject' | translate }}
     </ion-checkbox>
    </ion-item>
    <ion-button *ngIf="protocolEntryTypeId" class="omg-btn-secondary omg-btn-color-danger" (click)="deleteProtocolEntryType(protocolEntryType)">
      <fa-icon slot="start" [icon]="['fal', 'trash-alt']"></fa-icon>
      <span>{{'delete' | translate}}</span>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end" class="omg-form">
    <ion-button class="omg-btn-text" (click)="dismissModal()">{{ 'cancel' | translate }}</ion-button>
    <ion-button class="omg-btn-primary" [disabled]="(!protocolEntryTypeForm?.valid || !protocolEntryTypeForm?.dirty || loading) && !restoreMode" (click)="saveProtocolEntryType()" >
      <span *ngIf="!protocolEntryTypeId && !restoreMode">{{'button.create' | translate }}</span>
      <span *ngIf="protocolEntryTypeId">{{'button.save' | translate }}</span>
      <span *ngIf="!protocolEntryTypeId && restoreMode">{{'button.restore' | translate }}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
