import {Directive, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { IonButton, Platform } from '@ionic/angular';
import {sanitizeForFilename} from 'submodules/baumaster-v2-common';
import { DownloadService } from '../services/download/download.service';

@Directive({
  selector: 'ion-button[appDownload]'
})
export class DownloadDirective implements OnInit, OnChanges, OnDestroy {

  private objectURL: string;

  @Input()
  appDownload: Blob;

  @Input()
  appDownloadFilename: string;

  get download() { return sanitizeForFilename(this.appDownloadFilename, {replacement: '_', includesFileExt: true}); }

  get href() { return this.objectURL; }

  constructor(private downloadService: DownloadService,
              private ionButton: IonButton,
              private platform: Platform) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appDownload) {
      if (this.objectURL) {
        URL.revokeObjectURL(this.objectURL);
        this.objectURL = undefined;
      }
      this.objectURL = URL.createObjectURL(this.appDownload);
      if (!this.platform.is('capacitor')) {
        this.ionButton.href = this.href;
        this.ionButton.download = this.download;
      }
    }
  }

  ngOnInit() {
    if (!this.ionButton) {
      throw new Error('Couldn\'t get ion-button ref!');
    }
  }

  ngOnDestroy() {
    if (this.objectURL) {
      URL.revokeObjectURL(this.objectURL);
      this.objectURL = undefined;
    }
  }

  @HostListener('click')
  onClick(event: Event) {
    this.downloadService.downloadBlob(
      this.appDownload,
      this.appDownloadFilename,
      event
    );
  }

}
