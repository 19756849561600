import {Pipe, PipeTransform} from '@angular/core';
import {Attachment} from 'submodules/baumaster-v2-common';
import {fabric} from 'fabric';
import {CanvasDimension, CanvasOptions, SizeType} from '../utils/canvas-utils';
import {loadImageEnsureWidthHeight} from '../../../submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';

const size: { [key in SizeType]: { height: number, width: number } } = {
  small: {height: 45, width: 45},
  large: {height: 90, width: 90},
  'large-square': {height: 90, width: 90},
  'screenshot-preview': {height: 400, width: 400}
};

@Pipe({
  name: 'canvas'
})

export class CanvasPipe implements PipeTransform {
  async transform(markings: string, attachment: Attachment, canvasOptions: CanvasOptions, imageUrl: string): Promise<void> {
    console.log(`CanvasPipe.transform - attachment.id="${attachment.id}"`);
    if (typeof attachment === 'string') {
      return;
    }
    if (!imageUrl) {
      return;
    }
    const {image: img} = await loadImageEnsureWidthHeight(imageUrl, attachment?.width, attachment?.height);
    const canvasSize = size[canvasOptions.size];
    const viewportWidth = canvasSize.width;
    const viewportHeight = canvasSize.height;
    const canvasDimension: CanvasDimension = this.calculateOptimalDimension(img.width, img.height, viewportWidth, viewportHeight);

    const canvasElement = document.createElement('canvas');
    canvasOptions.element.innerHTML = '';
    canvasOptions.element.appendChild(canvasElement);
    const canvas = new fabric.StaticCanvas(canvasElement, {width: canvasDimension.width, height: canvasDimension.height});
    canvas.setZoom(canvasDimension.zoomFactor);

    if (attachment.markings && attachment.markings !== '') {
      let data = typeof attachment.markings === 'string' ? JSON.parse(attachment.markings) : attachment.markings;
      if (typeof data === 'string') {
        // markings are being stored as string in the database, even though is a jsonb datatype. This allows to work with both types of data.
        data = JSON.parse(data);
      }
      data.fabricData.backgroundImage = null;
      canvas.loadFromJSON(data.fabricData, canvas.renderAll.bind(canvas));
    }
    canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
  }

  calculateOptimalDimension(imageWidth, imageHeight, viewportWidth, viewportHeight): CanvasDimension {
    if (imageWidth > imageHeight) {
      const calculatedWidth = viewportWidth;
      const calculatedHeight = imageHeight / imageWidth * viewportWidth;
      const factor = calculatedHeight / imageHeight;
      return {width: calculatedWidth, height: calculatedHeight, zoomFactor: factor};
    } else {
      const calculatedHeight = viewportHeight;
      const calculatedWidth = imageWidth / imageHeight * viewportHeight;
      const factor = calculatedHeight / imageHeight;
      return {width: calculatedWidth, height: calculatedHeight, zoomFactor: factor};
    }
  }

}
