import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RxLet} from '@rx-angular/template/let';
import _ from 'lodash';
import {map, startWith} from 'rxjs/operators';
import {ProtocolEntrySearchFilter} from 'src/app/model/protocol-entry-search-filter';
import {AbstractEntryFilterByService} from 'src/app/services/entry/abstract-entry-filter-by.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {ProtocolEntrySearchFilterService} from 'src/app/services/search/protocol-entry-search-filter.service';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {EntryFilterFormComponent} from '../entry-filter-form/entry-filter-form.component';

@Component({
  selector: 'app-entry-filter',
  templateUrl: './entry-filter.component.html',
  styleUrls: ['./entry-filter.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    EntryFilterFormComponent,
    RxLet,
    FormsModule,
  ],
})
export class EntryFilterComponent {

  filterData$ = combineLatestAsync([
    this.entryFilterDataService.showRecentlyUsedInCurrentProject$,
    this.entryFilterDataService.nameableDropdownName$,
    this.entryFilterDataService.companies$,
    this.entryFilterDataService.crafts$,
    this.entryFilterDataService.profiles$,
    this.entryFilterDataService.entryTypes$,
    this.entryFilterDataService.customFields$,
    this.entryFilterDataService.locations$,
    this.entryFilterDataService.statuses$,
    this.entryFilterDataService.priorities$,
  ]).pipe(
    map(([
      showRecentlyUsedInCurrentProject,
      nameableDropdownName,
      companies,
      crafts,
      profiles,
      entryTypes,
      customFields,
      locations,
      statuses,
      priorities,
    ]) => ({
      showRecentlyUsedInCurrentProject,
      nameableDropdownName,
      companies,
      crafts,
      profiles,
      entryTypes,
      customFields,
      locations,
      statuses,
      priorities,
    })),
    startWith({
      showRecentlyUsedInCurrentProject: false,
      nameableDropdownName: '',
      companies: [],
      crafts: [],
      profiles: [],
      entryTypes: [],
      customFields: [],
      locations: [],
      statuses: [],
      priorities: [],
    })
  );

  filters$ = this.protocolEntrySearchFilterService.filters$;

  constructor(
    public entryFilterDataService: AbstractEntryFilterByService,
    private protocolEntrySearchFilterService: ProtocolEntrySearchFilterService,
    private posthogService: PosthogService
  ) { }

  async setFilters(filters: ProtocolEntrySearchFilter) {
    const currentFilters = await observableToPromise(this.filters$);
    const eventFilterStatus = {
      status: _.isEmpty(currentFilters.entry.status.in) && !_.isEmpty(filters.entry.status.in),
      craft: _.isEmpty(currentFilters.entry.craftId.in) && !_.isEmpty(filters.entry.craftId.in),
      company: _.isEmpty(currentFilters.entry.companyId.in) && !_.isEmpty(filters.entry.companyId.in),
      allCompanies: _.isEmpty(currentFilters.entry.allCompanies.in) && !_.isEmpty(filters.entry.allCompanies.in),
      observer: _.isEmpty(currentFilters.entry.observerCompanies.in) && !_.isEmpty(filters.entry.observerCompanies.in),
      responsible: _.isEmpty(currentFilters.entry.internalAssignmentId.in) && !_.isEmpty(filters.entry.internalAssignmentId.in),
      type: _.isEmpty(currentFilters.entry.typeId.in) && !_.isEmpty(filters.entry.typeId.in),
      location: _.isEmpty(currentFilters.entry.locationId.in) && !_.isEmpty(filters.entry.locationId.in),
      customField: _.isEmpty(currentFilters.entry.nameableDropdownId.in) && !_.isEmpty(filters.entry.nameableDropdownId.in),
      priority: _.isEmpty(currentFilters.entry.priority.in) && !_.isEmpty(filters.entry.priority.in),
      fromDateToDo: _.isNull(currentFilters.entry.todoUntil.gte) && !_.isNull(filters.entry.todoUntil.gte),
      toDateToDo: _.isNull(currentFilters.entry.todoUntil.lte) && !_.isNull(filters.entry.todoUntil.lte),
      fromDateCreated: _.isNull(currentFilters.entry.createdAt.gte) && !_.isNull(filters.entry.createdAt.gte),
      toDateCreated: _.isNull(currentFilters.entry.createdAt.lte) && !_.isNull(filters.entry.createdAt.lte),
    };
    if (Object.values(eventFilterStatus).includes(true)) {
      this.posthogService.captureEvent('Task Filter applied', eventFilterStatus);
    }
    this.protocolEntrySearchFilterService.filtersSubject.next(filters);
  }

}
